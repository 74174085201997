import { Component, OnInit } from '@angular/core';
import { IosInstallComponent } from './ios-install/ios-install.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'; //Para reconocer que existe otra version del Service Worker

import { OverlayContainer } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { Servicios } from './funciones/encryptar';
import { BaseDatos } from './funciones/basededatos';
import { EdgeDetectComponent } from './edge-detect/edge-detect.component';
import { filter, map } from 'rxjs/operators';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(public base: BaseDatos, private router: Router, private servicio: Servicios, private toast: MatSnackBar, private swUpdate: SwUpdate, private overlay: OverlayContainer) { }

  ngOnInit() {
    // Detecta si el dispositio tiene sistema operativo iOS 
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }

    // Detecta si el navegador es Edge
    const isEdge = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /edge/.test(userAgent);
    }

    // Detecta si esta en modo standalone 
    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);

    // Verifica si debe mostrar o no el mensaje de instalar app en iOS:
    if (isIos() && !isInStandaloneMode()) {
      Promise.resolve().then(() => {
        this.toast.openFromComponent(IosInstallComponent, {
          duration: 9000,
          horizontalPosition: 'center',
          panelClass: ['mat-elevation-z3']
        });
      });
    }

    // Verifica si debe mostrar o no el mensaje de funcionamiento en Edge
    if (isEdge() && !isInStandaloneMode()) {
      Promise.resolve().then(() => {
        this.toast.openFromComponent(EdgeDetectComponent, {
          duration: 9000,
          horizontalPosition: 'center',
          panelClass: ['mat-elevation-z3']
        });
      });
    }

    //Detecta nueva version de SW y pregunta si se lo instala
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));
    if (this.swUpdate.isEnabled) {
      updatesAvailable.subscribe(() => {
        let mensajeUpdate = '';
        if (localStorage.getItem('locale') == 'es') {
          mensajeUpdate = 'Se ha descargado nueva version de aplicación.';
        }
        else {
          mensajeUpdate = 'New version of application has been downloaded';
        }
        alert(mensajeUpdate);
        localStorage.removeItem("BaseDatos");
        localStorage.removeItem('idioma'); //Actualizar Idioma con UpDate de App
        this.base.expirarToken();
        this.router.navigate(['']);
        localStorage.setItem('ingresado', this.servicio.encriptar('No ingresado'));
        window.location.reload();
      });
    }
    ///Lee configuracion de tema en local data storage, de no existir setea tema obscuro por defecto
    let themeSelected = localStorage.getItem('themeDisplayed');
    if (themeSelected == null) {  //Primer Ingreso - Setear tema oscuro
      localStorage.setItem('themeDisplayed', "light-theme");
      themeSelected = localStorage.getItem('themeDisplayed');
    }
    else {
      let temasDisponibles = ["night-theme", "light-theme", "light-theme-verde", "light-theme-gris", "light-theme-azul"]
      // if (themeSelected != "night-theme" && themeSelected != "light-theme") {
      if (!temasDisponibles.includes(themeSelected)) {
        localStorage.setItem('themeDisplayed', "light-theme");
        themeSelected = localStorage.getItem('themeDisplayed');
      }
    }

    let skinSelected = localStorage.getItem('skinDisplayed');
    if (skinSelected == null) {  //Primer Ingreso - Setear tema oscuro
      localStorage.setItem('skinDisplayed', "skin-aestetic");
    } else {
      localStorage.setItem('skinDisplayed', skinSelected);
    }

    document.body.classList.add(themeSelected, "mat-app-background");
    this.overlay.getContainerElement().classList.add(themeSelected);
  }
}
