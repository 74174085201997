<div class="textura" [class.mediano]=normal [class.mediano]=mediano [class.grande]=grande>
    <h2 mat-dialog-title>{{idioma.CodigoQR}}</h2>

    <mat-dialog-content>
        <div style="text-align: center;" *ngIf="isScanQR">
            <p>{{idioma.MsgScanearCodigoTran}}</p>
        </div>
        <div style="text-align: center;" *ngIf="!isScanQR">
            <p style="text-align: center;">{{idioma.LeerQR}}</p>
        </div>
        <div class="square-with-corners" *ngIf="isScanQR">
            <div class="corner top-left"></div>
            <div class="corner top-right"></div>
            <div class="corner bottom-left"></div>
            <div class="corner bottom-right"></div>
            <div class="qr-container">
              <qrcode [qrdata]="data" 
                    [imageSrc]="'assets/resources/mipmap-hdpi/icon-cash-amarillo-qr.svg'" 
                    [imageHeight]="75" 
                    [imageWidth]="75"
                    [width]="200"
                    [margin]="2" 
                    [errorCorrectionLevel]="'M'">
              </qrcode>
            </div>
        </div>
        <div style="text-align: center;" *ngIf="!isScanQR">
            <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)">
            </zxing-scanner>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions style="padding-top: 0px">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon>done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>
</div>