<div class="textura" *ngIf="mostrarTexto">
  <h2 mat-dialog-title style="text-align: center;"><strong>{{idioma.PlazoFijoA}}</strong></h2>
  <mat-dialog-content>
    <div>
      <mat-card class="cardTitle">
        <div class="row">
          <div class="col-4">
            <b>{{idioma.Femision}}</b>
            <p>{{dato.fcontable}}</p>
          </div>
          <div class="col-4">
            <b>{{idioma.DesdeCuenta}}</b>
            <p>{{dato.ccuenta}}</p>
          </div>
          <div class="col-4">
            <b>{{idioma.Capitalpl}}</b>
            <p>{{dato.monto}}</p>
          </div>
        </div>
      </mat-card>
    </div>
    <div style="padding-top: 10px !important;">
      <mat-card class="cardBody">
        <div class="row">
          <div class="col-6">
            <b>{{idioma.Plazo}}</b>
          </div>
          <div class="col-6">
            <p> {{dato.plazo}}</p>
          </div>
          <div class="col-6">
            <b>{{idioma.Tasa}}</b>
          </div>
          <div class="col-6">
            <p>{{dato.tasa}}</p>
          </div>
          <div class="col-6">
            <b>{{idioma.InteresG}}</b>
          </div>
          <div class="col-6">
            <p>{{dato.interesnetoD}}</p>
          </div>
          <div class="col-6">
            <b>{{idioma.Impuesto}}</b>
          </div>
          <div class="col-6">
            <p>{{dato.impuestoneto}}</p>
          </div>
          <div class="col-6">
            <b>{{idioma.PRecibir}}</b>
          </div>
          <div class="col-6">
            <p>{{dato.neto}}</p>
          </div>
          <div class="col-6">
            <b>{{idioma.Frecuencia}}</b>
          </div>
          <div class="col-6">
            <p>{{dato.frecuencia}}</p>
          </div>
          <div class="col-6">
            <b>{{idioma.FVencimiento}}</b>
          </div>
          <div class="col-6">
            <p>{{dato.fvencimiento}}</p>
          </div>
        </div>
      </mat-card>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!band" style="padding: 0 0 24px 0;">
    <div style="text-align: center !important; width: 100%;">
      <button mat-raised-button class="buttonToken" (click)="close()">
        <mat-icon> cancel_presentation</mat-icon>
        {{rechazar}}
      </button>
      <button mat-raised-button class="buttonToken" (click)="accept()">
        <mat-icon>download_done</mat-icon>{{aceptar}}
      </button>
    </div>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="band" style="padding: 0 0 24px 0;">
    <div style="text-align: center !important; width: 100%;">
      <button mat-raised-button class="buttonToken" (click)="accept()">
        <mat-icon>download_done</mat-icon>{{aceptar}}
      </button>
    </div>
  </mat-dialog-actions>

</div>

<div class="dialogoAcuerdoPrivacidad">
  <p *ngIf="mostrar" [innerHTML]='mensaje'></p>
  <mat-dialog-content *ngIf="mostrar" style="height: 100%;" class="dialogAgreementContent">
    <iframe *ngIf="!bandMobile" src="assets/resources/reporte/TERMINOS_Y_CONDICIONES_CDP.pdf" frameborder="0"
      width="100%" height="95%">
    </iframe>
    <ngx-extended-pdf-viewer *ngIf="bandMobile" #pdfViewer
      [src]="'/assets/resources/reporte/TERMINOS_Y_CONDICIONES_CDP.pdf'" backgroundColor="#ffffff" [textLayer]="true"
      [handTool]=false [useBrowserLocale]="true" [zoom]="'65%'" [customToolbar]="additionalButtons">
    </ngx-extended-pdf-viewer>
    <ng-template #additionalButtons>
      <div style="text-align: center;">
        <button mat-icon-button id="zoomIn">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button id="zoomOut">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button id="download">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="mostrarPdfBase64" style="height: 100%;" class="dialogAgreementContent">
    <ngx-extended-pdf-viewer #pdfViewer [base64Src]="dato" backgroundColor="#ffffff" [handTool]=false
      [useBrowserLocale]="true" [textLayer]="false" [zoom]="'65%'" [showUnverifiedSignatures]="true"
      [customToolbar]="multiToolbar">
    </ngx-extended-pdf-viewer>
    <ng-template #multiToolbar>
      <div style="text-align: center;">
        <button mat-icon-button id="zoomIn">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button id="zoomOut">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button id="download">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </ng-template>
  </mat-dialog-content>
  <div style="width: 100% !important; text-align: right;">
    <button *ngIf="mostrar" mat-raised-button class="buttonToken" (click)="accept()">
      <mat-icon>download_done</mat-icon>{{aceptar}}
    </button>
  </div>