<div class="textura">
    <h2 mat-dialog-title>{{idioma.TituloDialogos}}</h2>

    <mat-dialog-content
        *ngIf="!salir && usuario!='depurarBase' && usuario!='Reporte' && usuario!='ReporteServ' && usuario!='saveUser'">
        <p>{{idioma.ExcNumSesion}}.</p>
        <p>{{idioma.RegViejo}}: {{usuario}}.</p>
        <p>{{idioma.ElimInfo}}.</p>
    </mat-dialog-content>

    <mat-dialog-actions
        *ngIf="!salir && usuario!='depurarBase' && usuario!='Reporte' && usuario!='ReporteServ' && usuario!='saveUser'">
        <div style="width: 100% !important; text-align: center;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon> cancel_presentation</mat-icon>
                {{idioma.Rechazar}}
            </button>
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>

    <mat-dialog-content *ngIf="salir && usuario!='eliminarTarea' && usuario!='editarTarea'">
        <p>{{idioma.SegCerrSesion}}?</p>
        <p>
            <mat-checkbox [checked]='bandGuardarInfo' [value]="bandGuardarInfo"
                (change)="bandGuardarInfo=!bandGuardarInfo" color="primary">
                {{idioma.keepUserInfo}}
            </mat-checkbox>
        </p>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="salir && usuario!='eliminarTarea' && usuario!='editarTarea'">
        <div style="width: 100% !important; text-align: center;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon> cancel_presentation</mat-icon>
                {{idioma.Rechazar}}
            </button>
            <button mat-raised-button class="buttonToken" (click)="logout()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>


    <mat-dialog-content *ngIf="salir && usuario=='eliminarTarea'">
        <p>{{idioma.SegElimTarea}}?</p>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="salir && usuario=='eliminarTarea'">
        <div style="width: 100% !important; text-align: center;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon> cancel_presentation</mat-icon>
                {{idioma.Rechazar}}
            </button>
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>

    <mat-dialog-content *ngIf="salir && usuario=='editarTarea'">

        <p>{{idioma.SegEditTarea}}</p>
        <p>{{idioma.SeguroContinuar}}</p>

    </mat-dialog-content>

    <mat-dialog-actions *ngIf="salir && usuario=='editarTarea'">
        <div style="width: 100% !important; text-align: center;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon> cancel_presentation</mat-icon>
                {{idioma.Rechazar}}
            </button>
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>

    <mat-dialog-content *ngIf="!salir && usuario=='depurarBase'">
        <p>{{idioma.ManteElimInfo}} {{dias}} {{idioma.Dias}}</p>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!salir && usuario=='depurarBase'">
        <div style="width: 100% !important; text-align: center;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon> cancel_presentation</mat-icon>
                {{idioma.Rechazar}}
            </button>
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>

    <mat-dialog-content *ngIf="bandReport">
        <p>{{idioma.ReporteDialog}}</p>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="bandReport">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>

    <mat-dialog-content *ngIf="bandReportServ">
        <p>{{idioma.ReporteServDialog}}</p>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="bandReportServ">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>

    <mat-dialog-content *ngIf="usuario=='saveUser'">
        <p>{{idioma.saveUser}}</p>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="usuario=='saveUser'">
        <div style="width: 100% !important; text-align: center;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon> cancel_presentation</mat-icon>
                {{idioma.Rechazar}}
            </button>
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>
</div>