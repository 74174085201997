import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { BaseDatos } from './basededatos';
import { Servicios } from './encryptar';

@Injectable()
export class Consulta {

  constructor(public apiService: ApiService, private base: BaseDatos, private servicios: Servicios) {
  }

  getConsulta() {
    this.apiService.postProvider2InternetCheck('/productGroup', this.base.id_token, { 'csubsystem': '04', 'cgrupoproducto': '', 'descripcion': '' }).then(
      (data1: any) => {
        localStorage.setItem('GrupoProductos', this.servicios.encriptar(JSON.stringify(data1.array)));
        for (let i = 0; i < data1.array.length; i++) {
          this.apiService.postProvider2InternetCheck('/product', this.base.id_token, { 'csubsystem': '04', 'cgrupoproducto': data1.array[i].cgrupoproducto }).then(
            (data2: any) => {
              localStorage.setItem('Productos', this.servicios.encriptar(JSON.stringify(data2.array)));
            }, (err) => {
              console.log(err);
            })
        }
      })
  }
}
