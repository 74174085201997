import { Component, OnInit, Input, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CarouselModule, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Alerta } from '../funciones/alerta';
import { ApiService } from '../api.service';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, OcultarCuentaPosTrans, plazoMeses } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { GraficoPosicionConsolidadaComponent } from '../grafico-posicion-consolidada/grafico-posicion-consolidada.component';
import { TransaccionesComponent } from '../transacciones/transacciones.component';
import { TransaccionesPreaprobadoComponent } from '../transacciones-preaprobado/transacciones-preaprobado.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { BeneficiariosComponent } from '../beneficiarios/beneficiarios.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatTabsModule, MatExpansionModule, MatButtonModule, MatDividerModule, MatMenuModule, MatPaginatorModule, MatTableModule, MatCardModule, CommonModule, CarouselModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, FormatoFecha, plazoMeses, FlexLayoutModule, GraficoPosicionConsolidadaComponent, TransaccionesComponent, TransaccionesPreaprobadoComponent, BeneficiariosComponent],
  selector: 'app-generar-cartas',
  templateUrl: './generar-cartas.component.html',
  styleUrls: ['./generar-cartas.component.scss']
})
export class GenerarCartasComponent implements OnInit, AfterViewInit {

  @Input() idiomas: any;
  @Input() datos;
  @Input() datos2;
  @Input() ctasocio;
  @Input() ahorros;
  @Input() inversiones;
  @Input() prestamos;
  @Input() datosGrafica;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() hasProducts: any[];
  @Input() prestamosA;
  @Input() prestamosAA;
  @Input() skinSeleccionadoBand;
  @Input() coloresGrafica;
  @ViewChild('paginatorAhorros') paginatorAhorros: MatPaginator;
  @ViewChild('paginatorInversiones') paginatorInversiones: MatPaginator;
  @ViewChild('paginatorPrestamos') paginatorPrestamos: MatPaginator;
  @ViewChild('paginatorPrestamosA') paginatorPrestamosA: MatPaginator;
  @Output() updatePosCons: EventEmitter<any> = new EventEmitter<any>();
  @Output() operationEvent: EventEmitter<any> = new EventEmitter<any>();

  datos3: any[];
  parametros: any;
  deshabilitar: boolean;
  deshabilitarPre: boolean;
  flechaR: boolean[];
  flechaL: boolean[];
  deshabilitarTransacciones: boolean;
  showTable: boolean = true;
  deshabilitarBloqueos: boolean;
  deshabilitarConfirmar: boolean;
  deshabilitarPignorados: boolean;
  deshabilitarBeneficiarios: boolean;
  intentoAviso: boolean = false;
  intentoPre: boolean = false;
  locale: any;
  lastLogin: any;
  txDesdeTx: boolean;
  numMaxRow: number;
  noMuestraGrafico: boolean;
  displayedColumnsAhorros = ['Cuenta', 'Disponible', 'Producto', 'Total', 'Bloqueado', 'Estado'];
  displayedColumnsPlazoFijo = ['Cuenta', 'MontoAper', 'Producto', 'Plazo', 'Tasa', 'Apertura', 'Vencimiento', 'Estado'];
  displayedColumnsPrestamos = ['Cuenta', 'MontoAper', 'Producto', 'SaldoCapital', 'fProxPago', 'ValorPagar', 'Estado'];
  displayedColumnsPrestamosPreaprobados = ['Producto', 'Moneda', 'Montomin', 'Montomax', 'Plazomin', 'Plazomax', 'Tasa', 'Fvigencia', 'Estado'];
  bandsFuncion: any;
  bandMobile: boolean;
  flechaL1: boolean;
  flechaR1: boolean;
  flechaL2: boolean;
  flechaR2: boolean;
  flechaL3: boolean;
  flechaR3: boolean;
  flechaL4: boolean;
  flechaR4: boolean;
  flechaL5: boolean;
  flechaR5: boolean;

  pageIndexAh: number = 0;
  pageSizeAh: number;
  pageIndexInv: number = 0;
  pageSizeInv: number;
  pageIndexPre: number = 0;
  pageSizePre: number;
  pageIndexPreA: number = 0;
  pageSizePreA: number;

  lengthAh: number;
  lengthInv: number;
  lengthPre: number;
  lengthPreA: number;

  datosAhorros: any;
  datosInversiones: any;
  datosPrestamos: any;
  datosPrestamosA: any;
  datosPrestamosAA: any;

  tabIndex = 0;

  tema: string;
  temaPrev: string;
  skin: string;
  skinPrev: string;
  skinAestetic: boolean = false;
  skinCompact: boolean = false;

  //ngx-carousel options
  customCarouselOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 12000,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 900,
    stagePadding: 15,
    margin: 5,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 2
      },
      940: {
        items: 3
      },
      1140: {
        items: 4
      }
    },
    nav: true
  }

  //ngx-carousel options
  customCarouselOptionsCompact: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 12000,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 900,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 2
      },
      940: {
        items: 3
      },
      1140: {
        items: 4
      }
    },
    nav: true
  }

  //ngx-carousel options
  customCarouselOptionsAh: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 12000,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    slideBy: 'page',
    navSpeed: 900,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 1,
      },
    },
    nav: false,
  }

  //ngx-carousel options
  customCarouselOptionsMen: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 900,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false,
  }

  constructor(private servicio: Servicios, private base: BaseDatos, private alerta: Alerta, public apiService: ApiService, private ngNavigatorShareService: NgNavigatorShareService) {
    this.flechaR = [];
    this.flechaL = [];
    this.flechaL1 = true;
    this.flechaR1 = false;
    this.flechaL2 = true;
    this.flechaR2 = false;
    this.flechaL3 = true;
    this.flechaR3 = false;
    this.flechaL4 = true;
    this.flechaR4 = false;
    this.flechaL5 = true;
    this.flechaR5 = false;
    this.noMuestraGrafico = false;
    this.txDesdeTx = false;
    this.bandMobile = false;
    this.numMaxRow = 5;
    this.tema = localStorage.getItem('themeDisplayed');
    this.skin = localStorage.getItem('skinDisplayed');
    this.locale = localStorage.getItem('locale');
    this.temaPrev = this.tema;
    this.skinPrev = this.skin;
    this.bandsFuncion = {
      '0': true,
      '1': false,
      '2': false,
      '3': false
    }

    this.pageSizeAh = 2
    this.pageIndexAh = 0
    this.pageSizeInv = 2
    this.pageIndexInv = 0
    this.pageSizePre = 2
    this.pageIndexPre = 0

    setTimeout(() => {
      this.intentoAviso = false;
    }, 500);

    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    this.cambiarSkinApp()
  }

  ngOnInit() {
    this.deshabilitar = false;
    this.lastLogin = this.base.retornarConsulta("loginInfo").lastLogin;
    this.deshabilitarPre = false;
    this.deshabilitarTransacciones = false;
    this.deshabilitarBeneficiarios = false;
    this.deshabilitarBloqueos = false;
    this.deshabilitarConfirmar = false;
    this.deshabilitarPignorados = false;
    this.showTable = true;
    this.parametros = null;
    if (this.datos2) {
      for (var i = 0; i < this.datos2.length; i++) {
        this.flechaR[i] = false;
        this.flechaL[i] = true;
      }
    }
    this.cambiarSkinApp()
  }

  ngDoCheck() {
    this.locale = localStorage.getItem('locale');
    this.tema = localStorage.getItem('themeDisplayed');
    if ((this.tema != this.temaPrev)) {
      this.temaPrev = this.tema;
    }
  }

  cambiarSkinApp() {
    if (this.skinSeleccionadoBand == "0") {
      this.skinAestetic = true;
      this.skinCompact = false;
    } else if (this.skinSeleccionadoBand == "1") {
      this.skinAestetic = false;
      this.skinCompact = true;
    }
  }

  buttonSelected(index) {
    this.showTable = false;
    this.createTable();

    this.bandsFuncion = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false
    }
    this.flechaL1 = true;
    this.flechaR1 = false;
    this.bandsFuncion[index] = true;
  }

  createTable() {
    this.pageSizeAh = 2
    this.pageIndexAh = 0
    this.pageSizeInv = 2
    this.pageIndexInv = 0
    this.pageSizePre = 2
    this.pageIndexPre = 0

    this.datosAhorros = []
    this.datosInversiones = []
    this.datosPrestamos = []
    this.datosPrestamosA = []

    setTimeout(() => {
      this.lengthAh = this.ahorros.length
      this.datosAhorros = new MatTableDataSource(this.ahorros);
      this.datosAhorros.paginator = this.paginatorAhorros;

      this.lengthInv = this.inversiones.length
      this.datosInversiones = new MatTableDataSource(this.inversiones);
      this.datosInversiones.paginator = this.paginatorInversiones;

      this.lengthPre = this.prestamos.length
      this.datosPrestamos = new MatTableDataSource(this.prestamos);
      this.datosPrestamos.paginator = this.paginatorPrestamos;

      this.lengthPreA = this.prestamosA.length
      this.datosPrestamosA = new MatTableDataSource(this.prestamosA);
      this.datosPrestamosA.paginator = this.paginatorPrestamosA;
    }, 100);
    setTimeout(() => {
      this.showTable = true;
    }, 80);
  }

  ngAfterViewInit() {
    //document.getElementById("floatContPosiConso").style.width = '0%';
    setTimeout(() => {
      this.lengthAh = this.ahorros.length
      this.datosAhorros = new MatTableDataSource(this.ahorros);
      this.datosAhorros.paginator = this.paginatorAhorros;

      this.lengthInv = this.inversiones.length
      this.datosInversiones = new MatTableDataSource(this.inversiones);
      this.datosInversiones.paginator = this.paginatorInversiones;

      this.lengthPre = this.prestamos.length
      this.datosPrestamos = new MatTableDataSource(this.prestamos);
      this.datosPrestamos.paginator = this.paginatorPrestamos;

      this.lengthPreA = this.prestamosA.length
      this.datosPrestamosA = new MatTableDataSource(this.prestamosA);
      this.datosPrestamosA.paginator = this.paginatorPrestamosA;

      this.datosPrestamosAA = this.prestamosAA;

      this.cambiarSkinApp()
    }, 100);
  }

  onTabClick(index) {
    this.tabIndex = index;
  }

  showHideRows(container, grupo, last) {
    if (last) {
      try {
        let classContainer1Width = document.getElementsByClassName(container)[0].clientWidth;
        if (grupo == '04') {
          if (this.grande) {
            document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width:680px');
            if (680 - classContainer1Width > 0) {
              document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL1) {
                document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 580px')
            if (580 - classContainer1Width > 0) {
              document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL1) {
                document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.normal) {
            document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 480px; font-size: 14px')
            if (480 - classContainer1Width > 0) {
              document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL1) {
                document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 100%; font-size: 14px')
            }
          }
        } else {
          if (grupo == '05') {
            if (this.grande) {
              document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 750px')
              if (750 - classContainer1Width > 0) {
                document.getElementById('flechaR2').setAttribute('style', 'visibility: visible;')
                if (!this.flechaL2) {
                  document.getElementById('flechaL2').setAttribute('style', 'visibility: visible;')
                }
              } else {
                document.getElementById('flechaR2').setAttribute('style', 'visibility: hidden;')
                document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 100%')
              }
            }
            if (this.mediano) {
              document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 650px')
              if (650 - classContainer1Width > 0) {
                document.getElementById('flechaR2').setAttribute('style', 'visibility: visible;')
                if (!this.flechaL2) {
                  document.getElementById('flechaL2').setAttribute('style', 'visibility: visible;')
                }
              } else {
                document.getElementById('flechaR2').setAttribute('style', 'visibility: hidden;')
                document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 100%')
              }
            }
            if (this.normal) {
              document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 550px; font-size: 14px')
              if (550 - classContainer1Width > 0) {
                document.getElementById('flechaR2').setAttribute('style', 'visibility: visible;')
                if (!this.flechaL2) {
                  document.getElementById('flechaL2').setAttribute('style', 'visibility: visible;')
                }
              } else {
                document.getElementById('flechaR2').setAttribute('style', 'visibility: hidden;')
                document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 100%; font-size: 14px')
              }
            }
          } else {
            if (grupo == '06') {
              if (this.grande) {
                document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 830px')
                if (830 - classContainer1Width > 0) {
                  document.getElementById('flechaR3').setAttribute('style', 'visibility: visible;')
                  if (!this.flechaL3) {
                    document.getElementById('flechaL3').setAttribute('style', 'visibility: visible;')
                  }
                } else {
                  document.getElementById('flechaR3').setAttribute('style', 'visibility: hidden;')
                  document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 100%')
                }
              }
              if (this.mediano) {
                document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 730px')
                if (730 - classContainer1Width > 0) {
                  document.getElementById('flechaR3').setAttribute('style', 'visibility: visible;')
                  if (!this.flechaL3) {
                    document.getElementById('flechaL3').setAttribute('style', 'visibility: visible;')
                  }
                } else {
                  document.getElementById('flechaR3').setAttribute('style', 'visibility: hidden;')
                  document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 100%')
                }
              }
              if (this.normal) {
                document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 630px; font-size: 14px')
                if (630 - classContainer1Width > 0) {
                  document.getElementById('flechaR3').setAttribute('style', 'visibility: visible;')
                  if (!this.flechaL3) {
                    document.getElementById('flechaL3').setAttribute('style', 'visibility: visible;')
                  }
                } else {
                  document.getElementById('flechaR3').setAttribute('style', 'visibility: hidden;')
                  document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 100%; font-size: 14px')
                }
              }
            } else if (grupo == 'preaprobados') {
              if (this.grande) {
                document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 830px')
                if (830 - classContainer1Width > 0) {
                  document.getElementById('flechaR4').setAttribute('style', 'visibility: visible;')
                  if (!this.flechaL4) {
                    document.getElementById('flechaL4').setAttribute('style', 'visibility: visible;')
                  }
                } else {
                  document.getElementById('flechaR4').setAttribute('style', 'visibility: hidden;')
                  document.getElementById('flechaL4').setAttribute('style', 'visibility: hidden;')
                  document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 100%')
                }
              }
              if (this.mediano) {
                document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 730px')
                if (730 - classContainer1Width > 0) {
                  document.getElementById('flechaR4').setAttribute('style', 'visibility: visible;')
                  if (!this.flechaL4) {
                    document.getElementById('flechaL4').setAttribute('style', 'visibility: visible;')
                  }
                } else {
                  document.getElementById('flechaR4').setAttribute('style', 'visibility: hidden;')
                  document.getElementById('flechaL4').setAttribute('style', 'visibility: hidden;')
                  document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 100%')
                }
              }
              if (this.normal) {
                document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 730px; font-size: 14px')
                if (730 - classContainer1Width > 0) {
                  document.getElementById('flechaR4').setAttribute('style', 'visibility: visible;')
                  if (!this.flechaL4) {
                    document.getElementById('flechaL4').setAttribute('style', 'visibility: visible;')
                  }
                } else {
                  document.getElementById('flechaR4').setAttribute('style', 'visibility: hidden;')
                  document.getElementById('flechaL4').setAttribute('style', 'visibility: hidden;')
                  document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 100%; font-size: 14px')
                }
              }
            }
          }
        }
      } catch (e) {
      }
    }
  }

  scroll(container, clase) {
    let tableContainer = document.getElementsByClassName(container)
    var tabla = document.getElementsByClassName(clase)
    for (var i = 0; i < tableContainer.length; i++) {
      if (tableContainer.item(i).scrollLeft != 0) {
        if (clase == 'ahorros' && i < tabla.length) {
          if (tableContainer[0].scrollLeft + 2 >= (tabla[0].clientWidth - tableContainer[0].clientWidth)) {
            this.flechaR1 = true;
            this.flechaL1 = false;
          } else {
            this.flechaR1 = false;
            this.flechaL1 = true;
          }
        }
        if (clase == 'pfijo') {
          if (tableContainer[0].scrollLeft + 2 >= (tabla[0].clientWidth - tableContainer[0].clientWidth)) {
            this.flechaR2 = true;
            this.flechaL2 = false;
          } else {
            this.flechaR2 = false;
            this.flechaL2 = true;
          }
        }
        if (clase == 'prestamos') {
          if (tableContainer[0].scrollLeft + 2 >= (tabla[0].clientWidth - tableContainer[0].clientWidth)) {
            this.flechaR3 = true;
            this.flechaL3 = false;
          } else {
            this.flechaR3 = false;
            this.flechaL3 = true;
          }
        }
        if (clase == 'preaprobados') {
          if (tableContainer[0].scrollLeft + 2 >= (tabla[0].clientWidth - tableContainer[0].clientWidth)) {
            this.flechaR4 = true;
            this.flechaL4 = false;
          } else {
            this.flechaR4 = false;
            this.flechaL4 = true;
          }
        }
      } else {
        this.flechaR1 = false;
        this.flechaL1 = true;
        this.flechaR2 = false;
        this.flechaL2 = true;
        this.flechaR3 = false;
        this.flechaL3 = true;
        this.flechaR4 = false;
        this.flechaL4 = true;
      }
    }
  }

  scrollTo(container, clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    let tableContainer = document.getElementsByClassName(container)
    if (direccion == 'right') {
      tableContainer.item(0).scrollLeft = tabla[0].clientWidth - tableContainer[0].clientWidth
    } else {
      tableContainer.item(0).scrollLeft = 0
      this.flechaR1 = false;
      this.flechaL1 = true;

    }
  }

  mostrarTransacciones(cuenta: any, subsistema: any, grupo: any) {
    this.deshabilitar = true;
    this.deshabilitarTransacciones = true;
    this.deshabilitarPre = false;
    this.deshabilitarBloqueos = false;
    this.deshabilitarBeneficiarios = false;
    this.deshabilitarConfirmar = false;
    this.deshabilitarPignorados = false;
    this.parametros = {
      'subsistema': subsistema,
      'cuenta': cuenta,
      'grupo': grupo
    }
    this.datos3 = [];
    for (let grupos of this.datos2) {
      for (let grupo of grupos) {
        if (grupo.ccuenta == cuenta) {
          if (grupo.subsistema == '04') {
            this.datos3[0] = {
              'ccuenta': grupo.ccuenta,
              'siglas': grupo.siglas,
              'cmoneda': grupo.cmoneda,
              'available': grupo.available,
              'outstanding': grupo.outstanding,
              'blocked': grupo.blocked,
              'accounting': grupo.accounting,
              'estado': grupo.estado,
              'pledged': grupo.pledged,
              'ccuentaetiqueta': grupo.ccuentaetiqueta,
              'subsistema': grupo.subsistema,
              'grupo': grupo.grupo,
              'interes': grupo.interes,
              'fechaCap': grupo.fechaCap,
              'tasa': grupo.tasa,
              'sobregiro': grupo.sobregiro,
              'dproducto': grupo.dproducto
            }
            break;
          }
          if (grupo.subsistema == '05') {
            this.datos3[0] = {
              'ccuenta': grupo.ccuenta,
              'siglas': grupo.siglas,
              'cmoneda': grupo.cmoneda,
              'monto': grupo.monto,
              'plazo': grupo.plazo,
              'tasa': grupo.tasa,
              'fvencimiento': grupo.fvencimiento,
              'cuotaInteres': grupo.cuotaInteres,
              'estado': grupo.estado,
              'ccuentaetiqueta': grupo.ccuentaetiqueta,
              'frecInteres': grupo.frecInteres,
              'interes': grupo.interes,
              'fPago': grupo.fPago,
              'cPagadas': grupo.cPagadas,
              'cPorPagar': grupo.cPorPagar,
              'fapertura': grupo.fapertura,
              'impuestos': grupo.impuestos,
              'dproducto': grupo.dproducto
            }
            break;
          }
          if (grupo.subsistema == '06') {
            this.datos3[0] = {
              'ccuenta': grupo.ccuenta,
              'siglas': grupo.siglas,
              'cmoneda': grupo.cmoneda,
              'capital': grupo.capital,
              'plazo': grupo.plazo,
              'tasa': grupo.tasa,
              'fvencimiento': grupo.fvencimiento,
              'actualBal': grupo.actualBal,
              'numCuotaPago': grupo.cuotaPago,
              'overdue': grupo.overdue,
              'proxpago': grupo.proxpago,
              'estado': grupo.estado,
              'frecuencia': grupo.frecuencia,
              'cuotasvencidas': grupo.cuotasvencidas,
              'diasmora': grupo.diasmora,
              'cuotaspendientes': grupo.cuotaspendientes,
              'payable': grupo.payable,
              'numerocuotas': grupo.numerocuotas,
              'ccuentaetiqueta': grupo.ccuentaetiqueta,
              'saldointeres': grupo.saldointeres,
              'saldomora': grupo.saldomora,
              'saldomoraSigCuota': grupo.saldomoraSigCuota,
              'valorpagar': grupo.valorpagar,
              'saldoVencido': grupo.saldoVencido,
              'cuentasPorCobrar': grupo.cuentasPorCobrar,
              'seguro': grupo.seguro,
              'dproducto': grupo.dproducto
            }
            break;
          }
        }
      }
    }

    for (let grupo of this.prestamosA) {
      if (grupo.spersonacreditospreaprobados == cuenta) {
        if (grupo.subsistema == 'PRESTAMOPREAPROBADO') {
          this.datos3[0] = {
            'cuenta': grupo.cuenta,
            'subsistemaproducto': grupo.subsistemaproducto,
            'cgrupoproducto': grupo.cgrupoproducto,
            'cproducto': grupo.cproducto,
            'cmoneda': grupo.cmoneda,
            'plazodesde': grupo.plazodesde,
            'plazohasta': grupo.plazohasta,
            'montodesde': grupo.montodesde,
            'montohasta': grupo.montohasta,
            'monto': grupo.monto,
            'plazo': grupo.plazo,
            'tasa': grupo.tasa,
            'estado': grupo.estado,
            'spersonacreditospreaprobados': grupo.spersonacreditospreaprobados,
            'fcontable': grupo.fcontable
          }
          break;
        }
      }
    }

    for (let grupo of this.prestamosAA) {
      if (grupo.spersonacreditospreaprobados == cuenta) {
        if (grupo.subsistema == 'PRESTAMOPREAPROBADO') {
          this.datos3[0] = {
            'cuenta': grupo.cuenta,
            'subsistemaproducto': grupo.subsistemaproducto,
            'cgrupoproducto': grupo.cgrupoproducto,
            'cproducto': grupo.cproducto,
            'cmoneda': grupo.cmoneda,
            'plazodesde': grupo.plazodesde,
            'plazohasta': grupo.plazohasta,
            'montodesde': grupo.montodesde,
            'montohasta': grupo.montohasta,
            'monto': grupo.monto,
            'plazo': grupo.plazo,
            'tasa': grupo.tasa,
            'estado': grupo.estado,
            'spersonacreditospreaprobados': grupo.spersonacreditospreaprobados,
            'fcontable': grupo.fcontable
          }
          break;
        }
      }
    }

    for (let dato of this.ctasocio) {
      if (dato.ccuenta == cuenta) {
        this.datos3[0] = {
          'ccuenta': dato.ccuenta,
          'siglas': dato.siglas,
          'cmoneda': dato.cmoneda,
          'available': dato.available,
          'outstanding': dato.outstanding,
          'blocked': dato.blocked,
          'accounting': dato.accounting,
          'estado': dato.estado,
          'pledged': dato.pledged,
          'ccuentaetiqueta': dato.ccuentaetiqueta,
          'subsistema': dato.subsistema,
          'grupo': dato.grupo,
          'interes': dato.interes,
          'fechaCap': dato.fechaCap,
          'tasa': dato.tasa,
          'sobregiro': dato.sobregiro,
          'dproducto': dato.dproducto
        }
        break;
      }
    }

  }

  mostrarTransaccionesPre(cuenta: any, subsistema: any, grupo: any, intentoPre: boolean) {
    this.intentoPre = intentoPre
    this.deshabilitarPre = true;
    this.deshabilitarTransacciones = true;
    this.deshabilitar = true;
    this.deshabilitarBloqueos = false;
    this.deshabilitarConfirmar = false;
    this.deshabilitarBeneficiarios = false;
    this.parametros = {
      'subsistema': subsistema,
      'cuenta': cuenta,
      'grupo': grupo
    }
    this.datos3 = [];
    if (!this.intentoPre) {
      for (let grupo of this.prestamosA) {
        if (grupo.spersonacreditospreaprobados == cuenta) {
          if (grupo.subsistema == 'PRESTAMOPREAPROBADO') {
            this.datos3[0] = {
              'cuenta': grupo.cuenta,
              'subsistemaproducto': grupo.subsistemaproducto,
              'cgrupoproducto': grupo.cgrupoproducto,
              'cproducto': grupo.cproducto,
              'cmoneda': grupo.cmoneda,
              'plazodesde': grupo.plazodesde,
              'plazohasta': grupo.plazohasta,
              'montodesde': grupo.montodesde,
              'montohasta': grupo.montohasta,
              'monto': grupo.monto,
              'plazo': grupo.plazo,
              'tasa': grupo.tasa,
              'estado': grupo.estado,
              'spersonacreditospreaprobados': grupo.spersonacreditospreaprobados,
              'fcontable': grupo.fcontable,
              'fvigencia': grupo.fvigencia
            }
            break;
          }
        }
      }
    }

    if (this.intentoPre) {
      for (let grupo of this.prestamosAA) {
        if (grupo.spersonacreditospreaprobados == cuenta) {
          if (grupo.subsistema == 'PRESTAMOPREAPROBADO') {
            this.datos3[0] = {
              'cuenta': grupo.cuenta,
              'subsistemaproducto': grupo.subsistemaproducto,
              'cgrupoproducto': grupo.cgrupoproducto,
              'cproducto': grupo.cproducto,
              'cmoneda': grupo.cmoneda,
              'plazodesde': grupo.plazodesde,
              'plazohasta': grupo.plazohasta,
              'montodesde': grupo.montodesde,
              'montohasta': grupo.montohasta,
              'monto': grupo.monto,
              'plazo': grupo.plazo,
              'tasa': grupo.tasa,
              'estado': grupo.estado,
              'spersonacreditospreaprobados': grupo.spersonacreditospreaprobados,
              'fcontable': grupo.fcontable,
              'fvigencia': grupo.fvigencia
            }
            break;
          }
        }
      }
    }
  }

  mostrarTransaccionesBloqueos(cuenta: any, subsistema: any, grupo: any) {
    this.deshabilitar = true;
    this.deshabilitarTransacciones = false;
    this.deshabilitarPre = false;
    this.deshabilitarBloqueos = true;
    this.deshabilitarConfirmar = false;
    this.deshabilitarPignorados = false;
    this.deshabilitarBeneficiarios = false;
    this.parametros = {
      'subsistema': "33", //Para Bloqueos aux
      'cuenta': cuenta,
      'grupo': grupo
    }
    this.datos3 = [];
    for (let grupos of this.datos2) {
      for (let grupo of grupos) {
        if (grupo.ccuenta == cuenta) {
          this.datos3[0] = {
            'ccuenta': grupo.ccuenta,
            'siglas': grupo.siglas,
            'dproducto': grupo.dproducto,
            'cmoneda': grupo.cmoneda,
            'available': grupo.available,
            'outstanding': grupo.outstanding,
            'blocked': grupo.blocked,
            'accounting': grupo.accounting,
            'estado': grupo.estado,
            'pledged': grupo.pledged,
            'ccuentaetiqueta': grupo.ccuentaetiqueta,
            'subsistema': grupo.subsistema,
            'grupo': grupo.grupo,
            'interes': grupo.interes,
            'fechaCap': grupo.fechaCap,
            'tasa': grupo.tasa,
            'sobregiro': grupo.sobregiro
          }
          break;
        }
      }
    }

    for (let dato of this.ctasocio) {
      if (dato.ccuenta == cuenta) {
        this.datos3[0] = {
          'ccuenta': dato.ccuenta,
          'siglas': dato.siglas,
          'dproducto': dato.dproducto,
          'cmoneda': dato.cmoneda,
          'available': dato.available,
          'outstanding': dato.outstanding,
          'blocked': dato.blocked,
          'accounting': dato.accounting,
          'estado': dato.estado,
          'pledged': dato.pledged,
          'ccuentaetiqueta': dato.ccuentaetiqueta,
          'subsistema': dato.subsistema,
          'grupo': dato.grupo,
          'interes': dato.interes,
          'fechaCap': dato.fechaCap,
          'tasa': dato.tasa,
          'sobregiro': dato.sobregiro
        }
        break;
      }
    }
  }

  mostrarTransaccionesConfirmar(cuenta: any, subsistema: any, grupo: any) {
    this.deshabilitar = true;
    this.deshabilitarTransacciones = false;
    this.deshabilitarPre = false;
    this.deshabilitarBloqueos = false;
    this.deshabilitarConfirmar = true;
    this.deshabilitarPignorados = false;
    this.deshabilitarBeneficiarios = false;
    this.parametros = {
      'subsistema': "77", //Para Saldos por Confirmar - aux
      'cuenta': cuenta,
      'grupo': grupo
    }
    this.datos3 = [];
    for (let grupos of this.datos2) {
      for (let grupo of grupos) {
        if (grupo.ccuenta == cuenta) {
          this.datos3[0] = {
            'ccuenta': grupo.ccuenta,
            'siglas': grupo.siglas,
            'cmoneda': grupo.cmoneda,
            'available': grupo.available,
            'outstanding': grupo.outstanding,
            'blocked': grupo.blocked,
            'accounting': grupo.accounting,
            'estado': grupo.estado,
            'pledged': grupo.pledged,
            'ccuentaetiqueta': grupo.ccuentaetiqueta,
            'subsistema': grupo.subsistema,
            'grupo': grupo.grupo,
            'interes': grupo.interes,
            'fechaCap': grupo.fechaCap,
            'tasa': grupo.tasa,
            'sobregiro': grupo.sobregiro,
            'dproducto': grupo.dproducto
          }
          break;
        }
      }
    }

    for (let dato of this.ctasocio) {
      if (dato.ccuenta == cuenta) {
        this.datos3[0] = {
          'ccuenta': dato.ccuenta,
          'siglas': dato.siglas,
          'cmoneda': dato.cmoneda,
          'available': dato.available,
          'outstanding': dato.outstanding,
          'blocked': dato.blocked,
          'accounting': dato.accounting,
          'estado': dato.estado,
          'pledged': dato.pledged,
          'ccuentaetiqueta': dato.ccuentaetiqueta,
          'subsistema': dato.subsistema,
          'grupo': dato.grupo,
          'interes': dato.interes,
          'fechaCap': dato.fechaCap,
          'tasa': dato.tasa,
          'sobregiro': dato.sobregiro,
          'dproducto': dato.dproducto
        }
        break;
      }
    }
  }

  mostrarTransaccionesPignorados(cuenta: any, subsistema: any, grupo: any) {
    this.deshabilitar = true;
    this.deshabilitarTransacciones = false;
    this.deshabilitarPre = false;
    this.deshabilitarBloqueos = false;
    this.deshabilitarConfirmar = false;
    this.deshabilitarPignorados = true;
    this.deshabilitarBeneficiarios = false;
    this.parametros = {
      'subsistema': "99", //Para Pignorados aux
      'cuenta': cuenta,
      'grupo': grupo
    }
    this.datos3 = [];
    for (let grupos of this.datos2) {
      for (let grupo of grupos) {
        if (grupo.ccuenta == cuenta) {
          this.datos3[0] = {
            'ccuenta': grupo.ccuenta,
            'siglas': grupo.siglas,
            'cmoneda': grupo.cmoneda,
            'available': grupo.available,
            'outstanding': grupo.outstanding,
            'blocked': grupo.blocked,
            'accounting': grupo.accounting,
            'estado': grupo.estado,
            'pledged': grupo.pledged,
            'ccuentaetiqueta': grupo.ccuentaetiqueta,
            'subsistema': grupo.subsistema,
            'grupo': grupo.grupo,
            'interes': grupo.interes,
            'fechaCap': grupo.fechaCap,
            'tasa': grupo.tasa,
            'sobregiro': grupo.sobregiro,
            'dproducto': grupo.dproducto
          }
          break;
        }
      }
    }

    for (let dato of this.ctasocio) {
      if (dato.ccuenta == cuenta) {
        this.datos3[0] = {
          'ccuenta': dato.ccuenta,
          'siglas': dato.siglas,
          'cmoneda': dato.cmoneda,
          'available': dato.available,
          'outstanding': dato.outstanding,
          'blocked': dato.blocked,
          'accounting': dato.accounting,
          'estado': dato.estado,
          'pledged': dato.pledged,
          'ccuentaetiqueta': dato.ccuentaetiqueta,
          'subsistema': dato.subsistema,
          'grupo': dato.grupo,
          'interes': dato.interes,
          'fechaCap': dato.fechaCap,
          'tasa': dato.tasa,
          'sobregiro': dato.sobregiro,
          'dproducto': dato.dproducto
        }
        break;
      }
    }
  }

  retornar() {
    this.deshabilitar = false;
    this.deshabilitarPre = false;
    this.parametros = null;
    if (this.datos2) {
      for (var i = 0; i < this.datos2.length; i++) {
        this.flechaR[i] = false;
        this.flechaL[i] = true;
      }
    }
    this.pageIndexAh
    this.txDesdeTx = false
    if (!this.bandMobile) {
      document.getElementById("floatContPosiConso").style.right = '0%';
    }

    setTimeout(() => {
      this.lengthAh = this.ahorros.length
      this.datosAhorros = new MatTableDataSource(this.ahorros);
      this.datosAhorros.paginator = this.paginatorAhorros;

      this.lengthInv = this.inversiones.length
      this.datosInversiones = new MatTableDataSource(this.inversiones);
      this.datosInversiones.paginator = this.paginatorInversiones;

      this.lengthPre = this.prestamos.length
      this.datosPrestamos = new MatTableDataSource(this.prestamos);
      this.datosPrestamos.paginator = this.paginatorPrestamos;

      this.lengthPreA = this.prestamosA.length
      this.datosPrestamosA = new MatTableDataSource(this.prestamosA);
      this.datosPrestamosA.paginator = this.paginatorPrestamosA;

    }, 100);
  }

  retornarMob() {
    window.dispatchEvent(new Event('resize'));
    this.deshabilitar = false;
    this.deshabilitarPre = false;
    this.deshabilitarBeneficiarios = false;
    this.parametros = null;
    if (this.datos2) {
      for (var i = 0; i < this.datos2.length; i++) {
        this.flechaR[i] = false;
        this.flechaL[i] = true;
      }
    }
    this.pageIndexAh
    this.txDesdeTx = false
  }

  mostrarSaldosDesdeTx(event) {
    this.retornar()
    switch (event.idSaldo) {
      case "33": { //Para Saldos Bloqueados
        this.txDesdeTx = true
        this.mostrarTransaccionesBloqueos(event.cuenta, event.subsistema, event.grupo)
      } break;
      case "77": { //Para Saldos por Confirmar
        this.txDesdeTx = true
        this.mostrarTransaccionesConfirmar(event.cuenta, event.subsistema, event.grupo)
      } break;
      case "99": { //Para Saldos Pignorados
        this.txDesdeTx = true
        this.mostrarTransaccionesPignorados(event.cuenta, event.subsistema, event.grupo)
      } break;
    }
  }

  // Dynamically set the SVG elements' class to change their color depending on the request status
  getStateColor(status) {
    if (status == 'ACTIVA') {
      return 'green-svg';
    } else {
      return 'red-svg';
    }
  }

  actualizarTransacciones06(event) {
    this.txDesdeTx = true
    this.mostrarTransacciones(event.cuenta, event.subsistema, event.grupo);
  }

  mostrarDialogo(data, producto) {
    this.alerta.generarDialogoDetalleCuentas(this.idiomas, data, producto, this.grande, this.mediano, this.normal, false);
  }

  enviarRespuestaN(spersonacreditospreaprobados) {
    var envio = {
      transaccion: '187019-con-negarCreditoWS.xml',
      usuario: this.base.usuario
    }
    envio['ctl^SECUENCIAL'] = spersonacreditospreaprobados;
    envio['ctl^CPERSONA'] = '$personCode';
    this.alerta.generarDialogoSeguroPreaprobado(this.idiomas, this.idiomas.TexoNoAcepta).then((data) => {
      if (data) {
        this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
          this.retornarPosicion();
        }, (err) => {
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            }
            else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          } else { //Sin Internet
            this.alerta.presentarAlerta(this.idiomas.ServidorError)
            this.base.insertarTarea(envio);
          }
        })
      } else {
      }
    });
  }

  shareAccountData(data) {
    let loginInfo = this.base.retornarConsulta("loginInfo")
    var accountData = this.idiomas.nombres + ": " + loginInfo.nombrePreferido
      + "\n" + this.idiomas.Cuenta + ": " + data.ccuenta
      + "\n" + this.idiomas.Producto + ": " + data.dproducto
      + "\n" + this.idiomas.correoElectronico + ": " + loginInfo.email
    this.ngNavigatorShareService.share({
      title: "",
      text: accountData
    }).then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error));
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  retornarPosicion() {
    this.noMuestraGrafico = true;
    this.deshabilitar = false;
    this.parametros = null;
    if (this.datos2) {
      for (var i = 0; i < this.datos2.length; i++) {
        this.flechaR[i] = false;
        this.flechaL[i] = true;
      }
    }
    this.txDesdeTx = false;
    this.updatePosCons.emit();
  }

  mostrarTranferencias(cuenta: string) {
    this.deshabilitar = false;
    this.deshabilitarPre = false;
    this.deshabilitarBeneficiarios = true;
    let data = {
      transaction: "Beneficiarios",
      account: cuenta
    }
    this.operationEvent.emit(data);
  }


}