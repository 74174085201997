<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch">

    <mat-card *ngIf="!tareas || tareas.length<=0" class="carta tareas">
        <div class="row">
            <div class="col card-header">
                <div class="center texto">
                    <h6><strong>{{idiomas.TarPend | uppercase}}</strong></h6>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="center col texto">
                <mat-label>
                    {{idiomas.NoTieneTareasPend | uppercase}}
                </mat-label>
            </div>
        </div>
        <br>
    </mat-card>

    <div *ngFor="let tarea of tareas ; let i = index;">
        <mat-card *ngIf="tarea.tarPendType=='compraEcommerce'" class="carta tareas">
            <h6 class="center">
                <strong>{{idiomas.COMPRAS | uppercase}}</strong>
            </h6>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th class="rigth">{{idiomas.Producto}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.producto}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="rigth">{{idiomas.Cuenta}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.cuenta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="rigth">{{idiomas.Cantidad}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.cantidad}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="rigth">{{idiomas.VUnitario}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.valorU | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="right">{{idiomas.Total}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.valorT | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div class="justify-content-between d-flex">

                <button type="button" [disabled]="!internet.internet" class="btn btn-success boton" (click)="buy(i)"
                    color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>
    </div>
</div>