<div class="example-form" style="padding-top: 2% !important; padding-bottom: 60px;" fxLayout="column"
    fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.PrestamoPreaprobado | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div *ngIf=intento>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf=mostrarMensaje class="center col texto">
                <div style="text-align: center;">
                    <img src="assets/resources/mipmap-hdpi/proximamente.png"  class="imagen3" priority />
                    <!-- <strong>{{idiomas.MensajeNoPreaprobado}}</strong> -->
                </div>
            </div>
            <div *ngIf="!deshabilitar&&mostrarInfo">
                <div *ngFor="let grupo of datos2; let i=index">
                    <div *ngIf="grupo[0].subsistema == 'PRESTAMOPREAPROBADO'">
                        <div class="row" style="margin-left: -24px !important;" *ngIf="!bandMobile">
                            <div class="col flecha" style="margin-right: 4px;">
                                <mat-icon [class.desaparecer]=flechaL id="flechaL"
                                    (click)="scrollTo('preaprobados','left')">
                                    keyboard_arrow_left</mat-icon>
                            </div>
                            <div class="col container1Aux" (scroll)="scroll('preaprobados')"
                                [class.heightconteiner1]="(grupo.length>numMaxRow)">
                                <table mat-table [dataSource]="grupo" class="preaprobados" id="tabla">
                                    <ng-container matColumnDef="Producto">
                                        <th mat-header-cell *matHeaderCellDef>{{idiomas.Producto}}</th>
                                        <td mat-cell *matCellDef="let dato ; let i=index" class="cuenta">
                                            <a *ngIf="dato.cproducto != '06'">{{idiomas.PrestamoPreaprobadoT}}</a>
                                            <a *ngIf="dato.cproducto == '06'">{{dato.cuenta}}</a>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Moneda">
                                        <th mat-header-cell *matHeaderCellDef>{{idiomas.Moneda}}</th>
                                        <td mat-cell *matCellDef="let dato" class="producto">{{dato.cmoneda}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Montomin">
                                        <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Montomin}}</th>
                                        <td mat-cell *matCellDef="let dato" class="numeros"> {{dato.montodesde |
                                            cantidadMonetaria}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Montomax">
                                        <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Montomax}}</th>
                                        <td mat-cell *matCellDef="let dato" class="numeros">{{dato.montohasta |
                                            cantidadMonetaria}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Plazomin">
                                        <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Plazomin}}</th>
                                        <td mat-cell *matCellDef="let dato" class="tasa">
                                            {{(dato.plazodesde/30)|plazoMeses}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Plazomax">
                                        <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Plazomax}}</th>
                                        <td mat-cell *matCellDef="let dato" class="tasa">{{(dato.plazohasta/30) |
                                            plazoMeses}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Tasa">
                                        <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Tasa}}</th>
                                        <td mat-cell *matCellDef="let dato" class="fechas left">{{dato.tasa}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Fvigencia">
                                        <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Fvigencia}}</th>
                                        <td mat-cell *matCellDef="let dato" class="fechas left"
                                            style="min-width: 80px !important;">{{dato.fvigencia}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Estado">
                                        <th mat-header-cell *matHeaderCellDef class="left"></th>
                                        <td mat-cell *matCellDef="let dato" class="center">
                                            {{idiomas.Aceptar}}
                                            <mat-icon style="cursor: pointer !important; padding-top: 5px;"
                                                (click)="mostrarTransacciones(dato.spersonacreditospreaprobados,dato.subsistema,'07')">
                                                download_done</mat-icon>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Rechazar">
                                        <th mat-header-cell *matHeaderCellDef class="left"></th>
                                        <td mat-cell *matCellDef="let dato" class="center">
                                            {{idiomas.RechazarPreApro}}
                                            <mat-icon style="cursor: pointer !important; padding-top: 5px;"
                                                (click)="enviarRespuestaN(dato.spersonacreditospreaprobados)">
                                                clear</mat-icon>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsPrestamosPreaprobados"
                                        class="mat-header-row">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let row; let even = even; let last = last;columns: displayedColumnsPrestamosPreaprobados;"
                                        [ngClass]="{gray: even}">{{showHideRows(last)}}</tr>
                                </table>
                            </div>
                            <div class="col flecha" style="margin-left: 4px;">
                                <mat-icon [class.desaparecer]=flechaR id="flechaR"
                                    (click)="scrollTo('preaprobados','right')">
                                    keyboard_arrow_right</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="deshabilitar">
                <app-transacciones-preaprobado [deshabilitarTransacciones]="deshabilitarTransacciones"
                    [parametros]="parametros" [data]="datos3" [txDesdeTx]="txDesdeTx" [idiomas]="idiomas"
                    [mediano]="mediano" [normal]="normal" [grande]="grande">
                </app-transacciones-preaprobado>
            </div>
            <div *ngIf="deshabilitar && !bandMobile" class="float-transacciones">
                <button mat-fab class="float-right" title={{idiomas.Regresar}} color="primary" (click)="retornar()">
                    <mat-icon class="white">arrow_back</mat-icon>
                </button>
            </div>
        </div>
    </mat-card>
    <div *ngIf="bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.PrestamoPreaprobado | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding-top:16px">
            <div *ngIf=intento>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf=mostrarMensaje class="center col texto">
                <div style="text-align: center;">
                    <img src="assets/resources/mipmap-hdpi/proximamente.png"  class="imagen3" priority/>
                    <!-- <strong>{{idiomas.MensajeNoPreaprobado}}</strong> -->
                </div>
            </div>
            <div *ngIf="!deshabilitar&&mostrarInfo">
                <div *ngFor="let grupo of datos2; let i=index">
                    <div *ngIf="grupo[0].subsistema == 'PRESTAMOPREAPROBADO'">
                        <div *ngIf="bandMobile">
                            <section class="policy-flechas">
                                <div *ngIf="grupo.length > 0" style="padding-top: 3px;">
                                    <div>
                                        <owl-carousel-o [options]="customCarouselOptions">
                                            <ng-container *ngFor="let dato of grupo">
                                                <ng-template carouselSlide>
                                                    <mat-card class="cards-mobile-accounts">
                                                        <div class="form-row">
                                                            <a *ngIf="dato.cproducto != '06'"
                                                                class="col-8 color-letras-cartas"
                                                                style="font-size: 1.1em;">
                                                                {{idiomas.PrestamoPreaprobadoT}}</a>
                                                            <a *ngIf="dato.cproducto == '06'"
                                                                class="col-8 color-letras-cartas"
                                                                style="font-size: 1.1em;">
                                                                {{dato.cuenta}}</a>
                                                            <div class="col-2" style="text-align: end;">
                                                                <div class="color-letras-cartas">
                                                                    <mat-icon
                                                                        style="cursor: pointer !important; font-size: 30px;"
                                                                        (click)="enviarRespuestaN(dato.spersonacreditospreaprobados)">
                                                                        clear</mat-icon>
                                                                </div>
                                                            </div>
                                                            <div class="col-2" style="text-align: end;">
                                                                <div class="color-letras-cartas"
                                                                    style="padding-bottom: 10px !important;">
                                                                    <mat-icon
                                                                        style="cursor: pointer !important; font-size: 30px;"
                                                                        (click)="mostrarTransacciones(dato.spersonacreditospreaprobados,dato.subsistema,'07')">
                                                                        download_done</mat-icon>
                                                                </div>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;">
                                                                    {{idiomas.Montomin}}</p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;"
                                                                    class="color-letras-cartas">
                                                                    ${{dato.montodesde
                                                                    |
                                                                    cantidadMonetaria}}</p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;">
                                                                    {{idiomas.Montomax}}</p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;"
                                                                    class="color-letras-cartas">
                                                                    ${{dato.montohasta
                                                                    | cantidadMonetaria}}</p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;">
                                                                    {{idiomas.Plazomin}}</p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;"
                                                                    class="color-letras-cartas">
                                                                    {{(dato.plazodesde/30) | plazoMeses}}
                                                                </p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;">
                                                                    {{idiomas.Tasa}}</p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;"
                                                                    class="color-letras-cartas">
                                                                    %{{dato.tasa}}
                                                                </p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;">
                                                                    {{idiomas.FvigenciaC}}</p>
                                                            </div>
                                                            <div class="col-6" style="height: fit-content !important;">
                                                                <p style="font-size: .9em; margin-bottom: 0;"
                                                                    class="color-letras-cartas">
                                                                    {{dato.fvigencia}}</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </ng-template>&nbsp;
                                            </ng-container>
                                        </owl-carousel-o>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="deshabilitar">
                <app-transacciones-preaprobado [deshabilitarTransacciones]="deshabilitarTransacciones"
                    [parametros]="parametros" [data]="datos3" [txDesdeTx]="txDesdeTx" [idiomas]="idiomas"
                    [mediano]="mediano" [normal]="normal" [grande]="grande">
                </app-transacciones-preaprobado>
            </div>
            <div *ngIf="deshabilitar && bandMobile" class="float-transacciones-mob">
                <button class="btn btnGrafico" title={{idiomas.Regresar}} color="primary" (click)="retornar()">
                    <mat-icon class="white">arrow_back</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>