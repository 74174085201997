import { AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormControlValidators } from '../funciones/formcontrol';
import { ApiService } from '../api.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CantidadMonetariaTransferencias, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { User } from '../interfaces/user';
import { ServerMockService } from '../services/server-mock.service';
import { WebAuthnService } from '../services/web-authn.service';
import { Servicios } from '../funciones/encryptar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatDialogModule, CommonModule, ReactiveFormsModule, FormsModule
    , FlexLayoutModule, MatProgressSpinnerModule, AlertasLoginComponent, FormatoFechaCalendar, OcultarCuentaPosTrans, CantidadMonetariaTransferencias],
  selector: 'app-dialogo-solicitar-autorizacion',
  templateUrl: './dialogo-solicitar-autorizacion.component.html',
  styleUrls: ['./dialogo-solicitar-autorizacion.component.css']
})
export class DialogoSolicitarAutorizacionComponent implements AfterViewInit {

  grande: boolean;
  mediano: boolean;
  idiomas: any;
  spinner: boolean = false;
  bandMobile: boolean;
  data: any;
  softToken: string;
  error: boolean = false;
  msjerror: string = "";
  interval: any;
  
  //Validacion por credenciales biometricas
  hasBiometricCredentials: boolean = false;
  intentoLoginWBiometricCredentials: boolean = false;
  users: User[];
  user: string;
  usuario: string;
  usuarioCredntialBiometric:any;
  
  validPin: boolean = false;
  enterPinForm:boolean = false;
  digits: any[] = [
    { id: 1, field: this.validators.numberForm() },
    { id: 2, field: this.validators.numberForm() },
    { id: 3, field: this.validators.numberForm() },
    { id: 4, field: this.validators.numberForm() },
    { id: 5, field: this.validators.numberForm() },
    { id: 6, field: this.validators.numberForm() },
  ];

  constructor(
    private dialogRef: MatDialogRef<DialogoSolicitarAutorizacionComponent>,
     @Inject(MAT_DIALOG_DATA) data, 
     public validators: FormControlValidators,
    private apiService: ApiService, 
    private renderer: Renderer2,
    private serverMockService: ServerMockService,
    public servicios: Servicios,
    private webAuthnService: WebAuthnService
  ) {
    this.idiomas = data.idm;
    this.grande = data.grande;
    this.mediano = data.mediano;
    this.data = data.info;
    this.softToken = data.tokenInfo;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
    this.spinner = this.bandMobile ? false : true;
    this.hasBiometricCredentials = localStorage.getItem('users') != null ? true : false;
    this.users = serverMockService.getUsers();
    this.enterPinForm = false;
  }

  ngOnInit() {
    if (!this.bandMobile) {
      this.getSolicitudesMob()
    }
    this.hasBiometricCredentials = localStorage.getItem('users') != null ? true : false;
    this.enterPinForm = false;
  }

  ngAfterViewInit() {
    if (this.bandMobile) {
      setTimeout(() => { this.renderer.selectRootElement('#myInput0').focus() }, 1)
    }
  }

  enableButton() {
    var i = 0
    for (let digit of this.digits) {
      if (digit.field.valid && (digit.field.value != '' || digit.field.value)) {
        i = i + 1
      }
    }
    if (i == this.digits.length) {
      this.validPin = true
    } else {
      this.validPin = false
    }
  }

  blockText(event) {
    if (isNaN(event.key) && event.key !== 'Tab' && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  focusNext(i, event) {
    if (i < this.digits.length - 1 && event.key !== 'Tab' && event.key !== 'Backspace' && (!isNaN(event.key) || event.keyCode === 229) && !isNaN(this.digits[i].field.value)) {
      this.renderer.selectRootElement('#myInput' + (i + 1)).focus();
    }

    if (i == 5 && event.key === 'Tab') {
      event.preventDefault();
    }

    if (i > 0 && event.key === 'Backspace') {
      this.digits[i].field.reset();
      this.renderer.selectRootElement('#myInput' + (i - 1)).focus();
    }
  }

  changeValue(i, event) {
    if (!isNaN(event.key)) {
      this.digits[i].field.setValue(event.key);
    }
  }

  getSolicitudesMob() {
    let request = {
      "userHB": this.data.userCode
    }
    this.interval = setInterval(() => {
      this.apiService.noTokenProviderInternetCheck(request, '/consultaSolicitudMob').then((data: any) => {
        if (data) {
          var datev = new Date(data.fvigencia).getTime()
          var date = new Date(data.factual).getTime()
          if (data.status == 'AUT' && (datev >= date)) {
            this.spinner = false
            clearInterval(this.interval)
            this.dialogRef.close(true)
          }

          if (data.status == '') {
            clearInterval(this.interval)
            this.dialogRef.close(false)
          }
        } else {
          clearInterval(this.interval)
          this.dialogRef.close(false)
        }
      }, (err) => {
        this.spinner = false
        clearInterval(this.interval)
        if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
          if (err.error) {
            this.error = true
            this.msjerror = err.error.mensaje
          } else {
            this.error = true
            this.msjerror = this.idiomas.ServidorError
          }
        }
      });
    }, 3000)
  }

  sendPin() {
    this.spinner = true
    var pin = this.digits[0].field.value + this.digits[1].field.value + this.digits[2].field.value + this.digits[3].field.value + this.digits[4].field.value + this.digits[5].field.value
    var token = localStorage.getItem('deviceLinked') !== null ? localStorage.getItem('deviceLinked') : ""
    if (token != "") {
      this.softToken = token
    }
    let request = {
      transaccion: "010181-sig-in.xml",
      userCode: this.softToken.split(';')[0],
      "ctl^CUSUARIO": this.softToken.split(';')[0],
      "ctl^NOMBREDISPOSITIVO": this.softToken.split(';')[1],
      "ctl^NUMEROTELEFONO": this.softToken.split(';')[2],
      "ctl^BIOMETRICVALIDATION": '0',
      "ctl^PIN": pin
    }
    this.apiService.noTokenProviderInternetCheck(request, '/seguridades').then(() => {
      this.spinner = false
      this.dialogRef.close(true)
    }, (err) => {
      this.spinner = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          this.error = true
          this.msjerror = err.error.mensaje
        } else {
          this.error = true
          this.msjerror = this.idiomas.ServidorError
        }
      }
    });
  }

  close() {
    this.spinner = false
    if (!this.bandMobile) {
      clearInterval(this.interval)
      this.dialogRef.close(false)
    } else {
      this.dialogRef.close()
    }
  }

  webAuthVerifyUserPassw() {
    this.intentoLoginWBiometricCredentials = true
    const usersBase = localStorage.getItem('users');
    this.users = this.serverMockService.getUsers();
    if (usersBase != null) {
      this.user = this.servicios.decryptAEStoString(this.users[0]['username'])
      this.webAuthSigninWUserPasswd(this.user)
    }
  }

  webAuthSigninWUserPasswd(usuario) {
    const user = this.serverMockService.getUser(usuario);
    this.usuarioCredntialBiometric = usuario
    this.webAuthnService.webAuthnSignin(user).then((response) => {
      // TODO: validar attestion (esto debe ser algo del REST)
      this.usuario = this.servicios.decryptAEStoString(user.username);
      this.sendBiometricValidation()
    }).catch((error) => {
      this.spinner = false
      console.log(error)
    });
  }

  sendBiometricValidation() {
    this.spinner = true
    var token = localStorage.getItem('deviceLinked') !== null ? localStorage.getItem('deviceLinked') : ""
    if (token != "") {
      this.softToken = token
    }
    let request = {
      transaccion: "010181-sig-in.xml",
      userCode: this.softToken.split(';')[0],
      "ctl^CUSUARIO": this.softToken.split(';')[0],
      "ctl^NOMBREDISPOSITIVO": this.softToken.split(';')[1],
      "ctl^NUMEROTELEFONO": this.softToken.split(';')[2],
      "ctl^BIOMETRICVALIDATION": '1',
      "ctl^PIN": '000000'
    }
    this.apiService.noTokenProviderInternetCheck(request, '/seguridades').then(() => {
      this.spinner = false
      this.dialogRef.close(true)
    }, (err) => {
      this.spinner = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          this.error = true
          this.msjerror = err.error.mensaje
        } else {
          this.error = true
          this.msjerror = this.idiomas.ServidorError
        }
      }
    });
  }

  activePIN(){
    this.enterPinForm= true;
  }
}
