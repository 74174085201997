import { Component, OnInit, Input, ɵConsole, DoCheck } from '@angular/core';
import { UntypedFormControl, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { FuncionesTabla } from '../funciones/funcionesTabla';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFecha, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { TemporizadorComponent } from '../temporizador/temporizador.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatDividerModule, MatTableModule, MatInputModule, MatCheckboxModule, MatProgressBarModule, MatExpansionModule, MatListModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-solicitud-plazo',
  templateUrl: './solicitud-plazo.component.html',
  styleUrls: ['./solicitud-plazo.component.css']
})

export class SolicitudPlazoComponent implements OnInit {


  @Input() idiomas: any;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() pendingTaskData: any;
  @Input() nomServ: string;

  //Variables
  intento: boolean;
  selectedAccount: any;
  selectedAgency: any = '';
  montoPrestamo = new UntypedFormControl('');
  mostrarDatos: boolean = false;
  mostrarDatosListado: boolean = false;
  data: any;
  cuentas: any;
  fdesde = new UntypedFormControl('');
  numeroDias: any
  tasa: number
  fvencimiento: any
  interesFinal: number
  solicitud = new UntypedFormControl('');
  ccuenta = new UntypedFormControl('');
  ccuentaInversion = new UntypedFormControl('');
  mostrarDatosS: boolean = false;
  accountS: boolean = false;
  mostrarCuenta: boolean = false;
  mostrarSucursal: boolean = false;
  mostrarTerminos: boolean = false;
  checkedC: boolean = false;
  itemTableSelected: boolean = false;
  tipo: any;
  accounts: any;
  agencies: any;
  originalAgencies: any;
  plazoN = new UntypedFormControl('');
  vencimientoN: any;
  tasaN: any;
  interesN: any;
  impuestoN: any;
  netoN: any;
  dia: any;
  flechaR: boolean;
  flechaL: boolean;
  flechaR1: boolean;
  flechaL1: boolean;
  displayedColumnsTabs: any;
  displayedColumnsTabs2: any;
  displayedColumns: any
  datos: any;
  datosNuevo: any;
  datosNuevoR: any;
  numMaxRow: number;
  numMaxRowMob: number;
  contadorN: number;
  mostrarTabla: boolean;
  mostrarSolicitud: boolean;
  tabla: any = [];
  tablaPagos: any = [];
  infoTasa: any = [];
  intereses: number;
  impuesto: number;
  obtenerPlazo: any
  tokenFormControl = this.validators.otpForm();
  tokenSolicitado: boolean;
  deshabilitar: boolean = false;
  mantenimiento: boolean = true;
  intentop: boolean;
  generaDocumento: boolean = false;
  pdf: string = "";
  seleccionTabla: string = "";
  indiceTabla: any = 0;
  mostrarCro: boolean = false;
  enableCronoIn: boolean = false;
  tabla2Seleccionada: boolean = false;
  envioResp: string = "0";
  bandMobile: boolean;
  disableButton: boolean;
  secuecial: any = "";
  deshabilitarL: boolean = true;
  montoMaximo: any
  capitalIngresado: number;
  frecuenciaSelected: String = '';
  calculateDisabled = true;
  hiddenCalculate = false;

  constructor(
    private api: ApiService,
    public alert: Alerta,
    private service: Servicios,
    private dbHandler: BaseDatos,
    public internet: internetComponent,
    public validators: FormControlValidators,
    public mostrarPdf: MostrarPDF,
    private funcionesTabla: FuncionesTabla) {
    this.intento = true;
    this.mostrarTabla = false;
    this.montoPrestamo.setValue(0);
    this.data = [];
    this.flechaR = false;
    this.flechaL = true;
    this.flechaR1 = false;
    this.flechaL1 = true;
    this.displayedColumnsTabs = ['escoger', 'capital', 'tasa', 'interes', 'impuesto', 'valorRecibir']
    this.displayedColumnsTabs2 = ['escoger', 'plansugerido', 'fvencimiento', 'tasa', 'valorRecibir']
    this.displayedColumns = ['numero', 'fecha', 'interes', 'capital']
    this.datos = [];
    this.datosNuevo = [];
    this.datosNuevoR = [];
    this.numMaxRow = 1;
    this.numMaxRowMob = 5;
    this.contadorN = 1;
    this.mostrarSolicitud = false;
    this.mostrarCuenta = false;
    this.obtenerPlazo = "no";
    this.intentop = false;
    this.envioResp = "0";
    this.tabla2Seleccionada = false;
    this.mostrarCro = false;
    this.enableCronoIn = false;
    this.indiceTabla = 0;
    this.tabla = [];
    this.tablaPagos = [];
    this.infoTasa = [];
    this.deshabilitar = false;
    this.mantenimiento = true;
    this.disableButton = false;
  }

  referenceFormControl = this.validators.numberForm();
  rechargeFormControl = this.validators.decimalsForm();
  accountFormControl = this.validators.comboForm();
  agencyFormControl = this.validators.comboForm();
  descriptionFormControl = new UntypedFormControl(null, Validators.required);
  suministroForm = this.validators.textNumberForm();
  tipoCuota = new UntypedFormControl('')

  tCuota = [
    { id: '0', descripcion: 'AL VENCIMIENTO' },
    { id: '5', descripcion: 'MENSUAL' }
  ]

  ngOnInit() {
    this.retornarDatosPlazo();
    this.mostrarDatosS = false;
    this.intento = true;
    this.tokenSolicitado = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  reporteTerminos() {
    const envioMensaje = '';
    this.alert.generarDialogoInversion(this.idiomas.PlazoFijoA, this.idiomas, envioMensaje, 'mostrar').then((data) => {
      if (data) {
      } else {
        this.intento = false;
      }
    })

  }

  solicitarToken() {
    const envio = { transaccion: '182003' };
    this.intento = true;
    this.api.postProvider2InternetCheck('/clavetemporal', this.dbHandler.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.mantenimiento = true;
      this.accountS = true;
      this.tokenFormControl.reset();
      this.tokenFormControl.enable();
      this.alert.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
    this.mostrarCro = true;
    this.enableCronoIn = true;
  }

  terminosC(evento) {
    if (evento.checked == true) {
      this.tokenFormControl.reset();
      this.checkedC = true
    } else {
      this.checkedC = false;
      this.tokenFormControl.reset();
    }
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace(/,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0]
          let decimalPart = amountArrayDotSplit[1]
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  retornarDatosPlazo() { //Servicios Disponibles para Registrar
    this.contadorN = 1;
    this.vencimientoN = ""
    this.tasaN = ""
    this.interesN = ""
    this.impuestoN = ""
    this.netoN = ""
    this.tCuota = [
      { id: '0', descripcion: 'AL VENCIMIENTO' },
      { id: '5', descripcion: 'MENSUAL' }
    ]
    this.datos = []
    this.intento = true;
    this.mostrarDatosS = false;
    this.mostrarDatosListado = false;
    this.mostrarTabla = false;
    this.mostrarCuenta = false;
    this.accountS = false;
    this.mostrarSucursal = false;
    this.mostrarTerminos = false;
    this.checkedC = false;
    this.deshabilitar = false;
    this.tokenSolicitado = false;
    this.accountFormControl.enable();
    this.tokenFormControl.reset()
    let envio = {
      transaccion: "010003-lv-plazo-datos.xml",
      alias: 'lov',
      'cri^tcp^CTRANSACCION^NORMAL': '2003'
    }
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, envio).then((data: any) => {
      if (data) {
        this.data = data;
        this.montoMaximo = this.data[0].VALORNUMERICO
        this.montoPrestamo = new UntypedFormControl("", [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(this.data[0].MONTOMINIMO), maxAmountValidationNumber(Number(this.montoMaximo))]);
        this.plazoN = new UntypedFormControl("", [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(this.data[0].PLAZOMINIMO), maxAmountValidationNumber(Number(this.data[0].PLAZOMAXIMO))]);
        this.mostrarDatosS = true;
        this.intento = false;

      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alert.presentarAlertaX(err.error.mensajeUsuario);
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }
  logout() {
    this.alert.clearAlerta();
    this.alert.generarOfflineDialogo({ 'salir': true }, this.idiomas);
  }


  simuladorPlazo() {
    let pedido = {
      'cpersona': this.dbHandler.cpersona,
      'monto': String(this.montoPrestamo.value.replace(/,/g, '')),
      'plazo': this.numeroDias,
      'tasa': this.infoTasa[0].MARGEN,
      'tipoMV': this.tipo == "mensual" ? 'mensual' : 'vencimiento',
      'numeroCuota': this.tipo == "mensual" ? Math.round(Number(this.numeroDias) / 30) : 1,
      'numeroDias': this.tipo == "mensual" ? '30' : '0',
      'user': "$userCode",
      'fcontable': this.data[0].FCONTABLE.substring(0, 10),
      'grupo': this.data[0].CGRUPOPRODUCTO,
      'producto': this.data[0].CPRODUCTO,
      'moneda': this.data[0].CMONEDA
    }

    this.api.postProvider2InternetCheck("/simuladorPlazo", this.dbHandler.id_token, pedido).then((data: any) => {
      if (data) {
        this.tablaPagos = data.TSOLICITUDCUOTAS;
        let cuotas = (this.numeroDias / 30).toFixed(0);
        let dias = this.numeroDias;
        let porcentaje = Number(this.impuesto) > 0 ? 0.02 : 0;
        let interes = 0;
        let impuesto = 0;
        for (var i = 0; i < this.tablaPagos.length; i++) {
          interes = interes + Number.parseFloat(this.tablaPagos[i].INTERES);
          let valorPorcentaje = (Number(porcentaje) * Number.parseFloat(this.tablaPagos[i].INTERES)).toFixed(2);
          impuesto = Number(impuesto) + Number(valorPorcentaje);
        }
        let neto = Number(this.montoPrestamo.value.replace(/,/g, '')) + Number(interes) - Number(impuesto)
        this.interesFinal = interes;
        this.impuesto = impuesto;
        this.netoN = neto;
        if (this.seleccionTabla == "tabla") {
          for (var i = 0; i < this.datos.length; i++) {
            if (i == Number(this.indiceTabla)) {
              this.datos[i].interesG = Number(this.interesFinal) - Number(this.impuesto);
              this.datos[i].impuesto = Number(this.impuesto) > 0 ? this.impuesto : '0';
              this.datos[i].neto = Number(neto);
              this.datos[i].tasa = this.infoTasa[0].MARGEN;
            }
          }
        }
        if (this.seleccionTabla == "tabla2") {
          for (var i = 0; i < this.datosNuevo.length; i++) {
            if (i == Number(this.indiceTabla)) {
              this.datosNuevo[i].interesG = Number(this.interesFinal) - Number(this.impuesto);
              this.datosNuevo[i].impuesto = Number(this.impuesto) > 0 ? this.impuesto : '0';
              this.datosNuevo[i].neto = Number(neto);
              this.datosNuevo[i].tasa = this.infoTasa[0].MARGEN;
            }
          }
        }
        this.mostrarCuenta = true;
        this.intento = false;
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false;
        this.mostrarCuenta = false;
        this.alert.presentarAlertaX(err.error.mensajeUsuario)
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false;
      }
    });
  }

  onSelectionChange(event: any): void {
    // Realiza acciones cuando se selecciona una opción
    this.frecuenciaSelected = event.value.id;
    this.itemTableSelected = false;
    this.calculateDisabled = false;
    this.hiddenCalculate = false;
    // Puedes agregar aquí el código que desees ejecutar al seleccionar una opción
  }

  obtenerTabla() {
    this.mostrarCro = false;
    this.enableCronoIn = false;
    this.calculateDisabled = true;
    this.hiddenCalculate = true;
    let elemento = document.getElementById("montoID");
    this.generaDocumento = false;
    this.tabla2Seleccionada = false;
    this.obtenerPlazo = "no";
    this.indiceTabla = 0;
    this.tabla = [];
    this.tablaPagos = [];
    this.infoTasa = [];
    elemento.blur();
    this.contadorN = 1;
    this.datos = [];
    this.mostrarDatosListado = false;
    this.mostrarTabla = false;
    this.mostrarCuenta = false;
    this.mostrarTerminos = false;
    this.mostrarSucursal = false;
    this.tokenFormControl.reset();
    if (Number(this.montoPrestamo.value.replace(/,/g, '')) >= this.data[0].MONTOMINIMO && Number(this.montoPrestamo.value.replace(/,/g, '')) <= Number(this.montoMaximo)) {
      this.intento = true;
      let pedido = {
        'monto': String(this.montoPrestamo.value.replace(/,/g, '')),
        'csubsistema': this.data[0].CSUBSISTEMA,
        'cgrupoproducto': this.data[0].CGRUPOPRODUCTO,
        'cproducto': this.data[0].CPRODUCTO,
        'cmoneda': this.data[0].CMONEDA,
        'nuevo': "no",
        'cpersona': this.dbHandler.cpersona,
        'frecuencia': this.frecuenciaSelected,
        'cuentaVista': "'404','406'"
      }

      this.api.postProvider2InternetCheck("/consultaPlazo", this.dbHandler.id_token, pedido).then((data: any) => {
        if (data) {
          this.accounts = data.listaCuentas
          this.datos = data.tabla
          this.datosNuevoR = data.tablaN
          this.datosNuevo[0].checked = false;
          this.intento = false;
          this.mostrarDatosListado = true;
          this.contadorN = 0;
        }
      }, (err) => {
        if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
          this.intento = false;
          this.alert.presentarAlertaX(err.error.mensajeUsuario)
          if (err.error) {
            if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          }
          else {
            this.alert.presentarAlerta(this.idiomas.ServidorError)
          }
        }
        else { //Sin Internet
          this.intento = false;
        }
      });
    }
  }

  obtenerTablaN() {
    this.capitalIngresado = this.montoPrestamo.value;
    this.generaDocumento = false;
    if (this.plazoN.valid) {
      this.mostrarCro = false;
      this.enableCronoIn = false;
      if (this.seleccionTabla == "tabla") {
        this.mostrarTabla = true;
      }
      else if (this.seleccionTabla == "tabla2") {
        this.mostrarTabla = true;
        this.tipoCuota.reset();
      } else {
        this.mostrarTabla = false;
      }
      this.mostrarCuenta = false;
      this.mostrarSucursal = false;
      this.mostrarTerminos = false;
      this.tokenFormControl.reset();
      this.accountFormControl.reset();
      this.agencyFormControl.reset();
      this.plazoN.enable();
      this.tipoCuota.enable();
      this.agencyFormControl.enable();
      this.vencimientoN = " ";
      this.tasaN = " ";
      this.interesN = " ";
      this.impuestoN = " ";
      this.netoN = " ";
      if (this.plazoN.value != '') {
        this.intento = true;
        let pedido = {
          'monto': String(this.montoPrestamo.value.replace(/,/g, '')),
          'csubsistema': this.data[0].CSUBSISTEMA,
          'cgrupoproducto': this.data[0].CGRUPOPRODUCTO,
          'cproducto': this.data[0].CPRODUCTO,
          'cmoneda': this.data[0].CMONEDA,
          'nuevo': "si",
          'cpersona': this.dbHandler.cpersona,
          'cuentaVista': "'01'",
          'frecuencia': this.frecuenciaSelected, //0 o 5
          'plazo': this.plazoN.value
        }
        this.api.postProvider2InternetCheck("/consultaPlazo", this.dbHandler.id_token, pedido).then((data: any) => {
          if (data) {
            this.datosNuevo = data.tablaN
            this.contadorN = 0;
            this.intento = false;
            this.contadorN = 0;
            this.obtenerPlazo = "si";
            if (this.tabla2Seleccionada) {
              this.datosNuevo[0].checked = true;
              this.fvencimiento = this.datosNuevo[0].vencimiento
              this.impuesto = this.datosNuevo[0].impuesto
              this.netoN = this.datosNuevo[0].neto
              this.tasa = this.datosNuevo[0].tasa
              this.numeroDias = this.plazoN.value
              this.interesFinal = Number(this.datosNuevo[0].interesG) + Number(this.datosNuevo[0].impuesto)
              this.seleccionTabla = "tabla2";
              this.indiceTabla = 0;
            }
          }
        }, (err) => {
          if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
            this.intento = false;
            this.alert.presentarAlertaX(err.error.mensajeUsuario)
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
            }
            else {
              this.alert.presentarAlerta(this.idiomas.ServidorError)
            }
          }
          else { //Sin Internet
            this.intento = false;
          }
        });
      } else {
        this.datosNuevo = this.datosNuevoR
        this.contadorN = 1;
        this.obtenerPlazo = "no"
      }
    } else {
      this.datosNuevo = this.datosNuevoR
      this.contadorN = 1;
      this.obtenerPlazo = "no"
      for (let index = 0; index < this.datos.length; index++) {
        if (this.datos[index].checked == true) {
          this.mostrarTabla = true;
          break;
        } else {
          this.mostrarTabla = false;
        }
      }
    }
  }

  calcularTasa(tipo) {
    this.intento = true;
    let cuotas = (this.numeroDias / 30).toFixed(0)
    let dias = this.numeroDias
    let porcentaje = Number(this.impuesto) > 0 ? 0.02 : 0
    let interes = 0
    let impuesto = 0
    for (var i = 1; i <= Number(cuotas); i++) {
      if (i != Number(cuotas)) {
        let calculoInteres = ((Number(this.montoPrestamo.value.replace(/,/g, '')) * this.tasa * 30 / 36000)).toFixed(2)
        interes = Number(interes) + Number(calculoInteres)
        let calculoImpuesto = (Number(porcentaje) * Number(calculoInteres)).toFixed(2)
        impuesto = Number(impuesto) + Number(calculoImpuesto)
        dias = Number(dias) - 30
      } else {
        let calculoInteres = ((Number(this.montoPrestamo.value.replace(/,/g, '')) * this.tasa * Number(dias) / 36000)).toFixed(2)
        interes = Number(interes) + Number(calculoInteres)
        let calculoImpuesto = (Number(porcentaje) * Number(calculoInteres)).toFixed(2)
        impuesto = Number(impuesto) + Number(calculoImpuesto)
      }
    }
    let neto = Number(this.montoPrestamo.value.replace(/,/g, '')) + Number(interes) - Number(impuesto)
    this.interesFinal = interes
    this.impuesto = impuesto
    this.netoN = neto
    this.mostrarCuenta = true;
    this.intento = false;
  }

  calcularTasaRes(tipo) {
    this.intento = true;
    let envio = {
      transaccion: "019050-lv-tasaPlazo.xml",
      alias: 'lov',
      'cri^lov^MONTO^NORMAL': String(this.montoPrestamo.value.replace(/,/g, '')),
      'cri^lov^CSUBSISTEMA^NORMAL': this.data[0].CSUBSISTEMA,
      'cri^lov^CPRODUCTO^NORMAL': this.data[0].CPRODUCTO,
      'cri^lov^CGRUPOPRODUCTO^NORMAL': this.data[0].CGRUPOPRODUCTO,
      'cri^lov^CTIPOBANCA^NORMAL': this.data[0].CTIPOBANCA,
      'cri^lov^CMONEDA^NORMAL': this.data[0].CMONEDA,
      'cri^lov^PLAZO^NORMAL': this.numeroDias,
      'cri^lov^FRECUENCIA^NORMAL': tipo
    }
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, envio).then((data: any) => {
      if (data) {
        this.infoTasa = data;
        if (tipo == "5") {
          this.intento = true;
          this.simuladorPlazo()

        } else {
          this.mostrarCuenta = true;
          this.intento = false;
          this.simuladorPlazo()
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alert.presentarAlertaX(err.error.mensajeUsuario);
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }
  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  showHideRows(last) {
    if (last) {
      try {
        //this.funcionesTabla.DivHeight(this.datos)
        let classcontainer2Width = document.getElementsByClassName('container1Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 600px')
          if (600 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:150px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 500px')
          if (500 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:115px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 400px; font-size: 14px')
          if (400 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:90px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }


  showHideRowsN(last) {
    if (last) {
      try {
        //this.funcionesTabla.DivHeight(this.datos)
        let classcontainer2Width = document.getElementsByClassName('container3Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('tablaNueva')[0].setAttribute('style', 'width: 600px')
          if (600 - classcontainer2Width > 0) {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:150px')
            if (!this.flechaL1) {
              document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaNueva')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('tablaNueva')[0].setAttribute('style', 'width: 500px')
          if (500 - classcontainer2Width > 0) {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:115px')
            if (!this.flechaL1) {
              document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaNueva')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('tablaNueva')[0].setAttribute('style', 'width: 400px; font-size: 14px')
          if (400 - classcontainer2Width > 0) {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:90px')
            if (!this.flechaL1) {
              document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaNueva')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }

  scroll(id) {
    if (id == 'tablaServiciosP') {
      var container1 = document.getElementsByClassName('container1Aux')
    }
    var tabla = document.getElementsByClassName(id)
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 2 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    if (clase == 'tablaServiciosP') {
      let container1 = document.getElementsByClassName('container1Aux')
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
      } else {
        container1.item(0).scrollLeft = 0
        this.flechaL = true
        this.flechaR = false
      }
    }

  }

  scrollN(id) {
    if (id == 'tablaNueva') {
      var container1 = document.getElementsByClassName('container3Aux')
    }
    var tabla = document.getElementsByClassName(id)
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 2 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR1 = true;
        this.flechaL1 = false;
      } else {
        this.flechaR1 = false;
        this.flechaL1 = true;
      }
    } else {
      this.flechaR1 = false;
      this.flechaL1 = true;
    }
  }

  scrollToN(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    if (clase == 'tablaNueva') {
      let container1 = document.getElementsByClassName('container3Aux')
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
      } else {
        container1.item(0).scrollLeft = 0
        this.flechaL1 = true
        this.flechaR1 = false
      }
    }

  }



  checkOption(e) {
    this.mostrarCro = false;
    this.enableCronoIn = false;
    let tipo = this.tipoCuota.value.descripcion
    this.tokenFormControl.reset();
    this.tokenSolicitado = false;
    this.mostrarTerminos = false;
    this.mostrarCuenta = false;
    this.accountS = false;
    this.mostrarSucursal = false;
    this.mostrarTerminos = false;
    this.checkedC = false;
    this.accountFormControl = new UntypedFormControl('')
    this.agencyFormControl = new UntypedFormControl('')
    this.tokenFormControl.reset();

    if (tipo == "AL VENCIMIENTO" && this.montoPrestamo.value.toString() !== '' && this.plazoN.value.toString() !== '') {
      this.tipo = "vencimiento"
      this.obtenerTabla();
      this.obtenerTablaN();
    }
    if (tipo == "MENSUAL" && this.montoPrestamo.value.toString() !== '' && this.plazoN.value.toString() !== '') {
      this.tipo = "mensual"
      this.obtenerTabla();
      this.obtenerTablaN();
    }
  }

  calculateSimultaion() {
    let tipo = this.tipoCuota.value.descripcion;
    if (tipo == "AL VENCIMIENTO") {
      this.tipo = "vencimiento"
      this.calcularTasaRes("0");
    }
    if (tipo == "MENSUAL") {
      this.tipo = "mensual"
      this.calcularTasaRes("5");
    }
  }

  checkAllOne(e, i, donde) {
    this.enableCronoIn = false;
    this.mostrarCro = false;
    this.mostrarCuenta = false;
    this.mostrarTerminos = false;
    this.tokenFormControl.reset();
    this.plazoN.enable();
    this.tipoCuota.enable();
    this.agencyFormControl.enable();
    if (donde == "tabla") {
      this.tabla = [];
      this.mostrarTabla = false;
      this.mostrarCuenta = false;
      if (e.checked == true) {
        this.itemTableSelected = true;
        for (let index = 0; index < this.datos.length; index++) {
          if (i != index) {
            this.datos[index].checked = false;
            this.datosNuevo[0].checked = false;
          } else {
            this.datos[index].checked = true;
            this.datosNuevo[0].checked = false;
          }
        }
        this.numeroDias = this.datos[i].plazo
        this.tasa = this.datos[i].tasa
        this.interesFinal = Number(this.datos[i].interesG) + Number(this.datos[i].impuesto)
        this.fvencimiento = this.datos[i].vencimiento
        this.impuesto = this.datos[i].impuesto
        this.netoN = this.datos[i].neto
        this.mostrarTabla = true;
        this.seleccionTabla = "tabla";
        this.indiceTabla = i;
        this.tabla2Seleccionada = false;
      } else {
        this.seleccionTabla = "";
        this.indiceTabla = 0;
        this.datos[i].checked = false;
        this.datosNuevo[0].checked = false;
        this.contadorN = 0;
        this.numeroDias = 0;
        this.mostrarTabla = false;
        this.tabla2Seleccionada = false;
        this.itemTableSelected = false;
      }

    } else {
      this.tabla = [];
      this.mostrarTabla = false;
      if (e.checked == true) {
        this.itemTableSelected = true;
        for (let index = 0; index < this.datos.length; index++) {
          this.datos[index].checked = false;
        }
        this.datosNuevo[0].checked = true;
        this.numeroDias = this.plazoN.value
        this.interesFinal = Number(this.datosNuevo[i].interesG) + Number(this.datosNuevo[i].impuesto)
        this.fvencimiento = this.datosNuevo[i].vencimiento
        this.impuesto = this.datosNuevo[i].impuesto
        this.netoN = this.datosNuevo[i].neto
        this.tasa = this.datosNuevo[i].tasa
        this.mostrarTabla = true;
        this.seleccionTabla = "tabla2";
        this.indiceTabla = i;
        this.tabla2Seleccionada = true;

      } else {
        this.seleccionTabla = "";
        this.indiceTabla = 0;
        this.datosNuevo[0].checked = false;
        this.numeroDias = 0;
        this.mostrarTabla = false;
        this.tabla2Seleccionada = false;
        this.itemTableSelected = false;
      }
    }
    this.selectedAccount = "";
    this.selectedAgency = "";
    this.onSelectedAccount(this.accounts[0].id);
    this.accountFormControl.setValue(this.accounts[0].id);
    this.consultaSucursales();
    this.agencyFormControl = new UntypedFormControl('')
  }

  enviarRespuesta(dato) {
    if (dato == "A" && this.envioResp == "0") {
      this.intento = true
      let numeroCuota = this.tipo == "mensual" ? Math.round(Number(this.numeroDias) / 30) : 1
      let numerodias = this.tipo == "mensual" ? "30" : "0";
      let pedido = {
        'cpersona': this.dbHandler.cpersona,
        'monto': String(this.montoPrestamo.value.replace(/,/g, '')),
        'plazo': this.numeroDias,
        'tasa': String(this.infoTasa[0].MARGEN),
        'tipoMV': this.tipo,
        'numeroCuota': String(numeroCuota),
        'numeroDias': String(numerodias),
        'fcontable': this.data[0].FCONTABLE.substring(0, 10),
        'fvencimiento': this.fvencimiento.substring(0, 10),
        'user': "$userCode",
        'plazominimo': this.data[0].PLAZOMINIMO,
        'plazomaximo': this.data[0].PLAZOMAXIMO,
        'montominimo': this.data[0].MONTOMINIMO,
        'montomaximo': this.data[0].MONTOMAXIMO,
        'margen': this.infoTasa[0].MARGEN,
        'margenMinimo': this.infoTasa[0].MARGENMINIMO,
        'margenMaximo': this.infoTasa[0].MARGENMAXIMO,
        'ccuenta': this.selectedAccount.id,
        'interes': this.interesFinal,
        'cgrupoproducto': this.data[0].CGRUPOPRODUCTO,
        'cproducto': this.data[0].CPRODUCTO,
        'cmoneda': this.data[0].CMONEDA,
        'ctipobanca': this.data[0].CTIPOBANCA,
        "key": this.service.toAES(this.tokenFormControl.value),
        "desencriptar": "1",
        'sucursal': this.selectedAgency == "" ? "" : this.selectedAgency.CSUCURSAL,
        'oficina': this.selectedAgency == "" ? "" : this.selectedAgency.COFICINA,
        'electronico': "1",
        'interesneto': Number(this.interesFinal) - Number(this.impuesto),
        'interesnetoD': (Number(this.interesFinal) - Number(this.impuesto)).toFixed(2),
        'impuestoneto': this.impuesto.toFixed(2).toString(),
        'neto': this.netoN.toFixed(2),
        'frecuencia': this.tipo == "mensual" ? "Mensual" : "Al vencimiento"
      }
      this.alert.generarDialogoInversion(this.idiomas.PlazoFijoA, this.idiomas, pedido, 'mostrarTexto').then((data) => {
        if (data) {
          this.envioResp = "1";
          this.api.postProvider2InternetCheck("/solicitudPlazo", this.dbHandler.id_token, pedido).then((data: any) => {
            if (data) {
              this.ccuenta = data.CCUENTA;
              this.ccuentaInversion = data.CUENTAINVERSION;
              this.solicitud = data.CCUENTA == null || data.CCUENTA == '' ? new UntypedFormControl(data.CSOLICITUD) : new UntypedFormControl(data.CCUENTA);
              this.mostrarSolicitud = true;
              this.intento = false;
              this.accountS = false;
              this.generaDocumento = data.GENERADOCUMENTO == null || data.GENERADOCUMENTO == '' || data.GENERADOCUMENTO != 'SI' ? false : true;
              this.accountFormControl.disable();
              this.agencyFormControl.disable();
              this.montoPrestamo.disable();
              this.tokenFormControl.disable();
              this.tipoCuota.enable();
              this.contadorN = 1;
              this.deshabilitar = true;
              this.checkedC = false;
              this.plazoN.disable();
              this.mantenimiento = false;
              this.seleccionTabla = "";
              this.indiceTabla = 0;
              this.mostrarCro = false;
              this.enableCronoIn = false;
              this.mostrarCro = false;
              this.envioResp == "0"
              this.tabla2Seleccionada = false;
              this.plazoN.reset();
              this.alert.presentarAlerta('TRANSACCION REALIZADA CORRECTAMENTE');
              if (Number(this.montoPrestamo.value.replace(/,/g, '')) >= 10000) {
                this.reporteLicitud();
              }

            }
          }, (err) => {
            this.envioResp == "0"
            this.intento = false;
            this.mostrarCro = false;
            this.enableCronoIn = false;
            this.mostrarCro = false;
            if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
              this.intento = false;
              this.alert.presentarAlertaX(err.error.mensajeUsuario)
              if (err.error) {
                if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
              }
              else {
                this.alert.presentarAlerta(this.idiomas.ServidorError)
              }
            }
            else { //Sin Internet
              this.intento = false;
            }
          })
        } else {
          this.envioResp = "0";
          this.intento = false;
        }
      })
    }
    if (dato == "N") {
      this.itemTableSelected = false;
      this.deshabilitarL = true;
      this.envioResp = "0";
      this.mostrarCro = false;
      this.enableCronoIn = false;
      this.intento = false;
      this.intentop = false;
      this.seleccionTabla = "";
      this.indiceTabla = 0;
      this.tokenSolicitado = false;
      this.deshabilitar = false;
      this.contadorN = 1;
      this.vencimientoN = ""
      this.tasaN = ""
      this.interesN = ""
      this.impuestoN = ""
      this.netoN = ""
      this.datos = []
      this.montoPrestamo = new UntypedFormControl("", [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(this.data[0].MONTOMINIMO), maxAmountValidationNumber(Number(this.montoMaximo))]);
      for (var i = 0; i < this.datos.length; i++) {
        this.datos[i].checked = false;
      }
      this.tCuota = [
        { id: '0', descripcion: 'AL VENCIMIENTO' },
        { id: '5', descripcion: 'MENSUAL' }
      ]
      this.mostrarDatosListado = false;
      this.mostrarTabla = false;
      this.mostrarCuenta = false;
      this.accountS = false;
      this.mostrarSucursal = false;
      this.mostrarTerminos = false;
      this.checkedC = false;
      this.intento = false;
      this.intentop = false;
      this.generaDocumento = false;
      this.plazoN.enable();
      this.tokenFormControl.reset()
      this.accountFormControl.reset();
      this.agencyFormControl.reset();
      this.plazoN.reset();
    }
  }

  reportePDFLicitud() {
    let tipo = this.tipoCuota.value.descripcion
    var envio = {
      "nombre": "18_ORIGENFONDOS",
      "tipo": "PDF",
      "rep^ccuenta": String(this.ccuentaInversion),
      "rep^destino": "AHORRO",
      "rep^valor": this.montoPrestamo.value,
      "rep^persona": this.dbHandler.cpersona,
      "rep^SECUENCIAL": this.secuecial,
      "ctl^CCUENTA": String(this.ccuentaInversion)
    }
    this.api.postProvider2InternetCheck("/reporte", this.dbHandler.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  reporteLicitud() {
    let tipo = this.tipoCuota.value.descripcion
    var envio = {
      "nombre": "18_ORIGENFONDOS",
      "subsistema": "18",
      "transaccion": "7017",
      "tipo": "PDF",
      "rep^ccuenta": String(this.ccuentaInversion),
      "rep^destino": "AHORRO",
      "rep^valor": this.montoPrestamo.value,
      "rep^persona": this.dbHandler.cpersona,
      "ctl^CCUENTA": String(this.ccuentaInversion)
    }
    this.api.postProvider2InternetCheck("/reporte", this.dbHandler.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        this.secuecial = data.ctl.R_SECUENCIAL
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        this.deshabilitarL = false;
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        this.deshabilitarL = true;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  onSelectedAccount(account: string) {
    this.selectedAccount = this.accounts.find((accountFromList) => (accountFromList.id === account));
    this.validarSaldoCuenta(this.selectedAccount)
  }

  onSelectAgency(agency: string) {
    this.selectedAgency = this.agencies.find((agencytFromList) => (agencytFromList.CSUCURSAL === agency));
    this.mostrarTerminos = true;
    this.checkedC = false;
    this.tokenFormControl.reset();
  }

  searchAgency(value: string) {

    if (value.trim() === '') {
      // Si el valor está vacío, muestra la lista completa
      this.agencies = this.originalAgencies;
    } else {
      // Si el valor no está vacío, aplica el filtro y muestra el resultado
      let selectedAgency = this.search(value);
      this.agencies = selectedAgency;
    }
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.agencies.filter(option => option.NOMBRE.toLowerCase().includes(filter));
  }

  validarSaldoCuenta(ccuenta) {
    this.intento = true;
    const envio = {
      transaccion: '010003-lv-saldoCuentaVista.xml',
      alias: 'tp',
      'cri^tp^CCUENTA^NORMAL': ccuenta.id
    }
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, envio).then((data: any) => {
      if (data[0] != null) {
        if (Number(data[0].SALDOMONEDACUENTA) > Number(this.montoPrestamo.value.replace(/,/g, ''))) {
          this.intento = false;
          this.mostrarTerminos = true;
          this.checkedC = false;
          this.tokenFormControl.reset();
        }
        else {
          this.accountS = false;
          this.mostrarSucursal = false;
          this.mostrarTerminos = false;
          this.checkedC = false;
          this.alert.presentarAlerta("SALDO DE LA CUENTA " + ccuenta.etiqueta + " MENOR AL VALOR INGRESADO");
          this.intento = false;
        }
      } else {
        this.accountS = false;
        this.mostrarSucursal = false;
        this.mostrarTerminos = false;
        this.checkedC = false;
        this.alert.presentarAlerta("SALDO DE LA CUENTA " + ccuenta.etiqueta + " MENOR AL VALOR INGRESADO");
        this.intento = false;
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") {
            this.logout()
          }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet  
      }
    });
  }

  consultaSucursales() {
    const envio = {
      transaccion: '010003-lv-direccion-sucursalesBase.xml',
      alias: 'lov'
    }
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, envio).then((data: any) => {
      if (data.length > 0) {
        this.agencies = data;
        this.originalAgencies = data;
        this.agencyFormControl.enable();
        this.mostrarSucursal = true;
      }
      Promise.resolve(null).then(() => this.intento = false);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") {
            this.logout()
          }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet  
      }
    });
  }

  transformadorCuentasHacia(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].id, 'viewValue':
          value[i].etiqueta, 'descProducto': this.idiomas.TiposCuentaBeneficiario.filter(x => { return value[i].tipo == x.value })[0].viewValue.toUpperCase(), banco: value[i].cbanco
        , identificacion: value[i].identificacionBeneficiario, nombre: value[i].nombreBeneficiario, moneda: value[i].moneda,
        tipoidentificacion: value[i].ctipoIdentificacion, efiInstFinan: value[i].efiInstFinan
      });
    }
    return lista;
  }

  reportePDFPlazo() {
    let envio = {
      "ccuenta": this.ccuenta
    }
    this.api.postProvider2InternetCheck("/consultaPlazoReporte", this.dbHandler.id_token, envio).then((data: any) => {
      this.pdf = data.listDetailImage[0].imagen
      const linkSource = 'data:application/pdf;base64,' + this.pdf
      if (this.bandMobile) {
        this.alert.generarDialogoInversion(this.idiomas.PlazoFijoA, this.idiomas, this.pdf, 'mostrarPdfBase64').then((data) => {
          if (data) {
          } else {
            this.intento = false;
          }
        })
      } else {
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
      this.intento = false;
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        document.getElementById("loadData_05_06").style.visibility = 'hidden'
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  //funcion del para terminar temporizador
  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }
}

//Validacion de montos minimos de recarga
interface minAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null
}
function minAmountValidationNumber(minValue): minAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto >= minValue) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de recarga
interface maxAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null
}
function maxAmountValidationNumber(maxValue): maxAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto <= maxValue) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}
//Validacion de montos minimos de recarga
interface minPlazoValidationNumber {
  (control: UntypedFormControl): ValidationErrors | null
}
function minPlazoValidationNumber(minValue): minPlazoValidationNumber {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto >= minValue) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de recarga
interface maxPlazoValidationNumber {
  (control: UntypedFormControl): ValidationErrors | null
}
function maxPlazoValidationNumber(maxValue): maxPlazoValidationNumber {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto <= maxValue) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}
