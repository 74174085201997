import { Component, OnInit, Input, HostListener, Renderer2 } from '@angular/core';
import { Alerta } from '../funciones/alerta';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { FormControlValidators } from '../funciones/formcontrol';
import { BaseDatos } from '../funciones/basededatos';
import { ApiService } from '../api.service';
import { internetComponent } from '../funciones/internet';
import { Servicios } from '../funciones/encryptar';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';

//Soporte para FIDO
import { User } from '../interfaces/user';
import { ServerMockService } from '../services/server-mock.service';
import { WebAuthnService } from '../services/web-authn.service';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';
import { Router } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatInputModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, TemporizadorComponent, AlertasLoginComponent, QRCodeModule],
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.css']
})
export class AdministracionComponent implements OnInit {

  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;
  @Input() preguntas: boolean;
  @Input('rating') private rating: number = 1;
  @Input('starCount') private starCount: number = 5;
  @Input('color') private color: string = 'accent';

  loginInfo: any

  constructor(public alerta: Alerta, public basedatos: BaseDatos, public apiService: ApiService,
    private serverMockService: ServerMockService, private webAuthnService: WebAuthnService,
    private validators: FormControlValidators, public internetC: internetComponent, public servicios: Servicios,
    private renderer: Renderer2, private router: Router) {
    this.respuestasR = [];
    this.respuestas = [];
    for (var i = 0; i < 3; i++) {
      this.preguntasForm[i] = this.validators.comboForm();
    }
    this.loginInfo = this.basedatos.retornarConsulta("loginInfo")
    this.hideActualPwd = [];
    this.hideNewPwd = [];
    this.hideConfirmPwd = [];
    this.hidePwdBiometric = [];
    for (var i = 0; i < 5; i++) {
      this.hideActualPwd[i] = true
    }
    for (var i = 0; i < 5; i++) {
      this.hideNewPwd[i] = true
    }
    for (var i = 0; i < 5; i++) {
      this.hideConfirmPwd[i] = true
    }
    for (var i = 0; i < 5; i++) {
      this.hidePwdBiometric[i] = true
    }
    this.enableCronoIn = false;
    this.mostrarCron = false;
  }

  // BOOLEAN
  containerPreguntas: boolean
  bandCheck: boolean;
  containerCambios: boolean
  changePwd: boolean
  changeEma: boolean
  deshabilitar: boolean
  cPreguntas: boolean
  cNvoPreguntas: boolean
  intento: boolean
  intentop: boolean
  offline: boolean
  mensajeContra: string = null;
  mensajeBiometria: string = null;
  personalInfo: boolean
  ratingInfo: boolean
  infoRequest: boolean
  questionsInfo: boolean
  isRegisterBiometric: boolean
  isDeleteBiometric: boolean
  isMobile: boolean
  intentoBiometric: boolean = false;
  intentoBiometricUser: boolean = false
  hideActualPwd: boolean[];
  hideNewPwd: boolean[];
  hideConfirmPwd: boolean[];
  hidePwdBiometric: boolean[];
  hasPin: boolean = false;
  registerPin: boolean = false;
  unRegisterPin: boolean = false;
  bandButtonDel: boolean = false;
  name: string = "";
  phone: string = "";
  dateLink: string = "";
  dateRegister: string = "";
  intentoPaises: boolean = false;
  lpaises: any[] = [];
  lpaisesInicial: any[] = [];
  lfiltradoPaises: any[] = [];

  //VARIABLES
  respuestas: any
  respuestasR: any
  nombre: string;
  idForm: string
  correo: string
  teflonoDo: string
  versionControlDo: string
  codigoAreaDo: string
  versionControl: string
  numerodireccion: string
  nombreSocio: string
  identificacionSocio: string
  direccionSocio: string
  celularSocio: string
  agenciaSocio: string
  correoSocio: string
  hasBiometricRegister: any
  hasBiometricRegisterUser: any
  users: User[];
  hasBiometricPhone: any;
  hasUnlinkMobile: boolean = false;
  vc: string = "";
  cEndpoint: string = "";

  //Variable para el temporizador
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  bandMobile: boolean;

  // FORMCONTROL
  preguntasForm: UntypedFormControl[] = [];
  actualPwd = new UntypedFormControl(null, Validators.required)
  tokenCo = this.validators.otpForm();
  tokenTel = this.validators.otpForm();
  tokenDir = this.validators.otpForm();
  usernameFormControl = this.validators.userForm();
  newPwd = this.validators.passwordForm();
  confirmPwd = this.validators.passwordForm();
  nuevoC = this.validators.emailForm();
  nuevoTel = this.validators.numberForm();
  nuevaDir = this.validators.textNumberSpecialFeaturetForm();
  observacionDomForm = this.validators.textNumberSpecialFeaturetForm();
  observacionRating = this.validators.textNumberSpecialFeaturetForm();
  deviceName = this.validators.textNumberSpecialFeaturetForm();
  phoneNumber = this.validators.numberForm();
  areaCode = this.validators.numberForm();
  offlineData: boolean;
  displayedColumnsPreguntas = ["Pregunta", "Respuesta"]
  private snackBarDuration: number = 2000;
  private ratingArr = [];

  data: string;
  validPin: boolean = false;
  pinSaved: boolean = false;
  deviceLinked: boolean = false;
  digits: any[] = [
    { id: 1, field: this.validators.numberForm() },
    { id: 2, field: this.validators.numberForm() },
    { id: 3, field: this.validators.numberForm() },
    { id: 4, field: this.validators.numberForm() },
    { id: 5, field: this.validators.numberForm() },
    { id: 6, field: this.validators.numberForm() },
  ];

  //TOKEN
  ingtokenCo: boolean;

  ngOnInit() {
    this.consultaInicial()
    this.biometricValidation()
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  biometricValidation() {
    this.isMobile = this.serverMockService.isMobile()
    this.hasBiometricPhone = this.servicios.desencriptar(localStorage.getItem('hasBiometricPhone'))
    this.users = this.serverMockService.getUsers();
    this.obtainHasBiometricDesv()
  }

  consultaInicial() {
    this.changePwd = false;
    this.changeEma = false;
    this.deshabilitar = false;
    this.personalInfo = false;
    this.ratingInfo = false;
    this.infoRequest = false;
    this.questionsInfo = false;
    this.isRegisterBiometric = false;
    this.isDeleteBiometric = false;
    this.intentoBiometricUser = false;
    this.containerPreguntas = true;
    for (var i = 0; i < 3; i++) {
      this.preguntasForm[i].reset();
    }
    this.nuevoC.reset();
    this.nuevoTel.reset();
    this.actualPwd.reset();
    this.newPwd.reset();
    this.confirmPwd.reset();
    this.cPreguntas = true;
    this.cNvoPreguntas = true;
    this.consultarPreguntas();
    this.containerCambios = false;
    this.intento = false;
    this.intentop = true;
    this.ingtokenCo = false;
    this.mostrarCron = false;
    this.enableCronoIn = false;
  }

  ngDoCheck() {
    if (!this.offline != this.internetC.internet) {
      this.offline = !this.internetC.internet
      if (!this.offline && this.containerPreguntas) {
        this.consultarPreguntas();
      }
    }
  }

  consultarPreguntas() {
    this.intentop = true;
    this.offlineData = true;
    let envio = {}
    this.apiService.postProvider2InternetCheck('/solpreguntas', this.basedatos.id_token, envio).then((data: any) => {
      this.preguntas = data
      if (this.preguntas != null) {
        this.intentop = false;
        this.cNvoPreguntas = true;
      }
      this.bandCheck = false;
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intentop = false;
        this.preguntas = null;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.intentop = false;
        this.inicializar()
      }
    })
  }

  validarPreguntas() {
    let respuestaValidacion: any
    this.offlineData = false;
    this.intento = true;
    let envio = { "preguntas": this.respuestasR }
    this.apiService.postProvider2InternetCheck('/envpreguntas', this.basedatos.id_token, envio).then((data: any) => {
      respuestaValidacion = data
      if (respuestaValidacion.code == "0") {
        this.containerPreguntas = false
        this.containerCambios = true
        this.cPreguntas = false
        this.cNvoPreguntas = false
        this.deshabilitar = false
        this.nuevoC.enable();
        this.nuevoTel.enable()
        this.newPwd.enable();
        this.confirmPwd.enable();
      }
      this.bandCheck = false;
      this.deviceQuery();
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.intento = false;
        this.inicializar();
      }
    })
  }

  SolicitarTokenCo() {
    this.intentop = true
    this.ingtokenCo = false
    const envio = { transaccion: '183210' };
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.intentop = false
        this.ingtokenCo = true
        this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
        setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
      }
    }, (err) => {
      this.intentop = false
      this.ingtokenCo = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  calificarHB() {
    this.intento = true;
    let envio = {
      transaccion: '183073-man-rating.xml',
    }
    envio['rec^tci0^0^CUSUARIO^tci0'] = "$userCode"
    envio['rec^tci0^0^FCALIFICACION^tci0'] = this.getCurrentTimeAsTimestamp()
    envio['rec^tci0^0^CALIFICACION^tci0'] = this.rating
    envio['rec^tci0^0^OBSERVACION^tci0'] = this.observacionRating.value.toUpperCase()
    envio['rec^tci0^0^SESION^tci0'] = "$session"
    this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, true, false).then((data) => {
      if (data) {
        this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
          this.intento = false
          this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, false, true)
          this.starCount = 5;
          this.rating = 1
          this.observacionRating.reset()
        }, (err) => {
          this.intento = false
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
            }
            else {
            }
          }
          else { //Sin Internet
            this.intento = false
          }
        });
      } else {
        this.intento = false;
      }
    });
  }

  MensajeHB() {
    this.intento = true;
    let envio = {
      transaccion: '183074-man-mensajes-ibanking.xml',
    }
    envio['rec^tumi0^0^CUSUARIO^tumi0'] = "$userCode"
    envio['rec^tumi0^0^FMENSAJE^tumi0'] = this.getCurrentTimeAsTimestamp()
    envio['rec^tumi0^0^CPERSONA_COMPANIA^tumi0'] = "$company"
    envio['rec^tumi0^0^TEXTOMENSAJE^tumi0'] = this.observacionDomForm.value.toUpperCase()
    envio['rec^tumi0^0^SESION^tumi0'] = "$session"
    this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, true, false).then((data) => {
      if (data) {
        this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
          this.intento = false
          this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, false, true)
          this.observacionDomForm.reset()
        }, (err) => {
          this.intento = false
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
            }
            else {
            }
          }
          else { //Sin Internet
            this.intento = false
          }
        });
      } else {
        this.intento = false;
      }
    });
  }

  hideIntervalActualPwd() {
    setInterval(() => {
      for (var i = 0; i < 5; i++) {
        this.hideActualPwd[i] = true
      }
    }, 5000);
  }

  hideIntervalNewPwd() {
    setInterval(() => {
      for (var i = 0; i < 5; i++) {
        this.hideNewPwd[i] = true
      }
    }, 5000);
  }
  hideIntervalConfirmPwd() {
    setInterval(() => {
      for (var i = 0; i < 5; i++) {
        this.hideConfirmPwd[i] = true
      }
    }, 5000);
  }
  hideIntervalPwdBiometric() {
    setInterval(() => {
      for (var i = 0; i < 5; i++) {
        this.hidePwdBiometric[i] = true
      }
    }, 5000);
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date()
    const year = now.getFullYear()
    const month = this.completeDigits(now.getMonth() + 1)
    const day = this.completeDigits(now.getDate())
    const seconds = now.getSeconds();
    const minutes = now.getMinutes();
    const hour = now.getHours();
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString()
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  logoutPassChanged() {
    this.router.navigate(['']);
    this.apiService.postProvider2InternetCheck('/logout', this.basedatos.id_token, {}).then((data) => {
      if (data) {
      }
    }, (err) => {
      console.log(err);
    });
    this.basedatos.limpiarUsuarioActivoBase()
  }

  inicializar() {
    this.registerPin = false
    this.unRegisterPin = false
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.cPreguntas = true;
    this.cNvoPreguntas = true;
    this.containerCambios = false;
    this.changePwd = false;
    this.changeEma = false;
    this.infoRequest = false;
    this.questionsInfo = false;
    this.isRegisterBiometric = false;
    this.intentoBiometric = false;
    this.isDeleteBiometric = false;
    this.personalInfo = false;
    this.intentoBiometricUser = false;
    this.ratingInfo = false;
    this.containerPreguntas = true;
    this.newPwd = this.validators.passwordForm();
    this.confirmPwd = this.validators.passwordForm();
    this.correo = "";
    this.idForm = "";
    this.nombre = "";
    this.versionControl = "";
    this.numerodireccion = "";
    this.mensajeContra = null;
    this.mensajeBiometria = null;
    this.nuevoC = this.validators.emailForm();
    this.nuevoTel = this.validators.numberForm();
    for (let i in this.preguntasForm) {
      this.preguntasForm[i].reset();
    }
    for (var i = 0; i < 5; i++) {
      this.hideActualPwd[i] = true;
    }
    for (var i = 0; i < 5; i++) {
      this.hideNewPwd[i] = true;
    }
    for (var i = 0; i < 5; i++) {
      this.hideConfirmPwd[i] = true;
    }
    for (var i = 0; i < 5; i++) {
      this.hidePwdBiometric[i] = true;
    }
    try {
      document.getElementById("floatCont").style.width = '100%';
    } catch (ex) { }
  }

  close() {
    this.alerta.generarDialogoSeguro(this.idiomas).then((data) => {
      if (data) {
        this.registerPin = false
        this.unRegisterPin = false
        this.hasPin = false
        this.mostrarCron = false
        this.enableCronoIn = false
        this.containerCambios = false
        this.changePwd = false
        this.changeEma = false
        this.personalInfo = false
        this.infoRequest = false
        this.isRegisterBiometric = false
        this.isDeleteBiometric = false
        this.intentoBiometric = false
        this.questionsInfo = false
        this.intentoBiometricUser = false
        this.containerPreguntas = true
        this.ratingInfo = false
        this.cPreguntas = true
        this.cNvoPreguntas = true
        this.pinSaved = false
        this.deviceLinked = false
        this.validPin = false
        this.registerPin = false
        this.unRegisterPin = false
        this.mensajeContra = null
        this.mensajeBiometria = null;
        this.nuevoC.reset()
        this.nuevoTel.reset()
        this.tokenCo.reset()
        this.actualPwd.reset()
        this.newPwd.reset()
        this.confirmPwd.reset()
        this.observacionRating.reset()
        this.observacionDomForm.reset()
        this.usernameFormControl.reset()
        this.actualPwd.reset()
        this.usernameFormControl.enable()
        this.actualPwd.enable()
        for (let i in this.preguntasForm) {
          this.preguntasForm[i].reset()
        }
        for (var i = 0; i < 5; i++) {
          this.hideActualPwd[i] = true
        }
        for (var i = 0; i < 5; i++) {
          this.hideNewPwd[i] = true
        }
        for (var i = 0; i < 5; i++) {
          this.hideConfirmPwd[i] = true
        }
        for (var i = 0; i < 5; i++) {
          this.hidePwdBiometric[i] = true
        }
        for (let digit of this.digits) {
          digit.field.reset()
        }
        this.deviceName.reset()
        this.phoneNumber.reset()
        this.areaCode.reset()
        this.alerta.clearAlerta()
        //document.getElementById("floatCont").style.width = '100%';
        this.biometricValidation()
        this.name = ""
        this.phone = ""
        this.dateLink = ""
        this.dateRegister = ""
      }
    });
  }

  validarRespuestas() {
    for (var i = 0; i < this.preguntasForm.length; i++) {
      this.respuestasR[i] = { cpregunta: this.preguntas[i].cpregunta, respuesta: this.preguntasForm[i].value.toUpperCase() }
    }
    this.validarPreguntas()
  }

  cambioEmail() {
    let cambio: any
    this.offlineData = true
    this.intentop = true
    let envio = {
      "emailNva": this.nuevoC.value,
      "email": this.correo,
      "versioncontrol": this.versionControl,
      "otp": this.servicios.toAES(this.tokenCo.value),
      "desencriptar": '1',
      "numerodireccion": this.numerodireccion
    }
    this.apiService.postProvider2InternetCheck('/cambiomail', this.basedatos.id_token, envio).then((data: any) => {
      cambio = data
      this.alerta.presentarAlerta(cambio.message);
      this.intentop = false
      this.nuevoC.disable()
      this.tokenCo.disable()
      this.bandCheck = false
      this.mostrarCron = false;
      this.enableCronoIn = false;
    }, (err) => {
      this.mostrarCron = false;
      this.enableCronoIn = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intentop = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true
      }
      else { //Sin Internet
        this.intento = false
        this.inicializar()
      }
    })
  }

  cambioPassword() {
    let cambio: any
    this.offlineData = true
    this.intentop = true
    if (this.newPwd.value == this.confirmPwd.value) {
      let envio = {
        "contrasena": this.servicios.toAES(this.actualPwd.value),
        "contrasenaNva": this.servicios.toAES(this.newPwd.value), "desencriptar": "1",
        "biometricCredentials": this.hasUnlinkMobile ? "1" : "0"
      }
      this.apiService.postProvider2InternetCheck('/cambioclave', this.basedatos.id_token, envio).then((data: any) => {
        cambio = data
        this.alerta.presentarAlerta(cambio.message)
        this.actualPwd.disable()
        this.newPwd.disable()
        this.confirmPwd.disable()
        this.intentop = false
        this.bandCheck = false
        this.obtainHasBiometric()
        this.logoutPassChanged()
      }, (err) => {
        if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
          this.intentop = false
          if (err.error) {
            if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
            else {
              this.alerta.presentarAlertaX(err.error.mensajeUsuario)
            }
          }
          else {
            this.alerta.presentarAlerta(this.idiomas.ServidorError)
          }
          this.bandCheck = true
        }
        else { //Sin Internet
          this.intentop = false
          this.inicializar()
        }
      })
    } else {
      this.alerta.presentarAlertaX(this.idiomas.AlertCoincidencia);
      this.intento = false;
    }
  }

  obtainHasBiometricDesv() {
    const envio = {
      usuario: this.loginInfo.usuario
    }
    this.apiService.noTokenProviderInternetCheck(envio, '/consultaTieneBiometria').then((data: any) => {
      if (data) {
        if (data[0]['hasBiometricUser'] == "true" || data[0]['hasNombreDispositivoMovil'] == "true") {
          this.hasBiometricRegisterUser = '1'
        } else {
          this.hasBiometricRegisterUser = '0'
        }
        if (data[0]['hasBiometricUser'] == "true") {
          this.hasUnlinkMobile = true

        } else {
          this.hasUnlinkMobile = false
        }
      }
      this.intento = false;
    }, (err) => {
      this.hasBiometricRegister = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
        }
      }
      else {
      }
    });
  }


  obtainHasBiometric() {
    let usersBase = localStorage.getItem('users')
    const envio = {
      usuario: this.loginInfo.usuario
    }
    this.apiService.noTokenProviderInternetCheck(envio, '/consultaTieneBiometria').then((data: any) => {
      if (data) {
        if (data[0]['hasBiometricUser'] == "true") {
          this.eliminarBiometria()
          if (usersBase != null) {
            if (this.servicios.decryptAEStoString(this.users[0]['username']) == this.loginInfo.usuario) {
              this.removeUser(this.loginInfo.usuario)
              localStorage.setItem('hasBiometricPhone', this.servicios.encriptar('0'))

            }
          }
        }
      }
    }, (err) => {
      this.hasBiometricRegister = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet  
      }
    });
  }

  obtenerCorreo() {
    this.intento = true
    let obtenerC: any
    this.offlineData = true;
    let envio = {
      "transaccion": "023201-mail-vc.xml",
      "alias": "tperfir12",
      "cri^tperfir12^CPERSONA^NORMAL": "$personCode"
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      obtenerC = data
      if (obtenerC != null) {
        this.correo = obtenerC[0].DIRECCION
        this.idForm = this.basedatos.cpersona
        this.nombre = this.basedatos.nombreUsuario
        this.versionControl = obtenerC[0].VERSIONCONTROL
        this.numerodireccion = obtenerC[0].NUMERODIRECCION
        //this.obtenerTelefono()
      }
      Promise.resolve(null).then(() => this.intento = false)
      this.bandCheck = false;
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.intento = false;
        this.inicializar()
      }
    })
  }

  obtenerTelefono() {
    this.intento = true
    let obtenerC: any
    this.offlineData = true
    let envio = {
      "transaccion": "023201-telefono-vc.xml",
      "alias": "tperfir12",
      "cri^tperfir12^CPERSONA^NORMAL": "$personCode"
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      obtenerC = data
      if (obtenerC != null) {
        this.teflonoDo = obtenerC[0].NUMEROTELEFONO
        this.versionControlDo = obtenerC[0].VERSIONCONTROL
        this.codigoAreaDo = obtenerC[0].CODIGOAREA
        if (!this.teflonoDo) {
          this.nuevoTel.disable()
          this.teflonoDo = "SIN DATOS"
        }
      }
      Promise.resolve(null).then(() => this.intento = false)
      this.bandCheck = false
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true
      }
      else { //Sin Internet
        this.intento = false
        this.inicializar()
      }
    })
  }


  obtenerValidacionesPassword() {
    this.intento = true
    this.mensajeContra = null
    this.apiService.noTokenProviderInternetCheck({}, '/validacionesClave').then((data: any) => {
      this.mensajeContra = this.idiomas.mensajeContra
      let passwordValidations = Object.keys(data)
      for (let validation of passwordValidations) {
        if (data[validation] == true) {
          if (validation == 'VALIDATE_MINPASS') {
            this.mensajeContra = this.mensajeContra + "<br>*" + this.idiomas[validation].replace('$MINPASSWORDHB', data['MINPASSWORDHB']);
          }
          else {
            this.mensajeContra = this.mensajeContra + "<br>*" + this.idiomas[validation]
          }
        } else {
          if (validation == 'ONLYNUMPASSWORDHB') {
            this.mensajeContra = this.mensajeContra + "<br>*" + this.idiomas['ALPHANUMERICPASSWORDHB']
          }
        }
      }
      this.intento = false
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet        
        this.inicializar()
      }
      this.intento = false
    });
  }

  obtenerPersonaInformation() {
    this.intento = true
    let envio = {
      "transaccion": "023201-con-inforPersona.xml",
      "cri^tpd0^CPERSONA^NORMAL^tpd0^=": "$personCode",
      "cri^tp0^CPERSONA^NORMAL^tp0^=": "$personCode",
      "cri^tpd1^CPERSONA^NORMAL^tpd1^=": "$personCode",
      "cri^tpt0^CPERSONA^NORMAL^tpt0^=": "$personCode",
      "fullNames": "true"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.nombreSocio = data.ctl.NOMBRELEGAL
      if (data.tp0.length > 0) {
        this.identificacionSocio = data.tp0[0]['tp0.IDENTIFICACION']
        this.agenciaSocio = data.tp0[0]['TOFICINAS.NOMBRE'] + " " + data.tp0[0]['TSUCURSALES.NOMBRE']
      }
      if (data.tpd0.length > 0) {
        this.direccionSocio = data.tpd0[0]['tpd0.DIRECCION']
      } else {
        this.direccionSocio = 'SIN DATOS'
      }
      if (data.tpd1.length > 0) {
        this.correoSocio = data.tpd1[0]['tpd1.DIRECCION']
      }
      if (data.tpt0.length > 0) {
        this.celularSocio = data.tpt0[0]['tpt0.NUMEROTELEFONO'] ? data.tpt0[0]['tpt0.NUMEROTELEFONO'] : 'SIN DATOS'
      } else {
        this.celularSocio = 'SIN DATOS'
      }
      this.intento = false;
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intento = false;
        this.preguntas = null;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.intento = false;
      }
    })
  }

  changePassword() {
    this.registerPin = false
    this.unRegisterPin = false
    this.obtenerValidacionesPassword()
    this.idForm = this.basedatos.cpersona;
    this.nombre = this.basedatos.nombreUsuario;
    this.containerPreguntas = false
    this.changePwd = true
    this.personalInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.isDeleteBiometric = false
    this.ratingInfo = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.intentoBiometricUser = true
  }

  deviceQuery() {
    this.intento = true
    var textQR = ""
    let request = {
      transaccion: '013045-con-in.xml',
      'cri^tdst0^CUSUARIO^NORMAL^tdst0^=': "$userCode",
      'cri^tusi0^CUSUARIO^NORMAL^tusi0^=': "$userCode",
      'cri^tdep0^CUSUARIO^NORMAL^tdep0^=': "$userCode"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, request).then((data: any) => {
      if (data.tdst0.length > 0) {
        if (data.tdst0[0].FVINCULACION == "null") {
          this.pinSaved = true
          textQR = data.tdst0[0].CUSUARIO + ";" + data.tdst0[0].NOMBREDISPOSITIVO + ";" + data.tdst0[0].NUMEROTELEFONO + ";" + data.tdst0[0].FREGISTRO
          this.data = this.servicios.encriptar(textQR).toString()
          this.name = data.tdst0[0].NOMBREDISPOSITIVO
          this.phone = data.tdst0[0].NUMEROTELEFONO
          this.dateRegister = data.tdst0[0].FREGISTRO
          this.vc = data.tusi0[0].VERSIONCONTROL
        } else {
          this.deviceLinked = true
          this.name = data.tdst0[0].NOMBREDISPOSITIVO
          this.phone = data.tdst0[0].NUMEROTELEFONO
          this.dateLink = data.tdst0[0].FVINCULACION
          this.vc = data.tusi0[0].VERSIONCONTROL
          this.cEndpoint = data.tdep0.length > 0 ? data.tdep0[0].CDISPOSITIVOENDPOINT : ""
          this.hasPin = true
        }
      }
      this.obtainHasBiometricDesv()
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlerta(err.error.mensaje)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  pinRegister() {
    this.registerPin = true
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.isDeleteBiometric = false
    this.ratingInfo = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.intentoBiometricUser = false
  }

  deletePin() {
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.isDeleteBiometric = false
    this.ratingInfo = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.intentoBiometricUser = false
    this.unRegisterPin = true
  }

  unregisterDevice() {
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.isDeleteBiometric = false
    this.ratingInfo = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.intentoBiometricUser = false
    this.unRegisterPin = true
  }

  enableButton() {
    var i = 0
    for (let digit of this.digits) {
      if (digit.field.valid && (digit.field.value != '' || digit.field.value)) {
        i = i + 1
      }
    }
    if (i == this.digits.length) {
      this.validPin = true
    } else {
      this.validPin = false
    }
  }

  blockText(event) {
    if (isNaN(event.key) && event.key !== 'Tab' && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  focusNext(i, event) {
    if (i < this.digits.length - 1 && event.key !== 'Tab' && event.key !== 'Backspace' && (!isNaN(event.key) || event.keyCode === 229) && !isNaN(this.digits[i].field.value)) {
      this.renderer.selectRootElement('#myInput' + (i + 1)).focus();
    }

    if (i == 5 && event.key === 'Tab') {
      event.preventDefault();
    }

    if (i > 0 && event.key === 'Backspace') {
      this.digits[i].field.reset();
      this.renderer.selectRootElement('#myInput' + (i - 1)).focus();
    }
  }

  changeValue(i, event) {
    if (!isNaN(event.key)) {
      this.digits[i].field.setValue(event.key);
    }
  }

  deviceRegister() {
    this.intentop = true
    this.validPin = false
    var date = new Date()
    var registerDate = date.getFullYear() + "-" + this.anadirCero(date.getMonth() + 1) + "-" + this.anadirCero(date.getDate()) + " " + this.anadirCero(date.getHours()) + ":" + this.anadirCero(date.getMinutes()) + ":" + this.anadirCero(date.getSeconds())
    var pin = this.digits[0].field.value + this.digits[1].field.value + this.digits[2].field.value + this.digits[3].field.value + this.digits[4].field.value + this.digits[5].field.value
    var textQR = this.basedatos.usuarioHB + ";" + this.deviceName.value.toUpperCase() + ";" + this.areaCode.value + " " + this.phoneNumber.value + ";" + registerDate
    let request = {
      transaccion: "013045-man-in.xml",
      "rec^tdst0^0^CUSUARIO^tdst0": "$userCode",
      "rec^tdst0^0^NOMBREDISPOSITIVO^tdst0": this.deviceName.value.toUpperCase(),
      "rec^tdst0^0^NUMEROTELEFONO^tdst0": this.areaCode.value + " " + this.phoneNumber.value,
      "rec^tdst0^0^PIN^tdst0": pin,
      "rec^tdst0^0^DATOSADICIONALES^tdst0": textQR,
      "rec^tdst0^0^FREGISTRO^tdst0": registerDate,
      "ctl^CPERSONA_PIN": "$personCode",
      "ctl^ADD_AREA_CODE": "true"
    }
    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, request).then((data: any) => {
      this.intentop = false
      this.pinSaved = true
      this.data = this.servicios.encriptar(textQR).toString()
    }, (err) => {
      this.intentop = false
      this.validPin = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensaje)
          }
        }
        else {
        }
      }
    });
  }

  deviceUnRegister() {
    this.intentop = true
    let request = {
      transaccion: "013045-man-in-cad.xml",
      "rec^tdst0^0^CUSUARIO^tdst0": "$userCode",
      "rec^tdst0^0^NOMBREDISPOSITIVO^tdst0": this.name,
      "rec^tdst0^0^NUMEROTELEFONO^tdst0": this.phone,
      "rec^tdst0^0^PIN^tdst0": "000000",
      "rec^tusi0^0^CUSUARIO^tusi0": "$userCode",
      "rec^tusi0^0^SOFTOKENACTIVO^tusi0^VAL": null,
      "rec^tusi0^0^SOFTOKENACTIVO^tusi0^oldval": "1",
      "rec^tusi0^0^VERSIONCONTROL^tusi0^VAL": this.vc,
      "rec^tusi0^0^VERSIONCONTROL^tusi0^oldval": this.vc
    }
    if (this.cEndpoint != "") {
      request["transaccion"] = "013045-man-in-cad-end.xml"
      request["rec^tdep0^0^CDISPOSITIVOENDPOINT^tdep0"] = this.cEndpoint
    }
    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, request).then((data: any) => {
      this.intentop = false
      this.basedatos.editarConsulta('pinValidation', false)
      this.retornarPin()
    }, (err) => {
      this.intentop = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
        }
      }
    });
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  personalInformation() {
    this.registerPin = false
    this.unRegisterPin = false
    this.nombreSocio = this.loginInfo.nombreUsuario
    this.obtenerPersonaInformation()
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = true
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.isDeleteBiometric = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.ratingInfo = false
    this.intentoBiometricUser = true
  }

  registerBiometric() {
    this.users = this.serverMockService.getUsers();
    let usersBase = localStorage.getItem('users')
    if (this.hasBiometricRegisterUser == '0') {
      this.nombreSocio = this.loginInfo.nombreUsuario
      this.usernameFormControl.setValue(this.loginInfo.usuario)
      this.usernameFormControl.disable()
      this.containerPreguntas = false
      this.changePwd = false
      this.personalInfo = false
      this.infoRequest = false
      this.intentoBiometricUser = false
      this.questionsInfo = false
      this.isRegisterBiometric = true
      this.isDeleteBiometric = false
      this.deshabilitar = true
      this.cPreguntas = false
      this.cNvoPreguntas = false
      this.ratingInfo = false
      this.mensajeBiometria = this.idiomas.RegistrarHuellaBA
    } else {
      if (usersBase != null) {
        if (this.servicios.decryptAEStoString(this.users[0]['username']) == this.loginInfo.usuario) {
          this.alerta.generarDialogo(this.idiomas, 'HuellaRegistradaTelUsu')
        } else {
          this.alerta.generarDialogo(this.idiomas, 'HuellaRegistradaTel')
        }
      } else if (this.hasBiometricPhone == '1') {
        this.alerta.generarDialogo(this.idiomas, 'HuellaRegistradaTel')
      } else if (this.hasBiometricRegisterUser != '0') {
        this.alerta.generarDialogo(this.idiomas, 'HuellaRegistradaTelUsu')
      }
    }
  }

  unlinkBiometric() {
    this.registerPin = false
    this.unRegisterPin = false
    this.nombreSocio = this.loginInfo.nombreUsuario
    this.usernameFormControl.setValue(this.loginInfo.usuario)
    this.usernameFormControl.disable()
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.intentoBiometricUser = false
    this.isRegisterBiometric = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.ratingInfo = false
    this.isDeleteBiometric = true
    this.mensajeBiometria = this.idiomas.RegistrarHuellaDBA
  }

  eliminarBiometria() {
    this.intento = true
    let envio = {
      idBiometric: "0"
    }
    this.apiService.postProvider2InternetCheck('/registrarIdBiometrico', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = false
      this.actualPwd.disable()
      this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase())
      localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('1'))
      localStorage.setItem('hasBiometricPhone', this.servicios.encriptar('0'))
      this.intentoBiometric = true
    }, (err) => {
      this.intentoBiometric = false
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    })
  }

  validatePasswordForDeleteBiometric() {
    this.intento = true
    let envio = {
      contrasena: this.servicios.toAES(this.actualPwd.value),
      desencriptar: "1"
    }

    this.apiService.postProvider2InternetCheck('/validarClave', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = false
      this.eliminarBiometria()
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    })
  }

  //Soporte FIDO
  removeUser(username: any) {
    this.serverMockService.removeUser(username)
    this.users = this.serverMockService.getUsers();
    localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('1'))
  }

  registrarBiometria() {
    //En caso de un password exitoso, solicitar si desea registrar la huella
    this.alerta.generarDialogoSeguroRegistrarHuella(this.idiomas).then((data) => {
      if (data) {
        //Este componente resuelve la suscripcion de la promesa INMEDIATAMENTE luego de abrirse
        this.alerta.generarDialogoEsperando(this.idiomas, this.idiomas.RegistrandoHuella).then((data) => {
          //En este caso, no nos interesa si data viene con algun evento/valor... llamar directamente el signup
          //Al abrir el dialogo, ya nos debe solicitar el registro de huella
          this.signup();
          this.actualPwd.disable()
        });
      }
    });
  }

  signup() {
    // Guardar el usuario en la 'DB' local
    const prevUser = this.serverMockService.getUser(this.usernameFormControl.value);
    if (prevUser) {
      this.alerta.presentarAlertaX(this.idiomas.HuellaRegistradaTelUsu.toUpperCase())
      return;
    }
    const user: User = this.serverMockService.addUser({ username: this.servicios.toAES(this.usernameFormControl.value), credentials: [] });
    this.users = this.serverMockService.getUsers();
    // Preguntar si existe el metodo de autenticacion usando WebAuthn
    this.webAuthnService.webAuthnSignup(user)
      .then((credential: PublicKeyCredential) => {
        //console.log('credentials.create RESPONSE', credential); //Esta respuesta quizas pueda estar almacenada en el REST/BDD
        const valid = this.serverMockService.registerCredential(user, credential);
        this.users = this.serverMockService.getUsers();
        this.insertIdBiometric(this.users[0]['id'])

      }).catch((error) => {
        //Quizas haya una mejor manera de cerrar un dialogo ?
        var element = document.getElementById("btnCloseWaitingDialog");
        element && element.click();
        localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('0'))
        this.alerta.presentarAlertaX(this.idiomas.ErrorRegistrarHuellaExitosa.toUpperCase())

        //console.log('credentials.create ERROR', error);
      });
  }


  validatePasswordForRegisterBiometric() {
    this.intento = true
    let envio = {
      contrasena: this.servicios.toAES(this.actualPwd.value),
      desencriptar: "1"
    }

    this.apiService.postProvider2InternetCheck('/validarClave', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = false
      this.registrarBiometria()
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    })
  }

  insertIdBiometric(idBiometric: any) {
    this.intento = true
    let envio = {
      idBiometric: idBiometric
    }

    this.apiService.postProvider2InternetCheck('/registrarIdBiometrico', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = false
      this.actualPwd.disable()

      //Mostrar un dialogo indicando que la huella se registró OK
      this.alerta.generarDialogo(this.idiomas, "RegistrarHuellaExitosa").then((data) => {
        if (data) {
          //Quizas haya una mejor manera de cerrar un dialogo ?
          var element = document.getElementById("btnCloseWaitingDialog");
          element && element.click();
        }
      });

      localStorage.setItem('hasBiometricPhone', this.servicios.encriptar('1'))
      this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase())
      this.intentoBiometric = true
    }, (err) => {
      this.intentoBiometric = false
      this.intento = false;
      this.removeUser(this.loginInfo.usuario)

      //Con esto cerramos el dialogo si existe un error automaticamente
      var element = document.getElementById("btnCloseWaitingDialog");
      this.alerta.presentarAlertaX(this.idiomas.ErrorRegistrarHuellaExitosa.toUpperCase())
      element && element.click();

      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    })
  }

  contactAs() {
    this.registerPin = false
    this.unRegisterPin = false
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = false
    this.infoRequest = true
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.isDeleteBiometric = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.ratingInfo = false
    this.intentoBiometricUser = false
  }

  rateInformation() {
    this.registerPin = false
    this.unRegisterPin = false
    this.ratingArr = [];
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = false
    this.ratingInfo = true
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.intentoBiometric = false
    this.isDeleteBiometric = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.intentoBiometricUser = false
  }

  onClick(rating: number) {
    this.rating = rating;
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  questInformation() {
    this.registerPin = false
    this.unRegisterPin = false
    this.containerPreguntas = false
    this.changePwd = false
    this.personalInfo = false
    this.infoRequest = false
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.ratingInfo = false
    this.questionsInfo = true
    this.isRegisterBiometric = false
    this.intentoBiometric = false
    this.isDeleteBiometric = false
    this.intentoBiometricUser = true
  }

  changeEmail() {
    this.registerPin = false
    this.unRegisterPin = false
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.obtenerCorreo()
    this.containerPreguntas = false
    this.changeEma = true
    this.deshabilitar = true
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.ratingInfo = false
    this.ingtokenCo = false
    this.intentoBiometricUser = true
  }

  retornarPE() {
    this.validPin = false
    this.registerPin = false
    this.unRegisterPin = false
    this.mostrarCron = false
    this.enableCronoIn = false
    document.querySelector('.mat-sidenav-content').scrollTop = 0
    this.containerPreguntas = false
    this.containerCambios = true
    this.deshabilitar = false
    this.changeEma = false
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.personalInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.intentoBiometric = false
    this.isDeleteBiometric = false
    this.intentoBiometricUser = false
    this.ingtokenCo = false
    this.correo = ""
    this.idForm = ""
    this.nombre = ""
    this.versionControl = ""
    this.numerodireccion = ""
    this.nuevoC = this.validators.emailForm()
    this.nuevoTel = this.validators.numberForm()
    this.tokenCo = this.validators.otpForm()
    this.alerta.clearAlerta()
    this.usernameFormControl.reset()
    this.actualPwd.reset()
    this.usernameFormControl.enable()
    this.actualPwd.enable()
  }

  retornarPC() {
    this.validPin = false
    this.registerPin = false
    this.unRegisterPin = false
    this.changePwd = false
    this.mostrarCron = false
    this.enableCronoIn = false
    document.querySelector('.mat-sidenav-content').scrollTop = 0;
    this.containerPreguntas = false
    this.containerCambios = true
    this.deshabilitar = false
    this.changePwd = false
    this.personalInfo = false
    this.ratingInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.intentoBiometric = false
    this.isDeleteBiometric = false
    this.intentoBiometricUser = false
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.ingtokenCo = false
    this.actualPwd = new UntypedFormControl('', Validators.required)
    this.newPwd = this.validators.passwordForm()
    this.confirmPwd = this.validators.passwordForm()
    this.alerta.clearAlerta()
    this.usernameFormControl.reset()
    this.deviceName.reset()
    this.actualPwd.reset()
    this.areaCode.reset()
    this.phoneNumber.reset()
    for (let digit of this.digits) {
      digit.field.reset()
    }
    this.usernameFormControl.enable()
    this.actualPwd.enable()
    this.biometricValidation()
  }

  retornarPin() {
    this.registerPin = false
    this.unRegisterPin = false
    this.containerCambios = true
    document.querySelector('.mat-sidenav-content').scrollTop = 0
    this.containerPreguntas = false
    this.containerCambios = true
    this.deshabilitar = false
    this.changeEma = false
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.personalInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.intentoBiometric = false
    this.isDeleteBiometric = false
    this.intentoBiometricUser = false
    this.ingtokenCo = false
    this.pinSaved = false
    this.deviceLinked = false
    this.validPin = false
    for (let digit of this.digits) {
      digit.field.reset()
    }
    this.deviceName.reset()
    this.phoneNumber.reset()
    this.areaCode.reset()
    this.name = ""
    this.phone = ""
    this.dateLink = ""
    this.dateRegister = ""
    this.vc = ""
    this.cEndpoint = ""
    this.alerta.clearAlerta()
    this.deviceQuery()
  }


  retornar() {
    this.validPin = false
    this.registerPin = false
    this.unRegisterPin = false
    this.mostrarCron = false
    this.enableCronoIn = false
    document.querySelector('.mat-sidenav-content').scrollTop = 0
    this.containerPreguntas = false
    this.containerCambios = true
    this.deshabilitar = false
    this.changePwd = false
    this.personalInfo = false
    this.ratingInfo = false
    this.infoRequest = false
    this.questionsInfo = false
    this.isRegisterBiometric = false
    this.intentoBiometricUser = false
    this.intentoBiometric = false
    this.isDeleteBiometric = false
    this.cPreguntas = false
    this.cNvoPreguntas = false
    this.actualPwd = new UntypedFormControl('', Validators.required)
    this.newPwd = this.validators.passwordForm();
    this.confirmPwd = this.validators.passwordForm();
    this.alerta.clearAlerta();
    this.usernameFormControl.reset()
    this.actualPwd.reset()
    this.usernameFormControl.enable()
    this.actualPwd.enable()
  }

  //Funcinamiento con tecla enter
  usrEnter() {
    if (this.preguntasForm[0].valid && this.preguntasForm[1].valid && this.preguntasForm[2].valid && this.cPreguntas == true) {
      this.validarRespuestas()
    }
  }

  pasEnter() {
    if (this.actualPwd.valid && this.newPwd.valid && this.confirmPwd.valid && this.changePwd == true && this.cPreguntas == false) {
      this.cambioPassword();
    }
  }

  corEnter() {
    if (this.nuevoC.valid && this.changeEma == true && this.cPreguntas == false) {
      this.cambioEmail()
    }
  }

  cdkDropListDroppedHandler(event: CdkDragDrop<any[]>) {
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex);
  }

  mouseEnterHandler(event: MouseEvent, chapterExpansionPanel: MatExpansionPanel) {
    if (event.buttons && !chapterExpansionPanel.expanded) {
      chapterExpansionPanel.open();
    }
  }

  chapters = [
    {
      id: 1,
      name: '¿Cómo puedo cambiar mi clave?',
      items: [
        {
          id: 1,
          name: 'Ingresa a tu coop virtual y dirigete a administración de cuenta, allí podrás modificar tu clave de acceso.'
        }
      ]
    },
    {
      id: 2,
      name: 'Se me bloqueo mi usuario, cómo activarlo',
      items: [
        {
          id: 2,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos y selecciona la opción 1️⃣ Reestablecer clave'
        }
      ]
    },
    {
      id: 3,
      name: 'No recuerdo mi usuario de Coop virtual',
      items: [
        {
          id: 3,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos y selecciona la opción 3️⃣ Recordar usuario'
        }
      ]
    },
    {
      id: 4,
      name: 'Quiero recibir asesoria personalizada para activar mi usuario',
      items: [
        {
          id: 4,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos y selecciona la opción 4️⃣ Comunicarse con un asesor'
        }
      ]
    },
    {
      id: 5,
      name: 'Quiero cambiar mi correo electrónico y número de teléfono',
      items: [
        {
          id: 5,
          name: 'Para mayor seguridad es recomendable acercarse a la agencia PJL más cercana.'
        }
      ]
    },
    {
      id: 6,
      name: '¿Cuál es la dirección de sus agencias?',
      items: [
        {
          id: 10,
          name: 'Ingresa en https://lorente.fin.ec/agencias/  y identifica la agencia más cercana.'
        }
      ]
    },
    {
      id: 7,
      name: 'Mi usuario se bloqueo totalmente , cómo lo reactivo',
      items: [
        {
          id: 7,
          name: 'Acércate a la agencia más cercana y te ayudarán con el desbloqueo.'
        }
      ]
    },
    {
      id: 8,
      name: '¿Tienes problemas con Coop virtual PJL?',
      items: [
        {
          id: 8,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos de 8:30 a 18:00 y te ayudarán con asesoría'
        }
      ]
    }
  ];

  funcionRetornarPaises(e) {
    if (!this.intentoPaises) {
      this.retornarPaises();
    }
  }

  retornarPaises() {
    this.intentoPaises = false
    this.lpaisesInicial = []
    let paisAux: IPaises = {
      id: "",
      codigoArea: "",
      nombre: ""
    }
    let request = {
      transaccion: "010003-lv-paises-in.xml",
      alias: "tpa0"
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, request).then((data: any) => {
      for (const pais of data) {
        paisAux.id = pais.CPAIS;
        paisAux.codigoArea = pais.CODIGOAREA;
        paisAux.nombre = pais.DESCRIPCION;
        this.lpaises.push(paisAux);
        paisAux = {
          id: "",
          codigoArea: "",
          nombre: ""
        }
      }
      this.lpaisesInicial = this.lpaises
      this.lfiltradoPaises = this.lpaises
      this.intentoPaises = true
    }, (err) => {
      this.intentoPaises = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  //Método para filtrar la lista de bancos
  applyFilter(filterValue: any) {
    if (filterValue) {
      this.lpaises = this.lfiltradoPaises.filter((banco) => (banco.id + banco.codigoArea).indexOf(filterValue) > -1);
    } else {
      this.lpaises = this.lpaisesInicial
    }
  }
}

export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}

interface IPaises {
  id: string,
  codigoArea: string,
  nombre: string
}