import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet';
import { FormControlValidators } from '../funciones/formcontrol';
import { Router } from '@angular/router';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { PagoServiciosComponent } from '../pago-servicios/pago-servicios.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatProgressBarModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, PagoServiciosComponent],
  selector: 'app-pago-servicios-menu',
  templateUrl: './pago-servicios-menu.component.html',
  styleUrls: ['./pago-servicios-menu.component.css']
})
export class PagoServiciosMenuComponent implements OnInit {

  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() idiomas: any;
  @Input() logoTemaOscuro: boolean;
  @Input() pendingTaskData: any;

  //Selected Index de Home
  @Input() selectedIndex: any;
  index: number = 20;
  //.
  @Output() regServEv = new EventEmitter<any>()
  bandsFuncion: any;
  band: any;
  dataTarPend: any;
  intento: boolean;
  servicios: any[];
  nomServ: string;
  bandMobile: boolean;
  valueIcon: string;

  constructor(public apiService: ApiService,
    public alerta: Alerta,
    private router: Router,
    public basedatos: BaseDatos, public internet: internetComponent, private validators: FormControlValidators) {
    this.bandsFuncion = {
      'habilitarMenu': true,
      'servNuevos': false,
      'servRegistrados': false
    }
    this.dataTarPend = {};
    this.servicios = [];
    this.band = false;
    this.intento = false;
    this.bandMobile = false;
  }

  ngOnInit() {
    this.bandsFuncion = {
      'habilitarMenu': true,
      'servNuevos': false,
      'servRegistrados': false
    }
    this.retornarGrupoServicios()
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  ngDoCheck() {
    if (!this.bandsFuncion.habilitarMenu && (this.selectedIndex != this.index)) {
      this.habilitarFunciones('habilitarMenu', null, null);
    }
  }

  habilitarFunciones(funcionalidad, nombre, data) {
    this.valueIcon = localStorage.getItem('nombre');
    this.nomServ = nombre;
    this.dataTarPend = data;
    this.bandsFuncion = {
      'habilitarMenu': false,
      'servNuevos': false,
      'servRegistrados': false
    }
    localStorage.setItem('nombre', nombre);
    this.bandsFuncion[funcionalidad] = true;
    window.scroll(0, 0);
  }

  retornarGrupoServicios() { //Retornar Servicios por Grupo
    this.intento = true;
    let response = {
      NOMBRESCONTRATO: ''
    }
    let enviobancos = {
      transaccion: '010003-lv-tipos-servicio-in.xml',
      alias: 'tpsc0'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviobancos).then((data: any) => {
      if (data) {
        for (let servicio of data) {
          if (servicio.NOMBRESCONTRATO != 'RECARGAS' && servicio.NOMBRESCONTRATO) {
            response.NOMBRESCONTRATO = servicio.NOMBRESCONTRATO
            this.servicios.push(response)
          }
          response = {
            NOMBRESCONTRATO: ''
          }
        }
        this.intento = false
        this.band = true
        this.basedatos.insertarConsulta('GruposServicios', this.servicios); //Inserta Lista de Servicios Disponibles
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { //Sin Internet
        this.intento = false
        this.servicios = this.basedatos.retornarConsulta('GruposServicios');
        if (!this.servicios) {
          this.servicios = []
        }
        this.band = true
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  registrarServicio(event) {
    this.regServEv.emit(event)
  }
}
