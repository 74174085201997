import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatBadgeModule, MatDividerModule, MatExpansionModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-menu-inferior',
  templateUrl: './menu-inferior.component.html',
  styleUrls: ['./menu-inferior.component.css']
})
export class MenuInferiorComponent {

  idiomas: any;
  tabIndex = 0;
  notificaciones: any;
  numTareasPendientes: any;

  tema: string;
  temaPrev: string;
  constructor(private menuInferior: MatBottomSheetRef<MenuInferiorComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) data) {
    this.idiomas = data.idm
    this.notificaciones = data.notificaciones
    this.numTareasPendientes = data.numTareasPendientes
    this.tema = localStorage.getItem('themeDisplayed');
    this.temaPrev = this.tema;
  }

  onTabClick(index) {
    this.tabIndex = index;
  }

  move(option) {
    this.menuInferior.dismiss(option);
  }

  openEComm(option) {
    this.menuInferior.dismiss(option);
  }

  logout(option) {
    this.menuInferior.dismiss(option);
  }

}
