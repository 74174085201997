import 'hammerjs'
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG, } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

declare var Hammer: any;
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {

    buildHammer(element: HTMLElement) {
        var aux = window.navigator.userAgent.toLowerCase();
        var band = /iphone|ipad|ipod/.test(aux);
        if (band) { //Configuracion para iOS
            let mc = new Hammer(element, {
                touchAction: "auto",
            });
            return mc;
        }
        else {      //Configuracion para el resto
            let mc = new Hammer(element, {
                touchAction: "pan-y",
            });
            return mc;
        }
    }
}