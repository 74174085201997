import { Injectable } from '@angular/core';
import { ClientDataObj } from '../interfaces/client-data-obj';
import { DecodedAttestionObj } from '../interfaces/decoded-attestion-obj';
import { User } from '../interfaces/user';
import * as CBOR from '../utils/cbor';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ServerMockService {

  constructor(private userService: UserService) { }

  // Validate and Store credential
  registerCredential(user: User, credential: PublicKeyCredential): boolean {
    const authData = this.extractAuthData(credential);
    const credentialIdLength = this.getCredentialIdLength(authData);
    const credentialId: Uint8Array = authData.slice(55, 55 + credentialIdLength);
    //console.log('credentialIdLength', credentialIdLength);
    //console.log('credentialId', credentialId);
    const publicKeyBytes: Uint8Array = authData.slice(55 + credentialIdLength);
    const publicKeyObject = CBOR.decode(publicKeyBytes.buffer);
    //console.log('publicKeyObject', publicKeyObject);

    const valid = true;

    if (valid) {
      user.credentials.push({ credentialId, publicKey: publicKeyBytes });
      this.updateUser(user);
    }

    return valid;
  }

  getCredentialIdLength(authData: Uint8Array): number {
    // get the length of the credential ID
    const dataView = new DataView(new ArrayBuffer(2));
    const idLenBytes = authData.slice(53, 55);
    idLenBytes.forEach((value, index) => dataView.setUint8(index, value));
    return dataView.getUint16(0);
  }

  extractAuthData(credential: PublicKeyCredential): Uint8Array {
    // decode the clientDataJSON into a utf-8 string
    const utf8Decoder = new TextDecoder('utf-8');
    const decodedClientData = utf8Decoder.decode(credential.response.clientDataJSON);

    const clientDataObj: ClientDataObj = JSON.parse(decodedClientData);
    //console.log('clientDataObj', clientDataObj);

    const decodedAttestationObj: DecodedAttestionObj = CBOR.decode((credential.response as any).attestationObject);
    //console.log('decodedAttestationObj', decodedAttestationObj);

    const { authData } = decodedAttestationObj;
    //console.log('authData', authData);

    return authData;
  }

  getUsers() {
    return this.userService.getUsers();
  }

  updateUser(user: User) {
    this.removeUser(user.username);
    this.addUser(user);
  }

  addUser(user: User) {
    user.id = '' + Math.floor(Math.random() * 10000000);
    this.userService.addUser(user);
    return user;
  }

  getUser(username: string) {
    return this.userService.getUser(username);
  }

  removeUser(username: string) {
    return this.userService.removeUser(username);
  }

  getChallenge() {
    return Uint8Array.from('someChallengeIsHereComOn', c => c.charCodeAt(0));
  }

  deleteUser(){
    return this.userService.deleteUser();
  }

  /**
   * Indica si estamos en un dispositivo movil
   * @returns true si estamos en un dispositivo movil
   */
  isMobile() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  /**
   * Indica si estamos en un explorador EDGE
   * @returns true si estamos en un explorador EDGE
   */
  isEdge() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /edge/.test(userAgent);
  }

  /**
   * Indica si estamos en un dispositivo ANDROID
   * @returns true si estamos en un dispositivo ANDROID
   */
  isAndroid() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  }

  /**
   * Indica si estamos en un dispositivo APPLE
   * @returns true si estamos en un dispositivo APPLE
   */
  isIphone() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  /**
   * Indica si estamos en un computador de escritorio (o laptop)
   * @returns true si estamos en un computador de escritorio (o laptop)
   */
  isInStandaloneMode() {
    return ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
  }
}
