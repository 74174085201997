import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogModule } from "@angular/material/dialog";
import { Servicios } from '../funciones/encryptar';
import { Router } from '@angular/router';
import { BaseDatos } from '../funciones/basededatos';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-offline',
  templateUrl: './dialogo-offline.component.html',
  styleUrls: ['./dialogo-offline.component.css']
})
export class DialogoOfflineComponent {
  
  fecha: any;
  hora: any;
  id: any;
  cuenta: any;
  salir: any;
  idioma: any;

  constructor(private router: Router, public baseDatos: BaseDatos, private todosDialogsRef: MatDialog, private servicio: Servicios, private dialogRef: MatDialogRef<DialogoOfflineComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.fecha = data.info.fecha;
    this.hora = data.info.hora;
    this.id = data.info.cedula;
    this.cuenta = data.info.cuenta;
    this.salir = data.info.salir;
    this.idioma = data.idioma;
  }

  close() {
    this.dialogRef.close();
  }

  logOut() {
    this.todosDialogsRef.closeAll(); //Cierra todos los dialogos para evitar el mensaje de logout por token vencido en las peticiones
    this.router.navigate(['']);
    this.baseDatos.limpiarUsuarioActivoBase()
  }
}
