import { Injectable } from '@angular/core';

@Injectable()
export class MostrarPDF {

  constructor() {
  }

  mostrarReportePDF(pdf: string) {
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|android/.test(userAgent);
    }
    const isEdge = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /edge/.test(userAgent);
    }
    const isAndroid = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /android/.test(userAgent);
    }
    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    if (isMobile() && !isInStandaloneMode()) {
      const linkSource = 'data:application/pdf;base64,' + pdf
      const downloadLink = document.createElement("a")
      downloadLink.href = linkSource
      downloadLink.target = "about:blank"
      downloadLink.click()
    } else {
      if (isEdge() && !isInStandaloneMode()) {
        var byteCharacters = atob(pdf);
        var fileName = "reporte.pdf";
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/pdf' });
        //window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        var base64URL = 'data:application/pdf;base64,' + pdf
        var win = window.open("about:blank")
        if (win == null || typeof (open) == 'undefined')
          alert("Turn off window  blocker!");
        else {
          win.document.write('<body style="margin:0">' +
            '<iframe src="' + base64URL + '" frameborder="0" style="width:100%; height:100%; margin:0; padding:0; position: relative" name = "reporte_transferencia" allowfullscreen></iframe>' +
            '</body>')
        }
      }
    }
  }
}