import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BaseDatos } from "../funciones/basededatos";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CantidadMonetaria, FormatoFecha, HoraSubString1, CambiarVariableCuenta, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatProgressBarModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFecha, HoraSubString1, CambiarVariableCuenta, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-certificados',
  templateUrl: './dialogo-certificados.component.html',
  styleUrls: ['./dialogo-certificados.component.css']
})
export class DialogoCertificadosComponent implements OnInit {

  er: boolean = false;
  pdfUrls: SafeResourceUrl;
  aceptar: string;
  rechazar: string;
  mensaje: string;
  idioma: any;
  tipo: string;
  band: boolean;
  imagenes: any;
  mostrar: boolean = false;
  mostrarTexto: boolean = false;
  dataLocalUrl: any;
  innerWidth: any;
  innerHeight: any;

  pdfURL = "";
  srcF: any;
  page = 1;
  validadorEnable = false;
  certificadosEnable = false;
  downloadOption:boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DialogoCertificadosComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public baseDatos: BaseDatos,
    private sanitizer: DomSanitizer
  ) {


    this.pdfURL = data.pdfConverted;
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.idioma = data.idm1;
    this.mensaje = data.mensaje;
    this.band = data.band;
    this.tipo = data.tipo;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.downloadOption = data.downloadOption

  }
  pdftoBlob(pdf: string) {
    const byteCharacters = atob(pdf);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/pdf" });
    return blob;
  }
  ngOnInit(): void {

    this.validadorEnable = true;
    var blob = this.pdftoBlob(this.pdfURL);
    this.srcF = window.URL.createObjectURL(blob);
    if (this.tipo == 'mostrar') {
      this.mostrarTexto = false;
      this.mostrar = true;
    } else {
      this.mostrar = false;
      this.mostrarTexto = true;
    }

  }

  accept() {
    this.dialogRef.close(this.er = true);
  }

  close() {
    this.dialogRef.close(this.er);
  }

}
