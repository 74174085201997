<div class="textura">
    <mat-card *ngIf="error" class="error">
        <p [innerHTML]="mensaje" [class.normal]=normal [class.mediano]=mediano [class.grande]=grande></p>
    </mat-card>
    <mat-card *ngIf="alertalogin" class="advertencia">
        <p [innerHTML]="mensaje" [class.normal]=normal [class.mediano]=mediano [class.grande]=grande></p>
    </mat-card>
    <mat-card *ngIf="alertaloginpass" class="advertenciapass">
        <p [innerHTML]="mensaje" [class.normal]=normal [class.mediano]=mediano [class.grande]=grande></p>
    </mat-card>
    <mat-card *ngIf="information" class="informacion">
        <p [innerHTML]="mensaje" [class.normal]=normal [class.mediano]=mediano [class.grande]=grande></p>
    </mat-card>
    <mat-card *ngIf="alertaBiometricCredential" class="alertaBiometricCredential">
        <p [innerHTML]="mensaje" [class.normal]=normal [class.mediano]=mediano [class.grande]=grande></p>
    </mat-card>
</div>