<div id="login">
    <div *ngIf="!bandMobile" #toolBar id="tool-bar" class="static-toolbar">
        <mat-toolbar class="cabecera" [class.cabecera-mob]="bandMobile" [class.offline]=!internetC.internet>
            <mat-toolbar-row class="form-row" style="justify-content: center;">
                <img *ngIf="logoTemaOscuro" class="col-12" src="assets/resources/mipmap-hdpi/logo_clientDark.png"
                    class="imagen" [class.offline]=!internetC.internet alt="Fit-Bank">
                <img *ngIf="!logoTemaOscuro" class="col-12" src="assets/resources/mipmap-hdpi/logo_client.png"
                    class="imagen" [class.offline]=!internetC.internet alt="Fit-Bank">
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <div *ngIf="bandMobile && isUserEnt" #toolBar id="tool-bar" class="static-toolbar">
        <mat-toolbar class="cabecera" [class.cabecera-mob]="bandMobile" [class.offline]=!internetC.internet>
            <mat-toolbar-row class="form-row" style="justify-content: center;">
                <img *ngIf="logoTemaOscuro" class="col-12" src="assets/resources/mipmap-hdpi/imagen-login.jpg"
                    class="imagen" [class.offline]=!internetC.internet alt="Fit-Bank">
                <img *ngIf="!logoTemaOscuro" class="col-12" src="assets/resources/mipmap-hdpi/imagen-login.jpg"
                    class="imagen" [class.offline]=!internetC.internet alt="Fit-Bank">
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <div class="under-toolbar" *ngIf="!bandMobile && !isUserEnt">
        <div class="formMaxWidthInit">
            <div style="margin-bottom: 0px">
                <div class="form-row contentMenu" style="background-color: transparent !important;">
                    <div style="padding-bottom: 10px;"
                        *ngIf="bandsFuncionLogin['1'] || bandsFuncionLogin['2'] || bandsFuncionLogin['3'] || bandsFuncionLogin['4']">
                        <a class="form-row returnContainer" (click)="returnInicioI()" style="max-width: 85px;">
                            <mat-icon class="returnArrow col">arrow_back_ios
                            </mat-icon>
                            <div class="returnLink col">{{idiomas.Regresar}}</div>
                        </a>
                    </div>
                    <mat-progress-bar *ngIf=intento mode="indeterminate"></mat-progress-bar>
                    <div class="col-12" style="margin-top: auto; margin-bottom: auto;">
                        <div *ngIf="bandsFuncionLogin['0']" class="customCarouselContainer">
                            <owl-carousel-o [options]="customCarouselOptions">
                                <ng-container>
                                    <ng-template carouselSlide>
                                        <img class="carouselImageWeb" src="assets/resources/mipmap-hdpi/imagen-1.jpg">
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <img class="carouselImageWeb" src="assets/resources/mipmap-hdpi/imagen-2.jpg">
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <img class="carouselImageWeb" src="assets/resources/mipmap-hdpi/imagen-3.jpg">
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <img class="carouselImageWeb" src="assets/resources/mipmap-hdpi/imagen-4.jpg">
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                        <div *ngIf="bandsFuncionLogin['2']">
                            <div *ngIf="internetC.internet" style="text-align: center;">
                                <div>
                                    <h1 class="title" style="font-size: 20px;">
                                        <strong>{{idiomas.Contactos}}</strong>
                                    </h1>
                                </div>
                                <br>
                            </div>
                            <p><strong>{{idiomas.MsgTelefono}}</strong></p>
                            <ul>
                                <li><a class="link-login" href="https://www.bancodelaustro.com/" target="_blank">Web</a>
                                </li>
                                <li>Call Center: 1800 228787</li>
                            </ul>
                            <p><a href="https://www.facebook.com/bancodelaustro" target="_blank"><img alt=""
                                        src="assets/resources/mipmap-hdpi/iconos/facebook.png"
                                        style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                    href="https://www.instagram.com/bancodelaustro/" target="_blank"><img alt=""
                                        src="assets/resources/mipmap-hdpi/iconos/instagram.png"
                                        style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                    href="https://twitter.com/i/flow/login?redirect_after_login=%2Fbancodelaustro"
                                    target="_blank"><img alt="" src="assets/resources/mipmap-hdpi/iconos/twitter.png"
                                        style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                    href="https://www.youtube.com/channel/UCk7goI-O9_adFDfXwSX9G_A" target="_blank"><img
                                        alt="" src="assets/resources/mipmap-hdpi/iconos/youtube.png"
                                        style="width: 24px; height: 24px;"></a>
                            </p>
                            <br>
                        </div>
                        <div *ngIf="bandsFuncionLogin['3']">
                            <app-agencias-mapa [idiomas]="idiomas"></app-agencias-mapa>
                        </div>
                        <div *ngIf="bandsFuncionLogin['4']">
                            <app-cajeros-mapa [idiomas]="idiomas"></app-cajeros-mapa>
                        </div>
                    </div>
                    <div *ngIf="bandsFuncionLogin['0']" style="width: 100%; height: 150px; padding-top: 10px;">
                        <div class="customCarouselContainer">
                            <owl-carousel-o [options]="customCarouselMenu">
                                <ng-container>
                                    <ng-template carouselSlide>
                                        <div class="form-row">
                                            <div class="col-4" style="margin-top: 5px; text-align: center;">
                                                <button mat-button class="buttonMenu"
                                                    [class.buttonSelected]="bandsFuncionLogin['2']" (click)="loginPC()">
                                                    <div class="iconRoundContainer">
                                                        <mat-icon class="iconMenu">login</mat-icon>
                                                    </div>
                                                    <p style="font-size: .8em;">{{idiomas.BancaPersonas}}</p>
                                                </button>
                                            </div>
                                            <div class="col-4" style="margin-top: 5px; text-align: center;">
                                                <button mat-button class="buttonMenu"
                                                    [class.buttonSelected]="bandsFuncionLogin['7']"
                                                    (click)="openSimulators()">
                                                    <div class="iconRoundContainer">
                                                        <img style="height: 35px; width: 35px; margin-left: 6px; padding-top: 6px !important;"
                                                            src="assets/resources/mipmap-hdpi/SIMULADORES.png" />
                                                    </div>
                                                    <p style="font-size: .8em;">{{idiomas.Simuladores}}</p>
                                                </button>
                                            </div>
                                            <div class="col-4" style="margin-top: 5px; text-align: center;">
                                                <button mat-button class="buttonMenu"
                                                    [routerLink]="['/validarCertificados']" target="_blank">
                                                    <div class="iconRoundContainer">
                                                        <img style="height: 35px; width: 35px; margin-left: 6px; padding-top: 6px !important;"
                                                            src="assets/resources/mipmap-hdpi/certificados-inicio.png" />
                                                    </div>
                                                    <p style="font-size: .8em;">{{idiomas.Certificados}}
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="form-row">
                                            <div class="col-6" style="margin-top: 5px; text-align: center;">
                                                <button mat-button class="buttonMenu"
                                                    [class.buttonSelected]="bandsFuncionLogin['5']"
                                                    (click)="buttonSelected(3)">
                                                    <div class="iconRoundContainer">
                                                        <img style="height: 35px; width: 35px; margin-left: 6px; padding-top: 6px !important;"
                                                            src="assets/resources/mipmap-hdpi/AGENCIAS.png" />
                                                    </div>
                                                    <p style="font-size: .8em;">{{idiomas.Agencias}}
                                                    </p>
                                                </button>
                                            </div>
                                            <div class="col-6" style="margin-top: 5px; text-align: center;">
                                                <button mat-button class="buttonMenu"
                                                    [class.buttonSelected]="bandsFuncionLogin['6']"
                                                    (click)="buttonSelected(4)">
                                                    <div class="iconRoundContainer">
                                                        <img style="height: 35px; width: 35px; margin-left: 6px; padding-top: 6px !important;"
                                                            src="assets/resources/mipmap-hdpi/CAJEROS.png" />
                                                    </div>
                                                    <p style="font-size: .8em;">{{idiomas.Cajeros}}
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="bandMobile && intentoInicio" class="container container-login" fxLayout="column"
        fxLayoutAlign="start stretch">
        <div *ngIf="spinnerDevice" class="col-12 spinner-device">
            <mat-spinner style="margin: auto"></mat-spinner>
        </div>

        <div *ngIf="!spinnerDevice" class="camposLogIn">
            <div style="padding-top: 40% !important; text-align: center;" *ngIf="bandsFuncionLogin['0']">
                <div style="text-align: center !important;">
                    <img src="assets/resources/mipmap-hdpi/imagen-login.jpg" class="imagenLogin"
                        [class.offline]=!internetC.internet />
                </div>
            </div>
            <div class="bottomBannerLogin">
                <div class="customCarouselContainer" *ngIf="bandsFuncionLogin['0']">
                    <owl-carousel-o [options]="customCarouselMenu">
                        <ng-container>
                            <ng-template *ngIf=hasSoftToken carouselSlide>
                                <!--Menu desplegable inicio para app de la tienda-->
                                <div class="form-row" style="width: 95% !important; margin-top: 5px;">
                                    <div class="col-4" style="text-align: center;">
                                        <button mat-button class="buttonMenu" [class.offline]=!internetC.internet
                                            (click)="loginApp()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon class="iconMenu">person_outline</mat-icon>
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.Usuario}}</p>
                                        </button>
                                    </div>
                                    <div class="col-4" style="text-align: center;">
                                        <button mat-button [disabled]="!hasBiometricCredentials" color="primary"
                                            class="buttonMenu" [class.offline]=!internetC.internet
                                            (click)="webAuthVerifyUserPassw()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon style="margin-top: 5px !important;"
                                                    class="iconMenu">fingerprint</mat-icon>
                                            </div>
                                            <div class="form-row">
                                                <div class="col-12" style="line-height: 2px; font-size: .8em;">
                                                    {{idiomas.FingerprintButton}}
                                                </div>
                                                <div class="col-12" style="line-height: 20px; font-size: .8em;">
                                                    {{idiomas.FingerprintButtonF}}
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                   <div class="col-4" style="text-align: center;" [hidden]="true">
                                        <button mat-button class="buttonMenu" [class.offline]=!internetC.internet
                                            [disabled]="disablePin || !hasDeviceLinked || !activeSofttoken"
                                            (click)="loginPIN()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon class="iconMenu">dialpad</mat-icon>
                                            </div>
                                            <p style="font-size: .8em;">PIN</p>
                                        </button>
                                    </div>
                                    <div class="col-4" style="text-align: center;">
                                        <button *ngIf=!hasDeviceLinked mat-button class="buttonMenu"
                                            [class.offline]=!internetC.internet (click)="openScanner()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon class="iconMenu">qr_code_scanner</mat-icon>
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.VincularDispositivo}}</p>
                                        </button>
                                        <button *ngIf=hasDeviceLinked mat-button class="buttonMenu"
                                            [class.offline]=!internetC.internet (click)="opendeviceUnRegister()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon class="iconMenu">phonelink_erase</mat-icon>
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.DesvincularDispositivo}}</p>
                                        </button>
                                    </div>
                                </div>
                                <!--Menu desplegable inicio para app de la web desde dispositivo mobile-->
                                <!--<div class="form-row" style="width: 95% !important; margin-top: 5px;">
                                    <div class="col-3" style="text-align: center;">
                                        <button mat-button class="buttonMenu" [class.offline]=!internetC.internet
                                            (click)="loginApp()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon class="iconMenu">person_outline</mat-icon>
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.Usuario}}</p>
                                        </button>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <button mat-button class="buttonMenu"
                                            [class.buttonSelected]="bandsFuncionLogin['7']" (click)="openSimulators()">
                                            <div class="iconRoundContainerMob">
                                                <img style="height: 35px; width: 35px; margin-left: 6px; padding-top: 6px !important;"
                                                    src="assets/resources/mipmap-hdpi/SIMULADORES.png" />
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.Simuladores}}</p>
                                        </button>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <button mat-button class="buttonMenu"
                                            [class.buttonSelected]="bandsFuncionLogin['5']" (click)="buttonSelected(3)">
                                            <div class="iconRoundContainerMob">
                                                <img style="height: 35px; width: 35px; margin-left: 6px; padding-top: 6px !important;"
                                                    src="assets/resources/mipmap-hdpi/AGENCIAS.png" />
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.Agencias}}
                                            </p>
                                        </button>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <button mat-button class="buttonMenu"
                                            [class.buttonSelected]="bandsFuncionLogin['6']" (click)="buttonSelected(4)">
                                            <div class="iconRoundContainerMob">
                                                <img style="height: 35px; width: 35px; margin-left: 6px; padding-top: 6px !important;"
                                                    src="assets/resources/mipmap-hdpi/CAJEROS.png" />
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.Cajeros}}
                                            </p>
                                        </button>
                                    </div>
                                </div>-->
                            </ng-template>
                            <ng-template *ngIf=!hasSoftToken carouselSlide>
                                <div class="form-row" style="width: 95% !important; margin-top: 5px;"
                                    *ngIf="bandStandlone">
                                    <div class="col-6" style="text-align: center;">
                                        <button mat-button class="buttonMenu" [class.offline]=!internetC.internet
                                            (click)="loginApp()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon class="iconMenu">person_outline</mat-icon>
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.Usuario}}</p>
                                        </button>
                                    </div>
                                    <div class="col-6" style="text-align: center;">
                                        <button mat-button [disabled]="!hasBiometricCredentials" color="primary"
                                            class="buttonMenu" [class.offline]=!internetC.internet
                                            (click)="webAuthVerifyUserPassw()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon style="margin-top: 5px !important;"
                                                    class="iconMenu">fingerprint</mat-icon>
                                            </div>
                                            <div class="form-row">
                                                <div class="col-12" style="line-height: 2px; font-size: .8em;">
                                                    {{idiomas.FingerprintButton}}
                                                </div>
                                                <div class="col-12" style="line-height: 20px; font-size: .8em;">
                                                    {{idiomas.FingerprintButtonF}}
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-row" style="width: 95% !important; margin-top: 5px;"
                                    *ngIf="!bandStandlone">
                                    <div class="col-12" style="text-align: center;">
                                        <button mat-button class="buttonMenu" [class.offline]=!internetC.internet
                                            (click)="loginApp()">
                                            <div class="iconRoundContainerMob">
                                                <mat-icon class="iconMenu">person_outline</mat-icon>
                                            </div>
                                            <p style="font-size: .8em;">{{idiomas.Usuario}}</p>
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div style="margin-bottom: 0px">
                <div class="form-row contentMenu" style="background-color: transparent !important;">
                    <div style="padding-bottom: 10px;"
                        *ngIf="bandsFuncionLogin['1'] || bandsFuncionLogin['2'] || bandsFuncionLogin['3'] || bandsFuncionLogin['4']">
                        <a class="form-row returnContainer" (click)="returnInicioI()" style="max-width: 85px;">
                            <mat-icon class="returnArrow col">arrow_back_ios
                            </mat-icon>
                            <div class="returnLink col">{{idiomas.Regresar}}</div>
                        </a>
                    </div>
                    <mat-progress-bar *ngIf=intento mode="indeterminate"></mat-progress-bar>
                    <div class="col-12" style="margin-top: auto; margin-bottom: auto;">
                        <div *ngIf="bandsFuncionLogin['3']">
                            <app-agencias-mapa [idiomas]="idiomas"></app-agencias-mapa>
                        </div>
                        <div *ngIf="bandsFuncionLogin['4']">
                            <app-cajeros-mapa [idiomas]="idiomas"></app-cajeros-mapa>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isUserEnt" class="under-toolbar">
        <mat-card class="formMaxWidth" *ngIf="!bandMobile">
            <div *ngIf="intento">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <br>
            </div>
            <div *ngIf="desLogin">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div>
                            <mat-form-field (keyup.enter)="usrEnter()" appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Usr}}</mat-label>
                                <input id="userID" matInput type="text" autocomplete="off"
                                    [formControl]="usernameFormControl" [readonly]="desnext && internetC.internet"
                                    oninput="this.value = this.value.toUpperCase();">
                                <mat-error *ngIf="usernameFormControl.hasError('required')">
                                    <strong>{{idiomas.ValidarUsuario}}</strong>
                                </mat-error>
                                <mat-error *ngIf="usernameFormControl.hasError('pattern')">
                                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="error && !desnext && internetC.internet">
                            <div *ngIf=!verifyMesgInfo>
                                <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            </div>
                            <div *ngIf=verifyMesgInfo>
                                <app-alertas-login [alertalogin]="error" [mensaje]="msjerror"></app-alertas-login>
                            </div>
                            <br>
                        </div>
                        <div class="form-row" style="width: 100%; text-align: center;"
                            *ngIf="!desnext && internetC.internet && !bandUnlockUser && bandMobile">
                            <div class="col-12" style="text-align: center;">
                                <button mat-raised-button [disabled]="usernameFormControl.invalid || disableButton"
                                    (click)="nextStep(false)" color="primary" class="login button-login"
                                    [class.offline]=!internetC.internet style="height: 70px !important; width: 75px">
                                    <mat-icon
                                        style="font-size: 25px !important; height: 30px; width: 30px">login</mat-icon>
                                    <div style="line-height: 15px; font-size: .8em;">
                                        {{idiomas.Siguiente}}
                                    </div>
                                </button>
                            </div>
                            <!-- <div class="col-12" *ngIf="usernameFormControl.invalid">
                                <button mat-raised-button [disabled]="!hasBiometricCredentials" color="primary"
                                    class="login button-login" [class.offline]=!internetC.internet
                                    style="height: 70px !important; width: 75px" (click)="webAuthVerifyUserPassw()">
                                    <mat-icon style="font-size: 25px !important; height: 30px; width: 30px">fingerprint
                                    </mat-icon>
                                    <div class="form-row">
                                        <div class="col-12" style="line-height: 2px; font-size: .8em;">
                                            {{idiomas.FingerprintButton}}
                                        </div>
                                        <div class="col-12" style="line-height: 20px; font-size: .8em;">
                                            {{idiomas.FingerprintButtonF}}
                                        </div>
                                    </div>
                                </button>
                            </div> -->
                        </div>
                        <div *ngIf="desnext || !internetC.internet">
                            <div class="form-row" *ngIf="!firstTimeLogin && internetC.internet">
                                <div class="col-3" style="text-align: center; margin: auto">
                                    <img [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + userImage)"
                                        style="width: 55px; height: 55px; background-color: white;border-radius: 10px">
                                </div>
                                <div class="col-9" style="display:inlineonclick-flex" *ngIf="internetC.internet">
                                    <app-alertas-login [alertalogin]="alertalogin" [mensaje]="msjalert">
                                    </app-alertas-login>
                                </div>
                            </div>
                            <br *ngIf="!firstTimeLogin && internetC.internet">
                            <div *ngIf="firstTimeLogin && internetC.internet">
                                <app-alertas-login [alertalogin]="alertalogin" [mensaje]="msjalert"></app-alertas-login>
                                <br>
                            </div>
                            <mat-form-field (keyup.enter)="psswEnter()" appearance="outline" style="min-width: 235px"
                                fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input id="userPwdID" matInput [type]="hide[0] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passwordFormControl">
                                <div matSuffix (click)="hide[0] = !hide[0]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[0]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary" (click)="hideInterval()">{{hide[0] ? 'visibility_off' :
                                        'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passwordFormControl.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="error && internetC.internet"
                                style="max-width: 235px; margin-left: auto; margin-right: auto;">
                                <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                                <br>
                            </div>
                        </div>
                        <div class="form-row" style="padding-top: 5px;">
                            <div class="col-9" *ngIf="internetC.internet">
                                <a *ngIf="usernameFormControl.value==null || usernameFormControl.value==''"
                                    style="text-decoration: underline;cursor: pointer;font-size: 12px"
                                    (click)="newUser(null)">
                                    {{idiomas.NuevoUsuario}}
                                </a>
                                <br *ngIf="usernameFormControl.value==null || usernameFormControl.value==''">
                                <a *ngIf="usernameFormControl.value==null || usernameFormControl.value==''"
                                    style="text-decoration: underline;cursor: pointer;font-size: 12px"
                                    (click)="newUser({reminder: true})">
                                    {{idiomas.RecuperarUsuario}}
                                </a>
                                <br *ngIf="usernameFormControl.value==null || usernameFormControl.value==''">
                                <a style="text-decoration: underline;cursor: pointer;font-size: 12px"
                                    (click)="changePassword()">
                                    {{idiomas.NuevaContrasenia}}
                                </a>
                            </div>
                            <div class="left textoffline col-7" *ngIf="!internetC.internet">
                                offline
                            </div>
                            <div class="right col-5" style="margin: auto"
                                *ngIf="((desnext && !firstTimeLogin) || !internetC.internet) && !bandUnlockUser">
                                <button mat-raised-button [disabled]="passwordFormControl.invalid || disableButton"
                                    (click)="login()" class="login button-login" color="primary"
                                    [class.offline]=!internetC.internet style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.Siguiente}}
                                </button>
                            </div>
                            <div class="col-5 right" style="margin: auto"
                                *ngIf="!desnext && internetC.internet && !bandUnlockUser && !bandMobile">
                                <button mat-raised-button [disabled]="usernameFormControl.invalid || disableButton"
                                    (click)="nextStep(false)" color="primary" class="login button-login"
                                    [class.offline]=!internetC.internet style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.Siguiente}}
                                </button>
                            </div>
                            <div class="right col-5" style="margin: auto"
                                *ngIf="firstTimeLogin && !uservalid && internetC.internet">
                                <button mat-raised-button (click)="login()" class="login button-login" color="primary"
                                    [class.offline]=!internetC.internet style="max-width: 130px" fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="col-5 right" style="margin: auto" *ngIf="internetC.internet && bandUnlockUser">
                                <button mat-raised-button (click)="nextStepUnlockUser()" color="primary"
                                    class="login button-login" [class.offline]=!internetC.internet
                                    style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.Desbloquear}}
                                </button>
                            </div>
                            <div class="col-5 right" style="margin: auto" *ngIf="!internetC.internet && bandUnlockUser">
                                <button mat-raised-button [disabled]="!passwordFormControl.valid" (click)="login()"
                                    class="login button-login" color="primary" [class.offline]=!internetC.internet
                                    style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div *ngIf="idiomas.LoginSecureURL" class="col-12"
                                style="text-align: center; top: 20px; opacity: 0.5; ">
                                <p class="loginOptionalText" [innerHTML]="idiomas.LoginSecureURL"></p>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="errorOffline && !internetC.internet">
                            <app-alertas-login [error]="errorOffline" [mensaje]="msjerrorOffline"></app-alertas-login>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="desnewUser">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <label *ngIf="newUsernameMigratedUser"
                            style="text-align: center; width: 100%;"><strong>{{idiomas.migratedUserNameChange |
                                uppercase}}</strong></label>
                        <div
                            *ngIf="!newUsernameMigratedUser && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idTipo}}</mat-label>
                                <mat-select [(value)]='tipoid'
                                    (selectionChange)="changeTipoIdentification($event.value)" [formControl]="idTipo">
                                    <mat-option *ngFor="let tipo of idiomas.Identificacion" [value]="tipo.value">
                                        {{tipo.viewValue}}
                                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="idTipo.hasError('required')">
                                    <strong>{{idiomas.ValidarCampo}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="!newUsernameMigratedUser && !internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idTipo}}</mat-label>
                                <mat-select [(value)]='tipoid' disabled>
                                    <mat-option *ngFor="let tipo of idiomas.Identificacion" [value]="idTipo.value">
                                        {{tipo.viewValue}}
                                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="!newUsernameMigratedUser && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idSocio}}</mat-label>
                                <input id="ID" matInput type="text" autocomplete="off" [formControl]="idForm">
                                <mat-error *ngIf="idForm.hasError('maxlength')">
                                    <strong>{{idiomas.msjExedeTamano}}</strong>
                                </mat-error>
                                <mat-error *ngIf="idForm.hasError('required')">
                                    <strong>{{idiomas.msjIdRequerido}}</strong>
                                </mat-error>
                                <mat-error *ngIf="idForm.hasError('pattern')">
                                    <strong>{{idiomas.msjSoloNumeros}} </strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="!newUsernameMigratedUser && !internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idSocio}}</mat-label>
                                <input matInput type="text" autocomplete="off" value="{{idForm.value}}" disabled>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="information && !uservalid && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <app-alertas-login [information]="information"
                                [mensaje]="msjinformation"></app-alertas-login>
                            <br>
                        </div>
                        <div
                            *ngIf="alertalogin && !uservalid && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <app-alertas-login [alertalogin]="alertalogin" [mensaje]="msjalert"></app-alertas-login>
                            <br>
                        </div>
                        <div
                            *ngIf="error && !uservalid && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="instructionsForm">
                            <div data-testid="liveness-detector" class="liveness-detector">
                                <div class="amplify-flex" style="flex-direction: column;">
                                    <div data-testid="liveness-detector-start"
                                        class="amplify-card liveness-detector-start">
                                        <div class="amplify-flex" style="flex-direction: column;">
                                            <div style="flex: 1 1 0%;">
                                                <div
                                                    style="color: var(--amplify-colors-font-primary); font-weight: var(--amplify-font-weights-bold);">
                                                    {{idiomas.instructionsHeaderHeadingText}}</div>
                                                <div style="color: var(--amplify-colors-font-primary);">
                                                    {{idiomas.instructionsHeaderBodyText}}</div>
                                            </div>
                                            <div class="amplify-flex amplify-alert-instructions"
                                                style="color: var(--amplify-colors-orange-80); background-color: var(--amplify-colors-orange-20); align-items: center;">
                                                <div style="flex: 1 1 0%;">
                                                    <div class="amplify-alert__heading">
                                                        {{idiomas.photosensitivyWarningHeadingText}}</div>
                                                    <div class="amplify-alert__body">
                                                        {{idiomas.photosensitivyWarningBodyText}}
                                                    </div>
                                                </div>
                                                <div data-testid="popover-icon" class="amplify-flex"
                                                    style="cursor: pointer; position: relative;">
                                                    <span class="amplify-icon amplify-alert__icon" aria-hidden="true"
                                                        style="width: 1em; height: 1em;"><svg width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                                                                fill="currentColor"></path>
                                                        </svg></span>
                                                </div>
                                            </div>
                                            <div class="amplify-flex" style="flex-direction: column;">
                                                <p class="amplify-text"
                                                    style="color: var(--amplify-colors-font-primary); font-weight: var(--amplify-font-weights-bold);">
                                                    {{idiomas.instructionListHeadingText}}</p>
                                                <div class="amplify-flex amplify-liveness-figures">
                                                    <figure
                                                        class="amplify-flex amplify-flex-instructions amplify-liveness-figure amplify-liveness-figure--success">
                                                        <div
                                                            class="amplify-liveness-figure__image amplify-liveness-figure__image--success">
                                                            <svg class="amplify-liveness-figure__icon"
                                                                aria-hidden="true" width="24" height="24">
                                                                <g fill="none">
                                                                    <path fill="#365E3D" d="M0 0h24v24H0z"></path>
                                                                    <path fill="#FFF"
                                                                        d="m9.435 15.62-4.054-4.055L4 12.936l5.435 5.435L21.101 6.704l-1.37-1.371z">
                                                                    </path>
                                                                </g>
                                                            </svg><svg width="150" height="150">
                                                                <title>{{idiomas.goodFitAltText}}</title>
                                                                <g fill="none" fill-rule="evenodd"
                                                                    transform="translate(0 -.001)">
                                                                    <path fill="#5B361E" fill-rule="nonzero"
                                                                        d="M124.655 0c9.173 8.155 9.394 17.812 13.258 32.385l.053.336.108.726.11.796.112.864.114.931.174 1.515.117 1.087.18 1.739.12 1.23.183 1.944.123 1.36.186 2.13.187 2.232.313 3.928.25 3.31.25 3.443.31 4.463.245 3.679.36 5.658.345 5.778.33 5.841.26 4.876.199 3.883.187 3.849.217 4.738.16 3.712.178 4.515.097 2.63v34.977L.519 150 .517 41.97c3-13.353 9.664-29.4 23.841-41.97h100.297Z">
                                                                    </path>
                                                                    <path fill="#FCDDCC" fill-rule="nonzero"
                                                                        stroke="#000"
                                                                        d="m50.469 18.849.145.153c9.021 9.393 22.62 16.197 36.089 21.996l2.1.897 1.05.443 2.089.876 8.176 3.385 1.979.825 1.944.82c7.782 3.3 14.617 6.491 19.213 10.006 3.57 2.73 5.793 5.645 5.924 8.999v20.474l-.008.624-.016.669-.04 1.089-.04.777-.047.815-.06.853-.068.887-.08.918-.093.95-.104.978-.057.496-.123 1.016-.066.513-.144 1.049-.076.527-.165 1.077c-.057.36-.116.724-.178 1.086l-.193 1.103-.21 1.116-.11.557-.233 1.13c-.12.564-.247 1.13-.38 1.694l-.275 1.14c-1.037 4.147-2.426 8.3-4.271 11.978-6.17 9.34-12.996 16.035-19.28 20.691l-.8.584-.794.562-.784.539-1.165.77-1.147.724-.755.459c-.249.148-.497.294-.74.434l-.73.416-1.078.588-.702.367-1.033.517-.671.321-.657.303-.643.285-.541.23H68.149a75.81 75.81 0 0 1-.81-.284l-.918-.336a75.953 75.953 0 0 1-.935-.355l-.963-.382a85.513 85.513 0 0 1-1.988-.83l-1.032-.455c-.52-.233-1.05-.475-1.585-.727l-1.087-.517-1.113-.547c-.935-.465-1.893-.959-2.873-1.482l-1.193-.644a141.053 141.053 0 0 1-6.297-3.669l-1.33-.83c-17.11-10.783-22.636-33.458-23.66-49.98l-.071-1.267c-.02-.417-.038-.83-.053-1.235l-.037-1.212a86.317 86.317 0 0 1 .042-5.559l.047-1.002.06-.96.064-.843c1.09-2.51 2.164-4.304 3.296-5.882l.408-.558.415-.545.421-.538 2.026-2.492.481-.597.493-.624.507-.656.518-.69a61.722 61.722 0 0 0 3.769-5.754c4.03-6.917 7.127-14.806 9.544-21.668l.566-1.623.802-2.344 2.077-6.175.416-1.205.395-1.109.373-1.007.267-.682.253-.612c.47-.943.8-1.531 1.06-1.876l-.035.047Z">
                                                                    </path>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="m94.566 121.353.722.895c-6.828 5.51-14.13 7.462-21.382 6.447-5.417-.758-10.535-3.2-13.987-6.186l-.318-.282.77-.854c3.285 2.964 8.343 5.434 13.694 6.183 6.797.95 13.632-.819 20.089-5.876l.412-.327Z">
                                                                    </path>
                                                                    <ellipse cx="51.331" cy="80.698" fill="#000"
                                                                        fill-rule="nonzero" rx="4.886" ry="6.707">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M42.539 63.719c4.453-2.586 11.355-3.268 17.22-.195l.35.19-.556 1.005c-5.437-3.01-11.946-2.479-16.175-.153l-.262.148-.577-.995Z">
                                                                    </path>
                                                                    <ellipse cx="103.281" cy="80.698" fill="#000"
                                                                        fill-rule="nonzero" rx="4.886" ry="6.707">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M94.492 63.719c4.453-2.586 11.355-3.268 17.22-.195l.35.19-.557 1.005c-5.436-3.01-11.946-2.479-16.174-.153l-.262.148-.577-.995Zm-22.972 32.9c0 4.216 2.006 7.72 5.831 7.48l.232-.018.115 1.144c-4.774.477-7.239-3.571-7.326-8.345l-.003-.26 1.15-.001h.001Z">
                                                                    </path>
                                                                    <path fill="#FFF" fill-rule="nonzero"
                                                                        d="M75.002.001H0v150h150v-150H75.002Zm0 0c25.627 0 46.402 33.579 46.402 75s-20.775 75-46.402 75c-25.627 0-46.402-33.579-46.402-75s20.775-75 46.402-75Z">
                                                                    </path>
                                                                    <path stroke="#AEB3B7" stroke-width="2"
                                                                        d="M120.921 75.001c0 20.555-5.214 39.117-13.589 52.507-8.386 13.406-19.838 21.493-32.313 21.493-12.476 0-23.928-8.087-32.312-21.493-8.377-13.39-13.59-31.952-13.59-52.507 0-20.555 5.214-39.116 13.589-52.507C51.091 9.09 62.543 1.001 75.018 1.001c12.476 0 23.928 8.088 32.314 21.493 8.375 13.39 13.588 31.952 13.588 52.507h.001Z">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <figcaption
                                                            class="amplify-liveness-figure__caption amplify-liveness-figure__caption--success">
                                                            {{idiomas.goodFitCaptionText}}</figcaption>
                                                    </figure>
                                                    <figure
                                                        class="amplify-flex amplify-flex-instructions amplify-liveness-figure amplify-liveness-figure--error">
                                                        <div
                                                            class="amplify-liveness-figure__image amplify-liveness-figure__image--error">
                                                            <svg class="amplify-liveness-figure__icon"
                                                                aria-hidden="true" width="24" height="24">
                                                                <g fill="none">
                                                                    <path fill="#600" d="M0 0h24v24H0z"></path>
                                                                    <path fill="#FFF"
                                                                        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                                                    </path>
                                                                </g>
                                                            </svg><svg width="150" height="150">
                                                                <title>{{idiomas.tooFarAltText}}</title>
                                                                <defs>
                                                                    <linearGradient id="a" x1="50%" x2="50%" y1="0%"
                                                                        y2="100%">
                                                                        <stop offset="0%" stop-color="#C2C2C2"></stop>
                                                                        <stop offset="100%" stop-color="#C2C2C2"
                                                                            stop-opacity="0">
                                                                        </stop>
                                                                    </linearGradient>
                                                                </defs>
                                                                <g fill="none" fill-rule="evenodd"
                                                                    transform="translate(2)">
                                                                    <path fill="#FFF" fill-rule="nonzero"
                                                                        d="M3.997 0h136v150h-136z"></path>
                                                                    <path fill="url(#a)" fill-rule="nonzero"
                                                                        d="M4.333 0h138v150h-138z"></path>
                                                                    <path fill="#5B361E" fill-rule="nonzero"
                                                                        stroke="#000" stroke-width="1.353"
                                                                        d="m22.515 58.137-1.895 82.434 98.784-2.343c0-8.798.813-16.271.813-31.497 0-15.748-2.345-55.36-3.766-64.125C108.16 11.338 74.737 5.03 56.707 11.04c-28.425 9.475-33.64 35.095-34.192 47.097Z">
                                                                    </path>
                                                                    <path fill="#EF992A" fill-rule="nonzero"
                                                                        d="M38.104 138.228c8.339-3.98 5.1-1.525 15.916-3.104H90.5c5.448 0 9.541 3.104 28.904 3.104 6.391 0 5.987 8.988-2.473 8.988-24.315 1.08-66.07 1.08-78.281 0-77.975-6.896-10.97-4.014-.546-8.988Z">
                                                                    </path>
                                                                    <path fill="#FCDDCC" fill-rule="nonzero"
                                                                        stroke="#000" stroke-width="1.353"
                                                                        d="m54.306 134.26 2.645-21.765h30.498l3.05 21.765c-15.778 14.791-30.703 6.163-36.193 0zm-27.59-54.458C25.42 66.68 33.467 67.18 37.653 69.07l2.837 25.314c-10.328-2.228-13.772-12.961-13.772-14.58zm89.512-.81c4.05-15.067-3.984-15.998-8.506-14.58L105.9 91.75c10.328-8.505 9.113-12.758 10.328-12.758z">
                                                                    </path>
                                                                    <path fill="#FCDDCC" fill-rule="nonzero"
                                                                        stroke="#000" stroke-width="1.353"
                                                                        d="M53.564 109.804c-14.195-8.986-16.116-30.658-15.302-40.37 2.24-5.21 4.37-5.723 7.958-11.909 6.3-10.86 9.028-25.451 10.579-25.009 14.241 16.008 50.215 20.259 50.649 31.708v13.023c0 4.178-.911 14.358-4.558 21.65-8.986 13.674-20.131 18.612-24.58 19.372-2.334.922-10.55.521-24.746-8.465Z">
                                                                    </path>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="m83.935 98.402.85 1.052c-7.974 6.435-17.2 5.243-23.018.18l-.23-.204.905-1.004c5.273 4.756 13.744 5.998 21.175.227l.318-.251Z">
                                                                    </path>
                                                                    <ellipse cx="56.448" cy="72.613" fill="#000"
                                                                        fill-rule="nonzero" rx="3.128" ry="4.294">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M50.664 61.476c2.917-1.694 7.404-2.147 11.244-.172l.31.165-.655 1.183c-3.348-1.854-7.361-1.545-9.985-.137l-.234.13-.68-1.17Z">
                                                                    </path>
                                                                    <ellipse cx="89.709" cy="72.613" fill="#000"
                                                                        fill-rule="nonzero" rx="3.128" ry="4.294">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M83.926 61.476c2.917-1.694 7.404-2.147 11.244-.172l.31.165-.655 1.183c-3.348-1.854-7.361-1.545-9.985-.137l-.234.13-.68-1.17Z">
                                                                    </path>
                                                                    <path stroke="#000" stroke-width="1.353"
                                                                        d="M69.005 82.806c0 1.858.859 5.487 4.287 5.144">
                                                                    </path>
                                                                    <path fill="#FFF"
                                                                        d="M73.004 0H0v150h146V0H73.004Zm.496 0C98.629 0 119 33.579 119 75s-20.371 75-45.5 75S28 116.421 28 75 48.371 0 73.5 0Z">
                                                                    </path>
                                                                    <path stroke="#AEB3B7" stroke-width="2"
                                                                        d="M118.4 75c0 20.555-5.156 39.117-13.441 52.507C96.665 140.913 85.338 149 72.999 149c-12.34 0-23.667-8.087-31.961-21.493C32.753 114.117 27.597 95.555 27.597 75c0-20.555 5.156-39.117 13.44-52.507C49.333 9.087 60.66 1 72.999 1c12.34 0 23.667 8.087 31.961 21.493C113.244 35.883 118.4 54.445 118.4 75Z">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <figcaption
                                                            class="amplify-liveness-figure__caption amplify-liveness-figure__caption--error">
                                                            {{idiomas.tooFarCaptionText}}</figcaption>
                                                    </figure>
                                                </div>
                                                <ol class="amplify-flex amplify-liveness-instruction-list">
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">1.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepOneText}}</span>
                                                    </li>
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">2.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepTwoText}}</span>
                                                    </li>
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">3.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepThreeText}}</span>
                                                    </li>
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">4.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepFourText}}</span>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div class="amplify-flex" (click)="rekognitionStep()"
                                                style="justify-content: center;">
                                                <button mat-raised-button color="primary" class="enviar button-login"
                                                    data-fullwidth="false" data-variation="primary"
                                                    style="margin-left: 0;" type="button">
                                                    <mat-icon>arrow_forward_ios</mat-icon>{{idiomas.Siguiente}}</button>
                                            </div>
                                            <div *ngIf="spinner">
                                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="faceLivenessForm">
                            <div style="margin-left: auto; margin-right: auto;">
                                <app-face-liveness *ngIf="!intentoSpinner && !error" [idiomas]="idiomas"
                                    (eventImage)="verifyImage($event)"></app-face-liveness>
                                <div *ngIf="intentoSpinner">
                                    <p style="text-align: center; font-size: 12px;">{{idiomas.ValidandoVideo}}</p>
                                    <div class="spinner">
                                        <mat-spinner style="margin: auto"></mat-spinner>
                                    </div>
                                    <br>
                                </div>
                                <div *ngIf="error && internetC.internet">
                                    <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="userExist">
                            <div *ngIf="internetC.internet">
                                <mat-form-field appearance="outline" fxFlexFill>
                                    <mat-label>{{idiomas.Usr}}</mat-label>
                                    <input id="userID" matInput type="text" autocomplete="off" [formControl]="userForm1"
                                        oninput="this.value = this.value.toUpperCase();" maxlength="15">
                                    <mat-error *ngIf="userForm1.hasError('required')">
                                        <strong>{{idiomas.ValidarUsuario}}</strong>
                                    </mat-error>
                                    <mat-error *ngIf="userForm1.hasError('pattern')">
                                        <strong>{{idiomas.NuevoUsuarioInvalido}}</strong>
                                    </mat-error>
                                    <mat-error *ngIf="userForm1.hasError('minlength')">
                                        <strong>{{idiomas.NuevoUsuarioLength}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="!internetC.internet">
                                <mat-form-field appearance="outline" fxFlexFill>
                                    <mat-label>{{idiomas.Usr}}</mat-label>
                                    <input matInput type="text" autocomplete="off" value="{{userForm1.value}}" disabled>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-row">
                            <!--<div class="col-3" style="max-width: 60px;">
                                <a style="text-decoration: underline;cursor: pointer; font-size: 12px; margin-top: 10px"
                                    (click)="return()">
                                    <div style="padding-top: 14px">{{idiomas.Regresar}}</div>
                                </a>
                            </div>-->
                            <div class="right col"
                                *ngIf="!userExist && internetC.internet && !verifyContract && !instructionsForm && !faceLivenessForm">
                                <button mat-raised-button (click)="sendIdentification()" color="primary"
                                    class="enviar button-login" [disabled]="!idTipo.valid || !idForm.valid" fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col"
                                *ngIf="userExist && internetC.internet && !uservalid && !instructionsForm && !faceLivenessForm">
                                <button mat-raised-button (click)="sendUser()" color="primary"
                                    class="enviar button-login" [disabled]="!userForm1.valid" fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col"
                                *ngIf="userExist && internetC.internet && uservalid && !instructionsForm && !faceLivenessForm">
                                <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                    [disabled]="!userForm1.valid" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div *ngIf="!internetC.internet" class="right textoffline col">
                                offline
                                <br>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="information && uservalid && internetC.internet">
                            <app-alertas-login [information]="information"
                                [mensaje]="msjinformation"></app-alertas-login>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="desnewPassword">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="internetC.internet">
                            <mat-form-field (keyup.enter)="enterNewPassword()" appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Usr}}</mat-label>
                                <input id="userID" matInput type="text" autocomplete="off" [formControl]="userForm"
                                    oninput="this.value = this.value.toUpperCase();">
                                <mat-error *ngIf="userForm.hasError('required')">
                                    <strong>{{idiomas.ValidarUsuario}}</strong>
                                </mat-error>
                                <mat-error *ngIf="userForm.hasError('pattern')">
                                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Usr}}</mat-label>
                                <input matInput type="text" autocomplete="off" value="{{userForm.value}}" disabled>
                            </mat-form-field>
                        </div>
                        <div *ngIf="error && internetC.internet">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="information && internetC.internet">
                            <app-alertas-login [information]="information"
                                [mensaje]="msjinformation"></app-alertas-login>
                            <br>
                        </div>
                        <div class="form-row">
                            <div class="right col" *ngIf="internetC.internet && !bandusuario">
                                <button mat-raised-button (click)="sendUserNewPass()" [disabled]="!userForm.valid"
                                    color="primary" class="enviar button-login" [class.offline]=!internetC.internet
                                    fxFlexFill>{{idiomas.Recuperar}}</button>
                            </div>
                            <div class="right col" *ngIf="internetC.internet && bandusuario">
                                <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                    [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div *ngIf="!internetC.internet" class="right textoffline col">
                                offline
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectImageForm" class="form-row">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <p class="col-12">{{idiomas.msjSeleccionImgQ}}</p>
                        <p *ngIf="nImgAndQ" class="col-12" style="font-size: 12px; opacity:0.6;">
                            {{idiomas.favImg}}</p>
                        <div *ngIf="nImgAndQ" style="text-align: center;padding-left: 0px;padding-right: 0px;"
                            class="col-12">
                            <table style="margin: auto; width: 210px; height: 160px;">
                                <tr>
                                    <td *ngFor="let imagen of imagenes.slice(0, (numImages/2));let i = index">
                                        <div [class.borderImg]="borderImg[i]" [class.borderImg1]="!borderImg[i]"
                                            style="text-align:center;" (click)="selectImage(imagen.cimagen)">
                                            <img value="i" id="i" src="data:image/png;base64,{{imagen.imagen64}}"
                                                style="width: 45px; height: 45px;cursor: pointer;padding-top: 4px">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td *ngFor="let imagen of imagenes.slice((numImages/2), numImages);let i = index">
                                        <div [class.borderImg]="borderImg[i+4]" [class.borderImg1]="!borderImg[i+4]"
                                            style="text-align:center;" (click)="selectImage(imagen.cimagen)">
                                            <img value="i+4" src="data:image/png;base64,{{imagen.imagen64}}"
                                                style="width: 35px; height: 35px;cursor: pointer;padding-top: 4px">
                                        </div>
                                    </td>
                                </tr>
                                <br>
                            </table>
                        </div>
                        <div *ngIf="internetC.internet && nImgAndQ" class="col-12">
                            <div *ngFor="let pregunta of preguntas; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" [formControl]="preguntasForm[i]"
                                        #input (input)="input.value=$event.target.value.toUpperCase()">
                                    <mat-error *ngIf="preguntasForm[i].hasError('required')">
                                        <strong>{{idiomas.ValidarCampo}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="!internetC.internet && nImgAndQ" class="col-12">
                            <div *ngFor="let pregunta of preguntas; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" value="{{preguntasForm[i].value}}"
                                        disabled>
                                </mat-form-field>
                            </div>
                        </div>
                        <div style="text-align: -webkit-center !important;">
                            <div *ngIf="alertalogin && internetC.internet" class="col-9">
                                <app-alertas-login [alertalogin]="alertalogin" [mensaje]="idiomas.msjalertaPreguntas">
                                </app-alertas-login>
                                <br>
                            </div>
                            <div *ngIf="error && internetC.internet" class="col-9">
                                <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                                <br>
                            </div>
                            <div *ngIf="information && internetC.internet" class="col-9">
                                <app-alertas-login [information]="information" [mensaje]="msjinformation">
                                </app-alertas-login>
                                <br>
                            </div>
                        </div>
                        <div class="row" *ngIf="internetC.internet" style="padding-left: 15px; padding-right: 15px">
                            <div class="col-7">
                                <a *ngIf="!imgQButton"
                                    style="text-decoration: underline;cursor: pointer; font-size: 12px"
                                    (click)="login()">
                                    {{idiomas.NImgAndQ}}
                                </a>
                            </div>
                            <div class="right col-5" *ngIf="!imgQButton" style="margin-left: auto">
                                <button mat-raised-button (click)="sendImageAndQuestions()" [disabled]=""
                                    color="primary" class="enviar button-login" [class.offline]=!internetC.internet
                                    fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col-5" *ngIf="imgQButton" style="margin: auto">
                                <button mat-raised-button (click)="nextStepNewPass()" color="primary"
                                    class="enviar button-login" [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>{{idiomas.Siguiente}}
                                </button>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 15px; padding-right: 15px;">
                            <div class="right col textoffline" *ngIf="!internetC.internet">
                                offline
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="otpEnterForm">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <p>{{idiomas.Otp}}</p>
                        <div>
                            <div style="margin-top: 1.6em;">
                                <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal"
                                    [idiomas]="idiomas" [enableCronoIn]="enableCronoIn"
                                    (terminaCrono)="onTerminaCrono($event)"></temporizador>
                            </div>
                            <div class="form-row" style="width: 100%; margin:auto">
                                <div class="col-md-8 col-sm-7 col-xs-6" *ngIf="internetC.internet">
                                    <mat-form-field (keyup.enter)="otpEnter()" appearance="outline" fxFlexFill>
                                        <input id="userOTP" matInput type="text" autocomplete="off" inputmode="numeric"
                                            onkeydown="this.setAttribute('type','password')" [formControl]="otpForm"
                                            maxlength="6" appBlockCopyPaste>
                                        <mat-error *ngIf="otpForm.hasError('required')">
                                            <strong>{{idiomas.ValidarCampo}}</strong>
                                        </mat-error>
                                        <mat-error *ngIf="otpForm.hasError('pattern')">
                                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                                        </mat-error>
                                        <mat-error *ngIf="otpForm.hasError('minlength')">
                                            <strong>{{idiomas.msjOtpminmax}}</strong>
                                        </mat-error>
                                        <mat-error *ngIf="otpForm.hasError('maxlength')">
                                            <strong>{{idiomas.msjOtpminmax}}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-8 col-sm-7 col-xs-6" *ngIf="!internetC.internet">
                                    <mat-form-field (keyup.enter)="otpEnter()" appearance="outline" fxFlexFill>
                                        <input matInput type="password" autocomplete="off" value="{{otpForm.value}}"
                                            disabled>
                                    </mat-form-field>
                                </div>
                                <div class="left textoffline col" *ngIf="!internetC.internet"
                                    style="padding-top: 8px !important">
                                    offline
                                </div>
                                <div class="col" *ngIf="internetC.internet" style="max-width: 150px;">
                                    <button mat-raised-button (click)="sendOtp()" style="min-width: 110px !important;"
                                        [disabled]="otpForm.invalid || disableButton" color="primary"
                                        class="enviarOtp1 button-login" [class.offline]=!internetC.internet fxFlexFill>
                                        <mat-icon>touch_app</mat-icon>
                                        {{idiomas.btnIngresar}}
                                    </button>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="right col-7" style="padding-top: 14px">
                                    <a *ngIf="internetC.internet && !enableCronoIn"
                                        style="text-decoration: underline;cursor: pointer; font-size: 12px; padding-right: 12px"
                                        (click)="login()">{{idiomas.NToken}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="error">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="newPassFormFirst">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="isPasswordExpired">
                            <app-alertas-login [alertaloginpass]="true" [mensaje]="mensajePasswordExpirado">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="mensajeContra!=null && !isPasswordExpired"
                            style="margin-bottom: -15px; margin-top: -10px;">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajeContra">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput [type]="hide[1] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passForm">
                                <div matSuffix (click)="hide[1] = !hide[1]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[1]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[1] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput [type]="hide[2] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passConfirmForm">
                                <div matSuffix (click)="hide[2] = !hide[2]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[2]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[2] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passConfirmForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput type="password" autocomplete="off" value="{{passForm.value}}" disabled>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput type="password" autocomplete="off" value="{{passConfirmForm.value}}"
                                    disabled>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!isPasswordExpired" style="margin-bottom: -10px; margin-top: -15px;">
                            <app-alertas-login [alertalogin]="true" [mensaje]="idiomas.LoginPassExtraMessage">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="mensajeContra!=null && isPasswordExpired"
                            style="margin-bottom: -15px; margin-top: -10px;">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajeContra">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="error && internetC.internet">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="information && internetC.internet">
                            <app-alertas-login [information]="information" [mensaje]="msjinformation">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div class="form-row">
                            <div class="right col" *ngIf="!QButton && internetC.internet">
                                <button mat-raised-button (click)="sendNewPass()" color="primary"
                                    class="enviar button-login"
                                    [disabled]="(!this.passForm.valid || !this.passConfirmForm.valid) || !(this.passForm.value == this.passConfirmForm.value)"
                                    [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col" *ngIf="QButton && internetC.internet">
                                <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                    [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div class="right textoffline col" *ngIf="!internetC.internet"
                                style="padding-top: 8px !important">
                                offline
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="newPassForm">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="isPasswordExpired">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajePasswordExpirado">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="mensajeContra!=null">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajeContra">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput [type]="hide[3] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passForm">
                                <div matSuffix (click)="hide[3] = !hide[3]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[3]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[3] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput [type]="hide[4] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passConfirmForm">
                                <div matSuffix (click)="hide[4] = !hide[4]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[4]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[4] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passConfirmForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <div *ngFor="let pregunta of preguntas1; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" [formControl]="preguntasForm1[i]"
                                        #input (input)="input.value=$event.target.value.toUpperCase()">
                                    <mat-error *ngIf="preguntasForm[i].hasError('required')">
                                        <strong>{{idiomas.ValidarCampo}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!internetC.internet">
                        <div>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput type="password" value="{{passForm.value}}" disabled>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput type="password" value="{{passConfirmForm.value}}" disabled>
                            </mat-form-field>
                        </div>
                        <div *ngFor="let pregunta of preguntas1; let i = index">
                            <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                <mat-label>{{pregunta.descripcion}}</mat-label>
                                <input matInput type="text" autocomplete="off" value="{{preguntasForm1[i].value}}"
                                    disabled>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="error && internetC.internet">
                        <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                        <br>
                    </div>
                    <div *ngIf="information && internetC.internet">
                        <app-alertas-login [information]="information" [mensaje]="msjinformation"></app-alertas-login>
                        <br>
                    </div>
                    <div *ngIf="alertalogin && internetC.internet">
                        <app-alertas-login [alertalogin]="alertalogin" [mensaje]="idiomas.msjalertaPreguntasPass">
                        </app-alertas-login>
                        <br>
                    </div>
                    <div class="form-row">
                        <div class="right col" *ngIf="!QButton && internetC.internet">
                            <button mat-raised-button (click)="sendNewPassAndQ()" color="primary"
                                class="enviar button-login"
                                [disabled]="(!this.passForm.valid || !this.passConfirmForm.valid) || !(this.passForm.value == this.passConfirmForm.value)"
                                [class.offline]=!internetC.internet fxFlexFill>
                                <mat-icon>send</mat-icon>
                                {{idiomas.Enviar}}
                            </button>
                        </div>
                        <div class="right col" *ngIf="QButton && internetC.internet">
                            <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                [class.offline]=!internetC.internet fxFlexFill>
                                <mat-icon>arrow_forward_ios</mat-icon>
                                {{idiomas.btnIngresar}}
                            </button>
                        </div>
                        <div class="right textoffline col" *ngIf="!internetC.internet"
                            style="padding-top: 8px !important">
                            offline
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="unlockUserForm">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="internetC.internet">
                            <div *ngFor="let pregunta of preguntas2; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" [formControl]="preguntasForm2[i]"
                                        #input (input)="input.value=$event.target.value.toUpperCase()">
                                    <mat-error *ngIf="preguntasForm2[i].hasError('required')">
                                        <strong>{{idiomas.ValidarCampo}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="!internetC.internet">
                            <div *ngFor="let pregunta of preguntas2; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" value="{{preguntasForm2[i].value}}"
                                        disabled>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="error && internetC.internet">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="information && internetC.internet">
                            <app-alertas-login [information]="information" [mensaje]="msjinformation">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="alertalogin && internetC.internet">
                            <app-alertas-login [alertalogin]="alertalogin" [mensaje]="idiomas.msjalertaQ">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div class="form-row">
                            <div class="right col" *ngIf="!QButton && internetC.internet">
                                <button mat-raised-button (click)="sendQuestions()" color="primary"
                                    class="enviar button-login" [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right textoffline col" *ngIf="!internetC.internet"
                                style="padding-top: 8px !important">
                                offline
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="solicitudForm">
                <div style="text-align: center;">
                    <p *ngIf="!error">{{idiomas.SolicitudEnviada}}</p>
                    <p *ngIf="error">{{idiomas.SolicitudNoVigAutorizada}}</p>
                </div>
                <div style="padding-bottom: 20px;">
                    <app-alertas-login [alertasolicitud]="true" [mensaje]="idiomas.MsgNotificacionesPush">
                    </app-alertas-login>
                </div>
                <div *ngIf="spinner" class="col-12 spinner">
                    <mat-spinner style="margin: auto"></mat-spinner>
                </div>
                <div *ngIf="!spinner && error" style="text-align: center;">
                    <img src="assets/resources/mipmap-hdpi/error.png" class="imagen3" />
                </div>
            </div>
            <div *ngIf="!desnext && !desnewUser && !desnewPassword && !selectImageForm && !otpEnterForm && !newPassFormFirst && !newPassForm && !unlockUserForm && (!solicitudForm && error) && intentoInicio"
                style="padding-top: 5px;">
                <a class="form-row returnContainer" (click)="return()" style="max-width: 85px;">
                    <mat-icon class="returnArrow col">arrow_back_ios
                    </mat-icon>
                    <div class="returnLink col">{{idiomas.Regresar}}</div>
                </a>
            </div>
            <div *ngIf="desnext || desnewUser || desnewPassword || selectImageForm || otpEnterForm || newPassFormFirst || newPassForm || unlockUserForm || (solicitudForm && error) || !intentoInicio"
                style="padding-top: 5px;">
                <a class="form-row returnContainer" (click)="return()" style="max-width: 85px;">
                    <mat-icon class="returnArrow col">arrow_back_ios
                    </mat-icon>
                    <div class="returnLink col">{{idiomas.Regresar}}</div>
                </a>
            </div>
        </mat-card>

        <div *ngIf="bandMobile" style="padding: 16px;">
            <div *ngIf="intento">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <br>
            </div>
            <div *ngIf="desLogin">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div>
                            <mat-form-field (keyup.enter)="usrEnter()" appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Usr}}</mat-label>
                                <input id="userID" matInput type="text" autocomplete="off"
                                    [formControl]="usernameFormControl" [readonly]="desnext && internetC.internet"
                                    oninput="this.value = this.value.toUpperCase();">
                                <mat-error *ngIf="usernameFormControl.hasError('required')">
                                    <strong>{{idiomas.ValidarUsuario}}</strong>
                                </mat-error>
                                <mat-error *ngIf="usernameFormControl.hasError('pattern')">
                                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="error && !desnext && internetC.internet">
                            <div *ngIf=!verifyMesgInfo>
                                <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            </div>
                            <div *ngIf=verifyMesgInfo>
                                <app-alertas-login [alertalogin]="error" [mensaje]="msjerror"></app-alertas-login>
                            </div>
                            <br>
                        </div>
                        <div class="form-row" style="width: 100%; text-align: center;"
                            *ngIf="!desnext && internetC.internet && !bandUnlockUser && bandMobile">
                            <div class="col-12" style="text-align: center;">
                                <button mat-raised-button [disabled]="usernameFormControl.invalid || disableButton"
                                    (click)="nextStep(false)" color="primary" class="login button-login"
                                    [class.offline]=!internetC.internet style="height: 70px !important; width: 75px">
                                    <mat-icon
                                        style="font-size: 25px !important; height: 30px; width: 30px">login</mat-icon>
                                    <div style="line-height: 15px; font-size: .8em;">
                                        {{idiomas.Siguiente}}
                                    </div>
                                </button>
                            </div>
                            <!-- <div class="col-12" *ngIf="usernameFormControl.invalid">
                                <button mat-raised-button [disabled]="!hasBiometricCredentials" color="primary"
                                    class="login button-login" [class.offline]=!internetC.internet
                                    style="height: 70px !important; width: 75px" (click)="webAuthVerifyUserPassw()">
                                    <mat-icon style="font-size: 25px !important; height: 30px; width: 30px">fingerprint
                                    </mat-icon>
                                    <div class="form-row">
                                        <div class="col-12" style="line-height: 2px; font-size: .8em;">
                                            {{idiomas.FingerprintButton}}
                                        </div>
                                        <div class="col-12" style="line-height: 20px; font-size: .8em;">
                                            {{idiomas.FingerprintButtonF}}
                                        </div>
                                    </div>
                                </button>
                            </div> -->
                        </div>
                        <div *ngIf="desnext || !internetC.internet">
                            <div class="form-row" *ngIf="!firstTimeLogin && internetC.internet">
                                <div class="col-5" style="text-align: center; margin: auto">
                                    <img [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + userImage)"
                                        style="width: 80px; height: 80px; background-color: white;border-radius: 10px">
                                </div>
                                <div class="col-7" style="display:inlineonclick-flex" *ngIf="internetC.internet">
                                    <app-alertas-login [alertalogin]="alertalogin" [mensaje]="msjalert">
                                    </app-alertas-login>
                                </div>
                            </div>
                            <br *ngIf="!firstTimeLogin && internetC.internet">
                            <div *ngIf="firstTimeLogin && internetC.internet">
                                <app-alertas-login [alertalogin]="alertalogin" [mensaje]="msjalert"></app-alertas-login>
                                <br>
                            </div>
                            <mat-form-field (keyup.enter)="psswEnter()" appearance="outline" style="min-width: 235px"
                                fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input id="userPwdID" matInput [type]="hide[0] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passwordFormControl">
                                <div matSuffix (click)="hide[0] = !hide[0]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[0]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary" (click)="hideInterval()">{{hide[0] ? 'visibility_off' :
                                        'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passwordFormControl.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="error && internetC.internet"
                                style="max-width: 235px; margin-left: auto; margin-right: auto;">
                                <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                                <br>
                            </div>
                            <div class="right col-5" style="margin: auto; padding-bottom: 10px;"
                                *ngIf="((desnext && !firstTimeLogin) || !internetC.internet) && !bandUnlockUser">
                                <button mat-raised-button [disabled]="passwordFormControl.invalid || disableButton"
                                    (click)="login()" class="login button-login" color="primary"
                                    [class.offline]=!internetC.internet style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.Siguiente}}
                                </button>
                            </div>
                        </div>
                        <div class="form-row" style="padding-top: 10px;">
                            <div class="col-9" *ngIf="internetC.internet">
                                <a class="form-row returnContainer" (click)="newUser(null)"
                                    style="max-width: fit-content !important; padding-bottom: 7px; padding-top: 7px;">
                                    <mat-icon>person
                                    </mat-icon>
                                    <div class="returnLink col">{{idiomas.NuevoUsuario}}</div>
                                </a>
                                <a class="form-row returnContainer" (click)="newUser({reminder: true})"
                                    style="max-width: fit-content !important; padding-bottom: 7px; padding-top: 7px;">
                                    <mat-icon>record_voice_over
                                    </mat-icon>
                                    <div class="returnLink col">{{idiomas.RecuperarUsuario}}</div>
                                </a>
                                <a class="form-row returnContainer" (click)="changePassword()"
                                    style="max-width: fit-content !important; padding-bottom: 7px; padding-top: 7px;">
                                    <mat-icon>lock
                                    </mat-icon>
                                    <div class=" returnLink col">{{idiomas.NuevaContrasenia}}
                                    </div>
                                </a>
                            </div>
                            <div class="left textoffline col-7" *ngIf="!internetC.internet">
                                offline
                            </div>
                            <div class="col-5 right" style="margin: auto"
                                *ngIf="!desnext && internetC.internet && !bandUnlockUser && !bandMobile">
                                <button mat-raised-button [disabled]="usernameFormControl.invalid || disableButton"
                                    (click)="nextStep(false)" color="primary" class="login button-login"
                                    [class.offline]=!internetC.internet style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.Siguiente}}
                                </button>
                            </div>
                            <div class="right col-5" style="margin: auto"
                                *ngIf="firstTimeLogin && !uservalid && internetC.internet">
                                <button mat-raised-button (click)="login()" class="login button-login" color="primary"
                                    [class.offline]=!internetC.internet style="max-width: 130px" fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="col-5 right" style="margin: auto" *ngIf="internetC.internet && bandUnlockUser">
                                <button mat-raised-button (click)="nextStepUnlockUser()" color="primary"
                                    class="login button-login" [class.offline]=!internetC.internet
                                    style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.Desbloquear}}
                                </button>
                            </div>
                            <div class="col-5 right" style="margin: auto" *ngIf="!internetC.internet && bandUnlockUser">
                                <button mat-raised-button [disabled]="!passwordFormControl.valid" (click)="login()"
                                    class="login button-login" color="primary" [class.offline]=!internetC.internet
                                    style="max-width: 130px" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div *ngIf="idiomas.LoginSecureURL && !bandMobile" class="col-12"
                                style="text-align: center; top: 20px; opacity: 0.5; ">
                                <p class="loginOptionalText">{{idiomas.LoginSecureURL}}</p>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="errorOffline && !internetC.internet">
                            <app-alertas-login [error]="errorOffline" [mensaje]="msjerrorOffline"></app-alertas-login>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="desnewUser">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <label *ngIf="newUsernameMigratedUser"
                            style="text-align: center; width: 100%;"><strong>{{idiomas.migratedUserNameChange |
                                uppercase}}</strong></label>
                        <div
                            *ngIf="!newUsernameMigratedUser && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idTipo}}</mat-label>
                                <mat-select [(value)]='tipoid'
                                    (selectionChange)="changeTipoIdentification($event.value)" [formControl]="idTipo">
                                    <mat-option *ngFor="let tipo of idiomas.Identificacion" [value]="tipo.value">
                                        {{tipo.viewValue}}
                                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="idTipo.hasError('required')">
                                    <strong>{{idiomas.ValidarCampo}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="!newUsernameMigratedUser && !internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idTipo}}</mat-label>
                                <mat-select [(value)]='tipoid' disabled>
                                    <mat-option *ngFor="let tipo of idiomas.Identificacion" [value]="idTipo.value">
                                        {{tipo.viewValue}}
                                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="!newUsernameMigratedUser && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idSocio}}</mat-label>
                                <input id="ID" matInput type="text" autocomplete="off" [formControl]="idForm">
                                <mat-error *ngIf="idForm.hasError('maxlength')">
                                    <strong>{{idiomas.msjExedeTamano}}</strong>
                                </mat-error>
                                <mat-error *ngIf="idForm.hasError('required')">
                                    <strong>{{idiomas.msjIdRequerido}}</strong>
                                </mat-error>
                                <mat-error *ngIf="idForm.hasError('pattern')">
                                    <strong>{{idiomas.msjSoloNumeros}} </strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="!newUsernameMigratedUser && !internetC.internet && !instructionsForm && !faceLivenessForm">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.idSocio}}</mat-label>
                                <input matInput type="text" autocomplete="off" value="{{idForm.value}}" disabled>
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="information && !uservalid && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <app-alertas-login [information]="information"
                                [mensaje]="msjinformation"></app-alertas-login>
                            <br>
                        </div>
                        <div
                            *ngIf="alertalogin && !uservalid && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <app-alertas-login [alertalogin]="alertalogin" [mensaje]="msjalert"></app-alertas-login>
                            <br>
                        </div>
                        <div
                            *ngIf="error && !uservalid && internetC.internet && !instructionsForm && !faceLivenessForm">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="instructionsForm">
                            <div data-testid="liveness-detector" class="liveness-detector">
                                <div class="amplify-flex" style="flex-direction: column;">
                                    <div data-testid="liveness-detector-start"
                                        class="amplify-card liveness-detector-start">
                                        <div class="amplify-flex" style="flex-direction: column;">
                                            <div style="flex: 1 1 0%;">
                                                <div
                                                    style="color: var(--amplify-colors-font-primary); font-weight: var(--amplify-font-weights-bold);">
                                                    {{idiomas.instructionsHeaderHeadingText}}</div>
                                                <div style="color: var(--amplify-colors-font-primary);">
                                                    {{idiomas.instructionsHeaderBodyText}}</div>
                                            </div>
                                            <div class="amplify-flex amplify-alert-instructions"
                                                style="color: var(--amplify-colors-orange-80); background-color: var(--amplify-colors-orange-20); align-items: center;">
                                                <div style="flex: 1 1 0%;">
                                                    <div class="amplify-alert__heading">
                                                        {{idiomas.photosensitivyWarningHeadingText}}</div>
                                                    <div class="amplify-alert__body">
                                                        {{idiomas.photosensitivyWarningBodyText}}
                                                    </div>
                                                </div>
                                                <div data-testid="popover-icon" class="amplify-flex"
                                                    style="cursor: pointer; position: relative;">
                                                    <span class="amplify-icon amplify-alert__icon" aria-hidden="true"
                                                        style="width: 1em; height: 1em;"><svg width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                                                                fill="currentColor"></path>
                                                        </svg></span>
                                                </div>
                                            </div>
                                            <div class="amplify-flex" style="flex-direction: column;">
                                                <p class="amplify-text"
                                                    style="color: var(--amplify-colors-font-primary); font-weight: var(--amplify-font-weights-bold);">
                                                    {{idiomas.instructionListHeadingText}}</p>
                                                <div class="amplify-flex amplify-liveness-figures">
                                                    <figure
                                                        class="amplify-flex amplify-flex-instructions amplify-liveness-figure amplify-liveness-figure--success">
                                                        <div
                                                            class="amplify-liveness-figure__image amplify-liveness-figure__image--success">
                                                            <svg class="amplify-liveness-figure__icon"
                                                                aria-hidden="true" width="24" height="24">
                                                                <g fill="none">
                                                                    <path fill="#365E3D" d="M0 0h24v24H0z"></path>
                                                                    <path fill="#FFF"
                                                                        d="m9.435 15.62-4.054-4.055L4 12.936l5.435 5.435L21.101 6.704l-1.37-1.371z">
                                                                    </path>
                                                                </g>
                                                            </svg><svg width="150" height="150">
                                                                <title>{{idiomas.goodFitAltText}}</title>
                                                                <g fill="none" fill-rule="evenodd"
                                                                    transform="translate(0 -.001)">
                                                                    <path fill="#5B361E" fill-rule="nonzero"
                                                                        d="M124.655 0c9.173 8.155 9.394 17.812 13.258 32.385l.053.336.108.726.11.796.112.864.114.931.174 1.515.117 1.087.18 1.739.12 1.23.183 1.944.123 1.36.186 2.13.187 2.232.313 3.928.25 3.31.25 3.443.31 4.463.245 3.679.36 5.658.345 5.778.33 5.841.26 4.876.199 3.883.187 3.849.217 4.738.16 3.712.178 4.515.097 2.63v34.977L.519 150 .517 41.97c3-13.353 9.664-29.4 23.841-41.97h100.297Z">
                                                                    </path>
                                                                    <path fill="#FCDDCC" fill-rule="nonzero"
                                                                        stroke="#000"
                                                                        d="m50.469 18.849.145.153c9.021 9.393 22.62 16.197 36.089 21.996l2.1.897 1.05.443 2.089.876 8.176 3.385 1.979.825 1.944.82c7.782 3.3 14.617 6.491 19.213 10.006 3.57 2.73 5.793 5.645 5.924 8.999v20.474l-.008.624-.016.669-.04 1.089-.04.777-.047.815-.06.853-.068.887-.08.918-.093.95-.104.978-.057.496-.123 1.016-.066.513-.144 1.049-.076.527-.165 1.077c-.057.36-.116.724-.178 1.086l-.193 1.103-.21 1.116-.11.557-.233 1.13c-.12.564-.247 1.13-.38 1.694l-.275 1.14c-1.037 4.147-2.426 8.3-4.271 11.978-6.17 9.34-12.996 16.035-19.28 20.691l-.8.584-.794.562-.784.539-1.165.77-1.147.724-.755.459c-.249.148-.497.294-.74.434l-.73.416-1.078.588-.702.367-1.033.517-.671.321-.657.303-.643.285-.541.23H68.149a75.81 75.81 0 0 1-.81-.284l-.918-.336a75.953 75.953 0 0 1-.935-.355l-.963-.382a85.513 85.513 0 0 1-1.988-.83l-1.032-.455c-.52-.233-1.05-.475-1.585-.727l-1.087-.517-1.113-.547c-.935-.465-1.893-.959-2.873-1.482l-1.193-.644a141.053 141.053 0 0 1-6.297-3.669l-1.33-.83c-17.11-10.783-22.636-33.458-23.66-49.98l-.071-1.267c-.02-.417-.038-.83-.053-1.235l-.037-1.212a86.317 86.317 0 0 1 .042-5.559l.047-1.002.06-.96.064-.843c1.09-2.51 2.164-4.304 3.296-5.882l.408-.558.415-.545.421-.538 2.026-2.492.481-.597.493-.624.507-.656.518-.69a61.722 61.722 0 0 0 3.769-5.754c4.03-6.917 7.127-14.806 9.544-21.668l.566-1.623.802-2.344 2.077-6.175.416-1.205.395-1.109.373-1.007.267-.682.253-.612c.47-.943.8-1.531 1.06-1.876l-.035.047Z">
                                                                    </path>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="m94.566 121.353.722.895c-6.828 5.51-14.13 7.462-21.382 6.447-5.417-.758-10.535-3.2-13.987-6.186l-.318-.282.77-.854c3.285 2.964 8.343 5.434 13.694 6.183 6.797.95 13.632-.819 20.089-5.876l.412-.327Z">
                                                                    </path>
                                                                    <ellipse cx="51.331" cy="80.698" fill="#000"
                                                                        fill-rule="nonzero" rx="4.886" ry="6.707">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M42.539 63.719c4.453-2.586 11.355-3.268 17.22-.195l.35.19-.556 1.005c-5.437-3.01-11.946-2.479-16.175-.153l-.262.148-.577-.995Z">
                                                                    </path>
                                                                    <ellipse cx="103.281" cy="80.698" fill="#000"
                                                                        fill-rule="nonzero" rx="4.886" ry="6.707">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M94.492 63.719c4.453-2.586 11.355-3.268 17.22-.195l.35.19-.557 1.005c-5.436-3.01-11.946-2.479-16.174-.153l-.262.148-.577-.995Zm-22.972 32.9c0 4.216 2.006 7.72 5.831 7.48l.232-.018.115 1.144c-4.774.477-7.239-3.571-7.326-8.345l-.003-.26 1.15-.001h.001Z">
                                                                    </path>
                                                                    <path fill="#FFF" fill-rule="nonzero"
                                                                        d="M75.002.001H0v150h150v-150H75.002Zm0 0c25.627 0 46.402 33.579 46.402 75s-20.775 75-46.402 75c-25.627 0-46.402-33.579-46.402-75s20.775-75 46.402-75Z">
                                                                    </path>
                                                                    <path stroke="#AEB3B7" stroke-width="2"
                                                                        d="M120.921 75.001c0 20.555-5.214 39.117-13.589 52.507-8.386 13.406-19.838 21.493-32.313 21.493-12.476 0-23.928-8.087-32.312-21.493-8.377-13.39-13.59-31.952-13.59-52.507 0-20.555 5.214-39.116 13.589-52.507C51.091 9.09 62.543 1.001 75.018 1.001c12.476 0 23.928 8.088 32.314 21.493 8.375 13.39 13.588 31.952 13.588 52.507h.001Z">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <figcaption
                                                            class="amplify-liveness-figure__caption amplify-liveness-figure__caption--success">
                                                            {{idiomas.goodFitCaptionText}}</figcaption>
                                                    </figure>
                                                    <figure
                                                        class="amplify-flex amplify-flex-instructions amplify-liveness-figure amplify-liveness-figure--error">
                                                        <div
                                                            class="amplify-liveness-figure__image amplify-liveness-figure__image--error">
                                                            <svg class="amplify-liveness-figure__icon"
                                                                aria-hidden="true" width="24" height="24">
                                                                <g fill="none">
                                                                    <path fill="#600" d="M0 0h24v24H0z"></path>
                                                                    <path fill="#FFF"
                                                                        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                                                    </path>
                                                                </g>
                                                            </svg><svg width="150" height="150">
                                                                <title>{{idiomas.tooFarAltText}}</title>
                                                                <defs>
                                                                    <linearGradient id="a" x1="50%" x2="50%" y1="0%"
                                                                        y2="100%">
                                                                        <stop offset="0%" stop-color="#C2C2C2"></stop>
                                                                        <stop offset="100%" stop-color="#C2C2C2"
                                                                            stop-opacity="0">
                                                                        </stop>
                                                                    </linearGradient>
                                                                </defs>
                                                                <g fill="none" fill-rule="evenodd"
                                                                    transform="translate(2)">
                                                                    <path fill="#FFF" fill-rule="nonzero"
                                                                        d="M3.997 0h136v150h-136z"></path>
                                                                    <path fill="url(#a)" fill-rule="nonzero"
                                                                        d="M4.333 0h138v150h-138z"></path>
                                                                    <path fill="#5B361E" fill-rule="nonzero"
                                                                        stroke="#000" stroke-width="1.353"
                                                                        d="m22.515 58.137-1.895 82.434 98.784-2.343c0-8.798.813-16.271.813-31.497 0-15.748-2.345-55.36-3.766-64.125C108.16 11.338 74.737 5.03 56.707 11.04c-28.425 9.475-33.64 35.095-34.192 47.097Z">
                                                                    </path>
                                                                    <path fill="#EF992A" fill-rule="nonzero"
                                                                        d="M38.104 138.228c8.339-3.98 5.1-1.525 15.916-3.104H90.5c5.448 0 9.541 3.104 28.904 3.104 6.391 0 5.987 8.988-2.473 8.988-24.315 1.08-66.07 1.08-78.281 0-77.975-6.896-10.97-4.014-.546-8.988Z">
                                                                    </path>
                                                                    <path fill="#FCDDCC" fill-rule="nonzero"
                                                                        stroke="#000" stroke-width="1.353"
                                                                        d="m54.306 134.26 2.645-21.765h30.498l3.05 21.765c-15.778 14.791-30.703 6.163-36.193 0zm-27.59-54.458C25.42 66.68 33.467 67.18 37.653 69.07l2.837 25.314c-10.328-2.228-13.772-12.961-13.772-14.58zm89.512-.81c4.05-15.067-3.984-15.998-8.506-14.58L105.9 91.75c10.328-8.505 9.113-12.758 10.328-12.758z">
                                                                    </path>
                                                                    <path fill="#FCDDCC" fill-rule="nonzero"
                                                                        stroke="#000" stroke-width="1.353"
                                                                        d="M53.564 109.804c-14.195-8.986-16.116-30.658-15.302-40.37 2.24-5.21 4.37-5.723 7.958-11.909 6.3-10.86 9.028-25.451 10.579-25.009 14.241 16.008 50.215 20.259 50.649 31.708v13.023c0 4.178-.911 14.358-4.558 21.65-8.986 13.674-20.131 18.612-24.58 19.372-2.334.922-10.55.521-24.746-8.465Z">
                                                                    </path>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="m83.935 98.402.85 1.052c-7.974 6.435-17.2 5.243-23.018.18l-.23-.204.905-1.004c5.273 4.756 13.744 5.998 21.175.227l.318-.251Z">
                                                                    </path>
                                                                    <ellipse cx="56.448" cy="72.613" fill="#000"
                                                                        fill-rule="nonzero" rx="3.128" ry="4.294">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M50.664 61.476c2.917-1.694 7.404-2.147 11.244-.172l.31.165-.655 1.183c-3.348-1.854-7.361-1.545-9.985-.137l-.234.13-.68-1.17Z">
                                                                    </path>
                                                                    <ellipse cx="89.709" cy="72.613" fill="#000"
                                                                        fill-rule="nonzero" rx="3.128" ry="4.294">
                                                                    </ellipse>
                                                                    <path fill="#000" fill-rule="nonzero"
                                                                        d="M83.926 61.476c2.917-1.694 7.404-2.147 11.244-.172l.31.165-.655 1.183c-3.348-1.854-7.361-1.545-9.985-.137l-.234.13-.68-1.17Z">
                                                                    </path>
                                                                    <path stroke="#000" stroke-width="1.353"
                                                                        d="M69.005 82.806c0 1.858.859 5.487 4.287 5.144">
                                                                    </path>
                                                                    <path fill="#FFF"
                                                                        d="M73.004 0H0v150h146V0H73.004Zm.496 0C98.629 0 119 33.579 119 75s-20.371 75-45.5 75S28 116.421 28 75 48.371 0 73.5 0Z">
                                                                    </path>
                                                                    <path stroke="#AEB3B7" stroke-width="2"
                                                                        d="M118.4 75c0 20.555-5.156 39.117-13.441 52.507C96.665 140.913 85.338 149 72.999 149c-12.34 0-23.667-8.087-31.961-21.493C32.753 114.117 27.597 95.555 27.597 75c0-20.555 5.156-39.117 13.44-52.507C49.333 9.087 60.66 1 72.999 1c12.34 0 23.667 8.087 31.961 21.493C113.244 35.883 118.4 54.445 118.4 75Z">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <figcaption
                                                            class="amplify-liveness-figure__caption amplify-liveness-figure__caption--error">
                                                            {{idiomas.tooFarCaptionText}}</figcaption>
                                                    </figure>
                                                </div>
                                                <ol class="amplify-flex amplify-liveness-instruction-list">
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">1.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepOneText}}</span>
                                                    </li>
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">2.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepTwoText}}</span>
                                                    </li>
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">3.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepThreeText}}</span>
                                                    </li>
                                                    <li class="amplify-flex"><span class="amplify-text"
                                                            aria-hidden="true">4.</span><span
                                                            class="amplify-text">{{idiomas.instructionListStepFourText}}</span>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div class="amplify-flex" (click)="rekognitionStep()"
                                                style="justify-content: center;">
                                                <button mat-raised-button color="primary" class="enviar button-login"
                                                    data-fullwidth="false" data-variation="primary"
                                                    style="margin-left: 0;" type="button">
                                                    <mat-icon>arrow_forward_ios</mat-icon>{{idiomas.Siguiente}}</button>
                                            </div>
                                            <div *ngIf="spinner">
                                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="faceLivenessForm">
                            <div style="margin-left: auto; margin-right: auto;">
                                <app-face-liveness *ngIf="!intentoSpinner && !error" [idiomas]="idiomas"
                                    (eventImage)="verifyImage($event)"></app-face-liveness>
                                <div *ngIf="intentoSpinner">
                                    <p style="text-align: center; font-size: 12px;">{{idiomas.ValidandoVideo}}</p>
                                    <div class="spinner">
                                        <mat-spinner style="margin: auto"></mat-spinner>
                                    </div>
                                    <br>
                                </div>
                                <div *ngIf="error && internetC.internet">
                                    <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="userExist">
                            <div *ngIf="internetC.internet">
                                <mat-form-field appearance="outline" fxFlexFill>
                                    <mat-label>{{idiomas.Usr}}</mat-label>
                                    <input id="userID" matInput type="text" autocomplete="off" [formControl]="userForm1"
                                        oninput="this.value = this.value.toUpperCase();" maxlength="15">
                                    <mat-error *ngIf="userForm1.hasError('required')">
                                        <strong>{{idiomas.ValidarUsuario}}</strong>
                                    </mat-error>
                                    <mat-error *ngIf="userForm1.hasError('pattern')">
                                        <strong>{{idiomas.NuevoUsuarioInvalido}}</strong>
                                    </mat-error>
                                    <mat-error *ngIf="userForm1.hasError('minlength')">
                                        <strong>{{idiomas.NuevoUsuarioLength}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="!internetC.internet">
                                <mat-form-field appearance="outline" fxFlexFill>
                                    <mat-label>{{idiomas.Usr}}</mat-label>
                                    <input matInput type="text" autocomplete="off" value="{{userForm1.value}}" disabled>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-row">
                            <!--<div class="col-3" style="max-width: 60px;">
                                <a style="text-decoration: underline;cursor: pointer; font-size: 12px; margin-top: 10px"
                                    (click)="return()">
                                    <div style="padding-top: 14px">{{idiomas.Regresar}}</div>
                                </a>
                            </div>-->
                            <div class="right col"
                                *ngIf="!userExist && internetC.internet && !verifyContract && !instructionsForm && !faceLivenessForm">
                                <button mat-raised-button (click)="sendIdentification()" color="primary"
                                    class="enviar button-login" [disabled]="!idTipo.valid || !idForm.valid" fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col"
                                *ngIf="userExist && internetC.internet && !uservalid && !instructionsForm && !faceLivenessForm">
                                <button mat-raised-button (click)="sendUser()" color="primary"
                                    class="enviar button-login" [disabled]="!userForm1.valid" fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col"
                                *ngIf="userExist && internetC.internet && uservalid && !instructionsForm && !faceLivenessForm">
                                <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                    [disabled]="!userForm1.valid" fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div *ngIf="!internetC.internet" class="right textoffline col">
                                offline
                                <br>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="information && uservalid && internetC.internet">
                            <app-alertas-login [information]="information"
                                [mensaje]="msjinformation"></app-alertas-login>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="desnewPassword">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="internetC.internet">
                            <mat-form-field (keyup.enter)="enterNewPassword()" appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Usr}}</mat-label>
                                <input id="userID" matInput type="text" autocomplete="off" [formControl]="userForm"
                                    oninput="this.value = this.value.toUpperCase();">
                                <mat-error *ngIf="userForm.hasError('required')">
                                    <strong>{{idiomas.ValidarUsuario}}</strong>
                                </mat-error>
                                <mat-error *ngIf="userForm.hasError('pattern')">
                                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Usr}}</mat-label>
                                <input matInput type="text" autocomplete="off" value="{{userForm.value}}" disabled>
                            </mat-form-field>
                        </div>
                        <div *ngIf="error && internetC.internet">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="information && internetC.internet">
                            <app-alertas-login [information]="information"
                                [mensaje]="msjinformation"></app-alertas-login>
                            <br>
                        </div>
                        <div class="form-row">
                            <div class="right col" *ngIf="internetC.internet && !bandusuario">
                                <button mat-raised-button (click)="sendUserNewPass()" [disabled]="!userForm.valid"
                                    color="primary" class="enviar button-login" [class.offline]=!internetC.internet
                                    fxFlexFill>{{idiomas.Recuperar}}</button>
                            </div>
                            <div class="right col" *ngIf="internetC.internet && bandusuario">
                                <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                    [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div *ngIf="!internetC.internet" class="right textoffline col">
                                offline
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectImageForm" class="form-row">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <p class="col-12">{{idiomas.msjSeleccionImgQ}}</p>
                        <p *ngIf="nImgAndQ" class="col-12" style="font-size: 12px; opacity:0.6;">
                            {{idiomas.favImg}}</p>
                        <div *ngIf="nImgAndQ" style="text-align: center;padding-left: 0px;padding-right: 0px;"
                            class="col-12">
                            <table style="margin: auto; width: 210px; height: 160px;">
                                <tr>
                                    <td *ngFor="let imagen of imagenes.slice(0, (numImages/2));let i = index">
                                        <div [class.borderImg]="borderImg[i]" [class.borderImg1]="!borderImg[i]"
                                            style="text-align:center;" (click)="selectImage(imagen.cimagen)">
                                            <img value="i" id="i" src="data:image/png;base64,{{imagen.imagen64}}"
                                                style="width: 45px; height: 45px;cursor: pointer;padding-top: 4px">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td *ngFor="let imagen of imagenes.slice((numImages/2), numImages);let i = index">
                                        <div [class.borderImg]="borderImg[i+4]" [class.borderImg1]="!borderImg[i+4]"
                                            style="text-align:center;" (click)="selectImage(imagen.cimagen)">
                                            <img value="i+4" src="data:image/png;base64,{{imagen.imagen64}}"
                                                style="width: 35px; height: 35px;cursor: pointer;padding-top: 4px">
                                        </div>
                                    </td>
                                </tr>
                                <br>
                            </table>
                        </div>
                        <div *ngIf="internetC.internet && nImgAndQ" class="col-12">
                            <div *ngFor="let pregunta of preguntas; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" [formControl]="preguntasForm[i]"
                                        #input (input)="input.value=$event.target.value.toUpperCase()">
                                    <mat-error *ngIf="preguntasForm[i].hasError('required')">
                                        <strong>{{idiomas.ValidarCampo}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="!internetC.internet && nImgAndQ" class="col-12">
                            <div *ngFor="let pregunta of preguntas; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" value="{{preguntasForm[i].value}}"
                                        disabled>
                                </mat-form-field>
                            </div>
                        </div>
                        <div style="text-align: -webkit-center !important;">
                            <div *ngIf="alertalogin && internetC.internet" class="col-9">
                                <app-alertas-login [alertalogin]="alertalogin" [mensaje]="idiomas.msjalertaPreguntas">
                                </app-alertas-login>
                                <br>
                            </div>
                            <div *ngIf="error && internetC.internet" class="col-9">
                                <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                                <br>
                            </div>
                            <div *ngIf="information && internetC.internet" class="col-9">
                                <app-alertas-login [information]="information" [mensaje]="msjinformation">
                                </app-alertas-login>
                                <br>
                            </div>
                        </div>
                        <div class="row" *ngIf="internetC.internet" style="padding-left: 15px; padding-right: 15px">
                            <div class="col-7">
                                <a *ngIf="!imgQButton"
                                    style="text-decoration: underline;cursor: pointer; font-size: 12px"
                                    (click)="login()">
                                    {{idiomas.NImgAndQ}}
                                </a>
                            </div>
                            <div class="right col-5" *ngIf="!imgQButton" style="margin-left: auto">
                                <button mat-raised-button (click)="sendImageAndQuestions()" [disabled]=""
                                    color="primary" class="enviar button-login" [class.offline]=!internetC.internet
                                    fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col-5" *ngIf="imgQButton" style="margin: auto">
                                <button mat-raised-button (click)="nextStepNewPass()" color="primary"
                                    class="enviar button-login" [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>{{idiomas.Siguiente}}
                                </button>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 15px; padding-right: 15px;">
                            <div class="right col textoffline" *ngIf="!internetC.internet">
                                offline
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="otpEnterForm">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <p>{{idiomas.Otp}}</p>
                        <div style="margin-top: 1.6em;">
                            <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                                [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                        </div>
                        <div>
                            <div class="form-row" style="width: 100%; margin:auto">
                                <div class="col-md-8 col-sm-7 col-xs-6" *ngIf="internetC.internet">
                                    <mat-form-field (keyup.enter)="otpEnter()" appearance="outline" fxFlexFill>
                                        <input id="userOTP" matInput type="text" autocomplete="off" inputmode="numeric"
                                            onkeydown="this.setAttribute('type','password')" [formControl]="otpForm"
                                            maxlength="6" appBlockCopyPaste>
                                        <mat-error *ngIf="otpForm.hasError('required')">
                                            <strong>{{idiomas.ValidarCampo}}</strong>
                                        </mat-error>
                                        <mat-error *ngIf="otpForm.hasError('pattern')">
                                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                                        </mat-error>
                                        <mat-error *ngIf="otpForm.hasError('minlength')">
                                            <strong>{{idiomas.msjOtpminmax}}</strong>
                                        </mat-error>
                                        <mat-error *ngIf="otpForm.hasError('maxlength')">
                                            <strong>{{idiomas.msjOtpminmax}}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-8 col-sm-7 col-xs-6" *ngIf="!internetC.internet">
                                    <mat-form-field (keyup.enter)="otpEnter()" appearance="outline" fxFlexFill>
                                        <input matInput type="password" autocomplete="off" value="{{otpForm.value}}"
                                            disabled>
                                    </mat-form-field>
                                </div>
                                <div class="left textoffline col" *ngIf="!internetC.internet"
                                    style="padding-top: 8px !important">
                                    offline
                                </div>
                                <div class="col" *ngIf="internetC.internet" style="max-width: 150px;">
                                    <button mat-raised-button (click)="sendOtp()" style="min-width: 110px !important;"
                                        [disabled]="otpForm.invalid || disableButton" color="primary"
                                        class="enviarOtp1 button-login" [class.offline]=!internetC.internet fxFlexFill>
                                        <mat-icon>touch_app</mat-icon>
                                        {{idiomas.btnIngresar}}
                                    </button>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="right col-7" style="padding-top: 14px">
                                    <a *ngIf="internetC.internet && !enableCronoIn"
                                        style="text-decoration: underline;cursor: pointer; font-size: 12px; padding-right: 12px"
                                        (click)="login()">{{idiomas.NToken}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="error">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="newPassFormFirst">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="isPasswordExpired">
                            <app-alertas-login [alertaloginpass]="true" [mensaje]="mensajePasswordExpirado">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="mensajeContra!=null && !isPasswordExpired"
                            style="margin-bottom: -15px; margin-top: -10px;">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajeContra">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput [type]="hide[1] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passForm">
                                <div matSuffix (click)="hide[1] = !hide[1]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[1]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[1] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput [type]="hide[2] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passConfirmForm">
                                <div matSuffix (click)="hide[2] = !hide[2]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[2]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[2] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passConfirmForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput type="password" autocomplete="off" value="{{passForm.value}}" disabled>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput type="password" autocomplete="off" value="{{passConfirmForm.value}}"
                                    disabled>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!isPasswordExpired" style="margin-bottom: -10px; margin-top: -15px;">
                            <app-alertas-login [alertalogin]="true" [mensaje]="idiomas.LoginPassExtraMessage">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="mensajeContra!=null && isPasswordExpired"
                            style="margin-bottom: -15px; margin-top: -10px;">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajeContra">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="error && internetC.internet">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="information && internetC.internet">
                            <app-alertas-login [information]="information" [mensaje]="msjinformation">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div class="form-row">
                            <div class="right col" *ngIf="!QButton && internetC.internet">
                                <button mat-raised-button (click)="sendNewPass()" color="primary"
                                    class="enviar button-login"
                                    [disabled]="(!this.passForm.valid || !this.passConfirmForm.valid) || !(this.passForm.value == this.passConfirmForm.value)"
                                    [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right col" *ngIf="QButton && internetC.internet">
                                <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                    [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                    {{idiomas.btnIngresar}}
                                </button>
                            </div>
                            <div class="right textoffline col" *ngIf="!internetC.internet"
                                style="padding-top: 8px !important">
                                offline
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="newPassForm">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="isPasswordExpired">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajePasswordExpirado">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="mensajeContra!=null">
                            <app-alertas-login [alertalogin]="true" [mensaje]="mensajeContra">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="internetC.internet">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput [type]="hide[3] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passForm">
                                <div matSuffix (click)="hide[3] = !hide[3]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[3]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[3] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput [type]="hide[4] ? 'password' : 'text'" autocomplete="off"
                                    [formControl]="passConfirmForm">
                                <div matSuffix (click)="hide[4] = !hide[4]" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide[4]"
                                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                    <mat-icon color="primary">{{hide[4] ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                                </div>
                                <mat-error *ngIf="passConfirmForm.hasError('required')">
                                    <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <div *ngFor="let pregunta of preguntas1; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" [formControl]="preguntasForm1[i]"
                                        #input (input)="input.value=$event.target.value.toUpperCase()">
                                    <mat-error *ngIf="preguntasForm[i].hasError('required')">
                                        <strong>{{idiomas.ValidarCampo}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!internetC.internet">
                        <div>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.Pssw}}</mat-label>
                                <input matInput type="password" value="{{passForm.value}}" disabled>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.PsswConfirm}}</mat-label>
                                <input matInput type="password" value="{{passConfirmForm.value}}" disabled>
                            </mat-form-field>
                        </div>
                        <div *ngFor="let pregunta of preguntas1; let i = index">
                            <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                <mat-label>{{pregunta.descripcion}}</mat-label>
                                <input matInput type="text" autocomplete="off" value="{{preguntasForm1[i].value}}"
                                    disabled>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="error && internetC.internet">
                        <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                        <br>
                    </div>
                    <div *ngIf="information && internetC.internet">
                        <app-alertas-login [information]="information" [mensaje]="msjinformation"></app-alertas-login>
                        <br>
                    </div>
                    <div *ngIf="alertalogin && internetC.internet">
                        <app-alertas-login [alertalogin]="alertalogin" [mensaje]="idiomas.msjalertaPreguntasPass">
                        </app-alertas-login>
                        <br>
                    </div>
                    <div class="form-row">
                        <div class="right col" *ngIf="!QButton && internetC.internet">
                            <button mat-raised-button (click)="sendNewPassAndQ()" color="primary"
                                class="enviar button-login"
                                [disabled]="(!this.passForm.valid || !this.passConfirmForm.valid) || !(this.passForm.value == this.passConfirmForm.value)"
                                [class.offline]=!internetC.internet fxFlexFill>
                                <mat-icon>send</mat-icon>
                                {{idiomas.Enviar}}
                            </button>
                        </div>
                        <div class="right col" *ngIf="QButton && internetC.internet">
                            <button mat-raised-button (click)="return()" color="primary" class="enviar button-login"
                                [class.offline]=!internetC.internet fxFlexFill>
                                <mat-icon>arrow_forward_ios</mat-icon>
                                {{idiomas.btnIngresar}}
                            </button>
                        </div>
                        <div class="right textoffline col" *ngIf="!internetC.internet"
                            style="padding-top: 8px !important">
                            offline
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="unlockUserForm">
                <div class="container container-login" fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <div *ngIf="internetC.internet">
                            <div *ngFor="let pregunta of preguntas2; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" [formControl]="preguntasForm2[i]"
                                        #input (input)="input.value=$event.target.value.toUpperCase()">
                                    <mat-error *ngIf="preguntasForm2[i].hasError('required')">
                                        <strong>{{idiomas.ValidarCampo}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="!internetC.internet">
                            <div *ngFor="let pregunta of preguntas2; let i = index">
                                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                                    <mat-label>{{pregunta.descripcion}}</mat-label>
                                    <input matInput type="text" autocomplete="off" value="{{preguntasForm2[i].value}}"
                                        disabled>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="error && internetC.internet">
                            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="information && internetC.internet">
                            <app-alertas-login [information]="information" [mensaje]="msjinformation">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div *ngIf="alertalogin && internetC.internet">
                            <app-alertas-login [alertalogin]="alertalogin" [mensaje]="idiomas.msjalertaQ">
                            </app-alertas-login>
                            <br>
                        </div>
                        <div class="form-row">
                            <div class="right col" *ngIf="!QButton && internetC.internet">
                                <button mat-raised-button (click)="sendQuestions()" color="primary"
                                    class="enviar button-login" [class.offline]=!internetC.internet fxFlexFill>
                                    <mat-icon>send</mat-icon>
                                    {{idiomas.Enviar}}
                                </button>
                            </div>
                            <div class="right textoffline col" *ngIf="!internetC.internet"
                                style="padding-top: 8px !important">
                                offline
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="scannQr">
                <div *ngIf="!valid">
                    <p style="text-align: center;">{{idiomas.LeerQR}}</p>
                    <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)">
                    </zxing-scanner>
                </div>
            </div>
           <div *ngIf="pinEnterForm" [hidden]="!pinEnterForm">
                <div style="text-align: center; height: 65px;">
                    <p>{{idiomas.IngresarPinLog}}</p>
                    <mat-form-field id="pin" *ngFor="let digit of digits; let i = index" appearance="outline"
                        style="width: 35px; margin-left: 5px;">
                        <input id="myInput{{i}}" (keydown)="blockText($event)"
                            (keyup)="enableButton(); focusNext(i, $event)" matInput [formControl]="digit.field"
                            (keypress)="changeValue(i, $event)" matInput [formControl]="digit.field" maxlength="1"
                            inputmode="numeric" type="password">
                    </mat-form-field>
                </div>
                <br><br>
                <div *ngIf="error && internetC.internet">
                    <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                    <br>
                </div>
                <div style="text-align: center;">
                    <button mat-raised-button (click)="sendPin()" style="min-width: 110px !important;"
                        [disabled]="!validPin" color="primary" class="enviarOtp1 button-login"
                        [class.offline]=!internetC.internet fxFlexFill>
                        <mat-icon>touch_app</mat-icon>
                        {{idiomas.Enviar}}
                    </button>
                </div>
            </div>
            <div *ngIf="desvincularForm">
                <div *ngIf="!deviceUnLinked" style="text-align: -webkit-center;">
                    <div style="width: 60%;">
                        <p><strong>{{idiomas.DesvincularDispositivoTot}}</strong></p>
                    </div>
                    <div style="width: 80%; text-align: justify;">
                        <p><strong>{{idiomas.DesvincularDispositivoTotMens}}</strong></p>
                    </div>
                    <div style="width: 60%;">
                        <p><strong>{{idiomas.NombreDispositivo}}:</strong> {{nombreDispositivo}}</p>
                    </div>
                    <div style="width: 60%;">
                        <p><strong>{{idiomas.NumeroTelefonoCelular}}:</strong> {{numeroTelefono}}</p>
                    </div>
                    <div style="width: 60%;">
                        <p><strong>{{idiomas.FechaRegistro}}:</strong> {{fechaVinculacion |
                            formatoFechaCalendar:idiomas.IdiomaSeleccionado}} </p>
                    </div>
                </div>
                <div *ngIf="!deviceUnLinked" class="container container-login" fxLayout="column"
                    fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.Usr}}</mat-label>
                            <input id="userID" matInput type="text" autocomplete="off"
                                [formControl]="usernameFormControl1" [readonly]="desnext && internetC.internet"
                                oninput="this.value = this.value.toUpperCase();">
                            <mat-error *ngIf="usernameFormControl1.hasError('required')">
                                <strong>{{idiomas.ValidarUsuario}}</strong>
                            </mat-error>
                            <mat-error *ngIf="usernameFormControl1.hasError('pattern')">
                                <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="min-width: 235px" fxFlexFill
                            (keyup.enter)="unregisterDevice()">
                            <mat-label>{{idiomas.Pssw}}</mat-label>
                            <input id="userPwdID" matInput [type]="hide[0] ? 'password' : 'text'" autocomplete="off"
                                [formControl]="passwordFormControl1">
                            <div matSuffix (click)="hide[0] = !hide[0]" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide[0]"
                                style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                <mat-icon color="primary">{{hide[0] ? 'visibility_off' :
                                    'visibility'}}
                                </mat-icon>
                            </div>
                            <mat-error *ngIf="passwordFormControl1.hasError('required')">
                                <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="error && internetC.internet">
                        <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                        <br>
                    </div>
                    <div class="form-row">
                        <div class="right col" *ngIf="internetC.internet">
                            <button mat-raised-button (click)="unregisterDevice()" color="primary"
                                class="enviar button-login" style="margin: 0px !important"
                                [disabled]="usernameFormControl1.invalid || passwordFormControl1.invalid"
                                [class.offline]=!internetC.internet fxFlexFill>
                                <mat-icon>send</mat-icon>
                                {{idiomas.Enviar}}
                            </button>
                        </div>
                        <div class="center textoffline col" *ngIf="!internetC.internet"
                            style="padding-top: 8px !important">
                            offline
                        </div>
                    </div>
                </div>
                <div *ngIf="deviceUnLinked" class="container container-login" fxLayout="column"
                    fxLayoutAlign="start stretch">
                    <div style="text-align: center;">
                        <p>{{idiomas.DispositivoDesvinculado}}</p>
                    </div>
                    <div class="camposLogIn" style="text-align: center;">
                        <img src="assets/resources/mipmap-hdpi/felicitacion.png" class="imagen3" />
                    </div>
                </div>
            </div>
            <div *ngIf="deviceLinkedForm">
                <div style="text-align: center;">
                    <p *ngIf="!deviceLinked"><strong>{{idiomas.DispAVincular}}</strong></p>
                    <p *ngIf="deviceLinked"><strong>{{idiomas.DispVinculado}}</strong></p>
                    <p><strong>{{idiomas.NombreDispositivo}}:</strong> {{deviceName}}</p>
                    <p><strong>{{idiomas.NumeroTelefonoCelular}}:</strong> {{phoneNumber}}</p>
                    <p><strong>{{idiomas.FechaRegistro}}:</strong> {{dateRegister |
                        formatoFechaCalendar:idiomas.IdiomaSeleccionado}}</p>
                </div>
                <div *ngIf="error && internetC.internet">
                    <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                    <br>
                </div>
                <div *ngIf="!deviceLinked" class="form-row">
                    <div class="col-6">
                        <button mat-raised-button (click)="linkDevice()" style="min-width: 110px !important;"
                            color="primary" class="enviar button-login" [class.offline]=!internetC.internet fxFlexFill>
                            <mat-icon>send</mat-icon>
                            {{idiomas.Vincular}}
                        </button>
                    </div>
                    <div class="col-6" style="text-align: end;">
                        <button mat-raised-button (click)="return()" style="min-width: 110px !important;"
                            color="primary" class="enviar button-login" [class.offline]=!internetC.internet fxFlexFill>
                            <mat-icon>cancel_presentation</mat-icon>
                            {{idiomas.Rechazar}}
                        </button>
                    </div>
                </div>
                <div *ngIf="deviceLinked" style="text-align: center;">
                    <img src="assets/resources/mipmap-hdpi/felicitacion.png" class="imagen3" />
                    <p>{{idiomas.MsgDispositivoVinculado}}</p>
                </div>
            </div>
            <div *ngIf="unlinkDevice">
                <div *ngIf="!deviceUnLinked" class="container container-login" fxLayout="column"
                    fxLayoutAlign="start stretch">
                    <div class="camposLogIn">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.Usr}}</mat-label>
                            <input id="userID" matInput type="text" autocomplete="off"
                                [formControl]="usernameFormControl1" [readonly]="desnext && internetC.internet"
                                oninput="this.value = this.value.toUpperCase();">
                            <mat-error *ngIf="usernameFormControl1.hasError('required')">
                                <strong>{{idiomas.ValidarUsuario}}</strong>
                            </mat-error>
                            <mat-error *ngIf="usernameFormControl1.hasError('pattern')">
                                <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="min-width: 235px" fxFlexFill
                            (keyup.enter)="unregisterDevice()">
                            <mat-label>{{idiomas.Pssw}}</mat-label>
                            <input id="userPwdID" matInput [type]="hide[0] ? 'password' : 'text'" autocomplete="off"
                                [formControl]="passwordFormControl1">
                            <div matSuffix (click)="hide[0] = !hide[0]" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide[0]"
                                style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                                <mat-icon color="primary">{{hide[0] ? 'visibility_off' :
                                    'visibility'}}
                                </mat-icon>
                            </div>
                            <mat-error *ngIf="passwordFormControl1.hasError('required')">
                                <strong>{{idiomas.msjContrasenaRequerida}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="error && internetC.internet">
                        <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
                        <br>
                    </div>
                    <div class="form-row">
                        <div class="right col" *ngIf="internetC.internet">
                            <button mat-raised-button (click)="unregisterDevice()" color="primary"
                                class="enviar button-login" style="margin: 0px !important"
                                [disabled]="usernameFormControl1.invalid || passwordFormControl1.invalid"
                                [class.offline]=!internetC.internet fxFlexFill>
                                <mat-icon>send</mat-icon>
                                {{idiomas.Enviar}}
                            </button>
                        </div>
                        <div class="center textoffline col" *ngIf="!internetC.internet"
                            style="padding-top: 8px !important">
                            offline
                        </div>
                    </div>
                </div>
                <div *ngIf="deviceUnLinked" class="container container-login" fxLayout="column"
                    fxLayoutAlign="start stretch">
                    <div style="text-align: center;">
                        <p>{{idiomas.DispositivoDesvinculado}}</p>
                    </div>
                    <div class="camposLogIn" style="text-align: center;">
                        <img src="assets/resources/mipmap-hdpi/felicitacion.png" class="imagen3" />
                    </div>
                </div>
            </div>
            <div *ngIf="!desnext && !desnewUser && !desnewPassword && !selectImageForm && !otpEnterForm && !newPassFormFirst && !newPassForm && !unlockUserForm && !scanQr && !deviceLinkedForm && !unlinkDevice && !openQR"
                style="padding-top: 5px;">
                <a class="form-row returnContainer" (click)="return()" style="max-width: 85px;">
                    <mat-icon class="returnArrow col">arrow_back_ios
                    </mat-icon>
                    <div class="returnLink col">{{idiomas.Regresar}}</div>
                </a>
            </div>
            <div *ngIf="desnext || desnewUser || desnewPassword || selectImageForm || otpEnterForm || newPassFormFirst || newPassForm || unlockUserForm || scanQr || deviceLinkedForm || unlinkDevice || openQR"
                style="padding-top: 5px;">
                <a class="form-row returnContainer" (click)="return()" style="max-width: 85px;">
                    <mat-icon class="returnArrow col">arrow_back_ios
                    </mat-icon>
                    <div class="returnLink col">{{idiomas.Regresar}}</div>
                </a>
            </div>
        </div>
    </div>
    <!-- Powered by FITBANK -->
    <div *ngIf="!spinnerDevice" class="row bottomBanner" style="position: fixed; bottom: 0;">
        <span *ngIf="logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#E3E2E2;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
            by&nbsp;</span>
        <span *ngIf="!logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#7C7E7E;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
            by&nbsp;</span>
        <span
            style="font-size:13px;line-height:15px;color:#0082cb;vertical-align:middle;font-weight: bold;"><strong></strong>
            FIT</span>
        <span
            style="font-size:13px;line-height:15px;color:#f7941d;vertical-align:middle;font-weight: bold;"><strong></strong>
            BANK&nbsp;</span>
        <span *ngIf="logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#E3E2E2;vertical-align:middle;font-weight: bold;"><strong></strong>
            &reg;</span>
        <span *ngIf="!logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#7C7E7E;vertical-align:middle;font-weight: bold;"><strong></strong>
            &reg;</span>
    </div>
</div>