<div class="example-form formBottom" fxLayout="column" fxLayoutAlign="start stretch" id="tableDiv"
  style="padding-bottom: 60px;">
  <mat-card class="cards-transations" *ngIf="!bandMobile">
    <div class="col card-header">
      <div id="lastelement1" class="center texto">
        <h6>
          <strong>{{idiomas.MontosMaximos | uppercase}}</strong>
        </h6>
      </div>
    </div>
    <div *ngIf=intento style="padding-top: 16px; padding-bottom: 16px; padding-left: 16px; padding-right: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf='!intento' autocomplete="off" class="form-row" style="padding: 16px;">
      <div autocomplete="off" class="col">
        <div id="carMonto" class="cardmon">
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha" style="margin-right: 4px;">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tablaMontos','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container1Aux" (scroll)="scroll4('tablaMontos')">
              <table mat-table [dataSource]="datos2" class="tablaMontos" id="tabla" style='width: 100%' fxFlexFill>
                <ng-container matColumnDef="Transacción">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Transaccion}}</th>
                  <td mat-cell *matCellDef="let dato">{{dato.subsistema_descripcion}}</td>
                </ng-container>
                <ng-container matColumnDef="Cupo">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Cupo}}</th>
                  <td mat-cell *matCellDef="let dato" class="moneda"> {{dato.frecuencia_descripcion}} </td>
                </ng-container>
                <ng-container matColumnDef="Moneda">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Moneda}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.cmoneda }}</td>
                </ng-container>
                <ng-container matColumnDef="Cupo Máximo">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.MontoMaximo}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.montomaximo_sistema | cantidadMonetaria}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Cupo Autorizado">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.MontoAutorizado}}</th>
                  <td mat-cell *matCellDef="let dato; let i = index" class="numeros">
                    <mat-form-field appearance="outline" class="tableInput" style="max-width: 95px; ; max-height: 50px"
                      [formGroup]="monto_aut">
                      <input matInput type="text" autocomplete="off" [formControlName]=i
                        (blur)="functionFormat($event.target.value,i)"
                        [value]="this.validators.FormatNumber(dato.montomaximo_user)"
                        (input)="dato.cupoAut=$event.target.value" (focusout)="functionAux(dato.cupoAut,i)"
                        (keyup)="VerificarMonto(dato.cupoAut,i)" [readonly]='!internet.internet'>
                    </mat-form-field>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; let even = even; let last = last; columns: displayedColumns;"
                  [ngClass]="{gray: even}">
                  {{showHideRows4(last)}}</tr>
              </table>
            </div>
            <div class="col flecha" style="margin-left: 4px;">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tablaMontos','right')">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
        <div autocomplete="off" class="form-row" (keyup.enter)="actualizarMontos()">

          <mat-form-field class="col-2 token" *ngIf="intentop && internet.internet" appearance="outline">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input id="soliToken" matInput type="text" onkeydown="this.setAttribute('type','password')"
              autocomplete="off" [formControl]="token" maxlength="6" inputmode="numeric" appBlockCopyPaste>
            <mat-error *ngIf="token.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="token.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumeros}}</strong>
            </mat-error>
            <mat-error *ngIf="token.hasError('minlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
            <mat-error *ngIf="token.hasError('maxlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2 token" *ngIf="!intentop && internet.internet" appearance="outline">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
          </mat-form-field>
          <div class="left col-3 token-button">
            <button mat-raised-button *ngIf="internet.internet" color="primary" (click)="SolicitarToken()"
              class="buttonToken">
              <mat-icon>security</mat-icon>
              {{idiomas.SolicitarToken}}
            </button>
          </div>
          <div *ngIf="mostrarCron" class="col">
            <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
              [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
          </div>
        </div>
        <div *ngIf=intento2>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="form-row">
          <div class="col-6">
            <button mat-raised-button *ngIf=token.valid color="primary" (click)="actualizarMontos()"
              class="buttonSubmit" [disabled]="disableButton">{{idiomas.ActualizarMontos}}</button>
          </div>
          <div align="end" style="text-align: end;" class="col-6">
            <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
              <mat-icon> clear_all</mat-icon>
              {{idiomas.Limpiar}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
  <div *ngIf="bandMobile">
    <div class="col card-header">
      <div id="lastelement1" class="center texto">
        <h6>
          <strong>{{idiomas.MontosMaximos | uppercase}}</strong>
        </h6>
      </div>
    </div>
    <div *ngIf=intento style="padding-top: 16px; padding-bottom: 16px; padding-left: 16px; padding-right: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf='!intento' autocomplete="off" class="form-row" style="padding: 16px;">
      <div autocomplete="off" class="col">
        <div class="col-12 container-card-mobile" [class.heightconteiner2]="!(datos2.length>numMaxRow)">
          <mat-list>
            <mat-divider></mat-divider>
            <mat-list-item *ngFor="let dato of datos2; let even = even;let i = index">
              <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                <div class="col-8">
                  <div mat-line style="font-weight: bold; font-size: .9em;">{{dato.montomaximo_sistema |
                    cantidadMonetaria}}</div>
                  <div mat-line>
                    <div style="font-size: .8em; white-space: initial;">{{dato.frecuencia_descripcion | uppercase}}
                    </div>
                  </div>
                </div>
                <div class="col-4" style="text-align: end;">
                  <div class="colorCartas" style="font-size: .9em;">
                    <mat-form-field appearance="outline" class="tableInput"
                      style="max-width: 95px; ; max-height: 50px; font-size: inherit !important"
                      [formGroup]="monto_aut">
                      <input matInput type="text" autocomplete="off" [formControlName]=i class="right"
                        (blur)="functionFormat($event.target.value,i)"
                        [value]="this.validators.FormatNumber(dato.montomaximo_user)"
                        (input)="dato.cupoAut=$event.target.value" (focusout)="functionAux(dato.cupoAut,i)"
                        (keyup)="VerificarMonto(dato.cupoAut,i)" [readonly]='!internet.internet'>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
        </div>
        <div autocomplete="off" class="form-row" (keyup.enter)="actualizarMontos()">
          <div class="left col-6 token-button" style="padding-bottom: 10px !important;">
            <button mat-raised-button *ngIf="internet.internet" color="primary" (click)="SolicitarToken()"
              class="buttonToken">
              <mat-icon>security</mat-icon>
              {{idiomas.SolicitarToken}}
            </button>
          </div>
          <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
            <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
              [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
          </div>
          <mat-form-field class="col-2 token" *ngIf="intentop && internet.internet" appearance="outline">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input id="soliToken" matInput type="text" onkeydown="this.setAttribute('type','password')"
              autocomplete="off" [formControl]="token" maxlength="6" inputmode="numeric" appBlockCopyPaste>
            <mat-error *ngIf="token.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="token.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumeros}}</strong>
            </mat-error>
            <mat-error *ngIf="token.hasError('minlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
            <mat-error *ngIf="token.hasError('maxlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2 token" *ngIf="!intentop && internet.internet" appearance="outline">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
          </mat-form-field>
        </div>
        <div *ngIf=intento2>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="form-row">
          <div class="col-6">
            <button mat-raised-button *ngIf=token.valid color="primary" (click)="actualizarMontos()"
              class="buttonSubmit" [disabled]="disableButton">{{idiomas.ActualizarMontos}}</button>
          </div>
          <div align="end" style="text-align: end;" class="col-6">
            <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
              <mat-icon> clear_all</mat-icon>
              {{idiomas.Limpiar}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>