<div class="textura">
    <div *ngIf="!intentoMessage">
        <div style="text-align: right;">
            <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <h2 mat-dialog-title style="text-align: center;"><strong>{{idiomas.DetalleTransferencia}}</strong></h2>
        <mat-dialog-content>
            <div *ngIf=intento>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar><br>
            </div>
            <div *ngIf="showPDF">
                <mat-card-content>
                    <div>
                        <mat-card class="cardTitle">
                            <div class="row">
                                <div class="col-4"><b>{{idiomas.Fecha}}/{{idiomas.Hora}} <p>{{dato.FREAL |
                                            formatoFecha:idiomas.IdiomaSeleccionado}}
                                            {{dato.FREAL |
                                            horaSubString1}}</p></b>
                                </div>
                                <div class="col-4">
                                    <b>{{idiomas.CDebito}}</b>
                                    <p>{{dato.CCUENTA}}</p>
                                </div>
                                <div class="col-4">
                                    <b>{{idiomas.Monto}}</b>
                                    <p>$ {{dato.VALOR | cantidadMonetaria}}</p>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <div style="padding-top: 10px !important;">
                        <mat-card class="cardBody">
                            <div class="row">
                                <div class="col-6">
                                    <b>{{idiomas.Empresa}}</b>
                                    <p> {{dato.DESCRIPCION}}</p>
                                </div>
                                <div class="col-6">
                                    <b>{{idiomas.Suministro}}</b>
                                    <p>{{dato.REFERENCIA}}</p>
                                </div>
                                <div class="col-6">
                                    <b>{{idiomas.Descripcion}}</b>
                                    <p>{{dato.DETALLE}}</p>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </mat-card-content>
                <div class="row" *ngIf="intentoMessage">
                    <div class="container" style="text-align-last: center;">
                        <mat-label>{{messageError}}</mat-label>
                    </div>
                </div>
            </div>
            <div *ngIf="!showPDF && bandIos">
                <ngx-extended-pdf-viewer #pdfViewer [base64Src]="base64URL" backgroundColor="#ffffff" [handTool]=false
                    [height]="'70%'" [useBrowserLocale]="true" [textLayer]="false" [customToolbar]="multiToolbar">
                </ngx-extended-pdf-viewer>
                <ng-template #multiToolbar>
                    <div style="text-align: center;">
                        <button mat-icon-button id="zoomIn">
                            <mat-icon>zoom_in</mat-icon>
                        </button>
                        <button mat-icon-button id="zoomOut">
                            <mat-icon>zoom_out</mat-icon>
                        </button>
                        <button mat-icon-button id="download">
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                    </div>
                </ng-template>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions>
            <div *ngIf="!bandIos" style="text-align: center !important; width: 100%;">
                <button mat-raised-button *ngIf="dato.NUMEROMENSAJE" (click)="sharePDF()" color="primary"
                    class="buttonToken">
                    <mat-icon>share</mat-icon>
                    {{idiomas.CompartirComprobante | uppercase}}
                </button>
                <button mat-raised-button *ngIf="dato.NUMEROMENSAJE" (click)="reportePDF()" color="primary"
                    class="buttonToken">
                    <mat-icon>cloud_download</mat-icon>
                    {{idiomas.DescargarComprobante | uppercase}}
                </button>
            </div>
            <div *ngIf="showPDF && bandIos" style="text-align: center !important; width: 100%;">
                <button mat-raised-button *ngIf="dato.NUMEROMENSAJE" (click)="reportePDF()" color="primary"
                    class="buttonToken">
                    <mat-icon>cloud_download</mat-icon>
                    {{idiomas.VisualizarComprobante | uppercase}}
                </button>
            </div>
        </mat-dialog-actions>
    </div>
</div>