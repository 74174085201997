<div class="dialogoAcuerdoPrivacidad">
  <mat-dialog-content *ngIf="mostrar" style="height: 100%" class="dialogAgreementContent">
    <iframe *ngIf="!bandMobile" #pdfMostrar (load)="contractLoaded()"
      src="assets/resources/reporte/TERMINOS_CONDICIONES_AHP.pdf" user-scalable="yes" type="application/pdf"
      frameborder="0" width="100%" height="95%">
    </iframe>
    <ngx-extended-pdf-viewer *ngIf="bandMobile" #pdfViewer
      [src]="'/assets/resources/reporte/TERMINOS_CONDICIONES_AHP.pdf'" backgroundColor="#ffffff" [textLayer]="true"
      [handTool]=false [useBrowserLocale]="true" [zoom]="'65%'" [customToolbar]="additionalButtons">
    </ngx-extended-pdf-viewer>
    <ng-template #additionalButtons>
      <div style="text-align: center;">
        <button mat-icon-button id="zoomIn">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button id="zoomOut">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button id="download">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="mostrarPdfBase64" style="height: 100%;" class="dialogAgreementContent">
    <ngx-extended-pdf-viewer #pdfViewer [base64Src]="dato" backgroundColor="#ffffff" [handTool]=false
      [useBrowserLocale]="true" [textLayer]="false" [zoom]="'65%'" [customToolbar]="multiToolbar">
    </ngx-extended-pdf-viewer>
    <ng-template #multiToolbar>
      <div style="text-align: center;">
        <button mat-icon-button id="zoomIn">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button id="zoomOut">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button id="download">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </ng-template>
  </mat-dialog-content>
  <div style="width: 100% !important; text-align: right;">
    <button mat-raised-button class="buttonToken" (click)="accept()">
      <mat-icon>download_done</mat-icon>{{aceptar}}
    </button>
  </div>
</div>