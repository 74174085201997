<div class="example-form" style="padding-top: 2% !important; padding-bottom: 60px;" fxLayout="column"
    fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile && mostrarProximamente">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.pagRec | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/proximamente.png" class="imagen3" priority />
        </div>
    </mat-card>
    <div *ngIf="bandMobile && mostrarProximamente">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.pagRec | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/proximamente.png" class="imagen3" priority />
        </div>
    </div>
    <mat-card class="cards-transations" *ngIf="!bandMobile && !mostrarProximamente">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.pagRec | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;">
            <div autocomplete="off" class="form-row">
                <div class="col-6" [class.col-12]="bandMobile">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.Identificacion1}}</mat-label>
                        <input matInput autocomplete="off" type="text" [formControl]="identificacionForm" #input
                            (input)="input.value=$event.target.value.toUpperCase()" [readonly]="bandData"
                            name="identificacion" (keyup.enter)="consultarRecuadaciones()">
                        <mat-error *ngIf="identificacionForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('minlength')">
                            <strong>{{idiomas.msjMin}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('maxlength')">
                            <strong>{{idiomas.msjExedeTamano}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('digitoVerificador')">
                            <strong>{{idiomas.CedulaInv}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 155px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.NumeroOrden}}</mat-label>
                        <mat-select [formControl]="recaudacionForm" (selectionChange)="seleccionRecaudacion()">
                            <mat-option *ngFor="let lrecaudacion of lrecaudacion" [value]="lrecaudacion">
                                {{lrecaudacion['tgta0.NUMEROFORMULARIO']}} - {{lrecaudacion['tgta0.DETALLE']}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.NombreE}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="nombreEmpresa" name="nombreEmpresa">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.NombreD}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="nombreDeudor" name="nombreDeudor">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.Monto}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="monto" name="monto">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.Referencia}}</mat-label>
                        <input matInput type="text" readonly [formControl]="referencia" name="referencia">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 160px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                        <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()">
                            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                                <mat-divider style="border-top-width: 3px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="bandData && internet.internet">
                <div class="form-row">
                    <label class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12">{{idiomas.SegToken}}</label>
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="password" autocomplete="off"
                            [formControl]="tokenFormControl" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="tokenFormControl.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div class="left col-2 token-button">
                        <button
                            [disabled]="(!cuentaDesdeSelected.valid) || (!recaudacionForm.valid) || (!referencia.valid) || (enableCronoIn)"
                            mat-raised-button (click)="solicitarToken()" class="buttonToken" color="primary">
                            <mat-icon>security</mat-icon>{{idiomas.SolicitarToken}}</button>
                    </div>
                    <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf=intento style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div id="floatCont" class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <div class="col-6" *ngIf="!bandData && identificacionForm.valid">
                <button mat-raised-button (click)="consultarRecuadaciones()" title={{idiomas.Consultar}} color="primary"
                    class="buttonSubmit" [disabled]="disableButton">
                    <mat-icon>search</mat-icon>
                    {{idiomas.Consultar}}
                </button>
            </div>
            <div class="col-6">
                <button
                    *ngIf="bandData && identificacionForm.valid && recaudacionForm.valid && cuentaDesdeSelected.valid && tokenFormControl.valid"
                    mat-raised-button (click)="pagoRecaudacion()" title={{idiomas.Aceptar}} color="primary"
                    class="buttonSubmit" [disabled]="disableButton">
                    <mat-icon>download_done</mat-icon>
                    {{idiomas.Aceptar}}
                </button>
            </div>
            <div class="col-6" align="end" style="text-align: end;">
                <button mat-raised-button *ngIf="bandData && identificacionForm.valid" (click)="close()"
                    title={{idiomas.Limpiar}} color="warn" class="buttonClean">
                    <mat-icon> clear_all</mat-icon>
                    {{idiomas.Limpiar}}
                </button>
            </div>
        </div>
    </mat-card>
    <div *ngIf="bandMobile && !mostrarProximamente">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.pagRec | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;">
            <div autocomplete="off" class="form-row">
                <div class="col-6" [class.col-12]="bandMobile">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.Identificacion1}}</mat-label>
                        <input matInput autocomplete="off" type="text" [formControl]="identificacionForm" #input
                            (input)="input.value=$event.target.value.toUpperCase()" [readonly]="bandData"
                            name="identificacion" (keyup.enter)="consultarRecuadaciones()">
                        <mat-error *ngIf="identificacionForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('minlength')">
                            <strong>{{idiomas.msjMin}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('maxlength')">
                            <strong>{{idiomas.msjExedeTamano}}</strong>
                        </mat-error>
                        <mat-error *ngIf="identificacionForm.hasError('digitoVerificador')">
                            <strong>{{idiomas.CedulaInv}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 155px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.NumeroOrden}}</mat-label>
                        <mat-select [formControl]="recaudacionForm" (selectionChange)="seleccionRecaudacion()">
                            <mat-option *ngFor="let lrecaudacion of lrecaudacion" [value]="lrecaudacion">
                                {{lrecaudacion['tgta0.NUMEROFORMULARIO']}} - {{lrecaudacion['tgta0.DETALLE']}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.NombreE}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="nombreEmpresa" name="nombreEmpresa">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.NombreD}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="nombreDeudor" name="nombreDeudor">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.Monto}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="monto" name="monto">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.Referencia}}</mat-label>
                        <input matInput type="text" readonly [formControl]="referencia" name="referencia">
                    </mat-form-field>
                </div>

                <div *ngIf="bandData" class="col-6" [class.col-12]="bandMobile" style="min-width: 160px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                        <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()">
                            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                                <mat-divider style="border-top-width: 3px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="bandData && internet.internet">
                <div class="form-row">
                    <div class="left col-6 token-button" style="padding-bottom: 10px !important;">
                        <button style="margin-top: 0 !important;"
                            [disabled]="(!cuentaDesdeSelected.valid) || (!recaudacionForm.valid) || (!referencia.valid) || (enableCronoIn)"
                            mat-raised-button (click)="solicitarToken()" class="buttonToken" color="primary">
                            <mat-icon>security</mat-icon>{{idiomas.SolicitarToken}}</button>
                    </div>
                    <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <mat-form-field class="col-2 token" [class.col-12]="bandMobile" *ngIf="tokenSolicitado"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="password" autocomplete="off"
                            [formControl]="tokenFormControl" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="tokenFormControl.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" [class.col-12]="bandMobile" *ngIf="!tokenSolicitado"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf=intento style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div id="floatCont" class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <div class="col-6" *ngIf="!bandData && identificacionForm.valid">
                <button mat-raised-button (click)="consultarRecuadaciones()" title={{idiomas.Consultar}} color="primary"
                    class="buttonSubmit" [disabled]="disableButton">
                    <mat-icon>search</mat-icon>
                    {{idiomas.Consultar}}
                </button>
            </div>
            <div class="col-6">
                <button
                    *ngIf="bandData && identificacionForm.valid && recaudacionForm.valid && cuentaDesdeSelected.valid && tokenFormControl.valid"
                    mat-raised-button (click)="pagoRecaudacion()" title={{idiomas.Aceptar}} color="primary"
                    class="buttonSubmit" [disabled]="disableButton">
                    <mat-icon>download_done</mat-icon>
                    {{idiomas.Aceptar}}
                </button>
            </div>
            <div class="col-6" align="end" style="text-align: end;">
                <button mat-raised-button *ngIf="bandData && identificacionForm.valid" (click)="close()"
                    title={{idiomas.Limpiar}} color="warn" class="buttonClean">
                    <mat-icon> clear_all</mat-icon>
                    {{idiomas.Limpiar}}
                </button>
            </div>
        </div>
    </div>
</div>