<div class="textura">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" id="btnCloseWaitingDialog" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <h3 mat-dialog-title style="text-align: center;">{{idiomas.EsperePorfavor}}</h3>

    <mat-dialog-content>
        <div style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/doubleRrippleGif.gif" class="rippleWaitingImage" />
            <hr>
            <a>{{mensaje}}</a>
        </div>
    </mat-dialog-content>
</div>