import { Component, Input, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { internetComponent } from '../funciones/internet';
import { Router } from '@angular/router';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GenerarCartasComponent } from '../generar-cartas/generar-cartas.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatProgressBarModule, MatButtonModule, CommonModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, GenerarCartasComponent],
  selector: 'app-form-posicion-consolidada',
  templateUrl: './form-posicion-consolidada.component.html',
  styleUrls: ['./form-posicion-consolidada.component.css']
})
export class FormPOSICIONCONSOLIDADAComponent implements OnInit {

  @ViewChild('generarCartas', { static: false }) generarCartas;
  @Input() idiomas: any;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() skinSeleccionadoBand: boolean;
  @Output() operationEventHome: EventEmitter<any> = new EventEmitter<any>();

  nombre: string;
  datos: any;
  datos2: any;
  idioma: any;
  datosGrafica: any[];
  ctasocio: any[];
  ahorros: any[];
  inversiones: any[];
  prestamos: any[];
  prestamosA: any[];
  prestamosAA: any[];
  intento: boolean;
  deshabilitar: boolean;
  offlineData: boolean;
  bandCheck: boolean;
  bandDoCheck: boolean;
  bandMobile: boolean = false;
  numero: string;
  prestprea: any[];
  servicios: any[];
  gruposServ: any[];
  lbancos: any[];
  lbancosTc: any[];
  lmonedas: any[];
  direcciones: any[];
  cuentas: any[];
  coloresGrafica= ['#fdc800','#0069b2','#e5ae32'];
  hasProducts: any = [
    { cuentaSocio: false, cont: 0 },
    { ahorros: false, cont: 0 },
    { inversiones: false, cont: 0 },
    { prestamos: false, cont: 0 },
    { prestamosA: false, cont: 0 }
  ]

  constructor(public apiService: ApiService,
    private servicio: Servicios,
    public alerta: Alerta,
    private router: Router,
    public basedatos: BaseDatos, public internet: internetComponent, private validators: FormControlValidators) {
    this.intento = false
    this.deshabilitar = false
    this.bandCheck = true;
    this.offlineData = true;
    this.bandDoCheck = false;
    this.prestprea = [];
    this.servicios = [];
    this.lbancos = [];
    this.lbancosTc = [];
    this.lmonedas = [];
    this.gruposServ = [];
    this.direcciones = [];
    this.cuentas = [];
    this.ctasocio = [];
    this.ahorros = [];
    this.inversiones = [];
    this.prestamos = [];
    this.prestamosA = [];
    this.prestamosAA = [];
    this.datosGrafica = [];
    this.idioma = JSON.parse(localStorage.getItem('idioma'))
  }

  idForm = this.validators.idForm();

  ngDoCheck() {
    if (this.bandDoCheck) {
      try {
        document.getElementById("floatContPosiConso").style.right = '0%';
        this.bandDoCheck = false;
      } catch (error) { }
    }
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    this.idioma = JSON.parse(localStorage.getItem('idioma'))
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.intento = true);
    Promise.resolve(null).then(() => this.posicionConsolidada());
    this.idioma = JSON.parse(localStorage.getItem('idioma'))
  }

  consultasListadeValores() {
    var serviciosban = this.basedatos.retornarConsulta('ServiciosDisponibles')
    var bancosban = this.basedatos.retornarConsulta('lbancos')
    var bancosbanTc = this.basedatos.retornarConsulta('lbancosTc')
    var monedasban = this.basedatos.retornarConsulta('lmoneda')
    var gruposservicios = this.basedatos.retornarConsulta('GruposServicios')
    if (serviciosban == null && bancosban == null && bancosbanTc == null && monedasban == null && gruposservicios == null) {
      this.retornarBancos();
      this.retornarBancosTC();
      this.retornarServicios();
      this.retornarMonedas();
      this.retornarGrupoServicios();
    }
    this.obtainDirections()
  }

  consultarUsuarioHB() {
    let envio = {
      "transaccion": "010003-con-usuario.xml",
      "cri^tmpu0^CUSUARIO^NORMAL^tmpu0^=": "$userCode",
      "cri^tcel0^CPERSONA^NORMAL^tcel0^=": "$personCode",
      "fullNames": "true"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      const fecha = new Date(data.tmpu0[0]['tmpu0.FDESDE']);
      //const fechaActual = new Date("2024-04-16");
      const fechaActual = new Date();
      if (fecha < fechaActual && data.tcel0.length < 1) {
        this.reporteInformacion()
      } else {
        this.posicionConsolidada()
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
      }
    });

  }

  reporteInformacion() {
    let pdf
    var envio = {
      "nombre": "18_CONTRATOAMPLIACIONUSO",
      "tipo": "PDF",
      "rep^IDENTIFICACION": "$personId",
      "rep^NOMBRE": "$personName",
    }
    this.apiService.postProvider2InternetCheck("/reporte", this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        pdf = data.reporte
        this.alerta.generarDialogoAmpliacionTerminos(this.idiomas, pdf).then((data: any) => {
          if (data) {
            this.posicionConsolidada()
          }
        })
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }


  posicionConsolidada() {
    this.offlineData = true;
    this.deshabilitar = true;
    let envio = {}
    this.apiService.postProvider2InternetCheck('/cuentasconsolidadas', this.basedatos.id_token, envio).then((data: any) => {
      this.nombre = data.clientName
      this.datos = data.array
      if (data.prestamos.length > 0) {
        this.prestamosA = this.groupByPreA(data.prestamos)
        this.prestamosAA = this.groupByPreAA(data.prestamos)
      }
      if (data.array != null) {
        this.ahorros = this.groupByAho(data.array)
        this.inversiones = this.groupByPla(data.array)
        this.prestamos = this.groupByPre(data.array)
        this.datos2 = this.groupBy2(data.array, function (item) {
          return [item.subsistema];   //Para reordenar JSON response de Servicio para posicion consolidada - agrupado por [items.keys]
        });

        if (this.ahorros.length > 0) {
          this.hasProducts[0].ahorros = true
          this.hasProducts[0].cont = this.ahorros.length
        }

        if (this.inversiones.length > 0) {
          this.hasProducts[1].inversiones = true
          this.hasProducts[1].cont = this.inversiones.length
        }

        if (this.prestamos.length > 0) {
          this.hasProducts[2].prestamos = true
          this.hasProducts[2].cont = this.prestamos.length
        }

        if (this.prestamosA.length > 0) {
          this.hasProducts[3].prestamosA = true
          this.hasProducts[3].cont = this.prestamosA.length
        }

        this.datosGrafica.push(this.ahorros)
        this.datosGrafica.push(this.inversiones)
        this.datosGrafica.push(this.prestamos)

        Promise.resolve(null).then(() => this.intento = false);
        this.intento = false;
        let consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.datos2,
        }
        this.basedatos.insertarPosicionConsolidada(consulta);

        if (this.internet.internet) {
          let fechaPreaprobados = {
            'fecha': new Date().toLocaleDateString(),
            'hora': new Date().toLocaleTimeString()
          }
          this.basedatos.insertarConsulta('prestPreapob', fechaPreaprobados);
        }
      }
      this.bandCheck = false;
      //this.consultasListadeValores();//Consulta LItas de Valores Beneficiarios y Servicios
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intento = false;
        this.datos = null;
        this.nombre = null;
        this.alerta.presentarAlerta(this.idiomas.AlertaID.toUpperCase());
        this.deshabilitar = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.intento = false;
        this.posicionConsolidadaOffline();
      }
    })
  }

  posicionConsolidadaOffline() {
    var objClientOffline = this.basedatos.darPosicionConsolidada();
    //if (objClientOffline) {
    this.intento = false;
    //this.nombre = objClientOffline.clientName;
    this.datos2 = objClientOffline.data;
    this.alerta.generarOfflineDialogo(objClientOffline, this.idiomas)
    //this.idForm.setValue(objClientOffline.cedula);
    this.bandCheck = false;
    /*}
    else { this.offlineData = false; }
    if (!this.offlineData) {
      //Almacena busqueda en tarear pendientes
      this.basedatos.insertarTarea(this.generarJson())
      this.alerta.presentarAlerta(this.idiomas.NoConectado)
      //.
      this.datos = null
      this.datos2 = null
      this.nombre = null
      this.idForm = new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]*$')]) //Nuevo FormControl
      this.deshabilitar = false;
      this.bandCheck = true;
      this.intento = false;
      document.getElementById("floatContPosiConso").style.width = '100%';
    }*/
  }

  generarJson() {
    return ({ 'id': this.idForm.value, 'usuario': this.basedatos.darUsuario(), "tipoPosicionConsolidada": "tipoPosicionConsolidada" })
  }

  verificarNumero(data: any) {
    for (var i in data) {
      if (data[i].subsistema == '04') {
        var cadena = JSON.stringify(data[i]);
        let arreglosubcadena = cadena.split(',');
        for (var j = 0; j < arreglosubcadena.length; j++) {
          let subcadena = arreglosubcadena[j].split(':');
          var subcadena1 = subcadena[1].replace(/['"}]+/g, '');
          if (!isNaN(Number.parseFloat(subcadena1))) {
          }
        }
      }
    }
  }

  close() {
    this.alerta.generarDialogoSeguro(this.idiomas).then((data) => {
      if (data) {
        this.datos = null
        this.datos2 = null
        this.nombre = null
        this.idForm = new UntypedFormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]*$')]) //Nuevo FormControl
        this.deshabilitar = false;
        this.bandCheck = true;
        this.intento = false;
        document.getElementById("floatContPosiConso").style.width = '100%';
      }
    });
  }

  refreshPosCons() {
    this.hasProducts = [
      { cuentaSocio: false, cont: 0 },
      { ahorros: false, cont: 0 },
      { inversiones: false, cont: 0 },
      { prestamos: false, cont: 0 },
      { prestamosA: false, cont: 0 }
    ]
    this.datos = null
    this.datos2 = null
    this.nombre = null
    this.prestamosA = []
    this.prestamosAA = []
    this.datosGrafica = []
    this.deshabilitar = false;
    this.bandCheck = true;
    this.intento = true;
    this.posicionConsolidada();
    document.querySelector('.mat-sidenav-content').scrollTop = 0;;
  }

  posConsTarea(id) {
    this.returnOfTrans();
    //Limpia el formulario
    this.datos = null
    this.datos2 = null
    this.nombre = null
    this.idForm = new UntypedFormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]*$')]) //Nuevo FormControl
    this.deshabilitar = false;
    this.bandCheck = true;
    this.intento = false;
    this.idForm.setValue(id);
    Promise.resolve(null).then(() => this.posicionConsolidada());
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  returnOfTrans() {
    //Para mostrar posicion consolidada si se encuentra en transacciones cuando se envia desde tareas pendientes
    try {
      if (this.generarCartas.deshabilitar) {
        this.bandDoCheck = true;
        this.generarCartas.retornar();
      }
    } catch (error) { }
    this.refreshPosCons()
  }

  groupBy2(arrayNF, f) {
    var array = arrayNF;
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  groupByCta(arrayNF) {
    var array = arrayNF;
    let idioma = this.idioma
    var accounts = []
    array.forEach(function (o) {
      o.dproducto = idioma.NombresCuenta.find(item => item.descripcion == o.dproducto).viewValue;
      if (o.cproducto == "406" || o.cproducto == '03') {
        accounts.push(o)
      }
    })
    accounts.sort(function (x, y) {
      return ((x.cproducto == y.cproducto) ? 0 : ((x.cproducto > y.cproducto) ? -1 : 1));
    })
    return accounts;
  }

  groupByAho(arrayNF) {
    var array = arrayNF;
    let idioma = this.idioma
    var accounts = []
    array.forEach(function (o) {
      if (o.subsistema == "04" && o.cproducto == '406') {
        o.dproducto = idioma.NombresCuenta.find(item => item.descripcion == o.dproducto).viewValue;
        accounts.push(o)
      }
    })
    array.forEach(function (o) {
      if (o.subsistema == "04" && o.cproducto != '406') {
        o.dproducto = idioma.NombresCuenta.find(item => item.descripcion == o.dproducto).viewValue;
        accounts.push(o)
      }
    })
    return accounts;
  }

  groupByPla(arrayNF) {
    var array = arrayNF;
    let idioma = this.idioma
    var accounts = []
    array.forEach(function (o) {
      if (o.subsistema == "05") {
        o.dproducto = idioma.NombresCuenta.find(item => item.descripcion == o.dproducto).viewValue;
        accounts.push(o)
      }
    })
    return accounts;
  }

  groupByPre(arrayNF) {
    var array = arrayNF;
    let idioma = this.idioma
    var accounts = []
    array.forEach(function (o) {
      if (o.subsistema == "06") {
        o.dproducto = idioma.NombresCuenta.find(item => item.descripcion == o.dproducto).viewValue;
        accounts.push(o)
      }
    })
    return accounts;
  }

  groupByPreA(arrayNF) {
    var array = arrayNF;
    let idioma = this.idioma
    var accounts = []
    array.forEach(function (o) {
      if (o.subsistema == "PRESTAMOPREAPROBADO") {
        o.cuenta = idioma.NombresCuenta.find(item => item.descripcion == o.cuenta).viewValue;
        accounts.push(o)
      }
    })
    return accounts;
  }

  groupByPreAA(arrayNF) {
    var array = arrayNF;
    var accounts = []
    array.forEach(function (o) {
      if (o.subsistema == "PRESTAMOPREAPROBADO" && o.cproducto == '06') {
        accounts.push(o)
      }
    })
    return accounts;
  }

  retornarServicios() { //Servicios Disponibles para Registrar
    let response = {
      id: '',
      descripcion: ''
    }
    let enviobancos = {
      transaccion: '010003-lv-servicios.xml',
      alias: 'ts0'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviobancos).then((data: any) => {
      if (data) {
        for (let servicio of data) {
          response.id = servicio.CSERVICIO
          response.descripcion = servicio.DESCRIPCION
          this.servicios.push(response)
          response = {
            id: '',
            descripcion: ''
          }
        }
        this.basedatos.insertarConsulta('ServiciosDisponibles', this.servicios); //Inserta Lista de Servicios Disponibles
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  retornarBancos() {
    let auxi: Ibancos = {
      id: '',
      nombre: '',
      efi: ''
    }
    let enviobancos = {
      transaccion: '010003-lv-bancos-beneficiario.xml',
      alias: 'vpif0'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviobancos).then((data: any) => {
      for (const banco of data) {
        auxi.id = banco.CPERSONA
        auxi.nombre = banco.NOMBRELEGAL
        auxi.efi = banco.CUENTABANCOINSTITUCION
        this.lbancos.push(auxi)
        auxi = {
          id: '',
          nombre: '',
          efi: ''
        }
      }
      this.lbancos = this.lbancos.sort((a, b) => {
        var a1 = a.nombre;
        var b1 = b.nombre;
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      })
      this.basedatos.insertarConsulta('lbancos', this.lbancos)
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  retornarBancosTC() {
    let auxi: Ibancos = {
      id: '',
      nombre: '',
      efi: ''
    }
    let enviobancos = {
      transaccion: '010003-lv-bancos-beneficiario.xml',
      alias: 'vpif0',
      'cri^tjib0^CPERSONA^NORMAL': '2'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviobancos).then((data: any) => {
      for (const banco of data) {
        auxi.id = banco.CPERSONA
        auxi.nombre = banco.NOMBRELEGAL
        auxi.efi = banco.CUENTABANCOINSTITUCION
        this.lbancosTc.push(auxi)
        auxi = {
          id: '',
          nombre: '',
          efi: ''
        }
      }
      this.lbancosTc = this.lbancosTc.sort((a, b) => {
        var a1 = a.nombre;
        var b1 = b.nombre;
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      })
      this.basedatos.insertarConsulta('lbancosTc', this.lbancosTc)
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  retornarMonedas() {
    let auxi: IMonedas = {
      cmoneda: '',
      descripcion: ''
    }
    let enviomonedas = {
      transaccion: '010003-lv-monedas-beneficiario.xml',
      alias: 'tm0'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviomonedas).then((data: any) => {
      for (const moneda of data) {
        auxi.cmoneda = moneda.CMONEDA
        auxi.descripcion = moneda.DESCRIPCION
        this.lmonedas.push(auxi)
        auxi = {
          cmoneda: '',
          descripcion: ''
        }
      }
      this.basedatos.insertarConsulta('lmoneda', this.lmonedas)
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  retornarGrupoServicios() { //Retornar Servicios por Grupo
    let response = {
      NOMBRESCONTRATO: ''
    }
    let envio = {
      transaccion: "010003-lv-tipos-servicio-in.xml",
      alias: "tpsc0"
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        for (let servicio of data) {
          response.NOMBRESCONTRATO = servicio.NOMBRESCONTRATO
          this.gruposServ.push(response)
          response = {
            NOMBRESCONTRATO: ''
          }
        }
        this.basedatos.insertarConsulta('GruposServicios', this.gruposServ); //Inserta Lista de Servicios Disponibles
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  obtainDirections() {
    const envio = {
      transaccion: '010003-lv-direcciones.xml',
      alias: 'tdir0',
      'cri^tdir0^CPERSONA^NORMAL': '$personCode'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      this.direcciones = this.transformadorDirecciones(data);
      const consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.direcciones
      };
      this.basedatos.insertarConsulta('DireccionesCompra', consulta);
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet  
      }
    });
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].CCUENTA, 'viewValue':
          value[i].CCUENTA, 'descProducto': value[i].DESCRIPCION, moneda: value[i].CMONEDA,
        tipoidentificacion: value[i].CTIPOIDENTIFICACION
      });
    }
    return lista;
  }

  transformadorDirecciones(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'id': value[i].NUMERODIRECCION, 'descripcion': value[i].DIRECCION
      });
    }
    return lista;
  }

  pressEnter() {
    if (this.idForm.valid && this.bandCheck) {
      this.posicionConsolidada();
    }
  }

  operationEvent(event) {
    this.operationEventHome.emit(event)
  }
}

interface Ibancos {
  id: string,
  nombre: string,
  efi: string
}

interface IMonedas {
  cmoneda: string,
  descripcion: string,
}