<div class="textura">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <h2 *ngIf="intentoTransferPagoServ" mat-dialog-title style="text-align: center;">
        <strong>{{idiomas.TituloTransferExitosa}}</strong>
    </h2>
    <h2 *ngIf="!intentoTransferPagoServ" mat-dialog-title style="text-align: center;">
        <strong>{{idiomas.Confirmacion}}</strong>
    </h2>
    <div *ngIf=intento>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar><br>
    </div>
    <div style="text-align: center;">
        <a *ngIf="intentoTransferPagoServ" style="font-size: 14px !important;">{{idiomas.MensajeTransferExitosa}}</a>
        <a *ngIf="!intentoTransferPagoServ" style="font-size: 14px !important;">{{idiomas.MensajePagoRecarExitosa}}</a>
    </div>
    <mat-dialog-content>
        <div *ngIf="showPDF" style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/felicitacion.png" class="imagen3" />
        </div>
        <div *ngIf="!showPDF && bandIos">
            <ngx-extended-pdf-viewer #pdfViewer [base64Src]="base64URL" backgroundColor="#ffffff" [handTool]=false
                [height]="'70%'" [useBrowserLocale]="true" [textLayer]="false"
                [customToolbar]="multiToolbar" [filenameForDownload]="filenameForDownload">
            </ngx-extended-pdf-viewer>
            <ng-template #multiToolbar>
                <div style="text-align: center;">
                    <button mat-icon-button id="zoomIn">
                        <mat-icon>zoom_in</mat-icon>
                    </button>
                    <button mat-icon-button id="zoomOut">
                        <mat-icon>zoom_out</mat-icon>
                    </button>
                    <button mat-icon-button id="download">
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                </div>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div *ngIf="!bandIos" style="text-align: center !important; width: 100%;">
            <button mat-raised-button (click)="sharePDF()" color="primary" class="buttonToken">
                <mat-icon>share</mat-icon>
                {{idiomas.CompartirComprobante | uppercase}}
            </button>
            <button mat-raised-button (click)="reportePDF()" color="primary" class="buttonToken">
                <mat-icon>cloud_download</mat-icon>
                {{idiomas.DescargarComprobante | uppercase}}
            </button>
        </div>
        <div *ngIf="showPDF && bandIos" style="text-align: center !important; width: 100%;">
            <button mat-raised-button (click)="reportePDF()" color="primary" class="buttonToken">
                <mat-icon>cloud_download</mat-icon>
                {{idiomas.VisualizarComprobante | uppercase}}
            </button>
        </div>
    </mat-dialog-actions>
</div>