<div class="example-form" style="padding-top: 10px;" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-progress-bar *ngIf=intento mode="indeterminate"></mat-progress-bar>
    <mat-form-field appearance="outline" fxFlexFill *ngIf="!bandProducts && !intento">
        <mat-label>{{idiomas.Consultar | uppercase}}</mat-label>
        <mat-select (selectionChange)="applyFilterTypeStore($event.value)" [formControl]="categoriaFormT">
            <mat-option *ngFor="let categoria of categoriasTiendas; let i=index" [value]="categoria.id">
                {{i + 1}} -- {{categoria.detalle}}
                <mat-divider style="border-top-width: 3px;"></mat-divider>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="form-row menu" *ngIf="!bandProducts && !intento">
        <div *ngFor="let proveedor of proveedores; let i=index" class="col-3"
            style="min-width: 260px; margin-right: auto; margin-left: auto;">
            <mat-card class="cards" align="center">
                <p>{{proveedor.nombre}}</p>
                <img class="image" *ngIf="proveedor.imagen && proveedor.imagen != ''"
                    [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + proveedor.imagen)"
                    style="cursor: pointer;" width="156px" height="123px"
                    (click)="tienda(proveedor.nombre, proveedor.id, proveedor.cpersona)" />
                <img class="image" *ngIf="!proveedor.imagen && !internet.internet"
                    [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + imagenOffline)"
                    style="cursor: pointer;" width="156px" height="123px"
                    (click)="tienda(proveedor.nombre, proveedor.id, proveedor.cpersona)" />
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header style="padding-left: 0">
                            <mat-panel-title [class.grande]="grande" [class.mediano]="mediano" [class.normal]="normal"
                                *ngIf="proveedor.slogan !='null'">
                                {{proveedor.slogan | cadenaCaracteres}}...</mat-panel-title>
                            <mat-panel-title [class.grande]="grande" [class.mediano]="mediano" [class.normal]="normal"
                                *ngIf="proveedor.slogan =='null'">SIN SLOGAN</mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p *ngIf="proveedor.slogan !='null'" style="text-align: left">{{proveedor.slogan}}</p>
                        <p *ngIf="proveedor.slogan =='null'" style="text-align: left">SIN SLOGAN</p>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
            <div style="height: 10px;"></div>
        </div>
    </div>
    <div *ngIf="!bandProducts && !intento" class="float" id="floatContPosiConso">
        <button mat-fab (click)="refreshTienda()" title={{idiomas.Actualizar}} class="float-right buttonFab" color="primary">
            <mat-icon>autorenew</mat-icon>
        </button>
    </div>
    <div *ngIf="bandProducts">
        <app-productos [parametros]="parametros" [idiomas]="idiomas" [mediano]="mediano" [normal]="normal"
            [grande]="grande" [grupoproductos]="grupoproductos"></app-productos>
        <div class="float-transacciones">
            <button mat-fab (click)="retornar()" title={{idiomas.Regresar}} class="float-right buttonFab" color="primary">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
    </div>
</div>