import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { FlexLayoutModule } from '@angular/flex-layout';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NotificationService } from './app/services/notification.service';
import { FuncionesMenuInferior } from './app/funciones/funcionesMenuInferior';
import { FuncionesTabla } from './app/funciones/funcionesTabla';
import { MostrarPDF } from './app/funciones/mostrarPDF';
import { Idioma } from './app/funciones/idioma';
import { internetComponent } from './app/funciones/internet';
import { MyHammerConfig } from './app/funciones/hammer';
import { HAMMER_GESTURE_CONFIG, BrowserModule, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { Consulta } from './app/funciones/consulta';
import { Alerta } from './app/funciones/alerta';
import { BaseDatos } from './app/funciones/basededatos';
import { Servicios } from './app/funciones/encryptar';
import { FormControlValidators } from './app/funciones/formcontrol';
import { ApiService } from './app/api.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CurrencyInputDirective } from './app/currency-input.directive';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            MatDialogModule,
            MatSnackBar,
            MatButtonModule,
            MatBottomSheetModule,
            BrowserModule,
            BrowserAnimationsModule,
            MatSnackBarModule,
            ServiceWorkerModule.register('/ngsw-worker.js', {
                enabled: environment.production
            }),
            AppRoutingModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatBadgeModule,
            CurrencyInputDirective,
            FormsModule,
            ReactiveFormsModule,
            FlexLayoutModule,
            DigitOnlyModule,
            NgxExtendedPdfViewerModule,
            HammerModule,
            CarouselModule,
            RecaptchaModule,
            RecaptchaFormsModule,
            GoogleMapsModule),
        ApiService,
        FormControlValidators,
        Servicios,
        BaseDatos,
        Alerta,
        Consulta,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        internetComponent,
        Idioma, MostrarPDF,
        FuncionesTabla,
        FuncionesMenuInferior,
        NotificationService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
    .catch(err => console.log(err));
