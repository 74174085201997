import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { MostrarPDF } from '../funciones/mostrarPDF';

import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { internetComponent } from '../funciones/internet';
import { TemporizadorComponent } from '../temporizador/temporizador.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatButtonModule, MatDividerModule, MatProgressBarModule, MatCardModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, OcultarCuentaPosTrans, FormatoFechaCalendar, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-certificado-bancario',
  templateUrl: './certificado-bancario.component.html',
  styleUrls: ['./certificado-bancario.component.css']
})
export class CertificadoBancarioComponent implements OnInit {

  // Inputs para configurar el tamaño y el idioma del componente desde el exterior
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() idiomas: any;

  // Propiedades del componente
  accountsList: any[] = [];
  tokenRequested: boolean = false;
  pdf: string = "";
  generatePDF: boolean = false;
  intento: boolean = false;
  enableFieldsRequestToken: boolean = false;
  optionSelectedToGeneratePdf: string = '';
  email: string = '';
  loginInfo: any;
  ccuenta: any = '';
  deshabilitarBotones: boolean = false;
  bandMobile: boolean = false;
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  // Formulario para el token
  tokenForm = this.validators.otpForm();


  constructor(public validators: FormControlValidators,
    public apiService: ApiService,
    public alertas: Alerta,
    public mostrarPdf: MostrarPDF,
    public internet: internetComponent,
    private dbHandler: BaseDatos) {
    this.bandMobile = false;
    this.enableCronoIn = false;
    this.mostrarCron = false;
  }


  ngOnInit(): void {
    this.bandMobile = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    this.returnAccounts();
    this.loginInfo = this.dbHandler.retornarConsulta("loginInfo");
    this.email = this.loginInfo.email;
  }

  // Función para obtener cuentas
  returnAccounts() {
    let request = {
      "transaccion": "186000-lv-cuentas-in.xml",
      "alias": "tc0",
      "cri^tc0^CPERSONA_CLIENTE^NORMAL": "$personCode",
      "cri^tc0^CSUBSISTEMA^NORMAL": "04",
      "cri^tctp0^CTRANSACCION^JOIN": "6074",
      "cri^tctp0^CESTATUSCUENTA^NORMAL": "002"
    };
    request['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.apiService.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, request)
      .then((data: any) => {
        if (data) {
          this.ccuenta = new OcultarCuentaPosTrans().transform(data[0].CCUENTA) + ' - ' + data[0].DESCRIPCION;
        }
      }, (err) => {
        alert(err);
      });
  }

  // Acción al hacer clic en el botón de generar certificados: '1': Consolidado, '0': Cuenta Activa
  actionButtonGenerate(codeCertificateIdentification: string) {
    this.enableFieldsRequestToken = true;
    this.optionSelectedToGeneratePdf = codeCertificateIdentification;
  }
  // Vista previa del reporte PDF
  previewReportPDF(typeCertificate: number) {
    this.enableFieldsRequestToken = false;
    this.generatePDF = true;

    const previewCertificateName = typeCertificate === 0 ? "18_CERTIFICADO_CUENTA_ACTIVA_P" : "18_CERTIFICADO_CONSOLIDADO_P";

    const envio = {
      "nombre": previewCertificateName,
      "tipo": "PDF",
      "rep^cpersona": String(this.dbHandler.cpersona),
    };
    this.apiService.postProvider2InternetCheck("/reporte", this.dbHandler.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte;
        this.previewActiveAccountReport(this.pdf);
        this.generatePDF = false;
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
          else {
            this.alertas.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario)
          }
        }
        else {
          this.alertas.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }
  previewActiveAccountReport(pdf: string) {
    let pdfConverted = pdf;
    this.alertas.generarDialogoCertificados(this.idiomas.Certificados, this.idiomas, pdfConverted, 'mostrar', true).then((data) => {
    })
  }

  logout() {
    this.alertas.clearAlerta();
    this.alertas.generarOfflineDialogo({ 'salir': true }, this.idiomas);
  }

  tokenRequest() {
    let request = {
      "transaccion": "185009"
    }
    this.intento = true;
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.dbHandler.id_token, request)
      .then((data) => {
        if (data) {
          this.tokenRequested = true;
          this.tokenForm.reset();
          this.alertas.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
          this.intento = false;
        }
      }, (err) => {
        this.alertas.presentarAlertaX(err);
      });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  obtainDateNowFormated() {
    //instancia de Date para obtener la fecha actual
    const currentDate = new Date();
    //Obtiene el año, el mes y el día de la fecha actual
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // El mes comienza desde 0, por lo que sumamos 1
    const day = String(currentDate.getDate()).padStart(2, '0');
    // Formatea la fecha en formato "yyyy-MM-dd"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  sendResponse() {
    this.intento = true;
    this.deshabilitarBotones = true;
    const tipoCertificado = this.optionSelectedToGeneratePdf === '0' ? "18_CUENTA_ACTIVA" : "18_CONSOLIDADO";

    let request = {
      'cpersona': this.dbHandler.cpersona,
      'user': "$userCode",
      'csubsistema': '18',
      "transaccion": "5009",
      'ccuenta': this.ccuenta,
      'tipoCertificado': tipoCertificado
    }
    this.apiService.postProvider2InternetCheck('/certificadocuentaactiva', this.dbHandler.id_token, request).then((data) => {
      let typeCertificateValue = this.optionSelectedToGeneratePdf == '0' ? 'CUENTA ACTIVA' : 'CONSOLIDADO';
      const envioMensaje =
        '</span>' + '<span><b>' + "Estimad@" + ':</b> ' + this.loginInfo.nombreUsuario +
        '</span><br><br>' + 
        '<div style="padding-top: 10px; padding-bottom: 10px; width: 80%;">' + "Usted ha generado un Certificado de Cuenta" + ': ' + "<b>" + typeCertificateValue + "</b>" + " en Coop Virtual PJL."+ 
        '</div>' +
        "<span>"+
        '<table align="center" style="border-collapse: collapse; border: 2px solid rgb(234, 233, 233); width: 90%;">'+
        '<tr style="background-color: rgb(234, 233, 233); color: rgb(0, 0, 0);">'+
        '<th colspan="2" style="text-align: center; padding: 10px;">Detalle de Transacci&oacute;n</th>'+
        '</tr>'+
            '<tr>'+
                '<td style="padding: 8px; text-align: left; width: 180px;"><b>Fecha emisi&oacute;n:</b></td>'+
                '<td style="padding: 8px; text-align: left;">'+ this.obtainDateNowFormated() +'</td>'+
            '</tr>'+
            '<tr>'+
                '<td style="padding: 8px; text-align: left; width: 180px;"><b>IP:</b></td>'+
                '<td style="padding: 8px; text-align: left;">'+data['IP']+'</td>'+
            '</tr>'+
        '</span><br>' + '<span>' + "Si no reconoce esta transacci&oacute;n, favor ac&eacute;quese a la agencia m&aacute;s cercana." +
        '</span><br>' + "<span style='font-family: Verdana, Geneva, Tahoma;'><b>" + "ESTE ES UN MENSAJE AUTOM&Aacute;TICO POR FAVOR NO RESPONDER.";

      this.sendAttachmentToEmail(data['R_SECUENCIAL'], tipoCertificado, envioMensaje, data['RUTACERTIFICADO']);
      this.enableCronoIn = false;
      this.mostrarCron = false;
    }, (err) => {
      this.enableCronoIn = false;
      this.mostrarCron = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false;
        this.alertas.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
          else {
            this.alertas.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario)
          }
        }
        else {
          this.alertas.presentarAlerta(this.idiomas.ServidorError);
        }
      } else { //Sin Internet
        this.intento = false;
      }
    });
  }

  sendAttachmentToEmail(csecuencial: string, tipoCertificado: string, contentMessage: String, rutaCertificado) {
    const generateCertificateName = this.optionSelectedToGeneratePdf === '0' ? "18_CERTIFICADO_CUENTA_ACTIVA_G" : "18_CERTIFICADO_CONSOLIDADO_G";
    var envio = {
      "rep^cpersona": this.dbHandler.cpersona,
      "nombre": generateCertificateName,
      "tipo": "PDF",
      "subsistema": "18",
      "transaccion": "5009",
      "csecuencial": csecuencial,
      'tipoCertificado': tipoCertificado,
      "titleMessage": "Certificado Bancario - COAC Padre Julian Lorente",
      "contentMessage": contentMessage,
      'rutaCertificado': rutaCertificado,
      "email": this.email,
    }
    this.apiService.postProvider2InternetCheck("/reporteCertificadosBancarios", this.dbHandler.id_token, envio).then((data: any) => {

      if (data) {
        this.alertas.generarDialogo(this.idiomas, 'DialogoParaEnviarReporteAlCorreo').then((data) => {
        })
        this.intento = false;
        this.cleanForm();
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
          else {
            this.alertas.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario)
            this.intento = false;
            this.cleanForm();
          }
        }
        else {
          this.alertas.presentarAlerta(this.idiomas.ServidorError);
          this.intento = false;
          this.cleanForm();
        }
      }
      else { //Sin Internet
        this.intento = false;
      }
    });
  }

  cleanForm() {
    this.tokenRequested = false;
    this.enableFieldsRequestToken = false;
    this.tokenForm.reset();
    this.generatePDF = false;
    this.pdf = "";
    this.deshabilitarBotones = false;
    this.enableCronoIn = false;
    this.mostrarCron = false;
  }
}
