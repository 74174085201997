import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { Alerta } from '../funciones/alerta';
import { internetComponent } from '../funciones/internet';
import { CantidadMonetariaPosCon, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { Servicios } from '../funciones/encryptar';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { CurrencyInputDirective } from '../currency-input.directive';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatDividerModule, MatInputModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, CantidadMonetariaPosCon, CurrencyInputDirective, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-transferencias-cuentas-propias',
  templateUrl: './transferencias-cuentas-propias.component.html',
  styleUrls: ['./transferencias-cuentas-propias.component.css']
})
export class TransferenciasCuentasPropiasComponent implements OnInit {

  //Inputs
  @Input() idiomas: any;
  @Input() dataTarPend: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  //Variables
  monedaDesde: string;
  productoDesde: string;
  monedaHacia: string;
  productoHacia: string;
  bancoBeneficiario: string;
  efiInstFinanBeneficiario: string;
  identificacionBeneficiario: string;
  nombreBeneficiario: string;
  cuentas: any;
  cuentasAux: any;
  cuentasRecep: any;
  cuentasAuxRecep: any;
  intento: boolean;
  cuentasBeneficiarios: any;
  ultimasTransferencias: any;
  tokenSolicitado: boolean;
  pdf: string = "";
  fecha: Date;
  dowloadReporte: boolean;
  comision: string;
  bandDisableForm: boolean;
  bandCtsDesde: boolean = false;
  bandCtsHacia: boolean = false;
  CodigoBackend: string = "";
  intentoCuentasOwn: boolean = false;
  intentoCuentasBene: boolean = false;
  pinValid: boolean = false;
  pinValidation: boolean;

  //Variable para el temporizador
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  fechaTransaccion: string = "";
  numeroMensaje: string = "";
  bandMobile: boolean;
  disableButton: boolean;
  intentoReenviar: boolean = false;

  //FormControls
  token = this.validators.otpForm();
  cuentaDesdeSelected = this.validators.comboForm();
  cuentaHaciaSelected = this.validators.comboForm();
  referencia = this.validators.textNumberForm();
  montoTransfer = this.validators.decimalsDolarForm();
  transferenciaSelected = new UntypedFormControl();

  constructor(public alerta: Alerta, public servicios: Servicios, public apiService: ApiService, public basedatos:
    BaseDatos, public validators: FormControlValidators, public internet: internetComponent, public mostrarPdf: MostrarPDF) {
    this.pinValidation = this.basedatos.retornarConsulta("pinValidation")
    this.cuentas = [];
    this.cuentasAux = [];
    this.cuentasRecep = [];
    this.cuentasAuxRecep = [];
    this.dowloadReporte = false;
    this.comision = "";
    this.bandDisableForm = false;
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.disableButton = false;
    this.intentoReenviar = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngOnInit() {
    this.funcionRetornarCuentasOwn("e");
    this.token.setValue('');
    this.obtainBeneficiaryAccounts();
    this.tokenSolicitado = false;
    this.intento = false;
    this.intentoReenviar = false;
  }

  ngAfterViewInit() {
    //Data desde tareas pendientes
    if (this.dataTarPend) {
      Promise.resolve(null).then(() => this.intento = true);
      setTimeout(function () { Promise.resolve(null).then(() => this.intentoReenviar = true), 100 });
      Promise.resolve(null).then(() => this.funcionRetornarCuentasOwn("e"));
    }
    //.
  }

  setValuesOfForm() {
    this.cuentaDesdeSelected.setValue(this.dataTarPend[0]);
  }

  obtainOwnAccount() {
    this.intentoCuentasOwn = false;
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'fullNames': 'true',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN^tc0^=': '$userCode',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN^tc0^=': '18',
      'cri^tctp0^CTRANSACCION^JOIN^tc0^=': '6073',
      'cri^tctp0^VERSIONTRANSACCION^JOIN^tc0^=': '01'
    };
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data.tc0) {
        this.cuentas = this.transformadorCuentasDesde(data.tc0);
        this.cuentasAux = this.transformadorCuentasDesde(data.tc0);
        this.obtainOwnAccountRecept()
        // Data desde tareas pendientes
        this.dataTarPend = this.cuentas;
        if (this.dataTarPend) {
          Promise.resolve(null).then(() => this.setValuesOfForm());
        }
        // .
        // Almacenar Consulta
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentas,
        };
        this.basedatos.insertarConsulta('CtasTransfCtasProp', consulta);
        this.bandCtsDesde = true;
        if (this.dataTarPend && this.bandCtsDesde && this.bandCtsHacia) {
          this.repeatTransferValidation();
        }
        this.intentoCuentasOwn = true;
      }
    }, (err) => {
      this.intentoCuentasOwn = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.cuentasTransferOffline();
        if (!this.cuentas || !this.cuentasAux) {
          this.cuentas = [];
          this.cuentasAux = [];
        }
      }
    });
  }

  funcionRetornarCuentasOwn(e) {
    if (!this.intentoCuentasOwn) {
      this.obtainOwnAccount();
    }
  }

  obtainOwnAccountRecept() {
    this.intentoCuentasBene = false;
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'fullNames': 'true',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN^tc0^=': '$userCode',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN^tc0^=': '18',
      'cri^tctp0^CTRANSACCION^JOIN^tc0^=': '6075',
      'cri^tctp0^VERSIONTRANSACCION^JOIN^tc0^=': '01'
    };
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data.tc0) {
        this.cuentasRecep = this.transformadorCuentasDesde(data.tc0);
        this.cuentasAuxRecep = this.transformadorCuentasDesde(data.tc0);
        // Data desde tareas pendientes
        if (this.dataTarPend) {
          this.intento = false;
          this.intentoReenviar = false;
        }
        //.
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentasRecep,
        };
        this.basedatos.insertarConsulta('CtasTransfCtasPropRecept', consulta);
        this.bandCtsHacia = true;
        this.intentoCuentasBene = true;
      }
    }, (err) => {
      this.intentoCuentasBene = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.cuentasReceptTransferOffline()
        if (!this.cuentasRecep || !this.cuentasAuxRecep) {
          this.cuentasRecep = [];
          this.cuentasAuxRecep = [];
        }
      }
    });
  }

  funcionRetornarCuentasBene(e) {
    if (!this.intentoCuentasBene) {
      this.obtainOwnAccountRecept();
    }
  }

  cuentasTransferOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasTransfCtasProp');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias);
      this.close();
    } else {
      this.cuentas = [];
      this.cuentasAux = [];
      if (!this.dataTarPend) {
        this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas);
      }
      this.cuentas = ObjConsulta.data;
      this.cuentasAux = ObjConsulta.data;
      this.bandCtsDesde = true;
      if (this.dataTarPend && this.bandCtsDesde && this.bandCtsHacia) {
        this.repeatTransferValidation();
      }
    }
  }

  cuentasReceptTransferOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasTransfCtasPropRecept');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias)
      this.close();
    } else {
      this.cuentasRecep = [];
      this.cuentasAuxRecep = [];
      this.cuentasRecep = ObjConsulta.data;
      this.cuentasAuxRecep = ObjConsulta.data;
      this.bandCtsHacia = true;
      if (this.dataTarPend && this.bandCtsDesde && this.bandCtsHacia) {
        this.repeatTransferValidation();
      }
    }
  }

  logout() {
    let obj = {
      'salir': true
    };
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  repeatTransferValidation() {
    let existOriAccount = false;
    let existDesAccount = false;
    let maskOriAccount = this.dataTarPend.cuentaOrigen.viewValue || this.dataTarPend.cuentaOrigen;
    let maskDesAccount = this.dataTarPend.cuentaDestino.viewValue || this.dataTarPend.cuentaDestino;
    this.cuentas.filter(x => {
      if (x.viewValue.slice(-4) == maskOriAccount.slice(-4)) {
        existOriAccount = true;
      }
    });
    this.cuentasAuxRecep.filter(x => {
      if (x.viewValue.slice(-4) == maskDesAccount.slice(-4)) {
        existDesAccount = true;
      }
    });
    if (!(existOriAccount && existDesAccount)) {
      this.alerta.generarDialogo(this.idiomas, "noRepetirTransferencia").then((data) => {
        if (data) {
          this.close();
        }
      });
    }
  }

  obtainBeneficiaryAccounts() {
    this.cuentasBeneficiarios = [];
    if (this.cuentaDesdeSelected.value) {
      for (let i = 0; i < this.cuentasRecep.length; i++) {
        if (this.cuentasRecep[i].value != this.cuentaDesdeSelected.value.value) {
          this.cuentasBeneficiarios.push(this.cuentasRecep[i]);
        }
      }
    } else {
      this.cuentasBeneficiarios = this.cuentasRecep;
    }
    if (this.dataTarPend) {
      this.cuentasAuxRecep = this.cuentasBeneficiarios;
    }
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        value: value[i]["tc0.CCUENTA"], viewValue:
          value[i]["tc0.CCUENTA"], 'descProducto': value[i]["tp0.DESCRIPCION"], moneda: value[i]["tc0.CMONEDA"]
        , saldo: value[i]["tsd0.SALDOMONEDAOFICIAL"] == "null" ? "0.00" : value[i]["tsd0.SALDOMONEDAOFICIAL"], SDisponible: this.idiomas.SDisponible
      });
    }
    return lista;
  }

  transformadorCuentasHacia(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].id, 'viewValue':
          value[i].etiqueta, 'descProducto': this.idiomas.TiposCuentaBeneficiario.filter(x => { return value[i].tipo == x.value })[0].viewValue.toUpperCase(), banco: value[i].cbanco
        , identificacion: value[i].identificacionBeneficiario, nombre: value[i].nombreBeneficiario, moneda: value[i].moneda,
        tipoidentificacion: value[i].ctipoIdentificacion
      });
    }
    return lista;
  }

  transformadorTransferencias(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'descripcion': value[i].descripcion, 'cuenta':
          value[i].cuenta, 'detalle': value[i].detalle, identificacionBen: value[i].identificacionBen
        , nombreBen: value[i].nombreBen, bancoBen: value[i].bancoBen, cuentaBen: value[i].cuentaBen
        , monto: value[i].monto
      });
    }
    return lista;
  }

  seleccionarCuentaDesde() {
    this.obtainBeneficiaryAccounts();
    if (this.cuentaHaciaSelected.value) {
      this.seleccionarCuentaHacia();
    }
    this.monedaDesde = this.cuentaDesdeSelected.value.moneda;
    this.productoDesde = this.cuentaDesdeSelected.value.descProducto;
  }

  seleccionarCuentaHacia() {
    console.log("cuentaHaciaSelected:", this.cuentaHaciaSelected.value.value);
    if (this.cuentaDesdeSelected.value.value == this.cuentaHaciaSelected.value.value) {
      this.monedaHacia = "";
      this.productoHacia = "";
      this.efiInstFinanBeneficiario = "";
    } else {
      this.monedaHacia = this.cuentaHaciaSelected.value.moneda;
      this.productoHacia = this.cuentaHaciaSelected.value.descProducto;
      this.efiInstFinanBeneficiario = '1';
    }
  }

  solicitarToken() {
    this.intento = true;
    const envio = { transaccion: '186073' };
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.basedatos.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.token.reset();
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  enviarTransferencia(isValidation) {
    this.numeroMensaje = "";
    this.fechaTransaccion = "";
    this.CodigoBackend = ""
    const envio = {
      cuenta: this.cuentaDesdeSelected.value.value,
      cuentaOculta: this.cuentaDesdeSelected.value.viewValue,
      cuentaPropia: this.cuentaHaciaSelected.value.value,
      cuentaPropiaOculta: this.cuentaHaciaSelected.value.viewValue,
      clave: this.servicios.toAES(!this.pinValidation ? this.token.value : "000000"),
      desencriptar: '1',
      monto: this.montoTransfer.value.replace("$", '').replace(/,/g, ''),
      referencia: this.referencia.value.toUpperCase(),
      moneda: this.monedaDesde,
      cuentaOrigen: this.cuentaDesdeSelected.value,
      cuentaDestino: this.cuentaHaciaSelected.value,
      comision: this.comision,
      ProdDeb: this.cuentaDesdeSelected.value.prodIdTipoOri,
      MonedaDeb: this.cuentaDesdeSelected.value.moneda,
      ProdCred: this.cuentaHaciaSelected.value.prodIdTipoOri,//prodIdTipoOri porque se utiliza el mismo transformador de cts origen y destino
      MonedaCred: this.cuentaHaciaSelected.value.moneda,
      RutaTranDest: this.efiInstFinanBeneficiario,
      'tarPendType': 'transferCtasPropias',
      isMobile: this.bandMobile ? "1" : "0",
      isValidation: isValidation
    };
    if (isValidation == '1') {
      this.alerta.generarDialogoConfirmacionTransferencias(this.idiomas.TransferenciasCuentasPropias, 'TransferenciasCuentasPropias', this.idiomas, envio).then((data) => {
        if (data) {
          this.apiService.postProvider2InternetCheck('/entrecuentaspropias', this.basedatos.id_token, envio).then((dataTr: any) => {
            this.disableFormControls();
            this.intento = false;
            this.CodigoBackend = dataTr.CodigoBackend;
            this.fechaTransaccion = dataTr.fechaTransaccion;
            this.numeroMensaje = dataTr.numeroMensaje;
            this.dowloadReporte = true;
            this.enableCronoIn = false;
            this.mostrarCron = false;
            this.alerta.generarDialogoSegurotransacciones(this.idiomas, this.numeroMensaje, true, 1);
          }, (err) => {
            this.intento = this.bandMobile && this.pinValidation ? true : false;
            this.disableButton = this.pinValidation ? true : false;
            this.dowloadReporte = false;
            this.enableCronoIn = false;
            this.mostrarCron = false;
            if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
              if (err.error) {
                if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
                else if (err.error.mensaje == "MENSAJE PENDIENTE DE AUTORIZACION POR SOFT TOKEN ") {
                  if (this.pinValidation) {
                    let user = {
                      userCode: this.basedatos.usuarioHB
                    }
                    this.alerta.generarDialogoSolicitarAutorizacionData(this.idiomas, this.grande, this.mediano, user, "").then((data: any) => {
                      if (data) {
                        this.pinValid = true
                        this.disableButton = false
                        if (this.bandMobile) {
                          this.enviarTransferencia('0')
                        }
                      } else {
                        this.intento = false
                        this.pinValid = false
                        this.disableButton = false
                      }
                    }, (err) => {
                      this.intento = false
                    });
                  }
                } else {
                  this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario);
                }
              } else {
                this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError);
              }
            } else { // Sin Internet
              this.disableFormControls();
              this.dowloadReporte = false;
              this.basedatos.insertarTarea(envio);
              this.alerta.presentarAlerta(this.idiomas.NoConectado);
            }
          });
        } else {
          this.intento = false;
          this.dowloadReporte = false;
          this.disableButton = false;
        }
      });
    } else {
      envio.isValidation = isValidation
      this.apiService.postProvider2InternetCheck('/entrecuentaspropias', this.basedatos.id_token, envio).then((dataTr: any) => {
        this.disableFormControls();
        this.intento = false;
        this.CodigoBackend = dataTr.CodigoBackend;
        this.fechaTransaccion = dataTr.fechaTransaccion;
        this.numeroMensaje = dataTr.numeroMensaje;
        this.dowloadReporte = true;
        this.enableCronoIn = false;
        this.mostrarCron = false;
        this.alerta.generarDialogoSegurotransacciones(this.idiomas, this.numeroMensaje, true, 1);
      }, (err) => {
        this.intento = false;
        this.disableButton = false;
        this.dowloadReporte = false;
        this.enableCronoIn = false;
        this.mostrarCron = false;
        if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
          if (err.error) {
            if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
            else {
              this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario);
            }
          } else {
            this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError);
          }
        } else { // Sin Internet
          this.disableFormControls();
          this.dowloadReporte = false;
          this.basedatos.insertarTarea(envio);
          this.alerta.presentarAlerta(this.idiomas.NoConectado);
        }
      });
    }
  }

  solicitarAutorizacion() {
    this.obtenerComision('1')
  }

  disableFormControls() {
    this.bandDisableForm = true;
    this.cuentaDesdeSelected.disable();
    this.montoTransfer.disable();
    this.cuentaHaciaSelected.disable();
    this.referencia.disable();
    this.token.disable();
  }

  obtenerComision(isValidation) {
    this.intento = true;
    this.disableButton = true;
    const enviocom = {
      transaccion: "010041-lv-comision-txpropia-in.xml",
      alias: "tcrp1",
      "cri^tcrp1^PARAMETRO14^NORMAL": this.cuentaDesdeSelected.value.viewValue,
      "cri^tcrp1^PARAMETRO16^NORMAL": this.montoTransfer.value.replace("$", '').replace(/,/g, '')
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviocom).then((data: any) => {
      if (data) {
        if (data.length > 0) {
          this.comision = data[0].PARAMETRO1;
          if (this.comision == "null" || this.comision == "0") {
            this.comision = "0.00";
          }
        } else {
          this.comision = "0.00";
        }
      } else {
        this.comision = "0.00";
      }
      this.enviarTransferencia(isValidation);
    }, (err) => {
      this.intento = false;
      this.disableButton = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet 
        this.enviarTransferencia('0');
      }
    });
  }

  close() {
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.bandDisableForm = false;
    this.dowloadReporte = false;
    this.tokenSolicitado = false;
    this.disableButton = false;
    this.pinValid = false;
    this.transferenciaSelected.setValue('');
    this.cuentaDesdeSelected.setValue('');
    this.montoTransfer.setValue('');
    this.cuentaHaciaSelected.setValue('');
    this.referencia.setValue('');
    this.token.setValue('');
    this.seleccionarCuentaDesde();
    this.seleccionarCuentaHacia();
    this.referencia.reset();
    this.cuentaDesdeSelected.reset();
    this.cuentaHaciaSelected.reset();
    this.montoTransfer.reset();
    this.token.reset();
    this.referencia.enable();
    this.cuentaDesdeSelected.enable();
    this.cuentaHaciaSelected.enable();
    this.montoTransfer.enable();
    this.token.enable();
    this.intentoReenviar = false;
  }

  compareJsonPerValue(object1: any, object2: any) {
    return object1 && object2 && ((object1.value == object2.value) || (object1.viewValue.slice(-4) == object2.toString().slice(-4)));
  }

  selectedOptionOrigen(cta) {
    if (this.dataTarPend && this.dataTarPend.resender) {
      this.dataTarPend.cuentaOrigen = cta;
      this.dataTarPend.referencia = '';
      Promise.resolve(null).then(() => this.setValuesOfForm());
    }
  }

  selectedOptionDestino(cta) {
    if (this.dataTarPend && this.dataTarPend.resender) {
      this.dataTarPend.cuentaDestino = cta;
      this.dataTarPend.referencia = '';
      this.dataTarPend.resender = false;
      Promise.resolve(null).then(() => this.setValuesOfForm());
    }
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  comaToDot(event) {
    if (event.key === ',') {
      event.target.value += '.';
      event.preventDefault();
    }
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace(/,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0];
          let decimalPart = amountArrayDotSplit[1];
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }
}