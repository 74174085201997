import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { Component, Inject } from '@angular/core';
import { Servicios } from '../funciones/encryptar';
import { Router } from "@angular/router";
import { BaseDatos } from "../funciones/basededatos";
import { ApiService } from "../api.service";
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { ServerMockService } from "../services/server-mock.service";

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatButtonModule, MatDialogModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-usuario',
  templateUrl: './dialogo-usuario.component.html',
  styleUrls: ['./dialogo-usuario.component.css']
})
export class DialogoUsuarioComponent {

  salir: any;
  idioma: any;
  bandReport: any;
  bandReportServ: any;
  public usuario: string;
  public dias: string;
  public er: boolean = false;
  jwt: any;
  bandGuardarInfo: boolean;

  constructor(private router: Router, public baseDatos: BaseDatos, private serverMockService: ServerMockService, public servicio: Servicios, private dialogRef: MatDialogRef<DialogoUsuarioComponent>, public apiService: ApiService, @Inject(MAT_DIALOG_DATA) data) {
    this.usuario = data.id;
    this.salir = data.salir;
    this.idioma = data.idioma;
    this.bandReport = data.bandReport;
    this.bandReportServ = data.bandReportServ;
    this.bandGuardarInfo = true;
    this.jwt = data.jwt
    if (this.usuario == 'depurarBase') {
      this.dias = data.dias;
    }
    else {
      this.dias = '0';
    }
  }

  accept() {
    this.dialogRef.close(this.er = true);
  }

  close() {
    this.dialogRef.close(this.er);
  }

  logout() {
    this.dialogRef.close();
    if (!this.bandGuardarInfo)//Elimina información de usuario si no desea almacenarla
    {
      try {
        localStorage.removeItem("BaseDatos");
      } catch (error) { }
    }
    this.router.navigate(['']);
    this.apiService.postProvider2InternetCheck('/logout', this.jwt, {}).then((data) => {
      if (data) {
      }
    }, (err) => {
      console.log(err);
    });
    this.baseDatos.limpiarUsuarioActivoBase()
  }
}



