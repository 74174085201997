import { Injectable } from '@angular/core';
import { Alerta } from '../funciones/alerta';
import { Servicios } from '../funciones/encryptar';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public alerta: Alerta, public servicios: Servicios) { }

  getUser(username: string): User {
    return this.getUsers().find(u => this.servicios.decryptAEStoString(u.username) === username);
  }

  getUsers(): User[] {
    const usersString = localStorage.getItem('users');
    return usersString ? JSON.parse(usersString) : [];
  }

  saveUsers(users: User[]) {
    const usersString = localStorage.getItem('users');
    return usersString ? localStorage.removeItem('users') : localStorage.setItem('users', JSON.stringify(users))
  }

  addUser(user: User) {
    const users = [...this.getUsers(), user];
    this.saveUsers(users);
  }

  removeUser(username: string) {
    const filteredUsers = this.getUsers().filter(user => this.servicios.decryptAEStoString(user.username) !== username);
    this.saveUsers(filteredUsers);
  }

  deleteUser() {
    return localStorage.removeItem('users')
  }
}
