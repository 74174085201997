<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.BtnContactAsesor | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label><strong>{{idiomas.Observacion}}</strong></mat-label>
                        <textarea matInput [formControl]="observacionDomForm" style="min-height: 200px;"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-12" style="padding-top: 5px;">
                    <a href="https://wa.me/593987436070" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-whatsapp.png"
                            class="vc_single_image-img attachment-full" alt="WhatsApp" loading="lazy"></a>
                    <a href="https://www.facebook.com/Coopadrejulian/" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-facebook.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                    <a href="https://www.instagram.com/coopadrejulian/" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-instagra.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                    <a href="https://twitter.com/P_JulianLorente" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-twiter-1.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                    <a href="https://lorente.fin.ec/web/blog" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-blog-1.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6" style="margin-top: 10px;">
                <button mat-raised-button (click)="MensajeHB()" *ngIf="observacionDomForm.valid"
                    title={{idiomas.BtnEnviarReq}} color="primary" class="buttonSubmit">
                    {{idiomas.BtnEnviarReq}}
                </button>
            </div>
            <div align="end" style="text-align: end; margin-top: 10px;" class="col-6">
                <button mat-raised-button (click)="close()" title={{idiomas.Rechazar}} color="warn" class="buttonClean">
                    <mat-icon> cancel_presentation</mat-icon>
                    {{idiomas.Rechazar}}
                </button>
            </div>
        </div>
        <div *ngIf=intento>
            <br>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </mat-card>
    <div class="cards-transations" *ngIf="bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.Notificaciones | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label><strong>{{idiomas.Observacion}}</strong></mat-label>
                        <textarea matInput [formControl]="observacionDomForm" style="min-height: 200px;"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-12" style="padding-top: 5px;">
                    <a href="https://wa.me/593987436070" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-whatsapp.png"
                            class="vc_single_image-img attachment-full" alt="WhatsApp" loading="lazy"></a>
                    <a href="https://www.facebook.com/Coopadrejulian/" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-facebook.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                    <a href="https://www.instagram.com/coopadrejulian/" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-instagra.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                    <a href="https://twitter.com/P_JulianLorente" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-twiter-1.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                    <a href="https://lorente.fin.ec/web/blog" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 5px;"><img width="25"
                            height="25" src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-blog-1.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6" style="margin-top: 10px;">
                <button mat-raised-button (click)="MensajeHB()" *ngIf="observacionDomForm.valid"
                    title={{idiomas.BtnEnviarReq}} color="primary" class="buttonSubmit">
                    {{idiomas.BtnEnviarReq}}
                </button>
            </div>
            <div align="end" style="text-align: end; margin-top: 10px;" class="col-6">
                <button mat-raised-button (click)="close()" title={{idiomas.Rechazar}} color="warn" class="buttonClean">
                    <mat-icon> cancel_presentation</mat-icon>
                    {{idiomas.Rechazar}}
                </button>
            </div>
        </div>
        <div *ngIf=intento>
            <br>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
</div>