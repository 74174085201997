<div class="textura">
    <h2 mat-dialog-title>{{idioma.TituloDialogos}}</h2>

    <mat-dialog-content *ngIf="cuenta && id && !salir">
        <p>{{idioma.Desconectado}} </p>
        <p>{{idioma.UltimaBusqueda}} <strong>ID: </strong>{{id}} <strong>{{idioma.Cuenta}}: </strong>{{cuenta}}</p>
        <p><strong>{{idioma.Fecha}}: </strong>{{fecha}} <strong>{{idioma.Hora}}: </strong>{{hora}}</p>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="!cuenta && id && !salir">
        <p>{{idioma.Desconectado}} </p>
        <p>{{idioma.UltimaBusqueda}} <strong>ID: </strong>{{id}}</p>
        <p><strong>{{idioma.Fecha}}: </strong>{{fecha}} <strong>{{idioma.Hora}}: </strong>{{hora}}</p>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="cuenta && !id && !salir">
        <p>{{idioma.Desconectado}} </p>
        <p>{{idioma.UltimaBusqueda}} <strong>{{idioma.Cuenta}}: </strong>{{cuenta}}</p>
        <p><strong>{{idioma.Fecha}}: </strong>{{fecha}} <strong>{{idioma.Hora}}: </strong>{{hora}}</p>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="!cuenta && !id && !salir">
        <p>{{idioma.Desconectado}} </p>
        <p>{{idioma.UltimaBusqueda}}</p>
        <p><strong>{{idioma.Fecha}}: </strong>{{fecha}} <strong>{{idioma.Hora}}: </strong>{{hora}}</p>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="salir">
        <p><strong>{{idioma.ErrorJWT}}</strong></p>
        <p>{{idioma.RedirLogin}}</p>
        <p>{{idioma.DebeLogin}}</p>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!salir">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="salir">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="logOut()">
                <mat-icon>download_done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>
</div>