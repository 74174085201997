<div *ngIf="!deshabilitar && !deshabilitarBeneficiarios" style="min-width: 90px;">
    <div *ngIf="intentoAviso && !bandMobile" style="padding-bottom: 10px; transition-delay: 5s;">
        <div *ngIf="datosPrestamosAA.length > 0">
            <section class="policy-flechas" *ngFor="let dato of datosPrestamosAA">
                <mat-card class="cards-mobile-accounts"
                    (click)="mostrarTransaccionesPre(dato.spersonacreditospreaprobados,dato.subsistema,'07',true)"
                    style="padding: 5px 5px 0px; background-color: transparent !important;"
                    [ngStyle]="{'border-color':tema == 'night-theme' ? '#ffffff' : '#2675A9'}">
                    <div class="form-row">
                        <div class="col-11 color-letras-cartas">
                            <p style="font-size: .8em; margin-bottom: 0;">
                                {{idiomas.TienePreAproAnti}} ${{dato.montohasta |
                                cantidadMonetaria}}{{idiomas.TienePreAproAntiD}}</p>
                        </div>
                        <div class="col-1" style="text-align: center;">
                            <div style="margin-top: -10px !important;" class="color-letras-cartas">
                                <mat-icon
                                    style="cursor: pointer !important;     vertical-align: -webkit-baseline-middle;">
                                    arrow_forward_ios</mat-icon>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </section>
        </div>
    </div>
    <div *ngIf="intentoAviso && bandMobile && skinAestetic" style="padding-bottom: 20px; padding-top: 15px;">
        <div *ngIf="datosPrestamosAA.length > 0">
            <section class="policy-flechas" *ngFor="let dato of datosPrestamosAA">
                <mat-card class="cards-mobile-accounts"
                    (click)="mostrarTransaccionesPre(dato.spersonacreditospreaprobados,dato.subsistema,'07',true)"
                    style="padding: 5px 10px 5px; background-color: transparent !important;"
                    [ngStyle]="{'border-color':tema == 'night-theme' ? '#ffffff' : '#2675A9'}">
                    <div class="color-letras-cartas">
                        <p
                            style="font-size: .8em; margin-bottom: 0; text-align: justify; text-decoration: underline;cursor: pointer;">
                            {{idiomas.TienePreAproAnti}} ${{dato.montohasta |
                            cantidadMonetaria}}{{idiomas.TienePreAproAntiD}}
                            <mat-icon
                                style="cursor: pointer !important; font-size: 18px !important; vertical-align: -webkit-baseline-middle;">
                                arrow_forward_ios</mat-icon>
                        </p>
                    </div>
                </mat-card>
            </section>
        </div>
    </div>
    <div style="padding-bottom: 10px;" *ngIf="!bandMobile">
        <mat-card *ngIf="!bandMobile" style="padding-top: 5px !important;padding-bottom: 5px !important;">
            <div [ngClass]="{'ceroHeight':noMuestraGrafico}">
                <div class="row">
                    <div class="center col texto">
                        <h6 class="posCon-header">
                            <strong>{{idiomas.GraficoConsolidada | uppercase}}</strong>
                        </h6>
                    </div>
                </div>
                <div class="row">
                    <app-grafico-posicion-consolidada *ngIf="!noMuestraGrafico" class="col" [colorsArrayChart]="coloresGrafica" [data]="datosGrafica"
                        [idiomas]="idiomas"></app-grafico-posicion-consolidada>
                </div>
            </div>
            <br>
        </mat-card>
    </div>
    <div style="padding-bottom: 5px;" *ngIf="bandMobile && skinAestetic">
        <div [ngClass]="{'ceroHeight':noMuestraGrafico}">
            <div class="row">
                <div class="center col texto">
                    <h6 class="posCon-header" style="margin-bottom: 0 !important;">
                        <strong>{{idiomas.GraficoConsolidada | uppercase}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <app-grafico-posicion-consolidada *ngIf="!noMuestraGrafico" class="col" [colorsArrayChart]="coloresGrafica" [data]="datosGrafica"
                    [idiomas]="idiomas"></app-grafico-posicion-consolidada>
            </div>
        </div>
    </div>

    <mat-card style="padding-bottom: 10px !important;" *ngIf="!bandMobile">
        <div class="control-section">
            <div class="content-wrapper">
                <div *ngIf="hasProducts[0].ahorros" style="text-align: center !important;">
                    <div class="btn-group manipulate-web row">
                        <button mat-raised-button class="buttonMenuPosCon" *ngIf="hasProducts[0].ahorros"
                            (click)="buttonSelected(0); onTabClick(0)" [ngClass]="{'active': tabIndex === 0}">
                            <mat-icon>currency_exchange</mat-icon>
                            {{idiomas.Ahorros}}</button>
                        <button mat-raised-button class="buttonMenuPosCon" *ngIf="hasProducts[1].inversiones"
                            (click)="buttonSelected(1); onTabClick(1)" [ngClass]="{'active': tabIndex === 1}">
                            <mat-icon>query_stats</mat-icon>
                            {{idiomas.InversionPC}}</button>
                        <button mat-raised-button class="buttonMenuPosCon" *ngIf="hasProducts[2].prestamos"
                            (click)="buttonSelected(2); onTabClick(2)" [ngClass]="{'active': tabIndex === 2}">
                            <mat-icon>payments</mat-icon>
                            {{idiomas.CtasPrestamosPc}}</button>
                        <button mat-raised-button class="buttonMenuPosCon" *ngIf="hasProducts[3].prestamosA"
                            (click)="buttonSelected(3); onTabClick(3)" [ngClass]="{'active': tabIndex === 3}">
                            <mat-icon>fact_check</mat-icon>
                            {{idiomas.CreditoPreaprobado}}</button>
                    </div>
                </div>
                <div id="tabContainer">
                    <div class="e-content">
                        <div *ngIf="bandsFuncion['0']">
                            <div *ngIf="ahorros.length > 0 && showTable" style="padding-top: 15px;">
                                <div class="row" style="margin-left: -24px !important;">
                                    <div class="col flecha" style="margin-right: 4px;">
                                        <mat-icon [class.desaparecer]=flechaL1 id="flechaL1"
                                            (click)="scrollTo('containerAH','ahorros','left')">
                                            add_circle_outline
                                        </mat-icon>
                                    </div>
                                    <div class="col containerAH" (scroll)="scroll('containerAH','ahorros')"
                                        [class.heightconteiner1]="(ahorros.length>numMaxRow)">
                                        <table mat-table [dataSource]="datosAhorros" class="ahorros" id="tabla">
                                            <ng-container matColumnDef="Cuenta">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.NCuenta}}</th>
                                                <td mat-cell *matCellDef="let dato" class="cuenta"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">
                                                    <a style="text-decoration: underline;cursor: pointer;">{{dato.ccuenta
                                                        |
                                                        ocultarCuentaPosTrans}}</a>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Producto">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Producto}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="left"
                                                    style="min-width: 100px;">
                                                    {{dato.dproducto}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Disponible">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.SDisponible}}</th>
                                                <td mat-cell *matCellDef="let dato" style="padding-left: 10px;"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>{{dato.available |
                                                    cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Total">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.SaldoTotal}}</th>
                                                <td mat-cell *matCellDef="let dato" [class.norBalCellWidth]=normal
                                                    style="padding-left: 10px;" [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>{{dato.accounting |
                                                    cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Bloqueado">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.VBloqueado}}</th>
                                                <td mat-cell *matCellDef="let dato" [class.norBalCellWidth]=normal
                                                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                                                    {{dato.blocked | cantidadMonetaria}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Estado">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.Estado}}</th>
                                                <td mat-cell *matCellDef="let dato">
                                                    {{dato.estado}} </td>
                                            </ng-container>
                                            <tr mat-header-row class="mayt-header-row"
                                                *matHeaderRowDef="displayedColumnsAhorros; sticky: true">
                                            </tr>
                                            <tr mat-row
                                                *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsAhorros"
                                                [ngClass]="{gray: even}">{{showHideRows('containerAH','04',last)}}</tr>
                                        </table>
                                    </div>
                                    <div class="col flecha" style="margin-left: 4px;">
                                        <mat-icon [class.desaparecer]=flechaR1 id="flechaR1"
                                            (click)="scrollTo('containerAH','ahorros','right')">
                                            keyboard_arrow_right</mat-icon>
                                    </div>
                                </div>
                                <mat-paginator #paginatorAhorros="matPaginator" style="background:transparent"
                                    [length]="lengthAh" [pageIndex]="pageIndexAh" [pageSize]="pageSizeAh"
                                    [hidePageSize]="true">
                                </mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="bandsFuncion['1']">
                            <div *ngIf="inversiones.length > 0 && showTable" style="padding-top: 15px;">
                                <div class="row" style="margin-left: -24px !important;">
                                    <div class="col flecha" style="margin-right: 4px;">
                                        <mat-icon [class.desaparecer]=flechaL2 id="flechaL2"
                                            (click)="scrollTo('containerPL','pfijo','left')">
                                            add_circle_outline</mat-icon>
                                    </div>
                                    <div class="col containerPL" (scroll)="scroll('containerPL','pfijo')"
                                        [class.heightconteiner1]="(inversiones.length>numMaxRow)">
                                        <table mat-table [dataSource]="datosInversiones" class="pfijo" id="tabla">
                                            <ng-container matColumnDef="Cuenta">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.NCuenta}}</th>
                                                <td mat-cell *matCellDef="let dato" class="cuenta"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">
                                                    <a style="text-decoration: underline;cursor: pointer;">{{dato.ccuenta
                                                        |
                                                        ocultarCuentaPosTrans}}</a>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Producto">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Producto}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="left"
                                                    style="min-width: 100px;">
                                                    {{dato.dproducto}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="MontoAper">
                                                <th mat-header-cell *matHeaderCellDef>Capital</th>
                                                <td mat-cell *matCellDef="let dato" style="padding-left: 10px;"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>
                                                    {{dato.monto | cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Plazo">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    {{idiomas.Plazo}}</th>
                                                <td mat-cell *matCellDef="let dato">{{dato.plazo}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="Tasa">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    {{idiomas.Tasa}}</th>
                                                <td mat-cell *matCellDef="let dato" [class.norBalCellWidth]=normal
                                                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                                                    {{dato.tasa | cantidadMonetaria}}%
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Apertura">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.FechaApertura}}</th>
                                                <td mat-cell *matCellDef="let dato" [class.norBalCellWidth]=normal
                                                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                                                    {{dato.fapertura | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Vencimiento">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.Vencimientopl}}</th>
                                                <td mat-cell *matCellDef="let dato">
                                                    {{dato.fvencimiento | formatoFecha:idiomas.IdiomaSeleccionado}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Estado">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.Estado}}</th>
                                                <td mat-cell *matCellDef="let dato" class="estado"> {{dato.estado}}
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row
                                                *matHeaderRowDef="displayedColumnsPlazoFijo; sticky: true">
                                            </tr>
                                            <tr mat-row
                                                *matRowDef="let row; let even = even; let last = last;columns: displayedColumnsPlazoFijo"
                                                [ngClass]="{gray: even}">{{showHideRows('containerPL','05',last)}}</tr>
                                        </table>
                                    </div>
                                    <div class="col flecha" style="margin-left: 4px;">
                                        <mat-icon [class.desaparecer]=flechaR2 id="flechaR2"
                                            (click)="scrollTo('containerPL','pfijo','right')">
                                            keyboard_arrow_right</mat-icon>
                                    </div>
                                </div>
                                <mat-paginator #paginatorInversiones="matPaginator" style="background:transparent"
                                    [length]="lengthInv" [pageIndex]="pageIndexInv" [pageSize]="pageSizeInv"
                                    [hidePageSize]="true">
                                </mat-paginator>
                            </div>
                        </div>

                        <div *ngIf="bandsFuncion['2']">
                            <div *ngIf="prestamos.length > 0 && showTable" style="padding-top: 15px;">
                                <div class="row" style="margin-left: -24px !important;">
                                    <div class="col flecha" style="margin-right: 4px;">
                                        <mat-icon [class.desaparecer]=flechaL3 id="flechaL3"
                                            (click)="scrollTo('containerCR','prestamos','left')">
                                            add_circle_outline</mat-icon>
                                    </div>
                                    <div class="col containerCR" (scroll)="scroll('containerCR','prestamos')"
                                        [class.heightconteiner1]="(prestamos.length>numMaxRow)">
                                        <table mat-table [dataSource]="datosPrestamos" class="prestamos" id="tabla">
                                            <ng-container matColumnDef="Cuenta">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.NCuenta}}</th>
                                                <td mat-cell *matCellDef="let dato" class="cuenta"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">
                                                    <a style="text-decoration: underline;cursor: pointer;">{{dato.ccuenta
                                                        |
                                                        ocultarCuentaPosTrans}}</a>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="MontoAper">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.MOriginal}}</th>
                                                <td mat-cell *matCellDef="let dato" style="padding-left: 10px;"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>
                                                    {{dato.capital | cantidadMonetaria}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="SaldoCapital">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.SCapital}}</th>
                                                <td mat-cell *matCellDef="let dato" [class.norBalCellWidth]=normal
                                                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                                                    {{dato.actualBal | cantidadMonetaria}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="fProxPago">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.FproxPago}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="fechas left fprog">
                                                    {{dato.proxpago | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="ValorPagar">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.VPagar}}</th>
                                                <td mat-cell *matCellDef="let dato" [class.norBalCellWidth]=normal
                                                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                                                    {{dato.valorpagar | cantidadMonetaria}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Producto">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Producto}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="left"
                                                    style="min-width: 100px;">
                                                    {{dato.dproducto}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Estado">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Estado}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="estado left"> {{dato.estado}}
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row
                                                *matHeaderRowDef="displayedColumnsPrestamos; sticky: true"
                                                class="mat-header-row"></tr>
                                            <tr mat-row
                                                *matRowDef="let row; let even = even; let last = last;columns: displayedColumnsPrestamos"
                                                [ngClass]="{gray: even}">{{showHideRows('containerCR','06',last)}}</tr>
                                        </table>
                                    </div>
                                    <div class="col flecha" style="margin-left: 4px;">
                                        <mat-icon [class.desaparecer]=flechaR3 id="flechaR3"
                                            (click)="scrollTo('containerCR','prestamos','right')">
                                            keyboard_arrow_right</mat-icon>
                                    </div>
                                </div>
                                <mat-paginator #paginatorPrestamos="matPaginator" style="background:transparent"
                                    [length]="lengthPre" [pageIndex]="pageIndexPre" [pageSize]="pageSizePre"
                                    [hidePageSize]="true">
                                </mat-paginator>
                            </div>
                        </div>

                        <div *ngIf="bandsFuncion['3']">
                            <div *ngIf="prestamosA.length > 0 && showTable" style="padding-top: 15px;">
                                <div class="row" style="margin-left: -24px !important;">
                                    <div class="col flecha" style="margin-right: 4px;">
                                        <mat-icon [class.desaparecer]=flechaL4 id="flechaL4"
                                            (click)="scrollTo('containerCRA','preaprobados','left')">
                                            add_circle_outline</mat-icon>
                                    </div>
                                    <div class="col containerCRA" (scroll)="scroll('containerCRA','preaprobados')"
                                        [class.heightconteiner1]="(prestamosA.length>numMaxRow)">
                                        <table mat-table [dataSource]="datosPrestamosA" class="preaprobados" id="tabla">
                                            <ng-container matColumnDef="Producto">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.Producto}}</th>
                                                <td mat-cell *matCellDef="let dato ; let i=index" class="cuenta">
                                                    <a *ngIf="dato.cproducto != '06'">{{idiomas.PrestamoPreaprobadoT |
                                                        uppercase}}</a>
                                                    <a *ngIf="dato.cproducto == '06'">{{dato.cuenta}}</a>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Moneda">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.Moneda}}</th>
                                                <td mat-cell *matCellDef="let dato" class="producto">{{dato.cmoneda}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Montomin">
                                                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Montomin}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="numeros"> {{dato.montodesde |
                                                    cantidadMonetaria}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Montomax">
                                                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Montomax}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="numeros">{{dato.montohasta |
                                                    cantidadMonetaria}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Plazomin">
                                                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Plazomin}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="tasa">
                                                    {{(dato.plazodesde/30)|plazoMeses}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Plazomax">
                                                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Plazomax}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="tasa">{{(dato.plazohasta/30)
                                                    |
                                                    plazoMeses}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Tasa">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Tasa}}</th>
                                                <td mat-cell *matCellDef="let dato" class="fechas left">{{dato.tasa}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Fvigencia">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Fvigencia}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="fechas left"
                                                    style="min-width: 80px !important;">{{dato.fvigencia}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Estado">
                                                <th mat-header-cell *matHeaderCellDef class="left"></th>
                                                <td mat-cell *matCellDef="let dato" class="center">
                                                    {{idiomas.Aceptar}}
                                                    <mat-icon style="cursor: pointer !important; padding-top: 5px;"
                                                        (click)="mostrarTransaccionesPre(dato.spersonacreditospreaprobados,dato.subsistema,'07',false)">
                                                        download_done</mat-icon>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Rechazar">
                                                <th mat-header-cell *matHeaderCellDef class="left"></th>
                                                <td mat-cell *matCellDef="let dato" class="center">
                                                    {{idiomas.RechazarPreApro}}
                                                    <mat-icon style="cursor: pointer !important; padding-top: 5px;"
                                                        (click)="enviarRespuestaN(dato.spersonacreditospreaprobados)">
                                                        clear</mat-icon>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row
                                                *matHeaderRowDef="displayedColumnsPrestamosPreaprobados; sticky: true"
                                                class="mat-header-row"></tr>
                                            <tr mat-row
                                                *matRowDef="let row; let even = even; let last = last;columns: displayedColumnsPrestamosPreaprobados"
                                                [ngClass]="{gray: even}">
                                                {{showHideRows('containerCRA','preaprobados',last)}}
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col flecha" style="margin-left: 4px;">
                                        <mat-icon [class.desaparecer]=flechaR4 id="flechaR4"
                                            (click)="scrollTo('containerCRA','preaprobados','right')">
                                            keyboard_arrow_right</mat-icon>
                                    </div>
                                </div>
                                <mat-paginator #paginatorPrestamosA="matPaginator" style="background:transparent"
                                    [length]="lengthPreA" [pageIndex]="pageIndexPreA" [pageSize]="pageSizePreA"
                                    [hidePageSize]="true">
                                </mat-paginator>
                            </div>

                        </div>

                        <div *ngIf="bandsFuncion['4']">
                            <div *ngIf="tarjetasDeCredito.length > 0 && showTable" style="padding-top: 15px;">
                                <div class="row" style="margin-left: -24px !important;">
                                    <div class="col flecha" style="margin-right: 4px;">
                                        <mat-icon [class.desaparecer]=flechaL5 id="flechaL5"
                                            (click)="scrollTo('containerTCR','tarjetasCredito','left')">
                                            add_circle_outline</mat-icon>
                                    </div>
                                    <div class="col containerTCR" (scroll)="scroll('containerTCR','tarjetasCredito')">
                                        <table mat-table [dataSource]="tarjetasDeCredito" class="tarjetasCredito"
                                            id="tabla">
                                            <ng-container matColumnDef="tarjetaNumero">
                                                <th mat-header-cell *matHeaderCellDef>{{idiomas.tarjetaNumero}}</th>
                                                <td mat-cell *matCellDef="let dato" class="cuenta"
                                                    (click)="mostrarTransacciones(dato.tarjetaNumero, dato.subsistema, 'TARJETACREDITO')"
                                                    style="padding-right: 10px"><a
                                                        style="text-decoration: underline;cursor: pointer;">{{dato.tarjetaNumero
                                                        |
                                                        ocultarCuentaPosTrans}}</a>
                                                </td>
                                                <td mat-cell *matCellDef="let dato" class="cuenta">{{dato.tarjetaNumero
                                                    |
                                                    ocultarCuentaPosTrans}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="tarjetaMarca">
                                                <th mat-header-cell *matHeaderCellDef class="left">
                                                    {{idiomas.tarjetaMarca}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="left">{{dato.tarjetaMarca}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="tarjetaTipo">
                                                <th mat-header-cell *matHeaderCellDef class="left">
                                                    {{idiomas.tarjetaTipo}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="left">{{dato.tarjetaTipo}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="fechaEmision">
                                                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.FEmision}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="fechas left">
                                                    {{dato.fechaEmision | formatoFecha:idiomas.IdiomaSeleccionado}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="fechaMaxPago">
                                                <th mat-header-cell *matHeaderCellDef class="left">
                                                    {{idiomas.fechaActualMaxPago}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="fechas left">
                                                    {{dato.fechaMaxPago | formatoFecha:idiomas.IdiomaSeleccionado}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="PeriodoIni">
                                                <th mat-header-cell *matHeaderCellDef class="right left">
                                                    {{idiomas.Periodo}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="fechas left">
                                                    {{dato.periodoInicial | formatoFecha:idiomas.IdiomaSeleccionado}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="PeriodoFin">
                                                <th mat-header-cell *matHeaderCellDef class="left"></th>
                                                <td mat-cell *matCellDef="let dato" class="fechas left">
                                                    {{dato.periodoFinal | formatoFecha:idiomas.IdiomaSeleccionado}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="minPagar">
                                                <th mat-header-cell *matHeaderCellDef class="right left"
                                                    style="padding-right: 10px">
                                                    {{idiomas.minPagar}}</th>
                                                <td mat-cell *matCellDef="let dato" class="numeros left"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande style="padding-right: 10px">
                                                    {{dato.minPagar | cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="saldoPagar">
                                                <th mat-header-cell *matHeaderCellDef class="right left">
                                                    {{idiomas.tcSaldoAnterior}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="numeros left"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>
                                                    {{dato.saldoPagar | cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="pagosCreditos">
                                                <th mat-header-cell *matHeaderCellDef class="right left">
                                                    {{idiomas.PagoCredito}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="numeros left"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>
                                                    {{dato.pagosCreditos | cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="consumosDebitos">
                                                <th mat-header-cell *matHeaderCellDef class="right left">
                                                    {{idiomas.ConsumoDebito}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="numeros left"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>
                                                    {{dato.consumosDebitos | cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="TotalPagar">
                                                <th mat-header-cell *matHeaderCellDef class="right left">
                                                    {{idiomas.TotalPagar}}
                                                </th>
                                                <td mat-cell *matCellDef="let dato" class="numeros left"
                                                    [class.norBalCellWidth]=normal [class.medBalCellWidth]=mediano
                                                    [class.graBalCellWidth]=grande>
                                                    {{dato.totalPagar | cantidadMonetaria}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Utilizado">
                                                <th mat-header-cell *matHeaderCellDef class="right"
                                                    style="padding-right: 10px;">
                                                    {{idiomas.Cupo}}</th>
                                                <td mat-cell *matCellDef="let dato" class="numeros"
                                                    style="padding-right: 10px;" [class.norBalCellWidth]=normal
                                                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                                                    {{dato.utilizado | cantidadMonetaria}}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsTarjetasCredito"
                                                class="mat-header-row">
                                            </tr>
                                            <tr mat-row
                                                *matRowDef="let row; let even = even; let last = last;columns: displayedColumnsTarjetasCredito;"
                                                [ngClass]="{gray: even}">
                                                {{showHideRows('containerTCR','TARJETACREDITO',last)}}
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col flecha" style="margin-left: 4px;">
                                        <mat-icon [class.desaparecer]=flechaR5 id="flechaR5"
                                            (click)="scrollTo('containerTCR','tarjetasCredito','right')">
                                            keyboard_arrow_right</mat-icon>
                                    </div>
                                </div>
                                <mat-paginator #paginatorTarjetas="matPaginator" style="background:transparent"
                                    [length]="lengthTar" [pageIndex]="pageIndexTar" [pageSize]="pageSizeTar"
                                    [hidePageSize]="true">
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <div style="padding-bottom: 10px !important;" *ngIf="bandMobile && skinAestetic">
        <div class="control-section" style="padding-top: 10px; padding-bottom: 10px;">
            <div class="content-wrapper">
                <div *ngIf="hasProducts[0].ahorros" style="text-align: center !important;">
                    <div class="manipulate-mob row">
                        <div *ngIf="hasProducts[0].ahorros"
                            style="margin-top: 3px; text-align: center !important; padding-left: 3px; padding-right: 3px;">
                            <button mat-stroked-button *ngIf="tema == 'light-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 0 ? coloresGrafica[0] : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs"
                                    [ngStyle]="{'color':tabIndex === 0 ? '#ffffff' : coloresGrafica[0]}"
                                    (click)="buttonSelected(0); onTabClick(0)">currency_exchange</mat-icon>
                                <div [ngStyle]="{'color':tabIndex === 0 ? '#ffffff' : coloresGrafica[0]}">
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.Ahorros}}
                                    </div>
                                </div>
                            </button>
                            <button mat-stroked-button *ngIf="tema == 'night-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 0 ? '#fdc800' : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs" color="primary"
                                    (click)="buttonSelected(0); onTabClick(0)">currency_exchange</mat-icon>
                                <div>
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.Ahorros}}
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="hasProducts[1].inversiones"
                            style="margin-top: 3px; text-align: center !important; padding-left: 3px; padding-right: 3px;">
                            <button mat-stroked-button *ngIf="tema == 'light-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                color="primary" class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 1 ? coloresGrafica[1] : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs"
                                    [ngStyle]="{'color':tabIndex === 1 ? '#ffffff' : coloresGrafica[1]}"
                                    (click)="buttonSelected(1); onTabClick(1)">query_stats</mat-icon>
                                <div [ngStyle]="{'color':tabIndex === 1 ? '#ffffff' : coloresGrafica[1]}">
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.InversionPC}}
                                    </div>
                                </div>
                            </button>
                            <button mat-stroked-button *ngIf="tema == 'night-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                color="primary" class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 1 ? '#fdc800' : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs" color="primary"
                                    (click)="buttonSelected(1); onTabClick(1)">query_stats</mat-icon>
                                <div color="primary">
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.InversionPC}}
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="hasProducts[2].prestamos"
                            style="margin-top: 3px; text-align: center !important; padding-left: 3px; padding-right: 3px;">
                            <button mat-stroked-button *ngIf="tema == 'light-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 2 ? coloresGrafica[2] : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs"
                                    [ngStyle]="{'color':tabIndex === 2 ? '#ffffff' : coloresGrafica[2]}"
                                    (click)="buttonSelected(2); onTabClick(2)">payments</mat-icon>
                                <div [ngStyle]="{'color':tabIndex === 2 ? '#ffffff' : coloresGrafica[2]}">
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.CtasPrestamosPc}}
                                    </div>
                                </div>
                            </button>
                            <button mat-stroked-button *ngIf="tema == 'night-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 2 ? '#fdc800' : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs" color="primary"
                                    (click)="buttonSelected(2); onTabClick(2)">payments</mat-icon>
                                <div color="primary">
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.CtasPrestamosPc}}
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="hasProducts[3].prestamosA"
                            style="margin-top: 3px; text-align: center !important; padding-left: 3px; padding-right: 3px;">
                            <button mat-stroked-button *ngIf="tema == 'light-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 3 ? '#2675A9' : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs"
                                    [ngStyle]="{'color':tabIndex === 3 ? '#ffffff' : '#2675A9'}"
                                    (click)="buttonSelected(3); onTabClick(3)">fact_check</mat-icon>
                                <div [ngStyle]="{'color':tabIndex === 3 ? '#ffffff' : '#2675A9'}">
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.Creditos}}
                                    </div>
                                </div>
                            </button>
                            <button mat-stroked-button *ngIf="tema == 'night-theme'"
                                style="width: fit-content !important; padding-left: 0px; padding-right: 0px;"
                                class="buttonSubmitPosCon"
                                [ngStyle]="{'background-color':tabIndex === 3 ? '#fdc800' : 'transparent'}">
                                <mat-icon class="bottom-menu-icons-tabs" color="primary"
                                    (click)="buttonSelected(3); onTabClick(3)">fact_check</mat-icon>
                                <div color="primary">
                                    <div style="line-height: 20px; font-size: .8em;">
                                        {{idiomas.Creditos}}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="tabContainer">
            <div class="e-content" style="padding-top: 15px;">
                <div *ngIf="bandsFuncion['0']">
                    <section class="policy-flechas">
                        <div *ngIf="ahorros.length > 0">
                            <owl-carousel-o [options]="customCarouselOptions">
                                <ng-container *ngFor="let dato of ahorros">
                                    <ng-template carouselSlide>
                                        <mat-card class="cards-mobile-accounts">
                                            <div style="height: fit-content !important; width: 100%;">
                                                <div style="text-align: end;">
                                                    <mat-icon class="color-letras-cartas"
                                                        style="cursor: pointer !important; font-size: 30px; height: 30px;width: 30px;"
                                                        (click)="mostrarDialogo(dato,'04')">
                                                        currency_exchange
                                                    </mat-icon>
                                                </div>
                                                <div style="width: 75%;margin-top: -35px;">
                                                    <p class="color-letras-cartas"
                                                        (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                        style="text-decoration: underline;cursor: pointer; font-size: 1.1em; ">
                                                        Nro.
                                                        {{dato.ccuenta |
                                                        ocultarCuentaPosTrans}}</p>
                                                </div>
                                            </div>
                                            <div style="height: fit-content !important;"
                                                (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">
                                                <p
                                                    style="font-size: .9em; margin-bottom: 7px !important; font-weight: bold;">
                                                    {{dato.dproducto}}
                                                </p>
                                            </div>
                                            <div class="form-row">
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;">
                                                        {{idiomas.SDisponible}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;"
                                                        class="color-letras-cartas">
                                                        ${{dato.available |
                                                        cantidadMonetaria}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;">
                                                        {{idiomas.SaldoTotal}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;"
                                                        class="color-letras-cartas">
                                                        ${{dato.accounting |
                                                        cantidadMonetaria}}</p>
                                                </div>
                                                <div class="col-6" *ngIf="dato.blocked > 0"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;">
                                                        {{idiomas.VBloqueado}}</p>
                                                </div>
                                                <div class="col-6" *ngIf="dato.blocked > 0"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;"
                                                        class="color-letras-cartas">
                                                        ${{dato.blocked |
                                                        cantidadMonetaria}}</p>
                                                </div>
                                            </div>
                                        </mat-card>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </section>
                </div>
                <div *ngIf="bandsFuncion['1']">
                    <section class="policy-flechas">
                        <div *ngIf="inversiones.length > 0" style="padding-top: 3px;">
                            <owl-carousel-o [options]="customCarouselOptions">
                                <ng-container *ngFor="let dato of inversiones">
                                    <ng-template carouselSlide>
                                        <mat-card class="cards-mobile-accounts">
                                            <div style="height: fit-content !important; width: 100%;">
                                                <div style="text-align: end;">
                                                    <mat-icon (click)="mostrarDialogo(dato,'05')"
                                                        style="cursor: pointer !important; font-size: 30px; height: 30px; width: 30px;"
                                                        class="color-letras-cartas">
                                                        query_stats
                                                    </mat-icon>
                                                </div>
                                                <div style="width: 75%;margin-top: -35px;">
                                                    <p class="color-letras-cartas"
                                                        (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                        style="text-decoration: underline;cursor: pointer; font-size: 1.1em;">
                                                        Nro.
                                                        {{dato.ccuenta |
                                                        ocultarCuentaPosTrans}}</p>
                                                </div>
                                            </div>
                                            <div (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                style="height: fit-content !important;">
                                                <p
                                                    style="font-size: .9em; margin-bottom: 7px !important; font-weight: bold;">
                                                    {{dato.dproducto}}</p>
                                            </div>
                                            <div class="form-row">
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; ; margin-bottom: 0 !important;">
                                                        Capital
                                                    </p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;"
                                                        class="color-letras-cartas">
                                                        ${{dato.monto |
                                                        cantidadMonetaria}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;">
                                                        {{idiomas.Tasa}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;"
                                                        class="color-letras-cartas">
                                                        {{dato.tasa |
                                                        cantidadMonetaria}}%</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;">
                                                        {{idiomas.VencimientoplMob}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0 !important;"
                                                        class="color-letras-cartas">
                                                        {{dato.fvencimiento |
                                                        formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                                                </div>
                                            </div>
                                        </mat-card>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </section>
                </div>

                <div *ngIf="bandsFuncion['2']">
                    <section class="policy-flechas">
                        <div *ngIf="prestamos.length > 0" style="padding-top: 3px;">
                            <owl-carousel-o [options]="customCarouselOptions">
                                <ng-container *ngFor="let dato of prestamos">
                                    <ng-template carouselSlide>
                                        <mat-card class="cards-mobile-accounts">
                                            <div style="height: fit-content !important; width: 100%;">
                                                <div style="text-align: end;">
                                                    <mat-icon (click)="mostrarDialogo(dato,'06')"
                                                        style="cursor: pointer !important; font-size: 30px; height: 30px; width: 30px;"
                                                        class="color-letras-cartas">
                                                        credit_score
                                                    </mat-icon>
                                                </div>
                                                <div style="width: 75%;margin-top: -35px;">
                                                    <p class="color-letras-cartas"
                                                        (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                        style="text-decoration: underline;cursor: pointer; font-size: 1.1em;">
                                                        Nro.
                                                        {{dato.ccuenta |
                                                        ocultarCuentaPosTrans}}</p>
                                                </div>
                                            </div>
                                            <div (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                style="height: fit-content !important;">
                                                <p
                                                    style="font-size: .9em; margin-bottom: 7px !important; font-weight: bold;">
                                                    {{dato.dproducto}}</p>
                                            </div>
                                            <div class="form-row">
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.MOriginal}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        ${{dato.capital
                                                        |
                                                        cantidadMonetaria}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.VPagar}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        ${{dato.valorpagar
                                                        | cantidadMonetaria}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.FproxPago}}</p>
                                                </div>
                                                <div class="col-6"
                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)"
                                                    style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        {{dato.proxpago |
                                                        formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                                                </div>
                                            </div>
                                        </mat-card>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </section>
                </div>

                <div *ngIf="bandsFuncion['3']">
                    <section class="policy-flechas">
                        <div *ngIf="prestamosA.length > 0" style="padding-top: 3px;">
                            <owl-carousel-o [options]="customCarouselOptions">
                                <ng-container *ngFor="let dato of prestamosA">
                                    <ng-template carouselSlide>
                                        <mat-card class="cards-mobile-accounts">
                                            <div class="form-row">
                                                <p *ngIf="dato.cproducto != '06'" class="col-8 color-letras-cartas"
                                                    style="text-decoration: underline;cursor: pointer; font-size: 1.1em;"
                                                    (click)="mostrarTransaccionesPre(dato.spersonacreditospreaprobados,dato.subsistema,'07',false)">
                                                    {{idiomas.PrestamoPreaprobadoT | uppercase}}</p>
                                                <p *ngIf="dato.cproducto == '06'" class="col-8 color-letras-cartas"
                                                    style="text-decoration: underline;cursor: pointer; font-size: 1.1em;"
                                                    (click)="mostrarTransaccionesPre(dato.spersonacreditospreaprobados,dato.subsistema,'07',false)">
                                                    {{dato.cuenta}}</p>
                                                <div class="col-2" style="text-align: end;">
                                                    <div class="color-letras-cartas">
                                                        <mat-icon style="cursor: pointer !important; font-size: 30px;"
                                                            (click)="enviarRespuestaN(dato.spersonacreditospreaprobados)">
                                                            clear</mat-icon>
                                                    </div>
                                                </div>
                                                <div class="col-2" style="text-align: end;">
                                                    <div class="color-letras-cartas"
                                                        style="padding-bottom: 10px !important;">
                                                        <mat-icon style="cursor: pointer !important; font-size: 30px;"
                                                            (click)="mostrarTransaccionesPre(dato.spersonacreditospreaprobados,dato.subsistema,'07',false)">
                                                            download_done</mat-icon>
                                                    </div>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.Montomin}}</p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        ${{dato.montodesde
                                                        |
                                                        cantidadMonetaria}}</p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.Montomax}}</p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        ${{dato.montohasta
                                                        | cantidadMonetaria}}</p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.Plazomin}}</p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        {{(dato.plazodesde/30) | plazoMeses}}
                                                    </p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.Tasa}}</p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        %{{dato.tasa}}
                                                    </p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;">
                                                        {{idiomas.FvigenciaC}}</p>
                                                </div>
                                                <div class="col-6" style="height: fit-content !important;">
                                                    <p style="font-size: .9em; margin-bottom: 0;"
                                                        class="color-letras-cartas">
                                                        {{dato.fvigencia}}</p>
                                                </div>
                                            </div>
                                        </mat-card>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <p style="font-size: 10px; color: #0069b2;">{{idiomas.UltimaConexion}} {{lastLogin}}</p>
        </div>
    </div>

    <div style="padding-bottom: 10px !important;" *ngIf="bandMobile && skinCompact">
        <div id="tabContainer">
            <div class="e-content">
                <div class="center col texto">
                    <h6 class="posCon" style="padding-top: 10px;">
                        <strong>{{idiomas.MisCuentas | uppercase}}</strong>
                    </h6>
                </div>
                <section class="policy-flechas-compact">
                    <div *ngIf="ahorros.length > 0">
                        <owl-carousel-o [options]="customCarouselOptionsCompact">
                            <ng-container *ngFor="let dato of ahorros">
                                <ng-template carouselSlide>
                                    <mat-card class="cards-mobile-accounts"
                                        style="padding: 0px 0px 0px 5px !important;">
                                        <div class="form-row">
                                            <div class="col-1 card-header-compact" *ngIf="locale=='es'">
                                                <div>A</div>
                                                <div>H</div>
                                                <div>O</div>
                                                <div>R</div>
                                                <div>R</div>
                                                <div>O</div>
                                                <div>S</div>
                                            </div>
                                            <div class="col-1 card-header-compact" *ngIf="locale!='es'">
                                                <div>S</div>
                                                <div>A</div>
                                                <div>V</div>
                                                <div>I</div>
                                                <div>N</div>
                                                <div>G</div>
                                                <div>S</div>
                                            </div>
                                            <div class="col-11" style="padding: 10px 10px 5px 10px;">
                                                <div class="form-row">
                                                    <div class="col-10">
                                                        <strong>
                                                            <p
                                                                style="font-size: .9em; height: fit-content !important; margin-bottom: 1px !important;">
                                                                {{dato.dproducto}}
                                                            </p>
                                                        </strong>
                                                    </div>
                                                    <div class="col-2" style="text-align: end;">
                                                        <mat-icon class="color-letras-cartas"
                                                            style="cursor: pointer !important; font-size: 20px; height: 20px;width: 20px;"
                                                            [matMenuTriggerFor]="menu">
                                                            more_vert
                                                        </mat-icon>
                                                    </div>
                                                    <mat-menu #menu="matMenu">
                                                        <a mat-menu-item title={{idiomas.DetalleTransferencialle}}
                                                            (click)="mostrarDialogo(dato,'04')"
                                                            style="cursor: pointer !important; vertical-align: text-bottom;">
                                                            <mat-icon>library_books</mat-icon>{{idiomas.detalleMovTC}}</a>
                                                        <a *ngIf="dato.subsistema=='04' && (dato.cproducto == '406' || dato.cproducto == '413')"
                                                            mat-menu-item title={{idiomas.Compartir}}
                                                            (click)="shareAccountData(dato)"
                                                            style="cursor: pointer !important; vertical-align: text-bottom;">
                                                            <mat-icon>share</mat-icon>{{idiomas.Compartir}}</a>
                                                    </mat-menu>
                                                    <a class="col-8 color-letras-cartas" style="font-size: .9em;">Nro.
                                                        {{dato.ccuenta |
                                                        ocultarCuentaPosTrans}}</a>
                                                    <div class="col-4">
                                                        <p style="font-size: 1.1em; margin-bottom: 0 !important;"
                                                            class="color-letras-cartas">
                                                            ${{dato.available |
                                                            cantidadMonetaria}}</p>
                                                    </div>
                                                    <div class="col-12" style="padding-top: 5px; padding-bottom: 5px;">
                                                        <mat-divider style="border-top-width: 2px;"></mat-divider>
                                                    </div>
                                                    <div class="col-6" style="height: fit-content !important;"
                                                        *ngIf="dato.subsistema=='04' && (dato.cproducto == '406' || dato.cproducto == '413')">
                                                        <div class="form-row">
                                                            <div class="col-2">
                                                                <mat-icon color="primary"
                                                                    (click)="mostrarTranferencias(dato.ccuenta)">loop</mat-icon>
                                                            </div>
                                                            <div class="col-10 color-letras-cartas">
                                                                <a style="font-size: .9em !important;"
                                                                    (click)="mostrarTranferencias(dato.ccuenta)">{{idiomas.transfererir}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6" style="height: fit-content !important;">
                                                        <div class="form-row">
                                                            <div class="col-2">
                                                                <mat-icon color="primary"
                                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">compare_arrows</mat-icon>
                                                            </div>
                                                            <div class="col-10 color-letras-cartas">
                                                                <a style="font-size: .9em !important;"
                                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">Transacciones</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </section>
                <section class="policy-flechas-compact">
                    <div *ngIf="inversiones.length > 0">
                        <owl-carousel-o [options]="customCarouselOptionsCompact">
                            <ng-container *ngFor="let dato of inversiones">
                                <ng-template carouselSlide>
                                    <mat-card class="cards-mobile-accounts"
                                        style="padding: 0px 10px 0px 5px !important;">
                                        <div class="form-row">
                                            <div class="col-1 card-header-compact">
                                                <div style="padding-top: 30% !important;" *ngIf="locale=='es'">
                                                    <div>P</div>
                                                    <div>L</div>
                                                    <div>A</div>
                                                    <div>Z</div>
                                                    <div>O</div>
                                                    <div>S</div>
                                                </div>
                                                <div style="padding-top: 50% !important;" *ngIf="locale!='es'">
                                                    <div>T</div>
                                                    <div>E</div>
                                                    <div>R</div>
                                                    <div>M</div>
                                                    <div>S</div>
                                                </div>
                                            </div>
                                            <div class="col-11" style="padding: 10px 10px 5px 10px;">
                                                <div class="form-row">
                                                    <div class="col-8">
                                                        <strong>
                                                            <p
                                                                style="font-size: .9em; height: fit-content !important; margin-bottom: 1px !important;">
                                                                {{dato.dproducto}}
                                                            </p>
                                                        </strong>
                                                    </div>
                                                    <div class="col-4 color-letras-cartas" style="text-align: end;">
                                                        <a style="font-size: .7em;">
                                                            {{dato.fvencimiento |
                                                            formatoFecha:idiomas.IdiomaSeleccionado}}</a>
                                                    </div>
                                                    <a class="col-8 color-letras-cartas" style="font-size: .9em;">Nro.
                                                        {{dato.ccuenta |
                                                        ocultarCuentaPosTrans}}</a>
                                                    <div class="col-4">
                                                        <p style="font-size: 1.1em; margin-bottom: 0 !important; text-align: right;"
                                                            class="color-letras-cartas">
                                                            ${{dato.monto |
                                                            cantidadMonetaria}}</p>
                                                    </div>
                                                    <div class="col-12" style="padding-top: 5px; padding-bottom: 5px;">
                                                        <mat-divider style="border-top-width: 2px;"></mat-divider>
                                                    </div>
                                                    <div class="col-6" style="height: fit-content !important;">
                                                        <div class="form-row">
                                                            <div class="col-2">
                                                                <mat-icon color="primary"
                                                                    (click)="mostrarDialogo(dato,'05')">library_books</mat-icon>
                                                            </div>
                                                            <div class="col-10 color-letras-cartas">
                                                                <a style="font-size: .9em !important;"
                                                                    (click)="mostrarDialogo(dato,'05')">{{idiomas.detalleMovTC}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6" style="height: fit-content !important;">
                                                        <div class="form-row">
                                                            <div class="col-2">
                                                                <mat-icon color="primary"
                                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">compare_arrows</mat-icon>
                                                            </div>
                                                            <div class="col-10 color-letras-cartas">
                                                                <a style="font-size: .9em !important;"
                                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">Transacciones</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </section>
                <section class="policy-flechas-compact">
                    <div *ngIf="prestamos.length > 0">
                        <owl-carousel-o [options]="customCarouselOptionsCompact">
                            <ng-container *ngFor="let dato of prestamos">
                                <ng-template carouselSlide>
                                    <mat-card class="cards-mobile-accounts"
                                        style="padding: 0px 10px 0px 5px !important;">
                                        <div class="form-row">
                                            <div class="col-1 card-header-compact">
                                                <div *ngIf="locale=='es'">
                                                    <div>P</div>
                                                    <div>R</div>
                                                    <div>E</div>
                                                    <div>S</div>
                                                    <div>T</div>
                                                    <div>A</div>
                                                    <div>M</div>
                                                    <div>O</div>
                                                    <div>S</div>
                                                </div>
                                                <div style="padding-top: 25px !important;" *ngIf="locale!='es'">
                                                    <div>L</div>
                                                    <div>O</div>
                                                    <div>A</div>
                                                    <div>N</div>
                                                    <div>S</div>
                                                </div>
                                            </div>
                                            <div class="col-11" style="padding: 10px 10px 5px 10px;">
                                                <div class="form-row">
                                                    <div class="col-8">
                                                        <strong>
                                                            <p
                                                                style="font-size: .9em; height: fit-content !important; margin-bottom: 1px !important;">
                                                                {{dato.dproducto}}
                                                            </p>
                                                        </strong>
                                                    </div>
                                                    <div class="col-4 color-letras-cartas" style="text-align: end;">
                                                        <a style="font-size: .7em;">
                                                            {{dato.fvencimiento |
                                                            formatoFecha:idiomas.IdiomaSeleccionado}}</a>
                                                    </div>
                                                    <a class="col-8 color-letras-cartas" style="font-size: .9em;">Nro.
                                                        {{dato.ccuenta |
                                                        ocultarCuentaPosTrans}}</a>
                                                    <div class="col-4">
                                                        <p style="font-size: 1.1em; margin-bottom: 0 !important; text-align: right;"
                                                            class="color-letras-cartas">
                                                            ${{dato.capital |
                                                            cantidadMonetaria}}</p>
                                                    </div>

                                                    <div class="col-4" style="height: fit-content !important;">
                                                        <p style="font-size: .8em; margin-bottom: 0;">
                                                            {{idiomas.FproxPago}}</p>
                                                    </div>
                                                    <div class="col-4" style="height: fit-content !important;">
                                                        <p style="font-size: .8em; margin-bottom: 0;"
                                                            class="color-letras-cartas">
                                                            {{dato.proxpago |
                                                            formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                                                    </div>
                                                    <div class="col-4" style="height: fit-content !important;">
                                                        <p style="font-size: .9em; margin-bottom: 0; text-align: right;"
                                                            class="color-letras-cartas">
                                                            ${{dato.valorpagar
                                                            | cantidadMonetaria}}</p>
                                                    </div>
                                                    <div class="col-12" style="padding-top: 5px; padding-bottom: 5px;">
                                                        <mat-divider style="border-top-width: 2px;"></mat-divider>
                                                    </div>
                                                    <div class="col-6"
                                                        style="height: fit-content !important; padding-top: 5px;">
                                                        <div class="form-row">
                                                            <div class="col-2">
                                                                <mat-icon color="primary"
                                                                    (click)="mostrarDialogo(dato,'06')">library_books</mat-icon>
                                                            </div>
                                                            <div class="col-10 color-letras-cartas">
                                                                <a style="font-size: .9em !important;"
                                                                    (click)="mostrarDialogo(dato,'06')">{{idiomas.detalleMovTC}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6"
                                                        style="height: fit-content !important; padding-top: 5px;">
                                                        <div class="form-row">
                                                            <div class="col-2">
                                                                <mat-icon color="primary"
                                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">compare_arrows</mat-icon>
                                                            </div>
                                                            <div class="col-10 color-letras-cartas">
                                                                <a style="font-size: .9em !important;"
                                                                    (click)="mostrarTransacciones(dato.ccuenta,dato.subsistema,dato.grupo)">Transacciones</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </section>
            </div>
        </div>
        <div *ngIf="intentoAviso" style="padding-top: 10px; transition-delay: 5s;">
            <div *ngIf="datosPrestamosAA.length > 0">
                <section class="policy-flechas" *ngFor="let dato of datosPrestamosAA">
                    <mat-card class="cards-mobile-accounts"
                        (click)="mostrarTransaccionesPre(dato.spersonacreditospreaprobados,dato.subsistema,'07',true)"
                        style="padding: 5px 10px 5px; background-color: transparent !important;"
                        [ngStyle]="{'border-color':tema == 'night-theme' ? '#ffffff' : '#2675A9'}">
                        <div class="color-letras-cartas">
                            <p
                                style="font-size: .8em; margin-bottom: 0; text-align: justify; text-decoration: underline;cursor: pointer;">
                                {{idiomas.TienePreAproAnti}} ${{dato.montohasta |
                                cantidadMonetaria}}{{idiomas.TienePreAproAntiD}}
                                <mat-icon
                                    style="cursor: pointer !important; font-size: 18px !important; vertical-align: -webkit-baseline-middle;">
                                    arrow_forward_ios</mat-icon>
                            </p>
                        </div>
                    </mat-card>
                </section>
            </div>
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <p style="font-size: 10px; color: #0069b2;">{{idiomas.UltimaConexion}} {{lastLogin}}</p>
        </div>
    </div>
</div>
<div *ngIf="deshabilitar && !deshabilitarPre && !deshabilitarBeneficiarios">
    <div style="padding-bottom: 5px; padding-top: 5px;" *ngIf="bandMobile">
        <button mat-raised-button (click)="retornar()" title={{idiomas.Regresar}}
            style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
            color="primary" class="buttonBene">
            <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
            <strong>{{idiomas.RegresarIni}}</strong>
        </button>
    </div>
    <app-transacciones (mostrarSaldos04)="mostrarSaldosDesdeTx($event)"
        [deshabilitarTransacciones]="deshabilitarTransacciones" [deshabilitarBloqueos]="deshabilitarBloqueos"
        [deshabilitarConfirmar]="deshabilitarConfirmar" [deshabilitarPignorados]="deshabilitarPignorados"
        [parametros]="parametros" [data]="datos3" [txDesdeTx]="txDesdeTx" [idiomas]="idiomas" [mediano]="mediano"
        [normal]="normal" [grande]="grande" [isMobile]="bandMobile"></app-transacciones>
    <div class="float-transacciones" *ngIf="!bandMobile">
        <button mat-fab (click)="retornar()" title={{idiomas.Regresar}} class="float-right" color="primary">
            <mat-icon class="white">arrow_back</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="deshabilitar && deshabilitarPre && !deshabilitarBeneficiarios">
    <div class="example-form" style="padding-top: 2% !important; padding-bottom: 60px;" fxLayout="column"
        fxLayoutAlign="start stretch">
        <mat-card class="cards-transations" *ngIf="!bandMobile">
            <div style="padding:16px">
                <div class="center col texto">
                    <h6 class="posCon-header">
                        <strong *ngIf="!intentoPre">{{idiomas.PrestamoPreaprobado | uppercase}}</strong>
                        <strong *ngIf="intentoPre">{{idiomas.TienePreAproAntiTit | uppercase}}</strong>
                    </h6>
                </div>
                <app-transacciones-preaprobado [deshabilitarTransacciones]="deshabilitarTransacciones"
                    [parametros]="parametros" [data]="datos3" [txDesdeTx]="txDesdeTx" [idiomas]="idiomas"
                    [mediano]="mediano" [normal]="normal" [grande]="grande">
                </app-transacciones-preaprobado>
                <div class="float-transacciones">
                    <button mat-fab (click)="retornar()" title={{idiomas.Regresar}} class="float-right" color="primary">
                        <mat-icon class="white">arrow_back</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card>
        <div *ngIf="bandMobile">
            <div style="padding-bottom: 5px; padding-top: 5px;">
                <button mat-raised-button (click)="retornarMob()" title={{idiomas.Regresar}}
                    style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
                    color="primary" class="buttonBene">
                    <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
                    <strong>{{idiomas.RegresarIni}}</strong>
                </button>
            </div>
            <div class="center col texto">
                <h6 class="posCon-header">
                    <strong *ngIf="!intentoPre">{{idiomas.PrestamoPreaprobado | uppercase}}</strong>
                    <strong *ngIf="intentoPre">{{idiomas.TienePreAproAntiTit | uppercase}}</strong>
                </h6>
            </div>
            <app-transacciones-preaprobado [deshabilitarTransacciones]="deshabilitarTransacciones"
                [parametros]="parametros" [data]="datos3" [txDesdeTx]="txDesdeTx" [idiomas]="idiomas"
                [mediano]="mediano" [normal]="normal" [grande]="grande">
            </app-transacciones-preaprobado>
        </div>
    </div>
</div>
<div *ngIf="!deshabilitar && !deshabilitarPre && deshabilitarBeneficiarios">
    <app-beneficiarios #beneficiarios [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
        [pendingTaskData]="dataTarPend" [bandTarjetaCredito]="1">
    </app-beneficiarios>
</div>