import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Injectable()
export class FormControlValidators {

  constructor() {
  }

  idForm() {
    return new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$'), cedulaValidatorCore])
  }

  rucForm() {
    return new UntypedFormControl('', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[0-9]*$')])
  }

  pasForm() {
    return new UntypedFormControl('', [Validators.required])
  }

  comboForm() {
    return new UntypedFormControl('', Validators.required)
  }

  fechaForm() {
    let factual = new Date();
    let minDate = new Date(factual.getUTCFullYear(), factual.getUTCMonth(), factual.getUTCDate());
    return new UntypedFormControl(new Date(minDate), Validators.required);
  }

  textForm() {
    return new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ .]+$')])
  }

  textFormNewClient() {
    return new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ -]+$')]);
  }

  textNumberSpecialFeaturetForm() {
    return new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ .,!#$%&’*+/=?^_`{|}~-]+$')])
  }

  texNumberSpecialFeaturetForm1() {
    return new UntypedFormControl('', [Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ .,!#$%&’*+/=?^_`{|}~-]+$')])
  }

  texNumberSpecialFeaturetForm() {
    return new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ .,!#$;<>%&’*+/=?^_`{|}~-]+$')])
  }

  comboFormScoring() {
    return new UntypedFormControl({ value: '', disabled: true }, Validators.required,)
  }

  numberForm() {
    return new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')])
  }

  numberFormAmountAH(totalApagar) {
    return new UntypedFormControl('', [Validators.required, Validators.min(totalApagar), Validators.pattern('^[0-9]*$')])
  }

  numberFormFeeAH() {
    return new UntypedFormControl('', [Validators.required, Validators.min(6), Validators.max(60), Validators.pattern('^[0-9]*$')])
  }

  numberFormDayAH() {
    return new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(31), Validators.pattern('^[0-9]*$')])
  }

  telnumberForm1() {
    return new UntypedFormControl('', [Validators.minLength(7), Validators.maxLength(7), Validators.pattern('^[0-9]*$')])
  }

  celNumberForm() {
    return new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')])
  }

  celNumberForm1() {
    return new UntypedFormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')])
  }

  telCelNumberForm() {
    return new UntypedFormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern('^[0-9]*$')])
  }

  accountNumberForm() {
    return new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.pattern('^[0-9]*$')])
  }

  textNumberForm() {
    return new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')])
  }

  textNumberForm1() {
    return new UntypedFormControl('', [Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')])
  }

  textNumberFormNewClient() {
    return new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ -]+$')])
  }

  userForm() {
    return new UntypedFormControl(null, [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+$')])
  }

  newUserForm() {
    return new UntypedFormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern('^[A-Z0-9]+[A-Z]+[A-Z0-9]+$')])
  }

  passwordForm() {
    return new UntypedFormControl(null, Validators.required)
  }

  emailForm() {
    return new UntypedFormControl('', [Validators.required, Validators.email])
  }

  dateBirthForm() {
    return new UntypedFormControl('', Validators.required)
  }

  decimalsForm() {
    return new UntypedFormControl(null, [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$')])
  }

  decimalsDolarForm() {
    return new UntypedFormControl(null, [Validators.required, Validators.pattern('^[$]+[,0-9]+([.][0-9]{0,2})?$')])
  }

  decimalsFormPago(totalpagar) {
    return new UntypedFormControl('', [Validators.min(parseFloat("0.01")), Validators.max(parseFloat(totalpagar)), Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$')])
  }

  fechadForm() {
    let fechaActual = new Date();
    return new UntypedFormControl(new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1), [Validators.required])
  }

  otpForm() {
    return new UntypedFormControl(null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(6), Validators.maxLength(6)])
  }

  userMigratedForm() {
    return new UntypedFormControl(null, [Validators.required])
  }

  FormatNumber(value: any) {
    var monto = ''
    value = value.replace(/,/g, '')
    if (isNaN(value)) {
      monto = value
    } else {
      monto = parseFloat(value).toFixed(2)
      var datos = monto.split(".")
      var resultado = datos[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if (datos[1]) {
        resultado += "." + datos[1].substr(0, 3);
      }
      monto = resultado
    }
    if (monto == 'NaN') {
      monto = "";
    }
    return monto
  }

  FormatNumberZeroValidation(value: any) {
    var monto = ''
    value = value.replace(/,/g, '')
    if (isNaN(value)) {
      monto = value
    } else {
      monto = parseFloat(value).toFixed(2)
      var datos = monto.split(".")
      var resultado = datos[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if (datos[1]) {
        resultado += "." + datos[1].substr(0, 3);
      }
      monto = resultado
    }
    if (Number(monto) == 0 || monto == 'NaN') {
      monto = "";
    }
    return monto
  }
}

function cedulaValidator(control: UntypedFormControl) {
  let cedula = control.value;
  var total = 0;
  var longitud = 0;
  var longcheck = 0;
  if (cedula) {
    longitud = cedula.length;
    longcheck = longitud - 1;
  }
  if (cedula !== "" && longitud === 10) {
    for (let i = 0; i < longcheck; i++) {
      if (i % 2 === 0) {
        var aux = cedula.charAt(i) * 2;
        if (aux > 9) aux -= 9;
        total += aux;
      } else {
        total += parseInt(cedula.charAt(i)); // parseInt o concatenará en lugar de sumar
      }
    }

    total = total % 10 ? 10 - total % 10 : 0;

    if (cedula.charAt(longitud - 1) != total) {
      return {
        digitoVerificador: {
          parsedDomain: cedula
        }
      }
    }
  }
  return null;
}

function cedulaValidatorCore(control: UntypedFormControl) {
  var cedula = control.value
  try {
    if (cedula !== "" && cedula.length === 10) {
      var rut = cedula.substring(0, 9);
      var verificador = cedula.substring(9, 10);
      var count = 0;
      var count2 = 0;
      var factor = 2;
      var suma = 0;
      var sum = 0;
      var digito = 0;
      count2 = rut.length - 1;

      while (count < rut.length) {
        sum = factor * (parseInt(rut.substr(count2, 1), 10));
        if (sum > 9) {
          sum -= 9;
        }

        suma += sum;
        count++;
        count2--;
        if (factor == 1) {
          factor = 2;
        } else {
          factor = 1;
        }
      }

      digito = 10 - (suma % 10);
      digito = digito == 10 ? 0 : digito;

      if (digito != verificador) {
        return {
          digitoVerificador: {
            parsedDomain: cedula
          }
        }
      }
    }
  } catch (e) { }
  return null;
}

function rucValidator(control: UntypedFormControl) {
  let ced = control.value;
  let cedula = ced.slice(0, 10);
  let ruc = ced.slice(10, 13);

  var total = 0;
  var longitud = cedula.length;
  var longcheck = longitud - 1;

  if (cedula !== "" && longitud === 10) {
    for (let i = 0; i < longcheck; i++) {
      if (i % 2 === 0) {
        var aux = cedula.charAt(i) * 2;
        if (aux > 9) aux -= 9;
        total += aux;
      } else {
        total += parseInt(cedula.charAt(i)); // parseInt o concatenará en lugar de sumar
      }
    }

    total = total % 10 ? 10 - total % 10 : 0;

    if ((cedula.charAt(longitud - 1) != total) || (ruc != '001')) {
      return {
        digitoVerificador: {
          parsedDomain: cedula
        }
      }
    }
  }
  return null;
}

function rucValidatorCore(control: UntypedFormControl) {
  var ruc = control.value
  try {
    if (ruc !== "" && ruc.length > 10) {
      var modulo11 = new Array(9);
      var valorRetorno = true;
      var verif = parseFloat("0");
      if (parseInt(ruc.substring(0, 2), 10) < parseInt('1', 10) ||
        parseInt(ruc.substring(0, 2), 10) > parseInt('22', 10)) {
        valorRetorno = false;
      } else if (parseInt(ruc.substring(2, 3), 10) < parseInt('0', 10)
        || (parseInt(ruc.substring(2, 3), 10) > parseInt('6', 10)
          && parseInt(ruc.substring(2, 3), 10) != parseInt('9', 10))) {
        valorRetorno = false;
      } else {
        if (parseInt(ruc.substring(2, 3), 10) == parseInt('9', 10)) {
          if (ruc.substring(10, 13) != "001")
            valorRetorno = false;
          else {
            modulo11 = [4, 3, 2, 7, 6, 5, 4, 3, 2];
            for (var i = 0; i < 9; i++) {
              verif = verif + (parseFloat(ruc.substring(i, (i + 1)))
                * (parseFloat(modulo11[i])));
            }

            if (verif % 11 == 0) {
              valorRetorno = parseInt(ruc.substring(9, 10), 10) == 0;
            } else {
              valorRetorno = (11 - (verif % 11)) == parseInt(ruc.substring(9, 10), 10);
            }
          }
        } else if (parseInt(ruc.substring(2, 3), 10) == 6) {
          if (ruc.substring(10, 13) != "001") {
            valorRetorno = false;
          } else {
            modulo11 = [3, 2, 7, 6, 5, 4, 3, 2];
            for (var i = 0; i < 8; i++) {
              verif = verif + (parseFloat(ruc.substring(i, (i + 1)))
                * (parseFloat(modulo11[i])));
            }

            if (verif % 11 == 0) {
              valorRetorno = parseInt(ruc.substring(8, 9), 10) == 0;
            } else {
              valorRetorno = (11 - (verif % 11)) == parseInt(ruc.substring(8, 9), 10);
            }
          }
        } else if (parseInt(ruc.substring(2, 3), 10) < 6 &&
          parseInt(ruc.substring(2, 3), 10) >= 0) {
          if (ruc.substring(10, 13) != "001") {
            valorRetorno = false;
          } else {
            modulo11 = [2, 1, 2, 1, 2, 1, 2, 1, 2];
            for (var i = 0; i < 9; i++) {
              var temp = parseInt(ruc.substring(i, (i + 1)), 10) * parseInt((modulo11[i]), 10);
              if (temp > 9) {
                temp -= 9;
              }
              verif += temp;
            }
            if (verif % 10 == 0) {
              valorRetorno = parseInt(ruc.substring(9, 10), 10) == 0;
            } else {
              valorRetorno = (10 - (verif % 10)) == parseInt(ruc.substring(9, 10), 10);
            }
          }
        }
      }
      if (!valorRetorno) {
        return {
          digitoVerificador: {
            parsedDomain: ruc
          }
        }
      }
    }
  } catch (e) { }
  return null;
}
