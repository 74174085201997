import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatCheckboxModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, MatButtonModule, CommonModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, AlertasLoginComponent],
  selector: 'app-dialogo-seguro',
  templateUrl: './dialogo-seguro.component.html',
  styleUrls: ['./dialogo-seguro.component.css']
})
export class DialogoSeguroComponent {

  er: boolean = false;
  grande: boolean = false;
  mediano: boolean = false;
  normal: boolean = false;
  mensaje: string;
  aceptar: string;
  rechazar: string;
  idioma: any;
  imagen: any;
  intento: boolean;
  reqJSON: any;
  information: boolean;
  msjinformation: string;
  bandContract: boolean;
  error: boolean;
  msjerror: string;
  checkaceptar: boolean;
  loadContract: boolean = false;
  isContractDialog: boolean
  bandMobile: boolean;

  constructor(public base: BaseDatos, public apiService: ApiService, private dialogRef: MatDialogRef<DialogoSeguroComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.mensaje = data.mensaje;
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.isContractDialog = data.isContractDialog
    this.idioma = data.idm1;
    this.imagen = data.imagen;
    this.reqJSON = data.reqJSON;
    this.intento = false;
    this.information = false;
    this.msjinformation = '';
    this.msjerror = '';
    this.error = false;
    this.bandContract = false;
    this.checkaceptar = false;

    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true
    }

    if (window.innerWidth < 834) {
      this.bandMobile = true
    } else {
      this.bandMobile = false
    }

    //Se verifica si existe fontSize almacenado
    let fontSize = localStorage.getItem('fontSize');
    if (fontSize != null) {
      switch (fontSize) {
        case 'large':
          this.grande = true;
          break;
        case 'medium':
          this.mediano = true;
          break;
        case 'small':
          this.normal = true;
          break;
        default:
          this.normal = true;
          break;
      }
    } else {
      this.mediano = true;
    }
  }

  accept() {
    this.dialogRef.close(this.er = true);
  }
  close() {
    this.dialogRef.close(this.er);
  }

  aceptanceMessageContract() {
    this.dialogRef.close(!this.error);
  }

  contractLoaded() {
    this.loadContract = false;
  }

  sendAceptanceContract() {
    this.intento = true
    if (this.isContractDialog) {
      this.apiService.postProvider2InternetCheck('/enviarcontratohb', this.base.id_token, this.reqJSON).then((data: any) => {
        if (data) {
          this.error = false
          this.information = true
          this.msjinformation = data.mensaje
          this.bandContract = true
          this.intento = false
        }
      }, (err) => {
        this.intento = false
        this.information = false
        this.error = true
        this.msjerror = err.error.mensajeUsuario
        this.bandContract = true
      })
    } else {
      this.apiService.noTokenProviderInternetCheck(this.reqJSON, '/enviarcontratohb').then((data: any) => {
        if (data) {
          this.error = false
          this.information = true
          this.msjinformation = data.mensaje
          this.bandContract = true
          this.intento = false
        }
      }, (err) => {
        this.intento = false
        this.information = false
        this.error = true
        this.msjerror = err.error.mensajeUsuario
        this.bandContract = true
      })
    }
  }
}