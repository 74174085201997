import { Component, OnInit, Input, AfterViewInit, HostListener } from '@angular/core';
import { BaseDatos } from '../funciones/basededatos';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Servicios } from '../funciones/encryptar';
import { internetComponent } from '../funciones/internet';
import { FormControlValidators } from '../funciones/formcontrol';
import { Validators, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuncionesTabla } from '../funciones/funcionesTabla';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { PagoServiciosComponent } from '../pago-servicios/pago-servicios.component';
import { RecargasComponent } from '../recargas/recargas.component';
import { CurrencyInputDirective } from '../currency-input.directive';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatDividerModule, MatTableModule, MatListModule, MatProgressBarModule, MatCheckboxModule, MatInputModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, CurrencyInputDirective, FlexLayoutModule, TemporizadorComponent, PagoServiciosComponent, RecargasComponent],
  selector: 'app-consulta-servicios',
  templateUrl: './consulta-servicios.component.html',
  styleUrls: ['./consulta-servicios.component.css']
})
export class ConsultaServiciosComponent implements OnInit, AfterViewInit {

  //Inputs
  @Input() idiomas: any;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() dataTarPend: any;

  //Variables
  flechaR: boolean;
  flechaL: boolean;
  numMaxRow: number;
  serviciosCad: any[] = [];
  idusuario: any;
  band: boolean = true;
  displayedColumns: string[] = ['Servicio', 'Suministro', 'Descripcion', 'Caducar'];
  datos2: any[];
  serviciob: string;
  tokenrecibido: boolean;
  servicios: any[];
  lfiltradoservicios: any[] = [];
  intento: boolean;
  showNuevoServicio: boolean;
  nombre: String;
  usuarioHB: String;
  expirar: boolean;
  intentot: boolean;
  bandBtnCaducar: boolean;
  tieneServicios: boolean = true;
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  bandMobile: boolean = false;
  bandsFuncion: any;
  resendPay: any;
  nomServ: string;
  disableButton: boolean = false;

  //FormControls
  servTipo = this.validators.comboForm();
  tokenForm = this.validators.otpForm();
  descripcionForm = new UntypedFormControl(null, Validators.required);
  suministroForm = this.validators.textNumberForm();

  constructor(private base: BaseDatos, private api: ApiService, public alerta: Alerta,
    private servicio: Servicios, public internet: internetComponent, private validators: FormControlValidators,
    private funcionesTabla: FuncionesTabla) {
    this.flechaR = false;
    this.flechaL = true;
    this.numMaxRow = 6;
    this.datos2 = [];
    this.intento = false;
    this.intentot = false;
    this.serviciob = "";
    this.tokenrecibido = false;
    this.servicios = [];
    this.showNuevoServicio = false;
    this.nombre = "";
    this.usuarioHB = "";
    this.expirar = false;
    this.bandBtnCaducar = false;
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.bandMobile = false;
    this.bandsFuncion = {
      'pagoServ': false,
      'recargas': false,
      'consultaServRec': true,
    };
  }

  ngOnInit() {
    this.bandMobile = false;
    this.bandsFuncion = {
      'pagoServ': false,
      'recargas': false,
      'consultaServRec': true,
    };
    this.consultarServicios();
    this.retornarServicios();
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    };
    this.bandMobile = isMobile();
  }

  ngAfterViewInit() {
    if (this.dataTarPend) {
      Promise.resolve(null).then(() => this.setValuesOfForm());
      Promise.resolve(null).then(() => this.showNuevoServicio = true);
    }
  }

  setValuesOfForm() {
    this.servTipo.setValue(this.dataTarPend.cservicio);
    this.serviciob = this.dataTarPend.dservicio;
    this.suministroForm.setValue(this.dataTarPend.suministro);
    this.descripcionForm.setValue(this.dataTarPend.descripcion);
  }

  showHideRows(last) {
    if (last) {
      try {
        this.funcionesTabla.DivHeight(this.datos2);
        let classcontainer2Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('tablaServicios')[0].setAttribute('style', 'width: 700px');
          if (700 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:150px');
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('tablaServicios')[0].setAttribute('style', 'width: 100%');
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('tablaServicios')[0].setAttribute('style', 'width: 600px');
          if (600 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:115px');
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('tablaServicios')[0].setAttribute('style', 'width: 100%');
          }
        }
        if (this.normal) {
          document.getElementsByClassName('tablaServicios')[0].setAttribute('style', 'width: 500px; font-size: 14px');
          if (500 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:90px');
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('tablaServicios')[0].setAttribute('style', 'width: 100%; font-size: 14px');
          }
        }
      } catch (e) {
      }
    }
  }

  scroll(id) {
    if (id == 'tablaServicios') {
      var container1 = document.getElementsByClassName('container2Aux');
    }
    var tabla = document.getElementsByClassName(id);
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 2 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase);
    if (clase == 'tablaServicios') {
      let container1 = document.getElementsByClassName('container2Aux');
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth;
      } else {
        container1.item(0).scrollLeft = 0;
        this.flechaL = true;
        this.flechaR = false;
      }
    }
  }

  caducarServicios() {
    this.bandBtnCaducar = false;
    this.expirar = true;
    let algosecaduca = false;
    this.idusuario = this.base.usuario;
    this.nombre = this.base.nombreUsuario;
    this.usuarioHB = this.base.usuarioHB;
    this.serviciosCad = [];
    var cont = 0;
    var envio = {
      transaccion: '184025-man-in.xml',
      usuario: this.base.usuario
    };
    var cont = 0;
    for (const serv of this.datos2) {
      if (serv.caducar) {
        envio['rec^tpsc0^' + cont + '^FINGRESO'] = serv.fregistro;
        envio['rec^tpsc0^' + cont + '^NUMEROCONTRATO'] = serv.suministro;
        envio['rec^tpsc0^' + cont + '^COMENTARIOS'] = serv.descripcion;
        envio['rec^tpsc0^' + cont + '^CPERSONA'] = "$personCode";
        envio['rec^tpsc0^' + cont + '^CSERVICIO'] = serv.cservicio;
        envio['rec^tpsc0^' + cont + '^SPERSONASERVICIOCONTRATO'] = serv.spersonaserviciocontrato;
        envio['ctl^CUSUARIOREAL'] = this.idusuario;
        envio['ctl^_ClientName'] = this.nombre;
        envio['ctl^USR'] = this.idusuario;
        envio['ctl^CPERSONA_USUARIO'] = "$personCode";
        cont = cont + 1;
        algosecaduca = true;
      }
    }
    this.api.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
      this.expirar = false;
      this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase());
      this.consultarServicios();
    }, (err) => {
      this.expirar = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
    if (!algosecaduca) {
      this.expirar = false;
      this.alerta.presentarAlertaX(this.idiomas.NadaCadServicio.toUpperCase());
      this.intento = false;
    }
  }

  consultarServicios() { //Servicios Registrados por Usuario
    this.intento = true;
    let envio = { transaccion: "184025-con-in.xml", alias: "tpsc0", "cri^tu0^CUSUARIO^JOIN": "$userCode" };
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
      this.datos2 = [];
      if (data.length == 0) {
        this.tieneServicios = false;
      } else {
        this.tieneServicios = true;
        for (var i = 0; i < data.length; i++) {
          let temReg = {
            fregistro: data[i].FINGRESO,
            servicio: data[i].DESCRIPCION,
            suministro: data[i].NUMEROCONTRATO,
            descripcion: data[i].COMENTARIOS,
            cservicio: data[i].CSERVICIO,
            nombrescontrato: data[i].NOMBRESCONTRATO,
            spersonaserviciocontrato: data[i].SPERSONASERVICIOCONTRATO,
            caducar: false
          };
          this.datos2.push(temReg);
        }

        //Almacenar Consulta
        let consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.datos2,
        };

        //Almacena en BDD los servicios registrados
        this.base.insertarConsulta('ServiciosRegistrados', consulta);
      }
      this.intento = false;
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
      }
      else { //Sin Internet     
        this.band = true;
        this.serviciosRegOffline();
      }
    })
  }

  serviciosRegOffline() {
    let ObjConsulta = this.base.retornarConsulta('ServiciosRegistrados');
    if (!ObjConsulta || !(ObjConsulta.data.length > 0)) {
      this.band = false; //Oculta tabla y muestra carta de no servicios registrados
      this.tieneServicios = false;
    }
    else {
      this.datos2 = [];
      this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas);
      this.datos2 = ObjConsulta.data;
      this.tieneServicios = true;
    }
  }

  close() {
    this.nuevoServicio();
    this.tokenrecibido = false;
    this.limpiar();
    this.mostrarCron = false;
    this.enableCronoIn = false;
  }

  limpiar() {
    this.intentot = false;
    this.intento = false;
    this.servTipo = this.validators.comboForm();
    this.tokenForm = this.validators.numberForm();
    this.descripcionForm = new UntypedFormControl(null, Validators.required);
    this.suministroForm = this.validators.textNumberForm();
    this.serviciob = '';
    this.mostrarCron = false;
    this.enableCronoIn = false;
  }

  logout() {
    let obj = {
      'salir': true
    };
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  valorTipo(valor) {
    for (const iterator of this.servicios) {
      if (valor == iterator.id) {
        this.serviciob = iterator.descripcion;
        break;
      }
    }
  }

  applyFilter(filterValue: any) {
    this.servicios = this.lfiltradoservicios.filter((servicio) => (servicio.id + servicio.descripcion).indexOf(filterValue) > -1);
  }

  solToken() {
    this.intentot = true;
    const envio = { transaccion: '183212' };
    this.api.postProvider2InternetCheck('/clavetemporal', this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intentot = false;
        this.tokenrecibido = true;
        this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
        setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
      }
    }, (err) => {
      this.intentot = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  registrarServicio() {
    this.intentot = true;
    this.disableButton = true;
    if (this.internet.internet) {
      var envio = {
        otp: this.servicio.toAES(this.tokenForm.value),
        desencriptar: "1",
        cservicio: this.servTipo.value,
        dservicio: this.serviciob,
        suministro: this.suministroForm.value,
        descripcion: this.descripcionForm.value.toUpperCase(),
        'tarPendType': 'regServ'
      };
    }
    this.api.postProvider2InternetCheck('/ingresoservicio', this.base.id_token, envio).then((data: any) => {
      this.intentot = false;
      this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase());
      this.consultarServicios();
      this.showNuevoServicio = false;
      this.close();
      this.mostrarCron = false;
      this.enableCronoIn = false;
    }, (err) => {
      this.intentot = false;
      this.mostrarCron = false;
      this.enableCronoIn = false;
      this.disableButton = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.showNuevoServicio = true;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
          else {
            this.alerta.presentarAlertaX(err.error.mensaje);
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
        let envio1 = {
          usuario: this.base.usuario,
          usuarioHB: this.base.usuarioHB,
          desencriptar: "1",
          cpersona: this.base.cpersona,
          nombreUsuario: this.base.nombreUsuario,
          cservicio: this.servTipo.value,
          dservicio: this.serviciob,
          suministro: this.suministroForm.value,
          descripcion: this.descripcionForm.value.toUpperCase(),
          compania: this.base.compania,
          'tarPendType': 'regServ'
        };
        this.base.insertarTarea(envio1);
        this.alerta.presentarAlerta(this.idiomas.NoConectado);
        this.showNuevoServicio = false;
        this.close();
      }
    });
  }

  retornarServicios() { //Servicios Disponibles para Registrar
    let response = {
      id: '',
      descripcion: ''
    };
    let envio = {
      transaccion: '010003-lv-servicios.xml',
      alias: 'ts0'
    };
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
      if (data) {
        for (let servicio of data) {
          response.id = servicio.CSERVICIO;
          response.descripcion = servicio.DESCRIPCION;
          if (servicio.CSERVICIO)
            this.servicios.push(response);
          response = {
            id: '',
            descripcion: ''
          };
        }
        this.base.insertarConsulta('ServiciosDisponibles', this.servicios); //Inserta Lista de Servicios Disponibles
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.servicios = this.base.retornarConsulta('ServiciosDisponibles');
        if (!this.servicios) {
          this.servicios = [];
        }
      }
    });
  }

  resendTransfer(tx) {
    let resendTransferJSON = {
      numeroServicio: tx.cservicio,
      suministro: tx.suministro,
      resender: true
    };
    if (tx.nombrescontrato != "RECARGAS") {
      resendTransferJSON['tarPendType'] = 'pagoServ'
    };
    if (tx.nombrescontrato == "RECARGAS") {
      resendTransferJSON['tarPendType'] = 'recargas'
    };

    if (resendTransferJSON['tarPendType'] == 'pagoServ') {
      this.habilitarFunciones('pagoServ', resendTransferJSON, tx.nombrescontrato);
    }
    if (resendTransferJSON['tarPendType'] == 'recargas') {
      this.habilitarFunciones('recargas', resendTransferJSON, '');
    }
  }

  habilitarFunciones(funcionalidad, data, nombre) {
    this.resendPay = data;
    this.nomServ = nombre;
    this.bandsFuncion = {
      'pagoServ': false,
      'recargas': false,
      'consultaServRec': false,
    };
    if (this.bandMobile) {
      this.consultarServicios();
    }
    this.bandsFuncion[funcionalidad] = true;
    this.alerta.clearAlerta();
    window.scroll(0, 0);
  }

  nuevoServicio() {
    this.lfiltradoservicios = this.servicios;
    if (this.showNuevoServicio == true) {
      this.showNuevoServicio = false;
    } else {
      document.querySelector('.mat-sidenav-content').scrollTop = 0;
      this.showNuevoServicio = true;
    }
  }

  selectCheck() {
    var cont = 0;
    for (const bene of this.datos2) {
      if (bene.caducar) {
        cont = cont + 1;
      }
    }
    if (cont > 0) {
      this.bandBtnCaducar = true;
    }
    else {
      this.bandBtnCaducar = false;
    }
  }

  comaToDot(event) {
    if (event.key === ',') {
      event.target.value += '.';
      event.preventDefault();
    }
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace("$", '').replace(/\,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0];
          let decimalPart = amountArrayDotSplit[1];
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }
}
