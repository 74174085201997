import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

const VAPID_PLUBLIC_KEY = "BEPNEsRyUXwkIUa1sgChL1fg6gZrX0Re__T2cZy9SKCbQBuyGxFL01gcqqhUOZnSX-xynrmKFvzJuBs9hyQ38HE"
const API_URL = 'https://pwapp-crm.fit-bank.com/api/enviar'

@Injectable()
export class NotificationService {

    response: any;

    constructor(private http: HttpClient) {

    }

    createHeader() {
        var header = new HttpHeaders();
        header = header.set('Content-Type', 'application/json');
        header = header.set("Access-Control-Allow-Origin", "*");
        header = header.set("Access-Control-Allow-Methods", "POST, GET, OPTIONS")
        return header
    }

    subscribeToNotifications() {
        return new Promise((resolve, reject) => {
            navigator.serviceWorker.register('/ngsw-worker.js').then((serviceWorkerRegistration) => {
                serviceWorkerRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: VAPID_PLUBLIC_KEY
                }).then(data => {
                    resolve(data)
                }, err => {
                    reject(err)
                })
            })
        })
    }

    sendNotification(localUrl, value) {
        return new Promise((resolve, reject) => {
            this.http.post(API_URL + localUrl, value, {
                headers: this.createHeader()
            }).subscribe(
                data => {
                    resolve(data)
                }, err => {
                    reject(err);
                })
        })
    }
}