import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule, MatCheckboxModule, NgxExtendedPdfViewerModule, MatProgressBarModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, AlertasLoginComponent],
  selector: 'app-dialogo-mostrar-pdf',
  templateUrl: './dialogo-mostrar-pdf.component.html',
  styleUrls: ['./dialogo-mostrar-pdf.component.css']
})
export class DialogoMostrarPdfComponent {
  er: boolean = false;
  intento: boolean;

  idiomas: any;
  base64URL: any;
  information: boolean;
  msjinformation: string;
  bandContract: boolean;
  error: boolean;
  msjerror: string;
  checkaceptar: boolean;

  constructor(
    private api: ApiService,
    private base: BaseDatos,
    public internet: internetComponent,
    public mostrarPdf: MostrarPDF,
    private dialogRef: MatDialogRef<DialogoMostrarPdfComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.idiomas = data.idm;
    this.base64URL = data.base64URL;
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    this.checkaceptar = false;
  }

  close() {
    this.dialogRef.close(this.er);
  }

  sendAceptanceContract() {
    let date = new Date()
    let faceptacion = date.getFullYear() + "-" + this.anadirCero((date.getMonth() + 1)) + "-" + this.anadirCero(date.getDate()) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    this.intento = true;
    let envio = {
      'faceptacion': faceptacion
    }

    this.api.postProvider2InternetCheck('/aceptacionAmpliacionContrato', this.base.id_token, envio).then((data: any) => {
      this.intento = false
      this.dialogRef.close(this.er = true);
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  logout() {
    let obj = {
      'salir': true
    }
  }

}
