import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { FormControlValidators } from '../funciones/formcontrol';
import { internetComponent } from '../funciones/internet';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';

@Component({
  selector: 'app-calificanos',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatInputModule, TextFieldModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  templateUrl: './calificanos.component.html',
  styleUrl: './calificanos.component.css'
})
export class CalificanosComponent {

  @Input('rating') rating: number = 1;
  @Input('starCount') starCount: number = 5;
  @Input('color') color: string = 'accent';

  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  bandMobile: boolean;
  intento: boolean;

  constructor(public alerta: Alerta, public basedatos: BaseDatos, public apiService: ApiService,
    private validators: FormControlValidators, public internetC: internetComponent, public servicios: Servicios) {
    this.bandMobile = false;
  }

  observacionRating = this.validators.texNumberSpecialFeaturetForm()
  ratingArr = [];

  ngOnInit(): void {
    this.bandMobile = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }


  async ngAfterViewInit() {
    Promise.resolve(null).then(async () => {
      this.ratingArr = [];
      for (let index = 0; index < this.starCount; index++) {
        this.ratingArr.push(index);
      }
    });
  }

  calificarHB() {
    this.intento = true;
    let envio = {
      transaccion: '183073-man-rating.xml',
    }
    envio['rec^tci0^0^CUSUARIO^tci0'] = "$userCode"
    envio['rec^tci0^0^FCALIFICACION^tci0'] = this.getCurrentTimeAsTimestamp()
    envio['rec^tci0^0^CALIFICACION^tci0'] = this.rating
    envio['rec^tci0^0^OBSERVACION^tci0'] = this.observacionRating.value.toUpperCase()
    envio['rec^tci0^0^SESION^tci0'] = "$session"

    this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, true, false).then((data) => {
      if (data) {
        this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
          this.intento = false
          this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, false, true)
          this.starCount = 5;
          this.rating = 1
          this.observacionRating.reset()
        }, (err) => {
          this.intento = false
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
            }
            else {
            }
          }
          else { //Sin Internet
            this.intento = false
          }
        });
      } else {
        this.intento = false;
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString()
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date()
    const year = now.getFullYear()
    const month = this.completeDigits(now.getMonth() + 1)
    const day = this.completeDigits(now.getDate())

    const seconds = now.getSeconds();
    const minutes = now.getMinutes();
    const hour = now.getHours();
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  }

  onClick(rating: number) {
    this.rating = rating;
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

}

export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}
