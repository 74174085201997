<div class="textura">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <h2 mat-dialog-title *ngIf="producto =='04'" style="text-align: center;"><strong>{{idiomas.DetalleTransferencia
            | uppercase}} {{idiomas.CtasVista}}</strong></h2>
    <h2 mat-dialog-title *ngIf="producto =='05'" style="text-align: center;"><strong>{{idiomas.DetalleTransferencia
            | uppercase}} {{idiomas.CtasPlazo}}</strong></h2>
    <h2 mat-dialog-title *ngIf="producto =='06'" style="text-align: center;"><strong>{{idiomas.DetalleTransferencia
            | uppercase}} {{idiomas.CtasPrestamos}}</strong></h2>
    <mat-dialog-content>
        <div *ngIf="producto =='04'">
            <mat-card class="cardTitle">
                <div class="row">
                    <div class="col-4">
                        <b>{{idiomas.NCuenta}}</b>
                        <p>{{dato.ccuenta | ocultarCuentaPosTrans}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Producto}}</b>
                        <p>{{dato.dproducto}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Estado}}</b>
                        <p>{{dato.estado}}</p>
                    </div>
                </div>
            </mat-card>
            <mat-card class="cardBody" style="padding-top: 10px !important;">
                <div class="row">
                    <div class="col-6">
                        <b>{{idiomas.SDisponible}}</b>
                    </div>
                    <div class="col-6">
                        <p>{{dato.available | cantidadMonetaria}}</p>
                    </div>
                    <!--<div class="col-6">
                        <b>{{idiomas.Moneda}}</b>
                    </div>
                    <div class="col-6">
                        <p>{{dato.cmoneda}}</p>
                    </div>-->

                    <div class="col-6">
                        <b>{{idiomas.VConfirmar}}</b>
                    </div>
                    <div class="col-6">
                        <p *ngIf="showBlocks && dato.outstanding !='0'"
                            (click)="mostrarTxDesdeTx(dato.ccuenta,'77',dato.grupo)"
                            style="text-decoration: underline;cursor: pointer;">{{dato.outstanding | cantidadMonetaria}}
                        </p>
                        <p *ngIf="!showBlocks || dato.outstanding =='0'">{{dato.outstanding | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-6">
                        <b>{{idiomas.FCapitalizacion}}</b>
                    </div>
                    <div class="col-6">
                        <p>{{dato.fechaCap | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                    </div>

                    <div class="col-6">
                        <b>{{idiomas.VBloqueado}}</b>
                    </div>
                    <div class="col-6">
                        <p *ngIf="showBlocks && dato.blocked !='0'"
                            (click)="mostrarTxDesdeTx(dato.ccuenta,'33',dato.grupo)"
                            style="text-decoration: underline;cursor: pointer;">{{dato.blocked | cantidadMonetaria}}</p>
                        <p *ngIf="!showBlocks || dato.blocked =='0'">{{dato.blocked | cantidadMonetaria}}</p>
                    </div>

                    <!--<div class="col-6">
                        <b>{{idiomas.InteresPagar}}</b>
                    </div>
                    <div class="col-6">
                        <p>{{dato.interes | cantidadMonetaria}}</p>
                    </div>-->

                    <div class="col-6">
                        <b>{{idiomas.VPignorado}}</b>
                    </div>
                    <div class="col-6">
                        <p *ngIf="showBlocks && dato.pledged !='0'"
                            (click)="mostrarTxDesdeTx(dato.ccuenta,'99',dato.grupo)"
                            style="text-decoration: underline;cursor: pointer;">
                            {{dato.pledged | cantidadMonetaria}}</p>
                        <p *ngIf="!showBlocks || dato.pledged == '0'">{{dato.pledged | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-6">
                        <b>{{idiomas.Sobregiro}}</b>
                    </div>
                    <div class="col-6">
                        <p>{{dato.sobregiro | cantidadMonetaria}}</p>
                    </div>

                    <div class="col-6">
                        <b>{{idiomas.SaldoTotal}}</b>
                    </div>
                    <div class="col-6">
                        <p>{{dato.accounting | cantidadMonetaria}}</p>
                    </div>
                    <!--<div class="col-6">
                        <b>{{idiomas.Tasa}}</b>
                    </div>
                    <div class="col-6">
                        <p>{{dato.tasa | cantidadMonetaria}}%</p>
                    </div>-->
                </div>
            </mat-card>
        </div>
        <div *ngIf="producto =='05'">
            <mat-card class="cardTitle">
                <div class="row">
                    <div class="col-4">
                        <b>{{idiomas.NCuenta}}</b>
                        <p>{{dato.ccuenta | ocultarCuentaPosTrans}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Producto}}</b>
                        <p>{{dato.dproducto}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Estado}}</b>
                        <p>{{dato.estado}}</p>
                    </div>
                </div>
            </mat-card>
            <mat-card class="cardBody" style="padding-top: 10px !important;">
                <div class="row">
                    <div class="col-7">
                        <b>{{idiomas.Capitalpl}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.monto | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.FechaApertura}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.fapertura | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                    </div>

                    <div class="col-7">
                        <b>{{idiomas.Tasa}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.tasa | cantidadMonetaria}}%</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.FPago}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.fPago | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                    </div>

                    <div class="col-7">
                        <b>{{idiomas.CInteres}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.cuotaInteres | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.Vencimientopl}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.fvencimiento | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                    </div>

                    <div class="col-7">
                        <b>{{idiomas.Impuesto}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.impuestos | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.CPagadas}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.cPagadas}}</p>
                    </div>

                    <div class="col-7">
                        <b>{{idiomas.Plazo}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.plazo}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.CPagar}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.cPorPagar}}</p>
                    </div>

                    <div class="col-7">
                        <b>{{idiomas.InteresesT}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.interes | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.FrIntereses}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.frecInteres}}</p>
                    </div>
                </div>
            </mat-card>
        </div>
        <div *ngIf="producto =='06'">
            <mat-card class="cardTitle">
                <div class="row">
                    <div class="col-4">
                        <b>{{idiomas.NCuenta}}</b>
                        <p>{{dato.ccuenta | ocultarCuentaPosTrans}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Producto}}</b>
                        <p>{{dato.dproducto}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Estado}}</b>
                        <p>{{dato.estado}}</p>
                    </div>
                </div>
            </mat-card>
            <mat-card class="cardBody" style="padding-top: 10px !important;">
                <div class="row">
                    <!--<div class="col-7">
                        <b>{{idiomas.MOriginal}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.capital | cantidadMonetaria}}</p>
                    </div>-->
                    <div class="col-7">
                        <b>{{idiomas.SCapital}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.actualBal | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.FproxPago}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.proxpago | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.DiasMora}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.diasmora}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.SaldoV}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.saldoVencido | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.SIntereses}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.saldointeres | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.CMora}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.cuotasvencidas}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.Seguro}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.seguro | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.CporCobrar}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.cuentasPorCobrar | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.VPagar}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.valorpagar | cantidadMonetaria}}</p>
                    </div>

                    <!--<div class="col-7">
                        <b>{{idiomas.Plazo}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.plazo}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.FechaVencimiento}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.fvencimiento | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.Tasa}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.tasa | cantidadMonetaria}}%</p>
                    </div>
                    <div class="col-7">
                        <b>{{idiomas.Moneda}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.cmoneda}}</p>
                    </div>-->
                </div>
            </mat-card>
        </div>
        <div *ngIf="producto =='PRESTAMOPREAPROBADO'">
            <mat-card class="cardTitle">
                <div class="row">
                    <div class="col-4">
                        <b>{{idiomas.CredioPreaprobado}}</b>
                        <p>{{dato.estado=='A'?'':dato.montohasta |
                            cantidadMonetaria}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Producto}}</b>
                        <p>{{dato.cuenta}}</p>
                    </div>
                    <div class="col-4">
                        <b>{{idiomas.Estado}}</b>
                        <p>{{dato.estado}}</p>
                    </div>
                </div>
            </mat-card>
            <mat-card class="cardBody" style="padding-top: 10px !important;">
                <div class="row">
                    <div class="col-7">
                        <b>{{dato.estado=='A'?'':idiomas.MontoMaximo}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.estado=='A'?'':dato.montohasta |
                            cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{dato.estado=='A'?idiomas.Plazo:idiomas.PlazoMax}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{(dato.plazohasta/30) | plazoMeses}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{dato.estado=='A'?idiomas.Monto:idiomas.MontoMin}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.montodesde | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-7">
                        <b>{{dato.estado=='A'?'':idiomas.PlazoMin}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.estado=='A'?'':(dato.plazodesde/30)
                            |plazoMeses}}</p>
                    </div>

                    <div class="col-7">
                        <b>{{idiomas.Tasa}}</b>
                    </div>
                    <div class="col-5">
                        <p>{{dato.tasa | cantidadMonetaria}}</p>
                    </div>
                </div>
            </mat-card>
        </div>
    </mat-dialog-content>
</div>