import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-mensaje-alerta',
  templateUrl: './mensaje-alerta.component.html',
  styleUrls: ['./mensaje-alerta.component.css']
})
export class MensajeAlertaComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackBarRef: MatSnackBarRef<MensajeAlertaComponent>) { }

  close() {
    this.snackBarRef.dismiss();
  }
}
