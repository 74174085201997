<div class="textura; fontsize" [class.mediano]=mediano [class.grande]=grande>
    <mat-dialog-content>
        <mat-progress-bar *ngIf=intento mode="indeterminate"></mat-progress-bar>
        <div class="form-row" *ngIf="!bandCompra && !intento && !bandCompraOffline">
            <img class="col-5" *ngIf="imagen != '' && imagen != '0'"
                [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + imagen)" width="156"
                height="123" style="margin-bottom: 5px; max-width: 156px" />
            <img class="col-5" *ngIf="imagen == ''" src="assets/resources/mipmap-hdpi/cargando_imagen.gif" width="156"
                height="123" style="margin-bottom: 5px; max-width: 156px" />
            <img class="col-5" *ngIf="imagen == '0'"
                [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + imagenOffline)" width="156"
                height="123" style="margin-bottom: 5px; max-width: 156px" />
            <p class="col-7">{{descripcion}}</p>
            <div *ngIf="!bandCompra && !intento" class="col-10" style="max-width: 300px;">
                <mat-form-field appearance="outline" fxFlexFill>
                    <mat-label>{{idioma.Cuenta}}</mat-label>
                    <mat-select (selectionChange)="tomarValorCuenta($event.value)" [formControl]="cuentaForm">
                        <mat-option *ngFor="let cuenta of cuentas" [(value)]="cuenta.value">
                            {{cuenta.viewValue | cambiarVariableCuenta}} - {{cuenta.descProducto}}
                            <mat-divider style="border-top-width: 3px;"></mat-divider>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="!bandCompra && !intento" class="col-2" style="max-width: 85px;">
                <mat-form-field appearance="outline" fxFlexFill>
                    <mat-label>{{idioma.Cantidad}}</mat-label>
                    <input matInput (change)="multiplicador()" min="1" max="20" type="number" [(ngModel)]="cantidad">
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!bandCompra && !intento && !bandCompraOffline" class="form-row">
            <p class="col-6" style="margin-bottom: 5px"><b>{{idioma.CompraT}}</b></p>
            <p class="col-6" style="margin-bottom: 5px"><b>{{idioma.FrEntrega}}</b></p>
            <div class="col-6">
                <div class="row">
                    <p class="col-4" style="text-align: left; margin-bottom: 5px; margin-top: 8px">{{idioma.Contado}}
                    </p>
                    <p class="col-5"
                        style="font-size: 1.5rem; margin-bottom: 0px; min-width: 100px; text-align: end; margin-bottom:0px">
                        ${{precioc}}
                    </p>
                    <div class="col-3" style="padding: 0px; margin-top: 8px; padding-left: 15px;">
                        <mat-checkbox color="primary" value="opciones[0]" [checked]="opciones[0]"
                            (change)="opciones[0]=!opciones[0]; selectCheck('0')">
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <p class="col-6" style="margin-bottom: 5px; margin-top: 8px">{{idioma.RAlmacen}}</p>
                    <div class="col-3" style="padding: 0px; margin-top: 8px; padding-left: 15px">
                        <mat-checkbox color="primary" value="opciones[1]" [checked]="opciones[1]"
                            (change)="opciones[1]=!opciones[1]; selectCheck('1')">
                        </mat-checkbox>
                    </div>
                    <p class="col-3" style="padding: 0px"></p>
                </div>
            </div>
            <div class="col-6" *ngIf="false">
                <div class="row">
                    <p class="col-4" style="text-align: left; margin-bottom: 5px; min-width: 115px; margin-top: 8px">
                        {{idioma.Cuotas}}</p>
                    <p class="col-5" style="font-size: 1.5rem; margin-bottom: 0px; text-align: end; margin-bottom:0px">
                        ${{preciod}}
                    </p>
                    <div class="col-3" style="padding: 0px; margin-top: 8px; padding-left: 15px">
                        <mat-checkbox color="primary" value="opciones[2]" [checked]="opciones[2]"
                            (change)="opciones[2]=!opciones[2]; selectCheck('2')">
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <p class="col-6" *ngIf="true" style="margin-bottom: 5px">{{idioma.NCuotas}}</p>
            <div class="col-6">
                <div class="row">
                    <p class="col-6" style="margin-bottom: 5px; margin-top: 8px">{{idioma.EDomicilio}}</p>
                    <div class="col-1" style="padding: 0px; margin-top: 8px; padding-left: 15px">
                        <mat-checkbox color="primary" value="opciones[3]" [checked]="opciones[3]"
                            (change)="opciones[3]=!opciones[3]; selectCheck('3')" style="margin-right: 8px;">
                        </mat-checkbox>
                    </div>
                    <p class="col-5" style="font-size: 1.5rem; text-align: end;padding: 0px;
                    max-width: 80px; min-width: 80px; margin-bottom: 0px">+$2.00</p>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <p class="col-4" style="margin-bottom: 5px; margin-top: 8px; min-width: 100px;">{{idioma.Subtotal}}
                    </p>
                    <p class="col-5" style="font-size: 1.5rem; text-align: end; margin-bottom: 0px">
                        ${{subtotal}}</p>
                    <p class="col-3" style="padding: 0px"></p>
                </div>
                <div class="row">
                    <p class="col-4" style="margin-bottom: 5px; margin-top: 8px">{{idioma.IVA}}</p>
                    <p class="col-5" style="font-size: 1.5rem; text-align: end; margin-bottom: 0px">
                        12%</p>
                    <p class="col-3" style="padding: 0px"></p>
                </div>
                <div class="row">
                    <p class="col-4" style="margin-bottom: 5px; margin-top: 8px">{{idioma.TotalEc}}</p>
                    <p class="col-5" style="font-size: 1.5rem; text-align: end; margin-bottom: 0px; min-width: 100px">
                        ${{total}}</p>
                    <p class="col-3" style="padding: 0px"></p>
                </div>
            </div>
            <div *ngIf="opciones[3]" class="col-6">
                <div class="row" style="max-width: 300px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idioma.Direcciones}}</mat-label>
                        <mat-select (selectionChange)="tomarValorDireccion($event.value)" [formControl]="direccionForm">
                            <mat-option *ngFor="let direccion of direcciones" [(value)]="direccion.id">
                                {{direccion.descripcion}}
                                <mat-divider style="border-top-width: 3px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <mat-progress-bar *ngIf="intentoCompra" mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="bandCompra && !intentoCompra">
            <h2 style="text-align: center"><strong>{{idioma.MensajeCompra}}</strong></h2>
            <h2 style="text-align: center"><strong>{{idioma.MensajeToken}} {{token}}</strong></h2>
            <br>
            <p style="text-align: center">{{idioma.MensajeFactura}}<a style="text-decoration: underline; cursor:pointer"
                    (click)="reportePDF()">{{idioma.Aqui}}</a></p>
            <br>
            <p style="text-align: center"><strong>{{idioma.MensajeCedula}}</strong></p>
        </div>
        <br>
        <div *ngIf="error" class="col-12">
            <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
            <br>
        </div>
        <div *ngIf="bandCompraOffline">
            <div class="row">
                <div class="center col texto">
                    <mat-label>
                        <strong>{{idioma.TarOffline | uppercase}}</strong>
                    </mat-label>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="center col texto">
                    <mat-label>
                        {{idioma.NoConectadoEcomm | uppercase}}
                    </mat-label>
                </div>
            </div>
            <br>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!bandCompraOffline">
        <button *ngIf="!bandCompra" [disabled]="bandBtnCompra || (!opciones[1] && !opciones[3])" style="margin-right: 8px;" mat-raised-button
            (click)="buy()" color="primary" class="buttonSubmit">{{idioma.Ccompra}}</button>
        <button *ngIf="!bandCompra" mat-raised-button class="buttonCancel" color="warn" style="margin-left: 0px;"
            (click)="close()">{{idioma.Rechazar}}</button>
        <button *ngIf="bandCompra" class="buttonSubmit" mat-raised-button color="primary" (click)="close()">{{idioma.Cerrar}}</button>
    </mat-dialog-actions>
</div>