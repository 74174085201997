<div class="example-form" style="padding-bottom: 40px;" fxLayout="column" fxLayoutAlign="start stretch" paddin>
  <mat-card *ngIf="!internetC.internet" class="carta">
    <div class="center col-12 texto">
      <h6>
        <strong>{{idiomas.AlertaDes | uppercase}}</strong>
      </h6>
    </div>
  </mat-card>
  <mat-card class="cards-transations" *ngIf="!bandMobile">
    <div *ngIf="internetC.internet">
      <div *ngIf="containerPreguntas!=''" id="preguntas" fxLayout="column" fxLayoutAlign="start center">
        <div class="col card-header">
          <div class="center texto">
            <h6>
              <strong>{{idiomas.TituloPreguntas}}</strong>
            </h6>
          </div>
        </div>
        <div class="col" *ngIf=intentop>
          <br>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" style="padding: 16px;" *ngIf="!intentop">
          <div *ngFor="let pregunta of preguntas; let i = index">
            <mat-form-field appearance="outline" style="font-size: 1em !important;" fxFlexFill [floatLabel]="'always'"
              (keyup.enter)="usrEnter()">
              <mat-label>{{pregunta.descripcion}}</mat-label>
              <input matInput type="text" autocomplete="off" [formControl]="preguntasForm[i]" #input
                (input)="input.value=$event.target.value.toUpperCase()">
              <mat-error *ngIf="preguntasForm[i].hasError('required')">
                <strong>{{idiomas.msgPregunta}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="containerCambios" id="opcionesCambios">
        <div class="center" *ngIf="!deshabilitar">
          <div class="col card-header">
            <div style="text-align: center">
              <h6>
                <h6><strong>{{idiomas.Administracion | uppercase}}</strong></h6>
              </h6>
            </div>
          </div>
          <div class="row" style="margin-left: auto; margin-right: auto; margin-top: 10px;">
            <div class="col-4" [class.col-6]="bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="personalInformation()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">perm_contact_calendar</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.BtnInfoPersona}}
              </p>
            </div>
            <div class="col-4" [class.col-6]="bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="changePassword()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">password</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.BtnPassword}}
              </p>
            </div>
            <div hidden class="col-4" [class.col-6]="bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="changeEmail()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">contact_mail</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.BtnEmail}}</p>
            </div>
            <div class="col-4" [class.col-6]="bandMobile" *ngIf="isMobile && bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="registerBiometric()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">fingerprint</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.RegistrarHuellaB}}</p>
            </div>
            <div class="col-4" [class.col-6]="bandMobile" *ngIf="!isMobile && hasUnlinkMobile && !bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="unlinkBiometric()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">remove_from_queue</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.DesvincularHuellaB}}</p>
            </div>
            <div class="col-4" *ngIf="!deviceLinked && !bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="pinRegister()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">phonelink_setup</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;" *ngIf="!pinSaved && !deviceLinked">{{idiomas.RegSoftToken}}</p>
              <p style="font-size: .9em;" *ngIf="pinSaved">{{idiomas.CodigoQR}}</p>
            </div>
            <div class="col-4" *ngIf="!deviceLinked && pinSaved && !bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="deletePin()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">phonelink_erase</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.EliminarPin}}</p>
            </div>
            <div class="col-4" *ngIf="deviceLinked && !bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="unregisterDevice()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">phonelink_erase</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.DesSoftToken}}</p>
            </div>
          </div>
        </div>
        <div class="row" style="margin-right: 0px; margin-left: 0px;">
          <div class="center col" style="padding-left: 0px; padding-right: 0px;">
            <div *ngIf="changePwd">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.BtnPassword.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="text-align: center !important; padding: 16px;" *ngIf="!intento">
                <div *ngIf="mensajeContra" style="width: 100%">
                  <app-alertas-login [alertaloginpass]="true" [mensaje]="mensajeContra">
                  </app-alertas-login>
                  <br>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'" (keyup.enter)="pasEnter()">
                  <mat-label>{{idiomas.ContraActual}}</mat-label>
                  <input id="actualPwd" matInput [type]="hideActualPwd[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="actualPwd">
                  <div matSuffix (click)="hideActualPwd[0] = !hideActualPwd[0]"
                    [attr.aria-label]="'HideActualPwd password'" [attr.aria-pressed]="hideActualPwd[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalActualPwd()">{{hideActualPwd[0] ? 'visibility_off' :
                      'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="actualPwd.hasError('required')">
                    <strong>{{idiomas.msgConActual}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'" (keyup.enter)="pasEnter()">
                  <mat-label>{{idiomas.ContraNueva}}</mat-label>
                  <input id="newPwd" matInput [type]="hideNewPwd[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="newPwd">
                  <div matSuffix (click)="hideNewPwd[0] = !hideNewPwd[0]" [attr.aria-label]="'HideNewPwd password'"
                    [attr.aria-pressed]="hideNewPwd[0]" style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalNewPwd()">{{hideNewPwd[0] ? 'visibility_off' :
                      'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="newPwd.hasError('required')">
                    <strong>{{idiomas.msgConNueva}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'" (keyup.enter)="pasEnter()">
                  <mat-label>{{idiomas.ConfCoNueva}}</mat-label>
                  <input id="confirmPwd" matInput [type]="hideConfirmPwd[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="confirmPwd">
                  <div matSuffix (click)="hideConfirmPwd[0] = !hideConfirmPwd[0]"
                    [attr.aria-label]="'HideConfirmPwd password'" [attr.aria-pressed]="hideConfirmPwd[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalConfirmPwd()">{{hideConfirmPwd[0] ? 'visibility_off'
                      : 'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="confirmPwd.hasError('required')">
                    <strong>{{idiomas.msgConFNueva}}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="isRegisterBiometric">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.RegistrarHuellaB.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="text-align: center !important; padding: 16px;">
                <div *ngIf="mensajeBiometria" style="width: 100%">
                  <app-alertas-login [alertaBiometricCredential]="true" [mensaje]="mensajeBiometria">
                  </app-alertas-login>
                  <br>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.Usr}}</mat-label>
                  <input id="userID" matInput type="text" autocomplete="off" [formControl]="usernameFormControl"
                    oninput="this.value = this.value.toUpperCase();">
                  <mat-error *ngIf="usernameFormControl.hasError('required')">
                    <strong>{{idiomas.ValidarUsuario}}</strong>
                  </mat-error>
                  <mat-error *ngIf="usernameFormControl.hasError('pattern')">
                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.ContraActual}}</mat-label>
                  <input id="actualPwd" matInput [type]="hidePwdBiometric[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="actualPwd">
                  <div matSuffix (click)="hidePwdBiometric[0] = !hidePwdBiometric[0]"
                    [attr.aria-label]="'HidePwdBiometric password'" [attr.aria-pressed]="hidePwdBiometric[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalPwdBiometric()">{{hidePwdBiometric[0] ?
                      'visibility_off' : 'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="actualPwd.hasError('required')">
                    <strong>{{idiomas.msgConActual}}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="isDeleteBiometric">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.DesvincularHuellaB.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="text-align: center !important; padding: 16px;" *ngIf="isDeleteBiometric">
                <div *ngIf="mensajeBiometria" style="width: 100%">
                  <app-alertas-login [alertaBiometricCredential]="true" [mensaje]="mensajeBiometria">
                  </app-alertas-login>
                  <br>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.Usr}}</mat-label>
                  <input id="userID" matInput type="text" autocomplete="off" [formControl]="usernameFormControl"
                    oninput="this.value = this.value.toUpperCase();">
                  <mat-error *ngIf="usernameFormControl.hasError('required')">
                    <strong>{{idiomas.ValidarUsuario}}</strong>
                  </mat-error>
                  <mat-error *ngIf="usernameFormControl.hasError('pattern')">
                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.ContraActual}}</mat-label>
                  <input id="actualPwd" matInput [type]="hidePwdBiometric[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="actualPwd">
                  <div matSuffix (click)="hidePwdBiometric[0] = !hidePwdBiometric[0]"
                    [attr.aria-label]="'HidePwdBiometric password'" [attr.aria-pressed]="hidePwdBiometric[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalPwdBiometric()">{{hidePwdBiometric[0] ?
                      'visibility_off' : 'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="actualPwd.hasError('required')">
                    <strong>{{idiomas.msgConActual}}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="changeEma" style="text-align: center !important;">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.BtnEmail.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="padding:16px" *ngIf="!intento">
                <div id="correoSocio" class="col-12">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.CorreoActual}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="correo" name="correo">
                  </mat-form-field>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.CorreoE}}</mat-label>
                  <div>
                    <input matInput type="text" autocomplete="off" [formControl]="nuevoC">
                  </div>
                  <mat-error *ngIf="nuevoC.hasError('required')">
                    <strong>{{idiomas.msgCorreo}}</strong>
                  </mat-error>
                  <mat-error *ngIf='nuevoC.hasError("email")'>
                    {{idiomas.msgCorreoE}}</mat-error>
                </mat-form-field>
                <div class="col-12" style="padding-left: 0px">
                  <div class="form-row">
                    <mat-form-field *ngIf="ingtokenCo" class="col-2 token" appearance="outline">
                      <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                      <input id="soliToken" matInput autocomplete="off" type="password" inputmode="numeric"
                        [formControl]="tokenCo" minlength="6" maxlength="6" appBlockCopyPaste>
                      <mat-error *ngIf="tokenCo.hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                      </mat-error>
                      <mat-error *ngIf="tokenCo.hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                      </mat-error>
                      <mat-error *ngIf="tokenCo.hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                      </mat-error>
                      <mat-error *ngIf="tokenCo.hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="!ingtokenCo" class="col-2 token" appearance="outline">
                      <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                      <input matInput autocomplete="off" type="password" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div class="left col-2 token-button" *ngIf="internetC.internet">
                      <button [disabled]="(!nuevoC.valid)" mat-raised-button color="primary" class="buttonToken"
                        (click)="SolicitarTokenCo()">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                      </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col">
                      <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                        [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="personalInfo" style="text-align: center !important;">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.BtnInfoPersona.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="!intento" style="padding: 16px;">
                <div class="col-12">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.nombreSocio}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="nombreSocio" name="nombreSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.Identificacion1}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="identificacionSocio" name="identificacionSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.Direccion}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="direccionSocio" name="direccionSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.CorreoActual}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="correoSocio" name="correoSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.NumeroTelefonoCelular}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="celularSocio" name="celularSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.Agencia}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="agenciaSocio" name="agenciaSocio">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="ratingInfo" style="text-align: center !important;">
              <div class="row">
                <div class="center col-12 texto">
                  <h6>
                    <strong>{{idiomas.BtnCalificar.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="!intento">
                <div class="col-12">
                  <button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
                    (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                    <mat-icon>
                      {{showIcon(i)}}
                    </mat-icon>
                  </button>
                  <mat-error *ngIf="starCount == null || starCount == 0">
                    Star count is <strong>required</strong> and cannot be zero
                  </mat-error>
                  <p class="body-2">
                    {{idiomas.Rating}} <span class="body-2">{{rating}}</span> / <span
                      class="body-2">{{starCount}}</span>
                  </p>
                </div>
                <div class="col-12">
                  <mat-form-field appearance="outline" fxFlexFill>
                    <mat-label><strong>{{idiomas.observacionRating}}</strong></mat-label>
                    <textarea matInput [formControl]="observacionRating" style="min-height: 200px;"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="questionsInfo" style="text-align: center !important;">
              <div class="row">
                <div class="center col-12 texto">
                  <h6>
                    <strong>{{idiomas.BtnPreguntas.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="!intento">
                <div class="col-12">
                  <mat-accordion displayMode="flat" [multi]="false" cdkDropListGroup>
                    <mat-expansion-panel class="acordion" *ngFor="let chapter of chapters" #chapterExpansionPanel
                      (mouseenter)="mouseEnterHandler($event, chapterExpansionPanel)">
                      <mat-expansion-panel-header class="panelColor">
                        <mat-panel-title>
                          {{ chapter.name }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-list (cdkDropListDropped)="cdkDropListDroppedHandler($event)" cdkDropList
                        [cdkDropListData]="chapter.items">
                        <mat-list-item cdkDrag *ngFor="let item of chapter.items" class="panelColor">
                          {{ item.name }}
                        </mat-list-item>
                      </mat-list>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
            <div *ngIf="registerPin">
              <div class="col card-header">
                <div class="center texto">
                  <h6 *ngIf="!pinSaved && !deviceLinked"><strong>{{idiomas.RegSoftToken | uppercase}}</strong></h6>
                  <h6 *ngIf="pinSaved"><strong>{{idiomas.CodigoQR | uppercase}}</strong></h6>
                </div>
              </div>
              <div style="padding: 16px">
                <div *ngIf="!pinSaved && !deviceLinked">
                  <div style="text-align: center;">
                    <mat-form-field appearance="outline" style="width: 325px !important;">
                      <mat-label>{{idiomas.NombreDispositivo}}</mat-label>
                      <div>
                        <input matInput type="text" autocomplete="off" style="text-transform: uppercase;"
                          [formControl]="deviceName">
                      </div>
                      <mat-error *ngIf="deviceName.hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                      </mat-error>
                      <mat-error *ngIf="deviceName.hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div style="text-align: center;">
                    <mat-form-field appearance="outline" style="width: 325px !important;">
                      <mat-label>{{idiomas.CodigoArea}}</mat-label>
                      <mat-select (openedChange)="funcionRetornarPaises($event)" [formControl]="areaCode">
                        <input *ngIf="intentoPaises" matInput autocomplete="off"
                          (keyup)="applyFilter($event.target.value.toUpperCase())" autocomplete="off"
                          (keydown)="$event.stopPropagation()"
                          placeholder="{{idiomas.FiltrarResultadosPaises | uppercase }}"
                          style="height: 35px;border-bottom-style: groove; text-transform: uppercase;">
                        <mat-option *ngIf="!intentoPaises" disabled>
                          <div style="margin: 10px;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                          </div>
                        </mat-option>
                        <mat-option *ngFor="let pais of lpaises" [(value)]="pais.codigoArea">
                          {{pais.id}} {{pais.codigoArea}}
                          <mat-divider style="border-top-width: 2px;"></mat-divider>
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="areaCode.hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div style="text-align: center;">
                    <mat-form-field appearance="outline" style="width: 325px !important;" floatLabel="always">
                      <mat-label>{{idiomas.NumeroTelefonoCelular}}</mat-label>
                      <div>
                        <input matInput type="text" autocomplete="off" [formControl]="phoneNumber" maxlength="13"
                          placeholder="Ej:0995658254">
                      </div>
                      <mat-error *ngIf="phoneNumber.hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                      </mat-error>
                      <mat-error *ngIf="phoneNumber.hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}} </strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div style="text-align: center; height: 70px;">
                    <mat-label id="label-pin">PIN: </mat-label>&nbsp;
                    <mat-form-field id="pin" *ngFor="let digit of digits; let i = index" appearance="outline"
                      style="width: 50px; margin-left: 5px;">
                      <input id="myInput{{i}}" (keydown)="blockText($event)"
                        (keyup)="enableButton(); focusNext(i, $event)" matInput [formControl]="digit.field"
                        (keypress)="changeValue(i, $event)" matInput [formControl]="digit.field" maxlength="1"
                        inputmode="numeric"
                        type="password">
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="pinSaved" style="text-align: center;">
                  <p>{{idiomas.MsgScanearCodigo}}</p>
                  <qrcode [qrdata]="data" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
                  <p>{{idiomas.MsgCerrarCodigo}}</p>
                </div>
              </div>
            </div>
            <div *ngIf="unRegisterPin">
              <div class="col card-header">
                <div class="center texto">
                  <h6><strong>{{idiomas.DesSoftToken | uppercase}}</strong></h6>
                </div>
              </div>
              <div style="text-align: center;">
                <p>{{idiomas.NombreDispositivo}} : {{name}}</p>
                <p>{{idiomas.NumeroTelefonoCelular}} : {{phone}}</p>
                <p *ngIf="deviceLinked">{{idiomas.FechaVinculacion}} : {{dateLink |
                  formatoFecha:idiomas.IdiomaSeleccionado}}</p>
                <p *ngIf="!deviceLinked">{{idiomas.FechaRegistro}} : {{dateRegister |
                  formatoFecha:idiomas.IdiomaSeleccionado}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
        <div class="col-6"
          *ngIf="!preguntasForm[0].valid && !preguntasForm[1].valid && !preguntasForm[2].valid && internetC.internet && !intentop">
          <button mat-raised-button (click)="consultarPreguntas()" *ngIf="cNvoPreguntas" title={{idiomas.NQ}}
            color="primary" class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>question_answer</mat-icon>
            {{idiomas.NQ}}
          </button>
        </div>
        <div class="col-12" *ngIf=intento style="padding-top: 16px; padding-right: 16px; padding-bottom: 16px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" *ngIf="changePwd && intentop"
          style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
          <br>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" *ngIf="changeEma && intentop"
          style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
          <br>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" *ngIf="registerPin && intentop"
          style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" *ngIf="unRegisterPin && intentop"
          style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-6"
          *ngIf="preguntasForm[0].valid && preguntasForm[1].valid && preguntasForm[2].valid && internetC.internet">
          <button mat-raised-button (click)="validarRespuestas()" *ngIf="cPreguntas" title={{idiomas.Aceptar}}
            color="primary" class="buttonSubmit" style="margin-top: 0px !important;"
            style="margin-top: 0px !important;">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="cambioEmail()"
            *ngIf="nuevoC.valid && changeEma && !cPreguntas && tokenCo.valid" title={{idiomas.Aceptar}} color="primary"
            class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="validatePasswordForRegisterBiometric()"
            *ngIf="actualPwd.valid && !cPreguntas && isRegisterBiometric" title={{idiomas.Aceptar}} color="primary"
            class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>add_circle_outline</mat-icon>
            {{idiomas.Registrar}}
          </button>
          <button mat-raised-button (click)="validatePasswordForDeleteBiometric()"
            *ngIf="actualPwd.valid && !cPreguntas && isDeleteBiometric" title={{idiomas.Aceptar}}
            style="color: #dc3545;" class="buttonSubmitDelete" style="margin-top: 0px !important;">
            <mat-icon>delete</mat-icon>
            {{idiomas.Caducar}}
          </button>
          <button mat-raised-button (click)="cambioPassword()"
            *ngIf="actualPwd.valid && newPwd.valid && confirmPwd.valid && changePwd && !cPreguntas && (newPwd.value == confirmPwd.value)"
            title={{idiomas.Aceptar}} color="primary" class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button *ngIf="!pinSaved && validPin && deviceName.valid && areaCode.valid && phoneNumber.valid"
            mat-raised-button (click)="deviceRegister()" title={{idiomas.Enviar}} color="primary" class="buttonSubmit">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button *ngIf="deviceLinked && unRegisterPin" mat-raised-button (click)="deviceUnRegister()"
            title={{idiomas.Enviar}} color="primary" class="buttonSubmit">
            <mat-icon>phonelink_erase</mat-icon>
            {{idiomas.DesSoftToken}}
          </button>
          <button *ngIf="!deviceLinked && unRegisterPin" mat-raised-button (click)="deviceUnRegister()"
            title={{idiomas.Enviar}} color="primary" class="buttonSubmit">
            <mat-icon>phonelink_erase</mat-icon>
            {{idiomas.EliminarPin}}
          </button>
          <button mat-raised-button (click)="calificarHB()" *ngIf="ratingInfo && rating > 0 && observacionRating.valid"
            title={{idiomas.BtnCalificanos}} color="primary" class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>star_border</mat-icon>
            {{idiomas.BtnCalificanos}}
          </button>
        </div>
        <div align="end" style="text-align: end;" class="col-6">
          <button mat-raised-button (click)="close()"
            *ngIf="preguntasForm[0].valid && preguntasForm[1].valid && preguntasForm[2].valid && internetC.internet && intentoBiometricUser"
            title={{idiomas.Rechazar}} color="warn" class="buttonClean" style="margin-top: 0px !important;">
            <mat-icon> cancel_presentation</mat-icon>
            {{idiomas.Rechazar}}
          </button>
        </div>
      </div>
    </div>
  </mat-card>
  <div *ngIf="bandMobile">
    <div *ngIf="internetC.internet">
      <div *ngIf="containerPreguntas!=''" id="preguntas" fxLayout="column" fxLayoutAlign="start center">
        <div class="col card-header">
          <div class="center texto">
            <h6>
              <strong>{{idiomas.TituloPreguntas}}</strong>
            </h6>
          </div>
        </div>
        <div class="col" *ngIf=intentop>
          <br>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" style="padding: 16px;" *ngIf="!intentop">
          <div *ngFor="let pregunta of preguntas; let i = index">
            <mat-form-field appearance="outline" style="font-size: 1em !important;" fxFlexFill [floatLabel]="'always'"
              (keyup.enter)="usrEnter()">
              <mat-label>{{pregunta.descripcion}}</mat-label>
              <input matInput type="text" autocomplete="off" [formControl]="preguntasForm[i]" #input
                (input)="input.value=$event.target.value.toUpperCase()">
              <mat-error *ngIf="preguntasForm[i].hasError('required')">
                <strong>{{idiomas.msgPregunta}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="containerCambios" id="opcionesCambios">
        <div class="center" *ngIf="!deshabilitar">
          <div class="col card-header">
            <div style="text-align: center">
              <h6>
                <h6><strong>{{idiomas.Administracion | uppercase}}</strong></h6>
              </h6>
            </div>
          </div>
          <div class="row" style="margin-left: auto; margin-right: auto; margin-top: 10px;">
            <div class="col-4" [class.col-6]="bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="personalInformation()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">perm_contact_calendar</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.BtnInfoPersona}}
              </p>
            </div>
            <div class="col-4" [class.col-6]="bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="changePassword()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">password</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.BtnPassword}}
              </p>
            </div>
            <div hidden class="col-4" [class.col-6]="bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="changeEmail()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">contact_mail</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.BtnEmail}}</p>
            </div>
            <div class="col-4" [class.col-6]="bandMobile" *ngIf="isMobile && bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="registerBiometric()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">fingerprint</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.RegistrarHuellaB}}</p>
            </div>
            <div class="col-4" [class.col-6]="bandMobile" *ngIf="!isMobile && hasUnlinkMobile && !bandMobile"
              style="text-align: center; min-width: 90px; padding-left: 0px !important; padding-right: 0px !important">
              <button mat-button class="buttonMenu" (click)="unlinkBiometric()">
                <div class="iconRoundContainer" style="margin-top: 0px;">
                  <mat-icon class="iconMenu" style="color: black;">remove_from_queue</mat-icon>
                </div>
              </button>
              <p style="font-size: .9em;">{{idiomas.DesvincularHuellaB}}</p>
            </div>
          </div>
        </div>
        <div style="padding: 10px;">
          <button *ngIf="changePwd" mat-raised-button (click)="retornarPC()" title={{idiomas.Regresar}}
            style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
            color="primary" class="buttonBene">
            <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
            <strong>{{idiomas.Regresar}}</strong>
          </button>
          <button *ngIf="changeEma" mat-raised-button (click)="retornarPE()" title={{idiomas.Regresar}}
            style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
            color="primary" class="buttonBene">
            <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
            <strong>{{idiomas.Regresar}}</strong>
          </button>
          <button *ngIf="changeDir" mat-raised-button (click)="retornarPE()" title={{idiomas.Regresar}}
            style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
            color="primary" class="buttonBene">
            <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
            <strong>{{idiomas.Regresar}}</strong>
          </button>
          <button *ngIf="!intentoBiometric && (isDeleteBiometric || isRegisterBiometric)" mat-raised-button
            (click)="retornarPC()" title={{idiomas.Regresar}}
            style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
            color="primary" class="buttonBene">
            <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
            <strong>{{idiomas.Regresar}}</strong>
          </button>
          <button *ngIf="intentoBiometric && (isDeleteBiometric || isRegisterBiometric)" mat-raised-button
            (click)="close()" title={{idiomas.Regresar}}
            style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
            color="primary" class="buttonBene">
            <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
            <strong>{{idiomas.Regresar}}</strong>
          </button>
          <button *ngIf="infoRequest || questionsInfo || ratingInfo || personalInfo" mat-raised-button
            (click)="retornar()" title={{idiomas.Regresar}}
            style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;"
            color="primary" class="buttonBene">
            <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
            <strong>{{idiomas.Regresar}}</strong>
          </button>
        </div>
        <div class="row" style="margin-right: 0px; margin-left: 0px;">
          <div class="center col" style="padding-left: 0px; padding-right: 0px;">
            <div *ngIf="changePwd">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.BtnPassword.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="text-align: center !important; padding: 16px;" *ngIf="!intento">
                <div style="padding-bottom: 20px; text-align: -webkit-center;">
                  <div *ngIf="mensajeContra" style="width: 55%">
                    <app-alertas-login [alertaloginpass]="true" [mensaje]="mensajeContra">
                    </app-alertas-login>
                  </div>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'" (keyup.enter)="pasEnter()">
                  <mat-label>{{idiomas.ContraActual}}</mat-label>
                  <input id="actualPwd" matInput [type]="hideActualPwd[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="actualPwd">
                  <div matSuffix (click)="hideActualPwd[0] = !hideActualPwd[0]"
                    [attr.aria-label]="'HideActualPwd password'" [attr.aria-pressed]="hideActualPwd[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalActualPwd()">{{hideActualPwd[0] ? 'visibility_off' :
                      'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="actualPwd.hasError('required')">
                    <strong>{{idiomas.msgConActual}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'" (keyup.enter)="pasEnter()">
                  <mat-label>{{idiomas.ContraNueva}}</mat-label>
                  <input id="newPwd" matInput [type]="hideNewPwd[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="newPwd">
                  <div matSuffix (click)="hideNewPwd[0] = !hideNewPwd[0]" [attr.aria-label]="'HideNewPwd password'"
                    [attr.aria-pressed]="hideNewPwd[0]" style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalNewPwd()">{{hideNewPwd[0] ? 'visibility_off' :
                      'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="newPwd.hasError('required')">
                    <strong>{{idiomas.msgConNueva}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'" (keyup.enter)="pasEnter()">
                  <mat-label>{{idiomas.ConfCoNueva}}</mat-label>
                  <input id="confirmPwd" matInput [type]="hideConfirmPwd[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="confirmPwd">
                  <div matSuffix (click)="hideConfirmPwd[0] = !hideConfirmPwd[0]"
                    [attr.aria-label]="'HideConfirmPwd password'" [attr.aria-pressed]="hideConfirmPwd[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalConfirmPwd()">{{hideConfirmPwd[0] ? 'visibility_off'
                      : 'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="confirmPwd.hasError('required')">
                    <strong>{{idiomas.msgConFNueva}}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="isRegisterBiometric">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.RegistrarHuellaB.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="text-align: center !important; padding: 16px;">
                <div *ngIf="mensajeBiometria" style="width: 100%">
                  <app-alertas-login [alertaBiometricCredential]="true" [mensaje]="mensajeBiometria">
                  </app-alertas-login>
                  <br>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.Usr}}</mat-label>
                  <input id="userID" matInput type="text" autocomplete="off" [formControl]="usernameFormControl"
                    oninput="this.value = this.value.toUpperCase();">
                  <mat-error *ngIf="usernameFormControl.hasError('required')">
                    <strong>{{idiomas.ValidarUsuario}}</strong>
                  </mat-error>
                  <mat-error *ngIf="usernameFormControl.hasError('pattern')">
                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.ContraActual}}</mat-label>
                  <input id="actualPwd" matInput [type]="hidePwdBiometric[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="actualPwd">
                  <div matSuffix (click)="hidePwdBiometric[0] = !hidePwdBiometric[0]"
                    [attr.aria-label]="'HidePwdBiometric password'" [attr.aria-pressed]="hidePwdBiometric[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalPwdBiometric()">{{hidePwdBiometric[0] ?
                      'visibility_off' : 'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="actualPwd.hasError('required')">
                    <strong>{{idiomas.msgConActual}}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="isDeleteBiometric">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.DesvincularHuellaB.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="text-align: center !important; padding: 16px;" *ngIf="isDeleteBiometric">
                <div *ngIf="mensajeBiometria" style="width: 100%">
                  <app-alertas-login [alertaBiometricCredential]="true" [mensaje]="mensajeBiometria">
                  </app-alertas-login>
                  <br>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.Usr}}</mat-label>
                  <input id="userID" matInput type="text" autocomplete="off" [formControl]="usernameFormControl"
                    oninput="this.value = this.value.toUpperCase();">
                  <mat-error *ngIf="usernameFormControl.hasError('required')">
                    <strong>{{idiomas.ValidarUsuario}}</strong>
                  </mat-error>
                  <mat-error *ngIf="usernameFormControl.hasError('pattern')">
                    <strong>{{idiomas.ValidarNoCarEsp}}</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.ContraActual}}</mat-label>
                  <input id="actualPwd" matInput [type]="hidePwdBiometric[0] ? 'password' : 'text'" autocomplete="off"
                    [formControl]="actualPwd">
                  <div matSuffix (click)="hidePwdBiometric[0] = !hidePwdBiometric[0]"
                    [attr.aria-label]="'HidePwdBiometric password'" [attr.aria-pressed]="hidePwdBiometric[0]"
                    style="margin-top: 0px; margin-left: 0px;cursor: pointer;">
                    <mat-icon color="primary" (click)="hideIntervalPwdBiometric()">{{hidePwdBiometric[0] ?
                      'visibility_off' : 'visibility'}}
                    </mat-icon>
                  </div>
                  <mat-error *ngIf="actualPwd.hasError('required')">
                    <strong>{{idiomas.msgConActual}}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="changeEma" style="text-align: center !important;">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.BtnEmail.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="form-row" style="padding:16px" *ngIf="!intento">
                <div id="correoSocio" class="col-12">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.CorreoActual}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="correo" name="correo">
                  </mat-form-field>
                </div>
                <mat-form-field appearance="outline" fxFlexFill [floatLabel]="'always'">
                  <mat-label>{{idiomas.CorreoE}}</mat-label>
                  <div>
                    <input matInput type="text" autocomplete="off" [formControl]="nuevoC">
                  </div>
                  <mat-error *ngIf="nuevoC.hasError('required')">
                    <strong>{{idiomas.msgCorreo}}</strong>
                  </mat-error>
                  <mat-error *ngIf='nuevoC.hasError("email")'>
                    {{idiomas.msgCorreoE}}</mat-error>
                </mat-form-field>
                <div class="col-12" style="padding-left: 0px">
                  <div class="form-row">
                    <div class="left col-6 token-button" *ngIf="internetC.internet"
                      style="padding-bottom: 10px !important;">
                      <button style="margin-top: 0 !important;" [disabled]="(!nuevoC.valid)" mat-raised-button
                        color="primary" class="buttonToken" (click)="SolicitarTokenCo()">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                      </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
                      <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                        [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <mat-form-field *ngIf="ingtokenCo" [class.col-12]="bandMobile" class="col-2 token"
                      appearance="outline">
                      <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                      <input id="soliToken" matInput autocomplete="off" type="password" inputmode="numeric"
                        [formControl]="tokenCo" minlength="6" maxlength="6" appBlockCopyPaste>
                      <mat-error *ngIf="tokenCo.hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                      </mat-error>
                      <mat-error *ngIf="tokenCo.hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                      </mat-error>
                      <mat-error *ngIf="tokenCo.hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                      </mat-error>
                      <mat-error *ngIf="tokenCo.hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="!ingtokenCo" [class.col-12]="bandMobile" class="col-2 token"
                      appearance="outline">
                      <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                      <input matInput autocomplete="off" type="password" disabled appBlockCopyPaste>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="personalInfo" style="text-align: center !important;">
              <div class="col card-header">
                <div style="text-align: center">
                  <h6>
                    <strong>{{idiomas.BtnInfoPersona.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="!intento" style="padding: 16px;">
                <div class="col-12">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.nombreSocio}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="nombreSocio" name="nombreSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.Identificacion1}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="identificacionSocio" name="identificacionSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.Direccion}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="direccionSocio" name="direccionSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.CorreoActual}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="correoSocio" name="correoSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.NumeroTelefonoCelular}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="celularSocio" name="celularSocio">
                  </mat-form-field>
                </div>
                <div class="col-6" [class.col-12]="bandMobile">
                  <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>{{idiomas.Agencia}}</mat-label>
                    <input matInput type="text" readonly [(ngModel)]="agenciaSocio" name="agenciaSocio">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="ratingInfo" style="text-align: center !important;">
              <div class="row">
                <div class="center col-12 texto">
                  <h6>
                    <strong>{{idiomas.BtnCalificar.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="!intento">
                <div class="col-12">
                  <button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
                    (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                    <mat-icon>
                      {{showIcon(i)}}
                    </mat-icon>
                  </button>
                  <mat-error *ngIf="starCount == null || starCount == 0">
                    Star count is <strong>required</strong> and cannot be zero
                  </mat-error>
                  <p class="body-2">
                    {{idiomas.Rating}} <span class="body-2">{{rating}}</span> / <span
                      class="body-2">{{starCount}}</span>
                  </p>
                </div>
                <div class="col-12">
                  <mat-form-field appearance="outline" fxFlexFill>
                    <mat-label><strong>{{idiomas.observacionRating}}</strong></mat-label>
                    <textarea matInput [formControl]="observacionRating" style="min-height: 200px;"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="questionsInfo" style="text-align: center !important;">
              <div class="row">
                <div class="center col-12 texto">
                  <h6>
                    <strong>{{idiomas.BtnPreguntas.toUpperCase()}}</strong>
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="!intento">
                <div class="col-12">
                  <mat-accordion displayMode="flat" [multi]="false" cdkDropListGroup>
                    <mat-expansion-panel class="acordion" *ngFor="let chapter of chapters" #chapterExpansionPanel
                      (mouseenter)="mouseEnterHandler($event, chapterExpansionPanel)">
                      <mat-expansion-panel-header class="panelColor">
                        <mat-panel-title>
                          {{ chapter.name }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-list (cdkDropListDropped)="cdkDropListDroppedHandler($event)" cdkDropList
                        [cdkDropListData]="chapter.items">
                        <mat-list-item cdkDrag *ngFor="let item of chapter.items" class="panelColor">
                          {{ item.name }}
                        </mat-list-item>
                      </mat-list>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
        <div class="col-6"
          *ngIf="!preguntasForm[0].valid && !preguntasForm[1].valid && !preguntasForm[2].valid && internetC.internet && !intentop">
          <button mat-raised-button (click)="consultarPreguntas()" *ngIf="cNvoPreguntas" title={{idiomas.NQ}}
            color="primary" class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>question_answer</mat-icon>
            {{idiomas.NQ}}
          </button>
        </div>
        <div class="col-12" *ngIf=intento style="padding-top: 16px; padding-right: 16px; padding-bottom: 16px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" *ngIf="changePwd && intentop"
          style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
          <br>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-12" *ngIf="changeEma && intentop"
          style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
          <br>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-6"
          *ngIf="preguntasForm[0].valid && preguntasForm[1].valid && preguntasForm[2].valid && internetC.internet">
          <button mat-raised-button (click)="validarRespuestas()" *ngIf="cPreguntas" title={{idiomas.Aceptar}}
            color="primary" class="buttonSubmit" style="margin-top: 0px !important;"
            style="margin-top: 0px !important;">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="cambioEmail()"
            *ngIf="nuevoC.valid && changeEma && !cPreguntas && tokenCo.valid" title={{idiomas.Aceptar}} color="primary"
            class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="validatePasswordForRegisterBiometric()"
            *ngIf="actualPwd.valid && !cPreguntas && isRegisterBiometric" title={{idiomas.Aceptar}} color="primary"
            class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>add_circle_outline</mat-icon>
            {{idiomas.Registrar}}
          </button>
          <button mat-raised-button (click)="validatePasswordForDeleteBiometric()"
            *ngIf="actualPwd.valid && !cPreguntas && isDeleteBiometric" title={{idiomas.Aceptar}}
            style="color: #dc3545;" class="buttonSubmitDelete" style="margin-top: 0px !important;">
            <mat-icon>delete</mat-icon>
            {{idiomas.Caducar}}
          </button>
          <button mat-raised-button (click)="cambioPassword()"
            *ngIf="actualPwd.valid && newPwd.valid && confirmPwd.valid && changePwd && !cPreguntas && (newPwd.value == confirmPwd.value)"
            title={{idiomas.Aceptar}} color="primary" class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="calificarHB()" *ngIf="ratingInfo && rating > 0 && observacionRating.valid"
            title={{idiomas.BtnCalificanos}} color="primary" class="buttonSubmit" style="margin-top: 0px !important;">
            <mat-icon>star_border</mat-icon>
            {{idiomas.BtnCalificanos}}
          </button>
        </div>
        <div align="end" style="text-align: end;" class="col-6">
          <button mat-raised-button (click)="close()"
            *ngIf="preguntasForm[0].valid && preguntasForm[1].valid && preguntasForm[2].valid && internetC.internet && intentoBiometricUser"
            title={{idiomas.Rechazar}} class="buttonClean" style="margin-top: 0px !important;">
            <mat-icon> cancel_presentation</mat-icon>
            {{idiomas.Rechazar}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div *ngIf="!bandMobile">
    <div *ngIf="changePwd" class="float-transacciones">
      <button mat-fab (click)="retornarPC()" title={{idiomas.Regresar}} class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
    <div *ngIf="registerPin" class="float-transacciones">
      <button mat-fab (click)="retornarPC()" title={{idiomas.Regresar}} class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
    <div *ngIf="changeEma" class="float-transacciones">
      <button mat-fab (click)="retornarPE()" title={{idiomas.Regresar}} class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
    <div *ngIf="!intentoBiometric && (isDeleteBiometric || isRegisterBiometric) " class="float-transacciones">
      <button mat-fab (click)="retornarPC()" title={{idiomas.Regresar}} class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
    <div *ngIf="intentoBiometric && (isDeleteBiometric || isRegisterBiometric)" class="float-transacciones">
      <button mat-fab (click)="close()" title={{idiomas.Regresar}} class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
    <div *ngIf="infoRequest || questionsInfo || ratingInfo || personalInfo" class="float-transacciones">
      <button mat-fab (click)="retornar()" title={{idiomas.Regresar}} class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
  </div>
</div>