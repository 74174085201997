<div class="textura">
    <h2 mat-dialog-title style="text-align: center;"><strong>{{transaccion}}</strong></h2>
    <mat-dialog-content>
        <mat-card-content>
            <div *ngIf=intento style="padding-top:  10px !important; padding-bottom: 10px !important;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div>
                <mat-card class="cardTitle">
                    <div class="row">
                        <div class="col-4"><b>{{idiomas.Fecha}}/{{idiomas.Hora}} <p>{{fecha}}</p></b>
                        </div>
                        <div class="col-4">
                            <b>{{idiomas.CuentaOrigen}}</b>
                            <p>{{dato.cuenta | ocultarCuentaPosTrans}}</p>
                        </div>
                        <div class="col-4">
                            <b>{{idiomas.Monto}}</b>
                            <p>$ {{dato.monto | cantidadMonetaria}}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div style="padding-top: 10px !important;">
                <mat-card class="cardBody">
                    <div *ngIf="evento == 'TransferenciasCuentasPropias'" class="row">
                        <div class="col-6">
                            <b>{{idiomas.Referencia}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.referencia}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.CuentaDestino}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.cuentaPropia}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Comision}}</b>
                        </div>
                        <div class="col-6">
                            <p>$ {{dato.comision}}</p>
                        </div>
                    </div>
                    <div *ngIf="evento == 'TransferenciasTerceros'" class="row">
                        <div class="col-5">
                            <b>{{idiomas.NombreBeneficiario}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.beneficiario}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.Referencia}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.referencia}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.CuentaDestino}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.cuentaBe | ocultarCuentaPosTrans}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.EntidadDestino}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.bancoN}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.Comision}}</b>
                        </div>
                        <div class="col-7">
                            <p>$ {{dato.comision}}</p>
                        </div>
                    </div>
                    <div *ngIf="evento == 'TransferenciasAOtros'" class="row">
                        <div class="col-5">
                            <b>{{idiomas.NombreBeneficiario}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.beneficiario}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.Referencia}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.referencia}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.CuentaDestino}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.cuentaBe | ocultarCuentaPosTrans}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.EntidadDestino}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.bancoN}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.Comision}}</b>
                        </div>
                        <div class="col-7">
                            <p>$ {{dato.comision}}</p>
                        </div>
                    </div>
                    <div *ngIf="evento == 'TransferenciasATarjetas'" class="row">
                        <div class="col-5">
                            <b>{{idiomas.NombreBeneficiario}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.beneficiario}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.Referencia}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.referencia}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.CuentaDestino}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.cuentaBe | ocultarCuentaPosTrans}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.EntidadDestino}}</b>
                        </div>
                        <div class="col-7">
                            <p>{{dato.bancoN}}</p>
                        </div>
                        <div class="col-5">
                            <b>{{idiomas.Comision}}</b>
                        </div>
                        <div class="col-7">
                            <p>$ {{dato.comision}}</p>
                        </div>
                    </div>
                    <div *ngIf="evento == 'Recargas'" class="row">
                        <div class="col-6">
                            <b>{{idiomas.Operadora}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.comentarioServicio}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Numero}}</b>
                        </div>
                        <div class="col-6">
                            <p> {{dato.numeroServicio}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Descripcion}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.descripcion}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Comision}}</b>
                        </div>
                        <div class="col-6">
                            <p>$ {{dato.comision}}</p>
                        </div>
                    </div>
                    <div *ngIf="evento == 'pagServ'" class="row">
                        <div class="col-6">
                            <b>{{idiomas.Servicio}}</b>
                        </div>
                        <div class="col-6">
                            <p> {{dato.comentarioServicio}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Suministro}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.numeroServicio}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Descripcion}}</b>
                        </div>
                        <div class="col-6">
                            <p> {{dato.descripcion}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Comision}}</b>
                        </div>
                        <div class="col-6">
                            <p>$ {{dato.comision}}</p>
                        </div>
                    </div>
                    <div *ngIf="evento == 'PagoPrestamo'" class="row">
                        <div class="col-6">
                            <b>{{idiomas.LoanAccount}}:</b>
                        </div>
                        <div class="col-6">
                            <p> {{dato.LoanAccount}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Referencia}}:</b>
                        </div>
                        <div class="col-6">
                            <p> {{dato.descripcion}}</p>
                        </div>
                    </div>
                    <div *ngIf="evento == 'AhorroProgramado'" class="row">
                        <div class="col-6">
                            <b>{{idiomas.FDesde}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.finicio}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.FHasta}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.fVencimiento}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.NumCuota}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.numeroCuotas}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.DiaMes}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.dia}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.DesdeCuenta}}</b>
                        </div>
                        <div class="col-6">
                            <p>{{dato.cuentaDebito}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.Monto}}</b>
                        </div>
                        <div class="col-6">
                            <p>$ {{dato.monto}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{idiomas.NetoGanado}}</b>
                        </div>
                        <div class="col-6">
                            <p>$ {{dato.NetoGanado}}</p>
                        </div>
                    </div>
                </mat-card>
            </div>

        </mat-card-content>
    </mat-dialog-content>
    <mat-dialog-actions style="padding: 0 0 24px 0;">
        <div style="text-align: center !important; width: 100%;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon> cancel_presentation</mat-icon>
                {{rechazar}}
            </button>
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{aceptar}}
            </button>
        </div>
        <div *ngIf="evento == 'TransferenciasTerceros' && isBenefQR && !beneficiaryExist"
            style="text-align: center !important; width: 100%;">
            <button mat-raised-button class="buttonToken" (click)="saveBene()">
                <mat-icon>download_done</mat-icon>{{idiomas.GuardarBene}}
            </button>
        </div>
    </mat-dialog-actions>
</div>