<div class="textura">
    <h2 mat-dialog-title style="text-align: center;"><strong>{{idiomas.TituloDialogos}}</strong></h2>
    <mat-dialog-content>

        <div *ngIf=intento>
            <br>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <div>
            <ngx-extended-pdf-viewer #pdfViewer [base64Src]="base64URL" backgroundColor="#ffffff" [handTool]=false
                [height]="'70%'" [useBrowserLocale]="true" [textLayer]="false" [customToolbar]="multiToolbar">
            </ngx-extended-pdf-viewer>
            <ng-template #multiToolbar>
                <div style="text-align: center;">
                    <button mat-icon-button id="zoomIn">
                        <mat-icon>zoom_in</mat-icon>
                    </button>
                    <button mat-icon-button id="zoomOut">
                        <mat-icon>zoom_out</mat-icon>
                    </button>
                    <button mat-icon-button id="download">
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                </div>
            </ng-template>
        </div>

        <div *ngIf="information">
            <br>
            <app-alertas-login [information]="information" [mensaje]="msjinformation.toUpperCase()"></app-alertas-login>
        </div>

        <div *ngIf="error">
            <br>
            <app-alertas-login [error]="error" [mensaje]="msjerror.toUpperCase()"></app-alertas-login>
        </div>

        <div class="form-row" style="margin: auto; margin-top: 5px;">
            <label class="col-2" style="max-width: 70px;margin-left: auto">{{idiomas.Aceptar}}</label>
            <mat-checkbox class="col-1" [checked]='checkaceptar' [value]="checkaceptar"
                style="max-width: 70px;margin-right: auto;padding-top: 5px" (change)="checkaceptar=!checkaceptar"
                color="primary">
            </mat-checkbox>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions style="padding-top: 0px">
        <button mat-raised-button class="buttonToken" [disabled]="!checkaceptar" (click)="sendAceptanceContract()">
            <mat-icon>done</mat-icon>{{idiomas.Aceptar}}
        </button>
    </mat-dialog-actions>
</div>