import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-diaologo-i-os-android-install',
  templateUrl: './diaologo-i-os-android-install.component.html',
  styleUrls: ['./diaologo-i-os-android-install.component.css']
})
export class DiaologoIOSAndroidInstallComponent {
  idiomas: any;
  deferredPrompt: any;

  bandIos: boolean;
  isChrome: boolean = false;
  isSafari: boolean = false;
  bandMobile: boolean;
  appInstall: boolean;

  constructor(public apiService: ApiService,
    private dialogRef: MatDialogRef<DiaologoIOSAndroidInstallComponent>, @Inject(MAT_DIALOG_DATA) data, public basedatos:
      BaseDatos, public mostrarPdf: MostrarPDF) {
    this.idiomas = data.idm1;
    this.bandIos = data.bandIos;
    this.appInstall = data.appInstall == "0" ? true : false;
    this.deferredPrompt = data.event

    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true
    }

    if (window.innerWidth < 834) {
      this.bandMobile = true
    } else {
      this.bandMobile = false
    }

    //Verifica si el dispositivo que esta siendo utilizado es de google chrome
    const isChrome = () => {
      var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      const pepe = M.join(' ').toString().toLowerCase();
      const word = 'chrome';
      if (pepe.indexOf(word) > -1) {
        return true
      } else {
        return false;
      }
    }

    const isSafari = () => {
      var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      const pepe = M.join(' ').toString().toLowerCase();
      const word = 'safari';
      if (pepe.indexOf(word) > -1) {
        return true
      } else {
        return false;
      }
    }
    this.isChrome = isChrome();
    this.isSafari = isSafari();
  }

  addToHomeScreenAndroid() {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          localStorage.setItem('AppInstalled', "1");
          setTimeout(() => { window.location.reload(); }, 1000);
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  deleteFromIOs() {
    localStorage.setItem('AppInstalled', "0");
    setTimeout(() => { window.location.reload(); }, 1000);
  }
}