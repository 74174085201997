<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch"
    style="margin: auto; padding-bottom:60px; padding-top:4%" *ngIf="!bandMobile">
    <mat-card *ngIf="!tareas || tareas.length<=0" class="cards-transations tareas">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.TarPend | uppercase}}</strong></h6>
            </div>
        </div>
        <div class="row" style="padding: 16px;">
            <div class="center col texto">
                <mat-label>
                    {{idiomas.NoTieneTareasPend | uppercase}}
                </mat-label>
            </div>
        </div>
    </mat-card>
    <div *ngFor="let tarea of tareas ; let i = index;">

        <!-- PRESTAMOS PRE APROBADOS -->
        <mat-card *ngIf="tarea.tarPendType=='presPreap'" class="carta tareas">
            <h6 class="center">
                <strong>{{idiomas.PrestamoPreaprobado}}</strong>
            </h6>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th class="rigth">{{idiomas.Monto}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="right">{{idiomas.Plazo}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.plazo}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="right">{{idiomas.Tasa}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.tasa}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div class="justify-content-between d-flex">

                <button type="button" [disabled]="!internet.internet" class="btn btn-success boton"
                    (click)="enviarPrestamoPreAprobado(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar mode="indeterminate" id="Tarea{{i}}" [style.visibility]="'hidden'"></mat-progress-bar>
        </mat-card>

        <!-- REGISTRO SERVICIO -->
        <mat-card *ngIf="tarea.tarPendType=='regServ'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.RegServicio | uppercase}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Servicio}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.dservicio}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Suministro}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.suministro}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Descripcion}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.descripcion}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'183212')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="enviarRegistroServicio(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>

        <!-- REGISTRO BENEFICIARIO -->
        <mat-card *ngIf="tarea.tarPendType=='regBen'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TituloIngresoBeneficiario.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-sm d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Identificacion1}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.identificacionBeneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Nombres}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombre}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Banco}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombreBanco}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.NumeroCuenta}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentabeneficiario | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.CMoneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'183211')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="enviarRegistroBeneficiario(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>

        <!-- REGISTRO TARJETAS DE CREDITO -->
        <mat-card *ngIf="tarea.tarPendType=='regBenTc'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TituloIngresoBeneficiarioTc.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-sm d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Identificacion1}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.identificacionBeneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Nombres}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombre}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Banco}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombreBanco}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Tarjeta}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentabeneficiario | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.CMoneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'183211')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="enviarRegistroBeneficiario(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>

        <!-- TRANSFERENCIA CUENTAS PROPIAS -->
        <mat-card *ngIf="tarea.tarPendType=='transferCtasPropias'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TransferenciasCuentasPropias.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" style="vertical-align: middle" color="primary"
                        (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaOrigen}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaDestino}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaPropiaOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.MontoTransferencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Referencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.referencia}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186073')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="obtenerComision(i,'7507')" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>
                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>

        <!--TRANSFERENCIAS TERCEROS MISMA INSTITUCION-->
        <mat-card *ngIf="tarea.tarPendType=='transferCtasTer'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TransferenciasTerceros.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" style="vertical-align: middle" color="primary"
                        (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaOrigen}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaDestino}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaBeOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.MontoTransferencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.NombreBeneficiario}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.beneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186074')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
                <div class="col-1" *ngIf="internet.internet && dowloadReporte"
                    style="cursor:pointer;padding-top: 10px;max-width: 30px !important">
                    <a (click)="reportePDFTransferenciaTerceros()" style="margin-top: 10px"><mat-icon color="primary"
                            style="font-size: 30px; height: 30px; width: 30px;"> picture_as_pdf</mat-icon></a>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="obtenerComision(i,'7508')" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>

        <!--TRANSFERENCIAS OTRAS INSTITUCIONES-->
        <mat-card *ngIf="tarea.tarPendType=='transferCtasOtr'" class="cards-transations">
            <div class="form-row card-pending-header">
                <div class="col center texto" style="min-width: 170px;margin:auto" [class.marginLeft]="true">
                    <h6>
                        <strong>{{idiomas.TransferenciasTerceros.toUpperCase()}}</strong>
                    </h6>
                </div>
                <!-- <div *ngIf="true" class="col iconCard2">
                    <button class="btn btnGrafico" style="vertical-align: middle" color="primary"
                        (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div> -->
            </div>
            <div style="padding:16px">
                <div class="row">
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.CuentaOrigen}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.cuenta | ocultarCuentaPosTrans}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.CuentaDestino}}:</b>
                    </div>
                    <div class="col-3">
                        <p>{{tarea.cuentaBe | ocultarCuentaPosTrans}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.Monto}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>$ {{tarea.monto | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.Moneda}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.moneda}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.NombreBeneficiario}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.beneficiario}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.BancoBeneficiario}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.banco}}</p>
                    </div>
                </div>
                <br>
                <div *ngIf="internet.internet" class="form-row">
                    <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken{{i}}" matInput type="password" autocomplete="off"
                            [formControl]="arrayOtp[i]" inputmode="numeric" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="arrayOtp[i].hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div class="left col-2 token-button">
                        <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186071')"
                            color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                </div>
                <div class="justify-content-between d-flex">
                    <button mat-raised-button
                        [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                        class="buttonSubmit" (click)="obtenerComision(i,'2080')" color="primary">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.Aceptar}}
                    </button>
                    <button mat-raised-button class="buttonSubmitDelete" (click)="cerrar(i)" color="warn">
                        <mat-icon>delete</mat-icon>
                        {{idiomas.Caducar}}
                    </button>
                </div>
                <div *ngIf="arrayIntentos[i]">
                    <mat-progress-bar mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
                </div>
            </div>
        </mat-card>

        <!--PAGOS TARJETAS DE CREDITO-->
        <mat-card *ngIf="tarea.tarPendType=='transferCtasOtrTarCred'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TransferenciasTarjetaCredito.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaOrigen}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaView | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaDestino}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaBeView | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.MontoTransferencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.NombreBeneficiario}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.beneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.BancoBeneficiario}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.banco}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186071')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="obtenerComision(i,'2080')" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>

        <!-- PAGO SERVICIOS -->
        <mat-card *ngIf="tarea && tarea.tarPendType=='pagoServ'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="center">
                        <strong>{{idiomas.pagServ | uppercase}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Servicio | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.numeroServicio}}
                                    - {{tarea.comentarioServicio | uppercase}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.DesdeCuenta | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuenta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Monto | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Comision | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.comision}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186000')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="sendServicePayment(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>

        <!-- RECARGAS -->
        <mat-card *ngIf="tarea && tarea.tarPendType=='recargas'" class="carta tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="center">
                        <strong>{{idiomas.Recargas | uppercase}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Servicio | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.numeroServicio}} - {{tarea.codigoServicio | uppercase}}
                                    - {{tarea.comentarioServicio | uppercase}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.DesdeCuenta | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuenta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Monto | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186001')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="sendRecharge(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>
                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </mat-card>
    </div>
</div>
<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch"
    style="margin: auto; padding-bottom:60px; padding-top:4%" *ngIf="bandMobile">
    <div *ngIf="!tareas || tareas.length<=0" class="tareas">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.TarPend | uppercase}}</strong></h6>
            </div>
        </div>
        <div class="row" style="padding: 16px;">
            <div class="center col texto">
                <mat-label>
                    {{idiomas.NoTieneTareasPend | uppercase}}
                </mat-label>
            </div>
        </div>
    </div>
    <div *ngFor="let tarea of tareas ; let i = index;">

        <!-- PRESTAMOS PRE APROBADOS -->
        <div *ngIf="tarea.tarPendType=='presPreap'" class="tareas">
            <h6 class="center">
                <strong>{{idiomas.PrestamoPreaprobado}}</strong>
            </h6>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th class="rigth">{{idiomas.Monto}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="right">{{idiomas.Plazo}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.plazo}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th class="right">{{idiomas.Tasa}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="right">{{tarea.tasa}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div class="justify-content-between d-flex">

                <button type="button" [disabled]="!internet.internet" class="btn btn-success boton"
                    (click)="enviarPrestamoPreAprobado(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar mode="indeterminate" id="Tarea{{i}}" [style.visibility]="'hidden'"></mat-progress-bar>
        </div>

        <!-- REGISTRO SERVICIO -->
        <div *ngIf="tarea.tarPendType=='regServ'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.RegServicio | uppercase}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Servicio}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.dservicio}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Suministro}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.suministro}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Descripcion}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.descripcion}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'183212')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="enviarRegistroServicio(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>

        <!-- REGISTRO BENEFICIARIO -->
        <div *ngIf="tarea.tarPendType=='regBen'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TituloIngresoBeneficiario.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-sm d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Identificacion1}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.identificacionBeneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Nombres}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombre}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Banco}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombreBanco}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.NumeroCuenta}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentabeneficiario | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.CMoneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'183211')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="enviarRegistroBeneficiario(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>

        <!-- REGISTRO TARJETAS DE CREDITO -->
        <div *ngIf="tarea.tarPendType=='regBenTc'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TituloIngresoBeneficiarioTc.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-sm d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Identificacion1}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.identificacionBeneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Nombres}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombre}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Banco}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.nombreBanco}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Tarjeta}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentabeneficiario | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.CMoneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'183211')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="enviarRegistroBeneficiario(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>

        <!-- TRANSFERENCIA CUENTAS PROPIAS -->
        <div *ngIf="tarea.tarPendType=='transferCtasPropias'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TransferenciasCuentasPropias.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" style="vertical-align: middle" color="primary"
                        (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaOrigen}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaDestino}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaPropiaOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.MontoTransferencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Referencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.referencia}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186073')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="obtenerComision(i,'7507')" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>
                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>

        <!--TRANSFERENCIAS TERCEROS MISMA INSTITUCION-->
        <div *ngIf="tarea.tarPendType=='transferCtasTer'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TransferenciasTerceros.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" style="vertical-align: middle" color="primary"
                        (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaOrigen}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaDestino}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaBeOculta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.MontoTransferencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.NombreBeneficiario}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.beneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186074')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
                <div class="col-1" *ngIf="internet.internet && dowloadReporte"
                    style="cursor:pointer;padding-top: 10px;max-width: 30px !important">
                    <a (click)="reportePDFTransferenciaTerceros()" style="margin-top: 10px"><mat-icon color="primary"
                            style="font-size: 30px; height: 30px; width: 30px;"> picture_as_pdf</mat-icon></a>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="obtenerComision(i,'7508')" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>

        <!--TRANSFERENCIAS OTRAS INSTITUCIONES-->
        <div *ngIf="tarea.tarPendType=='transferCtasOtr'" class="cards-transations">
            <div class="form-row card-pending-header">
                <div class="col center texto" style="min-width: 170px;margin:auto" [class.marginLeft]="true">
                    <h6>
                        <strong>{{idiomas.TransferenciasTerceros.toUpperCase()}}</strong>
                    </h6>
                </div>
                <!-- <div *ngIf="true" class="col iconCard2">
                    <button class="btn btnGrafico" style="vertical-align: middle" color="primary"
                        (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div> -->
            </div>
            <div style="padding:16px">
                <div class="row">
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.CuentaOrigen}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.cuenta | ocultarCuentaPosTrans}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.CuentaDestino}}:</b>
                    </div>
                    <div class="col-3">
                        <p>{{tarea.cuentaBe | ocultarCuentaPosTrans}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.Monto}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>$ {{tarea.monto | cantidadMonetaria}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.Moneda}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.moneda}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.NombreBeneficiario}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.beneficiario}}</p>
                    </div>
                    <div class="col-3" [class.col-5]="bandMobile">
                        <b>{{idiomas.BancoBeneficiario}}:</b>
                    </div>
                    <div class="col-3" [class.col-7]="bandMobile">
                        <p>{{tarea.banco}}</p>
                    </div>
                </div>
                <br>
                <div *ngIf="internet.internet" class="form-row">
                    <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken{{i}}" matInput type="password" autocomplete="off"
                            [formControl]="arrayOtp[i]" inputmode="numeric" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="arrayOtp[i].hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div class="left col-2 token-button">
                        <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186071')"
                            color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                </div>
                <div class="justify-content-between d-flex">
                    <button mat-raised-button
                        [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                        class="buttonSubmit" (click)="obtenerComision(i,'2080')" color="primary">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.Aceptar}}
                    </button>
                    <button mat-raised-button class="buttonSubmitDelete" (click)="cerrar(i)" color="warn">
                        <mat-icon>delete</mat-icon>
                        {{idiomas.Caducar}}
                    </button>
                </div>
                <div *ngIf="arrayIntentos[i]">
                    <mat-progress-bar mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
                </div>
            </div>
        </div>

        <!--PAGOS TARJETAS DE CREDITO-->
        <div *ngIf="tarea.tarPendType=='transferCtasOtrTarCred'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="posCon">
                        <strong>{{idiomas.TransferenciasTarjetaCredito.toUpperCase()}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaOrigen}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaView | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.CuentaDestino}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuentaBeView | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.MontoTransferencia}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto | cantidadMonetaria}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.NombreBeneficiario}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.beneficiario}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.BancoBeneficiario}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.banco}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186071')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="obtenerComision(i,'2080')" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>

        <!-- PAGO SERVICIOS -->
        <div *ngIf="tarea && tarea.tarPendType=='pagoServ'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="center">
                        <strong>{{idiomas.pagServ | uppercase}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Servicio | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.numeroServicio}}
                                    - {{tarea.comentarioServicio | uppercase}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.DesdeCuenta | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuenta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Monto | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Comision | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.comision}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186000')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="sendServicePayment(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>

                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>

        <!-- RECARGAS -->
        <div *ngIf="tarea && tarea.tarPendType=='recargas'" class="tareas">
            <div class="form-row">
                <div class="center col" style="min-width: 170px;margin:auto" [class.marginLeft]="arrayBandsEdit[i]">
                    <h6 class="center">
                        <strong>{{idiomas.Recargas | uppercase}}</strong>
                    </h6>
                </div>
                <div *ngIf="arrayBandsEdit[i]" class="col iconCard2">
                    <button class="btn btnGrafico" color="primary" (click)="editTarPend(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table d-flex flex-wrap justify-content-between">
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Servicio | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.numeroServicio}} - {{tarea.codigoServicio | uppercase}}
                                    - {{tarea.comentarioServicio | uppercase}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.DesdeCuenta | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.cuenta | ocultarCuentaPosTrans}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Moneda | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.moneda}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <thead>
                            <tr>
                                <th scope="col">{{idiomas.Monto | uppercase}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="celdas">{{tarea.monto}}</td>
                            </tr>
                        </tbody>
                    </div>
                </table>
            </div>
            <div *ngIf="internet.internet" class="form-row">

                <mat-form-field class="col-2 token" *ngIf="arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken{{i}}" matInput type="password" autocomplete="off" [formControl]="arrayOtp[i]"
                        inputmode="numeric" maxlength="6" appBlockCopyPaste>
                    <mat-error *ngIf="arrayOtp[i].hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="arrayOtp[i].hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" *ngIf="!arrayTokensSolicitados[i]" appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div class="col-3 token-button" style="min-width: 130.7px; max-width: 130.7px;">
                    <button mat-raised-button [disabled]="!internet.internet" (click)="solicitarToken(i,'186001')">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
            </div>
            <br>
            <div class="justify-content-between d-flex">
                <button type="button"
                    [disabled]="!internet.internet || !arrayOtp[i].valid || !arrayTokensSolicitados[i]"
                    class="btn btn-success boton" (click)="sendRecharge(i)" color="primary">
                    <mat-icon color="white">send</mat-icon>
                </button>
                <button type="button" class="btn btn-danger boton" (click)="cerrar(i)" color="warn">
                    <mat-icon color="white">close</mat-icon>
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="arrayIntentos[i]" mode="indeterminate" id="Tarea{{i}}"></mat-progress-bar>
        </div>
    </div>
</div>