import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { internetComponent } from '../funciones/internet';
import { FormControlValidators } from '../funciones/formcontrol';

import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFecha, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatTableModule, MatListModule, MatDividerModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() idiomas: any;

  bandNotification: boolean;
  intento: boolean;
  notificaciones: any[];
  displayedColumns: string[] = ['Fecha', /*'Tipo',*/ 'Notificacion']
  flechaR: boolean;
  flechaL: boolean;
  bandMobile: boolean;
  numMaxRow: number;

  constructor(private api: ApiService,
    public alerta: Alerta,
    private base: BaseDatos,
    public service: Servicios,
    public internet: internetComponent,
    private validators: FormControlValidators) {
    this.bandNotification = false;
    this.intento = true;
    this.numMaxRow = 9;
    this.notificaciones = [];
    this.flechaR = false;
    this.flechaL = true;
    this.bandMobile = false;
  }

  ngOnInit() {
    this.bandMobile = false;
    this.consultarNotificaciones()
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  consultarNotificaciones() {
    this.intento = true
    let envio = {
      "transaccion": "010003-con-notificaciones.xml",
      "cri^tpc0^CPERSONA^NORMAL^tpc0^=": "$personCode",
      "cri^tpc0^FCADUCIDAD^NORMAL^tpc0^>=": "$accountingDate",
      "cri^tpc1^CPERSONA^NORMAL^tpc1^=": "$personCode",
      "cri^tcp0^CPERSONA^NORMAL^tcc0^=": "$personCode",
      "cri^tcc0^FCADUCIDAD^NORMAL^tcc0^>=": "$accountingDate",
      "cri^tcp0^CPERSONA^NORMAL^tcc2^=": "$personCode",
      "fullNames": "true"
    }
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.notificaciones = []
        var Keys = Object.keys(data)
        var notificacion = {}
        for (var i = 0; i < Keys.length - 1; i++) {
          let messageArrayTemp = data[Keys[i]]
          if (messageArrayTemp.length != 0) {
            for (var j = 0; j < messageArrayTemp.length; j++) {
              if (Keys[i] == "tpc0") {
                notificacion['TITULO'] = messageArrayTemp[j]['tcc0.DESCRIPCION']
                notificacion['SUBTITULO'] = messageArrayTemp[j]['ttc0.DESCRIPCION']
                notificacion['FECHA'] = messageArrayTemp[j]['tpc0.FINGRESO']
                notificacion['COMENTARIO'] = messageArrayTemp[j]['tpc0.COMENTARIO']
                this.notificaciones.push(notificacion)
                notificacion = {}
              }
              if (Keys[i] == "tpc1") {
                notificacion['TITULO'] = messageArrayTemp[j]['tcc0.DESCRIPCION']
                notificacion['SUBTITULO'] = messageArrayTemp[j]['ttc0.DESCRIPCION']
                notificacion['FECHA'] = messageArrayTemp[j]['tpc1.FINGRESO']
                notificacion['COMENTARIO'] = messageArrayTemp[j]['tpc1.COMENTARIO']
                this.notificaciones.push(notificacion)
                notificacion = {}
              }
              if (Keys[i] == "tcc2") {
                notificacion['TITULO'] = messageArrayTemp[j]['tcc3.DESCRIPCION']
                notificacion['SUBTITULO'] = messageArrayTemp[j]['ttc0.DESCRIPCION']
                notificacion['FECHA'] = messageArrayTemp[j]['tcc2.FINGRESO']
                notificacion['COMENTARIO'] = messageArrayTemp[j]['tcc2.COMENTARIO']
                this.notificaciones.push(notificacion)
                notificacion = {}
              }
              if (Keys[i] == "tcc0") {
                notificacion['TITULO'] = messageArrayTemp[j]['tcc1.DESCRIPCION']
                notificacion['SUBTITULO'] = messageArrayTemp[j]['ttc0.DESCRIPCION']
                notificacion['FECHA'] = messageArrayTemp[j]['tcc0.FINGRESO']
                notificacion['COMENTARIO'] = messageArrayTemp[j]['tcc0.COMENTARIO']
                this.notificaciones.push(notificacion)
                notificacion = {}
              }
            }
            this.base.insertarConsulta('Notificaciones', this.notificaciones)
          }
        }
      }
      if (this.notificaciones.length != 0) {
        this.intento = false
        this.bandNotification = true
      }
      else {
        this.intento = false
        this.bandNotification = false
      }
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
        this.notificaciones = this.base.retornarConsulta('Notificaciones')
        if (!this.notificaciones) {
          this.notificaciones = []
        }
      }
    });
  }

  showHideRows(last) {
    if (last) {
      try {
        this.DivHeight()
        let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('notificacionest')[0].setAttribute('style', 'width: 850px')
          if (850 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('notificacionest')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('notificacionest')[0].setAttribute('style', 'width: 500px')
          if (500 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('notificacionest')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('notificacionest')[0].setAttribute('style', 'width: 456px; font-size: 14px')
          if (456 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('notificacionest')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }

  DivHeight() {
    if (window.orientation != 90 && window.orientation != -90) {
      document.getElementById('tableDivNot').setAttribute('style', 'padding-bottom: 0px')
      var lastelement = document.getElementById('lastelement1').getBoundingClientRect().top + document.getElementById('lastelement1').clientHeight + document.getElementsByClassName('cabecera')[0].clientHeight
      if ((this.notificaciones.length * document.getElementById('celda1').clientHeight) < (window.innerHeight - lastelement - 40)) {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - lastelement - 40) + 'px')
      }
    } else {
      document.getElementById('tableDivNot').setAttribute('style', 'padding-bottom: 40px')
      if ((this.notificaciones.length * document.getElementById('celda1').clientHeight) < (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 74)) {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight -
          document.getElementsByClassName('cabecera')[0].clientHeight - 74) + 'px')
      }
    }
  }

  scroll(id) {
    if (id == 'notificacionest') {
      var container1 = document.getElementsByClassName('container2Aux')
    }
    var tabla = document.getElementsByClassName(id)
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 2 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    if (clase == 'notificacionest') {
      let container1 = document.getElementsByClassName('container2Aux')
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
      } else {
        container1.item(0).scrollLeft = 0
        this.flechaL = true
        this.flechaR = false
      }
    }
  }

  logout() {
    this.alerta.clearAlerta()
    this.alerta.generarOfflineDialogo({ 'salir': true }, this.idiomas)
  }
}

