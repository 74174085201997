<div id="faceLivenessReactContainer" *ngIf="start_liveness_session">
    <app-faceliveness-react-wrapper #faceliveness [sessionId]="session_id"
        (livenessResults)="handleLivenessAnalysisResults($event)"
        (livenessErrors)="handleErrors($event)"
        [idiomas]="idiomas"></app-faceliveness-react-wrapper>
</div>

<div *ngIf="liveness_session_complete && error" style="text-align: center;">
    <br>
    <app-alertas-login [error]="error" [mensaje]="face_live_message"></app-alertas-login>
    <br>
    <button mat-raised-button class="enviar button-login" style="width: 100% !important;" color="primary"
        (click)="get_liveness_session()">{{idiomas.tryAgainText}}</button>
</div>