import { ChangeDetectorRef, HostListener, Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Servicios } from '../funciones/encryptar';
import { Alerta } from '../funciones/alerta';
import { Idioma } from '../funciones/idioma';
import { BaseDatos } from '../funciones/basededatos';
import { DateAdapter } from '@angular/material/core';
import { internetComponent } from '../funciones/internet'
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { FormControlValidators } from '../funciones/formcontrol';
import { FormPOSICIONCONSOLIDADAComponent } from '../form-posicion-consolidada/form-posicion-consolidada.component'
import { BeneficiariosComponent } from '../beneficiarios/beneficiarios.component'
import { MontosTransferenciasComponent } from '../montos-transferencias/montos-transferencias.component'
import { TransferenciasCuentasPropiasComponent } from '../transferencias-cuentas-propias/transferencias-cuentas-propias.component'
import { TransferenciasMismaInstitutcionComponent } from '../transferencias-misma-institutcion/transferencias-misma-institutcion.component'
import { TransferenciasComponent } from '../transferencias/transferencias.component'
import { ConsultaTransferenciasComponent } from '../consulta-transferencias/consulta-transferencias.component'
import { TransferenciaTarjetasCreditoComponent } from '../transferencia-tarjetas-credito/transferencia-tarjetas-credito.component'
import { PagoServiciosComponent } from '../pago-servicios/pago-servicios.component'
import { ConsultaServiciosComponent } from '../consulta-servicios/consulta-servicios.component'
import { RecargasComponent } from '../recargas/recargas.component'
import { ConsultaPagoServiciosComponent } from '../consulta-pago-servicios/consulta-pago-servicios.component'
import { AdministracionComponent } from '../administracion/administracion.component'
import { ContactarAcesorComponent } from '../contactar-acesor/contactar-acesor.component'
import { NotificacionesComponent } from '../notificaciones/notificaciones.component'
import { AhorroProgramadoComponent } from '../ahorro-programado/ahorro-programado.component'
import { SolicitudPlazoComponent } from '../solicitud-plazo/solicitud-plazo.component';
import { GenerarCartaPreaprobadoComponent } from '../generar-carta-preaprobado/generar-carta-preaprobado.component'
import { PagoRecaudacionesComponent } from '../pago-recaudaciones/pago-recaudaciones.component'
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { FuncionesMenuInferior } from '../funciones/funcionesMenuInferior';
import { PagoPrestamoComponent } from '../pago-prestamo/pago-prestamo.component';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { PagoServiciosMenuComponent } from '../pago-servicios-menu/pago-servicios-menu.component';
import { CertificadoBancarioComponent } from '../certificado-bancario/certificado-bancario.component';
import { TareasPendientesComponent } from '../tareas-pendientes/tareas-pendientes.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '../services/notification.service';
import { CalificanosComponent } from '../calificanos/calificanos.component';
import { PreguntasFrecuentesComponent } from '../preguntas-frecuentes/preguntas-frecuentes.component';
import { NgOptimizedImage } from '@angular/common'
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, NgOptimizedImage, MatProgressBarModule, MatTabsModule, MatMenuModule, MatBadgeModule, MatToolbarModule, CarouselModule, MatSidenavModule, MatDividerModule, MatExpansionModule, MatListModule, MatDatepickerModule, MatInputModule, MatButtonModule, CarouselModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, FormPOSICIONCONSOLIDADAComponent, BeneficiariosComponent, TransferenciasCuentasPropiasComponent, TransferenciasMismaInstitutcionComponent, TransferenciasComponent, TransferenciaTarjetasCreditoComponent, ConsultaTransferenciasComponent, SolicitudPlazoComponent, AhorroProgramadoComponent, PagoPrestamoComponent, GenerarCartaPreaprobadoComponent, ConsultaServiciosComponent, PagoServiciosMenuComponent, RecargasComponent, PagoRecaudacionesComponent, ConsultaPagoServiciosComponent, CertificadoBancarioComponent, MontosTransferenciasComponent, AdministracionComponent, NotificacionesComponent, TareasPendientesComponent, ContactarAcesorComponent, CalificanosComponent, PreguntasFrecuentesComponent, CertificadoBancarioComponent],
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnDestroy {

  /* Variables para ejecutar métodos de elementos del HTML (Relacionado con el identificador #) */
  @ViewChild('numTareas', { static: false }) numTareas; //Componente Tareas Pendientes
  @ViewChild('snav', { static: false }) snav: any; //Material Side Nav
  @ViewChild('matTabContentGroup', { static: false }) matTabContentGroupObj: MatTabGroup //Mat Tab Group For Content
  @ViewChild('posiConsoMultiTab', { static: false }) posiConsoMultiTab: any; //Componente Multitab de Posicion Consolidada

  /* Elementos referenciados del DOM */
  @ViewChild('snav', { read: ElementRef, static: false }) snavRef: ElementRef
  @ViewChild('matTabContentGroup', { read: ElementRef, static: false }) matTabContentGroup: ElementRef //Mat Tab Group For Content - DOM
  @ViewChild('matNavList', { read: ElementRef, static: false }) matNavList: ElementRef //Mat Nav List 
  @ViewChild('toolBar', { read: ElementRef, static: false }) toolBar: ElementRef //Tool-Bar 
  @ViewChild('contentHome', { read: ElementRef, static: false }) contentHome: ElementRef //Content space of home
  @ViewChild('searchTransactionInput', { static: false }) searchTransactionInput //Input For Search Transactions

  @ViewChild('modTransferencias', { static: false }) modTransferencias;
  @ViewChild('modTransferenciasTarCred', { static: false }) modTransferenciasTarCred;
  @ViewChild('modServicios', { static: false }) modServicios;
  @ViewChild('posiConso', { static: false }) posiConso;
  @ViewChild('adminCta', { static: false }) adminCta;
  @ViewChild('plazoFijo', { static: false }) plazoFijo;

  @ViewChild('transferPanel', { static: true, read: MatExpansionPanel }) transferPanelRef: MatExpansionPanel;
  @ViewChild('transferWebPanel', { static: true, read: MatExpansionPanel }) transferWebPanelRef: MatExpansionPanel;
  @ViewChild('creditCardPanel', { static: true, read: MatExpansionPanel }) creditCardPanelRef: MatExpansionPanel;
  @ViewChild('servicePanel', { static: true, read: MatExpansionPanel }) servicePanelRef: MatExpansionPanel;
  @ViewChild('adminPanel', { static: true, read: MatExpansionPanel }) adminPanelRef: MatExpansionPanel;
  @ViewChild('ecommercePanel', { static: true, read: MatExpansionPanel }) ecommercePanelRef: MatExpansionPanel;
  @ViewChild('girosPanel', { static: true, read: MatExpansionPanel }) girosPanelRef: MatExpansionPanel;
  @ViewChild('pagosProgramadosPanel', { static: true, read: MatExpansionPanel }) pagosProgramadosPanelRef: MatExpansionPanel;
  @ViewChild('prestamosPanel', { static: true, read: MatExpansionPanel }) prestamosPanelRef: MatExpansionPanel;
  @ViewChild('ahorrosPanel', { static: true, read: MatExpansionPanel }) ahorrosPanelRef: MatExpansionPanel;

  /* Variables para control de temas */
  temaSeleccionado: string;
  temaSeleccionadoPrev: string;
  temaSeleccionadoBand: number;

  /* Variables para control de temas */
  skinSeleccionado: string;
  skinSeleccionadoPrev: string;
  skinSeleccionadoBand: number;

  /* Variables para codigo QR */
  data: string;

  /* Variables parametro a componentes */
  grande: boolean;
  mediano: boolean;
  normal: boolean;
  idiomas: any;
  subTabsHeaderContainerWidth: number;

  /* Variables para control de sidenav */
  sideNavCounter: number;
  numTareasPendientes: number;
  numTareasPendientesPrev: number;
  referenceWidth: number;
  snavWidthChangeband: boolean;
  loginInfo: any;
  tareas: any;
  tabIndex: any = 0;
  tabIndexMenu: any;
  account: string = "";
  operation: string = "";

  /* Variables para control de funcionalidades por rol (pestañas y elementos de sidenav) */
  // mapTabsSideNav: any;
  // selectedIndexForChilds: number;

  /* Variables para control de pestañas/tabs (desplazamientos y estilo) */
  selectedIndex: number;
  panBand: boolean;
  selectedIndex1: number;
  tabsCounter: number;
  contPanGesture: number;
  numNoSelectedTabs: number;
  arrayPositionsTabs: any[];
  selectedTabPosition: any;
  tamMatTabLIst: number;
  tamMatTabLabelContainer: number;
  notificaciones: number;
  bandScrollMoveTabs: boolean;
  logoTemaOscuro: boolean;
  intentoDialogoInicio: boolean

  /* Variables para control de tabs mostrados */
  shownTransactionsTabs: any = {};
  shownTransactionsTabsLabelsArray: any[] = [];
  bandTabsAddedOrRemoved: boolean = false;
  // txMovedBySearchInput: string = null;
  // lastTabLabelSelected: string = null;
  selectedTabLabel: string = '';
  availableTransactionsArray: any[] = [];
  availableTransactionsArraySearched: any[] = [];

  /* Otras variables */
  mobileQuery: MediaQueryList;
  locale: string;
  alertaBool: boolean;
  /* Variables para los componentes */
  componentePosicionConsolidada: any;
  componenteBeneficiarios: any;
  componenteMontoMaximoTransferencia: any;
  componenteTransferenciasCuentasPropias: any;
  componenteTransferenciasTerceros: any;
  componenteTransferenciasAOtros: any;
  componenteTransferenciasAlExterior: any;
  componenteCTransferencias: any;
  componenteTarjetasCredito: any;
  componenteTransferenciasTarjetaCredito: any;
  componenteCTransferenciasTarjeta: any;
  componentegestionServicioss: any;
  componentepagServ: any;
  componenteRecargas: any;
  componenteconsPagServ: any;
  componenteNotificaciones: any;
  componenteAdministracion: any;
  componenteContacarAcesor: any;
  componenteAhorroProgramado: any;
  componenteRegAhProgramadosa: any;
  componenteManAhProgramados: any;
  componentePlazoFijo: any;
  componentePrestamoPreaprobadoT: any;
  componenteSolTarjeta: any;
  componentePagoPrestamos: any;
  componentepagRec: any;
  componenteSolicitudCredito: any;
  bandLogoZuizo: boolean;

  eventText: string;
  minDate: Date;
  maxDate: Date;
  chatBand: boolean;
  chatBandResize: boolean;
  cont: number;
  haveStore: boolean;
  index: number;
  iconId: string;
  dataTarPendB: any;
  dataTarPendTCP: any;
  dataTarPendTT: any;
  dataTarPendTAO: any;

  showAlert: boolean;
  bandMobile: boolean;
  panelOpenState: boolean = false;
  intento: boolean = false;
  backgroundImg: any;

  private _mobileQueryListener: () => void;
  @HostListener('window:popstate', ['$event'])
  async onPopState($event) {
    const url = new URL("https://aws-pwapp-homebanking.fit-bank.com/#/");
    this.alerta.cancelar()
    if (!this.alertaBool) { this.alerta.presentarAlerta(this.idiomas.PresionaSalir) }
    this.alertaBool = true;
    await setTimeout(() => {
      this.logout()
      this.alertaBool = false
    }, 1000);
  }

  //ngx-carousel options
  customCarouselOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 12000,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 900,
    stagePadding: 15,
    margin: 5,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  constructor(public base: BaseDatos, public internetC: internetComponent, private idm: Idioma, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public apiService: ApiService, private validators: FormControlValidators, public servicios: Servicios,
    private router: Router, private alerta: Alerta, private overlay: OverlayContainer, private dateAdapter: DateAdapter<any>, private dialog_manual: MatDialog, private dialog_ayuda: MatDialog, private dialog_calculator: MatDialog,
    private menuInferior: FuncionesMenuInferior, private sanitizer: DomSanitizer, private notificationService: NotificationService) {


    this.componentePosicionConsolidada = { componentClass: FormPOSICIONCONSOLIDADAComponent, componentLabelKey: 'PosicionConsolidada' }
    this.componenteBeneficiarios = { componentClass: BeneficiariosComponent, componentLabelKey: 'Beneficiarios' }
    this.componenteMontoMaximoTransferencia = { componentClass: MontosTransferenciasComponent, componentLabelKey: 'MaximoTransferencia' }
    this.componenteTransferenciasCuentasPropias = { componentClass: TransferenciasCuentasPropiasComponent, componentLabelKey: 'TransferenciasCuentasPropias' }
    this.componenteTransferenciasTerceros = { componentClass: TransferenciasMismaInstitutcionComponent, componentLabelKey: 'TransferenciasTerceros' }
    this.componenteTransferenciasAOtros = { componentClass: TransferenciasComponent, componentLabelKey: 'TransferenciasAOtros' }
    this.componenteCTransferencias = { componentClass: ConsultaTransferenciasComponent, componentLabelKey: 'CTransferencias' }
    this.componenteTarjetasCredito = { componentClass: BeneficiariosComponent, componentLabelKey: 'TarjetasCredito' }
    this.componenteTransferenciasTarjetaCredito = { componentClass: TransferenciaTarjetasCreditoComponent, componentLabelKey: 'TransferenciasTarjetaCredito' }
    this.componenteCTransferenciasTarjeta = { componentClass: ConsultaTransferenciasComponent, componentLabelKey: 'CTransferenciasTarjeta' }
    this.componentegestionServicioss = { componentClass: PagoServiciosComponent, componentLabelKey: 'gestionServicioss' }
    this.componentepagServ = { componentClass: ConsultaServiciosComponent, componentLabelKey: 'pagServ' }
    this.componenteRecargas = { componentClass: RecargasComponent, componentLabelKey: 'Recargas' }
    this.componenteconsPagServ = { componentClass: ConsultaPagoServiciosComponent, componentLabelKey: 'consPagServ' }
    this.componenteNotificaciones = { componentClass: AdministracionComponent, componentLabelKey: 'Notificaciones' }
    this.componenteAdministracion = { componentClass: NotificacionesComponent, componentLabelKey: 'Administracion' }
    this.componenteContacarAcesor = { componentClass: ContactarAcesorComponent, componentLabelKey: 'BtnContactAsesor' }
    this.componenteAhorroProgramado = { componentClass: AhorroProgramadoComponent, componentLabelKey: 'AhorroProgramado' }
    this.componentePlazoFijo = { componentClass: SolicitudPlazoComponent, componentLabelKey: 'plazoFijo' }
    this.componentePrestamoPreaprobadoT = { componentClass: GenerarCartaPreaprobadoComponent, componentLabelKey: 'PrestamoPreaprobadoT' }
    this.componentepagRec = { componentClass: PagoRecaudacionesComponent, componentLabelKey: 'pagRec' }
    this.componentePagoPrestamos = { componentClass: PagoPrestamoComponent, componentLabelKey: 'PagoPrestamos' }

    //Tabs de Transacciones dinamico, en base a JSON de control
    this.shownTransactionsTabs = this.base.retornarConsulta('favoriteTransactionsSelected');
    if (this.shownTransactionsTabs == null) {
      this.shownTransactionsTabs = { 'PosicionConsolidada': { favorite: true } }
    }
    this.shownTransactionsTabsLabelsArray = Object.keys(this.shownTransactionsTabs)
    this.selectedTabLabel = this.shownTransactionsTabsLabelsArray[0]
    this.dataTarPendB = {};
    this.dataTarPendTCP = {};
    this.dataTarPendTT = {};
    this.dataTarPendTAO = {};
    this.selectedIndex = 0;
    this.selectedIndex1 = 0;
    this.panBand = false;
    this.alertaBool = false;
    this.grande = false;
    this.mediano = false;
    this.normal = false;
    this.subTabsHeaderContainerWidth = 0;
    this.snavWidthChangeband = false;
    this.contPanGesture = 0;
    this.numTareasPendientes = 0;
    this.numTareasPendientesPrev = 0;
    this.tabsCounter = 0
    this.referenceWidth = 650; //Width para cambio de letra y tipo de menu lateral
    this.numNoSelectedTabs = 0;
    this.intentoDialogoInicio = true;
    // this.mapTabsSideNav = [];
    // this.selectedIndexForChilds = 0;
    this.arrayPositionsTabs = [];
    this.selectedTabPosition = 0;
    this.tamMatTabLIst = 0;
    this.tamMatTabLabelContainer = 0;
    this.bandLogoZuizo = false;
    this.showAlert = false;
    this.bandMobile = false;

    this.haveStore = this.base.retornarConsulta('tienda');
    this.eventText = '';
    this.minDate = new Date(2000, 0, 1);
    this.maxDate = new Date(2020, 0, 1);
    this.chatBand = true;
    this.chatBandResize = false;
    this.cont = 0;
    this.arrayPositionsTabs = [];
    this.loginInfo = { nombreUsuario: "", lastLogin: "", nombrePreferido: "", email: "" }
    this.sideNavCounter = 0;
    this.logoTemaOscuro = false;
    this.bandScrollMoveTabs = false; //Variable que controla el desplazamiento de tabs
    let temasDisponibles = ["night-theme", "light-theme", "light-theme-verde", "light-theme-azul"]
    this.temaSeleccionado = localStorage.getItem('themeDisplayed');
    this.skinSeleccionado = localStorage.getItem('skinDisplayed');
    if (!this.temaSeleccionado) //Cuando no se encuentre tema en localStorage
    {
      for (let temeIndex = 0; temeIndex < temasDisponibles.length; temeIndex++) {
        if (this.overlay.getContainerElement().classList.contains(temasDisponibles[temeIndex])) {
          for (let indexClassList = 0; indexClassList < this.overlay.getContainerElement().classList.length; indexClassList++) {
            if (temasDisponibles[temeIndex] == this.overlay.getContainerElement().classList.item(indexClassList)) {
              this.temaSeleccionado = this.overlay.getContainerElement().classList.item(indexClassList)
              break
            }
          }
          localStorage.setItem('themeDisplayed', this.temaSeleccionado)
          break;
        }
      }
    }

    if (this.haveStore) {
      this.index = 8
    } else {
      this.index = 7
    }
    this.iconId = "icon" + this.index

    //Se verifica si existe fontSize almacenado
    let fontSize = localStorage.getItem('fontSize');
    if (fontSize != null) {
      switch (fontSize) {
        case 'large':
          this.grande = true;
          break;
        case 'medium':
          this.mediano = true;
          break;
        case 'small':
          this.normal = true;
          break;
        default:
          this.normal = true;
          break;
      }
    } else {
      this.mediano = true;
    }

    //Obtener datos de usuario logeado de BD
    let loginInfoAux = this.base.retornarConsulta("loginInfo")
    if (loginInfoAux) {
      if (loginInfoAux.nombreUsuario && loginInfoAux.nombreUsuario != "null")
        this.loginInfo.nombreUsuario = loginInfoAux.nombreUsuario
      if (loginInfoAux.lastLogin && loginInfoAux.lastLogin != "null")
        this.loginInfo.lastLogin = loginInfoAux.lastLogin
      this.loginInfo.nombrePreferido = loginInfoAux.nombrePreferido
      this.loginInfo.email = loginInfoAux.email
      this.loginInfo.cuenta = loginInfoAux.cuenta
    }
    //  

    this.temaSeleccionadoPrev = this.temaSeleccionado;
    this.temaSeleccionadoBand = temasDisponibles.indexOf(this.temaSeleccionado)
    if (this.temaSeleccionado != "night-theme") {
      this.logoTemaOscuro = false;
    } else {
      this.logoTemaOscuro = true;
    }

    let skinDisponibles = ["skin-aestetic", "skin-compact"]
    this.skinSeleccionadoPrev = this.skinSeleccionado;
    this.skinSeleccionadoBand = skinDisponibles.indexOf(this.skinSeleccionado)

    this.locale = localStorage.getItem('locale');
    if (this.servicios.desencriptar(localStorage.getItem('ingresado')) != 'ingresado') {
      this.router.navigate([''])
    }
    history.pushState(null, null, document.URL)
    this.mobileQuery = media.matchMedia('(max-width: 2000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.idiomas = JSON.parse(localStorage.getItem('idioma'))
    this.dateAdapter.setLocale(this.locale) //Para setear idioma de calendarios
    this.selectedSNav(true)
    this.selectSkinBackground()
  }

  @HostListener('window:resize', ['$event']) //Evento que permite determinar cuando se redimenciona pantalla
  onResize(event) {
    this.toolbarWidth();
    this.getTabsXposition();
  }

  @HostListener('window:keydown', ['$event']) onKeyDown(e) { //Evento que detecta teclas combinadas
    if (e.ctrlKey && e.keyCode == 81) {
      if (!this.snav.opened) {
        this.abrirMenu(e)
        setTimeout(() => { this.searchTransactionInput.open() }, 250);
      } else {
        this.searchTransactionInput.open()
      }
    }
  }

  ngDoCheck() {
    if (this.bandTabsAddedOrRemoved) {
      if (this.matTabContentGroupObj._tabs.toArray().length == this.shownTransactionsTabsLabelsArray.length) {
        //this.orderTabs()
        this.move(this.selectedTabLabel)
        if (this.isLargeScreenForSideNav()) { //Actualizar tamanio de tabs container para desplazamientos personalizados de tabs
          this.snavWidthChangeband = false;
          setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
        }
      }
    }
    if (localStorage.getItem('ingresado')) {
      this.tareas = this.base.seleccionarTareas();
      this.numTareasPendientes = this.tareas.length;//Hacia Home - numero de tareas pendientes
    }
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    this.selectSkinBackground()
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onTabClick(index) {
    this.tabIndex = index;
  }

  selectSkinBackground() {
    let fondoSeleccionado = !this.bandMobile ? 'fondo' + this.temaSeleccionado + '.png' : 'fondo-mobile-' + this.temaSeleccionado + '-' + this.skinSeleccionado + '.jpg'
    this.backgroundImg = this.sanitizer.bypassSecurityTrustStyle('url(assets/resources/mipmap-hdpi/fondo-skin/' + fondoSeleccionado + ')')
  }

  //Representa document.ready para marcar primera pestana seleccionada
  ngAfterViewInit() {
    this.sideNavCounter = this.matNavList.nativeElement.childElementCount; //Cantidad de items en sidenav
    this.selectedSNav(true)
    this.matTabContentGroup.nativeElement.firstElementChild.firstElementChild.parentElement.style.display = 'none';//Oculta segunda barra de tab labels    
    this.getTabsXposition();
    this.selectedTabPosition = 0;

    //Obtiene id y descripcion de las opciones (tags a) en sidenav
    Promise.resolve(null).then(() => this.getIdsAndDescriptionSidenavMenuOptions());
    if (this.showAlert) {
      this.alerta.generarDialogo(this.idiomas, "MensajeTranferenciasSPI")
    }
    this.consultarNotificaciones();
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()

    if (this.isLargeScreenForSideNav() && !this.bandMobile) {
      Promise.resolve(null).then(() => this.abrirMenu(true));
    }
    window.scrollTo(0, 0);
    this.selectSkinBackground()

    //Obtiene id y descripcion de las opciones (tags a) en sidenav
    Promise.resolve(null).then(() => this.getIdsAndDescriptionSidenavMenuOptions());
    if (this.intentoDialogoInicio && !this.bandMobile) {
      this.alerta.generarDialogoSeguroInicio(this.idiomas, this.loginInfo.nombrePreferido).then((data) => {
        this.consultarContratoHB()
      })
    }

    if (!localStorage.getItem("pushNotifications") && this.bandMobile) {
      this.enableNotification();
    } else if (localStorage.getItem("pushNotifications") && this.bandMobile) {
      this.consultarAuthNotifications(JSON.parse(localStorage.getItem("pushNotifications")))
    }
  }

  enableNotification() {
    this.notificationService.subscribeToNotifications().then((data: any) => {
      if (data) {
        var json = JSON.parse(JSON.stringify(data))
        this.consultarAuthNotifications(json)
      } else {
        localStorage.setItem("pushNotifications", "No autorizado");
      }
    }, (err) => {
      localStorage.setItem("pushNotifications", "No autorizado");
    });
  }

  consultarAuthNotifications(json: any) {
    let envio = {
      "transaccion": "183009-con-endpoint-in.xml",
      usuario: this.base.usuario,
      "cri^tdep0^CPERSONA^NORMAL^tdep0^=": "$personCode",
      "cri^tdep0^CUSUARIO^NORMAL^tdep0^=": "$userCode",
      "fullNames": "true"
    }

    this.apiService.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.tdep0.length < 1) {
        this.sendAuthNotifications(json)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
      }
    });
  }

  sendAuthNotifications(json: any) {
    var cEndPoint = "";
    var request = {
      transaccion: "183009-man-endpoint-in.xml",
      usuario: this.base.usuario,
      "rec^tdep0^0^ENDPOINT^tdep0": json.endpoint,
      "rec^tdep0^0^AUTH^tdep0": json.keys.auth,
      "rec^tdep0^0^P256DH^tdep0": json.keys.p256dh,
      "rec^tdep0^0^APLICATIVO^tdep0": "HB",
      "rec^tdep0^0^CPERSONA^tdep0": "$personCode",
      "rec^tdep0^0^CUSUARIO^tdep0": "$userCode"
    }
    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, request).then((response: any) => {
      if (response) {
        for (let endPoint of response.tdep0) {
          cEndPoint = endPoint.CDISPOSITIVOENDPOINT
          localStorage.setItem("cEndPoitn", cEndPoint)
          break
        }
        localStorage.setItem("pushNotifications", JSON.stringify(json))
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          this.alerta.presentarAlerta(err.error.mensaje);
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
      }
    });
  }

  caducarNotifications() {
    var request = {
      transaccion: "183009-man-caducar-endpoint.xml",
      usuario: this.base.usuario,
    }
    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, request).then((response: any) => {
      if (response) {
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          this.alerta.presentarAlerta(err.error.mensaje);
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
      }
    });
  }

  consultarContratoHB() {
    let envio = {
      "transaccion": "010003-con-contratohb.xml",
      "cri^tpc0^CPERSONA^NORMAL^tpc0^=": "$personCode",
      "fullNames": "true"
    }
    let reqJSON = { id: '0000000000', tipoDoc: 'CED', tipoUsuario: 'HBA' }
    this.apiService.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.tpc0.length < 1) {
        this.alerta.generarDialogoSeguroContrato(this.idiomas, 'assets/resources/mipmap-hdpi/contratoTest.png', reqJSON).then((data) => {
          this.consultarSesionesUsuario();
        })
      } else {
        this.consultarSesionesUsuario();
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
      }
    });
  }

  consultarSesionesUsuario() {
    let envio = {
      "transaccion": "185003-con-transferecias-rating.xml",
      "cri^tm0^CPERSONA^NORMAL^tm0^=": "$personCode",
      "fullNames": "true"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.tm0.length > 3) {
        this.consultarRating();
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
      }
    });
  }

  consultarRating() {
    let envio = {
      "transaccion": "183073-con-rating.xml",
      "cri^tci0^CUSUARIO^NORMAL^tci0^=": "$userCode",
      "fullNames": "true"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.tci0.length == 0) {
        this.alerta.generarDialogoSeguroRating(this.idiomas);
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
      }
    });
  }


  consultarNotificaciones() {
    let envio = {
      "transaccion": "010003-con-notificaciones.xml",
      "cri^tpc0^CPERSONA^NORMAL^tpc0^=": "$personCode",
      "cri^tpc0^FCADUCIDAD^NORMAL^tpc0^>=": "$accountingDate",
      "cri^tpc1^CPERSONA^NORMAL^tpc1^=": "$personCode",
      "cri^tcp0^CPERSONA^NORMAL^tcc0^=": "$personCode",
      "cri^tcc0^FCADUCIDAD^NORMAL^tcc0^>=": "$accountingDate",
      "cri^tcp0^CPERSONA^NORMAL^tcc2^=": "$personCode",
      "fullNames": "true"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.tpc0.length > 0) {
        this.notificaciones = data.tpc0.length
      } else {
        this.notificaciones = 0
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
      }
    });
  }

  getIdsAndDescriptionSidenavMenuOptions() {
    this.availableTransactionsArray = []
    let aTags = this.matNavList.nativeElement.getElementsByTagName('a')
    for (let i = 0; i < aTags.length; i++) {
      if (aTags.item(i).id) {
        let descTag = aTags.item(i).firstChild.textContent
        if (aTags.item(i).id == 'tarPend') {
          this.availableTransactionsArray.push({ txCode: aTags.item(i).id, txDesc: this.idiomas.TarPend })
        } else {
          this.availableTransactionsArray.push({ txCode: aTags.item(i).id, txDesc: descTag.substr(descTag.indexOf(' ') + 1) })
        }
      }
    }
    this.availableTransactionsArraySearched = this.availableTransactionsArray;
  }

  getTabsXposition() {
    //Para Cambio de Width en ToolBar cuando sidenav en modo over
    if ((window.innerWidth <= this.referenceWidth) && (this.toolBar.nativeElement.style.width != '100%')) {
      this.toolBar.nativeElement.style.width = '100%'
    }
  }

  logout() {
    this.alerta.clearAlerta();
    this.alerta.generarDialogoLogin(0, true, this.idiomas, this.base.id_token);
  }

  move(labelTxSearched) {
    document.querySelector('.mat-sidenav-content').scrollTop = 0;
    try {
      if (labelTxSearched == 'PosicionConsolidada') {
        this.posiConso.refreshPosCons()
      }
      this.account = ""
    } catch (e) { }
    this.shownTransactionsTabs = {}
    //Si tab con label enviada no estaba activo para ser mostrado, se lo activa
    if (!this.shownTransactionsTabs[labelTxSearched]) {
      this.shownTransactionsTabs[labelTxSearched] = { favorite: false }
      this.shownTransactionsTabsLabelsArray.push(labelTxSearched)
      this.selectedTabLabel = labelTxSearched
      this.bandTabsAddedOrRemoved = true
      if (this.toolBar.nativeElement.style.width == '100%') {
        this.snav.close();
        window.dispatchEvent(new Event('resize'));
      }
      return;
    }//.

    let indexAux = null;
    this.matTabContentGroupObj._tabs.toArray().map((tabLabel, index) => {
      if (labelTxSearched == tabLabel.textLabel) {
        indexAux = index
      }
    })
    if (indexAux == null) { //En caso de no encontrar tab con label enviada como parametro
      return;
    }
    this.selectedIndex1 = indexAux;
    if (!this.isLargeScreenForSideNav()) {
      this.snav.close()
    }
    this.selectedTabLabel = labelTxSearched
    this.bandTabsAddedOrRemoved = false
  }

  selectedIndexChange(val) {
    this.selectedIndex = val;
    this.panBand = false;
    this.alerta.clearAlerta();
  }

  selectedIndexChange1(val) {
    if (!this.bandTabsAddedOrRemoved) {
      this.selectedTabLabel = this.matTabContentGroupObj._tabs.toArray()[val].textLabel
      this.selectedIndex1 = val;
      if ((this.bandScrollMoveTabs) && (this.tamMatTabLabelContainer < this.tamMatTabLIst)) {
        this.moveSpecificTabList();
      }
      this.bandScrollMoveTabs = false
    }
  }

  moveSpecificTabList() {
    let deltaX = 0;
    for (let i = 0; i < this.selectedIndex1; i++) {
      deltaX += this.arrayPositionsTabs[i].width;
    }
    deltaX *= -1
  }

  clickMatNav(val) {
    var leftPositionRow = ((this.snav.opened ? this.snavRef.nativeElement.getBoundingClientRect().width - 4 : 0) + 35)
    if ((val.clientX > leftPositionRow) && (val.clientX < window.innerWidth - 45)) {
      this.selectedSNav(true);
    }
    else {
      this.bandScrollMoveTabs = !this.bandScrollMoveTabs ? true : false
      if (val.clientX > leftPositionRow) { //Flecha derecha
        if (this.selectedIndex1 < this.numNoSelectedTabs) {
          this.selectedIndex1++;
        }
      }
      else { //Flecha izquierda
        if (this.selectedIndex1 > 0) {
          this.selectedIndex1--;
        }
      }
    }
  }

  selectedSNav(bandHideAlert) {
    if (bandHideAlert) {
      this.alerta.clearAlerta();
    }
    //Para Fucionamiento de Seleccion de Toolbar
    this.selectedIndex = this.selectedIndex1;
    //Para estilo de SiveNav Seleccionado
    for (let txtLabel of this.shownTransactionsTabsLabelsArray) {
      var listItemTemp = document.getElementById(txtLabel)
      if (listItemTemp) {
        if (txtLabel != this.selectedTabLabel) {
          if (this.grande) {
            listItemTemp.setAttribute("class", "mat-list-item menuItemNoSelected grandesidenav");
          } else {
            if (this.mediano) {
              listItemTemp.setAttribute("class", "mat-list-item menuItemNoSelected medianosidenav");
            } else {
              listItemTemp.setAttribute("class", "mat-list-item menuItemNoSelected");
            }
          }
        } else {
          if (this.grande) {
            listItemTemp.setAttribute("class", "mat-list-item menuItemSelected grandesidenav");
          } else {
            if (this.mediano) {
              listItemTemp.setAttribute("class", "mat-list-item menuItemSelected medianosidenav");
            } else {
              listItemTemp.setAttribute("class", "mat-list-item menuItemSelected ");
            }
          }
        }
      }
    }
    window.dispatchEvent(new Event('resize'));
  }

  panl() {
    if (this.selectedIndex1 == 0) {
      this.selectedIndex1 = 0;
      this.panBand = false;
    } else {
      if (this.contPanGesture <= 8) {
        this.contPanGesture += 1;
      }
      else {
        this.contPanGesture = 0;
        this.selectedIndex1 += -1;
      }
      this.panBand = true;
    }
  }

  panr() {
    if (this.selectedIndex1 == 0) {
      this.selectedIndex1 = 0;
      this.panBand = false;
    } else {
      if (this.contPanGesture <= 8) {
        this.contPanGesture += 1;
      }
      else {
        this.contPanGesture = 0;
        this.selectedIndex1 += -1;
      }
      this.panBand = true;
    }
  }

  //Gestos de cambio de pestana segementado, NO se realiza el gesto cuando se lo ejecuta desde una tabla
  izq(evt) {
    let tablas;
    let tablasAux = [];
    let moveband = true;
    try {
      tablas = document.getElementsByTagName("table");
    } catch (error) { }
    if (tablas.length > 0) {
      for (let i = 0; i < tablas.length; i++) {
        if ((tablas[i].getBoundingClientRect().top > 0) && (tablas[i].getBoundingClientRect().top < screen.height)) {
          tablasAux.push(tablas[i]);
        }
      }
      for (let i = 0; i < tablasAux.length; i++) {
        let tabTop = tablasAux[i].getBoundingClientRect().top;
        let tabHeight = tablasAux[i].getBoundingClientRect().height;
        if ((evt.center.y >= tabTop) && (evt.center.y <= (tabTop + tabHeight))) {
          moveband = false;
          break;
        }
      }
    }
    if (moveband) {
      if (this.selectedIndex == 0) {
        this.selectedIndex = 0;
      } else {
        this.selectedIndex += -1
      }
      this.selectedIndex1 = this.selectedIndex;
      window.scrollTo(0, 0);
      this.selectedSNav(true);
    }
  }

  right(evt) {
    let tablas;
    let tablasAux = [];
    let moveband = true;
    try {
      tablas = document.getElementsByTagName("table");
    } catch (error) { }
    if (tablas.length > 0) {
      for (let i = 0; i < tablas.length; i++) {
        if ((tablas[i].getBoundingClientRect().top > 0) && (tablas[i].getBoundingClientRect().top < screen.height)) {
          tablasAux.push(tablas[i]);
        }
      }
      for (let i = 0; i < tablasAux.length; i++) {
        let tabTop = tablasAux[i].getBoundingClientRect().top;
        let tabHeight = tablasAux[i].getBoundingClientRect().height;
        if ((evt.center.y >= tabTop) && (evt.center.y <= (tabTop + tabHeight))) {
          moveband = false;
          break;
        }
      }
    }
    if (moveband) {
      this.selectedIndex += 1
      this.selectedIndex1 = this.selectedIndex;
      window.scrollTo(0, 0);
      this.selectedSNav(true);
    }
  }

  toSpecificTab(event) {
    this.selectedIndex = event.sonTab;
    this.selectedIndex1 = this.selectedIndex;
    this.selectedSNav(true);
    switch (event.tarPendType) {
      case 'regBen': //Registro beneficiario
        {
          this.modTransferencias.habilitarFunciones('beneficiarios', event);
        } break;
      case 'transferCtasPropias': //Transferencia Cuentas Propias
        {
          this.modTransferencias.habilitarFunciones('transCtsProp', event);
        } break;
      case 'transferCtasTer': //Transferencia Terceros Misma Institucion
        {
          this.modTransferencias.habilitarFunciones('transMismaInst', event);
        } break;
      case 'transferCtasOtr': //Transferencias Otros Bancos
        {
          this.modTransferencias.habilitarFunciones('transOtraInst', event);
        } break;
      case 'regBenTc': //Registro beneficiario tarjeta de credito
        {
          this.modTransferenciasTarCred.habilitarFunciones('beneficiarios', event);
        } break;
      case 'transferCtasOtrTarCred': //transferencias tarjeta credito
        {
          this.modTransferenciasTarCred.habilitarFunciones('transTarCred', event);
        } break;
      case 'regServ': //Registro Servicios
        {
          this.modServicios.habilitarFunciones('servicios', event);
        } break;
      case 'pagoServ': //Pago Servicios
        {
          this.modServicios.habilitarFunciones('pagoServicios', event);
        } break;
      case 'recargas': //Recargas
        {
          this.modServicios.habilitarFunciones('recargas', event);
        } break;
      case 'plazoFijo': //Plazo fijo
        {
          this.modServicios.habilitarFunciones('plazoFijo', event);
        }
    }
  }

  posiConsoToMultitab(event) {
    var element = document.getElementById('PosicionConsolidada') as HTMLElement;
    if (element) {
      element.click();
    }
    setTimeout(() => { this.posiConsoMultiTab.createComponentPreloadData(event) }, 60);
    setTimeout(() => { this.matNavList.nativeElement.click() }, 10);
  }

  numTarPend(event) {
    if (this.numTareasPendientesPrev != event) {
      Promise.resolve(null).then(() => this.numTareasPendientes = event);
      this.numTareasPendientesPrev = event;
    }
  }

  abrirMenu(evt) {
    this.snav.open()
    window.dispatchEvent(new Event('resize'));
  }

  cerrarMenu(evt) {
    this.snav.close();
    window.dispatchEvent(new Event('resize'));
  }

  toolbarWidth() {
    if (!this.bandMobile) {
      document.getElementById('btnMenu').blur();
    }
    let snavWidth = 0
    snavWidth = this.snavRef.nativeElement.getBoundingClientRect().width
    let toolbarPercentage = 100
    if (this.isLargeScreenForSideNav()) {
      if (this.snav.opened) {
        toolbarPercentage = 100 - ((305 * 100) / window.innerWidth)
      }
      this.toolBar.nativeElement.style.width = toolbarPercentage + '%'
      this.snavWidthChangeband = false;
    }
    this.subTabsHeaderContainerWidth = toolbarPercentage
  }

  isLargeScreenForSideNav() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > this.referenceWidth && !this.bandMobile) {
      return true;
    } else {
      return false;
    }
  }

  doBlur() {
    document.getElementById('btnCerrarSesion').blur();
  }

  espanol() {
    this.idiomas = this.idm.toEspanol()
    this.dateAdapter.setLocale('es')
    this.locale = 'es'
    localStorage.setItem('locale', 'es')
    localStorage.setItem('idioma', JSON.stringify(this.idiomas));
    window.location.reload();
    if (this.snav.opened && this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
    setTimeout(() => { this.getIdsAndDescriptionSidenavMenuOptions() }, 100);
  }

  ingles() {
    this.idiomas = this.idm.toEnglish()
    this.dateAdapter.setLocale('en')
    this.locale = 'en'
    localStorage.setItem('locale', 'en')
    localStorage.setItem('idioma', JSON.stringify(this.idiomas));
    window.location.reload();
    if (this.snav.opened && this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
    setTimeout(() => { this.getIdsAndDescriptionSidenavMenuOptions() }, 100);
  }

  Grande(auto) {
    if (this.grande)
      return
    if (!auto) { //Se guarda fontSize solamente si fue seleccionado por el usuario
      localStorage.setItem('fontSize', 'large');
    }
    Promise.resolve(null).then(() => this.grande = true);
    Promise.resolve(null).then(() => this.mediano = false);
    Promise.resolve(null).then(() => this.normal = false);
    localStorage.setItem('fontSize', 'large');
    if (this.snav.opened && this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  Mediano(auto) {
    if (this.mediano)
      return
    if (!auto) { //Se guarda fontSize solamente si fue seleccionado por el usuario
      localStorage.setItem('fontSize', 'medium');
    }
    Promise.resolve(null).then(() => this.mediano = true);
    Promise.resolve(null).then(() => this.grande = false);
    Promise.resolve(null).then(() => this.normal = false);
    localStorage.setItem('fontSize', 'medium');
    if (this.snav.opened && this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  Normal(auto) {
    if (this.normal)
      return
    if (!auto) { //Se guarda fontSize solamente si fue seleccionado por el usuario
      localStorage.setItem('fontSize', 'small');
    }
    Promise.resolve(null).then(() => this.normal = true);
    Promise.resolve(null).then(() => this.mediano = false);
    Promise.resolve(null).then(() => this.grande = false);
    localStorage.setItem('fontSize', 'small');
    if (this.snav.opened && this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  resizeToolbar() {
    if (this.isLargeScreenForSideNav()) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  closeTab(talLabelToClose) {
    //Si tab con label enviada estaba activo, se lo desactiva - oculta
    if (this.shownTransactionsTabs[talLabelToClose]) {
      var indexOfClosedTab = this.shownTransactionsTabsLabelsArray.indexOf(talLabelToClose)
      if (talLabelToClose == this.selectedTabLabel) { //Se cierra tab activo
        if (indexOfClosedTab > 0) {
          this.selectedTabLabel = this.shownTransactionsTabsLabelsArray[indexOfClosedTab - 1]
        } else if (this.shownTransactionsTabsLabelsArray.length > 1) {
          this.selectedTabLabel = this.shownTransactionsTabsLabelsArray[indexOfClosedTab + 1]
        } else { //Se cierra ultimo tab
          if (!this.shownTransactionsTabs['homeTab']) {
            this.shownTransactionsTabs = { 'homeTab': { favorite: false } }
            this.shownTransactionsTabsLabelsArray.push('homeTab')
            this.selectedTabLabel = 'homeTab'
          }
          else {
            return;
          }
        }
      }
      this.selectedSNav(false); //Se ejecuta funcion de estilo de opciones en mat side nav antes de aliminar label del arreglo
      this.shownTransactionsTabsLabelsArray.splice(indexOfClosedTab, 1)
      delete this.shownTransactionsTabs[talLabelToClose]
      this.bandTabsAddedOrRemoved = true
    }//.
  }

  filterTransactionSearchedArray(query) {
    this.availableTransactionsArraySearched = this.availableTransactionsArray.filter(a => {
      if ((a.txCode + a.txDesc).toLowerCase().indexOf(query) > -1) {
        return true;
      }
    })
  }

  resetsearchedInput(searchedInput) {
    //console.log(searchedInput)
  }

  closePanels() {
    this.transferPanelRef.close()
    this.creditCardPanelRef.close()
    this.servicePanelRef.close()
    this.adminPanelRef.close()
  }

  openEComm(value) {
    var tabName = ""
    if (value == 1) {
      tabName = "Socio"
    } else {
      tabName = "Administrador"
    }
    this.router.navigate(['/ECommerce/' + tabName])
  }

  openBottomMenu() {
    this.menuInferior.mostrarMenu(this.idiomas, this.notificaciones, this.numTareasPendientes).then((data: any) => {
      if (data) {
        if (data == 0 || data == 1) {
          this.openEComm(data)
        } else if (data === 3) {
          this.logout()
        } else {
          this.move(data);
          this.selectedSNav(true);
          this.closePanels();
        }
      }
      else {
        this.tabIndex = 0
      }
    });
  }

  operationEvent(event) {
    this.operation = event.transaction
    this.account = event.account
  }

  cambiarTema(band): void {
    //"night-theme", "light-theme", "light-theme-verde", "light-theme-gris", "light-theme-azul"
    if (band == 0) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "night-theme";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (band == 1) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "light-theme";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (band == 2) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "light-theme-verde";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (band == 3) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "light-theme-azul";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (this.temaSeleccionadoPrev != this.temaSeleccionado) {
      if (this.overlay.getContainerElement().classList.contains(this.temaSeleccionadoPrev)) {
        this.overlay.getContainerElement().classList.remove(this.temaSeleccionadoPrev);
        this.overlay.getContainerElement().classList.add(this.temaSeleccionado);
      } else {
        this.overlay.getContainerElement().classList.add(this.temaSeleccionado);
      }
      if (document.body.classList.contains(this.temaSeleccionadoPrev)) {
        document.body.classList.remove(this.temaSeleccionadoPrev);
        document.body.classList.add(this.temaSeleccionado);
      } else {
        document.body.classList.add(this.temaSeleccionado);
      }
      if (this.temaSeleccionado != "night-theme") {
        this.logoTemaOscuro = false;
      } else {
        this.logoTemaOscuro = true;
      }
    }
    this.selectSkinBackground()
  }

  cambiarSkin(band): void {
    let skinDisponibles = ["skin-aestetic", "skin-compact"]
    if (band == 0) {
      this.skinSeleccionadoBand = band;
      this.skinSeleccionado = skinDisponibles[band];
      localStorage.setItem('skinDisplayed', this.skinSeleccionado);
    }
    if (band == 1) {
      this.skinSeleccionadoBand = band;
      this.skinSeleccionado = skinDisponibles[band];
      localStorage.setItem('skinDisplayed', this.skinSeleccionado);
    }
    window.location.reload();
  }

  openCodeQR() {
    this.intento = true;
    const envio = {
      'transaccion': '010003-lv-datoQR-in.xml',
      'cri^tu0^CUSUARIO^JOIN^tc0^=': "$userCode",
      "fullNames": "true"
    }
    var textQR = ""
    this.apiService.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data) {
        textQR = data.tc0[0]['tc0.CCUENTA'] + ";" + data.tc0[0]['tc0.CMONEDA'] + ";" + data.tc0[0]['tc0.CPERSONA_COMPANIA'] + ";" + data.tc0[0]['tp1.IDENTIFICACION'] + ";" + data.tc0[0]['tp1.CTIPOIDENTIFICACION'] + ";" + data.tc0[0]['tp1.NOMBRELEGAL'] + ";" + data.tc0[0]['tpd0.DIRECCION'] + ";" + data.tc0[0]['tpt0.NUMEROTELEFONO'] + ";" + data.tc0[0]['vpif0.CUENTABANCOINSTITUCION'];
        this.data = this.servicios.encriptar(textQR).toString();
        this.intento = false
        this.alerta.generarDialogoQR(this.idiomas, this.data, true).then((data: any) => {
          if (data) {
            this.tabIndex = 0
          } else {
            this.tabIndex = 0
          }
        });
      }
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet 
      }
    });
  }

}
