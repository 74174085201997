<div class="textura">
    <div *ngIf="!intentoMessage">
        <div style="text-align: right;">
            <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <h2 mat-dialog-title style="text-align: center;"><strong>{{idiomas.estodoCuentaTC}}</strong></h2>
        <mat-dialog-content>
            <div *ngIf=intento>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar><br>
            </div>
            <div class="form-row">
                <div *ngIf="showPDF" class="col-12" style="text-align: center;">
                    <mat-label>{{idiomas.GenerarPDF}}</mat-label>
                </div>
                <div *ngIf="!bandIos" class="col-6" style="text-align: center;">
                    <button mat-raised-button (click)="sharePDF()" color="primary" class="buttonToken">
                        <mat-icon>share</mat-icon>
                        {{idiomas.Compartir | uppercase}}
                    </button>
                </div>
                <div *ngIf="!bandIos" class="col-6" style="text-align: center;">
                    <button mat-raised-button (click)="reportePDF()" color="primary" class="buttonToken">
                        <mat-icon>cloud_download</mat-icon>
                        {{idiomas.Descargar | uppercase}}
                    </button>
                </div>
                <div *ngIf="showPDF && bandIos" class="col-12" style="text-align: center;">
                    <button mat-raised-button (click)="reportePDF()" color="primary" class="buttonToken">
                        <mat-icon>pageview</mat-icon>
                        {{idiomas.VisualizarComprobante | uppercase}}
                    </button>
                </div>
                <div *ngIf="showPDF" class="col-12" style="text-align: center; padding-top: 2%;">
                    <mat-label>{{idiomas.GenerarXLS}}</mat-label>
                </div>
                <div *ngIf="!bandIos" class="col-12" style="text-align: center;">
                    <button mat-raised-button (click)="reporteXLS()" color="primary" class="buttonToken">
                        <mat-icon>cloud_download</mat-icon>
                        {{idiomas.Descargar | uppercase}}
                    </button>
                </div>
                <div *ngIf="showPDF && bandIos" class="col-12" style="text-align: center;">
                    <button mat-raised-button (click)="reporteXLS()" color="primary" class="buttonToken">
                        <mat-icon>cloud_download</mat-icon>
                        {{idiomas.Descargar | uppercase}}
                    </button>
                </div>
            </div>
            <div *ngIf="!showPDF && bandIos">
                <ngx-extended-pdf-viewer #pdfViewer [base64Src]="base64URL" backgroundColor="#ffffff" [handTool]=false
                    [height]="'90vh'" [useBrowserLocale]="true" [textLayer]="false" [customToolbar]="multiToolbar">
                </ngx-extended-pdf-viewer>
                <ng-template #multiToolbar>
                    <div style="text-align: center;">
                        <button mat-icon-button id="zoomIn">
                            <mat-icon>zoom_in</mat-icon>
                        </button>
                        <button mat-icon-button id="zoomOut">
                            <mat-icon>zoom_out</mat-icon>
                        </button>
                        <button mat-icon-button id="download">
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                    </div>
                </ng-template>
            </div>
        </mat-dialog-content>
    </div>
</div>