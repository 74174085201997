import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CambiarVariableCuenta, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatCardModule, MatProgressBarModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, CambiarVariableCuenta, OcultarCuentaPosTrans, FlexLayoutModule],
  selector: 'app-dialogo-confirmacion-transferencia',
  templateUrl: './dialogo-confirmacion-transferencia.component.html',
  styleUrls: ['./dialogo-confirmacion-transferencia.component.css']
})
export class DialogoConfirmacionTransferenciaComponent {

  er: boolean = false;
  aceptar: string;
  rechazar: string;
  idiomas: any;
  intento: boolean;
  dato: any;
  fecha: any;
  transaccion: any;
  evento: any;
  isBenefQR: boolean;
  beneficiaryExist: boolean;

  constructor(public base: BaseDatos, public apiService: ApiService, private dialogRef: MatDialogRef<DialogoConfirmacionTransferenciaComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.transaccion = data.transaccion;
    this.aceptar = data.idm.Aceptar;
    this.rechazar = data.idm.Rechazar;
    this.idiomas = data.idm;
    this.evento = data.evento;
    this.dato = data.mensajeData;
    this.intento = false;
    this.isBenefQR = this.dato.isBeneQR == '1' ? true : false;
    this.beneficiaryExist = this.dato.beneficiaryExist == '1' ? true : false;
  }

  ngOnInit(): void {
    this.fecha = this.getCurrentTimeAsTimestamp();
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date()
    const year = now.getFullYear()
    const month = this.completeDigits(now.getMonth() + 1)
    const day = this.completeDigits(now.getDate())

    const seconds = now.getSeconds();
    const minutes = now.getMinutes();
    const hour = now.getHours();
    return `${year}-${month}-${day} ${hour}:${minutes}`
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString()
  }

  accept() {
    this.dialogRef.close(this.er = true);
  }
  close() {
    this.dialogRef.close(this.er);
  }

  saveBene() {
    this.intento = true;
    let envio = {
      transaccion: '183211-ingreo-beneficiaro-QR.xml',
      usuario: this.base.usuario,
      'rec^tprb0^0^CUENTABENEFICIARIO': this.dato.cuentaBe,
      'rec^tprb0^0^CPERSONA': "$personCode",
      'rec^tprb0^0^IDENTIFICACIONBENEFICIARIO': this.dato.identificacionBeneficiario,
      'rec^tprb0^0^ORIGENBENEFICIARIO^tprb0^VAL': "MBK",
      'rec^tprb0^0^ORIGENBENEFICIARIO^tprb0^oldval': "QR",
      'rec^tprb0^0^VERSIONCONTROL': "0",
    }
    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
      if(data){
        this.isBenefQR = false;
        this.beneficiaryExist = false;
        this.intento = false;
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
        }
        this.intento = false
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

}
