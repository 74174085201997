import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControlValidators } from '../funciones/formcontrol';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, CommonModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, GoogleMapsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-cajeros-mapa',
  templateUrl: './cajeros-mapa.component.html',
  styleUrls: ['./cajeros-mapa.component.css']
})
export class CajerosMapaComponent implements OnInit {

  @Input() idiomas: any;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) markers: QueryList<MapMarker>;

  zoom: number;
  icon: string;
  options: google.maps.MapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: true,
    maxZoom: 15,
    minZoom: 8,
  };
  center: google.maps.LatLngLiteral = {
    lat: -3.994106492862794,
    lng: -79.2043451148402
  };

  cajeros: any[] = [
    {
      nombre: "Cajero Agencia Matriz",
      direccion: "Mercadillo 162-18 y  Olmedo",
      position: {
        lat: -4.001136235497766,
        lng: -79.19945374886774,
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Centro",
      direccion: "18 de noviembre y José Antonio Eguiguren",
      position: {
        lat: -3.994106492862794,
        lng: -79.2043451148402
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Loja",
      direccion: "Av. Gran Colombia y Ancón",
      position: {
        lat: -3.9880440143285845,
        lng: -79.20450743302807
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Catamayo",
      direccion: "Av. Catamayo e Isidro Ayora y Bolívar",
      position: {
        lat: -3.986782838468071,
        lng: -79.35702925444859
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Yantzaza",
      direccion: "Jorge Mosquera y 26 de Febrero",
      position: {
        lat: -3.8337436450845863,
        lng: -78.76178381916085
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia El Pangui",
      direccion: "Calle Loja e Benigno Cruz y  Av. Jorge Mosquera",
      position: {
        lat: -3.628724100828159,
        lng: -78.5888334668854
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Gonzanamá",
      direccion: "Loja entre Bolívar y Av. 10 de Agosto",
      position: {
        lat: -4.230241421474629,
        lng: -79.43701073350981
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Amaluza",
      direccion: "Francisca Chigua y Manuel Rojas",
      position: {
        lat: -4.582142352328481,
        lng: -79.42704416503823
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Cariamanga",
      direccion: "Velasco Ibarra y 18 de Noviembre",
      position: {
        lat: -4.3259404561123285,
        lng: -79.55655778746713,
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Piñas",
      direccion: "Juan José Loaiza e Garcia Moreno y Juan León Mera",
      position: {
        lat: -3.6812984302589418,
        lng: -79.68205962952548
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Celica",
      direccion: "Calle Garcia Moreno y 12 de Diciembre",
      position: {
        lat: -4.103761600742111,
        lng: -79.95594136168698
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Quito Sur",
      direccion: "Av. Mariscal Sucre y Ajaví",
      position: {
        lat: -0.257070405500075,
        lng: -78.54307972102632
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Hipervalle",
      direccion: "Av. Orillas del Zamora y Azoguez",
      position: {
        lat: -3.9806063036340813,
        lng: -79.20240179623933
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero La Pileta",
      direccion: "Prol. 24 de Mayo y calle Victor Vivar",
      position: {
        lat: -3.9945666590039686,
        lng: -79.19843721779408
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Tebaida",
      direccion: "Av. Pio Jaramillo Alvarado y Av. Gobernación de Mainas",
      position: {
        lat: -4.011907352085369,
        lng: -79.20437549383128
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    },
    {
      nombre: "Cajero Agencia Santo Domingo",
      direccion: "Av. Quito entre Rio Mulaute y Calle Pallatanga",
      position: {
        lat: -0.25074654565234333,
        lng: -79.16353832513592
      },
      horarioslv: "00h00 a 23H59 (Todo el día)",
      horariossa: "00h00 a 23H59 (Todo el día)",
      horariosdo: "00h00 a 23H59 (Todo el día)",
      open: false
    }
  ]

  constructor(private validators: FormControlValidators) {
    this.icon = "assets/resources/mipmap-hdpi/cajerosUbicacion.png"
    this.zoom = 15
  }

  ngOnInit() {
  }

  cajerosForm = this.validators.comboForm();

  cambiarCoordenadas() {
    this.center.lat = this.cajerosForm.value.latitude;
    this.center.lng = this.cajerosForm.value.longitude;
    let curIdx = 0;
    this.zoom = 16;
    var marker;
    for (let i = 0; i < this.cajeros.length; i++) {
      if (this.cajerosForm.value.nombre == this.cajeros[i].nombre) {
        this.infoWindowsView.forEach((window: MapInfoWindow) => {
          if (i === curIdx) {
            marker = this.markers.get(i);
            window.open(marker);
            curIdx++;
          } else {
            window.close();
            curIdx++;
          }
        });
      }
    }
    this.scroll(document.getElementById("map"));
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  openInfo(marker: MapMarker, windowIndex: number) {
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        window.close();
        curIdx++;
      }
    });
  }
}
