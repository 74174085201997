import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'temporizador',
  templateUrl: './temporizador.component.html',
  styleUrls: ['./temporizador.component.css']
})
export class TemporizadorComponent {

  CRONO_VALUE: number = 120; //tiempo en segundos establecido para pedir otro token
  crono: number;
  enableCrono: boolean = false;
  interval: any;
  tiempo: string = "0:00";
  intTime: number;
  bandMobile:boolean = false;

  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;
  @Input() enableCronoIn: boolean;
  @Output() terminaCrono = new EventEmitter<boolean>();

  constructor() {
    this.intTime = 1000;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngOnChanges() {
    if (this.enableCronoIn) {
      this.enableCrono = true;
      this.onSolicitarToken();
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  onSolicitarToken() {
    this.crono = this.CRONO_VALUE;
    this.iniciarCrono();
  }

  iniciarCrono() {
    let minuto;
    let segundo;
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.crono--;
      minuto = Math.floor(this.crono / 60);
      segundo = this.crono % 60;
      if (segundo < 10) {
        this.tiempo = minuto + ":0" + segundo;
      } else {
        this.tiempo = minuto + ":" + segundo;
      }

      if (this.crono <= 0) {
        clearInterval(this.interval);
        this.enableCrono = false;
        this.terminaCrono.emit(false);
      }
    }, this.intTime);
  }

}
