import { Component, OnInit, Input } from '@angular/core';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';


@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatCardModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-alertas-login',
  templateUrl: './alertas-login.component.html',
  styleUrls: ['./alertas-login.component.css']
})
export class AlertasLoginComponent implements OnInit {

  @Input() error: boolean;
  @Input() alertalogin: boolean;
  @Input() alertaloginpass: boolean;
  @Input() mensaje: string;
  @Input() information: boolean;
  @Input() alertaBiometricCredential: boolean;

  grande: boolean = false;
  mediano: boolean = false;
  normal: boolean = false;

  constructor() {
    //Se verifica si existe fontSize almacenado
    let fontSize = localStorage.getItem('fontSize');
    if (fontSize != null) {
      switch (fontSize) {
        case 'large':
          this.grande = true;
          break;
        case 'medium':
          this.mediano = true;
          break;
        case 'small':
          this.normal = true;
          break;
        default:
          this.normal = true;
          break;
      }
    } else {
      this.mediano = true;
    }
  }


  ngDoCheck() {
    //Se verifica si existe fontSize almacenado
    let fontSize = localStorage.getItem('fontSize');
    if (fontSize != null) {
      switch (fontSize) {
        case 'large':
          this.grande = true;
          break;
        case 'medium':
          this.mediano = true;
          break;
        case 'small':
          this.normal = true;
          break;
        default:
          this.normal = true;
          break;
      }
    } else {
      this.mediano = true;
    }
  }

  ngOnInit() {
  }
}
