<div class="form-row">
    <div class="col-12" style="text-align: center">
        <h1 class="title" style="font-size: 20px;">{{ idiomas.Cajeros}}</h1>
    </div>
    <div class="col-12">
        <mat-form-field appearance="outline" style="min-width: 225px;">
            <mat-label>{{idiomas.Cajeros}}</mat-label>
            <mat-select (selectionChange)="cambiarCoordenadas()" [formControl]="cajerosForm">
                <mat-option *ngFor="let cajero of cajeros" [value]="cajero">
                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                    {{cajero.nombre}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="cajerosForm.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-12">
        <google-map id="map" height="400" width="auto" [center]="center" [zoom]="zoom" [options]="options">
            <map-marker #markerElem="mapMarker" *ngFor="let cajero of cajeros; let i = index"
                [position]="cajero.position" (mapClick)="openInfo(markerElem, i)">
                <map-info-window>
                    <p style="font-weight: bold; color: black;">{{cajero.nombre | uppercase}}</p>
                    <p style="margin-bottom: 2px; max-width: 200px; color: black;"><b>{{idiomas.Direcciones}}:</b>
                        {{cajero.direccion}}</p>
                    <p style="margin-bottom: 2px; color: black;"><b>{{idiomas.HorAtencion}}:</b></p>
                    <p style="margin-bottom: 2px; color: black;">Lunes a Viernes: {{cajero.horarioslv}}</p>
                    <p style="margin-bottom: 2px; color: black;">Sabados: {{cajero.horariossa}}</p>
                    <p style="margin-bottom: 2px; color: black;">Domingos: {{cajero.horariosdo}}</p>
                </map-info-window>
            </map-marker>
        </google-map>
        <!-- <agm-map id="map" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [streetViewControl]="false">
            <agm-marker *ngFor="let cajero of cajeros" [latitude]="cajero.latitude" [longitude]="cajero.longitude">
                <agm-info-window [disableAutoPan]="false" [isOpen]="cajero.open">
                    <p style="font-weight: bold; color: black;">{{cajero.nombre | uppercase}}</p>
                    <p style="margin-bottom: 2px; max-width: 200px; color: black;"><b>{{idiomas.Direcciones}}:</b>
                        {{cajero.direccion}}</p>
                    <p style="margin-bottom: 2px; color: black;"><b>{{idiomas.HorAtencion}}:</b></p>
                    <p style="margin-bottom: 2px; color: black;">Lunes a Viernes: {{cajero.horarioslv}}</p>
                    <p style="margin-bottom: 2px; color: black;">Sabados: {{cajero.horariossa}}</p>
                    <p style="margin-bottom: 2px; color: black;">Domingos: {{cajero.horariosdo}}</p>
                </agm-info-window>
            </agm-marker>
        </agm-map> -->
    </div>
</div>