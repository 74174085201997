import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { Servicios } from '../funciones/encryptar';
import { UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
    selector: 'app-dialogo',
    templateUrl: './dialogo.component.html',
    styleUrls: ['./dialogo.component.css']
})
export class DialogoComponent {

    mensaje: string;
    idioma: any;
    aceptar: string;

    constructor(private servicio: Servicios,
        private dialogRef: MatDialogRef<DialogoComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.mensaje = data.mensaje;
        this.idioma = data.idm1;
        this.aceptar = data.idm1.Aceptar;
    }

    close() {
        this.dialogRef.close(true);
    }
}

