<div id="transaccionesTab">
    <div>
        <div *ngIf="parametros.subsistema == 'PRESTAMOPREAPROBADO'">
            <div class="container1" *ngIf="!bandMobile">
                <table mat-table [dataSource]="data" class="prestpreap" id="tabla07PosCos">
                    <ng-container matColumnDef="Producto">
                        <th mat-header-cell *matHeaderCellDef>{{idiomas.Producto}}</th>
                        <td mat-cell *matCellDef="let dato" class="cuenta">{{idiomas.PrestamoPreaprobadoT}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Moneda">
                        <th mat-header-cell *matHeaderCellDef>{{idiomas.Moneda}}</th>
                        <td mat-cell *matCellDef="let dato" class="moneda">{{dato.cmoneda}}</td>
                    </ng-container>
                    <ng-container matColumnDef="MonMIN">
                        <th mat-header-cell *matHeaderCellDef class="right">
                            {{data[0].estado=='A'?idiomas.Monto:idiomas.MontoMin}}</th>
                        <td mat-cell *matCellDef="let dato" class="numeros">{{dato.montodesde | cantidadMonetaria}}</td>
                    </ng-container>
                    <ng-container matColumnDef="MonMAX">
                        <th mat-header-cell *matHeaderCellDef class="right">{{data[0].estado=='A'?'':idiomas.MontoMax}}
                        </th>
                        <td mat-cell *matCellDef="let dato" class="numeros">{{data[0].estado=='A'?'':dato.montohasta |
                            cantidadMonetaria}}</td>
                    </ng-container>
                    <ng-container matColumnDef="PlazoMIN">
                        <th mat-header-cell *matHeaderCellDef class="right">{{data[0].estado=='A'?'':idiomas.PlazoMin}}
                        </th>
                        <td mat-cell *matCellDef="let dato" class="numeros">
                            {{data[0].estado=='A'?'':(dato.plazodesde/30)
                            |plazoMeses}}</td>
                    </ng-container>
                    <ng-container matColumnDef="PlazoMAX">
                        <th mat-header-cell *matHeaderCellDef class="right">
                            {{data[0].estado=='A'?idiomas.Plazo:idiomas.PlazoMax}}</th>
                        <td mat-cell *matCellDef="let dato" class="numeros">{{this.numeroCuotasMax | plazoMeses}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Tasa">
                        <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Tasa}}</th>
                        <td mat-cell *matCellDef="let dato" class="tasa">{{tasaGenerada | cantidadMonetaria}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Fvigencia">
                        <th mat-header-cell *matHeaderCellDef class="center">{{idiomas.Fvigencia}}</th>
                        <td mat-cell *matCellDef="let dato" class="estado center">{{dato.fvigencia}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Estatus">
                        <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Estado}}</th>
                        <td mat-cell *matCellDef="let dato" class="estado left">{{dato.estado}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsPresAprobados" class="mat-header-row"></tr>
                    <tr mat-row
                        *matRowDef="let row; let even = even; let last = last;columns: displayedColumnsPresAprobados;"
                        [ngClass]="{gray: even}">{{TableSize(parametros.subsistema)}}</tr>
                </table>
            </div>
            <div class="container-card-mobile" *ngIf="bandMobile"
                [class.heightconteiner2]="!(data.length>numMaxRowMob)">
                <mat-list>
                    <mat-divider></mat-divider>
                    <mat-list-item *ngFor="let dato of data; let even = even; ; let i=index;">
                        <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                            <div class="col-8">
                                <div mat-line style="font-weight: bold; font-size: .9em;">
                                    <a>{{idiomas.PrestamoPreaprobadoT}}</a>
                                </div>
                            </div>
                            <div class="col-4" style="text-align: end;">
                                <div class="colorCartas" style="font-size: .9em;"> {{idiomas.Estado}}:
                                    {{dato.estado}}</div>
                            </div>
                            <div class="col-12">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <strong class="colorCartas">{{idiomas.DetalleTransferencia}}</strong>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="form-row">
                                        <div class="col-6" style="font-size: 1em;">{{idiomas.Moneda}}: {{dato.cmoneda}}
                                        </div>
                                        <div class="col-6" style="font-size: 1em;">{{idiomas.Tasa}}: {{tasaGenerada |
                                            cantidadMonetaria}}
                                        </div>
                                        <div class="col-6" style="font-size: 1em;">
                                            {{data[0].estado=='A'?idiomas.Monto:idiomas.MontoMin}}:{{dato.montodesde |
                                            cantidadMonetaria}}
                                        </div>
                                        <div class="col-6" style="font-size: 1em;">
                                            {{data[0].estado=='A'?'':idiomas.MontoMax}}:
                                            {{data[0].estado=='A'?'':dato.montohasta | cantidadMonetaria}}
                                        </div>
                                        <div class="col-6" style="font-size: 1em;">
                                            {{data[0].estado=='A'?'':idiomas.PlazoMin}}:
                                            {{data[0].estado=='A'?'':(dato.plazodesde/30) | plazoMeses}}
                                        </div>
                                        <div class="col-6" style="font-size: 1em;">
                                            {{data[0].estado=='A'?idiomas.Plazo:idiomas.PlazoMax}}:
                                            {{this.numeroCuotasMax | plazoMeses}}
                                        </div>
                                        <div class="col-12" style="font-size: 1em;">{{idiomas.Fvigencia}}:
                                            {{dato.fvigencia}}
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
    <div [class.formBottomTrans]="muestraGrafico" *ngIf="!bandMobile">
        <div *ngIf="parametros.subsistema == 'PRESTAMOPREAPROBADO'" class="form-row prestamospre formBottom">
            <label class="col-4"
                style=" padding-top: 17px ;min-width: 152px; font-size: .8em;">{{idiomas.Producto}}</label>
            <mat-form-field class="col-5" style="font-size: .8em;" appearance="outline">
                <input matInput type="text" readonly value={{idiomas.PrestamoPreaprobadoT}}>
            </mat-form-field>
            <label class="col-4"
                style="min-width: 152px; padding-top: 17px ;font-size: .8em">{{idiomas.MontomaxPre}}</label>
            <mat-form-field class="col-5" style="font-size: .8em;" appearance="outline">
                <input matInput autocomplete="off" id="montoID"
                    (focus)="montoPrestamo.setValue(this.validators.FormatNumber($event.target.value)); "
                    (change)=" calcularDatosPrestamoPreaprobado(); montoPrestamo.setValue(this.validators.FormatNumber($event.target.value)); "
                    [formControl]="montoPrestamo" maxlength="10">
                <mat-error *ngIf="montoPrestamo.hasError('minAmount')">
                    <strong>{{idiomas.montoDesde}} {{data[0].montodesde | cantidadMonetaria}} - {{data[0].montohasta |
                        cantidadMonetaria}}</strong>
                </mat-error>
                <mat-error *ngIf="montoPrestamo.hasError('maxAmount')">
                    <strong>{{idiomas.montoDesde}} {{data[0].montodesde | cantidadMonetaria}} - {{data[0].montohasta |
                        cantidadMonetaria}}</strong>
                </mat-error>
                <mat-error *ngIf="montoPrestamo.hasError('required')">
                    <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
            </mat-form-field>
            <label class="col-4"
                style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.NumeroPagos}}</label>
            <mat-form-field class="col-5" style="font-size: .8em;" appearance="outline">
                <input matInput autocomplete="off" id="cuotaID" (focus)="numeroCuotas.setValue($event.target.value); "
                    (change)=" calcularDatosPrestamoPreaprobado(); numeroCuotas.setValue($event.target.value); "
                    [formControl]="numeroCuotas" maxlength="10" type="number">
                <mat-error *ngIf="numeroCuotas.hasError('minAmount')">
                    <strong>{{idiomas.numeroCuotas}} {{numeroCuotasMin}} - {{numeroCuotasMax}}</strong>
                </mat-error>
                <mat-error *ngIf="numeroCuotas.hasError('maxAmount')">
                    <strong>{{idiomas.numeroCuotas}} {{numeroCuotasMin}} - {{numeroCuotasMax}}</strong>
                </mat-error>
                <mat-error *ngIf="numeroCuotas.hasError('required')">
                    <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="numeroCuotas.hasError('pattern')">
                    <strong>{{idiomas.ValorEntero}}</strong>
                </mat-error>
            </mat-form-field>
            <label class="col-4"
                style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.FijoPago}}</label>
            <mat-form-field *ngIf="data[0].estado=='P'" class="col-5" style="font-size: .8em" appearance="fill">
                <input matInput readonly [ngModel]="fechaF | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
                    [ngModelOptions]="{standalone: true}">
                <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="fechaF" [min]="minF" [max]="maxF"
                    [matDatepicker]="pickerdesde">
                <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
                <mat-datepicker touchUi #pickerdesde></mat-datepicker>
            </mat-form-field>
            <label class="col-4"
                style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.Tinteres}}</label>
            <mat-form-field class="col-5" style="font-size: .8em" appearance="fill">
                <input matInput type="number" readonly value="{{tasaGenerada | cantidadMonetaria}}">
            </mat-form-field>
            <label class="col-4" *ngIf="mostrarCuenta"
                style="min-width: 152px; padding-top: 17px ;font-size: .8em">{{idiomas.CuentaAhorro}}</label>
            <div class="col-5" style="min-width: 160px;" *ngIf="mostrarCuenta">
                <mat-form-field appearance="outline" fxFlexFill>
                    <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                    <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="selectedOptionOrigen()"
                        [compareWith]="compareJsonPerValue">
                        <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta">
                            {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                            <mat-divider style="border-top-width: 3px;"></mat-divider>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <label *ngIf="mostrarTerminos" class="col-4"
                style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.CuotaPrestamos}}</label>
            <mat-form-field *ngIf="mostrarTerminos" class="col-5" style="font-size: .8em" appearance="fill">
                <input matInput type="text" readonly value="{{cuotaAproximada.value | cantidadMonetaria}}">
            </mat-form-field>
            <div *ngIf="mostrarTerminos" class="col-md-12">
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedC" (change)="informacionI($event)"
                        [disabled]="deshabilitarC"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.Descuentos}}</label>
                </div>
                <label class="col-3"
                    style="min-width: 152px; padding-top: 17px; font-size: .8em; ">{{idiomas.FondoReserva}}</label>
                <mat-form-field class="col-2" style="font-size: .8em; min-width: 120px;" appearance="fill">
                    <input matInput type="number" readonly value="{{valorF | cantidadMonetaria}}">
                </mat-form-field>
                <label class="col-3"
                    style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.ImpuestoSolca}}{{idiomas.And}}{{idiomas.TesoroNacional}}</label>
                <mat-form-field class="col-2" style="font-size: .8em; min-width: 120px" appearance="fill">
                    <input matInput type="number" readonly value="{{valorS | cantidadMonetaria}}">
                </mat-form-field>
                <label class="col-3"
                    style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.SeguroDesgravamen}}</label>
                <mat-form-field class="col-2" style="font-size: .8em; min-width: 120px" appearance="fill">
                    <input matInput type="number" readonly value="{{valorSe | cantidadMonetaria}}">
                </mat-form-field>
                <div class="rowCardTopSpace col-lg-10 col-md-10 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedCP" (change)="informacionCP($event) ">
                        {{idiomas.AutorizaGCredPer}}</mat-checkbox>
                    <label
                        style="padding-left: 5px; padding-bottom: 15px; text-align: justify !important;">{{idiomas.AutorizoCredPer}}</label>
                </div>
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedI" (change)="informacionC($event)"
                        [disabled]="deshabilitarI"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.AceptarT1}}<u
                            style="cursor:pointer;" (click)="reporteTerminos()"><b class="color-letras-cartas"
                                style="font-size: 1.2em;">{{idiomas.AceptarT2}}</b></u></label>
                </div>
                <div class="rowCardTopSpace col-lg-10 col-md-10 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedAP" (change)="informacionAP($event) "
                        [disabled]="deshabilitarAP"></mat-checkbox>
                    <label
                        style="padding-left: 5px; text-align: justify !important;">{{idiomas.AutorizacionAhorroP}}</label>
                    <label
                        style="padding-left: 5px; padding-bottom: 15px; text-align: justify !important;">{{idiomas.AutorizoAhorroP}}
                        <u style="cursor:pointer"
                            (click)="reporteTerminosAP()">{{idiomas.AceptarT2}}</u>{{idiomas.AutorizaGAhorroP}}</label>
                </div>
                <div class="form-row" *ngIf="internet.internet" style="padding-left: 0px;">
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="password" autocomplete="off"
                            [formControl]="tokenFormControl" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="tokenFormControl.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div *ngIf="internet.internet" class="left col-2 token-button">
                        <button
                            [disabled]="(!checkedI)||(!checkedC)||(!checkedAP)||(!checkedCP)||(!checkedIn) || (enableCronoIn)"
                            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        *ngIf="internet.internet && generaDocumento" style="cursor:pointer; padding-top: 1em;">
                        <label>{{idiomas.CreditoApro}}</label>
                        <a (click)="reportePDF()" style="margin-top: 10px; padding-left: 30px">
                            <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                                picture_as_pdf</mat-icon></a>
                    </div>
                    <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        *ngIf="internet.internet && !deshabilitarL" style="cursor:pointer;padding-top: .9em;">
                        <label>{{idiomas.LicitudFondosR}}</label>
                        <a (click)="reportePDFLicitud()" style="margin-top: 10px; padding-left: 30px">
                            <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                                picture_as_pdf</mat-icon></a>
                    </div>
                </div>
            </div>
            <mat-progress-bar class="col-md-9" style="margin-top: 8px; margin-bottom: 8px;" *ngIf=intento
                mode="indeterminate">
            </mat-progress-bar>
        </div>
        <div class="form-row">
            <div id="floatCont" class="col-6">
                <button mat-raised-button
                    *ngIf="bandmonto&&cuentaVista!=''&&mostrarTerminos&&(tokenFormControl.valid && tokenSolicitado)"
                    (click)="enviarRespuestaA()" title={{idiomas.Aceptar}} color="primary" class="buttonSubmit"
                    [disabled]="disableButton">
                    <mat-icon>download_done</mat-icon>
                    {{idiomas.Aceptar}}
                </button>
            </div>
            <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
                    <mat-icon> clear_all</mat-icon>
                    {{idiomas.Limpiar}}
                </button>
            </div>
        </div>
    </div>
    <div [class.formBottomTrans]="muestraGrafico" *ngIf="bandMobile" style="padding-left: 16px; padding-right: 16px;">
        <div *ngIf="parametros.subsistema == 'PRESTAMOPREAPROBADO'" class="form-row"
            style="padding-bottom: 0 !important; padding-top: 10px; width: 100% !important;">
            <mat-form-field class="col-12" style="font-size: .8em;" appearance="outline">
                <mat-label>{{idiomas.Producto}}</mat-label>
                <input matInput type="text" readonly value={{idiomas.PrestamoPreaprobadoT}}>
            </mat-form-field>
            <mat-form-field class="col-12" style="font-size: .8em;" appearance="outline">
                <mat-label>{{idiomas.MontomaxPre}}</mat-label>
                <input matInput autocomplete="off" id="montoID"
                    (focus)="montoPrestamo.setValue(this.validators.FormatNumber($event.target.value)); "
                    (change)=" calcularDatosPrestamoPreaprobado(); montoPrestamo.setValue(this.validators.FormatNumber($event.target.value)); "
                    [formControl]="montoPrestamo" maxlength="10">
                <mat-error *ngIf="montoPrestamo.hasError('minAmount')">
                    <strong>{{idiomas.montoDesde}} {{data[0].montodesde | cantidadMonetaria}} - {{data[0].montohasta |
                        cantidadMonetaria}}</strong>
                </mat-error>
                <mat-error *ngIf="montoPrestamo.hasError('maxAmount')">
                    <strong>{{idiomas.montoDesde}} {{data[0].montodesde | cantidadMonetaria}} - {{data[0].montohasta |
                        cantidadMonetaria}}</strong>
                </mat-error>
                <mat-error *ngIf="montoPrestamo.hasError('required')">
                    <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-12" style="font-size: .8em;" appearance="outline">
                <mat-label>{{idiomas.NumeroPagos}}</mat-label>
                <input matInput autocomplete="off" id="cuotaID" (focus)="numeroCuotas.setValue($event.target.value); "
                    (change)=" calcularDatosPrestamoPreaprobado(); numeroCuotas.setValue($event.target.value); "
                    [formControl]="numeroCuotas" maxlength="10" type="number">
                <mat-error *ngIf="numeroCuotas.hasError('minAmount')">
                    <strong>{{idiomas.numeroCuotas}} {{numeroCuotasMin}} - {{numeroCuotasMax}}</strong>
                </mat-error>
                <mat-error *ngIf="numeroCuotas.hasError('maxAmount')">
                    <strong>{{idiomas.numeroCuotas}} {{numeroCuotasMin}} - {{numeroCuotasMax}}</strong>
                </mat-error>
                <mat-error *ngIf="numeroCuotas.hasError('required')">
                    <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="numeroCuotas.hasError('pattern')">
                    <strong>{{idiomas.ValorEntero}}</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="data[0].estado=='P'" class="col-12" style="font-size: .8em" appearance="outline">
                <mat-label>{{idiomas.FijoPago}}</mat-label>
                <input matInput readonly [ngModel]="fechaF | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
                    [ngModelOptions]="{standalone: true}">
                <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="fechaF" [min]="minF" [max]="maxF"
                    [matDatepicker]="pickerdesde">
                <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
                <mat-datepicker touchUi #pickerdesde></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-12" style="font-size: .8em" appearance="fill">
                <mat-label>{{idiomas.Tinteres}}</mat-label>
                <input matInput type="number" readonly value="{{tasaGenerada | cantidadMonetaria}}">
            </mat-form-field>
            <div class="col-12" style="min-width: 160px;" *ngIf="mostrarCuenta" [class.col-12]="bandMobile">
                <mat-form-field appearance="outline" fxFlexFill>
                    <mat-label>{{idiomas.CuentaAhorro}}</mat-label>
                    <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="selectedOptionOrigen()"
                        [compareWith]="compareJsonPerValue">
                        <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta">
                            {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                            <mat-divider style="border-top-width: 3px;"></mat-divider>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-form-field *ngIf="mostrarTerminos" class="col-12" style="font-size: .8em" appearance="fill">
                <mat-label>{{idiomas.CuotaPrestamos}}</mat-label>
                <input matInput type="text" readonly value="{{cuotaAproximada.value | cantidadMonetaria}}">
            </mat-form-field>
            <div *ngIf="mostrarTerminos" class="col-md-12">
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedC" (change)="informacionI($event)"
                        [disabled]="deshabilitarC"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.Descuentos}}</label>
                </div>
                <label class="col-3" [class.col-12]="bandMobile"
                    style="min-width: 152px; padding-top: 17px; font-size: .8em; ">{{idiomas.FondoReserva}}</label>
                <mat-form-field class="col-2" [class.col-12]="bandMobile" style="font-size: .8em; min-width: 120px;"
                    appearance="fill">
                    <input matInput type="number" readonly value="{{valorF | cantidadMonetaria}}">
                </mat-form-field>
                <label class="col-3" [class.col-12]="bandMobile"
                    style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.ImpuestoSolca}}{{idiomas.And}}{{idiomas.TesoroNacional}}</label>
                <mat-form-field class="col-2" [class.col-12]="bandMobile" style="font-size: .8em; min-width: 120px"
                    appearance="fill">
                    <input matInput type="number" readonly value="{{valorS | cantidadMonetaria}}">
                </mat-form-field>
                <label class="col-3" [class.col-12]="bandMobile"
                    style="min-width: 152px; padding-top: 17px; font-size: .8em">{{idiomas.SeguroDesgravamen}}</label>
                <mat-form-field class="col-2" [class.col-12]="bandMobile" style="font-size: .8em; min-width: 120px"
                    appearance="fill">
                    <input matInput type="number" readonly value="{{valorSe | cantidadMonetaria}}">
                </mat-form-field>
                <div class="rowCardTopSpace col-lg-10 col-md-10 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedCP" (change)="informacionCP($event) ">
                        {{idiomas.AutorizaGCredPer}}</mat-checkbox>
                    <label
                        style="padding-left: 5px; padding-bottom: 15px; text-align: justify !important;">{{idiomas.AutorizoCredPer}}</label>
                </div>
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedI" (change)="informacionC($event)"
                        [disabled]="deshabilitarI"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.AceptarT1}}<u
                            style="cursor:pointer;" (click)="reporteTerminos()"><b class="color-letras-cartas"
                                style="font-size: 1.2em;">{{idiomas.AceptarT2}}</b></u></label>
                </div>
                <div class="rowCardTopSpace col-lg-10 col-md-10 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px; font-size: .8em;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedAP" (change)="informacionAP($event) "
                        [disabled]="deshabilitarAP"></mat-checkbox>
                    <label
                        style="padding-left: 5px; text-align: justify !important;">{{idiomas.AutorizacionAhorroP}}</label>
                    <label
                        style="padding-left: 5px; padding-bottom: 15px; text-align: justify !important;">{{idiomas.AutorizoAhorroP}}
                        <u style="cursor:pointer"
                            (click)="reporteTerminosAP()">{{idiomas.AceptarT2}}</u>{{idiomas.AutorizaGAhorroP}}</label>
                </div>
                <div class="form-row" *ngIf="internet.internet" style="padding-left: 0px;">
                    <div *ngIf="internet.internet" class="left col-6 token-button"
                        style="padding-bottom: 10px !important;">
                        <button style="margin-top: 0 !important;"
                            [disabled]="(!checkedI)||(!checkedC)||(!checkedAP)||(!checkedCP)||(!checkedIn) || (enableCronoIn)"
                            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="password" autocomplete="off"
                            [formControl]="tokenFormControl" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="tokenFormControl.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        *ngIf="internet.internet && generaDocumento" style="cursor:pointer; padding-top: 1em;">
                        <label>{{idiomas.CreditoApro}}</label>
                        <a (click)="reportePDF()" style="margin-top: 10px; padding-left: 30px">
                            <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                                picture_as_pdf</mat-icon></a>
                    </div>
                    <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        *ngIf="internet.internet && !deshabilitarL" style="cursor:pointer;padding-top: .9em;">
                        <label>{{idiomas.LicitudFondosR}}</label>
                        <a (click)="reportePDFLicitud()" style="margin-top: 10px; padding-left: 30px">
                            <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                                picture_as_pdf</mat-icon></a>
                    </div>
                </div>
            </div>
            <mat-progress-bar class="col-md-9" style="margin-top: 8px; margin-bottom: 8px;" *ngIf=intento
                mode="indeterminate">
            </mat-progress-bar>
        </div>
        <div class="form-row">
            <div id="floatCont" class="col-6">
                <button mat-raised-button
                    *ngIf="bandmonto&&cuentaVista!=''&&mostrarTerminos&&(tokenFormControl.valid && tokenSolicitado)"
                    (click)="enviarRespuestaA()" title={{idiomas.Aceptar}} color="primary" class="buttonSubmit"
                    [disabled]="disableButton">
                    <mat-icon>download_done</mat-icon>
                    {{idiomas.Aceptar}}
                </button>
            </div>
            <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
                    <mat-icon> clear_all</mat-icon>
                    {{idiomas.Limpiar}}
                </button>
            </div>
        </div>
    </div>
</div>