import { Component, OnInit, Input } from '@angular/core';
import { Alerta } from '../funciones/alerta';
import { FormControlValidators } from '../funciones/formcontrol';
import { BaseDatos } from '../funciones/basededatos';
import { ApiService } from '../api.service';
import { internetComponent } from '../funciones/internet';
import { Servicios } from '../funciones/encryptar';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatInputModule, TextFieldModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-contactar-acesor',
  templateUrl: './contactar-acesor.component.html',
  styleUrls: ['./contactar-acesor.component.css']
})
export class ContactarAcesorComponent implements OnInit {

  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  loginInfo: any
  bandMobile: boolean;

  constructor(public alerta: Alerta, public basedatos: BaseDatos, public apiService: ApiService,
    private validators: FormControlValidators, public internetC: internetComponent, public servicios: Servicios) {
    this.loginInfo = this.basedatos.retornarConsulta("loginInfo");
    this.bandMobile = false;
  }

  // BOOLEAN
  containerPreguntas: boolean
  bandCheck: boolean;
  containerCambios: boolean
  changePwd: boolean
  changeEma: boolean
  deshabilitar: boolean
  cPreguntas: boolean
  cNvoPreguntas: boolean
  intento: boolean
  intentop: boolean
  offline: boolean
  mensajeContra: string = null;
  personalInfo: boolean
  ratingInfo: boolean
  infoRequest: boolean
  questionsInfo: boolean

  //VARIABLES
  respuestas: any
  respuestasR: any
  nombre: string;
  idForm: string
  correo: string
  versionControl: string
  numerodireccion: string
  nombreSocio: string
  identificacionSocio: string
  direccionSocio: string
  celularSocio: string
  agenciaSocio: string
  correoSocio: string

  // FORMCONTROL
  observacionDomForm = this.validators.textNumberSpecialFeaturetForm()



  ngOnInit() {
    this.bandMobile = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  MensajeHB() {
    this.intento = true;
    let envio = {
      transaccion: '183074-man-mensajes-ibanking.xml',
    }
    envio['rec^tumi0^0^CUSUARIO^tumi0'] = "$userCode"
    envio['rec^tumi0^0^FMENSAJE^tumi0'] = this.getCurrentTimeAsTimestamp()
    envio['rec^tumi0^0^CPERSONA_COMPANIA^tumi0'] = "$company"
    envio['rec^tumi0^0^TEXTOMENSAJE^tumi0'] = this.observacionDomForm.value.toUpperCase()
    envio['rec^tumi0^0^SESION^tumi0'] = "$session"

    this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, true, false).then((data) => {
      if (data) {
        this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
          this.intento = false
          this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, false, true)
          this.observacionDomForm.reset()
        }, (err) => {
          this.intento = false
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
            }
            else {
            }
          }
          else { //Sin Internet
            this.intento = false
          }
        });
      } else {
        this.intento = false;
      }
    });
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date()
    const year = now.getFullYear()
    const month = this.completeDigits(now.getMonth() + 1)
    const day = this.completeDigits(now.getDate())

    const seconds = now.getSeconds();
    const minutes = now.getMinutes();
    const hour = now.getHours();
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString()
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  close() {
    this.alerta.generarDialogoSeguro(this.idiomas).then((data) => {
      if (data) {
        this.observacionDomForm.reset()
      }
    });
  }

}