import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { internetComponent } from '../funciones/internet';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatDatepickerModule, MatCardModule, MatProgressBarModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, OcultarCuentaPosTrans, FormatoFecha, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-detalle-cuentas',
  templateUrl: './dialogo-detalle-cuentas.component.html',
  styleUrls: ['./dialogo-detalle-cuentas.component.css']
})
export class DialogoDetalleCuentasComponent {
  er: boolean = false;

  idiomas: any;
  dato: any;
  producto: any;
  grande: boolean = false;
  mediano: boolean = false;
  normal: boolean = false;
  showBlocks: boolean = false;

  constructor(public internet: internetComponent, private dialogRef: MatDialogRef<DialogoDetalleCuentasComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.idiomas = data.idioma;
    this.dato = data.data;
    this.producto = data.producto;
    this.grande = data.fontSize.grande;
    this.mediano = data.fontSize.mediano;
    this.normal = data.fontSize.normal;
    this.showBlocks = data.showBlocks;
  }

  close() {
    this.dialogRef.close(this.er);
  }

  mostrarTxDesdeTx(cuenta: any, subsistema: any, grupo: any) {
    let infoV = [];
    infoV.push(cuenta)
    infoV.push(subsistema)
    infoV.push(grupo)
    this.dialogRef.close(infoV);

  }
}
