import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { FormControlValidators } from '../funciones/formcontrol';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule, UntypedFormControl } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatCheckboxModule, MatProgressBarModule, MatDividerModule, MatButtonModule, MatInputModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, OcultarCuentaPosTrans, TemporizadorComponent],
  selector: 'app-ahorro-programado',
  templateUrl: './ahorro-programado.component.html',
  styleUrls: ['./ahorro-programado.component.css']
})
export class AhorroProgramadoComponent implements OnInit {

  @Input() idiomas: any;
  @Input() dataTarPend: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  cuentas: any;
  agencies: any;
  lagenciesFilto: any;
  productoDesde: string;
  tasaAhorro: any;
  selectedAgency: any;
  interesGanado: any;
  fhasta: any;
  fdesde: any;
  finicio: any;
  fvencimiento: any;

  bandCtsDesde: boolean = false;
  calculado: boolean = false;
  disableFdesde: boolean = false;
  disableFhasta: boolean = false;
  checkedC: boolean = false;
  checkedI: boolean = false;
  deshabilitarI: boolean = true;
  intento: boolean = false;
  disableButton: boolean;
  pdf: string = "";
  generaDocumento: boolean = false;
  mostrarTerminos: boolean = false;
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  bandMobile: boolean = false;
  mostrarSucursal: boolean = false;
  intentoCuentasOwn: boolean = false;
  tokenSolicitado: boolean;
  cuenta: string = "";
  lmotivos: any[] = [];
  listas: any = {};
  personas: any[] = [];
  oficial: string = "";
  csucursal: string = "";
  sucursal: string = "";
  frecuencia: string = "";
  tasa: any;
  montominimo: any;
  lvCutoas: any[];

  fechaActual = new Date();
  minDate = new Date(this.fechaActual.getUTCFullYear(), this.fechaActual.getUTCMonth(), this.fechaActual.getUTCDate());
  datedDesde = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), this.fechaActual.getDate());

  cuentaDesdeSelected = this.validators.comboForm();
  diasForm = this.validators.numberFormDayAH();
  montoForm = new UntypedFormControl();
  numeroCuotasForm = this.validators.comboForm();
  agencyFormControl = this.validators.comboForm();
  tokenFormControl = this.validators.otpForm();
  motivoForm = this.validators.texNumberSpecialFeaturetForm();

  constructor(
    public alerta: Alerta,
    public servicios: Servicios,
    public apiService: ApiService,
    public basedatos: BaseDatos,
    public validators: FormControlValidators,
    public internet: internetComponent,
    public mostrarPdf: MostrarPDF) {
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.tokenSolicitado = false;
    this.disableButton = false;
    this.mostrarSucursal = false;
  }

  ngOnInit() {
    this.obtainOwnAccount()
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
    this.obtenerTasa()
  }

  ngAfterViewInit() {
    //Data desde tareas pendientes
    if (this.dataTarPend) {
      Promise.resolve(null).then(() => this.intento = true);
      setTimeout(function () { Promise.resolve(null).then(() => this.intentoReenviar = true), 100 });
      Promise.resolve(null).then(() => this.funcionRetornarCuentasOwn("e"));
    }
  }

  funcionRetornarCuentasOwn(e) {
    if (!this.intentoCuentasOwn) {
      this.obtainOwnAccount();
    }
  }

  guardarAhorro() {
    this.intento = true;
    this.disableButton = true;
    var cuotaPlazo = 0.00;
    var fdesde = this.fechaActual.getFullYear() + "-" + this.anadirCero(this.fechaActual.getMonth() + 1) + "-" + this.anadirCero(this.fechaActual.getDate());
    cuotaPlazo = this.numeroCuotasForm.value * 30;
    let envio = {
      'cuentaDebito': this.cuentaDesdeSelected.value.value,
      'cuenta': this.cuentaDesdeSelected.value.value,
      'fdesde': this.fdesde,
      'fhasta': this.fhasta,
      'numeroCuotas': this.numeroCuotasForm.value,
      'dia': this.diasForm.value,
      'monto': Number(this.montoForm.value.replace(/,/g, '')).toFixed(2).toString(),
      'plazo': cuotaPlazo.toString(),
      'tasa': this.tasaAhorro.replace('%', ''),
      'fAceptacion': fdesde + " 00:00:00",
      'finicio': this.finicio,
      'fVencimiento': this.fvencimiento,
      'clave': this.servicios.toAES(this.tokenFormControl.value),
      'oficial': this.oficial,
      'sucursal': this.selectedAgency == "" ? this.csucursal : this.selectedAgency.CSUCURSAL,
      'oficina': this.selectedAgency == "" ? this.csucursal : this.selectedAgency.COFICINA,
      'sucursalOficial': this.sucursal,
      'nombreLegal': this.personas[0].NOMBRELEGAL,
      'tipoIdentificacion': this.personas[0].CTIPOIDENTIFICACION,
      'identificacion': this.personas[0].IDENTIFICACION,
      'NetoGanado': this.interesGanado,
      'desencriptar': '1'
    }

    for (let motivo of this.lmotivos) {
      if (motivo.CODIGO == this.motivoForm.value) {
        envio['motivoAH'] = motivo.VALOR
      }
    }

    this.alerta.generarDialogoConfirmacionTransferencias(this.idiomas.AhorroProgramado, 'AhorroProgramado', this.idiomas, envio).then((data) => {
      if (data) {
        this.apiService.postProvider2InternetCheck('/ahorroProgramado', this.basedatos.id_token, envio).then((data: any) => {
          if (data) {
            this.intento = false;
            this.numeroCuotasForm.disable();
            this.montoForm.disable();
            this.diasForm.disable();
            this.cuentaDesdeSelected.disable();
            this.motivoForm.disable();
            this.tokenFormControl.disable();
            this.agencyFormControl.disable();
            this.generaDocumento = true;
            this.cuenta = data.ccuenta;
            this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase());
            this.mostrarCron = false;
            this.enableCronoIn = false;
          }
        }, (err) => {
          this.intento = false;
          this.mostrarCron = false;
          this.enableCronoIn = false;
          this.disableButton = false;
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            }
            else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          }
          else { //Sin Internet
          }
        });
      } else {
        this.intento = false;
        this.disableButton = false;
      }
    })
  }


  obtenerFechaInicial() {
    var date = new Date();
    var diafijo = this.diasForm.value;
    if (date.getDate() == 31) {
      date.setDate(date.getDate() + 1);
    } else if (date.getMonth() == 0 && date.getDate() > 28) {
      date.setDate(date.getDate() + 3);
    } else {
      date.setMonth(date.getMonth() + 1);
    }

    if ((diafijo == 31) || (date.getMonth() == 1 && diafijo > 28)) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(0);
    } else {
      date.setDate(diafijo);
    }

    return date;
  }

  obtenerFechaVencimiento(date: Date) {
    var date = new Date(date.getFullYear(), date.getMonth(), 1);
    var diafijo = this.diasForm.value;
    var numcuotas = this.numeroCuotasForm.value;
    var diasFrecuencia = this.idiomas.Frecuencias[0].nDays;
    var mesesQueSuman = numcuotas * (diasFrecuencia / 30);
    date.setMonth(date.getMonth() + mesesQueSuman);

    if ((diafijo == 31) || (date.getMonth() == 1 && diafijo > 28)) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(0);
    } else {
      date.setDate(diafijo);
    }

    return date;
  }

  solicitarToken() {
    const envio = { transaccion: '187016' };
    this.intento = true;
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.basedatos.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.tokenFormControl.reset();
      this.tokenFormControl.enable();
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  consultaSucursales() {
    const envio = {
      transaccion: '010003-lv-direccion-sucursalesBase.xml',
      alias: 'lov'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      if (data.length > 0) {
        this.agencies = data
        this.lagenciesFilto = this.agencies
        this.agencyFormControl.enable();
        this.mostrarSucursal = true;
      }
      Promise.resolve(null).then(() => this.intento = false);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") {
            this.logout()
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet  
      }
    });
  }

  onSelectAgency(agency: string) {
    this.selectedAgency = this.agencies.find((agencytFromList) => (agencytFromList.CSUCURSAL === agency));
    this.mostrarTerminos = true;
    this.checkedC = false;
    this.tokenFormControl.reset();
  }

  //Método para filtrar la lista de bancos
  applyFilter(filterValue: any) {
    this.agencies = this.lagenciesFilto.filter((agencias) => (agencias.NOMBRE).indexOf(filterValue) > -1)
  }


  FechaCalculada() {
    if (this.numeroCuotasForm.value >= 0 && this.numeroCuotasForm.value && this.diasForm.value >= 1 && this.diasForm.value && this.diasForm.value <= 31) {
      this.frecuencia = this.idiomas.Frecuencias[0].viewValue;
      var datei = this.obtenerFechaInicial();
      var datev = this.obtenerFechaVencimiento(datei);
      this.fdesde = this.anadirCero(datei.getDate()) + "/" + this.anadirCero((datei.getMonth() + 1)) + "/" + datei.getFullYear();
      this.fhasta = this.anadirCero(datev.getDate()) + "/" + this.anadirCero((datev.getMonth() + 1)) + "/" + datev.getFullYear();
      this.finicio = datei.getFullYear() + "-" + this.anadirCero((datei.getMonth() + 1)) + "-" + this.anadirCero(datei.getDate());
      this.fvencimiento = datev.getFullYear() + "-" + this.anadirCero((datev.getMonth() + 1)) + "-" + this.anadirCero(datev.getDate());
      this.calculoInteres();
    } else {
      this.fhasta = "";
    }
  }

  setValuesOfForm() {
    this.cuentaDesdeSelected.setValue(this.dataTarPend[0]);
  }

  obtainOwnAccount() {
    this.intentoCuentasOwn = false;
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'usuario': '',
      'alias': 'tc0',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN': '',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
      'cri^tctp0^CTRANSACCION^JOIN': '6071',
      'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
    };
    envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.cuentas = this.transformadorCuentasDesde(data);
        this.dataTarPend = this.cuentas;
        if (this.dataTarPend) {
          Promise.resolve(null).then(() => this.setValuesOfForm());
        }
        // Almacenar Consulta
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentas,
        };
        this.basedatos.insertarConsulta('CtasTransfCtasProp', consulta); // Almacena en BDD las cuentas del socio
        this.bandCtsDesde = true;
        this.intentoCuentasOwn = true;
      }
    }, (err) => {
      this.intentoCuentasOwn = false;
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { // Sin Internet
        this.cuentasTransferOffline();
        if (!this.cuentas) {
          this.cuentas = [];
        }
      }
    });
  }

  obtainPersonData() {
    this.intento = true
    var envio = {
      transaccion: '010003-con-persona.xml',
      "cri^tp0^CPERSONA^NORMAL^tp0^=": "$personCode"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data.tp0) {
        this.personas = data.tp0
        this.consultarDatosOficial()
      }
    }, (err) => {
      this.intento = false
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { // Sin Internet
        this.cuentasTransferOffline();
        if (!this.cuentas) {
          this.cuentas = [];
        }
      }
    });
  }

  cuentasTransferOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasTransfCtasProp');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias);
      this.close();
    } else {
      this.cuentas = [];
      this.cuentas = ObjConsulta.data;
      this.bandCtsDesde = true;
    }
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].CCUENTA, 'viewValue':
          value[i].CCUENTA, 'descProducto': value[i].DESCRIPCION, moneda: value[i].CMONEDA,
        prodIdTipoOri: value[i].prodIdTipo,
        CUSUARIO_OFICIALCUENTA: value[i].CUSUARIO_OFICIALCUENTA,
        IDENTIFICACION: value[i].IDENTIFICACION,
        NOMBRELEGAL: value[i].NOMBRELEGAL,
        OFICINA: value[i].COFICINA,
        SUCURSAL: value[i].CSUCURSAL_APERTURA,
        TIPOIDENTIFICACION: value[i].CTIPOIDENTIFICACION,
      });
    }
    return lista;
  }

  seleccionarCuentaDesde() {
    this.productoDesde = this.cuentaDesdeSelected.value.descProducto;
    this.obtainPersonData();
    this.consultaSucursales()
  }

  selectedOptionOrigen(cta) {
    if (this.dataTarPend && this.dataTarPend.resender) {
      this.dataTarPend.cuentaOrigen = cta;
    }
    this.productoDesde = this.cuentaDesdeSelected.value.descProducto;
    this.obtainPersonData();
    this.consultaSucursales()
  }

  terminosC(event) {
    if (event.checked == true) {
      this.checkedC = true;
    } else {
      this.checkedC = false;
    }
  }

  informacionC(event) {
    if (event.checked == true) {
      this.checkedI = true;
    } else {
      this.checkedI = false;
    }
  }

  reporteTerminos() {
    this.alerta.generarDialogoAHProgramado(this.idiomas, '', 'mostrar');
  }

  obtenerTasa() {
    this.intento = true;
    this.lvCutoas = [];
    const enviocom = {
      transaccion: "010003-con-tasaAhorro.xml",
      "cri^tgta1^CGRUPOPRODUCTO^NORMAL^tgta1": "10",
      "cri^tgta1^CPRODUCTO^NORMAL^tgta1": "419",
      alias: "tgta1",
    };
    this.apiService.postProvider2InternetCheck("/consulta", this.basedatos.id_token, enviocom).then((data: any) => {
      if (data) {
        this.tasa = data.tgta1[0].MARGEN;
        this.montominimo = data.tgta1[0].MONTOMINIMO;
        this.montoForm = this.validators.numberFormAmountAH(this.montominimo);
        var plazoMinimo = Number(data.tgta1[0].PLAZOMINIMO) / 30;
        var plazoMaximo = Number(data.tgta1[0].PLAZOMAXIMO) / 30;
        for (var i = plazoMinimo; i <= plazoMaximo; i++) {
          this.lvCutoas.push({ CODIGO: plazoMinimo, VALOR: plazoMinimo + " MESES" });
          plazoMinimo++;
        }
      }
      this.intento = false;
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error.message) {
          this.alerta.presentarAlertaX(err.error.message)
        } else {
          this.alerta.presentarAlertaX(this.idiomas.ServidorError)
        }
      } else { //Sin Internet
      }
    });
  }

  reporteInformacion() {
    let loginInfoAux = this.basedatos.retornarConsulta("loginInfo");
    let split = loginInfoAux.lastLogin.split(',')[1];
    this.intento = true;
    var envio = {
      "nombre": "18_FORMRESIDECIAFISCALPNATURAL",
      "tipo": "PDF",
      "rep^cpersona": "$personCode",
      "rep^CID": "$personId",
      "rep^IDM": "$personId",
      "rep^SUCURSAL": "$originBranch",
      "rep^OFICINA": "$originOffice",
      "rep^IPLOCAL": split.split(':')[1],
      "rep^USUARIO": "$userCode"
    };
    this.apiService.postProvider2InternetCheck("/reporte", this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false;
        this.pdf = data.reporte;
        if (this.bandMobile) {
          this.alerta.generarDialogoAHProgramado(this.idiomas, this.pdf, 'mostrarPdfBase64').then((data) => {
            this.deshabilitarI = false
          });
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
          this.deshabilitarI = false
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false

      }
    });
  }

  consultarDatosOficial() {
    var envio = {
      transaccion: '184059-con-in.xml',
      "cri^tc0^CPERSONA_CLIENTE^NORMAL^tc0": "$personCode",
      "cri^tc0^CCUENTA^NORMAL^tc0": this.cuentaDesdeSelected.value.value
    };
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data.tc0) {
        this.oficial = data.tc0[0].VALORTEXTO;
        this.csucursal = data.tc0[0].CSUCURSAL;
        this.sucursal = data.tc0[0].NOMBRE;
        this.intento = false;
      } else {
        this.mostrarTerminos = false;
        this.checkedC = false;
        this.checkedI = false;
        this.alerta.presentarAlerta(this.idiomas.ErrorCuentaVista);
        this.intento = false;
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") {
            this.logout()
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      if (err.status == 500) {
        this.alerta.presentarAlerta(this.idiomas.ErrorCuentaVista);
      }
      else { //Sin Internet  

      }
    });
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  reportePDFAhorro() {
    this.intento = true;
    let envio = {
      "ccuenta": this.cuenta,
      "isAHP": "1"
    }
    this.apiService.postProvider2InternetCheck("/consultaPlazoReporte", this.basedatos.id_token, envio).then((data: any) => {
      this.pdf = data.listDetailImage[0].imagen;
      const linkSource = 'data:application/pdf;base64,' + this.pdf;
      if (this.bandMobile) {
        this.alerta.generarDialogoAHProgramado(this.idiomas, this.pdf, 'mostrarPdfBase64').then((data) => {
          this.intento = false
        });
      } else {
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        this.intento = false
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        document.getElementById("loadData_05_06").style.visibility = 'hidden'
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  close() {
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.listas = [];
    this.disableFdesde = false;
    this.disableFhasta = false;
    this.generaDocumento = false;
    this.intento = false;
    this.checkedC = false;
    this.checkedI = false;
    this.deshabilitarI = true;
    this.mostrarTerminos = false;
    this.tokenSolicitado = false;
    this.mostrarSucursal = false;
    this.oficial = "";
    this.csucursal = "";
    this.sucursal = "";
    this.tasaAhorro = "";
    this.interesGanado = "";
    this.fdesde = "";
    this.fhasta = "";
    this.finicio = "";
    this.fvencimiento = "";
    this.frecuencia = "";
    this.diasForm.reset();
    this.montoForm.reset();
    this.numeroCuotasForm.reset();
    this.tokenFormControl.reset();
    this.motivoForm.reset();
    this.agencyFormControl.reset();
    this.datedDesde = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
    this.montoForm.enable();
    this.diasForm.enable();
    this.numeroCuotasForm.enable();
    this.agencyFormControl.enable();
    this.motivoForm.enable();
    this.tokenFormControl.enable();
    this.disableButton = false;
  }

  logout() {
    const obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  calculoInteres() {
    var tasa = 0.00;
    var interesCuotaAcumulado = 0.00;
    var montoacumulado = 0.00;
    var interesCuota = 0.00;
    this.tasaAhorro = this.tasa + "%";
    tasa = Number(this.tasa) / 100;
    if (this.numeroCuotasForm.value && this.montoForm.value && this.numeroCuotasForm.value > 0 && parseFloat(this.montoForm.value.replace(/,/g, '')) > 9) {
      for (var i = 0; i < this.numeroCuotasForm.value; i++) {
        if (i == 0) {
          montoacumulado = parseFloat(this.montoForm.value.replace(/,/g, ''));
        } else {
          montoacumulado = montoacumulado + interesCuota + parseFloat(this.montoForm.value.replace(/,/g, ''));
        }
        interesCuota = (((montoacumulado * tasa) / 360) * 30);
        interesCuotaAcumulado = interesCuotaAcumulado + interesCuota;
      }
      //montoacumulado = montoacumulado + interesCuotaAcumulado
      this.interesGanado = this.validators.FormatNumberZeroValidation(montoacumulado.toFixed(2));
    } else {
      this.interesGanado = "";
    }
  }

  numeroDias(anio, mes) {
    return new Date(anio, mes, 0).getDate()
  }

  amountKeyInputControl(event) {
    this.calculoInteres()
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace(/,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0]
          let decimalPart = amountArrayDotSplit[1]
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }
}