<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.BtnCalificar | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div class="row">
                <div class="col-12">
                    <button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i"
                        [id]="'star_'+i" (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                        <mat-icon>
                            {{showIcon(i)}}
                        </mat-icon>
                    </button>
                    <mat-error *ngIf="starCount == null || starCount == 0">
                        Star count is <strong>required</strong> and cannot be zero
                    </mat-error>
                    <p class="body-2">
                        {{idiomas.Rating}} <span class="body-2">{{rating}}</span> / <span
                            class="body-2">{{starCount}}</span>
                    </p>
                </div>
                <div class="col-12">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label><strong>{{idiomas.observacionRating}}</strong></mat-label>
                        <textarea matInput [formControl]="observacionRating" style="min-height: 200px;"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <button mat-raised-button (click)="calificarHB()" *ngIf="rating > 0 && observacionRating.valid"
                    title={{idiomas.BtnCalificanos}} color="primary" class="buttonSubmit">
                    {{idiomas.BtnCalificanos}}
                </button>
            </div>
        </div>
    </mat-card>
    <div class="cards-transations" *ngIf="bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.BtnCalificar | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div class="row">
                <div class="col-12">
                    <button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i"
                        [id]="'star_'+i" (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                        <mat-icon>
                            {{showIcon(i)}}
                        </mat-icon>
                    </button>
                    <mat-error *ngIf="starCount == null || starCount == 0">
                        Star count is <strong>required</strong> and cannot be zero
                    </mat-error>
                    <p class="body-2">
                        {{idiomas.Rating}} <span class="body-2">{{rating}}</span> / <span
                            class="body-2">{{starCount}}</span>
                    </p>
                </div>
                <div class="col-12">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label><strong>{{idiomas.observacionRating}}</strong></mat-label>
                        <textarea matInput [formControl]="observacionRating" style="min-height: 200px;"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <button mat-raised-button (click)="calificarHB()" *ngIf="rating > 0 && observacionRating.valid"
                title={{idiomas.BtnCalificanos}} color="primary" class="buttonSubmit">
                {{idiomas.BtnCalificanos}}
            </button>
        </div>
    </div>
</div>