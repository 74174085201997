import { Component, OnInit, Input, DoCheck, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Router } from '@angular/router';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet'
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FormControlValidators } from '../funciones/formcontrol';
import { OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatTableModule, MatButtonModule, MatCardModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-trasacciones-offline',
  templateUrl: './trasacciones-offline.component.html',
  styleUrls: ['./trasacciones-offline.component.css']
})
export class TrasaccionesOfflineComponent implements DoCheck {

  @Output() tarPend = new EventEmitter<any>()
  @Output() numTarPenEcomm = new EventEmitter<Number>()
  @Input() idiomas: any;

  tareas: any;
  numTareasPrev: number;
  arrayOtp: UntypedFormControl[];
  arrayIntentos: boolean[];
  arrayTokensSolicitados: boolean[];
  arrayBandsEdit: boolean[];
  fecha: Date;
  pdf: any;
  comision: string;
  fcompra: string;
  token: string;

  constructor(private api: ApiService,
    public alerta: Alerta,
    private router: Router,
    private servicio: Servicios,
    private base: BaseDatos, public internet: internetComponent, private validators: FormControlValidators, public mostrarPdf: MostrarPDF) {
    this.idiomas = JSON.parse(localStorage.getItem('idioma'));
    this.numTareasPrev = 0;
    this.arrayOtp = [];
    this.arrayIntentos = [];
    this.arrayTokensSolicitados = [];
    this.arrayBandsEdit = [];
    this.comision = "";
    this.fcompra = "";
    this.token = "";
  }

  ngDoCheck() {
    if (localStorage.getItem('ingresado')) {
      this.tareas = this.base.seleccionarTareasEcomm();
      this.numTarPenEcomm.emit(this.tareas.length);//Hacia Home - numero de tareas pendientes
      if ((this.numTareasPrev == 0) || (this.numTareasPrev != this.tareas.length)) {
        this.numTareasPrev = this.tareas.length;
        this.arrayOtpFormControlUpdate();
      }
    }
  }

  arrayOtpFormControlUpdate() {
    this.arrayOtp = []
    this.arrayIntentos = []
    this.arrayTokensSolicitados = []
    this.arrayBandsEdit = []
    for (let t of this.tareas) {
      this.arrayOtp.push(this.validators.otpForm());
      this.arrayIntentos.push(false);
      this.arrayTokensSolicitados.push(false);
      this.arrayBandsEdit.push(false);
    }
  }

  cerrar(i) {
    this.alerta.generarDialogoEliminarTarea("eliminarTarea", true, this.idiomas).then(
      (data) => {
        if (data)
          this.base.eliminarTareaEcomm(i);
      }
    )
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  buy(i) {
    let fechaActual = new Date()
    this.fcompra = fechaActual.getFullYear() + "-" + this.anadirCero(fechaActual.getMonth() + 1) + "-" + this.anadirCero(fechaActual.getDate()) + " " + fechaActual.getHours() + ":" + fechaActual.getMinutes() + ":" + fechaActual.getSeconds()
    let envio = this.base.seleccionarTareasEcomm()[i];
    this.arrayIntentos[i] = true
    this.api.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
      this.arrayIntentos[i] = false
      this.token = data.ctl['PURCHASE_TOKEN']
      this.alerta.presentarAlerta(this.idiomas.CompraR);
      this.reportePDF(envio, i)
    }, (err) => {
      this.arrayIntentos[i] = false
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { // Sin Internet
        this.arrayIntentos[i] = false
      }
    });
  }

  editTarPend(i) {
    this.alerta.generarDialogoEliminarTarea("editarTarea", true, this.idiomas).then(
      (data) => {
        if (data) {
          let tempJson = this.tareas[i]
          if (tempJson.tarPendType == 'transferCtasTer' || tempJson.tarPendType == 'transferCtasPropias' || tempJson.tarPendType == 'transferCtasOtr' || tempJson.tarPendType == 'regBen') { //Tab Transferencias
            tempJson['sonTab'] = 1
          } else if (tempJson.tarPendType == 'transferCtasOtrTarCred' || tempJson.tarPendType == 'regBenTc') { //Tab Pago Tarjetas de Credito
            tempJson['sonTab'] = 2
          } else {
            if (tempJson.tarPendType == 'regServ' || tempJson.tarPendType == 'pagoServ' || tempJson.tarPendType == 'recargas') {  //Tab Servicios
              tempJson['sonTab'] = 3
            }
          }
          this.tarPend.emit(tempJson)
          this.base.eliminarTarea(i)
        }
      }
    )
  }

  reportePDF(compra, i) {
    this.arrayIntentos[i] = true
    var envio = {
      "nombre": "18_COMPROBANTE_ECOMMERCE",
      "subsistema": "18",
      "transaccion": "3037",
      "tipo": "PDF",
      "rep^DESCRIPCION_TRANSACCION": "COMPRA ECOMMERCE",
      "rep^FECHA": this.fcompra,
      "rep^CUSUARIO": "$userCode",
      "rep^NOMBREORDENANTE": compra.nombre,
      "rep^ID_ORDENANTE": "$personId",
      "rep^NOMBREPRODUCTO": compra.producto,
      "rep^PRECIO": compra.valorU,
      "rep^CANTIDAD": compra.cantidad,
      "rep^MONTO": compra.valorT,
      "rep^TOKEN": this.token,
      "rep^COMISION": '0.00',
      "rep^CIMAGEN": compra.cimagen,
      "rep^IVA": compra.iva,
      "rep^DOMICILIO": 'CLIENTE VA A RETIRAR EN TIENDA',
      "rep^VALOR": '0.00',
      "rep^REFERENCIA": "COMPRA PRODUCTO ECOMMERCE"
    }
    if (compra.direccion != '') {
      envio["rep^DOMICILIO"] = compra.direccion
      envio["rep^VALOR"] = '2.00'
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        this.arrayIntentos[i] = false
        this.base.eliminarTareaEcomm(i)
      }
    }, (err) => {
      this.arrayIntentos[i] = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }


  getCurrentTimeAsTimestamp() {
    const now = new Date()
    const year = now.getFullYear()
    const month = this.completeDigits(now.getMonth() + 1)
    const day = this.completeDigits(now.getDate())
    return `${year}-${month}-${day} 00:00:00.000`
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString()
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }
}
