import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, DoCheck } from '@angular/core';
import { FormControl, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Router } from '@angular/router';
import { Servicios } from '../funciones/encryptar'
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet'
import { FormControlValidators } from '../funciones/formcontrol';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, plazoMeses } from '../pipes/custom-pipes.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatExpansionModule, MatProgressBarModule, MatTableModule, MatListModule, MatDividerModule, MatButtonModule, MatCheckboxModule, MatInputModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, plazoMeses, OcultarCuentaPosTrans, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-transacciones-preaprobado',
  templateUrl: './transacciones-preaprobado.component.html',
  styleUrls: ['./transacciones-preaprobado.component.css']
})
export class TransaccionesPreaprobadoComponent {

  @Input() parametros: any;
  @Input() idiomas: any;
  @Input() data: any;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;

  fdesde: Date;
  datos: any;
  peticion: any;
  fd: any;
  datos2: any[];
  datos1: any[];
  datosTabla: any[];
  datosGrafico: any[];
  bandCheck: boolean;
  deshabilitar: boolean;
  datosBand: boolean;
  autoPeticion: boolean;
  primeraconsulta: string;
  pdf: string = "";
  xls: string = "";
  paraScroll: any;
  topOfTable: any;
  container: boolean;
  displayedColumnsPresAprobados = ['Producto', 'Moneda', 'MonMIN', 'MonMAX', 'PlazoMIN', 'PlazoMAX', 'Tasa', 'Fvigencia', 'Estatus'];
  flechaR: boolean;
  flechaL: boolean;
  flechaR1: boolean;
  flechaL1: boolean;
  flechaL2: boolean;
  flechaR2: boolean;
  prestprea: any[];
  prestamos: string = 'PRESTAMOS'
  numMaxRow: number; //Numero maximo de filas en tablas para sticky
  numeroCuotasMax: any;
  numeroCuotasMin: any;
  numeroCuotas = new FormControl();
  cuotaAproximada = this.validators.decimalsForm();
  montoPrestamo = this.validators.decimalsForm();
  numeroDias = new FormControl('');
  intento: boolean;
  autoPeticion1: any;
  bandHeight: boolean;
  bandHeight1: boolean;
  datos4: any[];
  fechaActual = new Date();
  dated = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1);
  bandmonto: boolean;
  loanPaymentInfo: any;
  informacionPersonal: any;
  tasaGenerada: any = 0;
  cuentas: any;
  cuentaDesdeSelected = this.validators.comboForm();
  cuentaVista: any;
  mostrarTerminos: boolean = false;
  personas: any[] = [];
  tokenSolicitado: boolean;
  checkedI: boolean = false;
  checkedIn: boolean = false;
  checkedC: boolean = false;
  checkedT: boolean = false;
  checkedAP: boolean = false;
  checkedCP: boolean = false;
  deshabilitarI: boolean = true;
  deshabilitarIn: boolean = true;
  deshabilitarT: boolean = true;
  deshabilitarAP: boolean = true;
  listas: any = {};
  diasForm: any;
  min;
  max;
  fecha = new Date();
  minF = new Date();
  maxF = new Date();
  fechaF;
  deshabilitarC: boolean = true;
  valorAhorroProgramado: any = 0
  valorAP: any = 0;
  valorF: any = 0;
  valorS: any = 0;
  valorSe: any = 0;
  oficial: any = '';
  sucursalO: any = '';
  mostrarCuenta: boolean = false;
  cuota: any = '';
  capital: any = '';
  interes: any = '';
  programado: any = '';
  valorCuota: any = '';
  fpago: any = '';
  totalCapital: any = '';
  totalInteres: any = '';
  totalAhorro: any = '';
  totalCuota: any = '';
  numeroOperacion: any = '';
  contador: any;
  cuentaRetorna: any = '';
  generaDocumento: boolean;
  deshabilitarL: boolean = true;
  secuecial: any = "";
  cuenta: any = "";
  bandMobile: boolean;
  muestraGrafico: boolean = false;

  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;

  constructor(private api: ApiService,
    public alerta: Alerta,
    private servicio: Servicios,
    private router: Router,
    private base: BaseDatos, public internet: internetComponent, private validators: FormControlValidators, public mostrarPdf: MostrarPDF) {
    this.fdesde = null;
    this.deshabilitar = false;
    this.datos2 = [];
    this.datosTabla = [];
    this.datos4 = [];
    this.datosGrafico = [];
    this.datos1 = [];
    this.prestprea = []
    this.intento = false;
    this.peticion = false;
    this.datosBand = true;
    this.flechaR = false;
    this.bandmonto = true;
    this.flechaL = true;
    this.flechaR1 = false;
    this.flechaL1 = true;
    this.flechaR2 = false;
    this.flechaL2 = true;
    this.paraScroll = 0;
    this.topOfTable = 0;
    this.autoPeticion = true;
    this.container = false;
    this.primeraconsulta = "";
    this.numMaxRow = 5;
    this.bandHeight = false;
    this.bandHeight1 = false;
    this.montoPrestamo.setValue(0);
    this.numeroCuotas.setValue(0);
    this.loanPaymentInfo = {};
    this.informacionPersonal = [];
    this.cuentas = [];
    this.cuentaVista = "";
    this.tokenSolicitado = false;
    this.cuentaDesdeSelected = new FormControl("", Validators.required);
    this.contador = 0;
    this.generaDocumento = false;
    this.enableCronoIn = false;
    this.mostrarCron = false;
  }

  tokenFormControl = this.validators.otpForm();

  ngOnInit() {
    this.deshabilitarL = true;
    this.contador = 0;
    this.fecha = new Date(this.data[0].fcontable);
    this.minF = new Date(this.fecha)
    this.maxF = new Date(this.fecha)
    this.fechaF = new Date(this.fecha)
    this.minF.setDate(this.fecha.getDate() + 29)
    this.maxF.setDate(this.fecha.getDate() + 34)
    this.fechaF.setDate(this.fecha.getDate() + 31)
    this.numeroCuotasMax = this.data[0].estado == 'A' ? ((parseInt(this.data[0].plazohasta) / 30)).toFixed(0) : (parseInt(this.data[0].plazohasta) / 30).toFixed(0);
    this.numeroCuotasMin = this.data[0].estado == 'A' ? ((parseInt(this.data[0].plazohasta) / 30)).toFixed(0) : (parseInt(this.data[0].plazodesde) / 30).toFixed(0);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngAfterViewInit() {
    document.querySelector('.mat-sidenav-content').scrollTop = 0;
    if (this.parametros.subsistema == "PRESTAMOPREAPROBADO") {
      let fechaPreaprobados;
      if (!this.internet.internet) {
        fechaPreaprobados = this.base.retornarConsulta('prestPreapob');
        Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(fechaPreaprobados, this.idiomas));
      }
      let plazo = this.data[0].plazo
      this.montoPrestamo = new FormControl(this.validators.FormatNumber(this.data[0].monto),
        [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(this.data[0].montodesde), maxAmountValidationNumber(this.data[0].montohasta)]);
      this.numeroCuotas = new FormControl(this.data[0].estado == 'A' ? (this.data[0].plazo / 30).toFixed(0) : (plazo / 30).toFixed(0), [Validators.required, Validators.pattern('^[0-9]*$'), minQuotaValidationNumber(this.numeroCuotasMin), maxQuotaValidationNumber(this.numeroCuotasMax)]);
      this.numeroDias = new FormControl((this.data[0].estado == 'A' ? this.data[0].plazo : plazo).toString(), [Validators.required, Validators.pattern('^[0-9]*$'), Validators.max(this.numeroCuotasMax * 30), Validators.min(this.data[0].plazodesde)]);
      this.calcularDatosPrestamoPreaprobado();
    }
  }

  setDisableApproved() {
    if (this.data[0].estado == 'A') {
      this.numeroCuotas.disable();
      this.montoPrestamo.disable();
    }
  }

  calcularDatosPrestamoPreaprobado() {
    if (this.numeroCuotas.value % 1 == 0) {
      this.numeroCuotas.disable();
      this.montoPrestamo.disable();
      this.obtenerInformacionComplementaria();
    } else {
      this.bandmonto = false;
    }
  }

  obtenerInformacionComplementaria() {
    this.mostrarTerminos = false;
    this.checkedC = false;
    this.checkedI = false;
    this.checkedT = false;
    this.checkedCP = false;
    this.checkedAP = false;
    this.deshabilitarC = true;
    this.deshabilitarT = true;
    this.mostrarCuenta = false;
    this.checkedIn = false;
    this.cuentaDesdeSelected.reset();
    this.deshabilitarI = true;
    this.deshabilitarIn = true;
    this.contador = 0;
    var montoAuxuliar;
    if (this.montoPrestamo.value != '') {
      montoAuxuliar = parseFloat(this.montoPrestamo.value.replace(/,/g, ""));
    } else {
      montoAuxuliar = parseFloat('0');
    }
    if ((montoAuxuliar <= this.data[0].montohasta && montoAuxuliar >= this.data[0].montodesde)
      && (Number(this.numeroCuotas.value) <= Number(this.numeroCuotasMax)
        && Number(this.numeroCuotas.value) >= Number(this.numeroCuotasMin))) {
      const envio = {
        transaccion: '010003-con-informacionCreditoPreaprobado.xml',
        alias: 'tp',
        'cri^tp^CPERSONA^NORMAL': '$personCode',
        'cri^tp^SPERSONACREDITOSPREAPROBADOS^NORMAL': this.data[0].spersonacreditospreaprobados
      }
      this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
        if (data != null) {
          this.cuentaDesdeSelected.disable();
          this.informacionPersonal = this.transformadorInfomacionPersonal(data);
          this.obtenerAhorroProgramado(montoAuxuliar);
        } else {
          this.intento = false;
        }
      }, (err) => {
        this.intento = false;
        if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
          if (err.error) {
            if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          }
          else {
            //this.alerta.presentarAlerta(this.idioma.ServidorError)
          }
        }
        else { //Sin Internet  
        }
      });
    } else {
      this.intento = false;
      this.bandmonto = false;
      this.numeroCuotas.enable();
      this.montoPrestamo.enable();
      this.contador = 0;
    }
  }

  obtenerPlazoMaximo(montoAuxuliar) {
    let envio = {
      "transaccion": "184056-ObtenerPlazoPorMontoRegla.xml",
      "cri^vpm1^MONTODESDE^NORMAL^vpm1^=": (this.montoPrestamo.value.replace(/,/g, '')).toString(),
      "cri^vpm1^MONTOHASTA^NORMAL^vpm1^=": (this.montoPrestamo.value.replace(/,/g, '')).toString()
    };
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.vpm1 != null) {
        let valorR = Number(data.vpm1[0].PLAZO) < Number(this.numeroCuotas.value) ? Number(data.vpm1[0].PLAZO) : Number(this.numeroCuotas.value)
        this.numeroCuotasMax = Number(data.vpm1[0].PLAZO)
        this.numeroCuotas = new FormControl(valorR, [Validators.required, Validators.pattern('^[0-9]*$'), minQuotaValidationNumber(this.numeroCuotasMin), maxQuotaValidationNumber(this.numeroCuotasMax)]);
        this.obtenerAhorroProgramado(montoAuxuliar);
      } else {
        this.intento = false;
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") {
            this.logout()
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      if (err.status == 500) {
        this.alerta.presentarAlerta("ERROR AL OBTENER VALOR DE AHORRO PROGRAMADO");
      }
      else { //Sin Internet  

      }
    });
  }
  obtenerAhorroProgramado(montoAuxuliar) {
    this.intento = true;
    let envio = {
      "transaccion": "184057-ObtenerAhorroPorMontoRegla.xml",
      "cri^vpm1^MONTODESDE^NORMAL^vpm1^=": (this.montoPrestamo.value.replace(/,/g, '')).toString(),
      "cri^vpm1^MONTOHASTA^NORMAL^vpm1^=": (this.montoPrestamo.value.replace(/,/g, '')).toString()
    };
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.vpm1 != null) {
        if (data.vpm1[0].MENSUAL) {
          const consulta = {
            'fecha': new Date().toLocaleDateString(),
            'hora': new Date().toLocaleTimeString(),
            'data': this.informacionPersonal
          };
          this.base.insertarConsulta('InfoPerCrePreAprobado', consulta);
          this.tasaGenerada = this.data[0].tasa;
          this.valorAhorroProgramado = data.vpm1[0].MENSUAL
          if (montoAuxuliar <= this.data[0].montohasta && montoAuxuliar >= this.data[0].montodesde) {
            this.bandmonto = true
            if (this.numeroCuotas.value != '' && this.montoPrestamo.value != "0") {
              this.obtenerTasa(this.data[0], montoAuxuliar);
              this.cuentaDesdeSelected.enable();
            }
          }

        }
        else {
          this.mostrarTerminos = false;
          this.checkedC = false;
          this.checkedI = false;
          this.checkedIn = false;
          this.checkedT = false;
          this.checkedAP = false;
          this.checkedCP = false;
          this.alerta.presentarAlerta("ERROR AL OBTENER VALOR DE AHORRO PROGRAMADO");
          this.intento = false;
        }
      } else {
        this.mostrarTerminos = false;
        this.checkedC = false;
        this.checkedI = false;
        this.checkedIn = false;
        this.checkedT = false;
        this.checkedAP = false;
        this.checkedCP = false;
        this.alerta.presentarAlerta("ERROR AL OBTENER VALOR DE AHORRO PROGRAMADO");
        this.intento = false;
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") {
            this.logout()
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      if (err.status == 500) {
        this.alerta.presentarAlerta("ERROR AL OBTENER VALOR DE AHORRO PROGRAMADO");
      }
      else { //Sin Internet  

      }
    });
  }

  selectedOptionOrigen() {
    this.contador = 0;
    this.cuentaVista = this.cuentaDesdeSelected.value.value;
    this.obtainPersonData();
  }

  obtenerTasa(datos, montoAuxuliar) {
    this.numeroDias.setValue(this.data[0].estado == 'A' ? this.data[0].plazohasta : Math.round(parseInt(this.numeroCuotas.value) * 30));
    if (this.tasaGenerada == "null" || this.tasaGenerada == "0") {
      this.tasaGenerada = "0.00"
      this.cuotaAproximada.setValue('0.00')
    } else {
      var calculo = 0
      if (this.informacionPersonal[0].tipoCuota == "A" || this.informacionPersonal[0].tipoCuota == "CAP") {
        calculo = montoAuxuliar / this.numeroCuotas.value
        calculo = calculo + (montoAuxuliar * (30 * this.tasaGenerada / 36000));
      } else {
        var interes = this.tasaGenerada / 1200
        var numerador = montoAuxuliar * interes
        var denominador = 1 - (Math.pow((1 + interes), -this.numeroCuotas.value))
        calculo = (numerador / denominador) - 0.01;
        calculo = Number(calculo.toFixed(2)) + Number(this.valorAhorroProgramado)
      }
      if (this.montoPrestamo.value.replace(/,/g, "") != null && this.numeroCuotas.value != null) {
        Promise.resolve(null).then(() => this.data[0].estado == 'A' ? this.cuotaAproximada.setValue(this.informacionPersonal[0].valor_cuota_solicitada) : this.cuotaAproximada.setValue(Math.round(calculo * 100) / 100));
      } else {
        Promise.resolve(null).then(() => this.cuotaAproximada.setValue('0.00'));
      }
      let cuotamaxima = Number(this.informacionPersonal[0].cuotaMaxima).toFixed(2)
      this.bandmonto = true;
      if (this.data[0].estado != 'A' && Number(calculo) > Number(cuotamaxima)) {
        this.alerta.presentarAlerta(this.idiomas.msgPrea1 + this.validators.FormatNumber(cuotamaxima) +
          this.idiomas.msgPrea2)
        this.bandmonto = false;
      } else {
        this.mostrarCuenta = true;
      }
    }

    this.numeroCuotas.enable();
    this.montoPrestamo.enable();
    this.intento = false;
    this.setDisableApproved();
    this.obtainOwnAccount();
  }

  transformadorInfomacionPersonal(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'spersonacredito': value[i].SPERSONACREDITOSPREAPROBADOS, 'solicitud': value[i].CSOLICITUD_NUEVA,
        'cuotaMaxima': value[i].VALORCUOTAMAXIMA,
        'tipoCuota': value[i].TIPOCUOTA, 'estadosistema': value[i].ESTADO_SISTEMA, 'monto': value[i].MONTO_ACTUAL,
        'valor_cuota_solicitada': value[i].VALOR_CUOTA_SOLICITADA
      });
    }
    return lista;
  }

  compareJsonPerValue(object1: any, object2: any) {
    return object1 && object2 && ((object1.value == object2.value) || (object1.viewValue.slice(-4) == object2.toString().slice(-4)));
  }

  obtainOwnAccount() {
    const envio = {
      transaccion: '4057'
    }
    this.api.postProvider2InternetCheck('/cuentastrasferencias', this.base.id_token, envio).then((data: any) => {
      this.cuentas = this.transformadorCuentasDesde(data);
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].id, 'viewValue':
          value[i].etiqueta, 'descProducto': value[i].producto, moneda: value[i].moneda,
        tipoidentificacion: value[i].ctipoIdentificacion, prodIdTipoOri: value[i].prodIdTipo
      });
    }
    return lista;
  }

  informacionAP(event) {
    if (event.checked == true) {
      this.tokenFormControl.reset();
      this.checkedAP = true
    } else {
      this.tokenFormControl.reset();
      this.checkedAP = false;
    }
  }

  reporteTerminosAP() {
    this.alerta.generarDialogoAHProgramado(this.idiomas, '', 'mostrar').then((data) => {
      this.deshabilitarAP = false
    });
  }

  informacionCP(event) {
    if (event.checked == true) {
      this.tokenFormControl.reset();
      this.checkedCP = true
    } else {
      this.tokenFormControl.reset();
      this.checkedCP = false;
    }
  }

  informacionT(event) {
    if (event.checked == true) {
      this.tokenFormControl.reset();
      this.checkedT = true
    } else {
      this.tokenFormControl.reset();
      this.checkedT = false;
    }
  }

  informacionI(event) {
    if (event.checked == true) {
      this.tokenFormControl.reset();
      this.checkedC = true;
    } else {
      this.tokenFormControl.reset();
      this.checkedC = false;
    }
  }
  informacionC(event) {
    if (event.checked == true) {
      this.tokenFormControl.reset();
      this.checkedI = true;
    } else {
      this.tokenFormControl.reset();
      this.checkedI = false;
    }
  }

  informacionIn(event) {
    if (event.checked == true) {
      this.tokenFormControl.reset();
      this.checkedIn = true;
    } else {
      this.tokenFormControl.reset();
      this.checkedIn = false;
    }
  }

  reporteTerminos() {
    this.intento = true
    var envio = {
      "nombre": "18_TERMINOSCONDCREDPRE",
      "tipo": "PDF",
      "rep^CPERSONA": "$personCode",
      "rep^TASA": this.tasaGenerada,
      "rep^PLAZO": this.numeroCuotas.value * 30,
      "rep^MONTO": String(this.montoPrestamo.value.replace(/,/g, '')),
      "rep^CUOTAS": this.numeroCuotas.value,
      "rep^VALORCUOTA": this.cuotaAproximada.value,
      "rep^OFICIAL": this.oficial,
      "rep^SUCURSALO": this.sucursalO + "" + this.sucursalO
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        if (this.bandMobile) {
          this.alerta.generarDialogoCreditoAprobado(this.idiomas, this.idiomas.PrestamoPreaprobadoT, this.pdf, 'mostrarPdfBase64').then((data) => {
            if (data) {
            } else {
              this.intento = false;
              this.deshabilitarI = false;
            }
          })
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
          this.deshabilitarI = false;
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false

      }
    });
  }


  enviarRespuestaN() {
    this.intento = true;
    var envio = {
      transaccion: '187019-con-negarCreditoWS.xml',
      usuario: this.base.usuario
    }
    envio['ctl^SECUENCIAL'] = this.data[0].spersonacreditospreaprobados;
    envio['ctl^CPERSONA'] = '$personCode';
    this.alerta.generarDialogoSeguroPreaprobado(this.idiomas, this.idiomas.TexoNoAcepta).then((data) => {
      if (data) {
        this.tokenSolicitado = false;
        this.checkedI = false;
        this.checkedIn = false;
        this.checkedT = false;
        this.deshabilitarI = false;
        this.deshabilitarC = false;
        this.deshabilitarT = false;
        this.deshabilitarIn = false;
        this.api.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
          this.bandmonto = false;
          this.numeroCuotas.disable();
          this.montoPrestamo.disable();
          this.cuentaDesdeSelected.disable();
          this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase());
          this.intento = false;
        }, (err) => {
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            this.intento = false;
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            }
            else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          } else { //Sin Internet
            this.alerta.presentarAlerta(this.idiomas.ServidorError)
            this.base.insertarTarea(envio);
            this.intento = false;
          }
        })
      } else {
        this.intento = false;
      }
    });
  }

  solicitarToken() {
    const envio = { transaccion: '187018' };
    this.intento = true;
    this.contador = 0;
    this.api.postProvider2InternetCheck('/clavetemporal', this.base.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.tokenFormControl.reset();
      this.tokenFormControl.enable();
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  reporteSimuladorCredito() {
    this.intento = true;
    var envio = {
      "nombre": "18_SIMULADORCREDITO",
      "subsistema": "06",
      "transaccion": "2000",
      "tipo": "PDF",
      "rep^CAPITAL": "[" + String(this.capital) + "]",
      "rep^INTERES": "[" + String(this.interes) + "]",
      "rep^IMPUESTOS": "[" + String(this.programado) + "]",
      "rep^NROPAGO": "[" + String(this.cuota) + "]",
      "rep^VENCIMIENTO": "[" + String(this.fpago) + "]",
      "rep^CUOTA": "[" + String(this.valorCuota) + "]",
      "rep^CGRUPOPRODUCTO": this.data[0].cuenta,
      "rep^PLAZO": this.numeroDias.value,
      "rep^MONTO": String(this.montoPrestamo.value.replace(/,/g, '')),
      "rep^NUMEROCUOTA": this.numeroCuotas.value,
      "rep^TASA": this.tasaGenerada,
      "rep^TOTALCAPITAL": this.totalCapital,
      "rep^TOTALINTERES": this.totalInteres,
      "rep^TOTALHORRO": this.totalAhorro,
      "rep^TOTALVALOR": this.totalCuota
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        if (this.bandMobile) {
          this.alerta.generarDialogoCreditoAprobado(this.idiomas, this.idiomas.PrestamoPreaprobadoT, this.pdf, 'mostrarPdfBase64').then((data) => {
            if (data) {
            } else {
              this.intento = false;
              this.deshabilitarT = false;
            }
          })
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
          this.deshabilitarT = false;
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  obtainPersonData() {
    this.intento = true
    var envio = {
      transaccion: '010003-con-persona.xml',
      "cri^tp0^CPERSONA^NORMAL^tp0^=": "$personCode"
    }
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.tp0) {
        this.personas = data.tp0
        this.consultaDatosACobrar();
      } else {

      }
    }, (err) => {
      this.intento = false
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }


  consultaDatosACobrar() {
    let fecha = this.data[0].fcontable
    let pedido = {
      'cpersona': this.base.cpersona,
      'monto': String(this.montoPrestamo.value.replace(/,/g, '')),
      'plazo': this.numeroCuotas.value,
      'numeroDias': Math.round(Number(this.numeroCuotas.value) * 30),
      'user': "$userCode",
      'fcontable': fecha,
      'secuencial': this.data[0].spersonacreditospreaprobados,
      'finicio': this.fechaF.getFullYear() + "-" + (this.fechaF.getMonth() + 1) + "-" + this.fechaF.getDate(),
      'dia': this.fechaF.getDate(),
      'tasa': this.data[0].tasa,
    }
    this.api.postProvider2InternetCheck('/simuladorCredito', this.base.id_token, pedido).then((data: any) => {
      if (data != null) {
        this.valorF = data.IRREPARTIBLE;
        this.valorS = data.SOLCA;
        this.valorSe = data.SEGURO;
        this.cuotaAproximada.setValue(data.TOTAL)
        this.deshabilitarC = false;
        this.mostrarTerminos = true;
        this.intento = false;

      } else {
        this.mostrarTerminos = false;
        this.checkedC = false;
        this.checkedI = false;
        this.checkedIn = false;
        this.checkedT = false;
        this.checkedAP = false;
        this.alerta.presentarAlerta(this.idiomas.ErrorCuentaVista);
        this.intento = false;
      }
    }, (err) => {
      this.intento = false;
      this.alerta.presentarAlerta(this.idiomas.ServidorError)
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") {
            this.logout()
          }
        }
      }
      if (err.status == 500) {
        this.alerta.presentarAlerta(this.idiomas.ErrorCuentaVista);
      }
      else { //Sin Internet  

      }
    });
  }

  reporteInformacion() {
    let loginInfoAux = this.base.retornarConsulta("loginInfo")
    let split = loginInfoAux.lastLogin.split(',')[1]
    this.intento = true
    var envio = {
      "nombre": "18_FORMRESIDECIAFISCALPNATURAL",
      "tipo": "PDF",
      "rep^cpersona": "$personCode",
      "rep^CID": "$personId",
      "rep^IDM": "$personId",
      "rep^SUCURSAL": "$originBranch",
      "rep^OFICINA": "$originOffice",
      "rep^IPLOCAL": split.split(':')[1],
      "rep^USUARIO": "$userCode"
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false;
        this.pdf = data.reporte;
        if (this.bandMobile) {
          this.alerta.generarDialogoCreditoAprobado(this.idiomas, this.idiomas.PrestamoPreaprobadoT, this.pdf, 'mostrarPdfBase64').then((data) => {
            if (data) {
            } else {
              this.intento = false;
              this.deshabilitarIn = false;
            }
          })
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
          this.deshabilitarIn = false;
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false

      }
    });
  }

  daysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  enviarRespuestaA() {
    var fecha = new Date(this.fechaF)
    var finicio = this.fechaF.getFullYear() + "-" + (this.fechaF.getMonth() + 1) + "-" + this.fechaF.getDate()
    fecha.setDate(1)
    fecha.setMonth(fecha.getMonth())
    var fvencimiento = new Date(fecha)
    fvencimiento.setMonth(fecha.getMonth() + (parseInt(this.numeroCuotas.value) - 1))
    var lastDay = this.daysInMonth(fvencimiento.getFullYear(), fvencimiento.getMonth() + 1)
    var fechaVencimiento = new Date(fvencimiento)
    var dias = lastDay < this.fechaF.getDate() ? lastDay : this.fechaF.getDate()
    fechaVencimiento.setDate(dias)
    var fechaInicio = new Date(this.data[0].fcontable).getTime();
    var fechaFin = new Date(fechaVencimiento).getTime();
    var fvencimientoFinal = fechaVencimiento.toISOString().slice(0, 10);
    var diff = fechaFin - fechaInicio;
    this.intento = true;
    let envio = {
      'cusuario': "$userCode",
      'cuentaDebito': this.cuentaVista,
      'monto': String(this.montoPrestamo.value.replace(/,/g, '')),
      'plazo': Math.round(diff / (1000 * 60 * 60 * 24)),
      'secuencial': this.data[0].spersonacreditospreaprobados,
      'cpersona': '$personCode',
      'valorcuota': this.cuotaAproximada.value,
      'tasa': this.data[0].tasa,
      'clave': this.servicio.toAES(this.tokenFormControl.value),
      'desencriptar': '1',
      'dia': this.fechaF.getDate(),
      'montoAhorro': this.valorAhorroProgramado,
      'numerocuota': this.numeroCuotas.value,
      'finicio': this.fechaF.getFullYear() + "-" + (this.fechaF.getMonth() + 1) + "-" + this.fechaF.getDate(),
      'fvencimiento': fvencimientoFinal
    }
    if (this.contador == 0) {
      this.alerta.generarDialogoSeguroPreaprobado(this.idiomas, this.idiomas.TexoAcepta).then((data) => {
        this.bandmonto = false;
        this.contador = 1;
        if (data) {
          this.api.postProvider2InternetCheck('/prestamospreaprobados', this.base.id_token, envio).then((data: any) => {
            if (data != null) {
              this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase());
              this.numeroCuotas.disable();
              this.montoPrestamo.disable();
              this.cuentaDesdeSelected.disable();
              this.tokenFormControl.disable();
              this.contador = 0;
              this.cuenta = data.CUENTACREDITO
              this.intento = false;
              this.generaDocumento = true;
              //   if(Number(this.montoPrestamo.value.replace(/,/g, ''))>=5000){
              //      this.reporteLicitud();
              //   }

            } else {
              this.contador = 0;
              this.alerta.presentarAlertaX(this.idiomas.CreditoError);
            }
            // if(Number(this.montoPrestamo.value.replace(/,/g, ''))>=5000){
            //   this.reporteLicitud();
            // }
            this.intento = false;
            this.mostrarCron = false;
            this.enableCronoIn = false;
          }, (err) => {
            this.bandmonto = false;
            this.intento = false;
            this.contador = 0;
            this.mostrarCron = false;
            this.enableCronoIn = false;
            if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
              this.intento = false;
              if (err.error) {
                if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
                else {
                  this.alerta.presentarAlertaX(err.error.mensajeUsuario)
                }
              }
              else {
                this.alerta.presentarAlerta(this.idiomas.ServidorError)
              }

            }
            if (err.status == 500) {
              this.alerta.presentarAlertaX(this.idiomas.ServidorError);
            } else { //Sin Internet
              this.alerta.presentarAlerta(this.idiomas.ServidorError)

            }
          });
        } else {
          this.contador = 0;
          this.intento = false;
        }
      });
    }
  }

  reporteLicitud() {
    var envio = {
      "nombre": "18_ORIGENFONDOS",
      "subsistema": "18",
      "transaccion": "7017",
      "tipo": "PDF",
      "rep^ccuenta": String(""),
      "rep^destino": "CONSUMO",
      "rep^valor": this.montoPrestamo.value,
      "rep^persona": this.base.cpersona
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        this.secuecial = data.ctl.R_SECUENCIAL
        if (this.bandMobile) {
          this.alerta.generarDialogoCreditoAprobado(this.idiomas, this.idiomas.PrestamoPreaprobadoT, this.pdf, 'mostrarPdfBase64').then((data) => {
            if (data) {
            } else {
              this.intento = false;
              this.deshabilitarL = false;
            }
          })
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
          this.deshabilitarL = false;
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        this.deshabilitarL = true;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  reportePDFLicitud() {
    var envio = {
      "nombre": "18_ORIGENFONDOS",
      "tipo": "PDF",
      "rep^ccuenta": this.cuenta,
      "rep^destino": "CONSUMO",
      "rep^valor": this.montoPrestamo.value,
      "rep^persona": this.base.cpersona,
      "rep^SECUENCIAL": this.secuecial
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        if (this.bandMobile) {
          this.alerta.generarDialogoCreditoAprobado(this.idiomas, this.idiomas.PrestamoPreaprobadoT, this.pdf, 'mostrarPdfBase64').then((data) => {
            if (data) {
            } else {
              this.intento = false;
              this.deshabilitarL = false;
            }
          })
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
          this.deshabilitarL = false;
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  reportePDF() {
    this.intento = true;
    let envio = {
      "ccuenta": this.cuenta,
      "isAHP": "1"
    }
    this.api.postProvider2InternetCheck("/consultaPlazoReporte", this.base.id_token, envio).then((data: any) => {
      this.pdf = data.listDetailImage[0].imagen
      const linkSource = 'data:application/pdf;base64,' + this.pdf
      if (this.bandMobile) {
        this.alerta.generarDialogoCreditoAprobado(this.idiomas, this.idiomas.PrestamoPreaprobadoT, this.pdf, 'mostrarPdfBase64').then((data) => {
          if (data) {
          } else {
            this.intento = false;
          }
        })
      } else {
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        this.intento = false
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        document.getElementById("loadData_05_06").style.visibility = 'hidden'
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }


  TableSize(grupo) {
    if (grupo == 'PRESTAMOPREAPROBADO') {
      try {
        if (this.grande) {
          //document.getElementById('btnCosultarTrans').style.fontSize = "25px"
          document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 630px')
          if (document.getElementsByClassName('prestpreap')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          //document.getElementById('btnCosultarTrans').style.fontSize = "20px"
          document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 580px')
          if (document.getElementsByClassName('prestpreap')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          //document.getElementById('btnCosultarTrans').style.fontSize = "inherit"
          document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 480px; font-size: 14px')
          if (document.getElementsByClassName('prestpreap')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) { }
    }
  }

  close() {
    document.querySelector('.mat-sidenav-content').scrollTop = 0;
    let fechaPreaprobados;
    if (!this.internet.internet) {
      fechaPreaprobados = this.base.retornarConsulta('prestPreapob');
      Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(fechaPreaprobados, this.idiomas));
    }
    let plazo = this.data[0].plazo
    this.montoPrestamo = new FormControl(this.validators.FormatNumber(this.data[0].monto),
      [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(this.data[0].montodesde), maxAmountValidationNumber(this.data[0].montohasta)]);
    this.numeroCuotas = new FormControl(this.data[0].estado == 'A' ? (this.data[0].plazo / 30).toFixed(0) : (plazo / 30).toFixed(0), [Validators.required, Validators.pattern('^[0-9]*$'), minQuotaValidationNumber(this.numeroCuotasMin), maxQuotaValidationNumber(this.numeroCuotasMax)]);
    this.numeroDias = new FormControl((this.data[0].estado == 'A' ? this.data[0].plazo : plazo).toString(), [Validators.required, Validators.pattern('^[0-9]*$'), Validators.max(this.numeroCuotasMax * 30), Validators.min(this.data[0].plazodesde)]);
    this.calcularDatosPrestamoPreaprobado();
  }


  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  scrollToTab(band) {
    if (band && this.paraScroll < 1) {
      scroll(0, this.topOfTable - document.getElementById("tool-bar").getBoundingClientRect().height);
      this.paraScroll = this.paraScroll + 1;
    }
  }

  className() {
    if (document.getElementsByClassName("cdk-overlay-pane").length == 2) {
      document.getElementsByClassName("cdk-overlay-pane")[1].setAttribute("style", "margin-top: 30px")
    } else {
      document.getElementsByClassName("cdk-overlay-pane")[0].setAttribute("style", "margin-top: 30px")
    }
  }
}

//Validacion de montos minimos de monto
interface minAmountValidation {
  (control: FormControl): ValidationErrors | null
}
function minAmountValidationNumber(minValue): minAmountValidation {
  return (control: FormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto >= minValue) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de monto
interface maxAmountValidation {
  (control: FormControl): ValidationErrors | null
}
function maxAmountValidationNumber(maxValue): maxAmountValidation {
  return (control: FormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto <= maxValue) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos minimos de numero
interface minQuotaValidation {
  (control: FormControl): ValidationErrors | null
}
function minQuotaValidationNumber(minValue): minQuotaValidation {
  return (control: FormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? monto : null;
    if (monto >= Number(minValue)) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de numero
interface maxQuotaValidation {
  (control: FormControl): ValidationErrors | null
}
function maxQuotaValidationNumber(maxValue): maxQuotaValidation {
  return (control: FormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? monto : null;
    if (monto <= Number(maxValue)) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

export interface Element {
  nro: string;
  fecha: Date;
  hora: string
  concepto: string;
  monto: number;
  vencimiento: string;
  detalle: string;
}