import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { QRCodeModule } from 'angularx-qrcode';
import { Servicios } from '../funciones/encryptar';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-dialogo-generar-qr',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatCheckboxModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, MatButtonModule, CommonModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, AlertasLoginComponent, QRCodeModule, ZXingScannerModule],
  templateUrl: './dialogo-generar-qr.component.html',
  styleUrl: './dialogo-generar-qr.component.css'
})

export class DialogoGenerarQRComponent {

  er: boolean = false;
  grande: boolean = false;
  mediano: boolean = false;
  normal: boolean = false;
  data: string;
  idioma: any;
  isScanQR: boolean;
  readText: any;

  constructor(
    public base: BaseDatos,
    public apiService: ApiService,
    public servicios: Servicios,
    private dialogRef: MatDialogRef<DialogoGenerarQRComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data.data;
    this.idioma = data.idioma
    this.isScanQR = data.isScanQR
    //Se verifica si existe fontSize almacenado
    let fontSize = localStorage.getItem('fontSize');
    if (fontSize != null) {
      switch (fontSize) {
        case 'large':
          this.grande = true;
          break;
        case 'medium':
          this.mediano = true;
          break;
        case 'small':
          this.normal = true;
          break;
        default:
          this.normal = true;
          break;
      }
    } else {
      this.mediano = true;
    }
  }

  close() {
    this.dialogRef.close(this.er);
  }

  scanSuccessHandler($event: any) {
    this.readText = this.servicios.desencriptar($event);
    var data = this.readText.split(";");
    this.dialogRef.close(data);
  }
}
