<div class="textura" *ngIf="mostrarTexto">
    <h2 mat-dialog-title>{{autorizacion}} </h2>

    <mat-dialog-content>
        <p style="text-align: justify;" [innerHTML]='mensaje'></p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div style="width: 100% !important; text-align: center;">
            <button mat-button class="boton" (click)="close()">{{rechazar}}</button>
            <button mat-button class="boton" (click)="accept()">{{aceptar}}</button>
        </div>
    </mat-dialog-actions>

</div>

<div class="textura" *ngIf="mostrarPdfBase64">
    <h2 mat-dialog-title style="text-align: center; margin-bottom: 0; margin-top: -10px;">
        <strong>{{idioma.PrestamoPreaprobadoT}}</strong></h2>

    <mat-dialog-content *ngIf="mostrarPdfBase64" style="height: 100%;" class="dialogAgreementContent">
        <ngx-extended-pdf-viewer #pdfViewer [base64Src]="dato" backgroundColor="#ffffff" [handTool]=false
            [useBrowserLocale]="true" [textLayer]="false" [showUnverifiedSignatures]="true" [zoom]="'65%'"
            [customToolbar]="multiToolbar">
        </ngx-extended-pdf-viewer>
        <ng-template #multiToolbar>
            <div style="text-align: center;">
                <button mat-icon-button id="zoomIn">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button mat-icon-button id="zoomOut">
                    <mat-icon>zoom_out</mat-icon>
                </button>
                <button mat-icon-button id="download">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </div>
        </ng-template>
    </mat-dialog-content>
    <div style="width: 100% !important; text-align: right;">
        <button mat-raised-button class="buttonToken" (click)="acceptDia()">
            <mat-icon>download_done</mat-icon>{{aceptar}}
        </button>
    </div>
</div>