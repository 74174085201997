import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { FormControlValidators } from '../funciones/formcontrol';
import { internetComponent } from '../funciones/internet';

import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatCardModule, MatInputModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, OcultarCuentaPosTrans, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-pago-recaudaciones',
  templateUrl: './pago-recaudaciones.component.html',
  styleUrls: ['./pago-recaudaciones.component.css']
})
export class PagoRecaudacionesComponent implements OnInit {
  @Input() idiomas: any;
  @Input() dataTarPend: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  cuentas: any;
  productoDesde: string;
  monto: any;
  nombreEmpresa: any;
  nombreDeudor: any;
  sgiroTransferencia: any;
  bandData: boolean = false;
  pipe: any;
  disableButton: boolean;
  bandMobile: boolean = false;
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;

  bandCtsDesde: boolean = false;
  tokenSolicitado: boolean;
  intento: boolean = false;
  cuenta: string = "";
  lrecaudacion: any[];
  mostrarProximamente:boolean = true;

  identificacionForm = this.validators.idForm();
  cuentaDesdeSelected = this.validators.comboForm();
  recaudacionForm = this.validators.comboForm();
  referencia = this.validators.textNumberForm();
  tokenFormControl = this.validators.otpForm();

  constructor(public alerta: Alerta, public servicios: Servicios, public apiService: ApiService,
    public basedatos: BaseDatos, public validators: FormControlValidators, public internet: internetComponent) {
    this.tokenSolicitado = false;
    this.pipe = new CantidadMonetaria();
    this.disableButton = false;
  }

  ngOnInit() {
    this.bandMobile = false;
    this.obtainOwnAccount()
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  pagoRecaudacion() {
    this.intento = true;
    this.disableButton = true;
    var envio = {
      'cuentaDebito': this.cuentaDesdeSelected.value.value,
      'sgiroTransferencia': this.sgiroTransferencia,
      'clave': this.servicios.toAES(this.tokenFormControl.value),
      'desencriptar': '1'
    }
    this.apiService.postProvider2InternetCheck('/pagoRecaudacion', this.basedatos.id_token, envio).then((data: any) => {
      this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase());
      this.close()
      this.intento = false;
      this.disableButton = false;
      this.mostrarCron = false;
      this.enableCronoIn = false;
    }, (err) => {
      this.intento = false;
      this.disableButton = false;
      this.mostrarCron = false;
      this.enableCronoIn = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { } // Sin Internet
    });
  }

  consultarRecuadaciones() {
    this.intento = true;
    this.disableButton = true;
    this.lrecaudacion = [];
    var envio = {
      transaccion: "010003-con-recaudaciones.xml",
      "fullNames": "true",
      "cri^tgta0^IDENTIFICACIONBENEFICIARIO^NORMAL^tgta0": this.identificacionForm.value
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false;
        if (data.tgta0.length > 0) {
          this.lrecaudacion = data.tgta0;
          this.bandData = true;
        } else {
          this.bandData = false;
          this.identificacionForm.reset();
          this.alerta.presentarAlerta(this.idiomas.NoTienePagos.toUpperCase());
        }
      }
      this.disableButton = false;
    }, (err) => {
      this.intento = false;
      this.disableButton = false;
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { } // Sin Internet
    });
  }

  funcionRecaudacion(e) {
    if (!this.lrecaudacion) {
      this.consultarRecuadaciones()
    }
  }

  seleccionRecaudacion() {
    this.monto = this.pipe.transform(this.recaudacionForm.value['tgta0.MONTO'])
    this.nombreEmpresa = this.recaudacionForm.value['tgta0.NOMBREORDENANTE']
    this.nombreDeudor = this.recaudacionForm.value['tgta0.NOMBREBENEFICIARIO']
    this.referencia.setValue(this.recaudacionForm.value['tgta0.DETALLE'])
    this.sgiroTransferencia = this.recaudacionForm.value['tgta0.SGIROTRANSFERENCIAAUTORIZADO']
  }

  solicitarToken() {
    const envio = { transaccion: '186025' };
    this.intento = true;
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.basedatos.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.tokenFormControl.reset();
      this.tokenFormControl.enable();
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);;
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  obtainOwnAccount() {
    const envio = {
      transaccion: '6071'
    }
    this.apiService.postProvider2InternetCheck('/cuentastrasferencias', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.cuentas = this.transformadorCuentasDesde(data);
        // Almacenar Consulta
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentas,
        };
        this.basedatos.insertarConsulta('CtasTransfCtasProp', consulta); // Almacena en BDD las cuentas del socio
        this.bandCtsDesde = true
      }
    }, (err) => {
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { // Sin Internet
        this.cuentasTransferOffline();
        if (!this.cuentas) {
          this.cuentas = [];
        }
      }
    });
  }

  cuentasTransferOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasTransfCtasProp');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias)
      this.close()
    } else {
      this.cuentas = [];
      this.cuentas = ObjConsulta.data;
      this.bandCtsDesde = true
    }
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        value: value[i].id,
        viewValue: value[i].etiqueta,
        descProducto: value[i].producto,
        moneda: value[i].moneda,
        prodIdTipoOri: value[i].prodIdTipo
      });
    }
    return lista;
  }

  seleccionarCuentaDesde() {
    this.productoDesde = this.cuentaDesdeSelected.value.descProducto;
  }

  selectedOptionOrigen(cta) {
    if (this.dataTarPend && this.dataTarPend.resender) {
      this.dataTarPend.cuentaOrigen = cta;
    }
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  close() {
    this.intento = false;
    this.tokenSolicitado = false;
    this.bandData = false;
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.monto = "";
    this.nombreEmpresa = "";
    this.nombreDeudor = "";
    this.identificacionForm.reset();
    this.referencia.reset();
    this.cuentaDesdeSelected.reset();
    this.recaudacionForm.reset();
    this.tokenFormControl.reset();
  }

  logout() {
    const obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

}
