<div fxLayout="column" class="example-form" fxLayout="column" fxLayoutAlign="start stretch"
  style="margin: auto; padding-bottom: 60px;">
  <mat-card class="cards-transations" *ngIf="bandsFuncion.consultaServRec && !bandMobile">
    <div>
      <div id="tableDiv" autocomplete="off" class="form-row" *ngIf="!showNuevoServicio">
        <div class="col">
          <div class="card-header">
            <div id="lastelement" class="center texto">
              <h6>
                <strong>{{idiomas.Servicios | uppercase}}</strong>
              </h6>
            </div>
          </div>
          <div *ngIf="intento" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <br>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf="tieneServicios && !intento && !bandMobile" style="text-align: center;">
            <mat-label  style="font-size: .7em; opacity: 0.9;">{{idiomas.InforPagServ}}</mat-label>
          </div>
          <div *ngIf="tieneServicios && !intento && !bandMobile" class="row" style="margin-left: -24px !important;"
            style="padding: 16px">
            <div class="col flecha" style="margin-right: 4px;">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tablaServicios','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class=" col container2Aux" [class.heightconteiner2]="!(datos2.length>numMaxRow)"
              (scroll)="scroll('tablaServicios')">
              <table mat-table [dataSource]="datos2" class="tablaServicios" (keyup.enter)="caducarServicios()">
                <ng-container matColumnDef="F.Registro">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.FRegistro}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas" style="min-width: 150px">
                    {{dato.fregistro | formatoFechaTranferServ:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="Servicio">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Servicio}}</th>
                  <td mat-cell *matCellDef="let dato" class="texto"> {{dato.servicio}} </td>
                </ng-container>
                <ng-container matColumnDef="Suministro">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Suministro}}</th>
                  <td mat-cell *matCellDef="let dato" class="texto" (click)="resendTransfer(dato)" style="text-decoration:
                  underline !important;cursor: pointer;"> {{dato.suministro}} </td>
                </ng-container>
                <ng-container matColumnDef="Descripcion">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Descripcion}}</th>
                  <td mat-cell *matCellDef="let dato" class="texto left" style="padding-left: 10px !important">
                    {{dato.descripcion}}</td>
                </ng-container>
                <ng-container matColumnDef="Caducar">
                  <th mat-header-cell *matHeaderCellDef class="right" [hidden]="!internet.internet">
                    {{idiomas.Caducar}}
                  </th>
                  <td mat-cell *matCellDef="let dato" class="numeros" style="padding-left: 10px !important"
                    [hidden]="!internet.internet">
                    <mat-checkbox *ngIf="dato.servicio" [checked]='dato.caducar' [value]="dato.caducar"
                      (change)="dato.caducar=!dato.caducar; selectCheck()" color="primary">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumns;"
                  [ngClass]="{gray: even}">
                  {{showHideRows(last)}}</tr>
              </table>
            </div>
            <div class="col flecha" style="margin-left: 4px;">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tablaServicios','right')">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>

          <div *ngIf='!tieneServicios' class="center col texto">
            <mat-label>
              {{idiomas.NoTieneServicios | uppercase}}
            </mat-label>
          </div>
          <div class="row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <div *ngIf="!intento && !showNuevoServicio" id="floatCont" class="col-6">
              <button mat-raised-button (click)="nuevoServicio()" title={{idiomas.Nuevo}} color="primary"
                class="buttonSubmit">
                <mat-icon>add_circle_outline</mat-icon>
                {{idiomas.Nuevo}}
              </button>
            </div>
            <div *ngIf="tieneServicios && !showNuevoServicio && internet.internet && !intento" class="col-6" align="end"
              style="text-align: end">
              <button mat-raised-button [disabled]="!bandBtnCaducar" (click)="caducarServicios()"
                style="color: #dc3545;" id=btnCaducar class="buttonSubmitDelete">
                <mat-icon>delete</mat-icon>
                {{idiomas.Caducar}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div autocomplete="off" *ngIf="showNuevoServicio">
        <div class="card-header">
          <div id="lastelement" class="center texto">
            <h6>
              <strong>{{idiomas.Servicios | uppercase}}</strong>
            </h6>
          </div>
        </div>
        <div class="form-row" style="padding: 16px;">
          <mat-form-field class="col-5" [class.col-12]="bandMobile" style="min-width: 190px !important"
            appearance="outline">
            <mat-label>{{idiomas.Servicio}}</mat-label>
            <mat-select [formControl]="servTipo" (selectionChange)="valorTipo($event.value)">
              <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
                (keydown)="$event.stopPropagation()" oninput="this.value = this.value.toUpperCase();"
                placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                style="height: 35px;border-bottom-style: groove;">
              <mat-option *ngFor="let serv of servicios" [(value)]="serv.id">
                {{serv.descripcion}}
                <mat-divider style="border-top-width: 2px;"></mat-divider>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="servTipo.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-7" [class.col-12]="bandMobile" style="min-width: 205px !important"
            appearance="fill">
            <input matInput type="text" autocomplete="off" value="{{serviciob}}" readonly>
          </mat-form-field>
          <mat-form-field class="col-12" style="min-width: 205px !important" appearance="outline">
            <mat-label>{{idiomas.Suministro}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="suministroForm">
            <mat-error *ngIf="suministroForm.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12" style="min-width: 205px !important" appearance="outline">
            <mat-label>{{idiomas.Descripcion}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="descripcionForm" #input
              (input)="input.value=$event.target.value.toUpperCase()">
            <mat-error *ngIf="descripcionForm.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
          <div class="col-12" *ngIf="internet.internet">
            <div class="form-row" *ngIf="internet.internet" (keyup.enter)="registrarServicio()">

              <mat-form-field class="col-2 token" *ngIf="tokenrecibido" [class.col-12]="bandMobile"
                appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input id="soliToken" matInput type="text" name="soliToken" autocomplete="off"
                  onkeydown="this.setAttribute('type','password')" [formControl]="tokenForm" maxlength="6"
                  inputmode="numeric" appBlockCopyPaste>
                <mat-error *ngIf="tokenForm.hasError('required')">
                  <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="tokenForm.hasError('pattern')">
                  <strong>{{idiomas.msjSoloNumeros}}</strong>
                </mat-error>
                <mat-error *ngIf="tokenForm.hasError('minlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
                <mat-error *ngIf="tokenForm.hasError('maxlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-2 token" *ngIf="!tokenrecibido" [class.col-12]="bandMobile"
                appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
              </mat-form-field>
              <div class="left col-3 token-button">
                <button
                  [disabled]="(!servTipo.valid) || (!suministroForm.valid) || (!descripcionForm.valid) || (enableCronoIn)"
                  mat-raised-button (click)="solToken()" color="primary" class="buttonToken">
                  <mat-icon>security</mat-icon>
                  {{idiomas.SolicitarToken}}
                </button>
              </div>
              <div *ngIf="mostrarCron" class="col">
                <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                  [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="expirar || intentot" style="padding-left: 16px; padding-right: 16px;">
        <mat-progress-bar *ngIf=expirar mode="indeterminate"></mat-progress-bar>
        <mat-progress-bar *ngIf=intentot mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
        <div *ngIf="showNuevoServicio" id="floatCont" class="col-6">
          <button mat-raised-button (click)="registrarServicio()" title={{idiomas.Aceptar}}
            *ngIf="!internet.internet || (tokenrecibido && tokenForm.valid)" color="primary" class="buttonSubmit"
            [disabled]="disableButton">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
        </div>
        <div *ngIf="showNuevoServicio" id="floatCont" align="end" style="text-align: end;" class="col-6">
          <button mat-raised-button (click)="close()" title={{idiomas.Regresar}} class="buttonClean" color="warn">
            <mat-icon>arrow_back_ios</mat-icon>
            {{idiomas.Regresar}}
          </button>
        </div>
      </div>
    </div>
  </mat-card>

  <div style="padding-left: 16px; padding-right: 16px;"
    *ngIf="bandMobile && !bandsFuncion.habilitarMenu && (bandsFuncion.pagoServ || bandsFuncion.recargas)">
    <button mat-raised-button (click)="habilitarFunciones('consultaServRec',true)" title={{idiomas.RegresarServ}}
      style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;" color="primary"
      class="buttonBene">
      <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
      <strong>{{idiomas.RegresarServ}}</strong>
    </button>
  </div>

  <div *ngIf="bandsFuncion.consultaServRec && bandMobile">
    <div>
      <div id="tableDiv" autocomplete="off" class="form-row" *ngIf="!showNuevoServicio">
        <div class="col">
          <div class="card-header">
            <div id="lastelement" class="center texto">
              <h6>
                <strong>{{idiomas.Servicios | uppercase}}</strong>
              </h6>
            </div>
          </div>
          <div *ngIf="intento" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <br>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div class="col-12 container-card-mobile" *ngIf="tieneServicios && !intento && bandMobile"
            [class.heightconteiner2]="!(datos2.length>numMaxRow)">
            <mat-list>
              <mat-divider></mat-divider>
              <mat-list-item *ngFor="let dato of datos2; let even = even;">
                <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                  <div class="col-8">
                    <div style="font-weight: bold; font-size: .8em;">{{dato.servicio}}</div>
                    <div>
                      <div style="font-size: .8em; white-space: initial;">{{dato.Descripcion | uppercase}}</div>
                    </div>
                  </div>
                  <div class="col-4" style="text-align: end;">
                    <div class="colorCartas" style="font-size: .8em; text-decoration:
                    underline !important;cursor: pointer;" (click)="resendTransfer(dato)">{{dato.suministro}}</div>
                  </div>
                  <div class="col-12"
                    style="font-weight: bold; font-size: .9em; text-align: end; padding-top: 5px; vertical-align: text-bottom;">
                    <a style="padding-right: 15px;">{{idiomas.Caducar}}</a>
                    <mat-checkbox *ngIf="dato.servicio" [checked]='dato.caducar' [value]="dato.caducar"
                      (change)="dato.caducar=!dato.caducar; selectCheck()" color="primary">
                    </mat-checkbox>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>

          <div *ngIf='!tieneServicios' class="center col texto">
            <mat-label>
              {{idiomas.NoTieneServicios | uppercase}}
            </mat-label>
          </div>
          <div class="row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <div *ngIf="!intento && !showNuevoServicio" id="floatCont" class="col-6">
              <button mat-raised-button (click)="nuevoServicio()" title={{idiomas.Nuevo}} color="primary"
                class="buttonSubmit">
                <mat-icon>add_circle_outline</mat-icon>
                {{idiomas.Nuevo}}
              </button>
            </div>
            <div *ngIf="tieneServicios && !showNuevoServicio && internet.internet && !intento" class="col-6" align="end"
              style="text-align: end">
              <button mat-raised-button [disabled]="!bandBtnCaducar" (click)="caducarServicios()"
                style="color: #dc3545;" id=btnCaducar class="buttonSubmitDelete">
                <mat-icon>delete</mat-icon>
                {{idiomas.Caducar}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div autocomplete="off" *ngIf="showNuevoServicio">
        <div class="card-header">
          <div id="lastelement" class="center texto">
            <h6>
              <strong>{{idiomas.Servicios | uppercase}}</strong>
            </h6>
          </div>
        </div>
        <div class="form-row" style="padding: 16px;">
          <mat-form-field class="col-5" [class.col-12]="bandMobile" style="min-width: 190px !important"
            appearance="outline">
            <mat-label>{{idiomas.Servicio}}</mat-label>
            <mat-select [formControl]="servTipo" (selectionChange)="valorTipo($event.value)">
              <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
                (keydown)="$event.stopPropagation()" oninput="this.value = this.value.toUpperCase();"
                placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                style="height: 35px;border-bottom-style: groove;">
              <mat-option *ngFor="let serv of servicios" [(value)]="serv.id">
                {{serv.descripcion}}
                <mat-divider style="border-top-width: 2px;"></mat-divider>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="servTipo.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-7" [class.col-12]="bandMobile" style="min-width: 205px !important"
            appearance="fill">
            <input matInput type="text" autocomplete="off" value="{{serviciob}}" readonly>
          </mat-form-field>
          <mat-form-field class="col-12" style="min-width: 205px !important" appearance="outline">
            <mat-label>{{idiomas.Suministro}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="suministroForm">
            <mat-error *ngIf="suministroForm.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12" style="min-width: 205px !important" appearance="outline">
            <mat-label>{{idiomas.Descripcion}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="descripcionForm" #input
              (input)="input.value=$event.target.value.toUpperCase()">
            <mat-error *ngIf="descripcionForm.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
          <div class="col-12" *ngIf="internet.internet">
            <div class="form-row" *ngIf="internet.internet" (keyup.enter)="registrarServicio()">
              <div class="left col-3 token-button" style="padding-bottom: 10px !important;">
                <button style="margin-top: 0 !important;"
                  [disabled]="(!servTipo.valid) || (!suministroForm.valid) || (!descripcionForm.valid) || (enableCronoIn)"
                  mat-raised-button (click)="solToken()" color="primary" class="buttonToken">
                  <mat-icon>security</mat-icon>
                  {{idiomas.SolicitarToken}}
                </button>
              </div>
              <div *ngIf="mostrarCron" class="col-12">
                <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                  [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
              </div>
              <mat-form-field class="col-2 token" *ngIf="tokenrecibido" [class.col-12]="bandMobile"
                appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input id="soliToken" matInput type="text" name="soliToken" autocomplete="off"
                  onkeydown="this.setAttribute('type','password')" [formControl]="tokenForm" maxlength="6"
                  inputmode="numeric" appBlockCopyPaste>
                <mat-error *ngIf="tokenForm.hasError('required')">
                  <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="tokenForm.hasError('pattern')">
                  <strong>{{idiomas.msjSoloNumeros}}</strong>
                </mat-error>
                <mat-error *ngIf="tokenForm.hasError('minlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
                <mat-error *ngIf="tokenForm.hasError('maxlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-2 token" *ngIf="!tokenrecibido" [class.col-12]="bandMobile"
                appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="expirar || intentot" style="padding-left: 16px; padding-right: 16px;">
        <mat-progress-bar *ngIf=expirar mode="indeterminate"></mat-progress-bar>
        <mat-progress-bar *ngIf=intentot mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
        <div *ngIf="showNuevoServicio" id="floatCont" class="col-6">
          <button mat-raised-button (click)="registrarServicio()" title={{idiomas.Aceptar}}
            *ngIf="!internet.internet || (tokenrecibido && tokenForm.valid)" color="primary" class="buttonSubmit"
            [disabled]="disableButton">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
        </div>
        <div *ngIf="showNuevoServicio" id="floatCont" align="end" style="text-align: end;" class="col-6">
          <button mat-raised-button (click)="close()" title={{idiomas.Regresar}} class="buttonClean" color="warn">
            <mat-icon>arrow_back_ios</mat-icon>
            {{idiomas.Regresar}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!bandsFuncion.habilitarMenu">
    <div *ngIf="bandsFuncion.pagoServ">
      <app-pago-servicios #pagoServ (regServEv)="registrarServicio($event)" [mediano]="mediano" [grande]="grande"
        [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend" [nomServ]="nomServ" [bandServReg]="false"
        [resendPay]="resendPay">
      </app-pago-servicios>
    </div>
    <div *ngIf="bandsFuncion.recargas">
      <app-recargas #recargas [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
        [pendingTaskData]="dataTarPend" [resendPay]="resendPay"></app-recargas>
    </div>
    <div *ngIf="!bandMobile && (bandsFuncion.pagoServ || bandsFuncion.recargas)" class="float-transacciones">
      <button mat-fab (click)="habilitarFunciones('consultaServRec',true)" title={{idiomas.Regresar}}
        class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
  </div>
</div>