import { Injectable } from '@angular/core';

@Injectable()
export class Funciones {

    constructor() {
    }

    toNumberFormat(cantidad: string) {
        return new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(Number(cantidad));
    }

    toDateFormat(fecha: string) {
        return fecha.substring(0, 10);
    }

    toHourFormat(fecha: string) {
        return fecha.substring(10, 15);
    }

    generateNameOfTransaction(typeTransaction: string): string {
        const currentDate = new Date();

        const day = currentDate.getDate();
        const monthNames = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
        const month = monthNames[currentDate.getMonth()];
        const year = currentDate.getFullYear();

        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const time = `${hours}_${minutes}_${seconds}`;
        return `${typeTransaction} ${day}-${month}-${year} ${time}`;
    }
}