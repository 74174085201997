<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch">
    <label class="center"><strong>{{idiomas.AdministracionTienda | uppercase}}</strong></label>

    <div class="row" *ngIf="cargarDatos">
        <div class="col-4" style="min-width: 120px">
            <mat-form-field (keyup.enter)="cambioNombreProducto($event.target.value)" appearance="outline" fxFlexFill>
                <mat-label>{{idiomas.Producto}}</mat-label>
                <input matInput type="text" [formControl]="identificador"
                    oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-4" style="min-width: 150px">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idiomas.Categoria}}</mat-label>
                <mat-select (selectionChange)="cambioCateogria()" [formControl]="categorias"
                    (openedChange)="funcionConsultaEstatus($event)">
                    <mat-option *ngIf="!datosCategoria" disabled>
                        <div style="margin: 10px;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                    </mat-option>
                    <mat-option *ngFor="let tipocat of datosCategoria" [value]="tipocat['tcat1.CCATEGORIAECOMM']">
                        {{tipocat['tcat1.CCATEGORIAECOMM']}} -- {{tipocat['tcat1.DESCRIPCION']}}
                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-4" style="min-width: 120px">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idiomas.Estado}}</mat-label>
                <mat-select (selectionChange)="cambioEstatusProductos()" [formControl]="estatus">
                    <mat-option *ngFor="let tipodoc of datosEstatus" [value]="tipodoc.id">
                        {{tipodoc.detalle}}
                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-progress-bar *ngIf="intento" mode="indeterminate"></mat-progress-bar>

    <div fxLayout="column" fxLayoutAlign="start stretch" id="tableDiv" *ngIf="cargarTabla">
        <mat-card>
            <div class="row" id="lastelement">
                <div class="center col texto">
                    <label style="float:center;"><strong>{{idiomas.TablaProducto | uppercase}}</strong></label>
                </div>
            </div>
            <div class="row"  style="margin-left: -24px !important;">
                <div class="col flecha" style="margin-right: 4px;">
                    <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tablaProducto','left')">
                        keyboard_arrow_left</mat-icon>
                </div>
                <div class="col container3Aux" (scroll)="scroll()"
                    [class.heightconteiner2]="!(datosProductos.length>numMaxRow)">
                    <table mat-table [dataSource]="datosProductos" class="tablaProducto" id="tabla" matSort>

                        <ng-container matColumnDef="Nuevo">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td class="left" style="max-width: 60px;padding-right: 10px;" mat-cell
                                *matCellDef="let element">
                                <mat-icon color="warn"
                                    *ngIf="element['tpro0.FDESDE'] >= fdesde && element['tpro0.VERSIONCONTROL']==0"
                                    style="cursor: pointer !important;" title="{{idiomas.Nuevo | uppercase}}">
                                    new_releases
                                </mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Producto">
                            <th mat-header-cell *matHeaderCellDef>{{idiomas.Producto | uppercase}}</th>
                            <td mat-cell *matCellDef="let element">
                                {{element['tpro0.NOMBRE'] | uppercase}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Detalle">
                            <th mat-header-cell class="left" *matHeaderCellDef>{{idiomas.Detalles| uppercase}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="left">
                                {{element['tpro0.DESCRIPCION'] | cadenaCaracteres | uppercase}}...
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="BotonDetalle">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let element" class="right">
                                <button mat-icon-button color="primary"
                                    (click)="mostrarDescripcion(element['tpro0.DESCRIPCION'])">
                                    <mat-icon>more</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Categoria">
                            <th mat-header-cell *matHeaderCellDef> {{idiomas.Categoria| uppercase}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element['tcat0.DESCRIPCION'] | uppercase}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Subcategoria1">
                            <th mat-header-cell class="left" *matHeaderCellDef> {{carac1}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="left">
                                {{element['tpro0.DETALLE1'] | uppercase}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Subcategoria2">
                            <th mat-header-cell class="left" *matHeaderCellDef> {{carac2}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="left">
                                {{element['tpro0.DETALLE2'] | uppercase}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Estatus">
                            <th class="center" class="left" mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{idiomas.Estado| uppercase}}
                            </th>
                            <td class="center" mat-cell *matCellDef="let element">
                                <mat-icon color="warn" *ngIf="element['tpro0.DISPONIBLE']==0">remove_shopping_cart
                                </mat-icon>
                                <mat-icon color="primary" *ngIf="element['tpro0.DISPONIBLE']==1">shopping_cart
                                </mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Precio">
                            <th class="right" mat-header-cell *matHeaderCellDef style="padding-right: 10px;">
                                {{idiomas.Precio| uppercase}}</th>
                            <td class="numeros" mat-cell *matCellDef="let element"
                                style="min-width: 80px; padding-right: 10px">
                                $ {{element['tpro0.PRECIO'] | cantidadMonetaria}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Editar">
                            <th class="center" mat-header-cell *matHeaderCellDef class="left"
                                [hidden]="!internet.internet">
                                {{idiomas.Editar| uppercase}} </th>
                            <td class="center" mat-cell *matCellDef="let element" [hidden]="!internet.internet">
                                <button mat-icon-button class="left" color="primary"
                                    (click)="editProduct(element['tpro0.CPRODUCTOECOMM'])">
                                    <mat-icon aria-label="Edit" style="cursor: pointer !important;"
                                        title="{{idiomas.Editar}}">edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Eliminar">
                            <th class="center" mat-header-cell *matHeaderCellDef class="left"
                                [hidden]="!internet.internet">
                                {{idiomas.Eliminar| uppercase}} </th>
                            <td class="center" mat-cell *matCellDef="let element" [hidden]="!internet.internet">
                                <button mat-icon-button color="warn"
                                    (click)="removeProduct(element['tpro0.CPRODUCTOECOMM'])">
                                    <mat-icon aria-label="Remove" style="cursor: pointer !important;"
                                        title="{{idiomas.Eliminar}}">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsProducto; sticky: true"></tr>
                        <tr id="celda" mat-row
                            *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsProducto"
                            [ngClass]="{gray: even}">
                            {{showHideColumns(last)}}</tr>
                    </table>
                </div>
                <div class="col flecha" style="margin-left: 4px;">
                    <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tablaProducto','right')">
                        keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <mat-paginator style="background:transparent" [length]="length" [pageIndex]="pageIndex"
                [pageSize]="pageSize" (page)="pageEvent = siguienteConsultaTabla($event)"></mat-paginator>
        </mat-card>
    </div>
    <div class="float-transacciones">
        <button mat-fab class="float-right buttonFab" *ngIf="internet.internet" title="{{idiomas.NuevoProducto| uppercase}}"
            color="primary" (click)="addNew()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-fab class="float-right buttonFab" *ngIf="internet.internet" title="{{idiomas.MantenimientoTienda| uppercase}}"
            color="primary" (click)="editStore()">
            <mat-icon>store</mat-icon>
        </button>
    </div>
    <div class="float" id="floatCont" style="z-index: 100;">
        <button mat-fab class="float-right buttonFab" title="{{idiomas.Actualizar | uppercase}}" color="primary"
            (click)="refrescartabla()">
            <mat-icon>replay</mat-icon>
        </button>
    </div>
</div>