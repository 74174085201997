<div style="width: 100%; height: 50px; margin-top: 1%; text-align: center">
    <label *ngIf="adm==0" class="center"><strong>{{idioma.NuevoProducto| uppercase}}</strong></label>
    <label *ngIf="adm==1" class="center"><strong>{{idioma.EditarProducto| uppercase}}</strong></label>
    <label *ngIf="adm==2" class="center"><strong>{{idioma.EliminarProducto| uppercase}}</strong></label>
    <div *ngIf="!intento">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div><br>
</div>
<mat-dialog-content>
    <div *ngIf="adm==0 &&  !intentoMessage" class="container" style="margin-top: 1%;text-align: center;">
        <mat-radio-group (change)="radioChange($event)" [formControl]="orden" aria-label="Select an option">
            <mat-radio-button color="primary" style="padding-left: 5px" [value]="1">
                {{idioma.IngresoProducto| uppercase}}
            </mat-radio-button>
            <mat-radio-button color="primary" style="padding-left: 5px" [value]="2">
                {{idioma.CargarProductos| uppercase}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="row" *ngIf="adm==0 && cargaArchivo &&  !intentoMessage">
        <div *ngIf="cargaArchivo" class="container" style="text-align-last: center;">
            <input style="display: none" type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                (change)="onSelectFileArchive($event)" #hiddenfileinput multiple>
            <button (click)="hiddenfileinput.click()" mat-raised-button color="primary">
                <mat-icon style="cursor: pointer !important;" title="{{idioma.SeleccionarArchivo| uppercase}}">
                    attach_file</mat-icon>
            </button>
            <mat-label *ngIf="!cargadoArchivo" style="padding-left: 20px;">{{archivoNombre| uppercase}}</mat-label>
        </div>
    </div>
    <div class="row" *ngIf="adm==0 && cargaProducto &&  !intentoMessage">
        <div class="container" style="text-align-last: center;">
            <img *ngFor='let url of urls' [src]="url" width="156" height="123"> <br />
            <input style="display: none" type="file" accept='image/*' (change)="onSelectFile($event)" #hiddenfileinput
                multiple>
            <button (click)="hiddenfileinput.click()" mat-raised-button color="primary">
                <mat-icon style="cursor: pointer !important;" title="{{idioma.SeleccionarImagen| uppercase}}">
                    add_a_photo</mat-icon>
            </button>
        </div>
        <div style="margin-top: 10px;" class="col-md-12">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Producto | uppercase}}</mat-label>
                <input matInput type="text" [formControl]="productoP" oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Categoria| uppercase}}</mat-label>
                <mat-select [formControl]="categoriaFormP">
                    <mat-option *ngFor="let categoria of categoriasProductos; let i=index" [value]="categoria.id">
                        {{i + 1}} -- {{categoria.detalle}}
                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div id="precioP" class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Precio| uppercase}}</mat-label>
                <input class="numeros"
                    (blur)="precioP.setValue(this.validators.FormatNumberZeroValidation($event.target.value))"
                    (keypress)="comaToDot($event)" autocomplete="off" maxlength="10" inputmode="decimal" matInput
                    type="text" name="precioP" [formControl]="precioP">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{carac1| uppercase}}</mat-label>
                <input matInput type="text" [formControl]="caracteristicaP1"
                    oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{carac2| uppercase}}</mat-label>
                <input matInput type="text" [formControl]="caracteristicaP2"
                    oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-radio-group aria-label="Select an option" [formControl]="disponibilidadP">
                <mat-radio-button color="primary" style="padding-left: 5px" [value]="1">
                    {{idioma.Disponible| uppercase}}</mat-radio-button>
                <mat-radio-button color="primary" style="padding-left: 5px" [value]="0">
                    {{idioma.NoDisponible| uppercase}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-md-6">
            <mat-radio-group aria-label="Select an option" [formControl]="diferirP">
                <mat-radio-button color="primary" style="padding-left: 5px" [value]="1"> {{idioma.Diferir| uppercase}}
                </mat-radio-button>
                <mat-radio-button color="primary" style="padding-left: 5px" [value]="0"> {{idioma.NoDiferir| uppercase}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="container">
            <mat-expansion-panel>
                <mat-expansion-panel-header style="padding-left: 0">
                    <mat-panel-title>
                        {{idioma.Detalles| uppercase}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline" fxFlexFill>
                    <textarea matInput maxlength="199" [formControl]="descripcionP"
                        oninput="this.value = this.value.toUpperCase();"></textarea>
                </mat-form-field>
            </mat-expansion-panel>
        </div>
    </div>
    <div class="row" *ngIf="adm==0 && (cargaArchivo || cargaProducto) && intentoMessage">
        <div class="container" style="text-align-last: center;">
            <mat-label *ngIf="intentoMessage">{{message}}</mat-label>
            <mat-label *ngIf="intentoMessageError">{{messageError}}</mat-label>
        </div>
    </div>
    <div class="row" *ngIf="adm==1 &&  !intentoMessage">
        <div class="show-image" style="margin-left: auto; margin-right: auto;">
            <img *ngIf="!imagenesProductos[index]" src="assets/resources/mipmap-hdpi/cargando_imagen.gif" class="zoom"
                width="156" height="123" />
            <img style="width:100%" width="156" height="123"
                style="margin-bottom: 5px; margin-left: auto; margin-right: auto;" *ngIf="imagenesProductos[index]"
                [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + imagenesProductos[index].detalle)"
                width="156" height="123" style="text-align-last: center;">
            <mat-icon *ngIf="imagenesProductos.length > 1" class="left" (click)="izquierda(i)" title="Mas Imagenes"
                color="primary">
                keyboard_arrow_left</mat-icon>
            <mat-icon *ngIf="imagenesProductos.length > 1" class="right" (click)="derecha(i)" title="Mas Imagenes"
                color="primary">
                keyboard_arrow_right</mat-icon>
        </div>
        <div class="col-md-6" style="text-align-last: center;">
            <img style="padding-bottom: 10px;" [src]="imgURL" width="156" height="123" *ngIf="imgURL"><br />
            <input style="display: none;" type="file" accept='image/*' (change)="onSelectFileEdit($event)"
                #hiddenfileinput>
            <button (click)="hiddenfileinput.click()" mat-raised-button color="primary">
                <mat-icon aria-label="Remove" style="cursor: pointer !important;"
                    title="{{idioma.SeleccionarImagen| uppercase}}">add_a_photo
                </mat-icon>
            </button>
        </div>
        <div style="margin-top: 10px;" class="col-md-12">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Producto | uppercase}}</mat-label>
                <input matInput type="text" [formControl]="productoP" oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Categoria| uppercase}}</mat-label>
                <mat-select [formControl]="categoriaFormP">
                    <mat-option *ngFor="let categoria of categoriasProductos; let i=index" [value]="categoria.id">
                        {{i + 1}} -- {{categoria.detalle}}
                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Precio| uppercase}}</mat-label>
                <input class="numeros"
                    (blur)="precioP.setValue(this.validators.FormatNumberZeroValidation($event.target.value))"
                    (keypress)="comaToDot($event)" autocomplete="off" maxlength="10" inputmode="decimal" matInput
                    type="text" name="precioP" [formControl]="precioP">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{carac1| uppercase}}</mat-label>
                <input matInput type="text" [formControl]="caracteristicaP1"
                    oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{carac2| uppercase}}</mat-label>
                <input matInput type="text" [formControl]="caracteristicaP2"
                    oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-radio-group aria-label="Select an option" [formControl]="disponibilidadP">
                <mat-radio-button [checked]="checkDis" color="primary" style="padding-left: 5px" [value]="1">
                    {{idioma.Disponible| uppercase}}
                </mat-radio-button>
                <mat-radio-button [checked]="checkDis2" color="primary" style="padding-left: 5px" [value]="2">
                    {{idioma.NoDisponible| uppercase}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-md-6">
            <mat-radio-group aria-label="Select an option" [formControl]="diferirP">
                <mat-radio-button [checked]="checkdif" color="primary" style="padding-left: 5px" [value]="1">
                    {{idioma.Diferir| uppercase}}</mat-radio-button>
                <mat-radio-button [checked]="checkdif2" color="primary" style="padding-left: 5px" [value]="2">
                    {{idioma.NoDiferir| uppercase}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="container">
            <mat-expansion-panel>
                <mat-expansion-panel-header style="padding-left: 0">
                    <mat-panel-title>
                        {{idioma.Detalles| uppercase}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline" fxFlexFill>
                    <textarea matInput maxlength="199" [formControl]="descripcionP"
                        oninput="this.value = this.value.toUpperCase();"></textarea>
                </mat-form-field>
            </mat-expansion-panel>
        </div>
    </div>
    <div class="row" *ngIf="(adm==1 || adm==2) && intentoMessage">
        <div class="container" style="text-align-last: center;">
            <mat-label *ngIf="intentoMessage">{{message}}</mat-label>
            <mat-label *ngIf="intentoMessageError">{{messageError}}</mat-label>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button *ngIf="adm==0 && cargaProducto && !intentoMessage" [disabled]="!descripcionP.valid || !caracteristicaP2.valid || !diferirP.valid || !caracteristicaP2.valid || 
        !caracteristicaP1.valid || !precioP.valid || !productoP.valid || !categoriaFormP.valid || cargadoArchivo"
        mat-raised-button (click)="newProductSingle()" class="buttonSubmit"
        color="primary">{{idioma.GuardarCambios}}</button>
    <button *ngIf="adm==0 && cargaArchivo && !intentoMessage" [disabled]="cargadoArchivo" mat-raised-button
        (click)="newProductArchive()" class="buttonSubmit" color="primary">{{idioma.GuardarCambios}}</button>
    <button *ngIf="adm==1 && !intentoMessage" mat-raised-button (click)="editProduct()" class="buttonSubmit"
        color="primary">{{idioma.GuardarCambios}}</button>
    <button *ngIf="adm==2 && !intentoMessage" mat-raised-button (click)="removeProducto()" class="buttonCancel"
        color="warn">{{idioma.Eliminar}}</button>
    <button *ngIf="intentoMessage" mat-raised-button class="buttonSubmit" color="primary" (click)="close()">{{idioma.Regresar}}</button>
    <button *ngIf="!intentoMessage" mat-raised-button class="buttonCancel" color="warn" (click)="close()">{{idioma.Rechazar}}</button>
</mat-dialog-actions>