import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { BaseDatos } from "../funciones/basededatos";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatDialogModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-ahorro-programado',
  templateUrl: './dialogo-ahorro-programado.component.html',
  styleUrls: ['./dialogo-ahorro-programado.component.css']
})
export class DialogoAhorroProgramadoComponent implements OnInit {

  er: boolean = false;
  aceptar: string;
  rechazar: string;
  idioma: any;
  tipo: string
  band: boolean;
  imagenes: any;
  mostrar: boolean = false;
  mostrarTexto: boolean = false;
  mostrarPdfBase64: boolean = false;
  dataLocalUrl: string;
  innerWidth: any;
  innerHeight: any;
  loadContract: boolean = false;
  bandMobile: boolean;
  dato:any;

  constructor(private dialogRef: MatDialogRef<DialogoAhorroProgramadoComponent>, @Inject(MAT_DIALOG_DATA) data,
    private _DomSanitizer: DomSanitizer, public baseDatos: BaseDatos) {
    this.dataLocalUrl = "data:application/pdf;base64, " + data.mensaje;
    this.dato = data.mensaje
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Cerrar;
    this.idioma = data.idm1;
    this.band = data.band;
    this.tipo = data.tipo;
    this.loadContract = true;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true
    }

    if (window.innerWidth < 834) {
      this.bandMobile = true
    } else {
      this.bandMobile = false
    }
  }

  ngOnInit(): void {
    if (this.tipo == 'mostrar') {
      this.mostrarTexto = false;
      this.mostrarPdfBase64 = false;
      this.mostrar = true;
    } else if (this.tipo == 'mostrarPdfBase64') {
      this.mostrar = false;
      this.mostrarTexto = false;
      this.mostrarPdfBase64 = true;
    } else {
      this.mostrar = false;
      this.mostrarPdfBase64 = false;
      this.mostrarTexto = true;
    }
  }

  ngAfterViewInit() {

  }

  contractLoaded() {
    this.loadContract = false;
  }

  accept() {
    this.dialogRef.close(this.er = true);
  }

  close() {
    this.dialogRef.close(this.er);
  }
}
