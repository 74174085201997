import { CommonModule, UpperCasePipe } from '@angular/common';
import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { FormControlValidators } from '../funciones/formcontrol';
import { Idioma } from '../funciones/idioma';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { MatInputModule } from '@angular/material/input';


@Component({
  selector: 'app-validar-certificado',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatTooltipModule, MatInputModule, MatDatepickerModule, RecaptchaFormsModule, RecaptchaModule, MatSelectModule, MatCardModule, MatTabsModule, MatExpansionModule, MatMenuModule, MatToolbarModule, MatSidenavModule, MatExpansionModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  templateUrl: './validar-certificado.component.html',
  styleUrl: './validar-certificado.component.css',
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.RECAPTCHA_SITE_KEY,
    } as RecaptchaSettings,
  },]
})
export class ValidarCertificadoComponent {

  @ViewChild('captcha') cap;

  @Input() dataTarPend: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;
  idiomas: any;
  bandMobile: boolean;


  //CAPTCHA
  siteKey: string = environment.RECAPTCHA_SITE_KEY; // Usa la clave del sitio
  captchaResponse: string = '';
  isCaptchaResolved: boolean = true;
  intento: boolean;
  selectedDate: any;

  numeroCertificado = this.validators.texNumberSpecialFeaturetForm();
  certificateCode = this.validators.decimalsForm();

  onDateSelected(event: MatDatepickerInputEvent<Date>): void {
    this.selectedDate = event;

  }

  isButtonDisabled(): boolean {
    if (this.numeroCertificado.value != '') {
      return false;
    } else {
      return true;
    }
  }

  onCaptchaChange(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }
  resolved(captchaResponse: string) {
    captchaResponse ? this.isCaptchaResolved = false : this.isCaptchaResolved = true;
  }

  @HostListener('window:resize', ['$event']) //Evento que permite determinar cuando se redimenciona pantalla
  onResize(event) {
    if (window.innerWidth < 760) {
      this.bandMobile = true;
    } else {
      this.bandMobile = false;
    }
  }

  constructor(public alertas: Alerta, public mostrarPdf: MostrarPDF, public servicios: Servicios, public internetC: internetComponent, public apiService: ApiService, public basedatos:
    BaseDatos, private router: Router, public validators: FormControlValidators, public internet: internetComponent, private idm: Idioma) {
    this.idm.getIdioma();
    this.idiomas = JSON.parse(localStorage.getItem('idioma'));
  }

  ngOnInit(): void {
    this.intento = false;


    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|android/.test(userAgent);
    }

    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true;
    }
    if (window.innerWidth < 760) {
      this.bandMobile = true;
    } else {
      this.bandMobile = false;
    }
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace(/,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0];
          let decimalPart = amountArrayDotSplit[1];
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  close() {
    this.numeroCertificado.setValue('');
    this.isCaptchaResolved = true;
    this.certificateCode.setValue('');
    this.captchaResponse = '';
    this.cap.reset();
  }

  returnInicio() {
    this.router.navigate(['']);
  }

  enviar() {
    this.intento = true;
    const envio = {
      'certificate_number': this.numeroCertificado.value,

    }
    this.apiService.noTokenProviderInternetCheck(envio, '/verifyCertificate').then((data: any) => {
      if (data) {
        if (data.is_valid == false) {
          this.alertas.presentarAlertaX(this.idiomas.msjalertaValidacionCertificado);
        } if (data.is_valid == true) {
          this.previewActiveAccountReport(data.file);
          this.intento = false;
          this.close();
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false;
        this.alertas.presentarAlertaX(err.error.mensajeUsuario);
      }
      else { //Sin Internet
        this.intento = false;
        this.alertas.presentarAlerta(this.idiomas.ServidorError);
      }
    });
  }
  previewActiveAccountReport(pdf: string) {
    let pdfConverted = pdf;
    this.alertas.generarDialogoCertificados(this.idiomas.Certificados, this.idiomas, pdfConverted, 'mostrar',false).then((data) => {
    })
  }
}
