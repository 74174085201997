<div class="textura">
    <h2 mat-dialog-title>{{idioma.TituloDialogos}}</h2>

    <mat-dialog-content>
        <p [innerHTML]='mensaje'></p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon>download_done</mat-icon>{{aceptar}}
            </button>
        </div>
    </mat-dialog-actions>
</div>