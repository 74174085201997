import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class internetComponent {
  
  static instance: internetComponent;
  public online$: Observable<boolean>;
  private $subscription: any;
  public internetParcialNav: boolean;
  public internetParcialRest: boolean;
  public internet: boolean;
  public prevInternet: boolean;
  public desktopBand: boolean;

  constructor() {
    if (!(internetComponent.instance = internetComponent.instance)) {
      this.internetParcialNav = true;
      this.internetParcialRest = true;
      this.desktopBand = false;
      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      )
      this.networkStatus()
    }
    return internetComponent.instance = internetComponent.instance || this; //Controla el Singleton de Instancia 
  }

  public networkStatus() {
    this.$subscription = this.online$.subscribe(value => {
      this.internet = value;
    });
  }

  public internetSetStatus(data) {
    this.internet = data;
    if (!this.$subscription && data) {
      this.$subscription = this.online$.subscribe(value => {
        this.internet = value;
      });
    }
  }

  public internetGetStatus() {
    return this.internet;
  }

  public StopNetwokStatus() {
    if (this.$subscription != null) {
      this.$subscription.unsubscribe();
    }
    this.$subscription = null;
  }

  public desconectar() {
    this.internet = !this.internet;
  }

  public setDesktopBand(data) {
    this.desktopBand = data;
  }
}
