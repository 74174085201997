import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-pago-prestamo',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './dialogo-pago-prestamo.component.html',
  styleUrls: ['./dialogo-pago-prestamo.component.css']
})
export class DialogoPagoPrestamoComponent {

  idiomas: any;
  loanPaymentInfo: any;

  constructor(private dialogRef: MatDialogRef<DialogoPagoPrestamoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.idiomas = data.idiomas;
    this.loanPaymentInfo = data.loanPaymentInfo;
  }

  close(event) {
    this.dialogRef.close(event);
  }
}
