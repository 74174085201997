<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.BtnPreguntas | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div class="row" *ngIf="!intento">
                <div class="col-12">
                    <mat-accordion displayMode="flat" [multi]="false" cdkDropListGroup>
                        <mat-expansion-panel class="acordion" *ngFor="let chapter of chapters" #chapterExpansionPanel
                            (mouseenter)="mouseEnterHandler($event, chapterExpansionPanel)">
                            <mat-expansion-panel-header class="panelColor">
                                <mat-panel-title>
                                    {{ chapter.name }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-list (cdkDropListDropped)="cdkDropListDroppedHandler($event)" cdkDropList
                                [cdkDropListData]="chapter.items">
                                <mat-list-item cdkDrag *ngFor="let item of chapter.items" class="panelColor">
                                    {{ item.name }}
                                </mat-list-item>
                            </mat-list>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="cards-transations" *ngIf="bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.BtnPreguntas | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div class="row" *ngIf="!intento">
                <div class="col-12">
                    <mat-accordion displayMode="flat" [multi]="false" cdkDropListGroup>
                        <mat-expansion-panel class="acordion" *ngFor="let chapter of chapters" #chapterExpansionPanel
                            (mouseenter)="mouseEnterHandler($event, chapterExpansionPanel)">
                            <mat-expansion-panel-header class="panelColor">
                                <mat-panel-title>
                                    {{ chapter.name }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-list (cdkDropListDropped)="cdkDropListDroppedHandler($event)" cdkDropList
                                [cdkDropListData]="chapter.items">
                                <mat-list-item cdkDrag *ngFor="let item of chapter.items" class="panelColor">
                                    {{ item.name }}
                                </mat-list-item>
                            </mat-list>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>
</div>