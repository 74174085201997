<div class="textura">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <h2 mat-dialog-title style="text-align: center;">{{idiomas.ErrorTituloDialogo}}</h2>
    <a  style="font-size: 12px !important;">{{mensaje}}</a>
    <mat-dialog-content>
        <div style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/error.png" class="imagen3" />
        </div>
    </mat-dialog-content>
    <div style="text-align: center;">
        <a style="font-size: 12px !important;">{{idiomas.confirmarDialogoBeneficiarios}}</a>
    </div>
    <div class="col-12" style="display: flex; justify-content: center;">
        <mat-dialog-actions >
            <button mat-raised-button class="buttonToken" (click)="accept()">{{aceptar}}</button>
        </mat-dialog-actions>
        <mat-dialog-actions>
            <button mat-raised-button class="buttonToken ml-3" (click)="close()">{{rechazar}}</button>
        </mat-dialog-actions>
    </div>
</div>