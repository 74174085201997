import { Injectable } from "@angular/core";

@Injectable()
export class FuncionesTabla {
  
  constructor() {
  }

  DivHeight(datos: any) {
    if (window.orientation != 90 && window.orientation != -90) {
      document.getElementById('tableDiv').setAttribute('style', 'padding-bottom: 0px')
      var lastelement = document.getElementById('lastelement').getBoundingClientRect().top + document.getElementById('lastelement').clientHeight + document.getElementsByClassName('cabecera')[0].clientHeight
      if ((datos.length * document.getElementById('celda').clientHeight) < (window.innerHeight - lastelement - 70)) {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - lastelement - 70) + 'px')
      }
    } else {
      document.getElementById('tableDiv').setAttribute('style', 'padding-bottom: 38px')
      if ((datos.length * document.getElementById('celda').clientHeight) < (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 110)) {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight -
          document.getElementsByClassName('cabecera')[0].clientHeight - 110) + 'px')
      }
    }
  }

  DivHeight1(datos: any) {
    if (window.orientation != 90 && window.orientation != -90) {
      document.getElementById('tableDiv').setAttribute('style', 'padding-bottom: 0px')
      var lastelement = document.getElementById('lastelement').getBoundingClientRect().top + document.getElementById('lastelement').clientHeight + document.getElementsByClassName('cabecera')[0].clientHeight
      if ((datos.length * document.getElementById('celda').clientHeight) < (window.innerHeight - lastelement - 40)) {
        document.getElementsByClassName('container3Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container3Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - lastelement - 40) + 'px')
      }
    } else {
      document.getElementById('tableDiv').setAttribute('style', 'padding-bottom: 81px')
      if ((datos.length * document.getElementById('celda').clientHeight) < (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 110)) {
        document.getElementsByClassName('container3Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container3Aux')[0].setAttribute('style', 'height:' + (window.innerHeight -
          document.getElementsByClassName('cabecera')[0].clientHeight - 110) + 'px')
      }
    }
  }

  DivHeight2(datos: any, lenght, nrg) {
    var data = datos.length
    if(lenght < data || data < nrg)
      data = lenght
    else  
       data = nrg
    if (window.orientation != 90 && window.orientation != -90) {
      document.getElementById('tableDiv').setAttribute('style', 'padding-bottom: 0px')
      var lastelement = document.getElementById('lastelement').getBoundingClientRect().top + document.getElementById('lastelement').clientHeight + document.getElementsByClassName('cabecera')[0].clientHeight
      if ((data * document.getElementById('celda').clientHeight) < (window.innerHeight - lastelement - 50)) {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - lastelement - 40) + 'px')
      }
    } else {
      document.getElementById('tableDiv').setAttribute('style', 'padding-bottom: 81px')
      if ((data * document.getElementById('celda').clientHeight) < (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 110)) {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
      } else {
        document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight -
          document.getElementsByClassName('cabecera')[0].clientHeight - 110) + 'px')
      }
    }
  }
}
