<mat-sidenav-container (swipeleft)="!isLargeScreenForSideNav() ? cerrarMenu($event) : null" class="sidenav-container"
    [class.sidenav-contoffline]="!internetC.internet">
    <mat-sidenav (keyup.esc)="resizeToolbar()" [mode]="isLargeScreenForSideNav() ? 'side' : 'over'" id="snav-EC" #snav
        class="sidenav" (click)="selectedSNav(true)" style="min-width: 250px; position: fixed; padding-right: 11px;">
        <div class="borde">
            <img src="assets/resources/mipmap-hdpi/logo_clientMenu.png" class="imagen2"
                [class.offline]=!internetC.internet />
            <div style="margin-left: 5px;">
                <div>
                    <mat-label style="font-size: 10px;">{{idiomas.Usuario | uppercase}}: {{loginInfo.nombreUsuario}}
                    </mat-label>
                </div>
                <div style="margin-top: -7px;">
                    <mat-label style="font-size: 10px;">{{idiomas.LastLogin | uppercase}}: {{loginInfo.lastLogin}}
                    </mat-label>
                </div>
            </div>
        </div>
        <a style="font-size: inherit; font-family: 'Noto Sans', 'Helvetica Neue', sans-serif">{{idiomas.Ecommerce}}
            - {{tabName}}</a>
        <mat-nav-list id="matNavListEcomm">
            <a mat-list-item id="ecomm0" class="snavfont" (click)="closeEComm()" [class.medianosidenav]=mediano
                [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm0" style="margin-right: 5px">home</mat-icon>
                {{idiomas.Home}}
            </a>
            <a *ngIf="tabName == 'Socio'" mat-list-item id="ecomm1" class="snavfont" (click)="move(1)"
                [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm1" style="margin-right: 5px">store</mat-icon>
                {{idiomas.Tiendas}}
            </a>
            <a *ngIf="tabName == 'Socio'" mat-list-item id="ecomm2" class="snavfont" (click)="move(2)"
                [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm2" style="margin-right: 5px">shopping_basket</mat-icon>
                {{idiomas.ComprasRealizadas}}
            </a>
            <a *ngIf="tabName == 'Administrador'" mat-list-item id="ecomm1" class="snavfont" (click)="move(1)"
                [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm1" style="margin-right: 5px">inventory</mat-icon>
                {{idiomas.AdminProductos}}
            </a>
            <a *ngIf="tabName == 'Administrador'" mat-list-item id="ecomm2" class="snavfont" (click)="move(2)"
                [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm2" style="margin-right: 5px">local_offer</mat-icon>
                {{idiomas.VentasRealizadas}}
            </a>
            <a *ngIf="tabName == 'Socio'" mat-list-item id="ecomm3" class="snavfont" (click)="move(3)"
                [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm3" style="margin-right: 5px">history</mat-icon><span
                    matBadge="{{numTransaccionesPendientes}}" matBadgeOverlap="false"
                    [matBadgeHidden]="numTransaccionesPendientes<=0"
                    matBadgeColor="accent">{{idiomas.TarOffline}}</span>
            </a>
            <a *ngIf="tabName == 'Socio'" mat-list-item id="ecomm4" class="snavfont" (click)="logout()"
                [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm4" style="margin-right: 5px">exit_to_app</mat-icon>
                {{idiomas.CerrarSesion}}
            </a>
            <a *ngIf="tabName == 'Administrador'" mat-list-item id="ecomm3" class="snavfont" (click)="logout()"
                [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px">
                <mat-icon id="icon-ecomm3" style="margin-right: 5px">exit_to_app</mat-icon>
                {{idiomas.CerrarSesion}}
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content id="contentHome">
        <div id="tool-bar" class="static-toolbar">
            <mat-toolbar class="cabecera" [class.offline]=!internetC.internet>
                <mat-toolbar-row>
                    <button mat-icon-button class="boton" id="btnMenu"
                        (click)="(snav.opened)?cerrarMenu($event):abrirMenu($event)">
                        <mat-icon matBadge="{{numTransaccionesPendientes}}"
                            [matBadgeHidden]="numTransaccionesPendientes<=0" matBadgeColor="accent">menu
                        </mat-icon>
                    </button>
                    <div class="row divlogos">
                        <img class="col-3" *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/logo_clientDark.png"
                            class="imagen3" [class.offline]=!internetC.internet />
                        <img class="col-3" *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/logo_client.png"
                            class="imagen3" [class.offline]=!internetC.internet />
                    </div>
                    <div style="margin-right: 1px; margin-left: 1px; padding-top: 10px" *ngIf=!internetC.internet>
                        <mat-icon>wifi_off</mat-icon>
                    </div>
                    <button mat-icon-button class="boton2" id="btnCerrarSesion" (click)="doBlur()"
                        [matMenuTriggerFor]="CerrarSesion">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #CerrarSesion="matMenu">
                        <a mat-menu-item (click)=logout()>{{idiomas.CerrarSesion}}</a>
                        <a mat-menu-item [matMenuTriggerFor]="Idioma">{{idiomas.Idioma}}</a>
                        <mat-menu #Idioma="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="locale=='es'"
                                [class.menuItemNoSelected]="locale!='es'" (click)=espanol()>{{idiomas.Espanol}}</a>
                            <a mat-menu-item [class.menuItemSelected]="locale=='en'"
                                [class.menuItemNoSelected]="locale!='en'" (click)=ingles()>{{idiomas.Ingles}}</a>
                        </mat-menu>
                        <a mat-menu-item [matMenuTriggerFor]="Fuente">{{idiomas.TamanoFuente}}</a>
                        <mat-menu #Fuente="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="grande" [class.menuItemNoSelected]="!grande"
                                (click)=Grande(false)>{{idiomas.Grande}}</a>
                            <a mat-menu-item [class.menuItemSelected]="mediano" [class.menuItemNoSelected]="!mediano"
                                (click)=Mediano(false)>{{idiomas.Mediana}}</a>
                            <a mat-menu-item [class.menuItemSelected]="normal" [class.menuItemNoSelected]="!normal"
                                (click)=Normal(false)>{{idiomas.Pequena}}</a>
                        </mat-menu>
                        <a mat-menu-item [matMenuTriggerFor]="Tema">{{idiomas.Tema}}</a>
                        <mat-menu #Tema="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==0"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=0"
                                (click)=cambiarTema(0)>{{idiomas.TemaOscuro}}</a>
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==1"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=1"
                                (click)=cambiarTema(1)>{{idiomas.TemaClaro}}</a>
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==2"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=2"
                                (click)=cambiarTema(2)>{{idiomas.TemaClaroVerde}}</a>
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==3"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=3"
                                (click)=cambiarTema(3)>{{idiomas.TemaClaroAzul}}</a>
                        </mat-menu>
                    </mat-menu>
                </mat-toolbar-row>
                <mat-toolbar-row class="cabecera2" style="visibility: hidden;">
                    <mat-tab-group (click)="clickMatNav($event)" (panleft)="panl()" (panright)="panr()" class="tabs"
                        [class.mediano]=mediano [class.grande]=grande
                        (selectedIndexChange)="selectedIndexChange1($event)" [selectedIndex]="selectedIndex1"
                        id="axuTabLabelsEcomm">
                        <mat-tab label="{{idiomas.Home}}">
                        </mat-tab>
                        <mat-tab *ngIf="tabName == 'Socio'" label="{{idiomas.Tiendas}}">
                        </mat-tab>
                        <mat-tab *ngIf="tabName == 'Socio'" label="{{idiomas.ComprasRealizadas}}">
                        </mat-tab>
                        <mat-tab *ngIf="tabName == 'Administrador'" label="{{idiomas.AdminProductos}}">
                        </mat-tab>
                        <mat-tab *ngIf="tabName == 'Administrador'" label="{{idiomas.VentasRealizadas}}">
                        </mat-tab>
                        <mat-tab *ngIf="tabName == 'Socio'" label={{idiomas.TarOffline}}>
                        </mat-tab>
                    </mat-tab-group>
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
        <div class="under-toolbar">
            <mat-tab-group id="matTabHeaderHideEcom" class="tabs" [class.offline]=!internetC.internet
                [class.mediano]=mediano [class.grande]=grande (selectedIndexChange)="selectedIndexChange($event)"
                [selectedIndex]="selectedIndex">
                <mat-tab label="{{idiomas.Home}}">
                    <div id="modHome" class="contenido">
                    </div>
                </mat-tab>
                <mat-tab *ngIf="tabName == 'Socio'" label="{{idiomas.Tiendas}}">
                    <div id="tiendas" class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-proveedores #tiendas [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas"></app-proveedores>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="tabName == 'Socio'" label="{{idiomas.ComprasRealizadas}}">
                    <div id="modCompras" class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-consulta-cv-realizada #modCompras [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [idCompra]="idCompra">
                        </app-consulta-cv-realizada>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="tabName == 'Administrador'" label="{{idiomas.AdminProductos}}">
                    <div id="modAdminProducts" class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-panel-proveedores #modAdminProducts [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas">
                        </app-panel-proveedores>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="tabName == 'Administrador'" label="{{idiomas.VentasRealizadas}}">
                    <div id="modVentas" class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-consulta-cv-realizada #modVentas [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [idVenta]="idVenta"></app-consulta-cv-realizada>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="tabName == 'Socio'" label={{idiomas.TarOffline}}>
                    <div class="contenido" (swiperight)="izq($event)">
                        <app-trasacciones-offline [idiomas]="idiomas" #numTareas (numTarPenEcomm)="numTarPend($event)"
                            (tarPend)="toSpecificTab($event)"></app-trasacciones-offline>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- Powered by FITBANK -->
<div class="row bottomBanner" style="position: fixed; bottom: 0; z-index: 100;">
    <span
        style="font-size:13px;line-height:15px;color:#848484;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
        by&nbsp;</span>
    <span
        style="font-size:13px;line-height:15px;color:#0082cb;vertical-align:middle;font-weight: bold;"><strong></strong>
        FIT</span>
    <span
        style="font-size:13px;line-height:15px;color:#f7941d;vertical-align:middle;font-weight: bold;"><strong></strong>
        BANK&nbsp;</span>
    <span
        style="font-size:13px;line-height:15px;color:#848484;vertical-align:middle;font-weight: bold;"><strong></strong>
        &reg;</span>
</div>