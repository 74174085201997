<div id="login" *ngIf="bandsFuncionInicio.inicio">
    <div #toolBar id="tool-bar" class="static-toolbar">
        <mat-toolbar class="cabecera" [class.cabecera-mob]="bandMobile" [class.offline]=!internetC.internet>
            <mat-toolbar-row class="form-row" style="justify-content: center;">
                <img *ngIf="logoTemaOscuro" class="col-12" src="assets/resources/mipmap-hdpi/logo_clientDark.png"
                    class="imagen" [class.offline]=!internetC.internet alt="Fit-Bank">
                <img *ngIf="!logoTemaOscuro" class="col-12" src="assets/resources/mipmap-hdpi/logo_client.png"
                    class="imagen" [class.offline]=!internetC.internet alt="Fit-Bank">
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <div class="under-toolbar">
        <div *ngIf="!bandMobile">
            <div class="formMaxWidthInit">
                <div style="margin-bottom: 0px">
                    <div class="form-row contentMenu" style="background-color: transparent !important;">
                        <div *ngIf="bandsFuncion['1'] || bandsFuncion['2'] || bandsFuncion['3'] || bandsFuncion['4']">
                            <a class="form-row returnContainer" (click)="returnInicio()" style="max-width: 85px;">
                                <mat-icon class="returnArrow col">arrow_back_ios
                                </mat-icon>
                                <div class="returnLink col">{{idiomas.Regresar}}</div>
                            </a>
                        </div>
                        <mat-progress-bar *ngIf=intento mode="indeterminate"></mat-progress-bar>
                        <div class="col-12" style="margin-top: auto; margin-bottom: auto;">
                            <div *ngIf="bandsFuncion['0']" class="customCarouselContainer">
                                <owl-carousel-o [options]="customCarouselOptions">
                                    <ng-container>
                                        <ng-template carouselSlide>
                                            <img class="carouselImageWeb"
                                                src="assets/resources/mipmap-hdpi/imagen-1.jpg">
                                        </ng-template>
                                        <!--<ng-template carouselSlide>
                                            <img class="carouselImageWeb"
                                                src="assets/resources/mipmap-hdpi/imagen-2.jpg">
                                        </ng-template>
                                        <ng-template carouselSlide>
                                            <img class="carouselImageWeb"
                                                src="assets/resources/mipmap-hdpi/imagen-3.jpg">
                                        </ng-template>-->
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                            <!--<div *ngIf="bandsFuncion['0'] && marketingSlides.length > 0">
                                <div class="customCarouselContainer">
                                    <owl-carousel-o [options]="customCarouselOptions">
                                        <ng-container *ngFor="let slide of marketingSlides">
                                            <ng-template carouselSlide>
                                            <a href={{slide.href}} *ngIf="slide.href != ''" target="_blank">
                                                    <img class="carouselImageWeb"
                                                        [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + slide.src)">
                                                </a>
                                                <a *ngIf="slide.href == ''" target="_blank">
                                                    <img class="carouselImageWeb"
                                                        [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + slide.src)">
                                                </a>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                            </div>-->
                            <div *ngIf="bandsFuncion['2']">
                                <div *ngIf="internetC.internet" style="text-align: center;">
                                    <div>
                                        <h1 class="title" style="font-size: 20px;">
                                            <strong>{{idiomas.Contactos}}</strong>
                                        </h1>
                                    </div>
                                    <br>
                                </div>
                                <p><strong>{{idiomas.MsgTelefono}}</strong></p>
                                <ul>
                                    <li><a class="link-login" href="https://www.bancodelaustro.com/"
                                            target="_blank">Web</a>
                                    </li>
                                    <li>Call Center: 1800 228787</li>
                                </ul>
                                <p><a href="https://www.facebook.com/bancodelaustro" target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/facebook.png"
                                            style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                        href="https://www.instagram.com/bancodelaustro/" target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/instagram.png"
                                            style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                        href="https://twitter.com/i/flow/login?redirect_after_login=%2Fbancodelaustro"
                                        target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/twitter.png"
                                            style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                        href="https://www.youtube.com/channel/UCk7goI-O9_adFDfXwSX9G_A"
                                        target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/youtube.png"
                                            style="width: 24px; height: 24px;"></a>
                                </p>
                                <br>
                            </div>
                            <div *ngIf="bandsFuncion['3']">
                                <app-agencias-mapa [idiomas]="idiomas"></app-agencias-mapa>
                            </div>
                            <div *ngIf="bandsFuncion['4']">
                                <app-cajeros-mapa [idiomas]="idiomas"></app-cajeros-mapa>
                            </div>
                        </div>
                        <div *ngIf="bandsFuncion['0']" style="width: 100%; height: 150px; padding-top: 10px;">
                            <div>
                                <a (click)="buttonSelectedPres(1)"
                                    style="text-decoration: underline;cursor: pointer;text-align: center; font-size: .8em;">
                                    <div class="returnLink col">{{idiomas.DeseaCredito}}</div>
                                </a>
                            </div>
                            <div class="customCarouselContainer">
                                <owl-carousel-o [options]="customCarouselMenu">
                                    <ng-container>
                                        <ng-template carouselSlide>
                                            <div class="form-row">
                                                <div class="col-3" style="margin-top: 5px; text-align: center;">
                                                    <button mat-button class="buttonMenu"
                                                        [class.buttonSelected]="bandsFuncion['1']"
                                                        (click)="buttonSelected(1)">
                                                        <div class="iconRoundContainer">
                                                            <mat-icon class="iconMenu">accessibility_new</mat-icon>
                                                        </div>
                                                        <p style="font-size: .8em;">{{idiomas.NuevoSocio}}</p>
                                                    </button>
                                                </div>
                                                <div class="col-3" style="margin-top: 5px; text-align: center;">
                                                    <button mat-button class="buttonMenu"
                                                        [class.buttonSelected]="bandsFuncion['2']" (click)="login()">
                                                        <div class="iconRoundContainer">
                                                            <mat-icon class="iconMenu">login</mat-icon>
                                                        </div>
                                                        <p style="font-size: .8em;">{{idiomas.BancaPersonas}}</p>
                                                    </button>
                                                </div>
                                                <div class="col-3" style="margin-top: 5px; text-align: center;">
                                                    <button mat-button class="buttonMenu"
                                                        [class.buttonSelected]="bandsFuncion['3']"
                                                        (click)="loginCash()">
                                                        <div class="iconRoundContainer">
                                                            <mat-icon class="iconMenu">assignment_ind</mat-icon>
                                                        </div>
                                                        <p style="font-size: .8em;">{{idiomas.BancaEmpresas}}</p>
                                                    </button>
                                                </div>
                                                <div class="col-3" style="margin-top: 5px; text-align: center;">
                                                    <button mat-button class="buttonMenu"
                                                        [class.buttonSelected]="bandsFuncion['4']"
                                                        (click)="buttonSelected(2)">
                                                        <div class="iconRoundContainer">
                                                            <mat-icon class="iconMenu">phone_in_talk</mat-icon>
                                                        </div>
                                                        <p style="font-size: .8em;">{{idiomas.Contactos}}
                                                        </p>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template carouselSlide>
                                            <div class="form-row">
                                                <div class="col-4" style="margin-top: 5px; text-align: initial;">
                                                    <button mat-button class="buttonMenu"
                                                        [class.buttonSelected]="bandsFuncion['5']"
                                                        (click)="buttonSelected(3)">
                                                        <div class="iconRoundContainer">
                                                            <mat-icon class="iconMenu">business</mat-icon>
                                                        </div>
                                                        <p style="font-size: .8em;">{{idiomas.Agencias}}
                                                        </p>
                                                    </button>
                                                </div>
                                                <div class="col-4" style="margin-top: 5px; text-align: center;">
                                                    <button mat-button class="buttonMenu"
                                                        [class.buttonSelected]="bandsFuncion['6']"
                                                        (click)="buttonSelected(4)">
                                                        <div class="iconRoundContainer">
                                                            <mat-icon class="iconMenu">atm</mat-icon>
                                                        </div>
                                                        <p style="font-size: .8em;">{{idiomas.Cajeros}}
                                                        </p>
                                                    </button>
                                                </div>
                                                <div class="col-4" style="margin-top: 5px; text-align: end;">
                                                    <button mat-button class="buttonMenu"
                                                        [class.buttonSelected]="bandsFuncion['7']"
                                                        (click)="openSimulators()">
                                                        <div class="iconRoundContainer">
                                                            <mat-icon class="iconMenu">price_change</mat-icon>
                                                        </div>
                                                        <p style="font-size: .8em;">{{idiomas.Simuladores}}</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="bandMobile">
            <div class="formMaxWidthInit">
                <div style="margin-bottom: 0px">
                    <div *ngIf="bandsFuncion['1'] || bandsFuncion['2'] || bandsFuncion['3'] || bandsFuncion['4']"
                        style="padding-top: 5px;">
                        <a class="form-row returnContainer" (click)="returnInicio()" style="max-width: 85px;">
                            <mat-icon class="returnArrow col">arrow_back_ios
                            </mat-icon>
                            <div class="returnLink col">{{idiomas.Regresar}}</div>
                        </a>
                    </div>
                    <mat-progress-bar *ngIf=intento mode="indeterminate"></mat-progress-bar>
                    <div class="form-row contentMenu" style="background-color: transparent !important;">
                        <div class="col-12" style="margin-top: auto !important; margin-bottom: auto !important;">
                            <div *ngIf="bandsFuncion['0']" class="customCarouselContainerMHB">
                                <div style="padding-bottom: 10px;">
                                    <button mat-raised-button (click)="buttonSelectedPres(1)" color="primary"
                                        style="max-width: 130px; font-size: .6em;" class="buttonCred" fxFlexFill>
                                        <mat-icon color="primary">money</mat-icon>
                                        {{idiomas.DeseaCredito}}
                                    </button>
                                </div>
                                <div style="padding-top: 30% !important;">
                                    <owl-carousel-o [options]="customCarouselOptionsMHB">
                                        <ng-container>
                                            <ng-template carouselSlide>
                                                <img class="carouselImageMobile"
                                                    src="assets/resources/mipmap-hdpi/imagen-1.jpg">
                                            </ng-template>
                                            <!--<ng-template carouselSlide>
                                                <img class="carouselImageMobile"
                                                    src="assets/resources/mipmap-hdpi/imagen-2.jpg">
                                            </ng-template>
                                            <ng-template carouselSlide>
                                                <img class="carouselImageMobile"
                                                    src="assets/resources/mipmap-hdpi/imagen-3.jpg">
                                            </ng-template>-->
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                            </div>
                            <!--<div *ngIf="bandsFuncion['0']" class="col-12">
                                    <div class="customCarouselContainer">
                                    <owl-carousel-o [options]="customCarouselOptionsMHB">
                                        <ng-container *ngFor="let slide of marketingSlides">
                                            <ng-template carouselSlide>
                                                <a href={{slide.href}} *ngIf="slide.href != ''" target="_blank">
                                                    <img class="carouselImageMobile"
                                                        [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + slide.src)">
                                                </a>
                                                <a *ngIf="slide.href == ''" target="_blank">
                                                    <img class="carouselImageMobile"
                                                        [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + slide.src)">
                                                </a>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                            </div>-->
                            <div *ngIf="bandsFuncion['2']" class="col-12">
                                <div *ngIf="internetC.internet" style="text-align: center;">
                                    <div>
                                        <h1 class="title" style="font-size: 20px;">
                                            <strong>{{idiomas.Contactos}}</strong>
                                        </h1>
                                    </div>
                                    <br>
                                </div>
                                <p><strong>{{idiomas.MsgTelefono}}</strong></p>
                                <ul>
                                    <li><a class="link-login" href="https://www.bancodelaustro.com/"
                                            target="_blank">Web</a>
                                    </li>
                                    <li>Call Center: 1800 228787</li>
                                </ul>
                                <p><a href="https://www.facebook.com/bancodelaustro" target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/facebook.png"
                                            style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                        href="https://www.instagram.com/bancodelaustro/" target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/instagram.png"
                                            style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                        href="https://twitter.com/i/flow/login?redirect_after_login=%2Fbancodelaustro"
                                        target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/twitter.png"
                                            style="width: 24px; height: 24px;"></a>&nbsp; &nbsp;<a
                                        href="https://www.youtube.com/channel/UCk7goI-O9_adFDfXwSX9G_A"
                                        target="_blank"><img alt=""
                                            src="assets/resources/mipmap-hdpi/iconos/youtube.png"
                                            style="width: 24px; height: 24px;"></a>
                                </p>
                                <br>
                            </div>
                            <div *ngIf="bandsFuncion['3']" class="col-12">
                                <app-agencias-mapa [idiomas]="idiomas"></app-agencias-mapa>
                            </div>
                            <div *ngIf="bandsFuncion['4']" class="col-12">
                                <app-cajeros-mapa [idiomas]="idiomas"></app-cajeros-mapa>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
    <div class="bottomBannerLogin" *ngIf="bandMobile && bandsFuncion['0']" style="width: 100%; height: 150px; padding-top: 10px;">
        <div class="customCarouselContainer">
            <owl-carousel-o [options]="customCarouselMenu">
                <ng-container>
                    <ng-template carouselSlide>
                        <div class="form-row">
                            <div class="col-4" style="margin-top: 7px; text-align: center;">
                                <button mat-button class="buttonMenu" [class.buttonSelected]="bandsFuncion['1']"
                                    (click)="buttonSelected(1)">
                                    <div class="iconRoundContainer">
                                        <mat-icon class="iconMenu">accessibility_new</mat-icon>
                                    </div>
                                    <p style="font-size: .8em;">{{idiomas.NuevoSocio}}</p>
                                </button>
                            </div>
                            <div class="col-4" style="margin-top: 7px; text-align: center;">
                                <button mat-button class="buttonMenu" [class.buttonSelected]="bandsFuncion['2']"
                                    (click)="login()">
                                    <div class="iconRoundContainer">
                                        <mat-icon class="iconMenu">login</mat-icon>
                                    </div>
                                    <p style="font-size: .8em;">{{idiomas.BancaPersonas}}</p>
                                </button>
                            </div>
                            <div class="col-4" style="margin-top: 7px; text-align: center;">
                                <button mat-button class="buttonMenu" [class.buttonSelected]="bandsFuncion['4']"
                                    (click)="buttonSelected(2)">
                                    <div class="iconRoundContainer">
                                        <mat-icon class="iconMenu">phone_in_talk</mat-icon>
                                    </div>
                                    <p style="font-size: .8em;">{{idiomas.Contactos}}</p>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <!--<ng-template carouselSlide>
                        <div class="form-row">
                            <div class="col-4" style="margin-top: 7px; text-align: end;">
                                <button mat-button class="buttonMenu" [class.buttonSelected]="bandsFuncion['5']"
                                    (click)="buttonSelected(3)">
                                    <div class="iconRoundContainer">
                                        <mat-icon class="iconMenu">business</mat-icon>
                                    </div>
                                    <p style="font-size: .8em;">{{idiomas.Agencias}}
                                    </p>
                                </button>
                            </div>
                            <div class="col-4" style="margin-top: 7px; text-align: center;">
                                <button mat-button class="buttonMenu" [class.buttonSelected]="bandsFuncion['6']"
                                    (click)="buttonSelected(4)">
                                    <div class="iconRoundContainer">
                                        <mat-icon class="iconMenu">atm</mat-icon>
                                    </div>
                                    <p style="font-size: .8em;">{{idiomas.Cajeros}}</p>
                                </button>
                            </div>
                            <div class="col-4" style="margin-top: 7px; text-align: initial;">
                                <button mat-button class="buttonMenu" [class.buttonSelected]="bandsFuncion['7']"
                                    (click)="openSimulators()">
                                    <div class="iconRoundContainer">
                                        <mat-icon class="iconMenu">price_change</mat-icon>
                                    </div>
                                    <p style="font-size: .8em;">{{idiomas.Simuladores}}</p>
                                </button>
                            </div>
                        </div>
                    </ng-template>-->
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
    <!-- Powered by FITBANK -->
    <div class="row bottomBanner" style="position: fixed; bottom: 0;">
        <span *ngIf="logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#E3E2E2;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
            by&nbsp;</span>
        <span *ngIf="!logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#7C7E7E;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
            by&nbsp;</span>
        <span
            style="font-size:13px;line-height:15px;color:#0082cb;vertical-align:middle;font-weight: bold;"><strong></strong>
            FIT</span>
        <span
            style="font-size:13px;line-height:15px;color:#f7941d;vertical-align:middle;font-weight: bold;"><strong></strong>
            BANK&nbsp;</span>
        <span *ngIf="logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#E3E2E2;vertical-align:middle;font-weight: bold;"><strong></strong>
            &reg;</span>
        <span *ngIf="!logoTemaOscuro"
            style="font-size:13px;line-height:15px;color:#7C7E7E;vertical-align:middle;font-weight: bold;"><strong></strong>
            &reg;</span>
    </div>
</div>
<div *ngIf="bandsFuncionInicio.login" style="width: 100% !important; height: 100% !important;">
    <app-login #loginForm (evtRegresoDatos)="regresarInicio($event)"></app-login>
</div>