import { Component, OnInit, Inject, ɵConsole } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatExpansionModule, MatInputModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, OcultarCuentaPosTrans, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-administracion-tienda',
  templateUrl: './dialogo-administracion-tienda.component.html',
  styleUrls: ['./dialogo-administracion-tienda.component.css']
})
export class DialogoAdministracionTiendaComponent implements OnInit {
  er: boolean = false;
  idioma: any;
  adm: any;
  imagePath;
  imgURL: any;
  message: string;
  ctienda: any
  editarCategoria: boolean
  ingresarCategoria: boolean
  intento: boolean
  cimagen: any
  imagen: any
  ccategoriaPrin: any
  categoriadetallePrin: any
  ccategoriaSec: any
  categoriadetalleSec: any
  sloganDetalle: any
  categoriaTienda: any
  caracTiendaPrincipal: any
  caracTiendaSecundaria: any
  caracTiendaPrincipalDetalle: any
  caracTiendaSecundariaDetalle: any
  maxCaracteristicas: any
  cargaArchivo: boolean
  intentoCarac: boolean
  intentoTiendas: boolean
  categoriasTiendas: any[]
  cuentas: any[];
  ccuenta: any
  detalle: any
  intentoMessage: boolean
  intentoMessageError: boolean
  Message: any
  MessageError: any

  constructor
    (
      private dialogRef: MatDialogRef<DialogoAdministracionTiendaComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      public apiService: ApiService,
      public basedatos: BaseDatos,
      private validators: FormControlValidators,
      private _DomSanitizer: DomSanitizer
    ) {
    this.idioma = data.idioma
    this.adm = data.adm
    if (this.adm == 0) {
      this.ctienda = data.value
    } else {
      this.detalle = data.value
    }
    this.intento = false
    this.cargaArchivo = false
    this.intentoCarac = false
    this.intentoTiendas = false
    this.intentoMessage = false
    this.intentoMessageError = false
  }

  categoria1 = this.validators.textNumberForm()
  categoria2 = this.validators.textNumberForm()
  categoriaFormT = this.validators.comboForm();
  subcategoria = this.validators.textNumberForm()
  subcategoria1 = this.validators.textNumberForm()
  slogan = this.validators.textNumberSpecialFeaturetForm()
  categoriasTiendasAnt = this.validators.textNumberForm()
  cuentaForm = this.validators.comboForm();


  ngOnInit() {
    if (this.adm == 1) {
      this.intentoTiendas = true
      this.slogan.setValue(this.detalle)
    } else {
      this.retornarTienda()
    }

  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.obtainOwnAccount());
    Promise.resolve(null).then(() => this.retornarCategoriasLV());
  }

  close() {
    this.dialogRef.close(this.er);
  }

  onSelectFile(event) {
    if (event.target.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.cargaArchivo = true
      this.imgURL = reader.result;
    }
  }

  editStore() {
    this.intentoTiendas = false
    let envio = {
      transaccion: '183034-man-editarTienda.xml'
    }

    if (this.slogan.value && (this.slogan.value != this.sloganDetalle)) {
      envio["cri^ttie0^CTIENDA^NORMAL^ttie0^="] = this.ctienda
      envio["rec^ttie0^0^CTIENDA^ttie0"] = this.ctienda
      envio["rec^ttie0^0^SLOGAN^ttie0^VAL"] = this.slogan.value.toUpperCase()
      if (this.sloganDetalle != null) {
        envio["rec^ttie0^0^SLOGAN^ttie0^oldval"] = this.sloganDetalle
      }
      envio['rec^ttie0^0^VERSIONCONTROL^ttie0'] = "$vc"
    } else {
      envio["rec^ttie0^0^SLOGAN^ttie0"] = this.sloganDetalle
    }

    if (this.categoriaFormT.value && (this.categoriaFormT.value != this.ccategoriaPrin)) {
      envio["rec^ttce0^0^CTIENDA^ttce0"] = this.ctienda
      envio["rec^ttce0^0^CCATEGORIAECOMM^ttce0"] = this.ccategoriaPrin
      envio['rec^ttce0^0^VERSIONCONTROL^ttce0^val'] = "-1"
      envio['rec^ttce0^0^VERSIONCONTROL^ttce0^oldval'] = "0"

      envio["rec^ttce1^0^CTIENDA^ttce1^val"] = this.ctienda
      envio["rec^ttce1^0^CCATEGORIAECOMM^ttce1^val"] = this.categoriaFormT.value
    }

    if (this.subcategoria.value && (this.subcategoria.value != this.caracTiendaPrincipalDetalle)) {
      envio["rec^ttfe0^0^CCARACTERISTICA^ttfe0"] = this.caracTiendaPrincipal
      envio["rec^ttfe0^0^DESCRIPCION^ttfe0^val"] = this.subcategoria.value.toUpperCase()
      envio["rec^ttfe0^0^DESCRIPCION^ttfe0^oldval"] = this.caracTiendaPrincipalDetalle == null ? this.subcategoria.value : this.caracTiendaPrincipalDetalle
      envio['rec^ttfe0^0^VERSIONCONTROL^ttfe0'] = "$vc"
      envio["rec^ttfe0^0^CTIENDA^ttfe0"] = this.ctienda
    }

    if (this.subcategoria1.value && (this.subcategoria1.value != this.caracTiendaSecundariaDetalle)) {
      envio["rec^ttfe1^0^CCARACTERISTICA^ttfe1"] = this.caracTiendaSecundaria
      envio["rec^ttfe1^0^DESCRIPCION^ttfe1^val"] = this.subcategoria1.value.toUpperCase()
      envio["rec^ttfe1^0^DESCRIPCION^ttfe1^oldval"] = this.caracTiendaSecundariaDetalle == null ? this.subcategoria1.value : this.caracTiendaSecundariaDetalle
      envio['rec^ttfe1^0^VERSIONCONTROL^ttfe1'] = "$vc"
      envio["rec^ttfe1^0^CTIENDA^ttfe1"] = this.ctienda
    }

    if (this.cuentaForm.value && (this.cuentaForm.value != this.ccuenta)) {
      envio["cri^ttie0^CTIENDA^NORMAL^ttie0^="] = this.ctienda
      envio["rec^ttie0^0^CTIENDA^ttie0"] = this.ctienda
      envio["rec^ttie0^0^CCUENTA^ttie0^VAL"] = this.cuentaForm.value
      if (this.sloganDetalle != null) {
        envio["rec^ttie0^0^CCUENTA^ttie0^oldval"] = this.ccuenta
      }
      envio['rec^ttie0^0^VERSIONCONTROL^ttie0'] = "$vc"
    }

    if (this.cargaArchivo) {
      envio["rec^tImag^0^CIMAGEN^tImag"] = this.cimagen
      envio["rec^tImag^0^IMAGEN^tImag^val"] = this.imgURL.split(',')[1]
      envio["rec^tImag^0^IMAGEN^tImag^oldval"] = this.imagen
      envio['rec^tImag^0^VERSIONCONTROL^tImag'] = "$vc"
    }

    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
      this.intentoTiendas = true
      this.message = this.idioma.TransaccionE.toUpperCase()
      this.intentoMessage = true
    }, (err) => {
      this.intentoTiendas = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  obtainOwnAccount() {
    this.intento = true;
    const envio = {
      transaccion: '6071'
    }
    this.apiService.postProvider2InternetCheck('/cuentastrasferencias', this.basedatos.id_token, envio).then((data: any) => {
      this.cuentas = this.transformadorCuentasDesde(data);
      const consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.cuentas,
      };
      this.cuentaForm.setValue(this.cuentas[0].value)
      this.llenarDatos()
      //this.intento= false;
      //this.basedatos.insertarConsulta('CtasTransfCtasProp', consulta);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
      else { //Sin Internet    
      }
    });
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].id, 'viewValue':
          value[i].etiqueta, 'descProducto': value[i].producto, moneda: value[i].moneda,
        tipoidentificacion: value[i].ctipoIdentificacion
      });
    }
    return lista;
  }

  llenarDatos() {
    this.intento = false
  }

  retornarCategoriasLV() {
    this.intento = true
    let enviomonedas = {
      transaccion: '183034-con-categoriasT.xml',
      alias: "tcat0"
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, enviomonedas).then((data: any) => {
      this.categoriasTiendas = this.transformadorCategorias(data.tcat0)
      this.categoriaFormT.setValue(this.ccategoriaPrin)
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  transformadorCategorias(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].CCATEGORIAECOMM, 'viewValue':
          value[i].DESCRIPCION, 'descProducto': value[i].DESCRIPCION,
      });
    }
    return lista;
  }


  retornarTienda() {
    let envio = {
      transaccion: '183034-con-tiendas.xml',
      "cri^ttie0^CTIENDA^NORMAL^ttie0^=": this.ctienda,
      "cri^ttce0^CTIENDA^NORMAL^ttce0^=": this.ctienda,
      "cri^ttet0^CTIENDA^NORMAL^ttet0^=": this.ctienda
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.sloganDetalle = data.ttie0[0]['SLOGAN'] != null ? data.ttie0[0]['SLOGAN'] : null;
      if (data.ttet0[0]) {
        this.ccategoriaPrin = data.ttet0[0]['CCATEGORIAECOMM'] != null ? data.ttet0[0]['CCATEGORIAECOMM'] : null;
        this.categoriadetallePrin = data.ttet0[0]['DESCRIPCION'] != null ? data.ttet0[0]['DESCRIPCION'] : null;
      }
      this.cimagen = data.ttie0[0]['CIMAGEN'] != null ? data.ttie0[0]['CIMAGEN'] : null;
      this.imagen = data.ttie0[0]['IMAGEN'] != null ? data.ttie0[0]['IMAGEN'] : null;
      this.ccuenta = data.ttie0[0]['CCUENTA'] != null ? data.ttie0[0]['CCUENTA'] : null;
      this.caracTiendaPrincipal = data.ttce0[0]['CCARACTERISTICA'] != null ? data.ttce0[0]['CCARACTERISTICA'] : null;
      this.caracTiendaPrincipalDetalle = data.ttce0[0]['DESCRIPCION'] != null ? data.ttce0[0]['DESCRIPCION'] : null;
      this.caracTiendaSecundaria = data.ttce0[1]['CCARACTERISTICA'] != null ? data.ttce0[1]['CCARACTERISTICA'] : null;
      this.caracTiendaSecundariaDetalle = data.ttce0[1]['DESCRIPCION'] != null ? data.ttce0[1]['DESCRIPCION'] : null;
      this.subcategoria.setValue(this.caracTiendaPrincipalDetalle)
      this.subcategoria1.setValue(this.caracTiendaSecundariaDetalle)
      this.slogan.setValue(this.sloganDetalle)
      this.categoriaFormT.setValue(this.categoriadetallePrin)
      this.intentoTiendas = true
      this.imgURL = this._DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + this.imagen)
    }, (err) => {
      this.intentoTiendas = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
  }

}