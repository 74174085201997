<div style="width: 100%; height: 50px; margin-top: 1%; text-align: center">
    <label *ngIf="adm==0" class="center"><strong>{{idioma.MantenimientoTienda| uppercase}}</strong></label>
    <label *ngIf="adm==1" class="center"><strong>{{idioma.Detalles| uppercase}}</strong></label>
    <div *ngIf="!intentoTiendas">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div><br>
</div>
<mat-dialog-content>
    <div class="row" *ngIf="adm==0 && !intentoMessage">
        <div class="container" style="padding-bottom: 15px;text-align-last: center;">
            <img [src]="imgURL" width="156" height="123" *ngIf="imgURL"><br />
            <span style="color:red;" *ngIf="message">{{message}}</span>
            <input style="display: none" type="file" accept='image/*' (change)="onSelectFile($event)" #hiddenfileinput>
            <button (click)="hiddenfileinput.click()" mat-raised-button color="primary">
                <mat-icon aria-label="Remove" style="cursor: pointer !important;" title="SELECCIONA IMAGEN">add_a_photo
                </mat-icon>
            </button>
        </div>
        <div class="col-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Cuenta}}</mat-label>
                <mat-select [formControl]="cuentaForm">
                    <mat-option *ngFor="let cuenta of cuentas" [(value)]="cuenta.value">
                        {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Categoria| uppercase}}</mat-label>
                <mat-select [formControl]="categoriaFormT">
                    <mat-option *ngFor="let categoria of categoriasTiendas" [value]="categoria.value">
                        {{categoria.descProducto}}
                        <mat-divider style="border-top-width: 3px;"></mat-divider>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Caracteristica1| uppercase}}</mat-label>
                <input matInput type="text" [formControl]="subcategoria"
                    oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>{{idioma.Caracteristica2| uppercase}}</mat-label>
                <input matInput type="text" [formControl]="subcategoria1"
                    oninput="this.value = this.value.toUpperCase();">
            </mat-form-field>
        </div>
        <div class="container">
            <mat-expansion-panel>
                <mat-expansion-panel-header style="padding-left: 0">
                    <mat-panel-title>
                        SLOGAN
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline" fxFlexFill>
                    <textarea matInput maxlength="150" [formControl]="slogan"
                        oninput="this.value = this.value.toUpperCase();"></textarea>
                </mat-form-field>
            </mat-expansion-panel>
        </div>
        <div class="row" *ngIf="adm==0 && intentoMessageError">
            <div class="container" style="text-align-last: center;">
                <mat-label>{{messageError}}</mat-label>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="intentoTiendas && adm==1">
        <div class="container">
            <mat-form-field appearance="outline" fxFlexFill>
                <textarea matInput maxlength="150" [formControl]="slogan" readonly></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="adm==0 && intentoMessage">
        <div class="container" style="text-align-last: center;">
            <mat-label>{{message}}</mat-label>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button *ngIf="intentoTiendas && adm==0 && !intentoMessage" mat-raised-button (click)="editStore()"
        class="buttonSubmit" color="primary">{{idioma.GuardarCambios}}</button>
    <button *ngIf="intentoTiendas && intentoMessage" mat-raised-button class="buttonSubmit" color="primary"
        (click)="close()">{{idioma.Regresar}}</button>
    <button *ngIf="intentoTiendas && !intentoMessage" mat-raised-button class="buttonCancel" color="warn"
        (click)="close()">{{idioma.Rechazar}}</button>
</mat-dialog-actions>