<div class="textura">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <h2 mat-dialog-title style="text-align: center;">{{idiomas.BtnCalificar.toUpperCase()}}</h2>
    <div *ngIf=intento>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <mat-dialog-content>
          <div class="row">
            <div class="col-12">
              <button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
                (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                <mat-icon>
                  {{showIcon(i)}}
                </mat-icon>
              </button>
              <mat-error *ngIf="starCount == null || starCount == 0">
                Star count is <strong>required</strong> and cannot be zero
              </mat-error>
              <p class="body-2">
                {{idiomas.Rating}} <span class="body-2">{{rating}}</span> / <span
                  class="body-2">{{starCount}}</span>
              </p>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline" fxFlexFill>
                <mat-label><strong>{{idiomas.observacionRating}}</strong></mat-label>
                <textarea matInput [formControl]="observacionRating" style="min-height: 200px;"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6" style="margin-top: 10px;">
              <button mat-raised-button (click)="calificarHB()" *ngIf="rating > 0 && observacionRating.valid"
                title={{idiomas.BtnCalificanos}} color="primary" class="buttonSubmit">
                {{idiomas.BtnCalificanos}}
              </button>
            </div>
          </div>
    </mat-dialog-content>
</div>