import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormControlValidators } from '../funciones/formcontrol';
import { Alerta } from '../funciones/alerta';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-administracion-ventas',
  templateUrl: './dialogo-administracion-ventas.component.html',
  styleUrls: ['./dialogo-administracion-ventas.component.css']
})
export class DialogoAdministracionVentasComponent implements OnInit {
  
  er: boolean = false;
  idioma: any;
  bandVenta: boolean;
  mensaje: boolean;
  nmensaje: string;
  secuencia: string;
  movimiento: any;
  intento: boolean;

  constructor
    (
      private dialogRef: MatDialogRef<DialogoAdministracionVentasComponent>,
      @Inject(MAT_DIALOG_DATA) data, private validators: FormControlValidators, public apiService: ApiService, 
      public basedatos: BaseDatos, public servicios: Servicios) {
    this.idioma = data.idioma;
    this.nmensaje = data.datos.numMensaje;
    this.secuencia = data.datos.secuencia;
    this.bandVenta = false;
    this.mensaje = false;
    this.movimiento = {};
    this.intento = false;
  }

  token = this.validators.numberForm()
  mensajeer = this.validators.textForm()

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(this.er);
  }


  obtainMovement() {

  }

  confirm() {
    this.intento = true
    let envio = {
      transaccion: '183037-man-transfer-movement.xml',
      "ctl^PURCHASE_MESSAGEID": this.nmensaje,
      "ctl^SECUENCIA": this.secuencia,
      "ctl^PURCHASE_TOKEN": this.token.value
    }
    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = false
      this.bandVenta = true
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.bandVenta = false
        this.mensaje = true
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
        }
      }
      else { //Sin Internet
        this.bandVenta = false
        this.mensaje = true
      }
    });
  }

  /*transformadorMovimiento(value) {
    var movimiento = {};
    var estatus = ''
    movimiento = {
      cuenta: value["tmov0.CCUENTA_ORDENANTE"], cuentaBe: value["tmov0.CCUENTA_BENEFICIARIO"],
      monto: value["tmov0.TOTAL"], clave: this.servicios.toAES(value["tmov0.TOKEN"]), 
      referencia: "Compra de " + value["tmov0.CANTIDAD"] + " " + value["tpro0.NOMBRE"],
      desencriptar: '1', isEcommerceTransfer: '1'
    }
    return movimiento
  }*/

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  logout() {
    let obj = {
      'salir': true
    }
  }
}
