import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, DoCheck } from '@angular/core';
import { UntypedFormControl, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Router } from '@angular/router';
import { Servicios } from '../funciones/encryptar'
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet'
import { FormControlValidators } from '../funciones/formcontrol';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';

import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CambiarTextoTransaccion, CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { GraficoSaldosTiempoComponent } from '../grafico-saldos-tiempo/grafico-saldos-tiempo.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatTableModule, MatExpansionModule, MatDividerModule, MatProgressBarModule, MatDatepickerModule, MatInputModule, MatButtonModule, MatRadioModule, CarouselModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, CambiarTextoTransaccion, FormatoFecha, FlexLayoutModule, GraficoSaldosTiempoComponent],
  selector: 'app-transacciones',
  templateUrl: './transacciones.component.html',
  styleUrls: ['./transacciones.component.css']
})
export class TransaccionesComponent implements DoCheck {

  @Input() parametros: any;
  @Input() idiomas: any;
  @Input() data: any;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() isMobile: boolean;
  @Input() deshabilitarTransacciones: boolean;
  @Input() deshabilitarBloqueos: boolean;
  @Input() deshabilitarConfirmar: boolean;
  @Input() deshabilitarPignorados: boolean;
  @Input() txDesdeTx: boolean;
  @Output() mostrarSaldos04 = new EventEmitter<any>();
  @Output() mostrarSaldos06 = new EventEmitter<any>();

  fdesde: Date;
  fhasta: Date;
  datos: any;
  peticion: any;
  fd: any;
  fh: any;
  datos2: any[];
  datos1: any[];
  datosTabla: any[];
  datosGrafico: any[];
  bandCheck: boolean;
  deshabilitar: boolean;
  datosBand: boolean;
  autoPeticion: boolean;
  primeraconsulta: string;
  pdf: string = "";
  xls: string = "";
  bandData0506: boolean;
  bandBloqueos: boolean;
  bandPorConf: boolean;
  bandPignorados: boolean;
  bandPorConf2: boolean;
  paraScroll: any;
  topOfTable: any;
  container: boolean;
  factual = new Date();
  maxDate = new Date(this.factual.getUTCFullYear(), this.factual.getUTCMonth(), this.factual.getUTCDate());
  minimunDate = new Date(this.factual.getUTCFullYear(), this.factual.getUTCMonth(), this.factual.getUTCDate());
  minDate;
  factualPr;
  factualP = new Date()
  maxDateP;
  minDateP;
  displayedColumnsTAhorros = ['Fecha', 'Monto'/*, '+/-'*/, 'Movimientos', 'Saldo Contable'/*, 'Saldo Disponible'*/, 'Referencia'/*, 'Imprimir'*/, 'Oficina'];
  displayedColumnsTPlazo = ['NumCuotas', 'Vencimiento', 'Capital', 'Interes', 'Impuesto', 'Estado'/*, 'ValCuota', 'Dias'*/];
  displayedColumnsPrestamosPreaprobados = ['Producto', 'Moneda', 'Montomin', 'Montomax', 'Plazomin', 'Plazomax', 'Tasa', 'Estado'];
  displayedColumnsPresAprobados = ['MonMAX', 'PlazoMAX', 'MonMIN', 'PlazoMIN', /*'Moneda',*/ 'Tasa', 'Estatus', 'Producto'];
  displayedColumnsTPrestamos = ['NumCuota', 'Vencimiento', 'CapReducido', 'Capital', 'Interes', 'Seguros', 'ValorMora',/* 'DiasMora', 'TotCuota',*/ 'ValorDiferimiento', 'InteresDifrerimiento', 'ValorPagar', 'FPago',/*, 'Dias', 'Comision', 'Cargos', 'Fabono',*/ 'Estado'];
  displayedColumnsAhorros = ['Cuenta', 'Disponible', 'PorConfirmar', 'Bloqueado', 'Pignorado', 'Sobregiro', 'Total',  /*'Fecha','Interes', 'Tasa', 'Producto', 'Moneda', 'Estado'*/];
  displayedColumnsPlazoFijo = ['Cuenta', 'MontoAper', 'Plazo', 'Tasa', 'Apertura', 'Vencimiento', 'Interes', 'Frecuencia', 'CuotaInteres', 'Impuesto', 'FPago', 'CuotaPagada', 'CuotaPagar', 'Producto', 'Moneda', 'Estado'];
  displayedColumnsPrestamos = ['Cuenta', 'MontoAper', 'SaldoCapital', 'Svencido', 'SInteres', 'CuotaMora', 'DiasMora', 'InteresesMora', 'ctasPorCobrar', 'Seguro', 'ValorPagar', 'fProxPago', 'Plazo', 'Tasa', 'fVencimiento', 'Producto', 'Moneda', 'Estado'];
  flechaR: boolean;
  flechaL: boolean;
  dataSourceBlo: any[];
  dataSourceCheLoc: any[];
  dataSourceCheOts: any[];
  dataSourcePig: any[];
  displayedColumns = ['Nro', 'Fecha', 'Hora', 'Concepto', 'Monto', 'Vencimiento'/*, 'Detalle'*/];
  displayedColumnsChequesLocales = ['FechaDeposito', 'Hora', 'Banco', 'Monto', 'FechaConfirmacion', 'NumCheque', 'CtaGirada'];
  displayedColumnsChequesOtros = ['FechaDeposito', 'Hora', 'Banco', 'Monto', 'FechaConfirmacion', 'NumCheque', 'CtaGirada'];
  displayedColumnsPig = ['Nro', 'Fecha', /*'Hora', */'Concepto', 'Monto', 'Vencimiento'];
  flechaR1: boolean;
  flechaL1: boolean;
  flechaL2: boolean;
  flechaR2: boolean;
  prestprea: any[];
  prestamos: string = 'PRESTAMOS'
  numMaxRow: number; //Numero maximo de filas en tablas para sticky
  numeroCuotasMax: any;
  numeroCuotasMin: any;
  numeroCuotas = new UntypedFormControl();
  cuotaAproximada = this.validators.decimalsForm();
  montoPrestamo = this.validators.decimalsForm();
  formEstadoCuenta = new UntypedFormControl();
  numeroDias = new UntypedFormControl('');
  intento: boolean;
  autoPeticion1: any;
  bandHeight: boolean;
  bandHeight1: boolean;
  datos4: any[];
  fechaActual = new Date();
  dated = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1);
  dateh = new Date();
  bandmonto: boolean;
  loanPaymentInfo: any;
  bandBtnPagar: boolean;
  lBandBtnPagar: any[];
  tasaGenerada: any = 0;
  bandNoAcepta: boolean;
  informacionPersonal: any;
  lestados: any[] = [];
  directorios: any[] = [];
  bandMovements: boolean = false;

  intentoCom: boolean = false;
  intentoVPC: boolean = false;

  // Preaproado
  mostrarCuenta: boolean = false;
  cuota: any = '';
  capital: any = '';
  interes: any = '';
  programado: any = '';
  valorCuota: any = '';
  fpago: any = '';
  totalCapital: any = '';
  totalInteres: any = '';
  totalAhorro: any = '';
  totalCuota: any = '';
  numeroOperacion: any = '';
  contador: any;
  cuentaRetorna: any = '';
  generaDocumento: boolean;
  secuecial: any = "";
  cuenta: any = "";
  deshabilitarC: boolean = true;
  valorAP: any = 0;
  valorF: any = 0;
  valorS: any = 0;
  valorSe: any = 0;
  oficial: any = '';
  sucursalO: any = '';
  cuentas: any;
  cuentaDesdeSelected = this.validators.comboForm();
  cuentaVista: any;
  mostrarTerminos: boolean = false;
  tokenSolicitado: boolean;
  checkedI: boolean = false;
  checkedC: boolean = false;
  checkedCP: boolean = false;
  bandMobile: boolean = false;
  deshabilitarI: boolean = true;
  deshabilitarIn: boolean = true;
  deshabilitarT: boolean = true;
  deshabilitarAP: boolean = true;
  listas: any = {};
  tokenFormControl = this.validators.otpForm();
  fechaF;

  //ngx-carousel options
  customCarouselOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 12000,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 900,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
  }

  constructor(private api: ApiService,
    public alerta: Alerta,
    private servicio: Servicios,
    private router: Router,
    private base: BaseDatos, public internet: internetComponent, private validators: FormControlValidators, public mostrarPdf: MostrarPDF) {
    this.fdesde = null;
    this.fhasta = null;
    this.deshabilitar = false;
    this.datos2 = [];
    this.datosTabla = [];
    this.datos4 = [];
    this.datosGrafico = [];
    this.datos1 = [];
    this.prestprea = []
    this.dataSourceBlo = [];
    this.dataSourceCheLoc = [];
    this.dataSourceCheOts = [];
    this.dataSourcePig = [];
    this.peticion = false;
    this.datosBand = true;
    this.flechaR = false;
    this.bandmonto = true;
    this.flechaL = true;
    this.flechaR1 = false;
    this.flechaL1 = true;
    this.flechaR2 = false;
    this.flechaL2 = true;
    this.paraScroll = 0;
    this.topOfTable = 0;
    this.autoPeticion = true;
    this.container = false;
    this.primeraconsulta = "";
    this.bandBloqueos = false;
    this.bandPorConf = false;
    this.bandPignorados = false;
    this.bandPorConf2 = false;
    this.bandData0506 = false;
    this.numMaxRow = 3;
    this.bandHeight = false;
    this.bandHeight1 = false;
    this.montoPrestamo.setValue(0);
    this.numeroCuotas.setValue(0);
    this.loanPaymentInfo = {};
    this.bandBtnPagar = false;
    this.lBandBtnPagar = [];
    this.bandNoAcepta = true;
    this.informacionPersonal = [];
    this.numeroCuotasMax = 0;
    this.numeroCuotasMin = 0;
    this.intento = false;
    this.bandNoAcepta = true;
    this.informacionPersonal = [];
    this.cuentas = [];
    this.cuentaVista = "";
    this.tokenSolicitado = false;
    this.cuentaDesdeSelected = new UntypedFormControl("", Validators.required);
    this.contador = 0;
    this.generaDocumento = false;
  }

  ngDoCheck() {
    if (this.txDesdeTx) {
      this.ngAfterViewInit()
      this.txDesdeTx = false
    }
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngOnInit() {
    this.minimunDate = this.sumarDias(this.minimunDate, -365)
  }

  ngAfterViewInit() {
    if (!this.bandMobile) {
      document.getElementById("floatContPosiConso").style.width = '0px';
      document.getElementById("floatContPosiConso").style.right = '100%';
    }
    document.querySelector('.mat-sidenav-content').scrollTop = 0;;
    if (this.parametros.subsistema != "04") {
      document.getElementById("loadData_05_06").style.visibility = 'visible';
    }
    if (this.parametros.subsistema == "04") {
      this.autoPeticion = true;
      this.autoPeticion1 = true;
      this.consultar04();
    }
    if (this.parametros.subsistema == "05") {
      this.consultar05();
    }
    if (this.parametros.subsistema == "06") {
      this.consultar06();
    }
    if (this.parametros.subsistema == "33") { //Bloqueos
      this.consultarBloqueos();
    }
    if (this.parametros.subsistema == "77") { //Por Confirmar
      this.consultarConfirmar();
    }
    if (this.parametros.subsistema == "99") { //Pignorados
      this.consultarPignorados();
    }
  }

  consultar04() {
    this.bandMovements = false
    this.fdesde = new Date(this.dated)
    this.fhasta = new Date(this.dateh)
    if (this.fdesde.getTime() > this.fhasta.getTime()) {
      this.alerta.presentarAlertaX(this.idiomas.FDesdeHasta)
      this.dated = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
      this.dateh = new Date()
    } else {
      this.peticion = false
      if (this.autoPeticion && this.paraScroll == 0) {
        this.topOfTable = 120; //Quemado para primera consulta
        this.autoPeticion = false;
        this.primeraconsulta = "1";
      }
      else {
        scroll(0, 0);
        this.topOfTable = document.getElementById("transaccionesTab").getBoundingClientRect().top;
        this.paraScroll = 0;
        this.primeraconsulta = "2";
        this.autoPeticion1 = false
      }
      try {
        document.getElementById("loadData_04").style.visibility = 'visible';
      } catch (error) {
        document.getElementById("loadData_05_06").style.visibility = 'visible';
      }
      var fecha = null;
      var hora = null;
      var descripcion = null;
      var debitocredito = null;
      var valormonedacuenta = null;
      var saldocontable = null;
      var saldodisponible = null;
      var oficina = null;
      var sucursal = null;
      var documento = null;
      var band = null;
      var numeromensaje = null;
      this.datosGrafico = []
      var i = 0;
      this.fd = this.fdesde.getFullYear() + "-" + (this.fdesde.getMonth() + 1) + '-' + this.fdesde.getDate();
      this.fh = this.fhasta.getFullYear() + "-" + (this.fhasta.getMonth() + 1) + '-' + this.fhasta.getDate();
      let pedido = { 'subsistema': this.parametros.subsistema, 'ccuenta': this.parametros.cuenta, 'fdesde': this.fd, 'fhasta': this.fh, 'grupo': this.parametros.grupo, 'primeraconsulta': this.primeraconsulta }
      this.api.postProvider2InternetCheck('/transacciones', this.base.id_token, pedido).then((data: any) => {
        this.datos = data.movimientos;
        this.datos2 = [];
        this.datosTabla = [];
        this.datos4 = [];
        this.datosGrafico = data.saldos;
        this.datos1 = [];
        for (let dato of this.datos) {
          fecha = dato.fecha;
          hora = dato.fecha;
          descripcion = dato.descripcion.toUpperCase();
          if (dato.debitocredito == 'C') {
            debitocredito = '+';
          } else {
            debitocredito = '-';
          }
          valormonedacuenta = dato.valormonedacuenta;
          saldocontable = dato.saldoContable;
          saldodisponible = dato.saldoDisponible;
          oficina = dato.oficina;
          sucursal = dato.sucursal;
          documento = dato.documento;
          numeromensaje = dato.numeroMensaje
          band = "04";
          this.datos1[i] = { fecha, hora, descripcion, debitocredito, valormonedacuenta, saldocontable, saldodisponible, oficina, sucursal, documento, band, numeromensaje };
          this.datos4[i] = { fecha, hora, descripcion, debitocredito, valormonedacuenta, saldocontable, saldodisponible, oficina, sucursal, documento, band, numeromensaje };
          i = i + 1;
        }
        if (this.datos1.length > 0) //Existen transacciones
        {
          this.datos2.push(this.datos1);
          this.peticion = true;
          this.datosTabla.push(this.datos4);
          //Almacenar consulta en BDD
          let consulta = {
            'fdesde': this.fdesde,
            'subsistema': this.parametros.subsistema,
            'fecha': new Date().toLocaleDateString(),
            'hora': new Date().toLocaleTimeString(),
            'data': this.datos1,
            'cuenta': this.parametros.cuenta,
            'grafico': this.datosGrafico
          }
          this.base.insertarTransacciones(consulta);
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.NoTransFechas.toUpperCase())
          this.peticion = false
        }
        try {
          document.getElementById("loadData_04").style.visibility = 'hidden';
          document.getElementById("loadData_05_06").style.visibility = 'hidden';
        } catch (error) { }
        this.bandMovements = true
      }, (err) => {
        if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
          if (err.error) {
            if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          }
          else {
            this.alerta.presentarAlerta(this.idiomas.ServidorError)
          }
        }
        else { //Sin Internet
          this.consultar04Offline();
        }
        try {
          document.getElementById("loadData_04").style.visibility = 'hidden';
          document.getElementById("loadData_05_06").style.visibility = 'hidden';
        } catch (error) { }
      })
    }
  }

  consultar04Offline() {
    this.datos2 = [];
    this.datosTabla = [];
    this.datos4 = [];
    this.datosGrafico = [];
    var objTransaccionesOffline = this.base.darTransacciones(this.parametros.cuenta);
    if (objTransaccionesOffline) {
      Promise.resolve(null).then(() => this.dated = new Date(objTransaccionesOffline.fdesde));
      Promise.resolve(null).then(() => this.dateh = new Date(objTransaccionesOffline.fhasta));
      Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(objTransaccionesOffline, this.idiomas)); //Para evitar errores de Change After Check
      Promise.resolve(null).then(() => this.datos2.push(objTransaccionesOffline.data));
      Promise.resolve(null).then(() => this.datos4 = objTransaccionesOffline.data.slice(0));
      Promise.resolve(null).then(() => this.datos4[this.datos4.length] = {});
      Promise.resolve(null).then(() => this.datosTabla.push(this.datos4));
      Promise.resolve(null).then(() => this.peticion = true);
      Promise.resolve(null).then(() => this.datosGrafico.push(objTransaccionesOffline.grafico));
    }
    else {
      Promise.resolve(null).then(() => this.datosBand = false);
      Promise.resolve(null).then(() => this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta));
    }
    document.getElementById("loadData_04").style.visibility = 'hidden';
    document.getElementById("loadData_05_06").style.visibility = 'hidden';
  }

  consultar05() {
    var fecha = null;
    var hora = null;
    var cuota = null;
    var totalCapital = null;
    var interesPagado = null;
    var impuesto = null;
    var valorcuota = null;
    var saldocontable = 0.00;
    var dias = null;
    var estado = null;
    var band = null;
    var i = 0;
    let pedido = { 'subsistema': this.parametros.subsistema, 'ccuenta': this.parametros.cuenta }
    this.api.postProvider2InternetCheck('/transacciones', this.base.id_token, pedido).then((data: any) => {
      this.datos = data.movimientos;
      this.datos2 = [];
      this.datos4 = [];
      this.datosGrafico = [];
      this.datos1 = [];
      for (let dato of this.datos) {
        fecha = dato.fecha;
        hora = dato.fecha;
        cuota = dato.cuota;
        totalCapital = dato.totalCapital;
        interesPagado = dato.interesPagado;
        impuesto = dato.impuesto;
        valorcuota = parseFloat(totalCapital) + parseFloat(dato.interesPagado) - parseFloat(dato.impuesto);
        dias = dato.dias;
        estado = dato.estado;
        saldocontable = saldocontable + valorcuota;
        band = "05";
        this.datos1[i] = { fecha, hora, cuota, totalCapital, interesPagado, impuesto, valorcuota, dias, estado, saldocontable, band };
        this.datos4[i] = { fecha, hora, cuota, totalCapital, interesPagado, impuesto, valorcuota, dias, estado, saldocontable, band };
        i = i + 1;
      }
      if (this.datos1.length > 0) //Existen transacciones
      {
        this.datos2.push(this.datos1);
        this.datosGrafico = this.datos2[0];
        this.datosTabla.push(this.datos4);
        //Almacenar consulta en BDD
        let consulta = {
          'subsistema': this.parametros.subsistema,
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.datos1,
          'cuenta': this.parametros.cuenta
        }
        this.base.insertarTransacciones(consulta);
        //.
        Promise.resolve(null).then(() => this.bandData0506 = true);
      }
      else {
        this.alerta.presentarAlerta(this.idiomas.NoTrans)
        this.peticion = false
      }
      document.getElementById("loadData_05_06").style.visibility = 'hidden';
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.consultar05Offline();
      }
    })
  }

  consultar05Offline() {
    this.datos2 = [];
    this.datosTabla = [];
    this.datos4 = [];
    this.datosGrafico = [];
    var objTransaccionesOffline = this.base.darTransacciones(this.parametros.cuenta);
    if (objTransaccionesOffline) {
      Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(objTransaccionesOffline, this.idiomas)); //Para evitar errores de Change After Check
      Promise.resolve(null).then(() => this.datos2.push(objTransaccionesOffline.data));
      Promise.resolve(null).then(() => this.datos4 = objTransaccionesOffline.data.slice(0));
      Promise.resolve(null).then(() => this.datos4[this.datos4.length] = {});
      Promise.resolve(null).then(() => this.datosTabla.push(this.datos4));
      Promise.resolve(null).then(() => this.datosGrafico = this.datos2[0]);
      Promise.resolve(null).then(() => this.bandData0506 = true);
    }
    else {
      Promise.resolve(null).then(() => this.datosBand = false);
      Promise.resolve(null).then(() => this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta));
    }
    document.getElementById("loadData_05_06").style.visibility = 'hidden';
  }

  consultar06() {
    var cuota = null;
    var capreducido = null;
    var numdiascal = null;
    var valorCap = null;
    var valorInt = null;
    var comision = null;
    var valorSeg = null;
    var cargo = null;
    var totalcuota = null;
    var fecha = null;
    var fpago = null;
    var fabono = null;
    var estadoWS = null;
    var estado = null;
    var band = null;
    var saldocontable = null;
    var diasMora = null;
    var valorMora = null;
    var valorPagar = null;
    var payFee = null;
    var abonoCuota = null;
    var saldoCuota = null;
    var valorDiferimiento = null;
    var interesDiferimiento = null;
    var i = 0;
    let pedido = { 'subsistema': this.parametros.subsistema, 'ccuenta': this.parametros.cuenta }
    this.api.postProvider2InternetCheck('/transacciones', this.base.id_token, pedido).then((data: any) => {
      this.datos = data.movimientos;
      this.datos2 = [];
      this.datos4 = [];
      this.datosTabla = [];
      this.datosGrafico = [];
      this.datos1 = [];
      for (let dato of this.datos) {
        cuota = dato.cuota;
        capreducido = dato.capreducido;
        numdiascal = dato.numdiascal;
        valorCap = dato.valorCap;
        valorInt = dato.valorInt;
        comision = dato.comision;
        valorSeg = dato.valorSeg;
        cargo = dato.cargo;
        fecha = dato.fecha;
        fpago = dato.fpago;
        fabono = dato.fabono;
        estadoWS = dato.estadoWS;
        estado = dato.estado;
        saldocontable = dato.capreducido;
        diasMora = dato.diasMora;
        valorMora = dato.valorMora;
        valorPagar = dato.valorcuotapagar;
        totalcuota = parseFloat(dato.totalcuota) - parseFloat(dato.valorMora);
        abonoCuota = dato.abonoCuota;
        saldoCuota = parseFloat(dato.totalcuota) - parseFloat(dato.abonoCuota);
        valorDiferimiento = dato.valorDiferimiento;
        interesDiferimiento = dato.interesDiferimiento;
        if (saldoCuota == 0) {
          saldoCuota = "0"
        }
        band = "06";
        //Se agregan elemento que indica estado de pago de cuota
        payFee = "3" == estadoWS ? "PAGADO" : "1" == estadoWS ?
          "ACTIVO" : "";

        this.datos1[i] = { cuota, capreducido, numdiascal, valorCap, valorInt, comision, valorSeg, cargo, totalcuota, fecha, band, fpago, fabono, estado, saldocontable, diasMora, valorMora, valorPagar, payFee, abonoCuota, saldoCuota, valorDiferimiento, interesDiferimiento };
        this.datos4[i] = { cuota, capreducido, numdiascal, valorCap, valorInt, comision, valorSeg, cargo, totalcuota, fecha, band, fpago, fabono, estado, saldocontable, diasMora, valorMora, valorPagar, payFee, abonoCuota, saldoCuota, valorDiferimiento, interesDiferimiento };
        i = i + 1;
      }
      if (this.datos1.length > 0) //Existen transacciones
      {
        this.datos2.push(this.datos1);
        this.datos4[i] = {}
        this.datosTabla.push(this.datos4);
        //Datos para grafico
        for (let registro of this.datos2[0]) {
          if (registro.estado == "CANCELADO" || registro.estado == "APROBADO")
            this.datosGrafico.push(registro);
        }
        if (this.datosGrafico.length == 0) {
          this.datosGrafico.push(this.datos2[0][0]);
        }
        //.
        //Almacenar consulta en BDD
        let consulta = {
          'subsistema': this.parametros.subsistema,
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.datos1,
          'cuenta': this.parametros.cuenta
        }
        this.base.insertarTransacciones(consulta);
        //.
        Promise.resolve(null).then(() => this.bandData0506 = true);
      }
      else {
        this.alerta.presentarAlerta(this.idiomas.NoTrans)
        this.peticion = false
      }
      document.getElementById("loadData_05_06").style.visibility = 'hidden';
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario)
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.consultar06Offline();
      }
    })
    //Para Pago de Prestamos
    let loanFee = (Number(this.data[0]['saldointeres']) + Number(this.data[0]['cuentasPorCobrar']) + Number(this.data[0]['saldomoraSigCuota']) + Number(this.data[0]['valorpagar'])).toString()
    this.loanPaymentInfo = {
      account: this.data[0]['ccuenta'],
      accountMask: this.data[0]['ccuentaetiqueta'],
      prodAccount: '5',
      monedaAccount: this.data[0]['cmoneda'],
      feeLoan: this.validators.FormatNumber(loanFee).toString(),
      actualBal: this.data[0]['actualBal']
    }
  }

  pagoPrestamo() {
    this.alerta.generarDialogoPagoPrestamo(this.idiomas, this.loanPaymentInfo).then((data) => {
      if (data) {
        this.mostrarSaldos06.emit(this.parametros);
        Promise.resolve(null).then(() => this.bandData0506 = false);
        this.txDesdeTx = true
      }
    })
  }

  consultar06Offline() {
    this.datos2 = [];
    this.datosTabla = [];
    this.datos4 = [];
    this.datosGrafico = [];
    var objTransaccionesOffline = this.base.darTransacciones(this.parametros.cuenta);
    if (objTransaccionesOffline) {
      Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(objTransaccionesOffline, this.idiomas)); //Para evitar errores de Change After Check
      Promise.resolve(null).then(() => this.datos2.push(objTransaccionesOffline.data));
      Promise.resolve(null).then(() => this.datos4 = objTransaccionesOffline.data.slice(0));
      Promise.resolve(null).then(() => this.datos4[this.datos4.length] = {});
      Promise.resolve(null).then(() => this.datosTabla.push(this.datos4));
      Promise.resolve(null).then(() => {
        for (let registro of this.datos2[0]) {
          if (registro.estado == "CANCELADO" || registro.estado == "APROBADO")
            this.datosGrafico.push(registro);
        }
        if (this.datosGrafico.length == 0) {
          this.datosGrafico.push(this.datos2[0][0]);
        }
      });
      Promise.resolve(null).then(() => this.bandData0506 = true);
    }
    else {
      Promise.resolve(null).then(() => this.datosBand = false);
      Promise.resolve(null).then(() => this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta));
    }
    document.getElementById("loadData_05_06").style.visibility = 'hidden';

    //Para Pago de Prestamos
    let loanFee = (Number(this.data[0]['saldointeres']) + Number(this.data[0]['cuentasPorCobrar']) + Number(this.data[0]['saldomoraSigCuota']) + Number(this.data[0]['valorpagar'])).toString()
    this.loanPaymentInfo = {
      account: this.data[0]['ccuenta'],
      accountMask: this.data[0]['ccuentaetiqueta'],
      prodAccount: '5',
      monedaAccount: this.data[0]['cmoneda'],
      feeLoan: this.validators.FormatNumber(loanFee).toString(),
      actualBal: this.data[0]['actualBal']
    }
  }

  //Saldos Bloqueados - Por Confirmar
  consultarBloqueos() {
    let pedido = { 'ccuenta': this.parametros.cuenta }
    this.api.postProvider2InternetCheck('/saldosbloqueados', this.base.id_token, pedido).then((data: any) => {
      this.dataSourceBlo = data;
      document.getElementById("loadData_05_06").style.visibility = 'hidden'
      if (this.dataSourceBlo.length > 0) {
        Promise.resolve(null).then(() => this.bandBloqueos = true);
        //Almacenar consulta en BDD
        let consulta = {
          'subsistema': this.parametros.subsistema,
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.dataSourceBlo,
          'cuenta': this.parametros.cuenta
        }
        this.base.insertarSaldosBloqueados(consulta);
        //
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.consultarBloqueosOffline();
      }
    })
  }

  consultarBloqueosOffline() {
    var objSalBloqueadosOffline = this.base.darSaldosBloqueados(this.parametros.cuenta);
    if (objSalBloqueadosOffline) {
      Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(objSalBloqueadosOffline, this.idiomas)); //Para evitar errores de Change After Check
      Promise.resolve(null).then(() => this.dataSourceBlo = objSalBloqueadosOffline.data);
      if (objSalBloqueadosOffline.data.length > 0) {
        Promise.resolve(null).then(() => this.bandBloqueos = true);
      }
    }
    else {
      Promise.resolve(null).then(() => this.bandBloqueos = false);
      Promise.resolve(null).then(() => this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta));
    }
    document.getElementById("loadData_05_06").style.visibility = 'hidden';
  }

  consultarConfirmar() {
    let pedido = { 'ccuenta': this.parametros.cuenta }
    this.api.postProvider2InternetCheck('/saldoscheques', this.base.id_token, pedido).then((data: any) => {
      this.dataSourceCheLoc = data.locales;
      this.dataSourceCheOts = data.otros;
      if (this.dataSourceCheLoc.length > 0) {
        Promise.resolve(null).then(() => this.bandPorConf = true);
      }
      if (this.dataSourceCheOts.length > 0) {
        Promise.resolve(null).then(() => this.bandPorConf2 = true);
      }
      document.getElementById("loadData_05_06").style.visibility = 'hidden';

      //Almacenar consulta en BDD
      let aux = { locales: this.dataSourceCheLoc, otros: this.dataSourceCheOts }
      let consulta = {
        'subsistema': this.parametros.subsistema,
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': aux,
        'cuenta': this.parametros.cuenta
      }
      this.base.insertarSaldosPorConfirmar(consulta);
      //
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.consultarConfirmarOffline();
      }
    })
  }

  consultarConfirmarOffline() {
    var objSalPorConfirmarOffline = this.base.darSaldosPorConfirmar(this.parametros.cuenta);
    if (objSalPorConfirmarOffline) {
      let datos
      Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(objSalPorConfirmarOffline, this.idiomas)); //Para evitar errores de Change After Check
      Promise.resolve(null).then(() => this.dataSourceCheLoc = objSalPorConfirmarOffline.data.locales);
      Promise.resolve(null).then(() => this.dataSourceCheOts = objSalPorConfirmarOffline.data.otros);

      if (objSalPorConfirmarOffline.data.locales.length > 0) {
        Promise.resolve(null).then(() => this.bandPorConf = true);
      }
      if (objSalPorConfirmarOffline.data.otros.length > 0) {
        Promise.resolve(null).then(() => this.bandPorConf2 = true);
      }
    }
    else {
      Promise.resolve(null).then(() => this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta));
    }
    document.getElementById("loadData_05_06").style.visibility = 'hidden';
  }

  //Saldos Pignorados
  consultarPignorados() {
    let pedido = { 'ccuenta': this.parametros.cuenta }
    this.api.postProvider2InternetCheck('/saldospignorados', this.base.id_token, pedido).then((data: any) => {
      this.dataSourcePig = data;
      document.getElementById("loadData_05_06").style.visibility = 'hidden'
      if (this.dataSourcePig.length > 0) {
        Promise.resolve(null).then(() => this.bandPignorados = true);
        //Almacenar consulta en BDD
        let consulta = {
          'subsistema': this.parametros.subsistema,
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.dataSourcePig,
          'cuenta': this.parametros.cuenta
        }
        this.base.insertarSaldosPignorados(consulta);
        //
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.consultarPignoradosOffline();
      }
    })
  }

  consultarPignoradosOffline() {
    var objSalPignoradosOffline = this.base.darSaldosPignorados(this.parametros.cuenta);
    if (objSalPignoradosOffline) {
      Promise.resolve(null).then(() => this.alerta.generarOfflineDialogo(objSalPignoradosOffline, this.idiomas)); //Para evitar errores de Change After Check
      Promise.resolve(null).then(() => this.dataSourcePig = objSalPignoradosOffline.data);
      if (objSalPignoradosOffline.data.length > 0) {
        Promise.resolve(null).then(() => this.bandPignorados = true);
      }
    }
    else {
      Promise.resolve(null).then(() => this.bandPignorados = false);
      Promise.resolve(null).then(() => this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta));
    }
    document.getElementById("loadData_05_06").style.visibility = 'hidden';
  }

  scroll(id) {
    var container1 = document.getElementsByClassName('container2Aux')
    var tabla = document.getElementsByClassName(id)
    for (var i = 0; i < container1.length; i++) {
      if (container1.item(i).scrollLeft != 0) {
        if (container1[i].scrollLeft + 2 >= (tabla[0].clientWidth - container1[i].clientWidth)) {
          this.flechaR = true;
          this.flechaL = false;
        } else {
          this.flechaR = false;
          this.flechaL = true;
        }
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }

    }
  }

  scroll2(id, i) {
    let container1 = document.getElementsByClassName('container3Aux')
    var tabla = document.getElementsByClassName(id)
    if (i == 0) {
      if (container1.item(i).scrollLeft != 0) {
        if (container1[i].scrollLeft + 2 >= (tabla[0].clientWidth - container1[i].clientWidth)) {
          this.flechaR1 = true
          this.flechaL1 = false
        } else {
          this.flechaR1 = false
          this.flechaL1 = true
        }
      } else {
        this.flechaR1 = false
        this.flechaL1 = true
      }
    } else {
      if (container1.item(i).scrollLeft != 0) {
        if (container1[i].scrollLeft + 2 >= (tabla[1].clientWidth - container1[i].clientWidth)) {
          this.flechaR2 = true
          this.flechaL2 = false
        } else {
          this.flechaR2 = false
          this.flechaL2 = true
        }
      } else {
        this.flechaR2 = false
        this.flechaL2 = true
      }
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)

    let container1 = document.getElementsByClassName('container2Aux')
    if (direccion == 'right') {
      container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
    } else {
      container1.item(0).scrollLeft = 0
      this.flechaL = true
      this.flechaR = false
    }
  }

  scrollTo2(clase, direccion, i) {
    let tabla = document.getElementsByClassName(clase)
    let container1 = document.getElementsByClassName('container3Aux')
    if (i == 0) {
      if (direccion == 'right') {
        container1.item(i).scrollLeft = tabla[0].clientWidth - container1[i].clientWidth
      } else {
        container1.item(i).scrollLeft = 0
        this.flechaL1 = true
        this.flechaR1 = false
      }
    } else {
      if (direccion == 'right') {
        container1.item(i).scrollLeft = tabla[1].clientWidth - container1[i].clientWidth
      } else {
        container1.item(i).scrollLeft = 0
        this.flechaL2 = true
        this.flechaR2 = false
      }
    }
  }

  TableSize(grupo) {
    if (grupo == '04' || grupo == '33' || grupo == '77' || grupo == '99') {
      try {
        if (this.grande) {
          document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 1450px')
          document.getElementsByClassName('producto')[0].setAttribute('style', 'width: 90px')
          if (document.getElementsByClassName('ahorros')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 1270px')
          document.getElementsByClassName('producto')[0].setAttribute('style', 'width: 80px')
          if (document.getElementsByClassName('ahorros')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 1080px; font-size: 14px')
          document.getElementsByClassName('producto')[0].setAttribute('style', 'width: 80px')
          if (document.getElementsByClassName('ahorros')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('ahorros')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) { }
    }
    if (grupo == '05') {
      try {
        if (this.grande) {
          document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 1370px')
          if (document.getElementsByClassName('pfijo')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 1190px')
          if (document.getElementsByClassName('pfijo')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 1030px; font-size: 14px')
          if (document.getElementsByClassName('pfijo')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('pfijo')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) { }
    }
    if (grupo == '06') {
      try {
        if (this.grande) {
          document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 1470px')
          if (document.getElementsByClassName('prestamos')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 1270px')
          if (document.getElementsByClassName('prestamos')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 1080px; font-size: 14px')
          if (document.getElementsByClassName('prestamos')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestamos')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) { }
    }
    if (grupo == 'PRESTAMOPREAPROBADO') {
      try {
        if (this.grande) {
          //document.getElementById('btnCosultarTrans').style.fontSize = "25px"
          document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 630px')
          if (document.getElementsByClassName('prestpreap')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          //document.getElementById('btnCosultarTrans').style.fontSize = "20px"
          document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 580px')
          if (document.getElementsByClassName('prestpreap')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          //document.getElementById('btnCosultarTrans').style.fontSize = "inherit"
          document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 480px; font-size: 14px')
          if (document.getElementsByClassName('prestpreap')[0].clientWidth - document.getElementsByClassName('container1')[0].clientWidth <= 0) {
            document.getElementsByClassName('prestpreap')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) { }
    }
  }

  showHideRows(grupo, last, muestraGrafico) {
    if (grupo == '04') {
      if (last) {
        this.scrollToTab(last);
        try {
          this.DivHeight(grupo, muestraGrafico)
          let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('ahorrost')[0].setAttribute('style', 'width: 800px')
            if (800 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('ahorrost')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('ahorrost')[0].setAttribute('style', 'width: 680px')
            if (680 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('ahorrost')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.normal) {
            document.getElementsByClassName('ahorrost')[0].setAttribute('style', 'width: 600px; font-size: 14px')
            if (600 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('ahorrost')[0].setAttribute('style', 'width: 100%; font-size: 14px')
            }
          }
        } catch (e) { }
      }
    }
    if (grupo == '05') {
      if (last) {
        try {
          this.DivHeight(grupo, muestraGrafico)
          let classContainer2Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('pfijot')[0].setAttribute('style', 'width: 550px')
            if (550 - classContainer2Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('pfijot')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('pfijot')[0].setAttribute('style', 'width: 450px')
            if (450 - classContainer2Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('pfijot')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.normal) {
            document.getElementsByClassName('pfijot')[0].setAttribute('style', 'width: 328px; font-size: 14px')
            if (328 - classContainer2Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('pfijot')[0].setAttribute('style', 'width: 100%; font-size: 14px')
            }
          }
        } catch (e) { }
      }
    }
    if (grupo == '06') {
      if (last) {
        try {
          this.DivHeight(grupo, muestraGrafico)
          let classContainer3Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('prestamost')[0].setAttribute('style', 'width: 880px')
            if (880 - classContainer3Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('prestamost')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('prestamost')[0].setAttribute('style', 'width: 720px')
            if (720 - classContainer3Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('prestamost')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.normal) {
            document.getElementsByClassName('prestamost')[0].setAttribute('style', 'width: 700px; font-size: 14px')
            if (700 - classContainer3Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('prestamost')[0].setAttribute('style', 'width: 100%; font-size: 14px')
            }
          }
        } catch (e) { }
      }
    }
    if (grupo == '33') {
      if (last) {
        try {
          this.DivHeight(grupo, false)
          let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('bloqueos')[0].setAttribute('style', 'width: 700px')
            if (700 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('bloqueos')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('bloqueos')[0].setAttribute('style', 'width: 600px')
            if (600 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('bloqueos')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.normal) {
            document.getElementsByClassName('bloqueos')[0].setAttribute('style', 'width: 500px; font-size: 14px')
            if (500 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('bloqueos')[0].setAttribute('style', 'width: 100%; font-size: 14px')
            }
          }
        } catch (e) {
        }
      }
    }
    if (grupo == '99') {
      if (last) {
        try {
          this.DivHeight(grupo, false)
          let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('pignorados')[0].setAttribute('style', 'width: 675px')
            if (675 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('pignorados')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('pignorados')[0].setAttribute('style', 'width: 575px')
            if (575 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('pignorados')[0].setAttribute('style', 'width: 100%')
            }
          }
          if (this.normal) {
            document.getElementsByClassName('pignorados')[0].setAttribute('style', 'width: 475px; font-size: 14px')
            if (475 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('pignorados')[0].setAttribute('style', 'width: 100%; font-size: 14px')
            }
          }
        } catch (e) {
        }
      }
    }
  }

  DivHeight(grupo: any, muestraGrafico) {
    if (window.orientation != 90 && window.orientation != -90) {
      document.getElementById('transaccionesTab').setAttribute('style', 'padding-bottom: 0px !important')
      if (grupo == '05' || grupo == '06') {
        let heightTableAux = 240
        if ((this.datosTabla[0].length * document.getElementById('celda').clientHeight) < (window.innerHeight - (100 + heightTableAux + document.getElementsByClassName('cabecera')[0].clientHeight))) {
          document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
        } else {
          document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - (100 + heightTableAux + document.getElementsByClassName('cabecera')[0].clientHeight)) + 'px')
        }
      } else {
        if (grupo == '33') {
          if ((this.dataSourceBlo.length * document.getElementById('celda').clientHeight) < (window.innerHeight - (425 + document.getElementsByClassName('cabecera')[0].clientHeight))) {
            document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
          } else {
            document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - (425 + document.getElementsByClassName('cabecera')[0].clientHeight)) + 'px')
          }
        } else {
          if (grupo == '99') {
            if ((this.dataSourcePig.length * document.getElementById('celda').clientHeight) < (window.innerHeight - (425 + document.getElementsByClassName('cabecera')[0].clientHeight))) {
              document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
            } else {
              document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - (425 + document.getElementsByClassName('cabecera')[0].clientHeight)) + 'px')
            }
          } else {
            if ((this.datosTabla[0].length * document.getElementById('celda').clientHeight) < (window.innerHeight - (420 + document.getElementsByClassName('cabecera')[0].clientHeight))) {
              document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
            } else {
              document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - (420 + document.getElementsByClassName('cabecera')[0].clientHeight)) + 'px')
            }
          }
        }
      }
    } else {
      if (grupo == '33') {
        if ((this.dataSourceBlo.length * document.getElementById('celda').clientHeight) < (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 130)) {
          document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
        } else {
          document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 130) + 'px')
        }
      } else {
        if (grupo == '99') {
          if ((this.dataSourceBlo.length * document.getElementById('celda').clientHeight) < (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 130)) {
            document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
          } else {
            document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 130) + 'px')
          }
        } else {
          if ((this.datosTabla[0].length * document.getElementById('celda').clientHeight) < (window.innerHeight - document.getElementsByClassName('cabecera')[0].clientHeight - 130)) {
            document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height: auto')
          } else {
            document.getElementsByClassName('container2Aux')[0].setAttribute('style', 'height:' + (window.innerHeight -
              document.getElementsByClassName('cabecera')[0].clientHeight - 130) + 'px')
          }
        }
      }
    }
  }

  showHideRows2(grupo, last, i) {
    if (grupo == '77') {
      if (last) {
        try {
          let classContainer1Width = document.getElementsByClassName('container3Aux')[i - 1].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('saldoscheques')[i - 1].setAttribute('style', 'width: 1000px')
            if (1000 - classContainer1Width > 0) {
              document.getElementById('flechaR' + i).setAttribute('style', 'visibility: visible;')
              if (!this.flechaL1) {
                document.getElementById('flechaL' + i).setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR' + i).setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL' + i).setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('saldoscheques')[i - 1].setAttribute('style', 'width: 100%')
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('saldoscheques')[i - 1].setAttribute('style', 'width: 800px')
            if (800 - classContainer1Width > 0) {
              document.getElementById('flechaR' + i).setAttribute('style', 'visibility: visible;')
              if (!this.flechaL1) {
                document.getElementById('flechaL' + i).setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR' + i).setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL' + i).setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('saldoscheques')[i - 1].setAttribute('style', 'width: 100%')
            }
          }
          if (this.normal) {
            document.getElementsByClassName('saldoscheques')[i - 1].setAttribute('style', 'width: 600px; font-size: 14px')
            if (600 - classContainer1Width > 0) {
              document.getElementById('flechaR' + i).setAttribute('style', 'visibility: visible;')
              if (!this.flechaL1) {
                document.getElementById('flechaL' + i).setAttribute('style', 'visibility: visible;')
              }
            } else {
              document.getElementById('flechaR' + i).setAttribute('style', 'visibility: hidden;')
              document.getElementById('flechaL' + i).setAttribute('style', 'visibility: hidden;')
              document.getElementsByClassName('saldoscheques')[i - 1].setAttribute('style', 'width: 100%; font-size: 14px')
            }
          }
        } catch (e) {
        }
      }
    }
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  reportePDF() {
    //if (this.autoPeticion1) {
    //this.alerta.generarDialogoReporte("Reporte", this.idiomas);
    //}
    //else {
    let data = this.datosTabla[0]
    let saldo = 0
    if (data[data.length - 1].debitocredito == '+') {
      saldo = parseFloat(data[data.length - 1].saldodisponible) - parseFloat(data[data.length - 1].valormonedacuenta)
    } else {
      saldo = parseFloat(data[data.length - 1].saldodisponible) + parseFloat(data[data.length - 1].valormonedacuenta)
    }
    var sinicial = saldo.toFixed(2).toString();
    document.getElementById("loadData_04").style.visibility = 'visible'
    this.fdesde = new Date(this.dated);
    this.fhasta = new Date(this.dateh);
    this.fd = this.fdesde.getFullYear() + "-" + this.anadirCero((this.fdesde.getMonth() + 1)) + '-' + this.anadirCero(this.fdesde.getDate());
    this.fh = this.fhasta.getFullYear() + "-" + this.anadirCero((this.fhasta.getMonth() + 1)) + '-' + this.anadirCero(this.fhasta.getDate());
    let envio = {
      "nombre": "18_ESTADO_CUENTA_PDF",
      "subsistema": "18",
      "transaccion": "4001",
      "tipo": "PDF",
      "ctl^CUENTA": this.data[0].ccuenta,
      "rep^FDESDE_P": this.fd,
      "rep^FHASTA_P": this.fh,
      "rep^CCUENTA_P": this.data[0].ccuenta,
      "rep^SALDO_INICIAL_P": sinicial,
      "rep^CUSUARIO": "$userCode",
      "rep^CIA": "$company",
      "rep^FCONTABLE": "$accountingDate",
      "rep^CTRANSACCION": "4001",
      "rep^CSUBSISTEMA": "18",
      "rep^CPERSONA": "$personCode",
      "rep^MONEDA_P": this.data[0].cmoneda,
      "rep^SDISPONIBLE_P": this.data[0].available,
      "rep^SBLOQUEADO_P": this.data[0].blocked,
      "rep^Cpersona": "$personCode",
      "rep^Ffinal": this.fh,
      "rep^Finicial": this.fd
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        const linkSource = 'data:application/pdf;base64,' + this.pdf
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        document.getElementById("loadData_04").style.visibility = 'hidden'
      }
    }, (err) => {
      document.getElementById("loadData_04").style.visibility = 'hidden'
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
    //}
  }

  reporteXLS() {
    //if (this.autoPeticion1) {
    //this.alerta.generarDialogoReporte("Reporte", this.idiomas);
    //}
    //else {
    let data = this.datosTabla[0]
    let saldo = 0
    if (data[data.length - 1].debitocredito == '+') {
      saldo = parseFloat(data[data.length - 1].saldodisponible) - parseFloat(data[data.length - 1].valormonedacuenta)
    } else {
      saldo = parseFloat(data[data.length - 1].saldodisponible) + parseFloat(data[data.length - 1].valormonedacuenta)
    }
    var sinicial = saldo.toFixed(2).toString();
    document.getElementById("loadData_04").style.visibility = 'visible'
    this.fdesde = new Date(this.dated);
    this.fhasta = new Date(this.dateh);
    this.fd = this.fdesde.getFullYear() + "-" + this.anadirCero((this.fdesde.getMonth() + 1)) + '-' + this.anadirCero(this.fdesde.getDate());
    this.fh = this.fhasta.getFullYear() + "-" + this.anadirCero((this.fhasta.getMonth() + 1)) + '-' + this.anadirCero(this.fhasta.getDate());
    let envio = {
      "nombre": "18_ESTADO_CUENTA_XLS",
      "subsistema": "18",
      "transaccion": "4001",
      "tipo": "XLSX",
      "ctl^CUENTA": this.data[0].ccuenta,
      "rep^FDESDE_P": this.fd,
      "rep^FHASTA_P": this.fh,
      "rep^CCUENTA_P": this.data[0].ccuenta,
      "rep^SALDO_INICIAL_P": sinicial,
      "rep^CUSUARIO": "$userCode",
      "rep^CIA": "$company",
      "rep^FCONTABLE": "$accountingDate",
      "rep^CTRANSACCION": "4001",
      "rep^CSUBSISTEMA": "18",
      "rep^CPERSONA": "$personCode",
      "rep^MONEDA_P": this.data[0].cmoneda,
      "rep^SDISPONIBLE_P": this.data[0].available,
      "rep^SBLOQUEADO_P": this.data[0].blocked,
      "rep^Cpersona": "$personCode",
      "rep^Ffinal": this.fh,
      "rep^Finicial": this.fd
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.xls = data.reporte
        const linkSource = 'data:application/xlsx;base64,' + this.xls
        const downloadLink = document.createElement("a")
        const fileName = "reporte_transaccciones.xlsx"
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        document.getElementById("loadData_04").style.visibility = 'hidden'
      }
    }, (err) => {
      document.getElementById("loadData_04").style.visibility = 'hidden'
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
    //}
  }

  reporteDPF() {
    document.getElementById("loadData_05_06").style.visibility = 'visible';
    let envio = {
      "nombre": "05_CERTIFICADODEPOSITO",
      "subsistema": "18",
      "transaccion": "4001",
      "tipo": "PDF",
      "rep^CCUENTA": this.parametros.cuenta
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        const linkSource = 'data:application/pdf;base64,' + this.pdf
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        document.getElementById("loadData_05_06").style.visibility = 'hidden'
      }
    }, (err) => {
      document.getElementById("loadData_05_06").style.visibility = 'hidden'
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  //FUNCION PARA ABRIR EL DIALOGO DE CONSULTA DE LAS NOTAS ADICIONALES
  mostrarDialogo(data, producto) {
    this.alerta.generarDialogoDetalleCuentas(this.idiomas, data, producto, this.grande, this.mediano, this.normal, true).then((data) => {
      if (data) {
        this.mostrarTxDesdeTx(data[0], data[1], data[2])
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  scrollToTab(band) {
    if (band && this.paraScroll < 1) {
      scroll(0, this.topOfTable - document.getElementById("tool-bar").getBoundingClientRect().height);
      this.paraScroll = this.paraScroll + 1;
    }
  }

  className() {
    if (document.getElementsByClassName("cdk-overlay-pane").length == 2) {
      document.getElementsByClassName("cdk-overlay-pane")[1].setAttribute("style", "margin-top: 30px")
    } else {
      document.getElementsByClassName("cdk-overlay-pane")[0].setAttribute("style", "margin-top: 30px")
    }
  }

  clearScreenWithDialog() {
    this.alerta.generarDialogoSeguro(this.idiomas).then((data) => {
      if (data) {
        this.reset()
      }
    });
  }

  reset() {
    this.intento = false;
    this.bandmonto = false;
    this.tasaGenerada = 0;
    this.bandNoAcepta = false;
    Promise.resolve(null).then(() => this.cuotaAproximada.setValue('0.00'));
    Promise.resolve(null).then(() => this.numeroCuotas.setValue('0.00'));
    this.montoPrestamo.disable();
    this.numeroCuotas.disable();
  }


  mostrarTxDesdeTx(cuenta: any, subsistema: any, grupo: any) {
    if (subsistema == '04') {
      Promise.resolve(null).then(() => this.parametros.subsistema = '04');
      Promise.resolve(null).then(() => this.deshabilitarTransacciones = true);
      Promise.resolve(null).then(() => this.deshabilitarBloqueos = false);
      Promise.resolve(null).then(() => this.deshabilitarConfirmar = false);
      Promise.resolve(null).then(() => this.deshabilitarPignorados = false);
      Promise.resolve(null).then(() => this.autoPeticion = true);
      Promise.resolve(null).then(() => this.autoPeticion1 = true);
      Promise.resolve(null).then(() => this.paraScroll = 0);
      Promise.resolve(null).then(() => this.consultar04());
      this.intentoVPC = false
    }
    else {
      let parametrosTxDesdeTx = {
        'subsistema': this.parametros.subsistema,
        'cuenta': cuenta,
        'grupo': grupo,
        'idSaldo': subsistema
      }
      this.mostrarSaldos04.emit(parametrosTxDesdeTx)
      this.txDesdeTx = true
      this.intentoVPC = true
    }
  }

  comprobantePDF(i) {
    this.pdf = ""
    this.intentoCom = true
    this.fdesde = new Date(this.dated);
    this.fd = this.fdesde.getFullYear() + "-" + this.anadirCero((this.fdesde.getMonth() + 1)) + '-' + this.anadirCero(this.fdesde.getDate()) + ' 00:00:00';
    let data = this.datosTabla[0]
    let saldo = 0
    if (data[data.length - 1].debitocredito == '+') {
      saldo = parseFloat(data[data.length - 1].saldodisponible) - parseFloat(data[data.length - 1].valormonedacuenta)
    } else {
      saldo = parseFloat(data[data.length - 1].saldodisponible) + parseFloat(data[data.length - 1].valormonedacuenta)
    }
    var sinicial = saldo.toFixed(2).toString();
    var envio = {
      "nombre": "04_MOVIMIENTO",
      "subsistema": "04",
      "transaccion": "4004",
      "tipo": "PDF",
      "rep^NUMEROMENSAJE": data[i]['numeromensaje'],
      "rep^FDESDE_P": this.fd,
      "rep^CCUENTA_P": this.parametros.cuenta,
      "rep^SALDO_INICIAL_P": sinicial,
      "rep^CUSUARIO": "$userCode",
      "rep^CIA": "2",
      "rep^FCONTABLE": "$accountingDate",
      "rep^CTRANSACCION": "04",
      "rep^CSUBSISTEMA": "4004"
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intentoCom = false
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      this.intentoCom = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  comprobanteDPF() {
    let envio = {
      "ccuenta": this.parametros.cuenta == "501509000529" ? "4" : "W01-0001001"
    }
    this.api.postProvider2InternetCheck("/consultaPlazoReporte", this.base.id_token, envio).then((data: any) => {
      this.pdf = data.listDetailImage[0].imagen
      const linkSource = 'data:application/pdf;base64,' + this.pdf
      this.mostrarPdf.mostrarReportePDF(this.pdf)
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        document.getElementById("loadData_05_06").style.visibility = 'hidden'
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  compareJsonPerValue(object1: any, object2: any) {
    return object1 && object2 && ((object1.value == object2.value) || (object1.viewValue.slice(-4) == object2.toString().slice(-4)));
  }

  genrerarEstadoCuenta() {
    let data = this.datosTabla[0]
    let saldo = 0
    if (data[data.length - 1].debitocredito == '+') {
      saldo = parseFloat(data[data.length - 1].saldodisponible) - parseFloat(data[data.length - 1].valormonedacuenta)
    } else {
      saldo = parseFloat(data[data.length - 1].saldodisponible) + parseFloat(data[data.length - 1].valormonedacuenta)
    }
    var sinicial = saldo.toFixed(2).toString();
    this.fdesde = new Date(this.dated);
    this.fhasta = new Date(this.dateh);
    this.fd = this.fdesde.getFullYear() + "-" + this.anadirCero((this.fdesde.getMonth() + 1)) + '-' + this.anadirCero(this.fdesde.getDate());
    this.fh = this.fhasta.getFullYear() + "-" + this.anadirCero((this.fhasta.getMonth() + 1)) + '-' + this.anadirCero(this.fhasta.getDate());

    this.alerta.generarDialogoEstadoCuenta(this.idiomas, this.data[0].ccuenta, this.fd, this.fh, sinicial,
      this.data[0].cmoneda, this.data[0].available, this.data[0].blocked, this.grande, this.mediano, this.normal)
  }

  reportePDFPlazo() {
    if (this.autoPeticion1) {
      this.alerta.generarDialogoReporte("Reporte", this.idiomas);
    }
    else {
      this.intento = true;
      let envio = {
        "nombre": "18_TABLADPF",
        "rep^CCUENTA_INVERSION": this.parametros.cuenta
      }
      this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
        if (data) {
          this.pdf = data.reporte
          this.mostrarPdf.mostrarReportePDF(this.pdf)
          document.getElementById("loadData_05_06").style.visibility = 'hidden'
        }
        this.intento = false;
      }, (err) => {
        this.intento = false;
        if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
          document.getElementById("loadData_05_06").style.visibility = 'hidden'
          if (err.error) {
            if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          }
          else {
            this.alerta.presentarAlerta(this.idiomas.ServidorError)
          }
        }
        else { //Sin Internet
        }
      });
    }
  }

  /* Función que suma o resta días a una fecha, si el parámetro
días es negativo restará los días*/
  sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }
}


//Validacion de montos minimos de recarga
interface minAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null
}
function minAmountValidationNumber(minValue): minAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto >= minValue) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de monto
interface maxAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null
}
function maxAmountValidationNumber(maxValue): maxAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto <= maxValue) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos minimos de numero
interface minQuotaValidation {
  (control: UntypedFormControl): ValidationErrors | null
}
function minQuotaValidationNumber(minValue): minQuotaValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? monto : null;
    if (monto >= minValue) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de numero
interface maxQuotaValidation {
  (control: UntypedFormControl): ValidationErrors | null
}
function maxQuotaValidationNumber(maxValue): maxQuotaValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value
    monto = (monto != null) ? monto : null;
    if (monto <= maxValue) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

export interface Element {
  nro: string;
  fecha: Date;
  hora: string
  concepto: string;
  monto: number;
  vencimiento: string;
  detalle: string;
}