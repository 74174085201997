<div class="example-form" style="padding-top: 2% !important; padding-bottom: 30px;" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-card *ngIf="!mostrarMensajeNoData && !bandMobile" class="cards-transations">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.loanPayment | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding: 16px;">
            <div autocomplete="off" class="form-row" *ngIf="excuentas">
                <div class="col-12" style="min-width: 160px; margin-bottom: -15px !important;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.Cuenta}}</mat-label>
                        <mat-select [formControl]="cuenta" (selectionChange)="cambioCuenta()">
                            <mat-option *ngFor="let c of cuentasPres" [value]="c">
                                {{c.value}} - {{c.descProducto}}
                                <mat-divider style="border-top-width: 2px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuenta.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" style="width: 10px">
                    <button mat-raised-button [disabled]="(!cuenta.valid)" (click)="consultaCuotaPagar()"
                        color="primary" style="float: left;" class="buttonToken">
                        <mat-icon>search</mat-icon>
                        {{idiomas.Consultar}}
                    </button>
                </div>
            </div>
            <div *ngIf="consultado && excuentas" autocomplete="off" class="form-row" style="padding-top: 10px;">
                <div class="col-12" style="min-width: 225px">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.CuotaPrestamos}}</mat-label>
                        <input matInput autocomplete="off" [formControl]="cantidadForm" inputmode="tel" readonly>
                        <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-12" style="min-width: 160px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                        <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()">
                            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta">
                                {{cuenta.viewValue | cambiarVariableCuenta}} - {{cuenta.descProducto}}
                                <mat-divider style="border-top-width: 2px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div id="montoPagoPrestamo" [class.col-12]="bandMobile" class="col-4" style="min-width: 160px;">
                    <mat-form-field appearance="outline" floatLabel="always" fxFlexFill>
                        <mat-label>{{idiomas.MontoTransferencia}}</mat-label>
                        <div>
                            <input class="numeros" matInput type="text" autocomplete="off"
                                [formControl]="montoPagoPrestamo" name="montoPagoPrestamo" maxlength="10"
                                inputmode="decimal">
                        </div>
                        <span matPrefix>$&nbsp;</span>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('min')">
                            <strong>{{idiomas.montoDesde}} 0.01 - {{totalPrest}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('max')">
                            <strong>{{idiomas.montoDesde}} 0.01 - {{totalPrest}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-8" [class.col-12]="bandMobile" style="min-width: 160px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.Referencia}}</mat-label>
                        <div>
                            <input matInput type="text" autocomplete="off" style="text-transform: uppercase;"
                                [formControl]="referencia" name="referencia">
                        </div>
                        <mat-error *ngIf="referencia.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="referencia.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row" *ngIf="internet.internet && consultado && excuentas">
                <mat-form-field class="col-2 token" [class.col-12]="bandMobile" *ngIf="tokenSolicitado"
                    appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken" matInput type="text" autocomplete="off"
                        onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                        appBlockCopyPaste>
                    <mat-error *ngIf="token.hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="token.hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="token.hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="token.hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" [class.col-12]="bandMobile" *ngIf="!tokenSolicitado"
                    appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
                <div *ngIf="internet.internet" class="left col-2 token-button">
                    <button
                        [disabled]="(!cuentaDesdeSelected.valid) || (!montoPagoPrestamo.valid) || (!referencia.valid) || (enableCronoIn)"
                        mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
                <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
                    <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                        [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                </div>
            </div>
            <div style="padding: 16px;" *ngIf=intento>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="row" *ngIf="consultado && excuentas">
                <div id="floatCont" class="col-6">
                    <button mat-raised-button
                        [disabled]="(!(((tokenSolicitado && token.valid) || !internet.internet) && cuentaDesdeSelected.valid && montoPagoPrestamo.valid && referencia.valid)) || bandDisableForm"
                        (click)="payLoan()" title={{idiomas.Aceptar}} color="primary" class="buttonSubmit">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.sendPayment}}
                    </button>
                </div>
                <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                    <button mat-raised-button (click)="cancelPayLoan()" title={{idiomas.Limpiar}} color="warn"
                        class="buttonClean">
                        <mat-icon> clear_all</mat-icon>
                        {{idiomas.Limpiar}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card>
    <div *ngIf="!mostrarMensajeNoData && bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.loanPayment | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding: 16px;">
            <div autocomplete="off" class="form-row" *ngIf="excuentas">
                <div class="col-12" style="min-width: 160px; margin-bottom: -15px !important;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.Cuenta}}</mat-label>
                        <mat-select [formControl]="cuenta" (selectionChange)="cambioCuenta()">
                            <mat-option *ngFor="let c of cuentasPres" [value]="c">
                                {{c.value}} - {{c.descProducto}}
                                <mat-divider style="border-top-width: 2px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuenta.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" style="width: 10px">
                    <button mat-raised-button [disabled]="(!cuenta.valid)" (click)="consultaCuotaPagar()"
                        color="primary" style="float: left;" class="buttonToken">
                        <mat-icon>search</mat-icon>
                        {{idiomas.Consultar}}
                    </button>
                </div>
            </div>
            <div *ngIf="consultado && excuentas" autocomplete="off" class="form-row" style="padding-top: 10px;">
                <div class="col-12" style="min-width: 225px">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.CuotaPrestamos}}</mat-label>
                        <input matInput autocomplete="off" [formControl]="cantidadForm" inputmode="tel" readonly>
                        <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-12" style="min-width: 160px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                        <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()">
                            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta">
                                {{cuenta.viewValue | cambiarVariableCuenta}} - {{cuenta.descProducto}}
                                <mat-divider style="border-top-width: 2px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div id="montoPagoPrestamo" [class.col-12]="bandMobile" class="col-4" style="min-width: 160px;">
                    <mat-form-field appearance="outline" floatLabel="always" fxFlexFill>
                        <mat-label>{{idiomas.MontoTransferencia}}</mat-label>
                        <div>
                            <input class="numeros" matInput type="text" autocomplete="off"
                                [formControl]="montoPagoPrestamo" name="montoPagoPrestamo" maxlength="10"
                                inputmode="decimal">
                        </div>
                        <span matPrefix>$&nbsp;</span>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('min')">
                            <strong>{{idiomas.montoDesde}} 0.01 - {{totalPrest}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoPagoPrestamo.hasError('max')">
                            <strong>{{idiomas.montoDesde}} 0.01 - {{totalPrest}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-8" [class.col-12]="bandMobile" style="min-width: 160px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.Referencia}}</mat-label>
                        <div>
                            <input matInput type="text" autocomplete="off" style="text-transform: uppercase;"
                                [formControl]="referencia" name="referencia">
                        </div>
                        <mat-error *ngIf="referencia.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="referencia.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row" *ngIf="internet.internet && consultado && excuentas">
                <div *ngIf="internet.internet" class="left col-6 token-button" style="padding-bottom: 10px !important;">
                    <button style="margin-top: 0 !important;"
                        [disabled]="(!cuentaDesdeSelected.valid) || (!montoPagoPrestamo.valid) || (!referencia.valid) || (enableCronoIn)"
                        mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarToken}}
                    </button>
                </div>
                <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
                    <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                        [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                </div>
                <mat-form-field class="col-2 token" [class.col-12]="bandMobile" *ngIf="tokenSolicitado"
                    appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input id="soliToken" matInput type="text" autocomplete="off"
                        onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                        appBlockCopyPaste>
                    <mat-error *ngIf="token.hasError('required')">
                        <strong>{{idiomas.ValidarCampo}}</strong>
                    </mat-error>
                    <mat-error *ngIf="token.hasError('pattern')">
                        <strong>{{idiomas.msjSoloNumeros}}</strong>
                    </mat-error>
                    <mat-error *ngIf="token.hasError('minlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                    <mat-error *ngIf="token.hasError('maxlength')">
                        <strong>{{idiomas.msjOtpminmax}}</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2 token" [class.col-12]="bandMobile" *ngIf="!tokenSolicitado"
                    appearance="outline">
                    <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                    <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                </mat-form-field>
            </div>
            <div style="padding: 16px;" *ngIf=intento>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="row" *ngIf="consultado && excuentas">
                <div id="floatCont" class="col-6">
                    <button mat-raised-button
                        [disabled]="(!(((tokenSolicitado && token.valid) || !internet.internet) && cuentaDesdeSelected.valid && montoPagoPrestamo.valid && referencia.valid)) || bandDisableForm"
                        (click)="payLoan()" title={{idiomas.Aceptar}} color="primary" class="buttonSubmit">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.sendPayment}}
                    </button>
                </div>
                <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                    <button mat-raised-button (click)="cancelPayLoan()" title={{idiomas.Limpiar}} color="warn"
                        class="buttonClean">
                        <mat-icon> clear_all</mat-icon>
                        {{idiomas.Limpiar}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="mostrarMensajeNoData">
        <mat-card class="cards-transations">
            <div class="col card-header">
                <div class="center texto">
                    <h6><strong>{{idiomas.loanPayment | uppercase}}</strong></h6>
                </div>
            </div>
            <div class="center col texto" style="padding-top: 10px; padding-bottom: 10px;">
                <mat-label>
                    {{idiomas.NoPrestamosCuentas | uppercase}}
                </mat-label>
            </div>
        </mat-card>
    </div>
</div>