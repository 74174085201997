import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Idioma } from '../funciones/idioma';

import { UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-edge-detect',
  templateUrl: './edge-detect.component.html',
  styleUrls: ['./edge-detect.component.css']
})
export class EdgeDetectComponent implements OnInit {

  idiomas: any;
  
  constructor(private snackBarRef: MatSnackBarRef<EdgeDetectComponent>, private idm: Idioma) {
    this.idm.getIdioma();
    this.idiomas = JSON.parse(localStorage.getItem('idioma'));
  }

  ngOnInit() {
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
