import { Component, EventEmitter, HostListener, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { internetComponent } from '../funciones/internet';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControlValidators } from '../funciones/formcontrol';
import { Idioma } from '../funciones/idioma';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { DomSanitizer } from '@angular/platform-browser';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { CajerosMapaComponent } from '../cajeros-mapa/cajeros-mapa.component';
import { AgenciasMapaComponent } from '../agencias-mapa/agencias-mapa.component';
import { LoginComponent } from '../login/login.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatToolbarModule, MatButtonModule, CarouselModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, CajerosMapaComponent, AgenciasMapaComponent, LoginComponent],
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  @Output() evtRegresoDatos = new EventEmitter<any>();

  bandMobile: boolean;
  bandIos: boolean;
  bandiOSInstalled: boolean;
  bandAndroidInstalled: boolean;
  intento: boolean;
  information: boolean;
  error: boolean;
  bandusuario: boolean;
  bandUser: boolean;
  alertalogin: boolean;
  uservalid: boolean;
  userExist: boolean;
  bandsFuncion: any;
  bandsFuncionInicio: any;
  idiomas: any;
  msjinformation: string;
  msjerror: string;
  msjalert: string;
  temaSeleccionado: string;
  temaSeleccionadoPrev: string;
  temaSeleccionadoBand: number;
  logoTemaOscuro: boolean;
  newUsernameMigratedUser: boolean;
  verifyMesgInfo: boolean;
  bandStandalone: boolean;
  verifyContractIdType: string;
  verifyContractId: string;
  verifyContractCel: string;
  verifyContractEmail: string;
  verifyContract: boolean;
  isUserValid: boolean;
  reminderUser: boolean;
  isClientPrest: boolean = false;
  //Marketing Images Carousel
  marketingSlides: any[] = [];
  deferredPrompt: any;
  showButton: boolean = false;
  //ngx-carousel options
  customCarouselOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    rewind: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
  }

  //ngx-carousel options
  customCarouselMenu: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 9500,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
  }

  //ngx-carousel options
  customCarouselOptionsMHB: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 6500,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
  }

  constructor(public apiService: ApiService, public internetC: internetComponent, private router: Router, private validators: FormControlValidators,
    private idm: Idioma, private alerta: Alerta, private _DomSanitizer: DomSanitizer, private overlay: OverlayContainer,
    private routeParams: ActivatedRoute) {
    this.bandMobile = false;
    this.bandiOSInstalled = false;
    this.bandAndroidInstalled = false;
    this.showButton = false;
    this.bandIos = false;
    this.intento = false;
    this.information = false;
    this.alertalogin = false;
    this.error = false;
    this.bandusuario = false;
    this.bandUser = false;
    this.userExist = false;
    this.logoTemaOscuro = true;
    this.newUsernameMigratedUser = false;
    this.verifyMesgInfo = false;
    this.verifyContract = false;
    this.isUserValid = false;
    this.reminderUser = false;
    this.bandStandalone = true;
    this.verifyContractIdType = "";
    this.verifyContractId = "";
    this.verifyContractCel = "";
    this.verifyContractEmail = "";
    this.msjinformation = '';
    this.msjerror = '';
    this.msjalert = '';
    this.idm.getIdioma();
    if (localStorage) {
      this.idiomas = JSON.parse(localStorage.getItem('idioma'));
    } else {
      this.idiomas = "es"
    }
    this.bandsFuncion = {
      '0': true,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }
    this.bandsFuncionInicio = {
      'inicio': true,
      'login': false,
    };
    let temasDisponibles = ["night-theme", "light-theme", "light-theme-verde", "light-theme-azul"]
    if (localStorage) {
      this.temaSeleccionado = localStorage.getItem('themeDisplayed');
    } else {
      this.idiomas = "light-theme"
    }
    if (!this.temaSeleccionado) //Cuando no se encuentre tema en localStorage
    {
      for (let temeIndex = 0; temeIndex < temasDisponibles.length; temeIndex++) {
        if (this.overlay.getContainerElement().classList.contains(temasDisponibles[temeIndex])) {
          for (let indexClassList = 0; indexClassList < this.overlay.getContainerElement().classList.length; indexClassList++) {
            if (temasDisponibles[temeIndex] == this.overlay.getContainerElement().classList.item(indexClassList)) {
              this.temaSeleccionado = this.overlay.getContainerElement().classList.item(indexClassList)
              break
            }
          }
          localStorage.setItem('themeDisplayed', this.temaSeleccionado)
          break;
        }
      }
    }
    this.temaSeleccionadoPrev = this.temaSeleccionado;
    this.temaSeleccionadoBand = temasDisponibles.indexOf(this.temaSeleccionado)
    if (this.temaSeleccionado != "night-theme") {
      this.logoTemaOscuro = false;
    }
  }

  //Evento que verificar si ha sido intalado el app en android
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(event: any) {
    event.preventDefault();
    this.deferredPrompt = event
    localStorage.setItem("AppInstalled", "0");
  }

  ngOnInit(): void {
    this.routeParams.paramMap.subscribe(params => {
      this.verifyContractIdType = params.get('idType');
      this.verifyContractId = params.get('id');
      this.verifyContractCel = params.get('celular');
      this.verifyContractEmail = params.get('email');
      if (this.verifyContractIdType != null && this.verifyContractId != null) {
        this.verifyContract = true;
      }
      else {
        this.verifyContractIdType = "";
        this.verifyContractId = "";
        this.verifyContractCel = "";
        this.verifyContractEmail = "";
      }
    });
    /*if (!this.verifyContract) {
        setTimeout(function () { document.getElementById("userID").focus(); }, 1);
    }*/
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()

    //Verifica si el dispositivo que esta siendo utilizado es de apple
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /ipad|iphone|ipod/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandIos = isIos()
    this.bandsFuncionInicio = {
      'inicio': true,
      'login': false,
    };
  }

  ngAfterViewInit() {
    if (this.verifyContract) {
      let userverifyContractData = { verifyContract: true, tipoDocUsrMig: this.verifyContractIdType, idUsrMig: this.verifyContractId, UsrCel: this.verifyContractCel, UsrEmail: this.verifyContractEmail }
      Promise.resolve(null).then(() => this.newUser(userverifyContractData));
    }
    //Limpiar alertas
    this.alerta.clearAlerta();
    /*if (this.marketingSlides.length == 0) { //Si no se han consultado aun imagenes de marketing
      Promise.resolve(null).then(() => this.intento = true);
      Promise.resolve(null).then(() => this.obtenerImagenesMarketing());
    }*/

    //Verifica si esta utilizando el app despues de intalarlo como un app
    this.bandiOSInstalled = window.matchMedia('(display-mode: standalone)').matches === true;
    this.bandAndroidInstalled = window.matchMedia('(display-mode: standalone)').matches === true;

    //Funcionalidad para android detectar si esta instlado o no el app en el dispositivo mobile
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      this.deferredPrompt = event
      localStorage.setItem("AppInstalled", "0");
    });

    //Si se abre la aplicacion instalada se guardar una variable en 1 para saber q esta instalado
    if (this.bandMobile && this.bandAndroidInstalled) {
      localStorage.setItem("AppInstalled", "1");
    }
  }

  userForm = this.validators.userForm();
  idTipo = this.validators.comboForm();
  idCelular = this.validators.numberForm();
  idEmail = this.validators.emailForm();
  idForm = this.validators.idForm();
  userForm1 = this.validators.userForm();

  newUser(migratedUserData) {
    this.bandsFuncion = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false
    }
    this.bandsFuncion['4'] = true
    this.alertalogin = false
    this.error = false
    this.information = false
    if (migratedUserData != null) {
      if (("reminder" in migratedUserData) && migratedUserData['reminder'] == true) {
        this.reminderUser = true;
      }
      else {
        this.newUsernameMigratedUser = ("verifyContract" in migratedUserData) && migratedUserData['verifyContract'] == true ? false : true;
        Promise.resolve(null).then(() => this.setValuesOfNewUserForm(migratedUserData));
      }
    }
  }

  regresarInicio(evt) {
    this.bandsFuncionInicio['inicio'] = evt.intentoInicio
    this.bandsFuncionInicio['login'] = evt.intentoLogin
  }

  obtenerImagenesMarketing() {
    this.marketingSlides = []
    this.apiService.noTokenProviderInternetCheckImagenes({}, '/imagenesMarketing').then((data: any) => {
      if (data) {
        let imgMargetinkArray = data.tcps0;
        if (imgMargetinkArray != null && imgMargetinkArray.length > 0) {
          for (let imgInfo of imgMargetinkArray) {
            let imagen = imgInfo['timg0.IMAGEN'] != null && imgInfo['timg0.IMAGEN'] != "null" ? imgInfo['timg0.IMAGEN'] : "";
            let enlace = imgInfo['tcps0.VALORTEXTO'] != null && imgInfo['tcps0.VALORTEXTO'] != "null" ? imgInfo['tcps0.VALORTEXTO'] : "";
            this.marketingSlides.push({ src: imagen, href: enlace })
          }
        }
      }
      this.intento = false
    }, (err) => {
      this.intento = false
    });
  }

  returnInicio() {
    this.bandsFuncion = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }
    this.bandsFuncion['0'] = true

    this.bandsFuncionInicio = {
      'inicio': true,
      'login': false,
    };
  }

  setValuesOfNewUserForm(migratedUserData) {
    this.idTipo.setValue(migratedUserData.tipoDocUsrMig)
    this.idForm.setValue(migratedUserData.idUsrMig);
    this.idEmail.setValue(migratedUserData.UsrEmail);
    this.idCelular.setValue(migratedUserData.UsrCel);
    this.sendIdentification();
  }

  loginApp() {
    //Funcionalidad para android detectar si esta instlado o no el app en el dispositivo mobile
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      this.deferredPrompt = event
      localStorage.setItem("AppInstalled", "0");
    });

    //Funcionalidad para android detectar si esta instlado o no el app en el dispositivo mobile
    if (localStorage.getItem('AppInstalled') == "0") {
      this.alerta.generarDialogoAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'), this.deferredPrompt);
    }

    //Se verificar si esta instalado, si es mobile, si no se abrio desde la app y si tiene la varibale de appinstalado en 1 
    //y con esto no dejar que pueda usar el app en el safari del celular en iOS
    if (this.bandMobile && this.bandIos && !this.bandiOSInstalled) {
      this.alerta.generarDialogoAppleAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'));
    }

    //Se verificar si esta instalado, si es mobile, si no se abrio desde la app y si tiene la varibale de appinstalado en 1 
    //y con esto no dejar que pueda usar el app en el chrome del celular en android
    if (this.bandMobile && !this.bandAndroidInstalled && !this.bandIos && localStorage.getItem('AppInstalled') == "1") {
      this.alerta.generarDialogoAppleAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'));
    }

    // Se verifica que este intalado el app y si esta todo ok le deja hacer login sin problema
    if (this.bandMobile && (this.bandAndroidInstalled || this.bandiOSInstalled) && localStorage.getItem('AppInstalled') == "1") {
      this.bandsFuncionInicio = {
        'inicio': false,
        'login': true,
      };
    }
  }

  login() {
    this.bandsFuncionInicio = {
      'inicio': false,
      'login': true,
    };
  }

  loginCash() {
    window.open("https://aws-pwapp-cash.fit-bank.com/#/", "_blank")
  }

  return() {
    if (this.verifyContract) { //Si se envio identificacion despues de /verifyContract al regresar se vulve a /
      this.router.navigate(['']);
    }
    this.information = false
    this.alertalogin = false
    this.error = false
    this.bandUser = false
    this.userExist = false
    this.uservalid = false
    this.intento = false
    this.bandusuario = false
    this.reminderUser = false
    this.verifyContract = false
    this.newUsernameMigratedUser = false
    this.reminderUser = false
    this.idForm.reset()
    this.idTipo.reset()
    this.userForm.reset()
    this.userForm1.reset()
    this.idCelular.reset()
    this.idEmail.reset()
    this.buttonSelected(0)
    this.verifyContractIdType = "";
    this.verifyContractId = "";
  }

  buttonSelected(index) {
    this.bandsFuncion = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }

    this.bandsFuncion[index] = true;

    if (index == 1) {
      // Se verifica que este intalado el app y si esta todo ok le deja hacer login sin problema
      if (this.bandMobile) {
        this.generarDialogoInstalacion();
      } else if ((this.bandAndroidInstalled || this.bandiOSInstalled) && localStorage.getItem('AppInstalled') == "1") {
        this.isClientPrest = false;
      } else {
        this.isClientPrest = false;
      }
    }

    window.scroll(0, 0);
  }

  buttonSelectedPres(index) {
    this.bandsFuncion = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }

    this.bandsFuncion[index] = true;

    if (index == 1) {
      // Se verifica que este intalado el app y si esta todo ok le deja hacer login sin problema
      if (this.bandMobile) {
        this.generarDialogoInstalacion();
        this.isClientPrest = true;
      } else if ((this.bandAndroidInstalled || this.bandiOSInstalled) && localStorage.getItem('AppInstalled') == "1") {
        this.isClientPrest = true;
      } else {
        this.isClientPrest = true;
      }
    }

    window.scroll(0, 0);
  }

  generarDialogoInstalacion() {
    //Funcionalidad para android detectar si esta instlado o no el app en el dispositivo mobile
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      this.deferredPrompt = event
      localStorage.setItem("AppInstalled", "0");
    });

    //Funcionalidad para android detectar si esta instlado o no el app en el dispositivo mobile
    if (this.bandMobile && !this.bandIos && localStorage.getItem('AppInstalled') == "0") {
      this.alerta.generarDialogoAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'), this.deferredPrompt);
    }

    //Se verificar si esta instalado, si es mobile, si no se abrio desde la app y si tiene la varibale de appinstalado en 1 
    //y con esto no dejar que pueda usar el app en el safari del celular en iOS
    if (this.bandMobile && this.bandIos && !this.bandiOSInstalled) {
      this.alerta.generarDialogoAppleAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'));
    }

    //Se verificar si esta instalado, si es mobile, si no se abrio desde la app y si tiene la varibale de appinstalado en 1 
    //y con esto no dejar que pueda usar el app en el chrome del celular en android
    if (this.bandMobile && !this.bandAndroidInstalled && !this.bandIos && localStorage.getItem('AppInstalled') == "1") {
      this.alerta.generarDialogoAppleAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'));
    }
  }

  returnInit() {
    this.bandsFuncion = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false
    }
    this.bandsFuncion['0'] = true;
    this.newUsernameMigratedUser = false;
    this.verifyMesgInfo = false;
    this.verifyContract = false;
    this.isUserValid = false;
    this.reminderUser = false;
    this.information = false;
    this.alertalogin = false;
    this.error = false;
    this.verifyContractIdType = "";
    this.verifyContractId = "";
    this.verifyContractCel = "";
    this.verifyContractEmail = "";
    this.userForm.reset()
    this.idTipo.reset();
    this.idCelular.reset();
    this.idEmail.reset();
    this.idForm.reset();
    this.userForm1.reset();
  }

  openSimulators() {
    window.open("https://www.bancodelaustro.com/SimuladorCredito/index.html", "_blank")
  }

  changeTipoIdentification(valor) {
    if (valor == 'CED') {
      this.idForm = this.validators.idForm()
    } else if (valor == 'RUC') {
      this.idForm = this.validators.rucForm()
    } else {
      this.idForm = this.validators.pasForm()
    }
  }

  sendIdentification() {
    this.intento = true
    this.error = false
    let reqJSON = { id: this.idForm.value, tipoDoc: this.idTipo.value, email: this.idEmail.value, celular: this.idCelular.value, tipoUsuario: 'HBA' }
    if (this.reminderUser) {
      reqJSON['reminderUser'] = true
    }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/crearnuevousuario').then((data: any) => {
      if (data) {
        this.intento = false
        if (data.cod == 'HBR1-0') {
          this.bandUser = true
          this.userExist = true
          this.information = true
          this.msjinformation = data.mensaje + '<br>El usuario debe cumplir las siguiente características:<br>' +
            '*Mínimo 6 caracteres.<br>*Combinación de números y letras.'
        } else if (data.cod == 'HBR3-0') {
          this.alerta.generarDialogoSeguroContrato(this.idiomas, true, reqJSON).then((data) => {
            if (data) {
              this.sendIdentification()
            }
          })
        }
        else if (data.cod == 'HBR5-0') {//No existe persona con Id ingresada
          this.bandUser = false
          this.userExist = false
          this.error = true
          this.msjerror = data.mensaje
        } else {
          this.error = false
          this.bandUser = false
          this.userExist = false
          this.information = true
          if (data.mensaje) {
            this.msjinformation = data.mensaje
          } else {
            this.msjinformation = "ESTIMADO SOCIO, LE HEMOS ENVIADO UN E-MAIL CON LOS DATOS PARA SU INGRESO AL SISTEMA"
          }
          if (data.cod == 'HBR4-0') {
            this.verifyContract = true;
          }
        }
      }
    }, (err) => {
      this.intento = false
      this.bandUser = false
      this.userExist = true
      this.error = true
      this.msjerror = err.error.mensajeUsuario
    })
  }

  enterNewPassword() {
    if (this.userForm.valid) {
      document.getElementById("userID").blur();
      this.sendUserNewPass();
    }
  }

  sendUser() {
    this.intento = true
    let reqJSON = { id: this.idForm.value, tipoDoc: this.idTipo.value, email: this.idEmail.value, celular: this.idCelular.value, usuarioHB: this.userForm1.value, tipoUsuario: 'HBA' }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/enviarnuevousuario').then((data: any) => {
      if (data) {
        this.intento = false
        if (data.cod == 'HBR2-0') {
          this.information = false
          this.alertalogin = true
          this.userForm1.setValue('')
          this.msjalert = data.mensaje
          this.uservalid = false
        }
      }
    }, (err) => {
      this.alertalogin = false
      this.intento = false
      this.information = true;
      this.msjinformation = err.error.mensajeUsuario
      this.uservalid = true
    })
  }

  sendUserNewPass() {
    this.intento = true
    let reqJSON = { usuarioHB: this.userForm.value }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/claveolvidada').then((data: any) => {
      if (data) {
        this.information = true
        this.msjinformation = data.message
      }
      this.intento = false
      this.error = false
      this.bandusuario = true
    }, (err) => {
      this.bandusuario = false
      this.intento = false
      this.error = true
      this.msjerror = err.error.mensajeUsuario
    })
  }
}

