import { Component, OnInit, Input, AfterViewInit, DoCheck, Output, EventEmitter } from '@angular/core';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { internetComponent } from '../funciones/internet';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, plazoMeses } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { TransaccionesPreaprobadoComponent } from '../transacciones-preaprobado/transacciones-preaprobado.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatProgressBarModule, MatTableModule, MatButtonModule, CarouselModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, plazoMeses, FlexLayoutModule, TransaccionesPreaprobadoComponent],
  selector: 'app-generar-carta-preaprobado',
  templateUrl: './generar-carta-preaprobado.component.html',
  styleUrls: ['./generar-carta-preaprobado.component.css']
})
export class GenerarCartaPreaprobadoComponent implements OnInit {

  @Input() idiomas: any;
  @Input() datos;
  @Input() datos2;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Output() updatePosCons: EventEmitter<any> = new EventEmitter<any>();

  datos3: any[];
  parametros: any;
  deshabilitar: boolean;
  flechaR: boolean;
  flechaL: boolean;
  deshabilitarTransacciones: boolean;
  offlineData: boolean;
  txDesdeTx: boolean;
  numMaxRow: number;
  noMuestraGrafico: boolean;
  displayedColumnsPrestamosPreaprobados = ['Producto', 'Moneda', 'Montomin', 'Montomax', 'Plazomin', 'Plazomax', 'Tasa', 'Fvigencia','Rechazar','Estado'];
  retonarPosicion: string = "retorna";
  nombre: string;
  intento: boolean;
  bandCheck: boolean;
  mostrarMensaje: boolean;
  mostrarInfo: boolean;
  bandMobile: boolean;

    //ngx-carousel options
    customCarouselOptions: OwlOptions = {
      loop: false,
      autoplay: false,
      autoplayTimeout: 12000,
      autoplayHoverPause: true,
      autoplaySpeed: 1000,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 900,
      navText: ['&#8249', '&#8250;'],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
      nav: true,
    }

  constructor(private servicio: Servicios, private base: BaseDatos, public apiService: ApiService,
    public alerta: Alerta, public internet: internetComponent) {
    this.flechaR = false;
    this.flechaL = false;
    this.noMuestraGrafico = false;
    this.txDesdeTx = false;
    this.numMaxRow = 10;
    this.intento = false;
    this.deshabilitar = false;
    this.bandCheck = true;
    this.mostrarMensaje = false;
    this.mostrarInfo = false;
  }

  ngOnInit() {
    this.deshabilitar = false;
    this.deshabilitarTransacciones = false;
    this.parametros = null;
    this.posicionConsolidada();
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  posicionConsolidada() {
    this.intento = true;
    this.deshabilitar = false;
    this.mostrarMensaje = false;
    this.mostrarInfo = false;
    let envio = {}
    this.apiService.postProvider2InternetCheck('/cuentasconsolidadas', this.base.id_token, envio).then((data: any) => {
      this.nombre = data.clientName
      this.datos = data.array
      if (this.datos != null) {
        this.flechaR = false;
        this.flechaL = true;
        this.datos2 = [];
        if (data.prestamos.length > 0)  //Para agregar informacion en caso de tener prestamos pre aprobados.
        {
          this.mostrarInfo = true;
          this.datos2.push(data.prestamos)
          let consulta = {
            'fecha': new Date().toLocaleDateString(),
            'hora': new Date().toLocaleTimeString(),
            'data': this.datos2,
          }
          this.base.insertarPosicionConsolidadaPreaprobado(consulta);

          if (this.internet.internet) {
            let fechaPreaprobados = {
              'fecha': new Date().toLocaleDateString(),
              'hora': new Date().toLocaleTimeString()
            }
            this.base.insertarConsulta('prestPreapob', fechaPreaprobados);
          }
        } else {
          this.mostrarMensaje = true;
        }
        Promise.resolve(null).then(() => this.intento = false);
        this.intento = false;
      }
      this.bandCheck = false;
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.intento = false;
        this.datos = null;
        this.nombre = null;
        this.alerta.presentarAlerta(this.idiomas.AlertaID);
        this.deshabilitar = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.intento = false;
      }
    })
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }


  groupBy2(arrayNF, f) {
    var array = arrayNF;
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }


  showHideRows(last) {
    if (last) {
      try {
        //this.funcionesTabla.DivHeight(this.datos)
        let classcontainer2Width = document.getElementsByClassName('container1Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 650px')
          if (650 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 600px')
          if (600 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 550px; font-size: 14px')
          if (550 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('preaprobados')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }


  scroll(id) {
    let container1 = document.getElementsByClassName('container1Aux')
    var tabla = document.getElementsByClassName(id)
    for (var i = 0; i < container1.length; i++) {
      if (container1.item(i).scrollLeft != 0) {
        if (id == 'preaprobados') {
          if (container1[i].scrollLeft + 2 >= (tabla[0].clientWidth - container1[i].clientWidth)) {
            this.flechaR = true;
            this.flechaL = false;
          } else {
            this.flechaR = false;
            this.flechaL = true;
          }
        }
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    if (clase == 'preaprobados') {
      let container1 = document.getElementsByClassName('container1Aux')
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
      } else {
        container1.item(0).scrollLeft = 0
        this.flechaL = true
        this.flechaR = false
      }
    }

  }

  mostrarTransacciones(cuenta: any, subsistema: any, grupo: any) {
    this.mostrarInfo = false;
    this.deshabilitar = true;
    this.deshabilitarTransacciones = true;
    this.parametros = {
      'subsistema': subsistema,
      'cuenta': cuenta,
      'grupo': grupo
    }
    this.datos3 = [];
    for (let grupos of this.datos2) {
      for (let grupo of grupos) {
        if (grupo.spersonacreditospreaprobados == cuenta) {
          if (grupo.subsistema == 'PRESTAMOPREAPROBADO') {
            this.datos3[0] = {
              'cuenta': grupo.cuenta,
              'subsistemaproducto': grupo.subsistemaproducto,
              'cgrupoproducto': grupo.cgrupoproducto,
              'cproducto': grupo.cproducto,
              'cmoneda': grupo.cmoneda,
              'plazodesde': grupo.plazodesde,
              'plazohasta': grupo.plazohasta,
              'montodesde': grupo.montodesde,
              'montohasta': grupo.montohasta,
              'monto': grupo.monto,
              'plazo': grupo.plazo,
              'tasa': grupo.tasa,
              'estado': grupo.estado,
              'spersonacreditospreaprobados': grupo.spersonacreditospreaprobados,
              'fcontable': grupo.fcontable,
              'fvigencia': grupo.fvigencia
            }
            break;
          }
        }
      }
    }
  }

  retornar() {
    document.querySelector('.mat-sidenav-content').scrollTop = 0;
    this.noMuestraGrafico = true;
    this.deshabilitar = false;
    this.parametros = null;
    if (this.datos2) {
      for (var i = 0; i < this.datos2.length; i++) {
        this.flechaR = false;
        this.flechaL = true;
      }
    }
    this.txDesdeTx = false;
    this.updatePosCons.emit();
    this.posicionConsolidada();
  }
}