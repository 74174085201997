import { ChangeDetectorRef, HostListener, Component, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { Servicios } from '../funciones/encryptar';
import { Alerta } from '../funciones/alerta';
import { Idioma } from '../funciones/idioma'
import { BaseDatos } from '../funciones/basededatos';
import { DateAdapter } from '@angular/material/core';
import { internetComponent } from '../funciones/internet'
import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatButtonModule } from '@angular/material/button';
import { ProveedoresComponent } from '../proveedores/proveedores.component';
import { ConsultaCvRealizadaComponent } from '../consulta-cv-realizada/consulta-cv-realizada.component';
import { TrasaccionesOfflineComponent } from '../trasacciones-offline/trasacciones-offline.component';
import { PanelProveedoresComponent } from '../panel-proveedores/panel-proveedores.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatTabsModule, MatMenuModule, MatBadgeModule, MatToolbarModule, MatSidenavModule, MatDividerModule, MatExpansionModule, MatListModule, MatDatepickerModule, MatIconModule, MatIconModule, MatButtonModule, CarouselModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, ProveedoresComponent, ConsultaCvRealizadaComponent, PanelProveedoresComponent, TrasaccionesOfflineComponent],
  selector: 'app-home-ecommerce',
  templateUrl: './home-ecommerce.component.html',
  styleUrls: ['./home-ecommerce.component.css']
})
export class HomeEcommerceComponent implements OnDestroy {

  @ViewChild('modCompras', { static: false }) modCompras;
  @ViewChild('modAdminProducts', { static: false }) modAdminProducts;
  @ViewChild('modVentas', { static: false }) modVentas;
  @ViewChild('tiendas', { static: false }) tiendas;
  @ViewChild('numTareas', { static: false }) numTareas;
  @ViewChild('chatDrag', { static: false }) chatBot;
  @ViewChild('snav', { static: false }) snav: any;

  eventText: string;
  idiomas: any;
  mobileQuery: MediaQueryList;
  minDate: Date;
  maxDate: Date;
  selectedIndex: number;
  panBand: boolean;
  temaSeleccionado: string;
  temaSeleccionadoPrev: string;
  temaSeleccionadoBand: number;
  locale: string;
  selectedIndex1: number;
  alertaBool: boolean;
  chatBand: boolean;
  chatBandResize: boolean;
  grande: boolean;
  mediano: boolean;
  normal: boolean;
  cont: number;
  tabsCounter: number;
  sideNavCounter: number;
  numTransaccionesPendientes: number;
  numTransaccionesPendientesPrev: number;
  numNoSelectedTabs: number;
  arrayPositionsTabs: any[];
  selectedTabPosition: any;
  tamMatTabLIst: number;
  tamMatTabLabelContainer: number;
  bandScrollMoveTabs: boolean;
  referenceWidth: number;
  snavWidthChangeband: boolean;
  loginInfo: any;
  logoTemaOscuro: boolean;
  tabName: string;
  idCompra: any;
  idVenta: any;

  private _mobileQueryListener: () => void;
  @HostListener('window:popstate', ['$event'])
  async onPopState($event) {
    this.alerta.cancelar()
    if (!this.alertaBool) { this.alerta.presentarAlerta(this.idiomas.PresionaSalir) }
    this.alertaBool = true;
    await setTimeout(() => {
      history.pushState(null, null, document.URL);
      this.alertaBool = false
    }, 4000);
  }

  constructor(public base: BaseDatos, public internetC: internetComponent, private idm: Idioma, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public apiService: ApiService, public servicios: Servicios,
    private router: Router, private alerta: Alerta, private overlay: OverlayContainer, private dateAdapter: DateAdapter<any>, private rutaActiva: ActivatedRoute) {
    this.eventText = '';
    this.minDate = new Date(2000, 0, 1);
    this.maxDate = new Date(2020, 0, 1);
    this.selectedIndex = 1;
    this.selectedIndex1 = 1;
    this.panBand = false;
    this.alertaBool = false;
    this.grande = false;
    this.mediano = false;
    this.chatBand = true;
    this.idCompra = 0;
    this.idVenta = 1;
    this.chatBandResize = false;
    this.normal = false;
    this.snavWidthChangeband = false;
    this.cont = 0;
    this.numTransaccionesPendientes = 0;
    this.numTransaccionesPendientesPrev = 0;
    this.tabsCounter = 0
    this.referenceWidth = 650; //Width para cambio de letra y tipo de menu lateral
    this.numNoSelectedTabs = 0;
    this.arrayPositionsTabs = [];
    this.selectedTabPosition = 1;
    this.tamMatTabLIst = 0;
    this.tamMatTabLabelContainer = 0;
    this.loginInfo = { nombreUsuario: "", lastLogin: "" }
    this.sideNavCounter = 0;
    this.logoTemaOscuro = true;
    this.bandScrollMoveTabs = false; //Variable que controla el desplazamiento de tabs
    let temasDisponibles = ["night-theme", "light-theme", "light-theme-verde", "light-theme-azul"]
    this.temaSeleccionado = localStorage.getItem('themeDisplayed');
    if (!this.temaSeleccionado) //Cuando no se encuentre tema en localStorage
    {
      for (let temeIndex = 0; temeIndex < temasDisponibles.length; temeIndex++) {
        if (this.overlay.getContainerElement().classList.contains(temasDisponibles[temeIndex])) {
          for (let indexClassList = 0; indexClassList < this.overlay.getContainerElement().classList.length; indexClassList++) {
            if (temasDisponibles[temeIndex] == this.overlay.getContainerElement().classList.item(indexClassList)) {
              this.temaSeleccionado = this.overlay.getContainerElement().classList.item(indexClassList)
              break
            }
          }
          localStorage.setItem('themeDisplayed', this.temaSeleccionado)
          break;
        }
      }
    }

    //Se verifica si existe fontSize almacenado
    let fontSize = localStorage.getItem('fontSize');
    if (fontSize != null) {
      switch (fontSize) {
        case 'large':
          this.grande = true;
          break;
        case 'medium':
          this.mediano = true;
          break;
        case 'small':
          this.normal = true;
          break;
        default:
          this.normal = true;
          break;
      }
    } else {
      this.normal = true;
    }

    this.temaSeleccionadoPrev = this.temaSeleccionado;
    this.temaSeleccionadoBand = temasDisponibles.indexOf(this.temaSeleccionado)
    if (this.temaSeleccionado != "night-theme") {
      this.logoTemaOscuro = false;
    }
    //Obtener datos de usuario logeado de BD
    let loginInfoAux = this.base.retornarConsulta("loginInfo")
    if (loginInfoAux) {
      if (loginInfoAux.nombreUsuario && loginInfoAux.nombreUsuario != "null")
        this.loginInfo.nombreUsuario = loginInfoAux.nombreUsuario
      if (loginInfoAux.lastLogin && loginInfoAux.lastLogin != "null")
        this.loginInfo.lastLogin = loginInfoAux.lastLogin
    }
    //  
    this.locale = localStorage.getItem('locale');
    if (this.servicios.desencriptar(localStorage.getItem('ingresado')) != 'ingresado') {
      this.router.navigate([''])
    }
    history.pushState(null, null, document.URL)
    this.mobileQuery = media.matchMedia('(max-width: 2000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.idiomas = JSON.parse(localStorage.getItem('idioma'))
    this.dateAdapter.setLocale(this.locale) //Para setear idioma de calendarios
    this.tabName = this.rutaActiva.snapshot.params.tabName;
  }

  @HostListener('window:resize', ['$event']) //Evento que permite determinar cuando se redimenciona pantalla
  onResize(event) {
    let autoResizeFonts = !this.snavWidthChangeband;
    this.toolbarWidth();
    this.getTabsXposition(autoResizeFonts);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  //Representa document.ready para marcar primera pestana seleccionada
  ngAfterViewInit() {
    this.selectedIndex = 0
    this.sideNavCounter = document.getElementById('matNavListEcomm').childElementCount; //Cantidad de items en sidenav
    this.tabsCounter = (document.getElementsByClassName('mat-tab-label').length / 2) - 1; //Cantidad de Tabs Mostradas
    this.selectedTabPosition = 0;
    this.selectedSNav(false);
    document.getElementById("matTabHeaderHideEcom").firstElementChild.firstElementChild.parentElement.style.display = 'none';//Oculta segunda barra de tab labels    
    this.getTabsXposition(true);
    if (this.isLargeScreenForSideNav())
      Promise.resolve(null).then(() => this.abrirMenu(true));
  }

  calculateoverflow(event) {
    let ChatBot = document.getElementById('ChatBotMenu')
    let left = ChatBot.getBoundingClientRect().left
    let right = ChatBot.getBoundingClientRect().right
    let top = ChatBot.getBoundingClientRect().top
    let bottom = ChatBot.getBoundingClientRect().bottom
    const source: any = event.source
    if (this.chatBandResize) {
      source._dragRef._passiveTransform = { x: 0, y: 0 }
      this.chatBandResize = false;
    }
    if (left < 0) {
      event.source.element.nativeElement.style.transform = 'translate3d(-' + (window.innerWidth - (ChatBot.clientWidth)) + 'px,' + source._dragRef._activeTransform.y + 'px,0px)'
    }
    if (top < 0) {
      event.source.element.nativeElement.style.transform = 'translate3d(' + source._dragRef._activeTransform.x + 'px, -58px,0px)'
      if (left < 0)
        event.source.element.nativeElement.style.transform = 'translate3d(-' + (window.innerWidth - (ChatBot.clientWidth)) + 'px, -58px,0px)'
    }
    if (bottom > window.innerHeight) {
      event.source.element.nativeElement.style.transform = 'translate3d(' + source._dragRef._activeTransform.x + 'px, ' + ((window.innerHeight - (ChatBot.clientHeight + 58))) + 'px,0px)'
      if (left <= 0)
        event.source.element.nativeElement.style.transform = 'translate3d(-' + (window.innerWidth - (ChatBot.clientWidth)) + 'px, ' + ((window.innerHeight - (ChatBot.clientHeight + 58))) + 'px,0px)'
    }
    if (right > window.innerWidth) {
      event.source.element.nativeElement.style.transform = 'translate3d(0px, ' + source._dragRef._activeTransform.y + 'px,0px)'
      if (top < 0)
        event.source.element.nativeElement.style.transform = 'translate3d(0px, -58px,0px)'
      if (bottom > window.innerHeight)
        event.source.element.nativeElement.style.transform = 'translate3d(0px, ' + ((window.innerHeight - (ChatBot.clientHeight + 58))) + 'px,0px)'
    }
  }

  getTabsXposition(autoResizeFonts) {

    //Para Cambi de Width en ToolBar cuando sidenav en modo over
    if ((window.innerWidth <= this.referenceWidth) && (document.getElementById('tool-bar').style.width != '100%')) {
      document.getElementById('tool-bar').style.width = '100%'
    }

    //Para desplazamiento de tabs
    this.arrayPositionsTabs = [];
    this.numNoSelectedTabs = document.getElementsByClassName('noSelectedTab').length;
    this.arrayPositionsTabs.push(document.getElementsByClassName('selectedTab')[0].getBoundingClientRect());
    for (let i = 0; i < this.numNoSelectedTabs; i++) {
      this.arrayPositionsTabs.push(document.getElementsByClassName('noSelectedTab')[i].getBoundingClientRect());
    }
    this.arrayPositionsTabs.sort((a, b) => a.x - b.x); //Ordena arreglo de menor a mayor
    this.tamMatTabLIst = document.getElementsByClassName('mat-tab-list')[0].getBoundingClientRect().width; //Tamaño de la lista de tabs
    this.tamMatTabLabelContainer = document.getElementsByClassName('mat-tab-label-container')[0].getBoundingClientRect().width; //Tamaño de contenedor de pestañas
    //.
    if (autoResizeFonts && (localStorage.getItem('fontSize') == null)) {
      if (window.innerWidth < (this.referenceWidth + 350)) { //Tamaño de Fonts - Tentativo
        if (!this.normal)
          this.Normal(true);
      }
      else {
        if (!this.mediano)
          this.Mediano(true);
      }
    }
  }

  logout() {
    this.alerta.clearAlerta();
    this.alerta.generarDialogoLogin(0, true, this.idiomas, this.base.id_token);
  }

  move(num) {
    this.selectedIndex = num;
    this.selectedIndex1 = num;
    this.alerta.clearAlerta();
    if (!this.isLargeScreenForSideNav()) {
      this.snav.close()
    }
    this.returnMainOfTab();
  }

  selectedIndexChange(val) {
    this.selectedIndex = val;
    this.panBand = false;
    //Algoritmo para ejecutar consultas de componentes unicamente cuando estos son mostrados
    switch (this.selectedIndex) {
      case 0:
        {
          this.closeEComm()
          break
        }
      case 1:
        {
          if (this.tabName == 'Administrador')
            this.modAdminProducts.refrescartabla()
          break
        }
      case 2:
        {
          if (this.tabName == 'Socio') {
            this.modCompras.consultaMovimientos()
            this.modCompras.refrescartabla(0)
          }
          if (this.tabName == 'Administrador') {
            this.modVentas.consultaMovimientos()
            this.modVentas.refrescartabla(0)
          }
          break
        }
    }
    /*switch (this.selectedIndex) {
      case 0:
        {
          this.closeEComm()
          break
        }
      case 2:
        {
          this.modVentas.consultaMovimientos()
          break
        }
    }*/
  }

  selectedIndexChange1(val) {
    this.selectedIndex1 = val;
    if ((this.bandScrollMoveTabs) && (this.tamMatTabLabelContainer < this.tamMatTabLIst)) {
      this.moveSpecificTabList();
    }
    this.bandScrollMoveTabs = false
  }

  moveSpecificTabList() {
    let deltaX = 0;
    for (let i = 0; i < this.selectedIndex1; i++) {
      deltaX += this.arrayPositionsTabs[i].width;
    }
    deltaX *= -1
    document.getElementsByClassName('mat-tab-list')[0].setAttribute('style', 'transform: translateX(' + deltaX + 'px) !important');;
  }

  returnMainOfTab() {
    if (this.selectedIndex == 0) //Para regresar a Home
    {
      this.closeEComm;
    }
    if (this.selectedIndex == 1) //Para regresar a Tiendas desde Productos con clic en Tiendas pestana
    {
      if (this.tabName == "Socio")
        this.tiendas.retornar();
    }
    /*if (this.selectedIndex == 1) //Para regresar a Menu Transferencias
    {
      this.modCompras.habilitarFunciones('habilitarMenu', null);
    }
    if (this.selectedIndex == 2) //Para regresar a Menu Transferencia Tarjeta Credito
    {
      this.modAdminProducts.habilitarFunciones('habilitarMenu', null);
    }
    if (this.selectedIndex == 3) //Para regresar a Menu Servicios
    {
      this.modVentas.habilitarFunciones('habilitarMenu', null);
    }
    if (this.selectedIndex == 5) //Para regresar Inicio Administracion
    {
      this.adminCta.consultaInicial()
    }*/
  }

  clickMatNav(val) {
    if ((val.clientX > 35) && (val.clientX < window.innerWidth - 45)) {
      this.selectedSNav(true);
      this.returnMainOfTab();
    }
    else {
      this.bandScrollMoveTabs = !this.bandScrollMoveTabs ? true : false
      if (val.clientX > 35) { //Flecha derecha
        if (this.selectedIndex1 < this.numNoSelectedTabs) {
          this.selectedIndex1++;
        }
      }
      else { //Flecha izquierda
        if (this.selectedIndex1 > 0) {
          this.selectedIndex1--;
        }
      }
    }
  }

  selectedSNav(bandHideAlert) {
    if (bandHideAlert) {
      this.alerta.clearAlerta();
    }
    //Para Fucionamiento de Seleccion de Toolbar
    let chilNodes = document.getElementById("axuTabLabelsEcomm").firstElementChild.children[1].firstElementChild.firstElementChild.firstElementChild.id.split("-");
    this.selectedIndex = this.selectedIndex1;
    //Para estilo de SiveNav Seleccionado
    for (var i = 0; i < this.sideNavCounter; i++) {
      let i_str = i.toString();
      if (i != this.selectedIndex) {
        document.getElementById("icon-ecomm" + i_str).setAttribute("class", "mat-icon material-icons menuIconNoSelected");
        if (this.grande) {
          document.getElementById("ecomm" + i_str).setAttribute("class", "mat-list-item menuItemNoSelected grandesidenav");
        } else {
          if (this.mediano) {
            document.getElementById("ecomm" + i_str).setAttribute("class", "mat-list-item menuItemNoSelected medianosidenav");
          } else {
            document.getElementById("ecomm" + i_str).setAttribute("class", "mat-list-item menuItemNoSelected ");
          }
        }
      }
      else {
        document.getElementById("icon-ecomm" + i_str).setAttribute("class", "mat-icon material-icons menuIconSelected-Ecommerce");
        if (this.grande) {
          document.getElementById("ecomm" + i_str).setAttribute("class", "mat-list-item menuItemSelected-Ecommerce grandesidenav");
        } else {
          if (this.mediano) {
            document.getElementById("ecomm" + i_str).setAttribute("class", "mat-list-item menuItemSelected-Ecommerce medianosidenav");
          } else {
            document.getElementById("ecomm" + i_str).setAttribute("class", "mat-list-item menuItemSelected-Ecommerce ");
          }
        }
      }
    }
    //Para estilo de TabElement Seleccionado
    try {
      for (var i = 0; i < this.tabsCounter + 1; i++) {
        let i_str = i.toString();
        if (i != this.selectedIndex) {
          document.getElementById("mat-tab-label-" + chilNodes[3] + "-" + i_str).setAttribute("class", "mat-tab-label noSelectedTab");
        }
        else {
          document.getElementById("mat-tab-label-" + chilNodes[3] + "-" + i_str).setAttribute("class", "mat-tab-label selectedTab");
        }
      }
    } catch (ex) {

    }
    // if (this.selectedTabPosition == 0)//Desplaza hasta alinear tab 0 a la izquierda
    // {
    //   document.getElementsByClassName('mat-tab-list')[0].setAttribute('style', 'transform: translateX(0px) !important');;
    // }
  }

  panl() {
    if (this.selectedIndex1 == this.tabsCounter) {
      this.selectedIndex1 = this.tabsCounter;
      this.panBand = false;
    } else {
      if (this.cont <= 8) {
        this.cont += 1;
      }
      else {
        this.selectedIndex1 += 1;
        this.cont = 0;
      }
      this.panBand = true;
    }
  }

  panr() {
    if (this.selectedIndex1 == 0) {
      this.selectedIndex1 = 0;
      this.panBand = false;
    } else {
      if (this.cont <= 8) {
        this.cont += 1;
      }
      else {
        this.cont = 0;
        this.selectedIndex1 += -1;
      }
      this.panBand = true;
    }
  }

  //Gestos de cambio de pestana segementado, NO se realiza el gesto cuando se lo ejecuta desde una tabla
  izq(evt) {
    let tablas;
    let tablasAux = [];
    let moveband = true;
    try {
      tablas = document.getElementsByTagName("table");
    } catch (error) { }
    if (tablas.length > 0) {
      for (let i = 0; i < tablas.length; i++) {
        if ((tablas[i].getBoundingClientRect().top > 0) && (tablas[i].getBoundingClientRect().top < screen.height)) {
          tablasAux.push(tablas[i]);
        }
      }
      for (let i = 0; i < tablasAux.length; i++) {
        let tabTop = tablasAux[i].getBoundingClientRect().top;
        let tabHeight = tablasAux[i].getBoundingClientRect().height;
        if ((evt.center.y >= tabTop) && (evt.center.y <= (tabTop + tabHeight))) {
          moveband = false;
          break;
        }
      }
    }
    if (moveband) {
      if (this.selectedIndex == 0) {
        this.selectedIndex = 0;
      } else {
        this.selectedIndex += -1
      }
      this.selectedIndex1 = this.selectedIndex;
      window.scrollTo(0, 0);
      this.selectedSNav(true);
    }
  }

  right(evt) {
    let tablas;
    let tablasAux = [];
    let moveband = true;
    try {
      tablas = document.getElementsByTagName("table");
    } catch (error) { }
    if (tablas.length > 0) {
      for (let i = 0; i < tablas.length; i++) {
        if ((tablas[i].getBoundingClientRect().top > 0) && (tablas[i].getBoundingClientRect().top < screen.height)) {
          tablasAux.push(tablas[i]);
        }
      }
      for (let i = 0; i < tablasAux.length; i++) {
        let tabTop = tablasAux[i].getBoundingClientRect().top;
        let tabHeight = tablasAux[i].getBoundingClientRect().height;
        if ((evt.center.y >= tabTop) && (evt.center.y <= (tabTop + tabHeight))) {
          moveband = false;
          break;
        }
      }
    }
    if (moveband) {
      if (this.selectedIndex == this.tabsCounter) {
        this.selectedIndex = this.tabsCounter;
      } else {
        this.selectedIndex += 1
      }
      this.selectedIndex1 = this.selectedIndex;
      window.scrollTo(0, 0);
      this.selectedSNav(true);
    }
  }

  toSpecificTab(event) {
    this.selectedIndex = event.sonTab;
    this.selectedIndex1 = this.selectedIndex;
    this.selectedSNav(true);
    switch (event.tarPendType) {
      case 'compraEcomm': //Registro beneficiario
        {
          this.modCompras
        } break;
    }
  }

  numTarPend(event) {
    if (this.numTransaccionesPendientesPrev != event) {
      Promise.resolve(null).then(() => this.numTransaccionesPendientes = event);
      this.numTransaccionesPendientesPrev = event;
    }
  }

  abrirMenu(evt) {
    this.snav.open()
    window.dispatchEvent(new Event('resize'));
  }

  cerrarMenu(evt) {
    this.snav.close();
    window.dispatchEvent(new Event('resize'));
  }

  toolbarWidth() {
    document.getElementById('btnMenu').blur();
    if (this.isLargeScreenForSideNav()) {
      let toolbarPercentage = 100
      let snavWidth = 295
      if (this.snav.opened) {
        toolbarPercentage = 100 - ((250 * 100) / window.innerWidth)
      }
      document.getElementById('tool-bar').style.width = toolbarPercentage + '%'
      if (this.snavWidthChangeband) {
        document.getElementById('contentHome').style.marginLeft = snavWidth + 'px'
      }
      this.snavWidthChangeband = false;
    }
  }

  isLargeScreenForSideNav() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > this.referenceWidth) {
      return true;
    } else {
      return false;
    }
  }

  chatBotWindow() {
    var win = window
    win.open("https://chatbot.fit-bank.com/app/index.html#!/im?p=@SoftWarehouseBot", '_system', 'directories=no,titlebar=no,toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,width=360,height=640')
  }

  doBlur() {
    document.getElementById('btnCerrarSesion').blur();
  }

  espanol() {
    this.idiomas = this.idm.toEspanol()
    this.dateAdapter.setLocale('es')
    this.locale = 'es'
    localStorage.setItem('locale', 'es')
    localStorage.setItem('idioma', JSON.stringify(this.idiomas));
    if (this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  ingles() {
    this.idiomas = this.idm.toEnglish()
    this.dateAdapter.setLocale('en')
    this.locale = 'en'
    localStorage.setItem('locale', 'en')
    localStorage.setItem('idioma', JSON.stringify(this.idiomas));
    if (this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  Grande(auto) {
    if (this.grande)
      return
    if (!auto) { //Se guarda fontSize solamente si fue seleccionado por el usuario
      localStorage.setItem('fontSize', 'large');
    }
    Promise.resolve(null).then(() => this.grande = true);
    Promise.resolve(null).then(() => this.mediano = false);
    Promise.resolve(null).then(() => this.normal = false);
    if (this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  Mediano(auto) {
    if (this.mediano)
      return
    if (!auto) { //Se guarda fontSize solamente si fue seleccionado por el usuario
      localStorage.setItem('fontSize', 'medium');
    }
    Promise.resolve(null).then(() => this.mediano = true);
    Promise.resolve(null).then(() => this.grande = false);
    Promise.resolve(null).then(() => this.normal = false);
    if (this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  Normal(auto) {
    if (this.normal)
      return
    if (!auto) { //Se guarda fontSize solamente si fue seleccionado por el usuario
      localStorage.setItem('fontSize', 'small');
    }
    Promise.resolve(null).then(() => this.normal = true);
    Promise.resolve(null).then(() => this.mediano = false);
    Promise.resolve(null).then(() => this.grande = false);
    if (this.isLargeScreenForSideNav()) {
      this.snavWidthChangeband = true;
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 100);
    }
  }

  resizeToolbar() {
    if (this.isLargeScreenForSideNav()) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  cambiarTema(band): void {
    //"night-theme", "light-theme", "light-theme-verde", "light-theme-gris", "light-theme-azul"
    if (band == 0) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "night-theme";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (band == 1) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "light-theme";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (band == 2) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "light-theme-verde";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (band == 3) {
      this.temaSeleccionadoPrev = this.temaSeleccionado
      this.temaSeleccionado = "light-theme-azul";
      this.temaSeleccionadoBand = band;
      localStorage.setItem('themeDisplayed', this.temaSeleccionado);
    }
    if (this.temaSeleccionadoPrev != this.temaSeleccionado) {
      if (this.overlay.getContainerElement().classList.contains(this.temaSeleccionadoPrev)) {
        this.overlay.getContainerElement().classList.remove(this.temaSeleccionadoPrev);
        this.overlay.getContainerElement().classList.add(this.temaSeleccionado);
      } else {
        this.overlay.getContainerElement().classList.add(this.temaSeleccionado);
      }
      if (document.body.classList.contains(this.temaSeleccionadoPrev)) {
        document.body.classList.remove(this.temaSeleccionadoPrev);
        document.body.classList.add(this.temaSeleccionado);
      } else {
        document.body.classList.add(this.temaSeleccionado);
      }
      if (this.temaSeleccionado != "night-theme") {
        this.logoTemaOscuro = false;
      } else {
        this.logoTemaOscuro = true;
      }
    }
  }

  closeEComm() {
    this.router.navigate(['/home'])
  }
}
