<div id="tableDivNot" class="example-form" fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="cards-transations" *ngIf="!bandMobile">
    <div class="col card-header">
      <div id="lastelement1" class="center texto">
        <h6><strong>{{idiomas.Notificaciones | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding:16px">
      <div *ngIf="bandNotification && !intento && !bandMobile">
        <div class="row" style="margin-left: -24px !important;">
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('notificacionest','left')">
              keyboard_arrow_left
            </mat-icon>
          </div>
          <div class="col container2Aux" [class.heightconteiner2]="!(notificaciones.length>numMaxRow)"
            (scroll)="scroll('notificacionest')">
            <table mat-table [dataSource]="notificaciones" class="notificacionest">
              <ng-container matColumnDef="Fecha">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Fecha}}</th>
                <td mat-cell *matCellDef="let dato" style="max-width: 100px;">
                  {{dato.FECHA | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
              </ng-container>
              <ng-container matColumnDef="Tipo">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Tipo}}</th>
                <td mat-cell *matCellDef="let dato" class="hora">{{dato.Tipo}}</td>
              </ng-container>
              <ng-container matColumnDef="Notificacion">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Mensaje}}</th>
                <td mat-cell *matCellDef="let dato" style="padding-left: 10px; max-width: 250px;">{{dato.COMENTARIO}}
                </td>
              </ng-container>
              <tr mat-header-row class="mayt-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr id="celda1" mat-row *matRowDef="let row; let even = even; let last = last; columns: displayedColumns;"
                [ngClass]="{gray: even}">{{showHideRows(last)}}</tr>
            </table>
          </div>
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('notificacionest','right')">
              keyboard_arrow_right
            </mat-icon>
          </div>
        </div>
      </div>

      <div *ngIf="!bandNotification && !intento">
        <br>
        <div class="center col texto">
          <mat-label>
            {{idiomas.NoTieneNotificaciones | uppercase}}
          </mat-label>
        </div>
        <br>
      </div>
      <div *ngIf=intento>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </mat-card>
  <div *ngIf="bandMobile">
    <div class="col card-header">
      <div id="lastelement1" class="center texto">
        <h6><strong>{{idiomas.Notificaciones | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding:16px">
      <div class="col-12 container-card-mobile" *ngIf="bandNotification && !intento && bandMobile"
        [class.heightconteiner2]="!(notificaciones.length>numMaxRow)">
        <mat-list>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let dato of notificaciones; let even = even;">
            <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
              <div class="col-6">
                <div style="font-weight: bold; font-size: .8em;"> {{dato.FECHA |
                  formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                <div>
                  <div style="font-size: .8em; white-space: initial;">{{idiomas.Tipo}} - {{dato.Tipo}}</div>
                </div>
              </div>
              <div class="col-6" style="text-align: end !important;">
                <div class="colorCartas" style="font-size: .9em;">
                  {{dato.COMENTARIO}}</div>
              </div>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </div>

      <div *ngIf="!bandNotification && !intento">
        <br>
        <div class="center col texto">
          <mat-label>
            {{idiomas.NoTieneNotificaciones | uppercase}}
          </mat-label>
        </div>
        <br>
      </div>
      <div *ngIf=intento>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>