import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { internetComponent } from '../app/funciones/internet';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiService {

  constructor(private http: HttpClient, public internet: internetComponent) {
  }

  createHeader() {
    var header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json');
    header = header.set("Access-Control-Allow-Origin", "*");
    header = header.set("Access-Control-Allow-Methods", "POST, GET, OPTIONS")
    return header
  }

  createToken(token) {
    let header = this.createHeader();
    header = header.set('Authorization', 'Bearer ' + token);
    return header;
  }

  public noTokenProviderInternetCheck(values, localUrl) {
    return new Promise((resolve, reject) => {
      this.internetGetStatus().then(
        (data: any) => {
          this.internet.internetSetStatus(true);
          this.http.post(API_URL + localUrl, values, { headers: this.createHeader() }).subscribe(
            data => {
              resolve(data)
            }, err => {
              reject(err);
            })
        }, (err) => {
          //No Hay Internet
          if (err.status && err.status == -1) //Cuando no se conecte con REST de cliente
          {
            this.internet.StopNetwokStatus();
            if (this.internet.internetGetStatus()) {
              try {
                let idiomas = JSON.parse(localStorage.getItem('idioma'))
                alert(idiomas.NoApiServerResp);
              } catch (error) { }
            }
          }
          this.internet.internetSetStatus(false);
          reject(err)
        }
      )
    })
  }

  public postProvider2InternetCheck(localUrl, token, value) {
    return new Promise((resolve, reject) => {
      this.internetGetStatus().then(
        (data: any) => {
          //Hay Internet
          this.internet.internetSetStatus(true);
          this.http.post(API_URL + localUrl, value, { headers: this.createToken(token) }).subscribe(
            data => {
              resolve(data)
            }, err => {
              if (err.error.message && err.error.message == '3') { //Sesion de usuario expirada
                err.error['mensaje'] = "Error de autenticación via token JWT." // Se modifica mensaje para ejecutar el metodo "logout()" de los componentes
              }
              reject(err);
            })
        }, (err) => {
          //No Hay Internet
          if (err.status && err.status == -1) //Cuando no se conecte con REST de cliente
          {
            this.internet.StopNetwokStatus();
            if (this.internet.internetGetStatus()) {
              try {
                let idiomas = JSON.parse(localStorage.getItem('idioma'))
                alert(idiomas.NoApiServerResp);
              } catch (error) { }
            }
          }
          this.internet.internetSetStatus(false);
          reject(err)
        }
      )
    })
  }

  public internetGetStatus() { //DEBE existir un servicio en / para comprobar conexion
    let localUrl = '/';
    return new Promise((resolve, reject) => {
      setTimeout(reject, 60000, { status: -1 });
      this.http.get(API_URL + localUrl, { headers: this.createHeader() }).subscribe(
        data => {
          resolve(data)
        }, err => {
          reject(err)
        })
    })
  }

  internetStatusBucle() {
    this.internetGetStatus().then(
      (data: any) => {
        this.internet.internetSetStatus(true);
      }, (err) => {
        this.internet.internetSetStatus(false);
      }
    )
  }

  public noTokenProviderInternetCheckImagenes(values, localUrl) {
    return new Promise((resolve, reject) => {
      this.internetGetStatus().then(
        (data: any) => {
          this.internet.internetSetStatus(true);
          this.http.post(API_URL + localUrl, values, { headers: this.createHeader() }).subscribe(
            data => {
              resolve(data)
            }, err => {
              reject(err);
            })
        }, (err) => {
          reject(err)
        }
      )
    })
  }
}
