<div style="width: 100%; height: 50px; margin-top: 1%; text-align: center">
    <label class="center"><strong>Confirmación Venta del Producto</strong></label>
    <div *ngIf="intento">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div><br>
</div>
<mat-dialog-content>
    <div *ngIf="!bandVenta" class="row">
        <div *ngIf="!bandVenta" class="col-md-4">
        </div>
        <div *ngIf="!bandVenta" class="col-md-4">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>Token</mat-label>
                <input matInput type="text" [formControl]="token">
            </mat-form-field>
        </div>
        <div *ngIf="!bandVenta" class="col-md-4">
        </div>
        <div *ngIf="!bandVenta" class="col-md-4">
        </div>
        <div *ngIf="!bandVenta && mensaje" class="col-md-4">
            <mat-label>TOKEN INVÁLIDO</mat-label>
        </div>
    </div>
    <div *ngIf="bandVenta">
        <h2 style="text-align: center"><strong>SE HA REALIZADO LA ENTREGA DE LA COMPRA</strong></h2>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button *ngIf="!bandVenta" mat-raised-button (click)="confirm()" class="buttonSubmit" color="primary">{{idioma.GuardarCambios}}</button>
    <button *ngIf="!bandVenta" mat-raised-button class="buttonCancel" color="warn" (click)="close()">{{idioma.Rechazar}}</button>
    <button *ngIf="bandVenta" mat-raised-button class="buttonSubmit" color="primary" (click)="close()">{{idioma.Regresar}}</button>
</mat-dialog-actions>