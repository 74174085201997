import { Component, OnInit, Input } from '@angular/core';
import { Alerta } from '../funciones/alerta';
import { FormControlValidators } from '../funciones/formcontrol';
import { ApiService } from '../api.service';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { DomSanitizer } from '@angular/platform-browser';
import { internetComponent } from '../funciones/internet';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatDividerModule, MatButtonModule, MatCardModule, MatExpansionModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() idiomas: any;
  @Input() idioma: any;
  @Input() parametros: any;
  @Input() grupoproductos: any;

  //grupoproductos: any;
  categoriasProductos: any[];
  datoBusquedaListModulos: any[];
  intento: boolean;
  bandProductos: boolean;
  index: number[];
  imagenOffline: string;

  constructor(private alerta: Alerta, private validators: FormControlValidators, public apiService: ApiService,
    private servicio: Servicios, public basedatos: BaseDatos, private _DomSanitizer: DomSanitizer, public internet: internetComponent) {
    //this.grupoproductos = [];
    this.categoriasProductos = [];
    this.datoBusquedaListModulos = this.grupoproductos;
    this.intento = false;
    this.bandProductos = false;
    this.imagenOffline = '';
  }

  categoriaFormP = this.validators.comboForm();

  ngOnInit() {
  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.retornarCategorias());
    /*if(this.grupoproductos.length == 0){
      Promise.resolve(null).then(() => this.retornarProductos());
    }*/
    Promise.resolve(null).then(() => this.retornarProductos());
    this.imagenOffline = localStorage.getItem('ImagenOffline')
  }

  applyFilterTypeProduct(filterValue: any) {
    if (filterValue != 0) {
      this.grupoproductos = this.datoBusquedaListModulos.filter((producto) => producto.tipo == filterValue)
      if (this.grupoproductos.length == 0) {
        console.log("No existe dicho documento")
      }
    } else {
      this.grupoproductos = this.datoBusquedaListModulos
    }
  }

  listaProductos() {
    let lproductos = []
    for (let producto of this.grupoproductos) {
      if (producto.CTIENDA == this.parametros.id) {
        lproductos.push(producto)
      }
    }
    lproductos = this.groupBy2(lproductos, function (item) {
      return [item.CPRODUCTOECOMM];   //Para reordenar JSON response de Servicio para posicion consolidada - agrupado por [items.keys]
    });
    this.grupoproductos = this.transformadorProductos(lproductos)
    this.index = new Array(this.grupoproductos.length)
    for (var i = 0; i < this.grupoproductos.length; i++) {
      this.index[i] = 0
    }
    this.datoBusquedaListModulos = this.grupoproductos;
  }

  retornarProductos() {
    var lproductos = []
    let envio = {
      transaccion: '010003-con-productos.xml',
      "cri^tpro0^CTIENDA^NORMAL^tpro0^=": this.parametros.id
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      lproductos = this.groupBy2(data.tpro0, function (item) {
        return [item.CPRODUCTOECOMM];   //Para reordenar JSON response de Productos Ecommerce - agrupado por [items.keys]
      });
      this.grupoproductos = this.transformadorProductos(lproductos)
      if (this.grupoproductos.length > 0) {
        this.bandProductos = true
      }
      this.index = new Array(this.grupoproductos.length)
      for (var i = 0; i < this.grupoproductos.length; i++) {
        this.index[i] = 0
      }
      this.consultaOffiline(this.grupoproductos)
      this.intento = false
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        this.retornarProductosOffline()
        if (!this.grupoproductos) {
          this.bandProductos = false
          this.grupoproductos = []
        }
      }
    });
  }

  consultaOffiline(productos: any[]) {
    let aux = {
      id: '', tienda: '', cpersonaBen: '',
      tipo: '', producto: '', imagen: '',
      precioc: '', preciod: '', descripcion: '',
      nombreBen: '', identificacionBen: '',
      tipoidentificacion: '', ccuentaBen: '',
      cmoneda: '', cimagen0: ''
    }
    let lproductos = []
    for (let producto of productos) {
      aux.id =  producto.id
      aux.tienda = producto.tienda
      aux.cpersonaBen= producto.cpersonaBen
      aux.tipo= producto.tipo
      aux.producto= producto.producto
      aux.imagen = '0'
      aux.precioc= producto.precioc
      aux.preciod= producto.preciod
      aux.descripcion= producto.descripcion
      aux.nombreBen= producto.nombreBen
      aux.identificacionBen= producto.identificacionBen
      aux.tipoidentificacion= producto.tipoidentificacion
      aux.ccuentaBen= producto.ccuentaBen
      aux.cmoneda= producto.cmoneda
      aux.cimagen0= producto.cimagen0
      lproductos.push(aux)
      aux = {
        id: '', tienda: '', cpersonaBen: '',
        tipo: '', producto: '', imagen: '',
        precioc: '', preciod: '', descripcion: '',
        nombreBen: '', identificacionBen: '',
        tipoidentificacion: '', ccuentaBen: '',
        cmoneda: '', cimagen0: ''
      }
    }
    const consulta = {
      'fecha': new Date().toLocaleDateString(),
      'hora': new Date().toLocaleTimeString(),
      'data': lproductos
    };
    this.basedatos.insertarConsulta('Productos' + this.parametros.id, consulta);
  }

  retornarProductosOffline() {
    var ObjConsulta = this.basedatos.retornarConsulta('Productos' + this.parametros.id);
    if (!ObjConsulta) {
      this.bandProductos = false
    } else {
      this.grupoproductos = [];
      this.grupoproductos = ObjConsulta.data;
      this.index = new Array(this.grupoproductos.length)
      for (var i = 0; i < this.grupoproductos.length; i++) {
        this.index[i] = 0
      }
      this.datoBusquedaListModulos = this.grupoproductos
      this.bandProductos = true
    }
  }

  transformadorProductos(value) {
    let lista = [];
    var imagenes = [];
    for (let i = 0; i < value.length; i++) {
      if (value[i][0]['PERMITEDIFERIR'] == '1') {
        value[i][0]['PRECIOD'] = parseFloat((value[i][0]['PRECIO'] / 12).toFixed(2))
      } else {
        value[i][0]['PRECIOD'] = '0.00'
      }
      lista.push({
        id: value[i][0]['CPRODUCTOECOMM'], tienda: value[i][0]['CTIENDA'], cpersonaBen: value[i][0]['CPERSONA'],
        tipo: value[i][0]['CCATEGORIAECOMM_1'], producto: value[i][0]['NOMBRE'], imagen: value[i][0]['IMAGEN'],
        precioc: value[i][0]['PRECIO'], preciod: value[i][0]['PRECIOD'], descripcion: value[i][0]['DESCRIPCION'],
        nombreBen: value[i][0]['NOMBRELEGAL'], identificacionBen: value[i][0]['IDENTIFICACION'],
        tipoidentificacion: value[i][0]['CTIPOIDENTIFICACION'], ccuentaBen: value[i][0]['CCUENTA'],
        cmoneda: value[i][0]['CMONEDA'], cimagen0: value[i][0]['CIMAGEN']
      });
      for (let j = 0; j < value[i].length; j++) {
        imagenes.push(value[i][j]['IMAGEN'])
      }
      lista[i]['imagen'] = imagenes
      imagenes = []
    }
    return lista;
  }

  derecha(i) {
    var length = this.grupoproductos[i]['imagen'].length
    var auxin = this.index[i] + 1
    if (this.grupoproductos[i]['imagen'].length > 0 && auxin < length) {
      this.index[i] = this.index[i] + 1
    } else {
      this.index[i] = this.index[i] - (length - 1)
    }
  }


  izquierda(i) {
    var length = this.grupoproductos[i]['imagen'].length
    var auxin = this.index[i]
    if (this.grupoproductos[i]['imagen'].length > 0 && auxin > 0) {
      this.index[i] = this.index[i] - 1
    } else {
      this.index[i] = this.index[i] + (length - 1)
    }
  }

  retornarCategorias() {
    this.intento = true
    var categoriaaux = '';
    let auxi: ICategorias = {
      id: '',
      detalle: ''
    }
    let enviomonedas = {
      transaccion: '010003-lv-categoriasP.xml',
      alias: "tcat0",
      "cri^tcat0^CTIENDA^NORMAL": this.parametros.id
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviomonedas).then((data: any) => {
      for (const categoria of data) {
        auxi.id = categoria.CCATEGORIAECOMM
        auxi.detalle = categoria.DESCRIPCION
        if (categoria.CCATEGORIAECOMM) {
          this.categoriasProductos.push(auxi)
        }
        auxi = {
          id: '',
          detalle: ''
        }
      }
      const consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.categoriasProductos,
      };
      this.basedatos.insertarConsulta('CategotiasP' + this.parametros.id, consulta);
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        this.retornarCategoriasOffline()
        if (!this.categoriasProductos) {
          this.categoriasProductos = []
        }
      }
    });
  }

  retornarCategoriasOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CategotiasP' + this.parametros.id);
    if (ObjConsulta) {
      this.categoriasProductos = [];
      this.categoriasProductos = ObjConsulta.data;
    }
  }

  selectProducto(producto) {
    producto['grande'] = this.grande
    producto['mediano'] = this.mediano
    this.alerta.generarDialogoCompra(this.idiomas, producto)
  }

  groupBy2(arrayNF, f) {
    var array = arrayNF;
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }
}

interface ICategorias {
  id: string,
  detalle: string
}