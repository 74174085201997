import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { Funciones } from '../funciones/funciones';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-transacciones',
  templateUrl: './dialogo-transacciones.component.html',
  styleUrls: ['./dialogo-transacciones.component.css'],
  providers: [Funciones],
})
export class DialogoTransaccionesComponent {
  mensaje: string;
  aceptar: string;
  rechazar: string;
  pdf: string = "";
  bandIos: boolean;
  showPDF: boolean;
  base64URL: any;

  idiomas: any;
  numeroMensaje: any;
  intentoEnvioTransPagRec: any;

  er: boolean = false;
  bandMobile: boolean;
  intento: boolean;
  intentoTransferPagoServ: boolean;
  intentoBanred: boolean = false;
  nameDocumentTransaction: string;
  typeOfTransference = [
    'COMPROBANTE_TRANSFERENCIA',
    'COMPROBANTE_TRANSFERENCIA',
    'COMPROBANTE_TRANSFER_BANRED',
    'COMPROBANTE_TRANSFERENCIA_SPI',
    'COMPROBANTE_TRANSFER_TC_BANRED',
    'COMPROBANTE_TRANSFERENCIA_CRED',
    '18_COMPROBANTE_PAGO',
    '18_COMPROBANTE_PAGO',
    '18_COMPROBANTE_REC'
  ];

  constructor(public apiService: ApiService,
    private ngNavigatorShareService: NgNavigatorShareService,
    private dialogRef: MatDialogRef<DialogoTransaccionesComponent>, @Inject(MAT_DIALOG_DATA) data, public basedatos:
      BaseDatos, public mostrarPdf: MostrarPDF, public funciones: Funciones) {
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.idiomas = data.idm1;
    this.numeroMensaje = data.numeroMensaje;
    this.intentoTransferPagoServ = data.intentoTransferPagoServ;
    this.intentoEnvioTransPagRec = data.intentoEnvioTransPagRec;
    this.intentoBanred = data.intentoBanred
    this.bandMobile = false;
    this.bandIos = false;
    this.showPDF = true;
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    this.nameDocumentTransaction = this.obtaiTypeOfTransference();
    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true
    }

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /ipad|iphone|ipod/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandIos = isIos()

    if (isIos() && !isInStandaloneMode()) {
      this.bandIos = true
    }

    if (window.innerWidth < 834) {
      this.bandMobile = true
    } else {
      this.bandMobile = false
    }
  }

  get filenameForDownload(): string {
    return `${this.nameDocumentTransaction}.pdf`;
  }

  accept() {
    this.dialogRef.close(this.er);
  }

  close() {
    this.dialogRef.close(this.er);
  }

  obtaiTypeOfTransference(){
    if (this.intentoEnvioTransPagRec == "1") return this.funciones.generateNameOfTransaction(this.typeOfTransference[0]);
    if (this.intentoEnvioTransPagRec == "2") return this.funciones.generateNameOfTransaction(this.typeOfTransference[1]);
    if (this.intentoEnvioTransPagRec == "3") {
      const banRed = this.intentoBanred ? this.typeOfTransference[2] : this.typeOfTransference[3]
      return this.funciones.generateNameOfTransaction(banRed);
    }
    if (this.intentoEnvioTransPagRec == "4") {
      const banRed = this.intentoBanred ? this.typeOfTransference[4] : this.typeOfTransference[5]
      return this.funciones.generateNameOfTransaction(banRed);
    }
    if (this.intentoEnvioTransPagRec == "5") return this.funciones.generateNameOfTransaction(this.typeOfTransference[6]);
    if (this.intentoEnvioTransPagRec == "6") return this.funciones.generateNameOfTransaction(this.typeOfTransference[7])
    if (this.intentoEnvioTransPagRec == "7") return this.funciones.generateNameOfTransaction(this.typeOfTransference[8]);
  }

  reportePDF() {
    this.intento = true
    var envio = {}
    if (this.intentoEnvioTransPagRec == "1") {
      envio = {
        "nombre": this.typeOfTransference[0],
        "subsistema": "18",
        "transaccion": "6073",
        "tipo": "PDF",
        "rep^NUMEROMENSAJE": this.numeroMensaje
      }
    }
    if (this.intentoEnvioTransPagRec == "2") {
      envio = {
        "nombre": this.typeOfTransference[1],
        "subsistema": "18",
        "transaccion": "6074",
        "tipo": "PDF",
        "rep^NUMEROMENSAJE": this.numeroMensaje
      }
    }
    if (this.intentoEnvioTransPagRec == "3") {
      envio = {
        "nombre": this.intentoBanred ? this.typeOfTransference[2] : this.typeOfTransference[3],
        "subsistema": "18",
        "transaccion": "6071",
        "tipo": "PDF",
      }
    }
    if (this.intentoEnvioTransPagRec == "4") {
      envio = {
        "nombre": this.intentoBanred ? this.typeOfTransference[4] : this.typeOfTransference[5],
        "subsistema": "18",
        "transaccion": "6071",
        "tipo": "PDF",
      }
    }
    if (this.intentoEnvioTransPagRec == "5") {
      envio = {
        'nombre': this.typeOfTransference[6],
        'subsistema': '18',
        'transaccion': '6000',
        'tipo': 'PDF',
      }
    }

    if (this.intentoEnvioTransPagRec == "6") {
      envio = {
        'nombre': this.typeOfTransference[7],
        'subsistema': '18',
        'transaccion': '6000',
        'tipo': 'PDF',
        'rep^DESCRIPCION_TRANSACCION': 'RECARGAS MÓVILES',
      }
    }

    if (this.intentoEnvioTransPagRec == "7") {
      envio = {
        'nombre': this.typeOfTransference[8],
        'subsistema': '18',
        'transaccion': '6025',
        'tipo': 'PDF',
        'rep^SGIRO': this.numeroMensaje.sgiroTransferencia,
        'rep^FECHA': this.numeroMensaje.fecha,
        'rep^USUARIO': '$personName',
        'rep^MONEDA': this.numeroMensaje.moneda,
        'rep^CUENTA': this.numeroMensaje.cuenta
      }
    }

    if (this.intentoEnvioTransPagRec != "7") {
      envio['rep^NUMEROMENSAJE'] = this.numeroMensaje;
    }

    this.apiService.postProvider2InternetCheck("/reporte", this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        if (this.bandIos) {
          this.base64URL = this.pdf as string
          this.showPDF = false;
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf, this.nameDocumentTransaction)
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false

      }
    });
  }

  async sharePDF() {
    this.intento = true
    let infoTitleText = this.intentoEnvioTransPagRec == "1" || this.intentoEnvioTransPagRec == "2" ?
      'COMPROBANTE_TRANSFERENCIA' : this.intentoEnvioTransPagRec == "3" && this.intentoBanred ?
        "COMPROBANTE_TRANSFER_BANRED" : this.intentoEnvioTransPagRec == "3" && !this.intentoBanred ?
          "COMPROBANTE_TRANSFERENCIA_SPI" : this.intentoEnvioTransPagRec == "4" && this.intentoBanred ?
            'COMPROBANTE_TRANSFER_TC_BANRED' : this.intentoEnvioTransPagRec == "4" && !this.intentoBanred ?
              ' COMPROBANTE_TRANSFERENCIA_CRE' : 'COMPROBANTE_PAGO'
    var envio = {}
    if (this.intentoEnvioTransPagRec == "1") {
      envio = {
        "nombre": "COMPROBANTE_TRANSFERENCIA",
        "subsistema": "18",
        "transaccion": "6073",
        "tipo": "PDF",
        "rep^NUMEROMENSAJE": this.numeroMensaje
      }
    }
    if (this.intentoEnvioTransPagRec == "2") {
      envio = {
        "nombre": "COMPROBANTE_TRANSFERENCIA",
        "subsistema": "18",
        "transaccion": "6074",
        "tipo": "PDF",
        "rep^NUMEROMENSAJE": this.numeroMensaje
      }
    }
    if (this.intentoEnvioTransPagRec == "3") {
      envio = {
        "nombre": this.intentoBanred ? "COMPROBANTE_TRANSFER_BANRED" : "COMPROBANTE_TRANSFERENCIA_SPI",
        "subsistema": "18",
        "transaccion": "6071",
        "tipo": "PDF",
      }
    }
    if (this.intentoEnvioTransPagRec == "4") {
      envio = {
        "nombre": this.intentoBanred ? "COMPROBANTE_TRANSFER_TC_BANRED" : "COMPROBANTE_TRANSFERENCIA_CRED",
        "subsistema": "18",
        "transaccion": "6071",
        "tipo": "PDF",
      }
    }
    if (this.intentoEnvioTransPagRec == "5") {
      envio = {
        'nombre': '18_COMPROBANTE_PAGO',
        'subsistema': '18',
        'transaccion': '6000',
        'tipo': 'PDF',
      }
    }

    if (this.intentoEnvioTransPagRec == "6") {
      envio = {
        'nombre': '18_COMPROBANTE_PAGO',
        'subsistema': '18',
        'transaccion': '6000',
        'tipo': 'PDF',
        'rep^DESCRIPCION_TRANSACCION': 'RECARGAS MÓVILES',
      }
    }

    if (this.intentoEnvioTransPagRec == "7") {
      envio = {
        'nombre': '18_COMPROBANTE_REC',
        'subsistema': '18',
        'transaccion': '6025',
        'tipo': 'PDF',
        'rep^SGIRO': this.numeroMensaje.sgiroTransferencia,
        'rep^FECHA': this.numeroMensaje.fecha,
        'rep^USUARIO': '$personName',
        'rep^MONEDA': this.numeroMensaje.moneda,
        'rep^CUENTA': this.numeroMensaje.cuenta
      }
    }

    if (this.intentoEnvioTransPagRec != "7") {
      envio['rep^NUMEROMENSAJE'] = this.numeroMensaje;
    }

    this.apiService.postProvider2InternetCheck("/reporte", this.basedatos.id_token, envio).then(async (data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        var byteCharacters = atob(this.pdf);
        var byteNumbers = new Array(byteCharacters.length);

        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        const file = new File([new Blob([byteArray])], infoTitleText + '.pdf', { type: "application/pdf" });
        this.ngNavigatorShareService.share({
          title: infoTitleText,
          text: infoTitleText,
          url: 'https://bancavirtual.lorente.fin.ec',
          files: [file],
        }).then(() => console.log('Share was successful.'))
          .catch((error) => console.log('Sharing failed', error));
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false

      }
    });
  }

}