import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CambiarVariableCuenta, CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, HoraSubString1, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatProgressBarModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, FormsModule, OcultarCuentaPosTrans, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFecha, HoraSubString1, CambiarVariableCuenta, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-detalle-transferencia',
  templateUrl: './dialogo-detalle-transferencia.component.html',
  styleUrls: ['./dialogo-detalle-transferencia.component.css']
})
export class DialogoDetalleTransferenciaComponent {

  er: boolean = false;
  intento: boolean;
  bandMobile: boolean;
  bandIos: boolean;

  idiomas: any;
  dato: any;
  bandTarjetaCredito: any;
  tipoidentificacion: string[];
  pdf: string = "";
  showPDF: boolean;
  base64URL: any;

  messageError: any
  intentoMessage: boolean
  index: any;
  grande: boolean = false;
  mediano: boolean = false;
  normal: boolean = false;

  constructor(private api: ApiService, private base: BaseDatos, public internet: internetComponent, public mostrarPdf: MostrarPDF, private ngNavigatorShareService: NgNavigatorShareService,
    private dialogRef: MatDialogRef<DialogoDetalleTransferenciaComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.idiomas = data.idioma;
    this.dato = data.data;
    this.bandTarjetaCredito = data.bandConsultaTarjetaCredito
    this.index = data.i
    this.intentoMessage = false
    this.tipoidentificacion = [];
    this.grande = data.fontSize.grande;
    this.mediano = data.fontSize.mediano;
    this.normal = data.fontSize.normal;
    this.bandMobile = false;
    this.bandIos = false;
    this.showPDF = true;
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';

    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true
    }

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /ipad|iphone|ipod/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandIos = isIos()

    if (isIos() && !isInStandaloneMode()) {
      this.bandIos = true
    }

    if (window.innerWidth < 834) {
      this.bandMobile = true
    } else {
      this.bandMobile = false
    }
  }

  close() {
    this.dialogRef.close(this.er);
  }

  reportePDF() {
    this.intento = true
    let nombreReporte = this.bandTarjetaCredito == 2 ? "COMPROBANTE_TRANSFERENCIA_CRED" : this.dato.CTRANSACCION == "6071" ? "COMPROBANTE_TRANSFERENCIA_SPI" : "COMPROBANTE_TRANSFERENCIA";
    if (this.tipoidentificacion[this.index] == 'CED') {
      var tipoidentificacion = "CÉDULA DE IDENTIDAD"
    } else {
      tipoidentificacion = "RUC"
    }
    var envio = {
      "nombre": nombreReporte,
      "subsistema": "18",
      "transaccion": "5003",
      "tipo": "PDF",
      "rep^NUMEROMENSAJE": this.dato.NUMEROMENSAJE
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        if (this.bandIos) {
          this.base64URL = this.pdf as string
          this.showPDF = false;
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.messageError = this.idiomas.ServidorError
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  async sharePDF() {

    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.intento = true
    let nombreReporte = this.bandTarjetaCredito == 2 ? "COMPROBANTE_TRANSFERENCIA_CRED" : this.dato.CTRANSACCION == "6071" ? "COMPROBANTE_TRANSFERENCIA_SPI" : "COMPROBANTE_TRANSFERENCIA";
    if (this.tipoidentificacion[this.index] == 'CED') {
      var tipoidentificacion = "CÉDULA DE IDENTIDAD"
    } else {
      tipoidentificacion = "RUC"
    }
    var envio = {
      "nombre": nombreReporte,
      "subsistema": "18",
      "transaccion": "5003",
      "tipo": "PDF",
      "rep^NUMEROMENSAJE": this.dato.NUMEROMENSAJE
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then(async (data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        var byteCharacters = atob(this.pdf);
        var byteNumbers = new Array(byteCharacters.length);

        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        const file = new File([new Blob([byteArray])], nombreReporte + '.pdf', { type: "application/pdf" });
        this.ngNavigatorShareService.share({
          title: nombreReporte,
          text: nombreReporte,
          url: 'https://bancavirtual.lorente.fin.ec',
          files: [file],
        }).then(() => console.log('Share was successful.'))
          .catch((error) => console.log('Sharing failed', error));
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.messageError = this.idiomas.ServidorError
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
  }

}
