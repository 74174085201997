import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormControlValidators } from '../funciones/formcontrol';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Alerta } from '../funciones/alerta';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CambiarVariableCuenta, CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatButtonModule, MatInputModule, MatCheckboxModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, CambiarVariableCuenta, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-compra',
  templateUrl: './dialogo-compra.component.html',
  styleUrls: ['./dialogo-compra.component.css']
})
export class DialogoCompraComponent {

  er: boolean = false;
  idioma: any;
  cimagen: string;
  image: SafeUrl;
  imagen: SafeUrl;
  descripcion: string;
  precioc: number;
  preciod: number;
  comprar: string;
  rechazar: string;
  opciones: boolean[] = [];
  subtotal: number;
  total: number;
  bandCompra: boolean;
  bandBtnCompra: boolean;
  grande: boolean;
  mediano: boolean;
  accountSelected: boolean;
  directionSelected: boolean;
  cuenta: string
  parametros: any;
  direcciones: any[];
  cuentas: any[];
  intento: boolean;
  token: string;
  intentoCompra: boolean;
  cantidad: number;
  preciou: number;
  fcompra: string;
  pdf: string;
  bandCompraOffline: boolean;
  imagenOffline: string;
  direccion: string;
  error: boolean = false;
  msjerror: string = '';

  constructor(private dialogRef: MatDialogRef<DialogoCompraComponent>, @Inject(MAT_DIALOG_DATA) data,
    private validators: FormControlValidators, private _DomSanitizer: DomSanitizer, public apiService: ApiService,
    public basedatos: BaseDatos, public mostrarPdf: MostrarPDF) {
    this.idioma = data.idioma;
    this.imagen = ''
    this.cuenta = data.parametros.cuenta;
    this.image = data.parametros.imagen;
    this.cimagen = data.parametros.cimagen;
    this.descripcion = data.parametros.descripcion;
    this.precioc = parseFloat(data.parametros.precioc);
    this.preciod = parseFloat(data.parametros.preciod);
    this.preciou = this.precioc;
    this.grande = data.parametros.grande;
    this.mediano = data.parametros.mediano;
    this.parametros = data.parametros;
    this.subtotal = 0.00;
    this.total = 0.00;
    for (var i = 0; i < 4; i++) {
      this.opciones[i] = false;
    }
    this.bandCompra = false;
    this.bandBtnCompra = true;
    this.accountSelected = false;
    this.directionSelected = false;
    this.cuentas = [];
    this.direcciones = [];
    this.intento = false;
    this.token = '';
    this.intentoCompra = false;
    this.cantidad = 1;
    this.pdf = '';
    this.bandCompraOffline = false;
    this.imagenOffline = "";
    this.direccion = "";
  }

  direccionForm = this.validators.comboForm();
  cuentaForm = this.validators.comboForm();

  ngOnInit() {
  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.obtainOwnAccount());
    Promise.resolve(null).then(() => this.obtainDirections());
    this.imagenOffline = localStorage.getItem('ImagenOffline')
  }

  close() {
    this.dialogRef.close(this.er);
  }

  tomarValorDireccion(value) {
    for (let direccion of this.direcciones) {
      if (direccion.id == value) {
        this.direccion = direccion.descripcion
      }
    }
    if (this.direccionForm.value == "02") {
      this.directionSelected = true
    } else {
      this.directionSelected = false
    }
  }

  tomarValorCuenta(value) {
    this.accountSelected = true;
  }

  buy() {
    this.intentoCompra = true
    let fechaActual = new Date()
    this.fcompra = fechaActual.getFullYear() + "-" + this.anadirCero(fechaActual.getMonth() + 1) + "-" + this.anadirCero(fechaActual.getDate()) + " " + fechaActual.getHours() + ":" + fechaActual.getMinutes() + ":" + fechaActual.getSeconds()
    let envio = {
      transaccion: '183037-man-register-movement.xml',
      'rec^tme0^0^CTIENDA': this.parametros.tienda,
      'rec^tme0^0^CPERSONA_ORDENANTE': '$personCode',
      'rec^tme0^0^CPERSONA_BENEFICIARIO': this.parametros.cpersonaBen,
      'rec^tme0^0^CCUENTA_ORDENANTE': this.cuentaForm.value,
      'rec^tme0^0^CPRODUCTOECOMM': this.parametros.id,
      'rec^tme0^0^DIRECCION': this.direccion,
      'rec^tme0^0^VALOR': this.precioc,
      'rec^tme0^0^CANTIDAD': this.cantidad,
      'rec^tme0^0^TOTAL': this.total,
      'rec^tme0^0^ESTATUS': '0',
    }
    if (this.opciones[3]) {
      envio['rec^tme0^0^DIRECCION'] = this.direccion
    } else {
      envio['rec^tme0^0^DIRECCION'] = ''
    }
    this.purchaseTarPend(envio)
    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
      this.token = data.ctl['PURCHASE_TOKEN']
      this.bandCompra = true
      this.error = false
      this.intentoCompra = false
    }, (err) => {
      this.bandCompra = false
      this.intentoCompra = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.error = true
            this.msjerror = err.error.mensaje
            console.log(err.error.mensaje)
          }
        }
      }
      else { //Sin Internet
        this.basedatos.insertarTareaEcomm(envio);
        this.bandCompraOffline = true;
      }
    });
  }

  purchaseTarPend(envio) {
    var iva = this.precioc * 0.12
    iva = parseFloat(iva.toFixed(2))
    envio['producto'] = this.parametros.producto
    envio['nombre'] = this.parametros.nombre
    envio['cuenta'] = this.cuentaForm.value
    envio['cantidad'] = this.cantidad
    envio['valorU'] = this.precioc
    envio['valorT'] = this.total
    envio['estatus'] = '0'
    envio['cimagen'] = this.parametros.cimagen0
    envio['iva'] = iva
    envio['tarPendType'] = 'compraEcommerce'
    if (this.opciones[3]) {
      envio['direccion'] = this.direccion
    } else {
      envio['direccion'] = ''
    }
    if (this.parametros.nombre) {
      envio['nombre'] = this.parametros.nombre
    } else {
      envio['nombre'] = "$personName"
    }
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  obtainOwnAccount() {
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'usuario': '',
      'alias': 'tc0',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN': '',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
      'cri^tctp0^CTRANSACCION^JOIN': '6071',
      'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
    };
    envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.cuentas = this.transformadorCuentasDesde(data);
        this.cuentaForm.setValue(this.cuentas[0].value)
        this.llenarDatos()
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentas
        };
        this.basedatos.insertarConsulta('CuentasCompra', consulta);
      }
      this.intento = false;
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet 
        this.retornarCuentasOffline()
        this.llenarDatos()
        if (!this.cuentas) {
          this.cuentas = []
        }
      }
    });
  }

  retornarCuentasOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CuentasCompra');
    if (ObjConsulta || ObjConsulta.data != 0) {
      this.cuentas = [];
      this.cuentas = ObjConsulta.data;
    }
  }

  obtainDirections() {
    const envio = {
      transaccion: '010003-lv-direcciones.xml',
      alias: 'tdir0',
      'cri^tdir0^CPERSONA^NORMAL': '$personCode'
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      this.direcciones = this.transformadorDirecciones(data);
      const consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.direcciones
      };
      this.basedatos.insertarConsulta('DireccionesCompra', consulta);
      this.intento = false;
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet  
        this.retornarDireccionesOffline()
        if (!this.direcciones) {
          this.direcciones = []
        }
      }
    });
  }

  retornarDireccionesOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('DireccionesCompra');
    if (ObjConsulta || ObjConsulta.data != 0) {
      this.direcciones = [];
      this.direcciones = ObjConsulta.data;
    }
  }

  obtainImagen() {
    this.imagen = ''
    const envio = {
      transaccion: '010003-con-imagen.xml',
      "cri^timg0^CIMAGEN^NORMAL^timg0^=": this.cimagen
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.imagen = data.timg0[0].IMAGEN;
      this.intento = false
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet  
        this.imagen = '0'
      }
    });
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].CCUENTA, 'viewValue':
          value[i].CCUENTA, 'descProducto': value[i].DESCRIPCION, moneda: value[i].CMONEDA,
        tipoidentificacion: value[i].CTIPOIDENTIFICACION, cpersonaBanco: '2'
      });
    }
    return lista;
  }

  transformadorDirecciones(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      if (value[i].DIRECCION && value[i].DIRECCION != 'null') {
        lista.push({
          'id': value[i].NUMERODIRECCION, 'descripcion': value[i].DIRECCION
        });
      }
    }
    return lista;
  }

  llenarDatos() {
    if (this.cimagen) {
      this.obtainImagen()
    } else {
      this.imagen = this.image[0]
      this.intento = false
    }
  }

  multiplicador() {
    this.precioc = this.preciou * this.cantidad
    if (this.precioc >= 50) {
      this.preciod = this.precioc / 12
      this.preciod = Math.round(this.preciod * 100) / 100
    } else {
      this.preciod = 0.00
    }
    for (var i = 0; i < this.opciones.length; i++) {
      if (this.opciones[i]) {
        this.selectCheck(i.toString())
      }
    }
    this.precioc = parseFloat(this.precioc.toFixed(2))
  }

  selectCheck(value) {
    this.subtotal = 0.00
    this.total = 0.00
    var iva = 0.12
    if (value == '0') {
      this.subtotal = this.precioc
      this.total = this.precioc + (this.subtotal * iva)
      this.opciones[2] = false
    }
    if (value == '1') {
      if (this.opciones[0]) {
        this.subtotal = this.precioc
        this.total = this.precioc + (this.subtotal * iva)
      }
      if (this.opciones[2]) {
        this.subtotal = this.preciod * 12
        this.total = this.subtotal + (this.subtotal * iva)
      }
      this.opciones[3] = false
    }
    if (value == '2') {
      this.subtotal = this.precioc
      this.total = this.subtotal + (this.subtotal * iva)
      this.opciones[0] = false
    }
    if (value == '3') {
      if (this.opciones[0]) {
        this.subtotal = this.precioc
      } else if (this.opciones[2]) {
        this.subtotal = this.preciod * 12
      }
      this.total = this.subtotal + (this.subtotal * iva) + 2.00
      this.opciones[1] = false
    }
    if (value == '0' && !this.opciones[0]) {
      this.subtotal = 0.00
      if (this.opciones[3])
        this.total = (this.total - this.precioc - (this.precioc * iva)) + 2.00
      else
        this.total = this.total - this.precioc - (this.precioc * iva)
    }
    if (value == '1' && !this.opciones[1]) {
      this.total = this.total - 0.00
    }
    if (value == '2' && !this.opciones[2]) {
      this.subtotal = 0.00
      if (this.opciones[3])
        this.total = (this.total - (this.precioc) - (this.precioc * iva)) + 2.00
      else
        this.total = this.total - (this.precioc) - (this.precioc * iva)
    }
    if (value == '3' && !this.opciones[3]) {
      this.total = this.total - 2.00
      this.directionSelected = false
    }
    if ((this.total > 0.00 && (this.opciones[0] || this.opciones[2]))) {
      this.bandBtnCompra = false
    } else {
      this.bandBtnCompra = true
    }
    this.total = parseFloat(this.total.toFixed(2))
    this.subtotal = parseFloat(this.subtotal.toFixed(2))
  }

  reportePDF() {
    this.intento = true
    if (this.parametros.tipoidentificacion == 'CED') {
      var tipoidentificacion = "CÉDULA DE IDENTIDAD"
    } else {
      tipoidentificacion = "RUC"
    }
    var iva = this.precioc * 0.12
    iva = parseFloat(iva.toFixed(2))
    var envio = {
      "nombre": "18_COMPROBANTE_ECOMMERCE",
      "subsistema": "18",
      "transaccion": "3037",
      "tipo": "PDF",
      "rep^DESCRIPCION_TRANSACCION": "COMPRA ECOMMERCE",
      "rep^FECHA": this.fcompra,
      "rep^CUSUARIO": "$userCode",
      "rep^NOMBREORDENANTE": this.parametros.nombre,
      "rep^ID_ORDENANTE": "$personId",
      "rep^NOMBREPRODUCTO": this.parametros.producto,
      "rep^PRECIO": this.precioc,
      "rep^CANTIDAD": this.cantidad,
      "rep^MONTO": this.total,
      "rep^TOKEN": this.token,
      "rep^COMISION": '0.00',
      "rep^CIMAGEN": this.parametros.cimagen0,
      "rep^IVA": iva,
      "rep^DOMICILIO": 'CLIENTE VA A RETIRAR EN TIENDA',
      "rep^VALOR": '0.00',
      "rep^REFERENCIA": "COMPRA PRODUCTO ECOMMERCE"
    }
    if (this.opciones[3]) {
      envio["rep^DOMICILIO"] = this.direccion
      envio["rep^VALOR"] = '2.00'
    }
    if (this.parametros.nombre) {
      envio["rep^NOMBREORDENANTE"] = this.parametros.nombre
    } else {
      envio["rep^NOMBREORDENANTE"] = "$personName"
    }
    this.apiService.postProvider2InternetCheck("/reporte", this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
  }
}
