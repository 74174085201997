import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LivenessService } from './liveness.service';
import * as AWS from 'aws-sdk';
import { FaceLivenessReactWrapperComponent } from 'src/FaceLivenessReactWrapperComponent';
import awsmobile from 'src/aws-exports';
import { signOut } from 'aws-amplify/auth';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  standalone:true,
  imports:[FaceLivenessReactWrapperComponent, AlertasLoginComponent, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule,  FlexLayoutModule],
  selector: 'app-face-liveness',
  templateUrl: './face-liveness.component.html',
  styleUrls: ['./face-liveness.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FaceLivenessComponent implements OnInit {

  @Input() idiomas: any;
  @Output() eventImage = new EventEmitter<any>();
  @ViewChild('faceliveness', { static: false }) faceliveness: FaceLivenessReactWrapperComponent;

  public counter = 21;

  start_liveness_session = false;
  liveness_session_complete = false;
  session_id = null;
  is_live = false;
  error = false;
  confidence = 0;
  face_live_message = "";

  constructor(private faceLivenessService: LivenessService) { }

  ngOnInit(): void {
    this.faceLivenessService.liveness_session.subscribe(([status, data]) => {
      if (status == 'success') {
        this.initate_liveness_session(data);
      }
    })

    AWS.config.region = awsmobile['aws_project_region'];
    const cognito_endpoint = `cognito-idp.${awsmobile['aws_project_region']}.amazonaws.com/${awsmobile['aws_user_pools_id']}`
    // Initialize the Amazon Cognito credentials provider
    const session = this.faceLivenessService.get_current_session().then(data => {
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsmobile['aws_cognito_identity_pool_id'],
        Logins: {
          [cognito_endpoint]: data.tokens?.idToken?.toString()
        }
      });

      this.get_liveness_session()
    }).catch(err => {
      console.log(err)
    }
    );
  }

  public handleErrors(err: any) {
    this.liveness_session_complete = true;
    this.error = true;
    this.start_liveness_session = false;
    this.face_live_message = this.idiomas.ErrorLiveness.toUpperCase();
    this.is_live = false;
    // Force remove the ReactDOM
    this.faceliveness.ngOnDestroy();
  }

  public handleLivenessAnalysisResults(data: any) {
    if (data['Confidence'] >= 65) {
      this.is_live = true;
      this.error = false;
      if (data.ReferenceImage) {
        var image64 = this.base64ArrayBuffer(data.ReferenceImage.Bytes)
        this.signOut();
        this.eventImage.emit(image64);
      } else {
        this.error = true;
        this.face_live_message = this.idiomas.ErrorLiveness.toUpperCase();
      }
    } else {
      this.is_live = false;
      this.error = true;
      this.face_live_message = this.idiomas.ValidacionLiveness.toUpperCase();
    }
    this.liveness_session_complete = true;
    this.start_liveness_session = false;
    // Force remove the ReactDOM
    this.faceliveness.ngOnDestroy();
  }

  signOut(): void {
    setTimeout(() => {
      signOut();
    }, 3);
  }

  base64ArrayBuffer(arrayBuffer) {
    var base64 = ''
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

    var bytes = new Uint8Array(arrayBuffer)
    var byteLength = bytes.byteLength
    var byteRemainder = byteLength % 3
    var mainLength = byteLength - byteRemainder

    var a, b, c, d
    var chunk

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
      d = chunk & 63               // 63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
      chunk = bytes[mainLength]

      a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4 // 3   = 2^2 - 1

      base64 += encodings[a] + encodings[b] + '=='
    } else if (byteRemainder == 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

      a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2 // 15    = 2^4 - 1

      base64 += encodings[a] + encodings[b] + encodings[c] + '='
    }

    return base64
  }

  initate_liveness_session(data: {}) {
    this.is_live = false;
    this.session_id = data['SessionId'];
    this.confidence = 0;
    this.liveness_session_complete = false;
    setTimeout(() => {
      this.start_liveness_session = true;
    }, 3);
  }

  get_liveness_session() {
    this.start_liveness_session = false;
    this.faceLivenessService.get_face_liveness_session();
  }
}
