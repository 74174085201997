import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Alerta } from '../funciones/alerta';
import { FuncionesTabla } from '../funciones/funcionesTabla';
import { FormControlValidators } from '../funciones/formcontrol';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { DomSanitizer } from '@angular/platform-browser';
import { internetComponent } from '../funciones/internet';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CadenaCaracteres, CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatProgressBarModule, MatButtonModule, MatInputModule, MatTableModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, CadenaCaracteres, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-panel-proveedores',
  templateUrl: './panel-proveedores.component.html',
  styleUrls: ['./panel-proveedores.component.css']
})
export class PanelProveedoresComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() idiomas: any;

  datosEstatus: any[] = [{ id: '0', detalle: 'Agotado' }, { id: '1', detalle: 'Disponible' }]
  datosCategoria: any[];
  datosProductos: any;
  dataProductos: any [];
  displayedColumnsProducto = ['Nuevo', 'Producto', 'Detalle', 'BotonDetalle', 'Categoria', 'Subcategoria1', 'Subcategoria2', 'Estatus', 'Precio', 'Editar'/*, 'Eliminar'*/];
  flechaR: boolean;
  tabla: any;
  flechaL: boolean;
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number;
  numMaxRow: number;
  length: number;
  previo: number;
  bool: boolean;
  intento: boolean;
  cargarDatos: boolean;
  ctienda: any
  carac1: any
  carac2: any
  cargarTabla: boolean
  fdesde: any
  nuevo: any
  npg: any;
  nrg: number;
  popUltimo: any
  intentoSiguiente: boolean;
  arrProductos: any[];
  intentoEstatusProducto: boolean;
  intentoCategoriaProductos: boolean;
  intentoNombreProducto: boolean;
  indexCategoria: number;
  estatusId: string;


  constructor(
    private alerta: Alerta,
    private funcionesTabla: FuncionesTabla,
    private validators: FormControlValidators,
    public apiService: ApiService,
    public basedatos: BaseDatos,
    private _DomSanitizer: DomSanitizer,
    public internet: internetComponent
  ) {
    this.flechaR = false;
    this.flechaL = true;
    this.pageSize = 20;
    this.nrg = 21;
    this.numMaxRow = 9;
    this.cargarDatos = false;
    this.cargarTabla = false;
    this.arrProductos = [];
    this.intentoEstatusProducto = false;
    this.intentoCategoriaProductos = false;
    this.intentoNombreProducto = false;
    this.dataProductos = [];
    this.indexCategoria = 0;
    this.estatusId = '';
  }

  identificador = this.validators.textNumberSpecialFeaturetForm();
  categorias = this.validators.comboForm();
  estatus = this.validators.comboForm();

  ngOnInit() {
    this.retornarProductosTienda()
  }

  retornarProductosTienda() {
    this.intento = true;
    this.retornaFechaActual()
    this.npg = this.pageIndex + 1;
    let envio = this.retornarEnvio()
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.arrProductos = data.tpro0
      if (this.arrProductos.length > 0) {
        this.length = this.arrProductos.length
        if (data.tpro0.length == this.nrg) {
          this.popUltimo = this.arrProductos.pop();
        }
        this.intentoSiguiente = (data.tpro0.length < (this.nrg - 1)) ? true : false;
        this.datosProductos = new MatTableDataSource(this.arrProductos);
        this.datosProductos.paginator = this.paginator;
        this.ctienda = this.arrProductos[0]['tpro0.CTIENDA']
        this.carac1 = data.ttce0[0]['ttce0.DESCRIPCION']
        this.carac2 = data.ttce0[1]['ttce0.DESCRIPCION']
        this.cargarTabla = true
      }
      if (this.arrProductos.length <= 0) {
        this.cargarTabla = false
      }
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.arrProductos,
        'carac1': this.carac1,
        'carac2': this.carac2
      }
      this.basedatos.insertarConsulta('consultaProductos' + this.indexCategoria + this.estatusId, consulta)
      this.cargarDatos = true
      this.intento = false
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        let ObjConsulta = this.basedatos.retornarConsulta('consultaProductos' + this.indexCategoria + this.estatusId)
        if (ObjConsulta) {
          this.intento = false
          if (ObjConsulta.data.length == 0) {
            this.cargarTabla = false
          } else {
            this.arrProductos = []
            this.carac1 = ObjConsulta.carac1
            this.carac2 = ObjConsulta.carac2
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.arrProductos = ObjConsulta.data
            for (var i = 0; i < this.nrg; i++) {
              if (this.arrProductos[i])
                this.arrProductos[i] = this.arrProductos[i]
            }
            this.dataProductos = this.arrProductos
            this.intentoSiguiente = (ObjConsulta.data < (this.nrg - 1)) ? true : false;
            this.datosProductos = new MatTableDataSource(this.arrProductos);
            this.datosProductos.paginator = this.paginator;
            this.length = this.arrProductos.length
            this.cargarTabla = true
            this.cargarDatos = true
          }
        } else {
          this.intento = false
          this.cargarTabla = false
        }
      }
    });
  }

  //FUNCION CON EVENTO PARA AVANZAR A LA SIGUIENTE PAGINA DE LA TABLA
  siguienteConsultaTabla(e?: PageEvent) {
    this.pageIndex = e.pageIndex;
    if (this.intentoSiguiente == false) {
      this.consultarSiguienteRetornarProductosTienda();
    }
  }

  consultarSiguienteRetornarProductosTienda() {
    this.intento = true;
    this.arrProductos.push(this.popUltimo)
    this.npg = this.pageIndex + 1;
    let envio = this.retornarEnvio()
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.arrProductos = this.arrProductos.concat(data.tpro0);
      this.length = this.arrProductos.length;
      if (data.tpro0.length < this.nrg) {
        this.intentoSiguiente = (data.tpro0.length < (this.nrg - 1)) ? true : false;
        this.datosProductos = new MatTableDataSource(this.arrProductos);
        this.datosProductos.paginator = this.paginator;
      } else {
        this.popUltimo = this.arrProductos.pop();
        this.intentoSiguiente = (data.tpro0.length < (this.nrg - 1)) ? true : false;
        this.datosProductos = new MatTableDataSource(this.arrProductos);
        this.datosProductos.paginator = this.paginator;
      }
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.arrProductos,
        'carac1': this.carac1,
        'carac2': this.carac2
      }
      this.basedatos.insertarConsulta('consultaProductos' + this.indexCategoria + this.estatusId, consulta)
      this.intento = false
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        let ObjConsulta = this.basedatos.retornarConsulta('consultaProductos' + this.indexCategoria + this.estatusId)
        if (ObjConsulta) {
          this.intento = false
          if (ObjConsulta.data.length == 0) {
            this.cargarTabla = false
          } else {
            this.arrProductos = []
            this.arrProductos = ObjConsulta.data
            this.dataProductos = this.arrProductos
            if (ObjConsulta.data.length < this.nrg) {
              this.intentoSiguiente = (ObjConsulta.data.length < (this.nrg - 1)) ? true : false;
              this.datosProductos = new MatTableDataSource(this.arrProductos);
              this.datosProductos.paginator = this.paginator;
            } else {
              this.intentoSiguiente = (ObjConsulta.data.length < (this.nrg - 1)) ? true : false;
              this.datosProductos = new MatTableDataSource(this.arrProductos);
              this.datosProductos.paginator = this.paginator;
            }
            this.cargarTabla = true
            this.cargarDatos = true
          }
        } else {
          this.intento = false
          this.cargarTabla = false
        }
      }
    });
  }

  retornaFechaActual() {
    var d = new Date();
    d.setDate(d.getDate() - 2);
    var dd = String(d.getDate()).padStart(2, '0');
    var mm = String(d.getMonth() + 1).padStart(2, '0');
    var yyyy = d.getFullYear();
    this.fdesde = yyyy + "-" + mm + "-" + dd;
  }

  retornarEnvio() {
    let envio = {
      transaccion: '183036-con-admin-productos.xml',
      "npg^tpro0": this.npg.toString(),
      "nrg^tpro0": this.nrg.toString(),
      "fullNames": true
    }

    envio["cri^ttet0^CPERSONA^NORMAL^tpro0^="] = this.basedatos.cpersona

    if (this.intentoEstatusProducto) {
      envio["cri^tpro0^DISPONIBLE^NORMAL^tpro0^="] = this.estatus.value
      envio["cri^tpro0^CCATEGORIAECOMM_1^NORMAL^tpro0^="] = this.categorias.value
    }

    if (this.intentoCategoriaProductos) {
      envio["cri^tpro0^CCATEGORIAECOMM_1^NORMAL^tpro0^="] = this.categorias.value
      envio["cri^tpro0^DISPONIBLE^NORMAL^tpro0^="] = this.estatus.value
    }

    if (this.intentoNombreProducto) {
      envio["cri^tpro0^NOMBRE^NORMAL^tpro0^LIKE"] = "%" + this.identificador.value + "%"
    }

    return envio
  }

  cambioCateogria() {
    this.pageIndex = 0
    this.intentoCategoriaProductos = true
    this.intentoEstatusProducto = false
    this.intentoNombreProducto = false
    for(var i = 0; i<this.datosCategoria.length; i++){
      if(this.categorias.value == this.datosCategoria[i]['tcat1.CCATEGORIAECOMM']){
        this.indexCategoria = i + 1
      }
    }
    this.retornarProductosTienda()
  }

  cambioEstatusProductos() {
    this.pageIndex = 0
    this.intentoEstatusProducto = true
    this.intentoCategoriaProductos = false
    this.intentoNombreProducto = false
    this.estatusId = this.estatus.value == '0' ? 'A' : 'D'
    this.retornarProductosTienda()
  }


  cambioNombreProducto(filterValue: any) {
    this.pageIndex = 0
    this.intentoNombreProducto = true
    this.intentoEstatusProducto = false
    this.intentoCategoriaProductos = false
    if(this.internet.internet){
      this.retornarProductosTienda()
    } else {
      if (filterValue != 0) {
        this.arrProductos = this.dataProductos.filter((producto) => producto['tpro0.NOMBRE'].indexOf(filterValue) > -1)
        if (this.arrProductos.length == 0) {
          console.log("No existe dicho documento")
        } else {
          this.datosProductos = new MatTableDataSource(this.arrProductos)
        }
      } else {
        this.datosProductos = new MatTableDataSource(this.dataProductos)
      }
    }
  }


  retornarCategorias() {
    let envio = {
      transaccion: '010003-lv-categoriasP.xml',
      alias: "tcat0",
      "cri^tcat0^CTIENDA^NORMAL^tcat0^=": this.ctienda,
      "fullNames": true
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.datosCategoria = [];
      this.datosCategoria = !this.datosCategoria ? [] : data.tcat0;
      const consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.datosCategoria,
      };
      this.basedatos.insertarConsulta('CategoriasProd', consulta);
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        this.retornarCategoriasOffline()
        if (!this.datosCategoria) {
          this.datosCategoria = []
        }
      }
    });
  }

  retornarCategoriasOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CategoriasProd');
    if (ObjConsulta) {
      this.datosCategoria = [];
      this.datosCategoria = ObjConsulta.data;
    }
  }

  funcionConsultaEstatus(e) {
    if (!this.datosCategoria) {
      this.retornarCategorias()
    }
  }


  addNew() {
    this.alerta.generarDialogoAdminProduct(this.idiomas, this.arrProductos, 0, this.carac1, this.carac2, this.ctienda)
  }

  editStore() {
    this.alerta.generarDialogoAdmiEditStore(this.idiomas, 0, this.ctienda)
  }

  mostrarDescripcion(value) {
    this.alerta.generarDialogoAdmiEditStore(this.idiomas, 1, value)
  }

  editProduct(value) {
    for (var producto of this.arrProductos) {
      if (producto['tpro0.CPRODUCTOECOMM'] == value) {
        this.alerta.generarDialogoAdminProduct(this.idiomas, producto, 1, this.carac1, this.carac2, this.ctienda)
      }
    }
  }

  removeProduct(value) {
    for (var producto of this.arrProductos) {
      if (producto['tpro0.CPRODUCTOECOMM'] == value) {
        this.alerta.generarDialogoAdminProduct(this.idiomas, producto, 2, this.carac1, this.carac2, this.ctienda)
      }
    }
  }

  refrescartabla() {
    this.arrProductos = []
    this.intentoEstatusProducto = false
    this.intentoCategoriaProductos = false
    this.intentoNombreProducto = false
    this.length = 0
    this.pageIndex = 0
    this.indexCategoria = 0
    this.estatusId = ''
    this.identificador.reset()
    this.categorias.reset()
    this.estatus.reset()
    this.retornarProductosTienda()
  }

  showHideColumns(last) {
    if (last) {
      try {
        this.funcionesTabla.DivHeight1(this.arrProductos)
        let classContainer1Width = document.getElementsByClassName('container3Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('tablaProducto')[0].setAttribute('style', 'width: 1080px')
          if (1080 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaProducto')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('tablaProducto')[0].setAttribute('style', 'width: 950px')
          if (950 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaProducto')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('tablaProducto')[0].setAttribute('style', 'width: 850px; font-size: 14px')
          if (850 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaProducto')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }


  scroll() {
    let container2Aux = document.getElementsByClassName('container3Aux')
    var tabla = document.getElementsByClassName('tablaProducto')
    if (container2Aux.item(0).scrollLeft != 0) {
      if (container2Aux[0].scrollLeft + 1 >= (tabla[0].clientWidth - container2Aux[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    let container2Aux = document.getElementsByClassName('container3Aux')
    if (direccion == 'right') {
      container2Aux.item(0).scrollLeft = tabla[0].clientWidth - container2Aux[0].clientWidth
    } else {
      container2Aux.item(0).scrollLeft = 0
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

}