import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Chart, registerables, PieController, ArcElement } from 'chart.js';
import * as Chartjs from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import callbacks from 'chartjs-plugin-datalabels';
import { Tooltip } from 'chart.js';
import { Legend } from 'chart.js';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import * as Highcharts from 'highcharts';



declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

import Histogram from 'highcharts/modules/histogram-bellcurve';
Histogram(Highcharts);

import highcharts3D from 'highcharts/highcharts-3d';
highcharts3D(Highcharts);

const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);

const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, CommonModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-grafico-posicion-consolidada',
  templateUrl: './grafico-posicion-consolidada.component.html',
  styleUrls: ['./grafico-posicion-consolidada.component.css']
})
export class GraficoPosicionConsolidadaComponent implements OnInit {

  @Input() idiomas: any;
  @Input() data: any;
  @Input() colorsArrayChart: any;

  chart: any;
  labelsArray: any;
  totalsArray: any;
  colorsArray: any;
  acumAux: number;
  tema: string;
  localeIdioma: string;
  temaPrev: string;
  localeIdiomaPrev: string;
  bandChangeChart: boolean;
  bandHorizontal: boolean;
  bandHorizontalPrev: boolean;
  options: any;
  acumAuxAh: number = 0;
  acumAuxPol: number = 0;
  acumAuxPre: number = 0;

  constructor() {
    this.totalsArray = []
    this.labelsArray = []
    this.colorsArray = []
    this.tema = localStorage.getItem('themeDisplayed');
    this.localeIdioma = localStorage.getItem('locale');
    this.temaPrev = this.tema;
    this.localeIdiomaPrev = this.localeIdioma
    this.bandChangeChart = false;
    if (window.orientation == 90 || window.orientation == -90)
      this.bandHorizontal = true;
    else
      this.bandHorizontal = false;
    this.bandHorizontalPrev = this.bandHorizontal;

  }

  @HostListener('window:orientationchange', ['$event']) //Evento que permite determinar cuando cambia orientacion de pantalla
  onOrientationchange(event) {
    if (window.orientation == 90 || window.orientation == -90) //Pantalla en horizontal
      this.bandHorizontal = true;
    else
      this.bandHorizontal = false;

    try {
      this.bandChangeChart = (document.getElementById('posiConso')) ? true : false;
    } catch (error) {
      this.bandChangeChart = false;
    }
    if (this.bandChangeChart) {
      this.chart.destroy();
      this.drawChart();
    }
  }

  ngDoCheck() {
    this.tema = localStorage.getItem('themeDisplayed');
    this.localeIdioma = localStorage.getItem('locale');
    if ((this.tema != this.temaPrev)) {
      try {
        this.bandChangeChart = (document.getElementById('posiConso')) ? true : false;
      } catch (error) {
        this.bandChangeChart = false;
      }
      if (this.bandChangeChart) {
        this.chart.destroy();
        this.temaPrev = this.tema;
        this.drawChart();
      }
    }
    if (this.localeIdioma != this.localeIdiomaPrev) {
      try {
        this.bandChangeChart = (document.getElementById('posiConso')) ? true : false;
      } catch (error) {
        this.bandChangeChart = false;
      }
      if (this.bandChangeChart) {
        this.chart.destroy();
        this.localeIdiomaPrev = this.localeIdioma;
        this.labelsArray = [];
        this.totalsArray = [];
        this.colorsArray = [];
        for (let prods of this.data) {
          this.acumAux = 0;
          for (let prod of prods) {
            if (prod.subsistema == "04") {
              this.acumAux = this.acumAux + Number.parseFloat(prod.accounting)
              this.acumAuxAh = parseFloat(this.acumAux.toFixed(2))
            }
            if (prod.subsistema == "05") {
              this.acumAux = this.acumAux + Number.parseFloat(prod.monto)
              this.acumAuxPol = parseFloat(this.acumAux.toFixed(2))
            }
            if (prod.subsistema == "06") {
              this.acumAux = this.acumAux + Number.parseFloat(prod.actualBal)
              this.acumAuxPre = parseFloat(this.acumAux.toFixed(2))
            }
          }
          if (this.acumAux != 0) {
            this.totalsArray.push(this.acumAux)
            if (prods[0].subsistema == "04") {
              this.labelsArray.push(this.idiomas.CtasVistaG)
              let colorAux = (this.tema == 'light-theme-azul') ? this.colorsArrayChart[0] : this.colorsArrayChart[0]
              this.colorsArray.push(colorAux)
            }
            if (prods[0].subsistema == "05") {
              this.labelsArray.push(this.idiomas.CtasPlazoG)
              let colorAux = (this.tema == 'light-theme-azul') ? this.colorsArrayChart[1] : this.colorsArrayChart[1]
              this.colorsArray.push(colorAux)
            }
            if (prods[0].subsistema == "06") {
              this.labelsArray.push(this.idiomas.CtasPrestamosG)
              this.colorsArray.push(this.colorsArrayChart[2])
            }
          }
        }
        this.drawChart();
      }
    }
    if ((this.bandHorizontalPrev != this.bandHorizontal)) {
      try {
        this.bandChangeChart = (document.getElementById('posiConso')) ? true : false;
      } catch (error) {
        this.bandChangeChart = false;
      }
      if (this.bandChangeChart) {
        this.chart.destroy();
        this.bandHorizontalPrev = this.bandHorizontal;
        this.drawChart();
      }
    }
  }

  ngOnInit() {
    for (let prods of this.data) {
      this.acumAux = 0;
      for (let prod of prods) {
        if (prod.subsistema == "04") {
          this.acumAux = this.acumAux + Number.parseFloat(prod.accounting)
          this.acumAuxAh = parseFloat(this.acumAux.toFixed(2))
        }
        if (prod.subsistema == "05") {
          this.acumAux = this.acumAux + Number.parseFloat(prod.monto)
          this.acumAuxPol = parseFloat(this.acumAux.toFixed(2))
        }
        if (prod.subsistema == "06") {
          this.acumAux = this.acumAux + Number.parseFloat(prod.actualBal)
          this.acumAuxPre = parseFloat(this.acumAux.toFixed(2))
        }
      }
      if (this.acumAux != 0) {
        this.totalsArray.push(this.acumAux)
        if (prods[0].subsistema == "04") {
          this.labelsArray.push(this.idiomas.CtasVistaG)
          let colorAux = (this.tema == 'light-theme-azul') ? this.colorsArrayChart[0] : this.colorsArrayChart[0]
          this.colorsArray.push(colorAux)
        }
        if (prods[0].subsistema == "05") {
          this.labelsArray.push(this.idiomas.CtasPlazoG)
          let colorAux = (this.tema == 'light-theme-azul') ? this.colorsArrayChart[1] : this.colorsArrayChart[1]
          this.colorsArray.push(colorAux)
        }
        if (prods[0].subsistema == "06") {
          this.labelsArray.push(this.idiomas.CtasPrestamosG)
          this.colorsArray.push(this.colorsArrayChart[2])
        }
      }
    }

  }

  ngAfterViewInit() {
    this.drawChart()
  }

  getColorPorTema() {
    return (this.tema == 'night-theme') ? 'white' : 'black';
  }

  drawChart() {
    Chart.register(ChartDataLabels, PieController, ArcElement, Tooltip, callbacks, Legend);
    //Se reajustan colores de grafico
    this.colorsArray = []
    this.labelsArray.filter(x => {
      if (x == this.idiomas.CtasVistaG) {
        let colorAux = (this.tema == 'light-theme-azul') ? this.colorsArrayChart[0] : this.colorsArrayChart[0]
        this.colorsArray.push(colorAux)
      }
      if (x == this.idiomas.CtasPlazoG) {
        let colorAux = (this.tema == 'light-theme-azul') ? this.colorsArrayChart[1] : this.colorsArrayChart[1]
        this.colorsArray.push(colorAux)
      }
      if (x == this.idiomas.CtasPrestamosG) {
        this.colorsArray.push(this.colorsArrayChart[2])
      }
    })
    //
    //***********************/ChartsJS
    if (this.data[0]) {

      this.options = {
        chart: {
          type: 'pie',
          backgroundColor: 'transparent',
          spacingTop: 0,
          spacingBottom: 0,
          marginRight: 0,
          marginLeft: 0,
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          }
        },
        title: {
          text: ''
        },
        accessibility: {
          point: {
            valueSuffix: '$'
          }
        },
        tooltip: {
          pointFormat: '{series.name}: <b>${point.y}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: this.colorsArray,
            depth: 35,
            dataLabels: {
              enabled: true,
              format: '{point.name}'
            }
          }
        },
        credits: {
          enabled: false
        },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        },
        series: [{
          type: 'pie',
          name: this.idiomas.Monto,
          data: this.acumAuxAh > 0 && this.acumAuxPol > 0 && this.acumAuxPre > 0 ?
            [["Ahorros", this.acumAuxAh], ["Polizas", this.acumAuxPol], ["Prestamos", this.acumAuxPre]] :
            this.acumAuxAh > 0 && this.acumAuxPol > 0 && this.acumAuxPre < 1 ?
              [["Ahorros", this.acumAuxAh], ["Polizas", this.acumAuxPol]] :
              this.acumAuxAh > 0 && this.acumAuxPol < 1 && this.acumAuxPre < 1 ?
                [["Ahorros", this.acumAuxAh]] :
                this.acumAuxAh > 0 && this.acumAuxPol < 1 && this.acumAuxPre > 0 ?
                  [["Ahorros", this.acumAuxAh], ["Prestamos", this.acumAuxPre]] : [["Ahorros", this.acumAuxAh]]
        }]
      };

      Highcharts.chart('container', this.options);

    }
  }
}
