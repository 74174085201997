<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch" id="tableDiv" style="padding-bottom: 60px;">
  <mat-card class="cards-transations" *ngIf="bandsFuncion.consultaServRec && !bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.ConServPagados | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding:16px">
      <div class="form-row">
        <mat-form-field class="col-4" style="min-width: 145px !important" appearance="outline">
          <mat-label>{{idiomas.Cuenta}}</mat-label>
          <mat-select [formControl]="cuentaForm" (selectionChange)="limpiar()">
            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.CCUENTA">
              {{cuenta.CCUENTA | ocultarCuentaPosTrans}} {{cuenta.CMONEDA}} {{cuenta.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="cuentaForm.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="internet.internet || band1" class="col-4" [class.dategrande]="grande"
          appearance="outline" style="font-size: .85em; min-width: 145px">
          <mat-label>{{idiomas.FDesde}}</mat-label>
          <input matInput readonly [ngModel]="dated | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
            [ngModelOptions]="{standalone: true}" [disabled]="!internet.internet">
          <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="dated" [max]="maxDate"
            [matDatepicker]="pickerdesde" [disabled]="!internet.internet">
          <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
          <mat-datepicker touchUi #pickerdesde></mat-datepicker>
        </mat-form-field>
        <mat-form-field id="lastelement" *ngIf="internet.internet || band1" class="col-4" [class.dategrande]="grande"
          appearance="outline" style="font-size: .85em; min-width: 145px">
          <mat-label>{{idiomas.FHasta}}</mat-label>
          <input matInput readonly [ngModel]="dateh | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
            [ngModelOptions]="{standalone: true}" [disabled]="!internet.internet">
          <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="dateh" [max]="maxDate"
            [matDatepicker]="pickerhasta" [disabled]="!internet.internet">
          <mat-datepicker-toggle matSuffix [for]="pickerhasta"></mat-datepicker-toggle>
          <mat-datepicker touchUi #pickerhasta></mat-datepicker>
        </mat-form-field>
      </div>
      <div *ngIf="bandData && !bandMobile">
        <div class="col">
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha" style="margin-right: 4px;">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tablaServiciosP','left')">
                keyboard_arrow_left</mat-icon>
            </div>
            <div class="col container2Aux" [class.heightconteiner2]="!(datos2.length>numMaxRow)"
              (scroll)="scroll('tablaServiciosP')">
              <table mat-table [dataSource]="datos2" class="tablaServiciosP">
                <ng-container matColumnDef="F.Trasaccion">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Fecha}}</th>
                  <td mat-cell *matCellDef="let dato" style="max-width: 100px !important;">
                    {{dato.FREAL | formatoFechaTranferServ:idiomas.IdiomaSeleccionado}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Empresa">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Empresa}}</th>
                  <td mat-cell *matCellDef="let dato" style="max-width: 100px !important;">
                    {{dato.DESCRIPCION}} </td>
                </ng-container>
                <ng-container matColumnDef="Valor">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Valor}}</th>
                  <td mat-cell *matCellDef="let dato">
                    {{dato.VALOR | cantidadMonetaria}}
                </ng-container>
                <ng-container matColumnDef="RepetirTransfer">
                  <th mat-header-cell *matHeaderCellDef style="text-align:center;">{{idiomas.Reenviar}}</th>
                  <td mat-cell *matCellDef="let dato" style="text-align:center;  max-width: 75px !important">
                    <mat-icon *ngIf="dato.NUMEROMENSAJE" title={{idiomas.Reenviar}} (click)="resendTransfer(dato)"
                      style="cursor: pointer !important;">autorenew</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Imprimir">
                  <th mat-header-cell *matHeaderCellDef style="text-align:center;">
                    {{idiomas.DetalleTransferencia}}</th>
                  <td mat-cell *matCellDef="let dato" style="text-align:center;">
                    <mat-icon title={{idiomas.DetalleTransferencia}} style="cursor: pointer !important;"
                      (click)="mostrarDialogo(dato)">
                      library_books</mat-icon>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumns;"
                  [ngClass]="{gray: even}">
                  {{showHideRows(last)}}</tr>
              </table>
            </div>
            <div class="col flecha" style="margin-left: 4px;">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tablaServiciosP','right')">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div id="floatCont" class="col-6">
          <button mat-raised-button (click)="consultarPago()" title={{idiomas.Aceptar}} *ngIf="cuentaForm.valid"
            color="primary" class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
        <div id="floatCont" align="end" style="text-align: end" class="col-6">
          <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
            <mat-icon> clear_all</mat-icon>
            {{idiomas.Limpiar}}
          </button>
        </div>
      </div>
      <div *ngIf=intento>
        <br>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </mat-card>
  <div style="padding-left: 16px; padding-right: 16px;"
    *ngIf="bandMobile && (bandsFuncion.pagoServ || bandsFuncion.recargas)">
    <button mat-raised-button (click)="habilitarFunciones('consultaServRec',true)" title={{idiomas.ConServPagados}}
      style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;" color="primary"
      class="buttonBene">
      <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
      <strong>{{idiomas.ConServPagados}}</strong>
    </button>
  </div>
  <div *ngIf="bandsFuncion.consultaServRec && bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.ConServPagados | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding-top:16px">
      <div class="form-row">
        <mat-form-field class="col-4" style="min-width: 145px !important" appearance="outline">
          <mat-label>{{idiomas.Cuenta}}</mat-label>
          <mat-select [formControl]="cuentaForm" (selectionChange)="limpiar()">
            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.CCUENTA">
              {{cuenta.CCUENTA | ocultarCuentaPosTrans}} {{cuenta.CMONEDA}} {{cuenta.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="cuentaForm.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="internet.internet || band1" class="col-4" [class.dategrande]="grande"
          appearance="outline" style="font-size: .85em; min-width: 145px">
          <mat-label>{{idiomas.FDesde}}</mat-label>
          <input matInput readonly [ngModel]="dated | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
            [ngModelOptions]="{standalone: true}" [disabled]="!internet.internet">
          <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="dated" [max]="maxDate"
            [matDatepicker]="pickerdesde" [disabled]="!internet.internet">
          <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
          <mat-datepicker touchUi #pickerdesde></mat-datepicker>
        </mat-form-field>
        <mat-form-field id="lastelement" *ngIf="internet.internet || band1" class="col-4" [class.dategrande]="grande"
          appearance="outline" style="font-size: .85em; min-width: 145px">
          <mat-label>{{idiomas.FHasta}}</mat-label>
          <input matInput readonly [ngModel]="dateh | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
            [ngModelOptions]="{standalone: true}" [disabled]="!internet.internet">
          <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="dateh" [max]="maxDate"
            [matDatepicker]="pickerhasta" [disabled]="!internet.internet">
          <mat-datepicker-toggle matSuffix [for]="pickerhasta"></mat-datepicker-toggle>
          <mat-datepicker touchUi #pickerhasta></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 container-card-mobile" *ngIf="bandData && bandMobile"
        [class.heightconteiner2]="!(datos2.length>numMaxRow)">
        <mat-list>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let dato of datos2; let even = even;">
            <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
              <div class="col-8">
                <div mat-line style="font-weight: bold; font-size: .9em;">{{dato.FREAL |
                  formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                <div mat-line>
                  <div style="font-size: .8em; white-space: initial;">{{dato.DESCRIPCION | uppercase}}</div>
                </div>
              </div>
              <div class="col-4" style="text-align: end;">
                <div style="font-size: .9em;" class="color-letras-cartas">
                  {{dato.VALOR | cantidadMonetaria}}</div>
              </div>
              <div class="col-6" style="font-weight: bold; font-size: .9em;"><a
                  style="padding-right: 15px;">{{idiomas.Reenviar}}</a> <mat-icon *ngIf="dato.NUMEROMENSAJE"
                  title={{idiomas.Reenviar}} (click)="resendTransfer(dato)"
                  style="cursor: pointer !important; vertical-align: text-bottom;">autorenew
                </mat-icon>
              </div>
              <div class="col-6" style="font-weight: bold; font-size: .9em; text-align: end;"><a
                  style="padding-right: 15px;">{{idiomas.DetalleTransferencia}}</a>
                <mat-icon title={{idiomas.DetalleTransferencia}}
                  style="cursor: pointer !important; vertical-align: text-bottom;" (click)="mostrarDialogo(dato, i)">
                  library_books</mat-icon>
              </div>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="row">
        <div id="floatCont" class="col-6">
          <button mat-raised-button (click)="consultarPago()" title={{idiomas.Aceptar}} *ngIf="cuentaForm.valid"
            color="primary" class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
        <div id="floatCont" align="end" style="text-align: end" class="col-6">
          <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
            <mat-icon> clear_all</mat-icon>
            {{idiomas.Limpiar}}
          </button>
        </div>
      </div>
      <div *ngIf=intento>
        <br>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
  <div *ngIf="!bandsFuncion.habilitarMenu">
    <div *ngIf="bandsFuncion.pagoServ">
      <app-pago-servicios #pagoServ (regServEv)="registrarServicio($event)" [mediano]="mediano" [grande]="grande"
        [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend" [nomServ]="nomServ" [bandServReg]="false"
        [resendPay]="resendPay">
      </app-pago-servicios>
    </div>

    <div *ngIf="bandsFuncion.recargas">
      <app-recargas #recargas [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
        [pendingTaskData]="dataTarPend" [resendPay]="resendPay"></app-recargas>
    </div>
    <div *ngIf="!bandMobile && (bandsFuncion.pagoServ || bandsFuncion.recargas)" class="float-transacciones">
      <button mat-fab (click)="habilitarFunciones('consultaServRec',true)" title={{idiomas.Regresar}}
        class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
  </div>
</div>