import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { BaseDatos } from "../funciones/basededatos";
import { ApiService } from "../api.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatDialogModule, CommonModule, NgxExtendedPdfViewerModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-plazo',
  templateUrl: './dialogo-plazo.component.html',
  styleUrls: ['./dialogo-plazo.component.css']
})
export class DialogoPlazoComponent {

  er: boolean = false;
  mensaje: string;
  aceptar: string;
  rechazar: string;
  idioma: any;
  tipo: string
  band: boolean;
  imagenes: any;
  mostrar: boolean = false;
  mostrarTexto: boolean = false;
  mostrarPdfBase64: boolean = false;
  dataLocalUrl: any
  innerWidth: any
  innerHeight: any
  dato: any;
  bandMobile: boolean;

  constructor(private dialogRef: MatDialogRef<DialogoPlazoComponent>, @Inject(MAT_DIALOG_DATA) data,
    private api: ApiService, private _DomSanitizer: DomSanitizer,
    public baseDatos: BaseDatos) {
    this.dato = data.mensaje;
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.idioma = data.idm1;
    this.band = data.band;
    this.tipo = data.tipo;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true
    }

    if (window.innerWidth < 834) {
      this.bandMobile = true
    } else {
      this.bandMobile = false
    }
  }

  ngOnInit() {
    if (this.tipo == 'mostrar') {
      this.mostrarTexto = false;
      this.mostrarPdfBase64 = false;
      this.mostrar = true;
    } else if (this.tipo == 'mostrarPdfBase64') {
      this.mostrar = false;
      this.mostrarTexto = false;
      this.mostrarPdfBase64 = true;
    } else {
      this.mostrar = false;
      this.mostrarPdfBase64 = false;
      this.mostrarTexto = true;
    }
  }

  accept() {
    this.dialogRef.close(this.er = true);
  }

  close() {
    this.dialogRef.close(this.er);
  }

}
