import { Component, Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BaseDatos } from '../funciones/basededatos';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, CambiarVariableCuenta, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatCardModule, MatProgressBarModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, CambiarVariableCuenta, OcultarCuentaPosTrans, FlexLayoutModule],
  selector: 'app-dialogo-continuar-proceso-beneficiario',
  templateUrl: './dialogo-continuar-proceso-beneficiario.component.html',
  styleUrls: ['./dialogo-continuar-proceso-beneficiario.component.css']
})
export class DialogoContinuarProcesoBeneficiarioComponent {

  mensaje: string;
  aceptar: string;
  rechazar: string;

  idiomas: any;

  er: boolean = false;
  intento: boolean;

  constructor(public apiService: ApiService, private dialogRef: MatDialogRef<DialogoContinuarProcesoBeneficiarioComponent>, @Inject(MAT_DIALOG_DATA) data, public basedatos:
  BaseDatos) {
  this.aceptar = data.idm1.Aceptar;
  this.rechazar = data.idm1.Rechazar;
  this.idiomas = data.idm1;
  this.mensaje = data.message;
}
accept() {
    this.dialogRef.close(this.er = true);
}

close() {
  this.dialogRef.close(this.er = false);
}

}
