import CryptoJS from "crypto-js";
import { Injectable } from '@angular/core';

@Injectable()
export class Servicios {
    
    tempString: any;
    tempString2: any;

    constructor() {
        this.tempString = ["MTAwMDExMA==", "MTAwMTAwMQ==", "MTAxMDEwMA==", "MTAxMTAx", "MTEwMDEw", "MTEwMDAw", "MTEwMDAw", "MTExMDAw"]
        this.tempString2 = ["MTExMDAxMQ==", "MTEwMDEwMQ==", "MTEwMDAxMQ==", "MTExMDAxMA==", "MTEwMDEwMQ==", "MTExMDEwMA==", "MTEwMTExMQ=="]
    }

    toAES(pass: string) {
        let passw = pass.replace("\"", "");
        let hash = CryptoJS.AES.encrypt(passw, this.getStringTemp(this.tempString)).toString();
        let e64 = CryptoJS.enc.Base64.parse(hash);
        let final = e64.toString(CryptoJS.enc.Hex);
        return final;
    }

    credentialsHash(str: string) {
        return CryptoJS.SHA256(str);
    }

    getTempString(str: string) {
        let retString = '"'
        for (let i = 0; i < str.length; i++) {
            let rawStr = str[i].charCodeAt(0).toString(2)
            let wordArray = CryptoJS.enc.Utf8.parse(rawStr.toString());
            let base64 = CryptoJS.enc.Base64.stringify(wordArray);
            retString += base64 + '"-"';
        }
        let arrayTemp = retString.split("-")
        arrayTemp.pop()
    }

    getStringTemp(arr: any) {
        let retString = ""
        for (let i = 0; i < arr.length; i++) {
            var parsedWordArray = CryptoJS.enc.Base64.parse(arr[i].toString());
            var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
            retString += String.fromCharCode(parseInt(parsedStr, 2));
        }
        return retString;
    }

    ReqBodyToAES(bodyRq: string) {
        let hash = CryptoJS.AES.encrypt(bodyRq, this.getStringTemp(this.tempString)).toString();
        let e64 = CryptoJS.enc.Base64.parse(hash);
        let final = e64.toString(CryptoJS.enc.Hex);
        return final;
    }

    ResBodyAEStoString(bodyRsHex: string) {
        var reb64 = CryptoJS.enc.Hex.parse(bodyRsHex);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, this.getStringTemp(this.tempString));
        var plain = decrypt.toString(CryptoJS.enc.Utf8);
        return plain;
    }

    encriptar(pass: string) {
        if (pass) {
            var ciphertext = CryptoJS.AES.encrypt(pass, this.getStringTemp(this.tempString2));
            return ciphertext;
        } else {
            return null;
        }
    }

    desencriptar(pass: string) {
        if (pass) {
            var bytes = CryptoJS.AES.decrypt(pass.toString(), this.getStringTemp(this.tempString2));
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            return plaintext;
        }
        else {
            return null;
        }
    }

    decryptAEStoString(bodyRsHex: string) {
        var reb64 = CryptoJS.enc.Hex.parse(bodyRsHex);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, this.getStringTemp(this.tempString));
        var plain = decrypt.toString(CryptoJS.enc.Utf8);
        return plain;
    }
}