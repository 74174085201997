import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component'
import { HomeComponent } from './home/home.component';
import { InicioComponent } from './inicio/inicio.component';
import { HomeEcommerceComponent } from './home-ecommerce/home-ecommerce.component';
import { ValidarCertificadoComponent } from './validar-certificado/validar-certificado.component';

//En este archivo estan todos las paginas que usamos y como nos direccionamos

const routes: Routes = [
  { path: '', component: LoginComponent },
  {//Ruta para enviar identificacion automatica despues de verificar correo y aceptar contrato
    path: 'verifyContract/:idType/:id/:email/:celular',
    component: LoginComponent
  },
  { path: 'inicio', component: InicioComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: '#', component: LoginComponent },
  { path: 'validarCertificados', component: ValidarCertificadoComponent},
  { path: 'ECommerce/:tabName', component: HomeEcommerceComponent },
];

@NgModule({
  imports: [
    CommonModule,
    [RouterModule.forRoot(routes, { useHash: true })]
  ],
  declarations: [],
  exports: [RouterModule]
})

export class AppRoutingModule {

}

export const routingComponents = [LoginComponent, InicioComponent, HomeComponent
]