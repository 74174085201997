import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-transacciones-error',
  templateUrl: './dialogo-transacciones-error.component.html',
  styleUrls: ['./dialogo-transacciones-error.component.css']
})
export class DialogoTransaccionesErrorComponent {
  mensaje: string;
  aceptar: string;
  rechazar: string;
  idiomas: any;
  er: boolean = false;
  isBanred: boolean = false;

  constructor(public apiService: ApiService, private dialogRef: MatDialogRef<DialogoTransaccionesErrorComponent>, @Inject(MAT_DIALOG_DATA) data, public basedatos:
    BaseDatos, public mostrarPdf: MostrarPDF) {
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.idiomas = data.idm1;
    this.mensaje = data.mensaje
    this.isBanred = data.isBanred ? data.isBanred : false;
  }

  close() {
    this.dialogRef.close(this.er);
  }

  enviar() {
    this.dialogRef.close(true);
  }

}