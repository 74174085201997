<div class="formBottom" style="padding-top: 0px !important;">
    <mat-progress-bar *ngIf=intento mode="indeterminate"></mat-progress-bar>
    <p *ngIf="bandProductos && !intento" style="text-align: center;">{{parametros.nombre.toUpperCase()}}</p>
    <mat-form-field appearance="outline" fxFlexFill *ngIf="bandProductos && !intento">
        <mat-label>{{idiomas.Consultar | uppercase}}</mat-label>
        <mat-select (selectionChange)="applyFilterTypeProduct($event.value)" [formControl]="categoriaFormP">
            <mat-option *ngFor="let categoria of categoriasProductos; let i=index" [value]="categoria.id">
                {{i + 1}} -- {{categoria.detalle}}
                <mat-divider style="border-top-width: 3px;"></mat-divider>
            </mat-option>
            <mat-option [value]="0">
                Todos los productos
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-card *ngIf="!bandProductos && !intento" class="carta tareas">
        <div class="row">
            <div class="center col texto">
                <h6>
                    <strong>{{parametros.nombre.toUpperCase()}}</strong>
                </h6>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="center col texto">
                <mat-label>
                    {{idiomas.NProductos}}
                </mat-label>
            </div>
        </div>
        <br>
    </mat-card>
    <div class="form-row menu" *ngIf="bandProductos && !intento">
        <div *ngFor="let producto of grupoproductos; let i=index" class="col-3"
            style="min-width: 260px; margin-right: auto; margin-left: auto;">
            <mat-card class="cards" align="center" style="cursor: pointer">
                <p style="margin-bottom: 5px">{{producto.producto}}</p>
                <div class="row" *ngIf="(producto.imagen[0] != '0') && (producto.imagen.length > 1)">
                    <div class="show-image"
                        style="max-width: 260px; min-width: 248px; margin-left: auto; margin-right: auto; display: flex; flex-wrap: wrap;">
                        <img [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + producto.imagen[index[i]])"
                            width="156" height="123" style="margin-bottom: 5px; margin-left: auto; margin-right: auto">
                        <mat-icon class="left" (click)="izquierda(i)" title="Mas Imagenes" color="primary">
                            keyboard_arrow_left</mat-icon>
                        <mat-icon class="right" (click)="derecha(i)" title="Mas Imagenes" color="primary">
                            keyboard_arrow_right</mat-icon>
                    </div>
                </div>
                <div class="row" *ngIf="(producto.imagen[0] != '0') && !(producto.imagen.length > 1)">
                    <img [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + producto.imagen[0])"
                        class="zoom" width="156" height="123"
                        style="margin-bottom: 5px; margin-right: auto; margin-left: auto;" />
                </div>
                <div class="row" *ngIf="(producto.imagen[0] == '0') && !internet.internet">
                    <img [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + imagenOffline)"
                        width="156" height="123" style="margin-bottom: 5px; margin-right: auto; margin-left: auto;" />
                </div>
                <div class="row">
                    <p class="col-8" style="text-align: left; margin-bottom: 5px">{{idiomas.PContado}}</p>
                    <p class="col-4" style="font-size: 1.2rem;text-align: end;margin-bottom: 0px">
                        ${{producto.precioc}}</p>
                </div>
                <div class="row" *ngIf="false">
                    <p class="col-8" style="text-align: left; margin-bottom: 5px" *ngIf="producto.precioc >= 50">12
                        cuotas de</p>
                    <p class="col-4" style="font-size: 1.2rem;text-align: end;margin-bottom: 0px">
                        ${{producto.preciod}}</p>
                </div>
                <p style="text-align: left; margin-bottom: 5px" *ngIf="true">{{idiomas.NCuotas}}
                </p>
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header style="padding-left: 0">
                            <mat-panel-title>
                                {{idiomas.Descripcion}}
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p style="text-align: left">{{producto.descripcion}}</p>
                    </mat-expansion-panel>
                </mat-accordion>
                <div *ngIf="parametros.cpersona != basedatos.cpersona"
                    style="margin-top: 5px;  width: 85px; text-align: left">
                    <button id="btnComprar" class="buttonSubmit" mat-raised-button (click)="selectProducto(producto)" color="primary">
                        {{idiomas.Comprar}}
                    </button>
                </div>
            </mat-card>
            <div style="height: 10px;"></div>
        </div>
    </div>
</div>