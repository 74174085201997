import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from '../api.service';
import { UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule,  MatDialogModule, MatButtonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-esperando',
  templateUrl: './dialogo-esperando.component.html',
  styleUrls: ['./dialogo-esperando.component.css']
})
export class DialogoEsperandoComponent {
  mensaje: string;
  idiomas: any;
  er: boolean = false;

  constructor(public apiService: ApiService, private dialogRef: MatDialogRef<DialogoEsperandoComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.idiomas = data.idm1;
    this.mensaje = data.mensaje;
  }

  accept() {
    this.dialogRef.close(this.er);
  }

  close() {
    this.dialogRef.close(this.er);
  }

}
