import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { Servicios } from '../funciones/encryptar';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-credito',
  templateUrl: './dialogo-credito.component.html',
  styleUrls: ['./dialogo-credito.component.css']
})
export class DialogoCreditoComponent {
  mensaje: string;
  dato: any;
  idioma: any;
  aceptar: string;
  rechazar: string;
  autorizacion: string;
  er: boolean = false;
  mostrarTexto: boolean = false;
  mostrarPdfBase64: boolean = false;
  tipo: string

  constructor(private servicio: Servicios,
    private dialogRef: MatDialogRef<DialogoCreditoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.mensaje = data.mensaje;
    this.dato = data.mensajeData;
    this.idioma = data.idm1;
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.autorizacion = data.idm1.AutorizacionBuro;
    this.tipo = data.tipo;
  }

  ngOnInit() {
    if (this.tipo == 'mostratTexto') {
      this.mostrarTexto = true;
      this.mostrarPdfBase64 = false;
    } else if (this.tipo == 'mostrarPdfBase64') {
      this.mostrarTexto = false;
      this.mostrarPdfBase64 = true;
    } else {
      this.mostrarPdfBase64 = false;
      this.mostrarTexto = true;
    }
  }

  accept() {
    this.dialogRef.close(this.er = true);
    this.er = false;
  }

  acceptDia() {
    this.dialogRef.close(this.er);
  }

  close() {
    this.dialogRef.close(this.er);
  }
}
