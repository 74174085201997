import { Injectable } from "@angular/core";
import { MatBottomSheet, MatBottomSheetConfig } from "@angular/material/bottom-sheet";
import { MenuInferiorComponent } from "../menu-inferior/menu-inferior.component";

@Injectable()
export class FuncionesMenuInferior {

    constructor(private bottomSheet: MatBottomSheet) {
    }

    mostrarMenu(idiomas: any, notificaciones: any, numTareasPendientes: any) {
        return new Promise((resolve) => {
            let bottomSheetConfig = new MatBottomSheetConfig();
            bottomSheetConfig.disableClose = false;
            bottomSheetConfig.autoFocus = false;
            bottomSheetConfig.data = {
                idm: idiomas,
                notificaciones:notificaciones,
                numTareasPendientes:numTareasPendientes
            };
            const bottom = this.bottomSheet.open(MenuInferiorComponent, bottomSheetConfig);
            bottom.afterDismissed().subscribe(data => {
                resolve(data)
            });
        });
    }
}