import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormControlValidators } from '../funciones/formcontrol';
import { Alerta } from '../funciones/alerta';
import { FuncionesTabla } from '../funciones/funcionesTabla';
import { ApiService } from '../api.service';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { DomSanitizer } from '@angular/platform-browser';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { internetComponent } from '../funciones/internet';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, HoraSubString1 } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatTableModule, MatCardModule, MatProgressBarModule, MatButtonModule, MatInputModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFecha, HoraSubString1, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-consulta-cv-realizada',
  templateUrl: './consulta-cv-realizada.component.html',
  styleUrls: ['./consulta-cv-realizada.component.css']
})
export class ConsultaCvRealizadaComponent implements OnInit {
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() idVenta: any;
  @Input() idCompra: any;
  @Input() idiomas: any;
  //@Output() evtResendPurchase = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  numMaxRow: number;
  pdf: string;
  datosEstatus: any[] = [{ id: '1', detalle: 'Pendiente' }, { id: '2', detalle: 'Entregado' }]
  movimientos: any[];
  dataMovimientos: any[];
  displayedColumnsCompras = ['Orden', 'Producto', 'Cantidad', 'Estatus', 'FechaC', 'FechaE', 'Total', 'RepetirTransfer', 'Documento'];
  displayedColumnsVentas = ['Orden', 'Producto', 'Cliente', 'Estatus', 'FechaC', 'FechaE', 'Total', 'Documento', 'confirmacionVenta'];
  flechaR: boolean;
  lmovimientos: any[]
  tabla: any;
  flechaL: boolean;
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number;
  length: number;
  previo: number;
  bool: boolean;
  intento: boolean;
  bandMovimientos: boolean;
  intentoComprobante: boolean;
  fechaActual = new Date();
  fd: string;
  fh: string;
  estatus: string;
  dated = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1);
  datosMovimiento: any;
  intentoSiguiente: boolean;
  intentoSiguienteConsulta: boolean;
  npg: any;
  nrg: number;
  popUltimo: any;
  ord: number = 0;
  lengthlmov: number;

  constructor(private alerta: Alerta, private validators: FormControlValidators, public apiService: ApiService,
    private servicio: Servicios, public mostrarPdf: MostrarPDF, public basedatos: BaseDatos,
    private funcionesTabla: FuncionesTabla, private _DomSanitizer: DomSanitizer, public internet: internetComponent) {
    this.flechaR = false;
    this.flechaL = true;
    this.pageSize = 20;
    this.nrg = 20;
    this.numMaxRow = 5;
    this.movimientos = [];
    this.intento = false;
    this.intentoSiguienteConsulta = false;
    this.bandMovimientos = false;
    this.intentoComprobante = false;
    this.pdf = '';
    this.fd = '';
    this.fh = '';
    this.estatus = '';
    this.dataMovimientos = [];
  }

  estatusForm = this.validators.comboForm()
  productoForm = this.validators.textNumberSpecialFeaturetForm()

  ngOnInit() {
  }


  refrescartabla(value) {
    this.dated = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
    this.fd = ''
    this.estatus = ''
    this.ord = 0
    this.lengthlmov = this.pageSize
    this.length = 0
    this.pageIndex = 0
    this.datosMovimiento = []
    this.estatusForm.reset()
    this.productoForm.reset()
    if (value != 0) {
      this.consultaMovimientos()
    }
  }

  consultaPorEstatus(value: any) {
    this.ord = 0
    this.pageIndex = 0
    this.estatus = value == '1' ? 'P' : 'E'
    this.consultaMovimientos()
  }

  consultaPorNombre(filterValue) {
    this.ord = 0
    this.pageIndex = 0
    if (this.internet.internet) {
      this.consultaMovimientos()
    } else {
      if (filterValue != 0) {
        this.movimientos = this.dataMovimientos.filter((movimiento) => movimiento.producto.indexOf(filterValue) > -1)
        if (this.movimientos.length == 0) {
          console.log("No existe dicho documento")
        } else {
          this.datosMovimiento = new MatTableDataSource(this.movimientos)
        }
      } else {
        this.datosMovimiento = new MatTableDataSource(this.dataMovimientos)
      }
    }
  }

  cambiofechaDesde() {
    this.pageIndex = 0
    this.ord = 0
    this.fd = this.dated.getFullYear() + "-" + this.anadirCero(this.dated.getMonth() + 1) + "-" + this.anadirCero(this.dated.getDate())
    this.fh = this.dated.getFullYear() + "-" + this.anadirCero(this.dated.getMonth() + 1) + "-" + this.anadirCero(this.dated.getDate() + 1)
    this.consultaMovimientos()
  }

  confirmVenta(nMensaje, secuencia) {
    let data = { numMensaje: nMensaje, secuencia: secuencia }
    this.alerta.generarDialogoAdminTienda(this.idiomas, data)
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  consultaMovimientos() {
    this.movimientos = []
    this.datosMovimiento = []
    this.lengthlmov = this.pageSize
    this.length = 0
    this.pageIndex = 0
    this.ord = 0
    this.npg = this.pageIndex + 1;
    this.intento = true
    let envio = this.retornarEnvio()
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.lmovimientos = this.groupBy2(data.tmov0, function (item) {
        return [item['tmov0.NUMEROMENSAJE']];   //Para reordenar JSON response de Movimientos Ecommerce - agrupado por [items.keys]
      });
      if (this.lmovimientos.length > 0) {
        this.lmovimientos = this.transformadorMovimientos(this.lmovimientos)
        for (let movimiento of this.lmovimientos) {
          this.movimientos.push(movimiento)
        }
        this.lengthlmov = this.lmovimientos.length
        this.length = this.movimientos.length + 1
        this.intentoSiguiente = (data.tmov0.length < (this.nrg - 1)) ? true : false;
        this.datosMovimiento = new MatTableDataSource(this.movimientos);
        this.datosMovimiento.paginator = this.paginator;
        this.bandMovimientos = true
      } else if (this.idCompra == 0) {
        this.alerta.presentarAlerta(this.idiomas.NCompras)
        this.bandMovimientos = false
      } else if (this.idVenta == 1) {
        this.alerta.presentarAlerta(this.idiomas.NVentas)
        this.bandMovimientos = false
      }
      //Almacenar Consulta
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.lmovimientos,
        'fechaDesde': this.dated
      }
      if (this.idCompra == 0) {
        this.basedatos.insertarConsulta('consultaCompras' + this.estatus, consulta)
      } else {
        this.basedatos.insertarConsulta('consultaVentas' + this.estatus, consulta)
      }
      this.intento = false
    }, (err) => {
      this.intento = false
      this.bandMovimientos = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        let ObjConsulta = this.idCompra == 0 ? this.basedatos.retornarConsulta('consultaCompras' + this.estatus) : this.basedatos.retornarConsulta('consultaVentas' + this.estatus)
        if (ObjConsulta) {
          this.intento = false
          this.dated = ObjConsulta.fechaDesde
          if (ObjConsulta.data.length == 0) {
            this.bandMovimientos = false
            if (this.idCompra == 0) {
              this.alerta.presentarAlerta(this.idiomas.NCompras)
            } else if (this.idVenta == 1) {
              this.alerta.presentarAlerta(this.idiomas.NVentas)
            }
          } else {
            this.lmovimientos = []
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.lmovimientos = ObjConsulta.data
            for (var i = 0; i < this.nrg; i++) {
              if (this.lmovimientos[i])
                this.movimientos[i] = this.lmovimientos[i]
            }
            this.dataMovimientos = this.movimientos
            this.datosMovimiento = new MatTableDataSource(this.movimientos);
            this.lengthlmov = this.lmovimientos.length
            this.length = this.movimientos.length + 1
            this.intentoSiguiente = (ObjConsulta.data < (this.nrg - 1)) ? true : false;
            this.datosMovimiento.paginator = this.paginator;
            this.bandMovimientos = true
          }
        } else {
          this.intento = false
          this.dated = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
          this.bandMovimientos = false
          if (this.idCompra == 0) {
            this.alerta.presentarAlerta(this.idiomas.NCompras)
          } else if (this.idVenta == 1) {
            this.alerta.presentarAlerta(this.idiomas.NVentas)
          }
        }
      }
    });
  }

  //FUNCION CON EVENTO PARA AVANZAR A LA SIGUIENTE PAGINA DE LA TABLA
  siguienteConsultaTabla(e?: PageEvent) {
    this.pageIndex = e.pageIndex;
    this.pageIndex = e.pageIndex;
    if (this.intentoSiguiente == false && e.previousPageIndex < e.pageIndex) {
      this.consultaSiguienteMovimientos();
    } else {
      this.lengthlmov = this.lmovimientos.length
    }
    if (e.previousPageIndex >= e.pageIndex) {
      this.intentoSiguiente = true
      this.lengthlmov = this.pageSize
    }
    if (this.npg == e.pageIndex) {
      this.consultaSiguienteMovimientos();
    }
  }

  consultaSiguienteMovimientos() {
    this.lmovimientos = []
    this.npg = this.pageIndex + 1;
    let envio = this.retornarEnvio()
    this.intentoSiguienteConsulta = true
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.lmovimientos = this.groupBy2(data.tmov0, function (item) {
        return [item['tmov0.NUMEROMENSAJE']];   //Para reordenar JSON response de Movimientos Ecommerce - agrupado por [items.keys]
      });
      this.lmovimientos = this.transformadorMovimientos(this.lmovimientos)
      this.movimientos = this.movimientos.concat(this.lmovimientos)
      this.lengthlmov = this.lmovimientos.length
      if (data.tmov0.length < this.nrg) {
        this.intentoSiguiente = true;
        console.log('ya no hay mas datos')
        this.datosMovimiento = new MatTableDataSource(this.movimientos);
        this.datosMovimiento.paginator = this.paginator;
      } else {
        this.length = this.movimientos.length + 1;
        this.intentoSiguiente = false;
        this.datosMovimiento = new MatTableDataSource(this.movimientos);
        this.datosMovimiento.paginator = this.paginator;
      }
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.movimientos,
        'fechaDesde': this.dated
      }
      if (this.idCompra == 0) {
        this.basedatos.insertarConsulta('consultaCompras' + this.estatus, consulta)
      } else {
        this.basedatos.insertarConsulta('consultaVentas' + this.estatus, consulta)
      }
      this.intentoSiguienteConsulta = false
    }, (err) => {
      this.intentoSiguienteConsulta = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        let ObjConsulta = this.idCompra == 0 ? this.basedatos.retornarConsulta('consultaCompras' + this.estatus) : this.basedatos.retornarConsulta('consultaVentas' + this.estatus)
        if (ObjConsulta) {
          this.intento = false
          this.dated = ObjConsulta.fechaDesde
          if (ObjConsulta.data.length == 0) {
            this.bandMovimientos = false
            if (this.idCompra == 0) {
              this.alerta.presentarAlerta(this.idiomas.NCompras)
              this.bandMovimientos = false
            } else if (this.idVenta == 1) {
              this.alerta.presentarAlerta(this.idiomas.NVentas)
              this.bandMovimientos = false
            }
          } else {
            this.movimientos = []
            this.movimientos = ObjConsulta.data
            if (ObjConsulta.data.length > this.nrg) {
              this.lengthlmov = this.lmovimientos.length
            }
            this.dataMovimientos = this.movimientos
            if (ObjConsulta.data.length < this.nrg) {
              this.intentoSiguiente = true;
              console.log('ya no hay mas datos')
              this.datosMovimiento = new MatTableDataSource(this.movimientos);
              this.datosMovimiento.paginator = this.paginator;
            } else {
              this.length = this.movimientos.length + 1;
              this.intentoSiguiente = false;
              this.datosMovimiento = new MatTableDataSource(this.movimientos);
              this.datosMovimiento.paginator = this.paginator;
            }
            this.bandMovimientos = true
          }
        } else {
          this.intento = false
          this.dated = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
          this.bandMovimientos = false
          this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaConsultaTransf.toUpperCase())
        }
      }
    });
  }

  retornarEnvio() {
    let envio = {
      transaccion: '010003-con-movimientos-ecomm.xml',
      "npg^tmov0": this.npg.toString(),
      "nrg^tmov0": this.nrg.toString(),
      "fullNames": true
    }
    if (this.fd != '') {
      envio["cri^tmov0^FCOMPRA^NORMAL^tmov0^>="] = this.fd + " 00:00:00"
      envio["cri^tmov0^FCOMPRA^NORMAL^tmov0^<="] = this.fh + " 00:00:00"
    }
    if (this.productoForm.value) {
      envio["cri^tpro0^NOMBRE^NORMAL^tmov0^LIKE"] = "%" + this.productoForm.value + "%"
    }
    if (this.estatus != '') {
      envio["cri^tmov0^ESTATUS^NORMAL"] = this.estatus
    }
    if (this.idCompra == 0) {
      envio["cri^tmov0^CPERSONA_ORDENANTE^NORMAL"] = this.basedatos.cpersona
    }
    if (this.idVenta == 1) {
      envio["cri^tmov0^CPERSONA_BENEFICIARIO^NORMAL"] = this.basedatos.cpersona
    }
    return envio
  }

  groupBy2(arrayNF, f) {
    var array = arrayNF;
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  transformadorMovimientos(value) {
    const lista = [];
    var imagenes = [];
    var estatus = ''
    for (let i = 0; i < value.length; i++) {
      if (value[i][0]["tmov0.ESTATUS"] == 'P') {
        estatus = 'Pendiente'
      } else {
        estatus = 'Entregado'
      }
      this.ord = this.ord + 1
      lista.push({
        id: value[i][0]["tpro0.CPRODUCTOECOMM"], orden: this.ord, producto: value[i][0]["tpro0.NOMBRE"], 'estatus': estatus,
        fechac: value[i][0]["tmov0.FCOMPRA"], fechae: value[i][0]["tmov0.FENTREGA"], total: value[i][0]["tmov0.TOTAL"], precioc: value[i][0]["tpro0.PRECIO"],
        diferir: value[i][0]["tpro0.PERMITEDIFERIR"], descripcion: value[i][0]["tpro0.DESCRIPCION"], tienda: value[i][0]["tpro0.CTIENDA"],
        tipo: value[i][0]["tpro0.CCATEGORIAECOMM_1"], cimagen: value[0]["timg1.CIMAGEN"], ccuentaor: value[i][0]["tmov0.CCUENTA_ORDENANTE"],
        cpersona_tienda: value[i][0]["tmov0.CPERSONA_BENEFICIARIO"], cpersona_cliente: value[i][0]["tmov0.CPERSONA_ORDENANTE"],
        ccuentaBen: value[i][0]["tmov0.CCUENTA_BENEFICIARIO"], nombre: value[i][0]["tper0.NOMBRELEGAL"], token: value[i][0]["tmov0.TOKEN"],
        cantidad: value[i][0]["tmov0.CANTIDAD"], identificacion: value[i][0]["tper0.IDENTIFICACION"], nMensaje: value[i][0]["tmov0.NUMEROMENSAJE"],
        direccion: value[i][0]["tmov0.DIRECCION"], secuencia: value[i][0]["tcfb0.SBLOQUEOFONDOS"]
      });
      for (let j = 0; j < value[i].length; j++) {
        imagenes.push(value[i][j]['timg1.CIMAGEN'])
      }
      lista[i]['cimagen'] = imagenes
      imagenes = []
    }
    return lista;
  }

  showHideColumns(last) {
    if (last) {
      try {
        this.funcionesTabla.DivHeight2(this.movimientos, this.lengthlmov, this.nrg)
        let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('tabladocumento')[0].setAttribute('style', 'width: 1000px')
          if (1000 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tabladocumento')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('tabladocumento')[0].setAttribute('style', 'width: 900px')
          if (900 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tabladocumento')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('tabladocumento')[0].setAttribute('style', 'width: 800px; font-size: 14px')
          if (800 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tabladocumento')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }


  reportePDF(movimiento) {
    this.intentoComprobante = true
    var iva = (movimiento.precioc * movimiento.cantidad) * 0.12
    if (movimiento.tipoidentificacion == 'CED') {
      var tipoidentificacion = "CÉDULA DE IDENTIDAD"
    } else {
      tipoidentificacion = "RUC"
    }
    iva = parseFloat(iva.toFixed(2))
    var envio = {
      "nombre": "18_COMPROBANTE_ECOMMERCE",
      "subsistema": "18",
      "transaccion": "3037",
      "tipo": "PDF",
      "rep^DESCRIPCION_TRANSACCION": "COMPRA ECOMMERCE",
      "rep^FECHA": movimiento.fechac,
      "rep^CUSUARIO": "$userCode",
      "rep^NOMBREORDENANTE": movimiento.nombre,
      "rep^ID_ORDENANTE": movimiento.identificacion,
      "rep^NOMBREPRODUCTO": movimiento.producto,
      "rep^PRECIO": movimiento.precioc.replace(/,/g, ""),
      "rep^CANTIDAD": movimiento.cantidad,
      "rep^MONTO": movimiento.total.replace(/,/g, ""),
      "rep^TOKEN": movimiento.token,
      "rep^COMISION": '0.00',
      "rep^CIMAGEN": movimiento.cimagen[0],
      "rep^IVA": iva,
      "rep^DOMICILIO": movimiento.direccion,
      "rep^VALOR": '0.00',
      "rep^REFERENCIA": "COMPRA PRODUCTO ECOMMERCE"
    }
    if (movimiento.direccion != "CLIENTE VA A RETIRAR EN TIENDA") {
      envio["rep^VALOR"] = '2.00'
    }
    if (this.idCompra == 0) {
      envio["nombre"] = "18_COMPROBANTE_ECOMMERCE"
    }
    if (this.idVenta == 1) {
      envio["nombre"] = "18_COMPROBANTE_ECOMMERCETI"
    }
    this.apiService.postProvider2InternetCheck("/reporte", this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.intentoComprobante = false
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intentoComprobante = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intentoComprobante = false
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  scroll() {
    let container2Aux = document.getElementsByClassName('container2Aux')
    var tabla = document.getElementsByClassName('tabladocumento')
    if (container2Aux.item(0).scrollLeft != 0) {
      if (container2Aux[0].scrollLeft + 1 >= (tabla[0].clientWidth - container2Aux[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    let container2Aux = document.getElementsByClassName('container2Aux')
    if (direccion == 'right') {
      container2Aux.item(0).scrollLeft = tabla[0].clientWidth - container2Aux[0].clientWidth
    } else {
      container2Aux.item(0).scrollLeft = 0
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  resendPurchase(movimiento) {
    let producto = {
      tienda: movimiento.tienda,
      cpersonaBen: movimiento.cpersona_tienda,
      id: movimiento.id,
      tipo: movimiento.tipo,
      nombre: movimiento.nombre,
      producto: movimiento.producto,
      cimagen: movimiento.cimagen[0],
      cimagen0: movimiento.cimagen[0],
      precioc: movimiento.precioc,
      preciod: 0.00,
      descripcion: movimiento.descripcion,
      grande: this.grande,
      meiano: this.mediano,
      cuenta: movimiento.ccuentaor,
      identificacion: movimiento.identificacion
    }
    if (movimiento.diferir == '1') {
      producto['preciod'] = parseFloat((movimiento.precioc / 12).toFixed(2))
    }
    this.alerta.generarDialogoCompra(this.idiomas, producto)
  }
}
