import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { Alerta } from '../funciones/alerta';
import { internetComponent } from '../funciones/internet';
import { Servicios } from '../funciones/encryptar';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CambiarVariableCuenta, CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { TemporizadorComponent } from '../temporizador/temporizador.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDividerModule, MatInputModule, MatProgressBarModule, MatCardModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, CambiarVariableCuenta, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-pago-prestamo',
  templateUrl: './pago-prestamo.component.html',
  styleUrls: ['./pago-prestamo.component.css']
})
export class PagoPrestamoComponent implements OnInit {

  @Input() idiomas: any;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  //@Input() loanPaymentInfo: any;
  // @Output() payLoanEvent = new EventEmitter<any>()

  cuentaDesdeSelected = this.validators.comboForm()
  cuentas: any;
  cuentasPres: any;
  monedaDesde: string;
  productoDesde: string;
  fechaVencimiento: any;
  CuotaPagoPrestamo: any;
  montoPagoPrestamo = new FormControl();
  referencia = this.validators.textNumberForm();
  token = this.validators.otpForm();
  tokenSolicitado: boolean;
  intento: boolean;
  chosenPaymentOption: string;
  bandDisableForm: boolean;
  bandMobile: boolean;
  //Variable para el temporizador
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  flechaL0: boolean;
  flechaR0: boolean;
  flechaL1: boolean;
  flechaR1: boolean;
  mostrarMensajeNoData: boolean = false;
  totalPrest: any;

  cpersona: string;
  nombre: string;
  fecha: string;
  datos: any;
  datosp: any[];
  datos2: any[];
  consultado: boolean = false;
  excuentas: boolean = false;
  cantidadForm = this.validators.decimalsForm();
  cuenta = this.validators.comboForm();
  factual = new Date();
  dated = new Date(this.factual.getUTCFullYear(), this.factual.getUTCMonth(), this.factual.getUTCDate());

  constructor(public alerta: Alerta, public servicios: Servicios, public apiService: ApiService, public basedatos:
    BaseDatos, public validators: FormControlValidators, public internet: internetComponent) {
    this.cuentas = [];
    this.bandDisableForm = false;
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.flechaL0 = true;
    this.flechaR0 = false;
    this.flechaL1 = true;
    this.flechaR1 = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngOnInit() {
    this.token.setValue('');
    this.campos();
    this.obtainAccountsToPayLoan();
    this.tokenSolicitado = false;
  }

  ngAfterViewInit() {
  }

  campos() {
    this.intento = true;
    this.excuentas = false;
    const envio = {
      'transaccion': "010003-lv-cuentas-prestamo.xml",
      "alias": "tc0",
      'cri^tpersona0^CPERSONA^NORMAL^tc0^=': "$personCode",
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data.tc0.length > 0) {
        this.cpersona = data.ctl.CPERSONA;
        this.nombre = data.ctl.NOMBRELEGAL;
        this.cuentasPres = this.transformador(data.tc0);
        this.intento = false;
        if (this.cuentasPres != null) {
          if (this.cuentasPres[0].value == null) {
            this.nombre = "";
            this.cuentasPres = [];
            this.consultado = false;
            this.cuentasPres = this.validators.comboForm()
            this.datos2 = [];
            this.alerta.presentarAlerta(this.idiomas.NoPrestamosCuentas);
            this.mostrarMensajeNoData = true;
          } else {
            this.mostrarMensajeNoData = false;
            this.excuentas = true;
            const consulta = {
              'fecha': new Date().toLocaleDateString(),
              'hora': new Date().toLocaleTimeString(),
              'data': this.cuentasPres,
              'cpersona': this.cpersona,
              'nombre': this.nombre,
            };
            this.basedatos.insertarConsulta('CtasPres', consulta);
          }
        }
      } else {
        this.intento = false;
        this.alerta.presentarAlerta(this.idiomas.NoPrestamosCuentas);
        this.mostrarMensajeNoData = true;
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        //console.log(err);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
      }
      else { //Sin Internet
        this.camposOffline();
      }
    }
    )
  }

  camposOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasPagPres');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias)
      this.intento = false;
      this.excuentas = false;
    } else {
      this.cuentasPres = ObjConsulta.data;
      this.cpersona = ObjConsulta.cpersona;
      this.nombre = ObjConsulta.nombre;
      this.intento = false;
      this.excuentas = true;
    }
  }

  transformador(value) {
    let lista = [];
    for (let i = 0; i < value.length; i++)
      lista.push({ "value": value[i].CCUENTA, "viewValue": value[i].CCUENTA, "descProducto": value[i].DESCRIPCION, "moneda": value[i].CMONEDA })
    return lista;
  }

  consultaCuotaPagar() {
    this.intento = true
    const envio = {
      'transaccion': "036600-con-pagoPrestamosValor.xml",
      'cri^tpersonalicitudfondos^CPERSONA^NORMAL^tpersonalicitudfondos^=': this.cpersona,
      'cri^tnt^CPERSONA^NORMAL^tpersonalicitudfondos^=': this.cpersona,
      'cri^tpdl^CPERSONA^NORMAL^tpersonalicitudfondos^=': this.cpersona,
      'cri^tpersonatelefonos1^CPERSONA^NORMAL^tpersonalicitudfondos^=': this.cpersona,
      'cri^tperdnia1^CPERSONA^NORMAL^tperdnia1^=': this.cpersona,
      'cri^tpertnia1^CPERSONA^JOIN^tperdnia1^=': this.cpersona,
      'cri^tperdnia2^CPERSONA^NORMAL^tperdnia2^=': this.cpersona,
      'cri^tpertnia2^CPERSONA^JOIN^tperdnia2^=': this.cpersona,
      'cri^HOL^CPERSONA^NORMAL^HOL^=': this.cuenta.value.value,
      'ctl^IDENTIFICACION': "$personId",
      'ctl^NOMBREPER': this.nombre,
      'ctl^CPERSONACOND': this.cpersona,
      'ctl^CPERSONAC': this.cpersona,
      'ctl^PERSONABENEF': this.cpersona,
      'ctl^IDENTIFICACIONS': this.cpersona,
      'ctl^NOMBREBENEF': this.nombre,
      'ctl^NROCTA': this.cuenta.value.value,
      'ctl^CCUENTA': this.cuenta.value.value,
      'ctl^CCUENTA_COLOCACIONES': this.cuenta.value.value,
      'ctl^MONEDACTA': this.cuenta.value.moneda,
      'ctl^FECHA': "$accountingDate",
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        if (data['E-CUOTAS'][0]) {
          var total = 0
          this.intento = false
          this.consultado = true
          this.datosp = []
          this.datosp = [data['E-CUOTAS']]
          for (let i = 0; i < this.datosp[0].length; i++) {
            if (this.datosp[0][i].ESTATUS == "Vigente") {
              total = total + Number(this.datosp[0][i].TOTAL)
            }
          }
          this.totalPrest = Number(total);
          this.cantidadForm.setValue(total.toString());
          this.montoPagoPrestamo = this.validators.decimalsFormPago(this.totalPrest);
          this.CuotaPagoPrestamo = data['E-CUOTAS'][0].CUOTA;
          this.fechaVencimiento = data['E-CUOTAS'][0].FVENCIMIENTO;
          let consulta = {
            'clientName': this.nombre,
            'fecha': new Date().toLocaleDateString(),
            'hora': new Date().toLocaleTimeString(),
            'cta': this.cuenta.value,
            'data': this.datosp,
            'cuenta': this.cuenta.value.value
          }
          this.basedatos.insertarConsulta('CuotaPresPag', consulta);
        } else {
          this.intento = false
          this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCtaPres)
        }
      }
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
      }
      else { //Sin Internet
        Promise.resolve(null).then(() => this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta))
      }
    })
  }

  consultaCuotaOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CuotaPresPag');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias)
      this.intento = false;
      this.consultado = false;
    } else {
      this.datos = ObjConsulta.data;
      this.datos = ObjConsulta.data;
      var total = 0;
      this.datosp = this.datos;
      for (let i = 0; i < this.datosp[0].length; i++) {
        if (this.datosp[0][i].ESTATUS == "Vencida") {
          total = total + Number(this.datosp[0][i].TOTAL);
        }
      }
      this.totalPrest = Number(total);
      this.cantidadForm.setValue(total.toString());
      this.montoPagoPrestamo = this.validators.decimalsFormPago(this.totalPrest);
      this.intento = false;
      this.excuentas = true;
    }
  }

  cambioCuenta() {
    this.consultado = false;
    this.dated = new Date(this.factual.getUTCFullYear(), this.factual.getUTCMonth(), this.factual.getUTCDate());
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  obtainAccountsToPayLoan() {
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'usuario': '',
      'alias': 'tc0',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN': '',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
      'cri^tctp0^CTRANSACCION^JOIN': '6073',
      'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
    }
    envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.cuentas = this.transformadorCuentasDesde(data);
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentas,
        };
        this.basedatos.insertarConsulta('CtasPagPres', consulta);
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
        this.obtainAccountsToPayLoanOffline()
        if (!this.cuentas) {
          this.cuentas = []
        }
      }
    });
  }

  obtainAccountsToPayLoanOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasPagPres');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias)
    } else {
      this.cuentas = ObjConsulta.data;
    }
  }

  seleccionarCuentaDesde() {
    this.monedaDesde = this.cuentaDesdeSelected.value.moneda;
    this.productoDesde = this.cuentaDesdeSelected.value.descProducto;
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].CCUENTA, 'viewValue':
          value[i].CCUENTA, 'descProducto': value[i].DESCRIPCION, moneda: value[i].CMONEDA,
        tipoidentificacion: value[i].CTIPOIDENTIFICACION
      });
    }
    return lista;
  }

  solicitarToken() {
    this.intento = true;
    const envio = { transaccion: '066014' };
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.basedatos.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  comaToDot(event) {
    if (event.key === ',') {
      event.target.value += '.';
      event.preventDefault();
    }
  }

  payLoan() {
    this.intento = true;
    let valPago = this.montoPagoPrestamo.value.replace(/,/g, "");
    let envio = {
      'cuentaOrigen': this.cuentaDesdeSelected.value.value,
      'cuentaDestino': this.cuenta.value.value,
      'clave': this.servicios.toAES(this.token.value), desencriptar: '1',
      'monto': this.montoPagoPrestamo.value.replace(/,/g, ""),
      'transaccion': "066014-pago-prestamo-man.xml",
      'rec^EXCHANGE^0^CUENTAORIGEN': this.cuentaDesdeSelected.value.value,
      'rec^EXCHANGE^0^VALORMOVIMIENTO': valPago,
      'rec^EXCHANGE^0^VALORMONEDACUENTA': valPago,
      'rec^EXCHANGE^0^VALORORIGEN': valPago,
      'rec^EXCHANGE^0^VALORDESTINO': valPago,
      'rec^EXCHANGE^0^CUENTADESTINO': this.cuentaDesdeSelected.value.value,
      'rec^E-CUOTAS^0^CUOTA': this.CuotaPagoPrestamo,
      'rec^E-CUOTAS^0^FVENCIMIENTO': this.fechaVencimiento,
      'rec^E-CUOTAS^0^CAPITAL': valPago,
      'rec^E-CUOTAS^0^TOTAL': valPago,
      'rec^E-CUOTAS^0^SALDOCUOTA': valPago,
      'ctl^CCUENTA': this.cuenta.value.value,
      'ctl^CCUENTA_COLOCACIONES': this.cuenta.value.value,
      'ctl^NROCTA': this.cuenta.value.value,
      'ctl^VALORPAGO': valPago,
      'ctl^TOTALNOTASDEBITO': valPago,
      'ctl^TOTALAPLICADO': valPago,
      'ctl^TOTALAPAGAR': valPago,
      'ctl^TOTALRECIBIDO': valPago,
      'ctl^TOTALCUOTASMAXEXTRA': valPago,
      'ctl^TOTALCUOTAVENCIDA': valPago,
      //'ctl^TOTALCUOTAXVENCER': this.loanPaymentInfo.actualBal,
      'ctl^TITULARES': '$personCode',
      'ctl^CPERSONA': '$personCode',
      'ctl^CPERSONA_USUARIO': '$personCode',
      'ctl^CUSUARIOREAL': '$userCode',
      'ctl^USR': '$userCode',
      'ctl^_ClientName': '$personName',
      'tarPendType': 'loanPayment'
    };

    const envioMensaje = {
      cuenta: envio.cuentaOrigen,
      LoanAccount: envio.cuentaDestino,
      monto: this.validators.FormatNumber(envio.monto),
      descripcion: this.referencia.value.toUpperCase()
    };
    this.alerta.generarDialogoConfirmacionTransferencias(this.idiomas.Prestamos, 'PagoPrestamo', this.idiomas, envioMensaje).then((data) => {
      if (data) {
        this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
          this.disableFormControls();
          this.intento = false;
          this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase());
          this.mostrarCron = false;
          this.enableCronoIn = false;
        }, (err) => {
          this.mostrarCron = false;
          this.enableCronoIn = false;
          if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
            this.intento = false
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
              else {
                this.alerta.presentarAlertaX(this.idiomas.ServidorError)
              }
            }
            else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          }
          else { //Sin Internet
            this.intento = false
          }
        });
      }
      else {
        this.intento = false
      }
    });
  }

  paymentLoanBalOptionChange() {
    if (this.chosenPaymentOption == "Other") {
      this.montoPagoPrestamo.setValue('');
    } else {
      this.montoPagoPrestamo.setValue(this.chosenPaymentOption);
    }
  }

  disableFormControls() {
    this.cuentaDesdeSelected.disable()
    this.montoPagoPrestamo.disable()
    this.referencia.disable()
    this.token.disable()
    this.bandDisableForm = true
    this.cuenta.disable()
  }

  cancelPayLoan() {
    this.cuentaDesdeSelected.reset()
    this.cuentaDesdeSelected.enable()
    this.montoPagoPrestamo.reset()
    this.montoPagoPrestamo.enable()
    this.referencia.reset()
    this.referencia.enable()    
    this.cuenta.reset()
    this.cuenta.enable()
    this.token.reset()
    this.cuenta.reset()
    this.bandDisableForm = true
    this.consultado = false
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace(/,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0]
          let decimalPart = amountArrayDotSplit[1]
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }

}
