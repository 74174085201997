<div class="form-row">
    <div class="col-12" style="text-align: center">
        <h1 class="title" style="font-size: 20px;">{{ idiomas.Agencias}}</h1>
    </div>
    <div class="col-12">
        <mat-form-field appearance="outline" style="min-width: 225px;">
            <mat-label>{{idiomas.Agencias}}</mat-label>
            <mat-select (selectionChange)="cambiarCoordenadas()" [formControl]="agenciasForm">
                <mat-option *ngFor="let agencia of agencias" [value]="agencia">
                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                    {{agencia.nombre}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="agenciasForm.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-12">
        <google-map id="map" height="400" width="auto" [center]="center" [zoom]="zoom" [options]="options">
            <map-marker #markerElem="mapMarker" *ngFor="let agencia of agencias; let i = index"
                [position]="agencia.position" (mapClick)="openInfo(markerElem, i)">
                <map-info-window>
                    <p style="font-weight: bold; color: black;">{{agencia.nombre | uppercase}}</p>
                    <p style="margin-bottom: 2px; max-width: 200px; color: black;"><b>{{idiomas.Direcciones}}:</b>
                        {{agencia.direccion}}</p>
                    <p style="margin-bottom: 2px; color: black;"><b>{{idiomas.Telefonos}}:</b> {{agencia.telefonos}}</p>
                    <p style="margin-bottom: 2px; color: black;"><b>{{idiomas.HorAtencion}}:</b></p>
                    <p style="margin-bottom: 2px; color: black;">Lunes a Viernes: {{agencia.horarioslv}}</p>
                    <p style="margin-bottom: 2px; color: black;">Sabados: {{agencia.horariossa}}</p>
                    <p style="margin-bottom: 2px; color: black;">Domingos: {{agencia.horariosdo}}</p>
                </map-info-window>
            </map-marker>
        </google-map>
    </div>
</div>