<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch" style="margin: auto; padding-bottom:35px;">
  <mat-card class="cards-transations" *ngIf="bandsFuncion.habilitarMenu && !bandMobile"
    style="margin-bottom: 10px !important;">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.pagServ | uppercase}}</strong></h6>
      </div>
    </div>
    <div *ngIf=intento style="padding-left: 16px; padding-right: 16px; padding-top: 16px; padding-bottom: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="form-row menu" *ngIf="bandsFuncion.habilitarMenu && band && !bandMobile" style="margin-top: 5px;">
      <div class="col-3 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'null'==valueIcon}"
        (click)="habilitarFunciones('servRegistrados',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-frecuentes-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-frecuentes.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.ServReg}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'AGUA'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','AGUA',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/agua-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/agua.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.AGUA}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'CATALOGO'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','CATALOGO',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/catalogo-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/catalogo.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.CATALOGO}}</mat-label>
      </div>

      <!--<div class="col-3 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','COMPRAS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/compras-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/compras.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.COMPRAS}}</mat-label>
      </div>-->

      <div class="col-3 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu"
        [ngClass]="{'active': 'CONSEJO_JUDICATURA'==valueIcon}"
          (click)="habilitarFunciones('servNuevos','CONSEJO_JUDICATURA',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/consejo-judicatura-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/consejo-judicatura.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.CONSEJO_JUDICATURA}}</mat-label>
      </div>

      <!--<div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','EVENTOS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/eventos-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/eventos.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.EVENTOS}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','GIROS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/giros-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/giros.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.GIROS}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','INTERNET',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/internet-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/internet.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.INTERNET}}</mat-label>
      </div>-->

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'LUZ'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','LUZ',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/luz-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/luz.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.LUZ}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'MUNICIPIO'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','MUNICIPIO',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/municipio-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/municipio.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.MUNICIPIOS}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
        [ngClass]="{'active': 'OBLIGACIONES_PATRONALES'==valueIcon}"
          (click)="habilitarFunciones('servNuevos','OBLIGACIONES_PATRONALES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/iess-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/iess.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.OBLIGACIONES_PATRONALES}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'PEAJE'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','PEAJE',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/peaje-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/peaje.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.PEAJE}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'PLANES'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','PLANES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/planes-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/planes.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.PLANES}}</mat-label>
      </div>

      <!--<div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','PPS_MIES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/mies-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/mies.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.PPS_MIES}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','RECAUDACIONES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/recaudaciones-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/recaudaciones.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.RECAUDACIONES}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
          (click)="habilitarFunciones('servNuevos','REGISTRO_PROPIEDAD',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/registro-propiedad-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/registro-propiedad.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.REGISTRO_PROPIEDAD}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
          (click)="habilitarFunciones('servNuevos','SERVICIOS_VARIOS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-varios-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-varios.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.SERVICIOS_VARIOS}}</mat-label>
      </div>-->

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'SRI'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','SRI',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/sri-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/sri.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.SRI}}</mat-label>
      </div>

      <!--<div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','TARJETA',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/tarjeta-credito-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/tarjeta-credito.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.TARJETA}}</mat-label>
      </div>-->

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'TELEFONO'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','TELEFONO',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/telefono-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/telefono.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.TELEFONO}}</mat-label>
      </div>

      <div class="col-3 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'TRANSITO'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','TRANSITO',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/ant-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/ant.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.TRANSITO}}</mat-label>
      </div>
    </div>
  </mat-card>

  <div style="padding-left: 16px; padding-right: 16px;" *ngIf="bandMobile && !bandsFuncion.habilitarMenu">
    <button mat-raised-button (click)="habilitarFunciones('habilitarMenu',null)" title={{idiomas.RegresarServ}}
      style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;" color="primary"
      class="buttonBene">
      <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
      <strong>{{idiomas.RegresarServ}}</strong>
    </button>
  </div>

  <div *ngIf="bandsFuncion.habilitarMenu && bandMobile" style="margin-bottom: 10px !important;">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.pagServ | uppercase}}</strong></h6>
      </div>
    </div>
    <div *ngIf=intento style="padding-left: 16px; padding-right: 16px; padding-top: 16px; padding-bottom: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="form-row" *ngIf="bandsFuncion.habilitarMenu && band && bandMobile" style="margin-top: 5px;">
      <div class="col-4 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'null'==valueIcon}"
        (click)="habilitarFunciones('servRegistrados',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-frecuentes-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-frecuentes.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.ServReg}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'AGUA'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','AGUA',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/agua-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/agua.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.AGUA}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important; margin-top: 10px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'CATALOGO'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','CATALOGO',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/catalogo-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/catalogo.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.CATALOGO}}</mat-label>
      </div>

      <!--<div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','COMPRAS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/compras-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/compras.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.COMPRAS}}</mat-label>
      </div>-->

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
        [ngClass]="{'active': 'CONSEJO_JUDICATURA'==valueIcon}"
          (click)="habilitarFunciones('servNuevos','CONSEJO_JUDICATURA',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/consejo-judicatura-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/consejo-judicatura.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.CONSEJO_JUDICATURA}}</mat-label>
      </div>

      <!--<div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','EVENTOS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/eventos-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/eventos.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.EVENTOS}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','GIROS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/giros-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/giros.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.GIROS}}</mat-label>
      </div>
      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','INTERNET',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/internet-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/internet.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.INTERNET}}</mat-label>
      </div>-->


      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'LUZ'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','LUZ',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/luz-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/luz.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.LUZ}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'MUNICIPIO'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','MUNICIPIO',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/municipio-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/municipio.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.MUNICIPIOS}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
        [ngClass]="{'active': 'OBLIGACIONES_PATRONALES'==valueIcon}"
          (click)="habilitarFunciones('servNuevos','OBLIGACIONES_PATRONALES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/iess-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/iess.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.OBLIGACIONES_PATRONALES}}</mat-label>
      </div>

      <!--<div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','PEAJE',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/peaje-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/peaje.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.PEAJE}}</mat-label>
      </div>-->

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'PLANES'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','PLANES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/planes-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/planes.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.PLANES}}</mat-label>
      </div>

      <!--<div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','PPS_MIES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/mies-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/mies.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.PPS_MIES}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" (click)="habilitarFunciones('servNuevos','RECAUDACIONES',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/recaudaciones-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/recaudaciones.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.RECAUDACIONES}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
          (click)="habilitarFunciones('servNuevos','REGISTRO_PROPIEDAD',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/registro-propiedad-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/registro-propiedad.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.REGISTRO_PROPIEDAD}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
          (click)="habilitarFunciones('servNuevos','SERVICIOS_VARIOS',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-varios-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/servicios-varios.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.SERVICIOS_VARIOS}}</mat-label>
      </div>-->

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'SRI'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','SRI',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/sri-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/sri.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.SRI}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu"
        [ngClass]="{'active': 'TARJETA'==valueIcon}" 
        (click)="habilitarFunciones('servNuevos','TARJETA',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" 
            src="assets/resources/mipmap-hdpi/servicios/tarjeta-credito-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home"
            src="assets/resources/mipmap-hdpi/servicios/tarjeta-credito.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.TARJETA}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': 'TELEFONO'==valueIcon}"
        (click)="habilitarFunciones('servNuevos','TELEFONO',null)" >
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/telefono-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/telefono.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.TELEFONO}}</mat-label>
      </div>

      <div class="col-4 div-menu" style="margin-left: 0px !important;">
        <button mat-raised-button class="button-menu" 
        [ngClass]="{'active': valueIcon==='TRANSITO'}" 
        (click)="habilitarFunciones('servNuevos','TRANSITO',null)">
          <img *ngIf="logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/ant-dark.png">
          <img *ngIf="!logoTemaOscuro" class="icon-home" src="assets/resources/mipmap-hdpi/servicios/ant.png">
        </button>
        <br>
        <mat-label class="label-menu" style="white-space: normal; font-size: 13px;">
          {{idiomas.TRANSITO}}</mat-label>
      </div>
    </div>
  </div>
  <div *ngIf="!bandsFuncion.habilitarMenu">
    <div *ngIf="bandsFuncion.servNuevos">
      <app-pago-servicios #agua (regServEv)="registrarServicio($event)" [mediano]="mediano" [grande]="grande"
        [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend" [nomServ]="nomServ" [bandServReg]="false">
      </app-pago-servicios>
    </div>

    <div *ngIf="bandsFuncion.servRegistrados">
      <app-pago-servicios #servRegistrados [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
        [dataTarPend]="dataTarPend" [bandServReg]="true"></app-pago-servicios>
    </div>
    <div class="float-transacciones" *ngIf="!bandMobile">
      <button mat-fab (click)="habilitarFunciones('habilitarMenu',null)" title={{idiomas.Regresar}} class="float-right"
        color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
      </button>
    </div>
  </div>
</div>