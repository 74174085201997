import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatDialogModule, MatButtonModule, NgxExtendedPdfViewerModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-estado-cuenta',
  templateUrl: './dialogo-estado-cuenta.component.html',
  styleUrls: ['./dialogo-estado-cuenta.component.css']
})
export class DialogoEstadoCuentaComponent {

  er: boolean = false;
  intento: boolean;
  bandMobile: boolean;
  bandIos: boolean;

  idiomas: any;
  ccuenta: any;
  fdesde: any;
  fhasta: any;
  sinicial: any;
  cmoneda: any;
  available: any;
  blocked: any;
  pdf: string = "";
  showPDF: boolean;
  base64URL: any;

  messageError: any
  intentoMessage: boolean
  grande: boolean = false;
  mediano: boolean = false;
  normal: boolean = false;

  constructor(private api: ApiService, private base: BaseDatos, public internet: internetComponent, public mostrarPdf: MostrarPDF, private ngNavigatorShareService: NgNavigatorShareService,
    private dialogRef: MatDialogRef<DialogoEstadoCuentaComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.idiomas = data.idm;
    this.ccuenta = data.ccuenta;
    this.fdesde = data.fdesde;
    this.fhasta = data.fhasta;
    this.sinicial = data.sinicial;
    this.cmoneda = data.cmoneda;
    this.available = data.available;
    this.blocked = data.blocked;
    this.intentoMessage = false
    this.grande = data.fontSize.grande;
    this.mediano = data.fontSize.mediano;
    this.normal = data.fontSize.normal;
    this.bandMobile = false;
    this.bandIos = false;
    this.showPDF = true;
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';

    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
    if (isMobile() && !isInStandaloneMode()) {
      this.bandMobile = true
    }

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /ipad|iphone|ipod/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandIos = isIos()

    if (isIos() && !isInStandaloneMode()) {
      this.bandIos = true
    }

    if (window.innerWidth < 834) {
      this.bandMobile = true
    } else {
      this.bandMobile = false
    }
  }

  close() {
    this.dialogRef.close(this.er);
  }

  reportePDF() {
    this.intento = true
    let envio = {
      "nombre": "18_ESTADO_CUENTA_PDF",
      "subsistema": "18",
      "transaccion": "4001",
      "tipo": "PDF",
      "ctl^CUENTA": this.ccuenta,
      "rep^FDESDE_P": this.fdesde,
      "rep^FHASTA_P": this.fhasta,
      "rep^CCUENTA_P": this.ccuenta,
      "rep^SALDO_INICIAL_P": this.sinicial,
      "rep^CUSUARIO": "$userCode",
      "rep^CIA": "$company",
      "rep^FCONTABLE": "$accountingDate",
      "rep^CTRANSACCION": "4001",
      "rep^CSUBSISTEMA": "18",
      "rep^CPERSONA": "$personCode",
      "rep^MONEDA_P": this.cmoneda,
      "rep^SDISPONIBLE_P": this.available,
      "rep^SBLOQUEADO_P": this.blocked,
      "rep^Cpersona": "$personCode",
      "rep^Ffinal": this.fhasta,
      "rep^Finicial": this.fdesde
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        if (this.bandIos) {
          this.base64URL = this.pdf as string
          this.showPDF = false;
        } else {
          this.mostrarPdf.mostrarReportePDF(this.pdf)
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.messageError = this.idiomas.ServidorError
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  async sharePDF() {

    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.intento = true
    let nombreReporte = "18_ESTADO_CUENTA_PDF"
    let envio = {
      "nombre": "18_ESTADO_CUENTA_PDF",
      "subsistema": "18",
      "transaccion": "4001",
      "tipo": "PDF",
      "ctl^CUENTA": this.ccuenta,
      "rep^FDESDE_P": this.fdesde,
      "rep^FHASTA_P": this.fhasta,
      "rep^CCUENTA_P": this.ccuenta,
      "rep^SALDO_INICIAL_P": this.sinicial,
      "rep^CUSUARIO": "$userCode",
      "rep^CIA": "$company",
      "rep^FCONTABLE": "$accountingDate",
      "rep^CTRANSACCION": "4001",
      "rep^CSUBSISTEMA": "18",
      "rep^CPERSONA": "$personCode",
      "rep^MONEDA_P": this.cmoneda,
      "rep^SDISPONIBLE_P": this.available,
      "rep^SBLOQUEADO_P": this.blocked,
      "rep^Cpersona": "$personCode",
      "rep^Ffinal": this.fhasta,
      "rep^Finicial": this.fdesde
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then(async (data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        var byteCharacters = atob(this.pdf);
        var byteNumbers = new Array(byteCharacters.length);

        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        const file = new File([new Blob([byteArray])], nombreReporte + '.pdf', { type: "application/pdf" });
        this.ngNavigatorShareService.share({
          title: nombreReporte,
          text: nombreReporte,
          url: 'https://bancavirtual.lorente.fin.ec',
          files: [file],
        }).then(() => console.log('Share was successful.'))
          .catch((error) => console.log('Sharing failed', error));
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.messageError = this.idiomas.ServidorError
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  reporteXLS() {
    this.intento = true
    let envio = {
      "nombre": "18_ESTADO_CUENTA_XLS",
      "subsistema": "18",
      "transaccion": "4001",
      "tipo": "XLSX",
      "ctl^CUENTA": this.ccuenta,
      "rep^FDESDE_P": this.fdesde,
      "rep^FHASTA_P": this.fhasta,
      "rep^CCUENTA_P": this.ccuenta,
      "rep^SALDO_INICIAL_P": this.sinicial,
      "rep^CUSUARIO": "$userCode",
      "rep^CIA": "$company",
      "rep^FCONTABLE": "$accountingDate",
      "rep^CTRANSACCION": "4001",
      "rep^CSUBSISTEMA": "18",
      "rep^CPERSONA": "$personCode",
      "rep^MONEDA_P": this.cmoneda,
      "rep^SDISPONIBLE_P": this.available,
      "rep^SBLOQUEADO_P": this.blocked,
      "rep^Cpersona": "$personCode",
      "rep^Ffinal": this.fhasta,
      "rep^Finicial": this.fdesde
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        const linkSource = 'data:application/xlsx;base64,' + this.pdf
        const downloadLink = document.createElement("a")
        const fileName = "reporte_transaccciones.xlsx"
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.messageError = this.idiomas.ServidorError
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  async shareXLS() {

    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.intento = true
    let nombreReporte = "18_ESTADO_CUENTA_XLS"
    let envio = {
      "nombre": "18_ESTADO_CUENTA_XLS",
      "subsistema": "18",
      "transaccion": "4001",
      "tipo": "XLSX",
      "ctl^CUENTA": this.ccuenta,
      "rep^FDESDE_P": this.fdesde,
      "rep^FHASTA_P": this.fhasta,
      "rep^CCUENTA_P": this.ccuenta,
      "rep^SALDO_INICIAL_P": this.sinicial,
      "rep^CUSUARIO": "$userCode",
      "rep^CIA": "$company",
      "rep^FCONTABLE": "$accountingDate",
      "rep^CTRANSACCION": "4001",
      "rep^CSUBSISTEMA": "18",
      "rep^CPERSONA": "$personCode",
      "rep^MONEDA_P": this.cmoneda,
      "rep^SDISPONIBLE_P": this.available,
      "rep^SBLOQUEADO_P": this.blocked,
      "rep^Cpersona": "$personCode",
      "rep^Ffinal": this.fhasta,
      "rep^Finicial": this.fdesde
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then(async (data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        var byteCharacters = atob(this.pdf);
        var byteNumbers = new Array(byteCharacters.length);

        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        const file = new File([new Blob([byteArray])], nombreReporte + '.xlsx', { type: "application/xlsx" });
        this.ngNavigatorShareService.share({
          title: nombreReporte,
          text: nombreReporte,
          url: 'https://bancavirtual.lorente.fin.ec',
          files: [file],
        }).then(() => console.log('Share was successful.'))
          .catch((error) => console.log('Sharing failed', error));
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.messageError = this.idiomas.ServidorError
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
  }

}
