import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Chart, registerables, LineController, ArcElement, BarController, BarElement } from 'chart.js';
import * as Chartjs from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import callbacks from 'chartjs-plugin-datalabels';
import { Tooltip } from 'chart.js';
import { Legend } from 'chart.js';
import { CategoryScale } from 'chart.js';
import { LineElement, PointElement, LinearScale, Title, TimeScale } from 'chart.js';

import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-grafico-saldos-tiempo',
  templateUrl: './grafico-saldos-tiempo.component.html',
  styleUrls: ['./grafico-saldos-tiempo.component.css']
})
export class GraficoSaldosTiempoComponent implements OnInit {

  @Input() idiomas: any;
  @Input() data: any;

  chart: any;
  labelsArray: any;
  totalsArray: any;
  dataJsonsArray: any;
  dataJsonsArrayFecha: any;
  dataJsonsArraySaldo: any;
  acumAux: number;
  tema: string;
  colorParaTema: string;
  localeIdioma: string;
  temaPrev: string;
  localeIdiomaPrev: string;
  bandChangeChart: boolean;
  pipes: any;
  date: Date;

  constructor() {
    this.totalsArray = [];
    this.labelsArray = [];
    this.pipes = new CantidadMonetaria();
    this.dataJsonsArray = [];
    this.tema = localStorage.getItem('themeDisplayed');
    this.localeIdioma = localStorage.getItem('locale');
    this.temaPrev = this.tema;
    this.localeIdiomaPrev = this.localeIdioma
    this.colorParaTema = this.getColorPorTema();
    this.bandChangeChart = false;
    this.dataJsonsArrayFecha = [];
    this.dataJsonsArraySaldo = [];
  }

  ngDoCheck() {
    this.tema = localStorage.getItem('themeDisplayed');
    this.localeIdioma = localStorage.getItem('locale');
    if ((this.tema != this.temaPrev) || (this.localeIdioma != this.localeIdiomaPrev)) {
      try {
        this.bandChangeChart = (document.getElementById('saldosTiempo')) ? true : false;
      } catch (error) {
        this.bandChangeChart = false;
      }
      if (this.bandChangeChart) {
        this.chart.destroy();
        this.temaPrev = this.tema;
        this.localeIdiomaPrev = this.localeIdioma;
        this.colorParaTema = this.getColorPorTema();
        this.drawChart();
      }
    }
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  ngOnInit() {
    var dataArray = []
    this.dataJsonsArrayFecha = [];
    this.dataJsonsArraySaldo = [];
    this.dataJsonsArray = []; let i = 0;
    for (let trans of this.data) {
      if (trans.band == "04") {
        const fecha = new Date(trans.fecha)
        fecha.setDate(fecha.getDate() + 1);
        var fehcaIn = fecha.toLocaleDateString('en-us', { month: "short", day: "numeric" })
        dataArray.push({ t: new Date(trans.fecha.replace(/\s/, 'T')).valueOf(), y: trans.valormonedacuenta });
        this.dataJsonsArrayFecha.push(fehcaIn);
        this.dataJsonsArraySaldo.push(trans.valormonedacuenta)
      }
      if (trans.band == "05") {
        var fehcaIn = new Date(trans.fecha).toLocaleDateString('en-us', { month: "short", day: "numeric" })
        dataArray.push({ t: new Date(trans.fecha.replace(/\s/, 'T')).valueOf(), y: trans.saldocontable });
        this.dataJsonsArrayFecha.push(fehcaIn);
        this.dataJsonsArraySaldo.push(trans.saldocontable)
      }
      if (trans.band == "06") {
        if (i == 0) {
          var fehcaIn = new Date(0).toLocaleDateString('en-us', { month: "short", day: "numeric" })
          this.dataJsonsArrayFecha.push(fehcaIn);
          this.dataJsonsArraySaldo.push(trans.capreducido)
        }
        if (trans.estado == "PAGADO") {
          var fehcaIn = new Date(trans.fpago).toLocaleDateString('en-us', { month: "short", day: "numeric" })
          this.dataJsonsArrayFecha.push(fehcaIn);
          this.dataJsonsArraySaldo.push((trans.capreducido - trans.valorCap))
        } else if (trans.estado == "APROBADO") {
          var fehcaIn = new Date(trans.fabono).toLocaleDateString('en-us', { month: "short", day: "numeric" })
          this.dataJsonsArrayFecha.push(fehcaIn);
          this.dataJsonsArraySaldo.push((trans.capreducido - trans.valorCap))
        }
        else {
          var fehcaIn = new Date().toLocaleDateString('en-us', { month: "short", day: "numeric" })
          this.dataJsonsArrayFecha.push(fehcaIn);
          this.dataJsonsArraySaldo.push(trans.capreducido)
        }
      }
      i++
    }
    for (let j = 0; j < dataArray.length; j++) {
      let k = j;
      var data = dataArray.length > k + 1 ? dataArray[k + 1]['t'] : "1";
      if (dataArray[j]['t'] != data) {
        this.dataJsonsArray.push(dataArray[j])
      }
    }
  }

  ngAfterViewInit() {
    this.drawChart()
  }

  drawChart() {
    Chart.register(ChartDataLabels, LineController, BarController, BarElement, LineElement, PointElement, LinearScale, Title, ArcElement, CategoryScale, Tooltip, callbacks, Legend, TimeScale);
    //***********************/ChartsJS
    if (this.data[0].band == "04") {
      this.chart = new Chart('saldosTiempo', {
        type: 'line',
        data: {
          datasets: [{
            label: 'Saldo vs Tiempo',
            backgroundColor: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
            borderColor: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
            data: this.dataJsonsArraySaldo,
            type: 'line',
            pointRadius: 3,
            fill: true,
            borderWidth: 3,
          }],
          labels: this.dataJsonsArrayFecha
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              intersect: true, //////////////////////////ANALIZAR
              mode: 'index',
              callbacks: {
                title: function (data) {
                  let label = data[0].label;
                  if ((new Date(label)).toDateString() == (new Date(0)).toDateString()) {
                    return ""
                  }
                  else {
                    let month = label.match(/Jan|Apr|Aug|Dec/g);
                    if ((localStorage.getItem('locale') == 'en') || !month)
                      return label;
                    else {
                      let result = month[0];
                      switch (month[0]) {
                        case 'Jan':
                          result = 'Ene';
                          break;
                        case 'Apr':
                          result = 'Abr';
                          break;
                        case 'Aug':
                          result = 'Ago';
                          break;
                        case 'Dec':
                          result = 'Dic';
                          break;
                      }
                      return result;
                    }
                  }
                },
                label: function (context) {
                  //get the concerned dataset
                  var dataset = context.dataset.data;
                  //get the current items value
                  var currentValue = dataset[context.dataIndex];
                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                  var percentage = Number(currentValue).toFixed(2);
                  return "$" + (new CantidadMonetaria().transform(percentage.toString())).toString();
                },
              }
            },
            datalabels: {
              display: false
            },

            legend: {
              position: 'left',
              display: false,
              labels: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
                font: {
                  size: 25,
                  weight: 'bold'
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: false,
                text: 'Date'
              },
              ticks: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
              },
            },
            y: {
              title: {
                display: false,
                text: 'value'
              },
              ticks: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
              },
            },
            pointLabel: {
              display: false
            }
          }
        },
      }
      )
    }
    if (this.data[0].band == "05") {
      this.chart = new Chart('saldosTiempo', {
        type: 'bar',
        data: {
          datasets: [{
            label: 'Saldo vs Tiempo',
            backgroundColor: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
            borderColor: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
            data: this.dataJsonsArraySaldo,
            type: 'bar',
            borderWidth: 0,
            barPercentage: this.dataJsonsArraySaldo.length > 1 ? 0.6 : 0.1,
          }],
          labels: this.dataJsonsArrayFecha
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              intersect: true, //////////////////////////ANALIZAR
              mode: 'index',
              callbacks: {
                title: function (data) {
                  let label = data[0].label;
                  if ((new Date(label)).toDateString() == (new Date(0)).toDateString()) {
                    return ""
                  }
                  else {
                    let month = label.match(/Jan|Apr|Aug|Dec/g);
                    if ((localStorage.getItem('locale') == 'en') || !month)
                      return label;
                    else {
                      let result = month[0];
                      switch (month[0]) {
                        case 'Jan':
                          result = 'Ene';
                          break;
                        case 'Apr':
                          result = 'Abr';
                          break;
                        case 'Aug':
                          result = 'Ago';
                          break;
                        case 'Dec':
                          result = 'Dic';
                          break;
                      }
                      return result;
                    }
                  }
                },
                label: function (context) {
                  //get the concerned dataset
                  var dataset = context.dataset.data;
                  //get the current items value
                  var currentValue = dataset[context.dataIndex];
                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                  var percentage = Number(currentValue).toFixed(2);
                  return "$" + (new CantidadMonetaria().transform(percentage.toString())).toString();
                },
              }
            },
            datalabels: {
              display: false
            },
            legend: {
              position: 'left',
              display: false,
              labels: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
                font: {
                  size: 25,
                  weight: 'bold'
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: false,
                text: 'Date'
              },
              ticks: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
              },
            },
            y: {
              title: {
                display: false,
                text: 'value'
              },
              ticks: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
              },
            },
            pointLabel: {
              display: false
            }
          }
        },
      }
      )
    }
    if (this.data[0].band == "06") {
      this.chart = new Chart('saldosTiempo', {
        type: 'line',
        data: {
          datasets: [{
            label: 'Saldo vs Tiempo',
            backgroundColor: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
            borderColor: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
            data: this.dataJsonsArraySaldo,
            type: 'line',
            pointRadius: 3,
            fill: true,
            borderWidth: 3,
          }],
          labels: this.dataJsonsArrayFecha
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              intersect: true, //////////////////////////ANALIZAR
              mode: 'index',
              callbacks: {
                title: function (data) {
                  let label = data[0].label;
                  if ((new Date(label)).toDateString() == (new Date(0)).toDateString()) {
                    return ""
                  }
                  else {
                    let month = label.match(/Jan|Apr|Aug|Dec/g);
                    if ((localStorage.getItem('locale') == 'en') || !month)
                      return label;
                    else {
                      let result = month[0];
                      switch (month[0]) {
                        case 'Jan':
                          result = 'Ene';
                          break;
                        case 'Apr':
                          result = 'Abr';
                          break;
                        case 'Aug':
                          result = 'Ago';
                          break;
                        case 'Dec':
                          result = 'Dic';
                          break;
                      }
                      return result;
                    }
                  }
                },
                label: function (context) {
                  //get the concerned dataset
                  var dataset = context.dataset.data;
                  //get the current items value
                  var currentValue = dataset[context.dataIndex];
                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                  var percentage = Number(currentValue).toFixed(2);
                  return "$" + (new CantidadMonetaria().transform(percentage.toString())).toString();
                },
              }
            },
            datalabels: {
              display: false
            },
            legend: {
              position: 'left',
              display: false,
              labels: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
                font: {
                  size: 25,
                  weight: 'bold'
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: false,
                text: 'Date'
              },
              ticks: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
              },
            },
            y: {
              title: {
                display: false,
                text: 'value'
              },
              ticks: {
                color: (this.tema == 'light-theme') ? '#00AE39' : '#ffffff',
              },
            },
            pointLabel: {
              display: false
            }
          }
        },
      }
      )
    }
  }

  getColorPorTema() {
    return (this.tema == 'night-theme') ? 'white' : 'black';
  }

  translate_this_label(label) {
    let month = label.match(/Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Nov|Dec/g);
    if (!month)
      return label;
    let translation = this.translate_month(month[0]);
    return label.replace(month, translation, 'g');
  }

  translate_month(month) {
    let result = month;
    switch (month) {
      case 'Feb':
        result = 'Fev';
        break;
      case 'Apr':
        result = 'Abr';
        break;
      case 'May':
        result = 'Mai';
        break;
      case 'Aug':
        result = 'Ago';
        break;
      case 'Sep':
        result = 'Set';
        break;
      case 'Dec':
        result = 'Dez';
        break;
    }
    return result;
  }
}
