<div class="textura" *ngIf="!isBanred">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <h2 mat-dialog-title style="text-align: center;">ERROR</h2>
    <a style="font-size: 12px !important;">{{mensaje}}</a>
    <mat-dialog-content>
        <div style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/error.png" class="imagen3" />
        </div>
    </mat-dialog-content>
</div>

<div class="textura" *ngIf="isBanred">
    <h2 mat-dialog-title style="text-align: center;">ERROR</h2>
    <mat-dialog-content>
        <div style="text-align: center;">
            <a style="font-size: 12px !important;">{{mensaje}}</a>
            <img src="assets/resources/mipmap-hdpi/error.png" class="imagen3" />
        </div>
        <div style="text-align: center;">
            <a style="font-size: 12px !important;">{{idiomas.EnviarSPI}}</a>
        </div>
        <div style="text-align: center;">
            <button mat-raised-button class="buttonToken" (click)="enviar()" color="primary">{{aceptar}}</button>
            <button mat-raised-button class="buttonToken" (click)="close()">{{rechazar}}</button>
        </div>
    </mat-dialog-content>
</div>