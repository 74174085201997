<div id="transaccionesTab">
  <div class="card-detalle-cuentas"
    *ngIf="(parametros.subsistema == 04 || parametros.subsistema == 33 || parametros.subsistema == 77 || parametros.subsistema == 99)">
    <mat-card class="cards" *ngIf="!isMobile">
      <div class="container1">
        <div *ngFor="let dato of data">
          <mat-card class="cardTitle">
            <div class="row">
              <div class="col-4">
                <b>{{idiomas.NCuenta}}</b>
                <p>{{dato.ccuenta | ocultarCuentaPosTrans}}</p>
              </div>
              <div class="col-4">
                <b>{{idiomas.Producto}}</b>
                <p>{{dato.dproducto}}</p>
              </div>
              <div class="col-4"
                *ngIf="parametros.subsistema != 33 && parametros.subsistema != 77 && parametros.subsistema != 99">
                <b>{{idiomas.DetalleTransferencia}}</b>
                <p><button mat-icon-button (click)="mostrarDialogo(dato,'04')">
                    <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                      title={{idiomas.DetalleTransferencia}}>
                      library_books
                    </mat-icon>
                  </button></p>
              </div>
              <div class="col-4"
                *ngIf="parametros.subsistema == 33 || parametros.subsistema == 77 || parametros.subsistema == 99">
                <b>{{idiomas.Movimientos}}</b>
                <p><button mat-icon-button (click)="mostrarTxDesdeTx(dato.ccuenta,dato.subsistema,dato.grupo)">
                    <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                      title={{idiomas.Movimientos}}>
                      list</mat-icon>
                  </button></p>
              </div>
            </div>
          </mat-card>
          <mat-card class="cardBody" style="padding-top: 10px !important;" *ngIf="parametros.grupo != 07">
            <div class="row">
              <div class="col-3">
                <b>{{idiomas.SaldoTotal}}</b>
                <p>{{dato.accounting | cantidadMonetaria}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.SDisponible}}</b>
                <p>{{dato.available | cantidadMonetaria}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.VBloqueado}}</b>
                <p>{{dato.blocked | cantidadMonetaria}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.Estado}}</b>
                <p>{{dato.estado}}</p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-card>
    <div *ngIf="isMobile">
      <owl-carousel-o [options]="customCarouselOptions">
        <ng-container>
          <ng-template carouselSlide>
            <div *ngFor="let dato of data">
              <mat-card class="cards-mobile-transactions" style="height: auto !important; padding: 12px !important;">
                <div class="row">
                  <div class="col-4">
                    <b style="font-size: .8em;">{{idiomas.NCuenta}}</b>
                    <p style="font-size: .7em;">{{dato.ccuenta | ocultarCuentaPosTrans}}</p>
                  </div>
                  <div class="col-4">
                    <b style="font-size: .8em;">{{idiomas.Producto}}</b>
                    <p style="font-size: .7em; margin-bottom: 0 !important;">{{dato.dproducto}}</p>
                  </div>
                  <div class="col-4"
                    *ngIf="parametros.subsistema != 33 && parametros.subsistema != 77 && parametros.subsistema != 99">
                    <b style="font-size: .8em;">{{idiomas.DetalleTransferencia}}</b>
                    <p style="margin-bottom: 0 !important;"><button mat-icon-button (click)="mostrarDialogo(dato,'04')">
                        <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                          title={{idiomas.DetalleTransferencia}}>
                          library_books
                        </mat-icon>
                      </button></p>
                  </div>
                  <div class="col-3"
                    *ngIf="parametros.subsistema == 33 || parametros.subsistema == 77 || parametros.subsistema == 99">
                    <b style="font-size: .8em;">{{idiomas.Movimientos}}</b>
                    <p><button mat-icon-button (click)="mostrarTxDesdeTx(dato.ccuenta,dato.subsistema,dato.grupo)">
                        <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                          title={{idiomas.Movimientos}}>
                          list</mat-icon>
                      </button></p>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <br *ngIf="parametros.grupo != 07 && isMobile">
  </div>

  <!--Aqui inicia lo de poliza-->
  <div class="card-detalle-cuentas" *ngIf="parametros.subsistema == 05">
    <mat-card class="cards" *ngIf="!isMobile">
      <div class="container1">
        <div *ngFor="let dato of data">
          <mat-card class="cardTitle">
            <div class="row">
              <div class="col-4">
                <b>{{idiomas.NCuenta}}</b>
                <p>{{dato.ccuenta | ocultarCuentaPosTrans}}</p>
              </div>
              <div class="col-4">
                <b>{{idiomas.Producto}}</b>
                <p>{{dato.dproducto}}</p>
              </div>
              <div class="col-4" style="text-align: center;">
                <b>{{idiomas.DetalleTransferencia}}</b>
                <p><button mat-icon-button (click)="mostrarDialogo(dato,'05')">
                    <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                      title={{idiomas.DetalleTransferencia}}>
                      library_books
                    </mat-icon>
                  </button></p>
              </div>
            </div>
          </mat-card>
          <mat-card class="cardBody" style="padding-top: 10px !important;" *ngIf="parametros.grupo != 07">
            <div class="row">
              <div class="col-3">
                <b>{{idiomas.Capitalpl}}</b>
                <p>{{dato.monto | cantidadMonetaria}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.Tasa}}</b>
                <p>{{dato.tasa | cantidadMonetaria}}%</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.Vencimientopl}}</b>
                <p>{{dato.fvencimiento | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.Estado}}</b>
                <p>{{dato.estado}}</p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-card>

    <div *ngIf="isMobile">
      <owl-carousel-o [options]="customCarouselOptions">
        <ng-container>
          <ng-template carouselSlide>
            <div *ngFor="let dato of data">
              <mat-card class="cards-mobile-transactions" style="height: auto !important; padding: 12px !important;">
                <div class="row">
                  <div class="col-5">
                    <b style="font-size: .8em;">{{idiomas.NCuenta}}</b>
                    <p style="font-size: .7em;">{{dato.ccuenta | ocultarCuentaPosTrans}}</p>
                  </div>
                  <div class="col-4">
                    <b style="font-size: .8em;">{{idiomas.Producto}}</b>
                    <p style="font-size: .7em; margin-bottom: 0 !important;">{{dato.dproducto}}</p>
                  </div>
                  <div class="col-3" style="text-align: center;">
                    <b style="font-size: .8em;">{{idiomas.DetalleTransferencia}}</b>
                    <p style="margin-bottom: 0 !important;"><button mat-icon-button (click)="mostrarDialogo(dato,'05')">
                        <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                          title={{idiomas.DetalleTransferencia}}>
                          library_books
                        </mat-icon>
                      </button></p>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>

  <!--Aqui incia lo de prestamo-->
  <div class="card-detalle-cuentas" *ngIf="parametros.subsistema == 06">
    <mat-card *ngIf="!isMobile" class="cards">
      <div class="container1">
        <div *ngFor="let dato of data">
          <mat-card class="cardTitle">
            <div class="row">
              <div class="col-5">
                <b>{{idiomas.NOperacion}}</b>
                <p>{{dato.ccuenta |
                  ocultarCuentaPosTrans}}
                  <!--<mat-chip-list *ngIf="internet.internet" title={{idiomas.loanPayment}} style="display: inline-block;">
                    <mat-chip selected (click)="pagoPrestamo()" style="cursor: pointer !important;
                      min-height: 22px !important;
                      min-width: 80px !important;
                      justify-content: center;
                      font-size: 1em;">
                      {{idiomas.Pagar}}</mat-chip>
                  </mat-chip-list>-->
                </p>
              </div>
              <div class="col-4">
                <b>{{idiomas.Producto}}</b>
                <p>{{dato.dproducto}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.DetalleTransferencia}}</b>
                <p><button mat-icon-button (click)="mostrarDialogo(dato,'06')">
                    <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                      title={{idiomas.DetalleTransferencia}}>
                      library_books
                    </mat-icon>
                  </button></p>
              </div>
            </div>
          </mat-card>
          <mat-card class="cardBody" style="padding-top: 10px !important;" *ngIf="parametros.grupo != 07">
            <div class="row">
              <div class="col-3">
                <b>{{idiomas.Capitalpl}}</b>
                <p>{{dato.capital | cantidadMonetaria}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.Tasa}}</b>
                <p>{{dato.tasa | cantidadMonetaria}}%</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.Vencimientopl}}</b>
                <p>{{dato.fvencimiento | formatoFecha:idiomas.IdiomaSeleccionado}}</p>
              </div>
              <div class="col-3">
                <b>{{idiomas.Estado}}</b>
                <p>{{dato.estado}}</p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-card>

    <div *ngIf="isMobile">
      <owl-carousel-o [options]="customCarouselOptions">
        <ng-container>
          <ng-template carouselSlide>
            <div *ngFor="let dato of data">
              <mat-card class="cards-mobile-transactions" style="height: auto !important; padding: 12px !important;">
                <div class="row">
                  <div class="col-5">
                    <b style="font-size: .8em;">{{idiomas.NOperacion}}</b>
                    <p style="font-size: .7em;">{{dato.ccuenta | ocultarCuentaPosTrans}}
                      <!--<mat-chip-list *ngIf="internet.internet" title={{idiomas.loanPayment}}
                        style="display: inline-block;">
                        <mat-chip selected (click)="pagoPrestamo()" style="cursor: pointer !important;
                          min-height: 22px !important;
                          min-width: 80px !important;
                          justify-content: center;
                          font-size: 1em;">
                          {{idiomas.Pagar}}</mat-chip>
                      </mat-chip-list>-->
                    </p>
                  </div>
                  <div class="col-4">
                    <b style="font-size: .8em;">{{idiomas.Producto}}</b>
                    <p style="font-size: .7em; margin-bottom: 0 !important;">{{dato.dproducto}}</p>
                  </div>
                  <div class="col-3">
                    <b style="font-size: .8em;">{{idiomas.DetalleTransferencia}}</b>
                    <p style="margin-bottom: 0 !important;"><button mat-icon-button (click)="mostrarDialogo(dato,'06')">
                        <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                          title={{idiomas.DetalleTransferencia}}>
                          library_books
                        </mat-icon>
                      </button></p>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>

  <!--Inicia prestamo preaprobados-->
  <div class="card-detalle-cuentas" *ngIf="parametros.subsistema == 'PRESTAMOPREAPROBADO'">
    <mat-card *ngIf="!isMobile" class="cards" style="height: auto !important; padding: 12px !important;">
      <div class="container1">
        <div *ngFor="let dato of data">
          <mat-card class="cardTitle">
            <div class="row">
              <div class="col-5">
                <b style="font-size: .8em;">{{idiomas.CredioPreaprobado}}</b>
                <p style="font-size: .8em;">{{data[0].estado=='A'?'':dato.montohasta |
                  cantidadMonetaria}}</p>
              </div>
              <div class="col-4">
                <b style="font-size: .8em;">{{idiomas.Producto}}</b>
                <p style="font-size: .8em;">{{dato.cuenta}}</p>
              </div>
              <div class="col-3">
                <b style="font-size: .8em;">{{idiomas.DetalleTransferencia}}</b>
                <p><button mat-icon-button (click)="mostrarDialogo(dato,'PRESTAMOPREAPROBADO')">
                    <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                      title={{idiomas.DetalleTransferencia}}>
                      library_books
                    </mat-icon>
                  </button></p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-card>

    <div *ngIf="isMobile">
      <owl-carousel-o [options]="customCarouselOptions">
        <ng-container>
          <ng-template carouselSlide>
            <div *ngFor="let dato of data">
              <mat-card class="cards-mobile-transactions">
                <div class="row">
                  <div class="col-5">
                    <b style="font-size: .8em;">{{idiomas.CredioPreaprobado}}</b>
                    <p style="font-size: .8em;">{{data[0].estado=='A'?'':dato.montohasta |
                      cantidadMonetaria}}</p>
                  </div>
                  <div class="col-4">
                    <b style="font-size: .8em;">{{idiomas.Producto}}</b>
                    <p>{{dato.cuenta}}</p>
                  </div>
                  <div class="col-3">
                    <b>{{idiomas.DetalleTransferencia}}</b>
                    <p><button mat-icon-button (click)="mostrarDialogo(dato,'PRESTAMOPREAPROBADO')">
                        <mat-icon style="font-size: 30px; height: 50px; width: 50px; padding-top: 5px;"
                          title={{idiomas.DetalleTransferencia}}>
                          library_books
                        </mat-icon>
                      </button></p>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
  <br *ngIf="!isMobile">

  <!--Aqui inicia los movimientos por cuenta-->
  <mat-card class="cards" *ngIf="!isMobile">
    <div [class.formBottomTrans]="muestraGrafico">
      <div *ngIf="parametros.subsistema == 04 && datosBand && deshabilitarTransacciones">
        <mat-label *ngIf="bandMovements" style="font-size: .6em; opacity: 0.9; padding-bottom: 10px;">
          {{idiomas.MensajeEstadoCuenta}}</mat-label><br>
        <div *ngIf="bandMovements" class="form-row" style="padding-top: 10px;">
          <div class="col-4" style="min-width: 165px; max-width: 165px;">
            <mat-form-field style="font-size: .85em;" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.FDesde}}</mat-label>
              <input matInput readonly [ngModel]="dated | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
                [ngModelOptions]="{standalone: true}">
              <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="dated" [max]="maxDate"
                [min]="minimunDate" [matDatepicker]="pickerdesde" (dateChange)="consultar04()">
              <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
              <mat-datepicker touchUi #pickerdesde></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-4" style="min-width: 165px; max-width: 165px;">
            <mat-form-field style="font-size: .85em;" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.FHasta}}</mat-label>
              <input matInput readonly [ngModel]="dateh | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
                [ngModelOptions]="{standalone: true}">
              <input matInput readonly name="fhasta" [hidden]='true' [(ngModel)]="dateh" [max]="maxDate"
                [min]="minimunDate" [matDatepicker]="pickerhasta" (dateChange)="consultar04()">
              <mat-datepicker-toggle matSuffix [for]="pickerhasta"></mat-datepicker-toggle>
              <mat-datepicker touchUi #pickerhasta></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="bandMovements" class="form-row">
            <div class="col-2" style="margin-top: 5px;">
              <button class="btn btnGrafico" color="primary" (click)="genrerarEstadoCuenta()">
                <mat-icon title={{idiomas.estodoCuentaTC}}>library_books</mat-icon>
              </button>
            </div>
            <div class="col-2" style="margin-top: 5px;margin-left: 50%">
              <button class="btn btnGrafico" color="primary" (click)="muestraGrafico=!muestraGrafico">
                <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
                <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div id="loadData_04" style="visibility:hidden; padding-bottom: 10px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf=intentoCom style="padding-bottom: 10px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <!-- Grafico Linea de Tiempo -->
        <div *ngIf="(muestraGrafico) && (datosGrafico.length>0) && (parametros.subsistema == 04 && peticion)"
          [ngClass]="{'ceroHeight':!muestraGrafico}">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.GrafTrans04}}</strong>
              </h6>
            </div>
          </div>
          <div class="row">
            <app-grafico-saldos-tiempo *ngIf="muestraGrafico" class="col" [data]="datosGrafico" [idiomas]="idiomas">
            </app-grafico-saldos-tiempo>
          </div>
        </div>
        <div id="cardTrans04" *ngIf="parametros.subsistema == 04 && peticion">
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('ahorrost','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container2Aux" [class.heightconteiner2]="!(datosTabla[0].length>numMaxRow)"
              (scroll)="scroll('ahorrost')">
              <table mat-table [dataSource]="datosTabla[0]" class="ahorrost" id="tabla04Trans">
                <ng-container matColumnDef="Fecha">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Fecha}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas" style="min-width: 105px;">
                    {{dato.fecha | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="Hora">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Hora}}</th>
                  <td mat-cell *matCellDef="let dato" class="hora">{{dato.hora | horaSubString}}</td>
                </ng-container>
                <ng-container matColumnDef="Movimientos">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Transaccion}}</th>
                  <td mat-cell *matCellDef="let dato" class="movimientos" style="padding-left: 10px">
                    {{dato.descripcion}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="+/-">
                  <th mat-header-cell *matHeaderCellDef class="debitocredito rightp">+/-</th>
                  <td mat-cell *matCellDef="let dato" class="debitocredito right">{{dato.debitocredito}}</td>
                </ng-container>
                <ng-container matColumnDef="Monto">
                  <th mat-header-cell *matHeaderCellDef class="right rightp">{{idiomas.Monto}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros right" [class.norBalCellWidth]=normal
                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                    {{dato.debitocredito}} {{dato.valormonedacuenta | cantidadMonetaria}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Saldo Contable">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.SaldoContable}}</th>
                  <td mat-cell *matCellDef="let dato" class="scontable" [class.norBalCellWidth]=normal
                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                    {{dato.saldocontable | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Oficina">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Sucursal}}</th>
                  <td mat-cell *matCellDef="let dato" class="oficina left">{{dato.sucursal}}</td>
                </ng-container>
                <ng-container matColumnDef="Referencia">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Referencia}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.documento}}</td>
                </ng-container>
                <ng-container matColumnDef="Imprimir">
                  <th mat-header-cell *matHeaderCellDef [hidden]="!internet.internet" class="left">{{idiomas.Imprimir}}
                  </th>
                  <td mat-cell *matCellDef="let dato; let i = index" style="text-align:center;cursor: pointer"
                    [hidden]="!internet.internet">
                    <a (click)="comprobantePDF(i)"><mat-icon color="primary"
                        style="font-size: 30px; height: 30px; width: 30px;"> picture_as_pdf</mat-icon></a>
                  </td>
                </ng-container>
                <tr mat-header-row class="mayt-header-row" *matHeaderRowDef="displayedColumnsTAhorros; sticky: true">
                </tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsTAhorros"
                  [ngClass]="{gray: even}">{{showHideRows(parametros.subsistema,last,muestraGrafico)}}</tr>
              </table>
            </div>
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('ahorrost','right')">
                keyboard_arrow_right
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div id="loadData_05_06" style="visibility:hidden;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <!-- Grafico Linea de Tiempo -->
      <div
        *ngIf="(muestraGrafico) && (datosGrafico.length>0) && (parametros.subsistema == 05 && datosBand && bandData0506)"
        [ngClass]="{'ceroHeight':!muestraGrafico}">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.GrafTrans05}}</strong>
            </h6>
          </div>
          <div style="margin-top: -8px; margin-right: 1%">
            <button class="btn btnGrafico" color="primary" (click)="muestraGrafico=!muestraGrafico">
              <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
              <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <app-grafico-saldos-tiempo *ngIf="muestraGrafico" class="col" [data]="datos2[0]" [idiomas]="idiomas">
          </app-grafico-saldos-tiempo>
        </div>
      </div>
      <div *ngIf="parametros.subsistema == 05 && datosBand && bandData0506">
        <div id="cardTrans05">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.DetallePago}}</strong>
              </h6>
            </div>
            <div style="margin-top: -8px; margin-right: 1%">
              <button *ngIf="!muestraGrafico" class="btn btnGrafico" color="primary"
                (click)="muestraGrafico=!muestraGrafico">
                <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
                <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf=intento>
            <mat-progress-bar style="margin-top: 10px; margin-bottom: 10px; " *ngIf=intento mode="indeterminate">
            </mat-progress-bar>
          </div>
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('pfijot','left')">keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container2Aux" [class.heightconteiner2]="!(datosTabla[0].length>numMaxRow)"
              (scroll)="scroll('pfijot')">
              <table mat-table [dataSource]="datosTabla[0]" class="pfijot" id="tabla05Trans">
                <ng-container matColumnDef="NumCuotas">
                  <th mat-header-cell *matHeaderCellDef class="center">{{idiomas.NumCuota}}</th>
                  <td mat-cell *matCellDef="let dato" class="center">{{dato.cuota}}</td>
                </ng-container>
                <ng-container matColumnDef="Capital">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Capitalpl}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros" [class.norBalCellWidth]=normal
                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                    {{dato.totalCapital | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Interes">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Interes}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros" [class.norBalCellWidth]=normal
                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                    {{dato.interesPagado | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Impuesto">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Impuesto}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros" [class.norBalCellWidth]=normal
                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>{{dato.impuesto | cantidadMonetaria}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="ValCuota">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.ValCuota}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros" [class.norBalCellWidth]=normal
                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>{{dato.valorcuota |
                    cantidadMonetaria}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Vencimiento">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Vencimientopl}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas left" style="min-width: 90px;">
                    {{dato.fecha | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="Dias">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Dias}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.dias}}</td>
                </ng-container>
                <ng-container matColumnDef="Estado">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Estado}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas left">{{dato.estado}}</td>
                </ng-container>
                <tr mat-header-row class="mayt-header-row" *matHeaderRowDef="displayedColumnsTPlazo; sticky: true"></tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even;let last = last; columns: displayedColumnsTPlazo"
                  [ngClass]="{gray: even}">
                  {{showHideRows(parametros.subsistema,last,muestraGrafico)}}</tr>
              </table>
            </div>
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('pfijot','right')">
                keyboard_arrow_right
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin -->

      <!-- Grafico Linea de Tiempo -->
      <div
        *ngIf="(muestraGrafico) && (datosGrafico.length>0) && (parametros.subsistema == 06 && datosBand && bandData0506)"
        class="cardsGrafico cardsGraficoNoBoxShadow bkGraficoPosCons" [ngClass]="{'ceroHeight':!muestraGrafico}">
        <div class="row">
          <div class="center col texto">
            <h6>
              <strong>{{idiomas.GrafTrans06}}</strong>
            </h6>
          </div>
          <div style="margin-top: -8px; margin-right: 4%">
            <button class="btn btnGrafico" color="primary" (click)="muestraGrafico=!muestraGrafico">
              <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
              <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <app-grafico-saldos-tiempo *ngIf="muestraGrafico" class="col" [data]="datosGrafico" [idiomas]="idiomas">
          </app-grafico-saldos-tiempo>
        </div>
      </div>
      <div *ngIf="parametros.subsistema == 06 && datosBand && bandData0506">
        <div id="cardTrans06">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.DetalleAmortizacion}}</strong>
              </h6>
            </div>
            <div style="margin-top: -8px; margin-right: 1%">
              <button *ngIf="!muestraGrafico" class="btn btnGrafico" color="primary"
                (click)="muestraGrafico=!muestraGrafico">
                <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
                <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
              </button>
            </div>
          </div>
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('prestamost','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container2Aux" [class.heightconteiner2]="!(datosTabla[0].length>numMaxRow)"
              (scroll)="scroll('prestamost')">
              <table mat-table [dataSource]="datosTabla[0]" class="prestamost" id="tabla06Trans">
                <ng-container matColumnDef="NumCuota">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.NumCuota}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.cuota}}</td>
                </ng-container>
                <ng-container matColumnDef="CapReducido">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.CapReducido}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.capreducido | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Dias">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Dias}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.numdiascal}}</td>
                </ng-container>
                <ng-container matColumnDef="DiasMora">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.DiasMora}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.diasMora}}</td>
                </ng-container>
                <ng-container matColumnDef="Capital">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Capitalpl}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.valorCap | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Interes">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Interes}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.valorInt | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Comision">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Comision}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.comision | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Seguros">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Seguros}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.valorSeg | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Cargos">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Cargos}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.cargo | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="TotCuota">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.TotCuota}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.valorPagar | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="AbonoCuota">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Abono}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.abonoCuota | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="SaldoCuota">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Saldo}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.saldoCuota | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="ValorDiferimiento">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.VDiferimiento}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.valorDiferimiento |
                    cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="InteresDifrerimiento">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.IDiferimiento}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.interesDiferimiento |
                    cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="ValorMora">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.InteresesMora}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.valorMora | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="ValorPagar">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.VPagar}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros left">{{dato.valorPagar | cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="Vencimiento">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Vencimientopl}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas left" style="min-width: 100px;">
                    {{dato.fecha | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="FPago">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.FPago}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas left" style="min-width: 80px;">
                    {{dato.fpago | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="Fabono">
                  <th mat-header-cell *matHeaderCellDef class="left" style="padding-right: 10px">{{idiomas.Fabono}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas left" style="min-width: 80px;">
                    {{dato.fabono | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="Estado">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Estado}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas">{{dato.estado}}</td>
                </ng-container>
                <tr mat-header-row class="mayt-header-row" *matHeaderRowDef="displayedColumnsTPrestamos; sticky: true">
                </tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsTPrestamos;"
                  [ngClass]="{gray: even}">{{showHideRows(parametros.subsistema,last,muestraGrafico)}}</tr>
              </table>
            </div>
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('prestamost','right')">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="parametros.subsistema == 33 && bandBloqueos">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.Bloqueos}}</strong>
            </h6>
          </div>
        </div>
        <div class="row" style="margin-left: -24px !important;">
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('bloqueos','left')">
              keyboard_arrow_left
            </mat-icon>
          </div>
          <div class="col container2Aux" (scroll)="scroll('bloqueos')">
            <table mat-table [dataSource]="dataSourceBlo" class="bloqueos">
              <ng-container matColumnDef="Nro">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Numero}}</th>
                <td mat-cell *matCellDef="let row">{{row.SBLOQUEOFONDOS}}</td>
              </ng-container>
              <ng-container matColumnDef="Fecha">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Fecha}}</th>
                <td mat-cell *matCellDef="let row" class="fechas left" style="min-width: 95px;">
                  {{row.FECHA | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
              </ng-container>
              <ng-container matColumnDef="Hora">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Hora}}</th>
                <td mat-cell *matCellDef="let row">{{row.HORA}}</td>
              </ng-container>
              <ng-container matColumnDef="Concepto">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Concepto}}</th>
                <td mat-cell *matCellDef="let row" class="left">{{row.CONCEPTO}}</td>
              </ng-container>
              <ng-container matColumnDef="Monto">
                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Monto}}</th>
                <td mat-cell *matCellDef="let row" class="numeros" [class.norBalCellWidth]=normal
                  [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                  {{row.MONTOPENDIENTE | cantidadMonetaria}}</td>
              </ng-container>
              <ng-container matColumnDef="Vencimiento">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Vencimientopr}}</th>
                <td mat-cell *matCellDef="let row" class="fechas left" style="min-width: 95px;">
                  {{row.FVENCIMIENTO | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr id="celda" mat-row *matRowDef="let row; let even = even ;let last = last;columns: displayedColumns"
                [ngClass]="{gray: even}">
                {{showHideRows(parametros.subsistema,last)}}</tr>
            </table>
          </div>
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('bloqueos','right')">
              keyboard_arrow_right</mat-icon>
          </div>
        </div>
      </div>
      <div *ngIf="parametros.subsistema == 77 && bandPorConf && deshabilitarConfirmar">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.ChequesLocales}}</strong>
            </h6>
          </div>
        </div>
        <div class="row" style="margin-left: -24px !important;">
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaL1 id="flechaL1" (click)="scrollTo2('saldoscheques','left',0)">
              keyboard_arrow_left
            </mat-icon>
          </div>
          <div class="col container3Aux" [class.heightconteiner2]="!(dataSourceCheLoc.length>numMaxRow)"
            (scroll)="scroll2('saldoscheques',0)">
            <table mat-table [dataSource]="dataSourceCheLoc" class="saldoscheques">
              <ng-container matColumnDef="FechaDeposito">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.FDeposito}}</th>
                <td mat-cell *matCellDef="let row" class="fechas">
                  {{row.FDEPOSITO | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
              </ng-container>
              <ng-container matColumnDef="Hora">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Hora}}</th>
                <td mat-cell *matCellDef="let row">{{row.HORA}}</td>
              </ng-container>
              <ng-container matColumnDef="Banco">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Banco}}</th>
                <td mat-cell *matCellDef="let row" class="left">{{row.BANCO}}</td>
              </ng-container>
              <ng-container matColumnDef="Monto">
                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Monto}}</th>
                <td mat-cell *matCellDef="let row" class="numeros" [class.norBalCellWidth]=normal
                  [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>{{row.MONTO | cantidadMonetaria}}
                </td>
              </ng-container>
              <ng-container matColumnDef="FechaConfirmacion">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.FConfirmacion}}</th>
                <td mat-cell *matCellDef="let row" class="fechas left">
                  {{row.FCONFIRMACION | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
              </ng-container>
              <ng-container matColumnDef="NumCheque">
                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Cheque}}</th>
                <td mat-cell *matCellDef="let row" class="numeros">{{row.NUMEROCHEQUE}}</td>
              </ng-container>
              <ng-container matColumnDef="CtaGirada">
                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Cgirada}}</th>
                <td mat-cell *matCellDef="let row" class="numeros">{{row.CUENTAGIRADA}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsChequesLocales; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; let even = even; let last = last;columns: displayedColumnsChequesLocales"
                [ngClass]="{gray: even}">{{showHideRows2(parametros.subsistema,last,1)}}</tr>
            </table>
          </div>
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaR1 id="flechaR1" (click)="scrollTo2('saldoscheques','right',0)">
              keyboard_arrow_right</mat-icon>
          </div>
        </div>
        <br>
        <div *ngIf=bandPorConf2 id="cardTrans77">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.Chequesotros}}</strong>
              </h6>
            </div>
          </div>
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaL2 id="flechaL2" (click)="scrollTo2('saldoscheques','left',1)">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container3Aux" [class.heightconteiner2]="!(dataSourceCheOts.length>numMaxRow)"
              (scroll)="scroll2('saldoscheques',1)">
              <table mat-table [dataSource]="dataSourceCheOts" class="saldoscheques">
                <ng-container matColumnDef="FechaDeposito">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.FDeposito}}</th>
                  <td mat-cell *matCellDef="let row" class="fechas">
                    {{row.FDEPOSITO | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="Hora">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Hora}}</th>
                  <td mat-cell *matCellDef="let row">{{row.HORA}}</td>
                </ng-container>
                <ng-container matColumnDef="Banco">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Banco}}</th>
                  <td mat-cell *matCellDef="let row" class="left">{{row.BANCO}}</td>
                </ng-container>
                <ng-container matColumnDef="Monto">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Monto}}</th>
                  <td mat-cell *matCellDef="let row" class="numeros" [class.norBalCellWidth]=normal
                    [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>{{row.MONTO | cantidadMonetaria}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="FechaConfirmacion">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.FConfirmacion}}</th>
                  <td mat-cell *matCellDef="let row" class="fechas left">
                    {{row.FCONFIRMACION | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
                </ng-container>
                <ng-container matColumnDef="NumCheque">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Cheque}}</th>
                  <td mat-cell *matCellDef="let row" class="numeros">{{row.NUMEROCHEQUE}}</td>
                </ng-container>
                <ng-container matColumnDef="CtaGirada">
                  <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Cgirada}}</th>
                  <td mat-cell *matCellDef="let row" class="numeros">{{row.CUENTAGIRADA}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsChequesOtros; sticky: true"></tr>
                <tr mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsChequesOtros"
                  [ngClass]="{gray: even}">{{showHideRows2(parametros.subsistema,last,2)}}</tr>
              </table>
            </div>
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaR2 id="flechaR2" (click)="scrollTo2('saldoscheques','right',1)">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
        <br>
      </div>
      <div *ngIf="parametros.subsistema == 99 && bandPignorados && deshabilitarPignorados && bandPignorados">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.Pignorados}}</strong>
            </h6>
          </div>
        </div>
        <div class="row" style="margin-left: -24px !important;">
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('pignorados','left')">
              keyboard_arrow_left
            </mat-icon>
          </div>
          <div class="col container2Aux" (scroll)="scroll('pignorados')">
            <table mat-table [dataSource]="dataSourcePig" class="pignorados">
              <ng-container matColumnDef="Nro">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Numero}}</th>
                <td mat-cell *matCellDef="let row">{{row.SPIGNORACIONFONDOS}}</td>
              </ng-container>
              <ng-container matColumnDef="Fecha">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Fecha}}</th>
                <td mat-cell *matCellDef="let row" class="fechas left">
                  {{row.FECHA | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
              </ng-container>
              <ng-container matColumnDef="Hora">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Hora}}</th>
                <td mat-cell *matCellDef="let row">{{row.HORA}}</td>
              </ng-container>
              <ng-container matColumnDef="Concepto">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Concepto}}</th>
                <td mat-cell *matCellDef="let row">{{row.CONCEPTO}}</td>
              </ng-container>
              <ng-container matColumnDef="Monto">
                <th mat-header-cell *matHeaderCellDef class="right">{{idiomas.Monto}}</th>
                <td mat-cell *matCellDef="let row" class="numeros" [class.norBalCellWidth]=normal
                  [class.medBalCellWidth]=mediano [class.graBalCellWidth]=grande>
                  {{row.MONTOPENDIENTE | cantidadMonetaria}}</td>
              </ng-container>
              <ng-container matColumnDef="Vencimiento">
                <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Vencimientopr}}</th>
                <td mat-cell *matCellDef="let row" class="fechas left">
                  {{row.FVENCIMIENTO | formatoFecha:idiomas.IdiomaSeleccionado}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsPig; sticky: true"></tr>
              <tr id="celda" mat-row *matRowDef="let row; let even = even ;let last = last;columns: displayedColumnsPig"
                [ngClass]="{gray: even}">
                {{showHideRows(parametros.subsistema,last)}}</tr>
            </table>
          </div>
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('pignorados','right')">
              keyboard_arrow_right</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </mat-card>


  <div *ngIf="isMobile" style="padding: 12px; padding-top: 0 !important;">
    <div [class.formBottomTrans]="muestraGrafico">
      <div *ngIf="parametros.subsistema == 04 && datosBand && deshabilitarTransacciones">
        <div *ngIf="bandMovements" class="form-row" style="padding-top: 10px;">
          <div class="col-6" style="min-width: 155px;">
            <mat-form-field style="font-size: .8em;" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.FDesde}}</mat-label>
              <input matInput readonly [ngModel]="dated | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
                [ngModelOptions]="{standalone: true}">
              <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="dated" [max]="maxDate"
                [min]="minimunDate" [matDatepicker]="pickerdesde" (dateChange)="consultar04()">
              <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
              <mat-datepicker touchUi #pickerdesde></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6" style="min-width: 155px;">
            <mat-form-field style="font-size: .8em;" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.FHasta}}</mat-label>
              <input matInput readonly [ngModel]="dateh | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
                [ngModelOptions]="{standalone: true}">
              <input matInput readonly name="fhasta" [hidden]='true' [(ngModel)]="dateh" [max]="maxDate"
                [min]="minimunDate" [matDatepicker]="pickerhasta" (dateChange)="consultar04()">
              <mat-datepicker-toggle matSuffix [for]="pickerhasta"></mat-datepicker-toggle>
              <mat-datepicker touchUi #pickerhasta></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="bandMovements" class="form-row">
            <mat-label class="col-8" *ngIf=" bandMovements"
              style="font-size: .6em; opacity: 0.9; padding-bottom: 10px; text-align: justify;">
              {{idiomas.MensajeEstadoCuenta}}</mat-label>
            <div calss="col-2" style="margin-top: 5px;">
              <button class="btn btnGrafico" color="primary" (click)="genrerarEstadoCuenta()">
                <mat-icon title={{idiomas.estodoCuentaTC}}>library_books</mat-icon>
              </button>
            </div>
            <div calss="col-2" style="margin-top: 5px;margin-left: 4%">
              <button class="btn btnGrafico" color="primary" (click)="muestraGrafico=!muestraGrafico">
                <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
                <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div id="loadData_04" style="visibility:hidden; padding-bottom: 10px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf=intentoCom style="padding-bottom: 10px;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <!-- Grafico Linea de Tiempo -->
        <div *ngIf="(muestraGrafico) && (datosGrafico.length>0) && (parametros.subsistema == 04 && peticion)"
          [ngClass]="{'ceroHeight':!muestraGrafico}">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.GrafTrans04}}</strong>
              </h6>
            </div>
          </div>
          <div class="row">
            <app-grafico-saldos-tiempo *ngIf="muestraGrafico" class="col" [data]="datosGrafico" [idiomas]="idiomas">
            </app-grafico-saldos-tiempo>
          </div>
        </div>
        <div class="container-card-mobile" *ngIf="parametros.subsistema == 04 && peticion"
          [class.heightconteiner2]="!(datos4.length>numMaxRow)">
          <div class="col-12">
            <mat-list>
              <section id="intro">
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let dato of datos4; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div class="col-8">
                      <div style="font-weight: bold; font-size: .9em;">{{dato.fecha |
                        formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                      <div>
                        <div style="font-size: .8em; white-space: initial;">{{dato.descripcion |
                          cambiarTextoTransaccion}}
                        </div>
                      </div>
                    </div>
                    <div class="col-4" style="text-align: end !important;">
                      <div class="colorCartas" style="font-size: .9em;">
                        {{dato.debitocredito}}
                        {{dato.valormonedacuenta |
                        cantidadMonetaria}}</div>
                      <div style="font-size: 0.7em;">{{dato.saldocontable |
                        cantidadMonetaria}}</div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </section>
            </mat-list>
          </div>
        </div>
      </div>

      <div id="loadData_05_06" style="visibility:hidden;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <!-- Grafico Linea de Tiempo -->
      <div
        *ngIf="(muestraGrafico) && (datosGrafico.length>0) && (parametros.subsistema == 05 && datosBand && bandData0506)"
        [ngClass]="{'ceroHeight':!muestraGrafico}">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.GrafTrans05}}</strong>
            </h6>
          </div>
          <div style="margin-top: -8px; margin-right: 1%">
            <button class="btn btnGrafico" color="primary" (click)="muestraGrafico=!muestraGrafico">
              <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
              <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <app-grafico-saldos-tiempo *ngIf="muestraGrafico" class="col" [data]="datos2[0]" [idiomas]="idiomas">
          </app-grafico-saldos-tiempo>
        </div>
      </div>
      <div *ngIf="parametros.subsistema == 05 && datosBand && bandData0506">
        <div id="cardTrans05">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.DetallePago}}</strong>
              </h6>
            </div>
            <div style="margin-top: -8px; margin-right: 1%">
              <button *ngIf="!muestraGrafico" class="btn btnGrafico" color="primary"
                (click)="muestraGrafico=!muestraGrafico">
                <mat-icon *ngIf="muestraGrafico">clear</mat-icon>
                <mat-icon *ngIf="!muestraGrafico">bar_chart</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf=intento>
            <mat-progress-bar style="margin-top: 10px; margin-bottom: 10px; " *ngIf=intento mode="indeterminate">
            </mat-progress-bar>
          </div>
          <div class="col-12 container-card-mobile" [class.heightconteiner2]="!(datos4.length>numMaxRow)">
            <mat-list>
              <section id="intro">
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let dato of datos4; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div class="col-6">
                      <div style="font-weight: bold; font-size: .9em;">#{{dato.cuota}}: {{dato.fecha |
                        formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                      <div>
                        <div style="font-size: .8em; white-space: initial;">{{dato.estado | uppercase}}</div>
                      </div>
                    </div>
                    <div class="col-6" style="text-align: end !important;">
                      <div class="colorCartas" style="font-size: .9em;">
                        {{dato.valorcuota | cantidadMonetaria}}</div>
                      <div style="font-size: 0.7em;">{{idiomas.Impuesto}}: {{dato.impuesto | cantidadMonetaria}}</div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </section>
            </mat-list>
          </div>
        </div>
      </div>
      <!-- Fin -->

      <div *ngIf="parametros.subsistema == 06 && datosBand && bandData0506">
        <div id="cardTrans06">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.DetalleAmortizacion}}</strong>
              </h6>
            </div>
          </div>
          <div class="col-12 container-card-mobile" [class.heightconteiner2]="!(datos4.length>numMaxRow)">
            <mat-list>
              <section id="intro">
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let dato of datos4; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div class="col-6">
                      <div style="font-weight: bold; font-size: .8em;">#{{dato.cuota}}: {{dato.fecha |
                        formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                    </div>
                    <div class="col-6" style="text-align: end !important;">
                      <div class="colorCartas" style="font-size: .9em;">
                        {{dato.valorPagar | cantidadMonetaria}}</div>
                      <div style="font-size: 0.7em;">{{dato.estado | uppercase}}
                      </div>
                    </div>
                    <div class="col-12">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <strong class="colorCartas">{{idiomas.DetalleTransferencia}}</strong>
                          </mat-panel-title>
                          <mat-panel-description>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="form-row">
                          <div class="col-6" style="font-size: 1em;">{{idiomas.Capitalpl}}: {{dato.valorCap |
                            cantidadMonetaria}}
                          </div>
                          <div class="col-6" style="font-size: 1em;">{{idiomas.Interes}}: {{dato.valorInt |
                            cantidadMonetaria}}
                          </div>
                          <div class="col-6" style="font-size: 1em;">{{idiomas.VDiferimiento}}: {{dato.valorDiferimiento
                            |
                            cantidadMonetaria}}
                          </div>
                          <div class="col-6" style="font-size: 1em;">{{idiomas.IDiferimiento}}:
                            {{dato.interesDiferimiento
                            |
                            cantidadMonetaria}}
                          </div>
                          <div class="col-6" style="font-size: 1em;">{{idiomas.Seguros}}: {{dato.valorSeg |
                            cantidadMonetaria}}
                          </div>
                          <div class="col-6" style="font-size: 1em;">{{idiomas.InteresesMora}}: {{dato.valorMora |
                            cantidadMonetaria}}
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </section>
            </mat-list>
          </div>
        </div>
      </div>
      <div *ngIf="parametros.subsistema == 33 && bandBloqueos">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.Bloqueos}}</strong>
            </h6>
          </div>
        </div>
        <div class="col-12 container-card-mobile" [class.heightconteiner2]="!(dataSourceBlo.length>numMaxRow)">
          <mat-list>
            <section id="intro">
              <mat-divider></mat-divider>
              <mat-list-item *ngFor="let dato of dataSourceBlo; let even = even;">
                <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                  <div class="col-6">
                    <div style="font-weight: bold; font-size: .8em;">#{{dato.SBLOQUEOFONDOS}}: {{dato.FECHA |
                      formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                  </div>
                  <div class="col-6" style="text-align: end !important;">
                    <div class="colorCartas" style="font-size: .9em;">
                      {{dato.MONTOPENDIENTE | cantidadMonetaria}}</div>
                    <div style="font-size: 0.7em;">{{dato.CONCEPTO}}
                    </div>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </section>
          </mat-list>
        </div>
      </div>
      <div *ngIf="parametros.subsistema == 77 && bandPorConf && deshabilitarConfirmar">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.ChequesLocales}}</strong>
            </h6>
          </div>
        </div>
        <div class="col-12 container-card-mobile" [class.heightconteiner2]="!(dataSourceCheLoc.length>numMaxRow)">
          <mat-list>
            <section id="intro">
              <mat-divider></mat-divider>
              <mat-list-item *ngFor="let dato of dataSourceCheLoc; let even = even;">
                <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                  <div class="col-6">
                    <div style="font-weight: bold; font-size: .8em;">{{idiomas.FEfectivizacion}}: {{dato.FCONFIRMACION
                      |
                      formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                  </div>
                  <div class="col-6" style="text-align: end !important;">
                    <div class="colorCartas" style="font-size: .9em;">
                      {{dato.MONTO | cantidadMonetaria}}</div>
                    <div style="font-size: 0.7em;">{{idiomas.Banco}}: {{dato.BANCO}}
                    </div>
                    <div style="font-size: 0.7em;">{{idiomas.Cheque}}: {{dato.NUMEROCHEQUE}}
                    </div>
                    <div style="font-size: 0.7em;">{{idiomas.Cgirada}}: {{dato.CUENTAGIRADA}}
                    </div>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </section>
          </mat-list>
        </div>
        <br>
        <div *ngIf=bandPorConf2 id="cardTrans77">
          <div class="row">
            <div class="center col texto">
              <h6 class="posCon">
                <strong>{{idiomas.Chequesotros}}</strong>
              </h6>
            </div>
          </div>
          <div class="col-12 container-card-mobile" [class.heightconteiner2]="!(dataSourceCheOts.length>numMaxRow)">
            <mat-list>
              <section id="intro">
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let dato of dataSourceCheOts; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div class="col-6">
                      <div style="font-weight: bold; font-size: .8em;">{{idiomas.FEfectivizacion}}: {{dato.FCONFIRMACION
                        |
                        formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                    </div>
                    <div class="col-6" style="text-align: end !important;">
                      <div class="colorCartas" style="font-size: .9em;">
                        {{dato.MONTO | cantidadMonetaria}}</div>
                      <div style="font-size: 0.7em;">{{idiomas.Banco}}: {{dato.BANCO}}
                      </div>
                      <div style="font-size: 0.7em;">{{idiomas.Cheque}}: {{dato.NUMEROCHEQUE}}
                      </div>
                      <div style="font-size: 0.7em;">{{idiomas.Cgirada}}: {{dato.CUENTAGIRADA}}
                      </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </section>
            </mat-list>
          </div>
        </div>
        <br>
      </div>
      <div *ngIf="parametros.subsistema == 99 && bandPignorados && deshabilitarPignorados && bandPignorados">
        <div class="row">
          <div class="center col texto">
            <h6 class="posCon">
              <strong>{{idiomas.Pignorados}}</strong>
            </h6>
          </div>
        </div>
        <div class="col-12 container-card-mobile" [class.heightconteiner2]="!(dataSourcePig.length>numMaxRow)">
          <mat-list>
            <section id="intro">
              <mat-divider></mat-divider>
              <mat-list-item *ngFor="let dato of dataSourcePig; let even = even;">
                <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                  <div class="col-6">
                    <div style="font-weight: bold; font-size: .8em;">#{{dato.SPIGNORACIONFONDOS}}</div>
                    <div>
                      <div style="font-size: .8em; white-space: initial;">{{idiomas.Fecha}}: {{dato.FECHA |
                        formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                    </div>
                  </div>
                  <div class="col-6" style="text-align: end !important;">
                    <div class="colorCartas" style="font-size: .9em;">
                      {{dato.MONTOPENDIENTE | cantidadMonetaria}}</div>
                    <div style="font-size: 0.7em;">{{idiomas.Concepto}}: {{dato.CONCEPTO}}
                    </div>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </section>
          </mat-list>
        </div>
      </div>
      <!-- Fin -->
    </div>
  </div>
</div>