import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatButtonModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-rating',
  templateUrl: './dialogo-rating.component.html',
  styleUrls: ['./dialogo-rating.component.css']
})
export class DialogoRatingComponent {

  mensaje: string;
  aceptar: string;
  rechazar: string;
  pdf: string = "";

  idiomas: any;
  rating: number
  starCount: number
  color: string = 'accent';

  observacionRating = this.validators.textNumberSpecialFeaturetForm()

  er: boolean = false;
  intento: boolean;
  ratingArr:any[];


  constructor(public apiService: ApiService, private validators: FormControlValidators, private dialogRef: MatDialogRef<DialogoRatingComponent>, @Inject(MAT_DIALOG_DATA) data, public basedatos:
    BaseDatos, public mostrarPdf: MostrarPDF) {
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.idiomas = data.idm1;
    this.rating = 1;
    this.starCount = 5;
    this.ratingArr = [];
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }


  calificarHB() {
    this.intento = true;
    let envio = {
      transaccion: '183073-man-rating.xml',
    }
    envio['rec^tci0^0^CUSUARIO^tci0'] = "$userCode"
    envio['rec^tci0^0^FCALIFICACION^tci0'] = this.getCurrentTimeAsTimestamp()
    envio['rec^tci0^0^CALIFICACION^tci0'] = this.rating
    envio['rec^tci0^0^OBSERVACION^tci0'] = this.observacionRating.value.toUpperCase()
    envio['rec^tci0^0^SESION^tci0'] = "$session"

    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = false
      this.starCount = 5;
      this.rating = 1
      this.dialogRef.close(this.er);
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date()
    const year = now.getFullYear()
    const month = this.completeDigits(now.getMonth() + 1)
    const day = this.completeDigits(now.getDate())

    const seconds = now.getSeconds();
    const minutes = now.getMinutes();
    const hour = now.getHours();
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString()
  }

  close() {
    this.dialogRef.close(this.er);
  }

  onClick(rating: number) {
    this.rating = rating;
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

}
