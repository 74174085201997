<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutAlign="start stretch" id="tableDiv">
        <div class="form-row">
            <div class="col-4" style="min-width: 105px;">
                <mat-form-field (keyup.enter)="consultaPorNombre($event.target.value)" appearance="outline" fxFlexFill>
                    <mat-label>{{idiomas.Producto}}</mat-label>
                    <input matInput type="text" [formControl]="productoForm"
                        oninput="this.value = this.value.toUpperCase();">
                </mat-form-field>
            </div>

            <div class="col-4" style="min-width: 156px;">
                <mat-form-field style="font-size: .85em;" appearance="outline" fxFlexFill>
                    <mat-label>{{idiomas.FCompra}}</mat-label>
                    <input matInput readonly [ngModel]="dated | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
                        [ngModelOptions]="{standalone: true}">
                    <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="dated"
                        [matDatepicker]="pickerdesde" (dateChange)="cambiofechaDesde()" [disabled]="!internet.internet">
                    <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
                    <mat-datepicker #pickerdesde></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-4" style="min-width: 110px;">
                <mat-form-field appearance="outline" fxFlexFill>
                    <mat-label>{{idiomas.Estado}}</mat-label>
                    <mat-select (selectionChange)="consultaPorEstatus($event.value)" [formControl]="estatusForm">
                        <mat-option *ngFor="let tipodoc of datosEstatus" [value]="tipodoc.id">
                            {{tipodoc.id}} -- {{tipodoc.detalle}}
                            <mat-divider style="border-top-width: 3px;"></mat-divider>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="intentoSiguienteConsulta">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="intento">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="intentoComprobante">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <mat-card *ngIf="idCompra==0 && (bandMovimientos && !intento)">
            <div class="row">
                <div class="center col texto" id="lastelement">
                    <label><strong>{{idiomas.ComprasRealizadas | uppercase}}</strong></label>
                </div>
            </div>
            <div class="row" style="margin-left: -24px !important;">
                <div class="col flecha" style="margin-right: 4px;">
                    <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tabladocumento','left')">
                        keyboard_arrow_left</mat-icon>
                </div>
                <div class="col container2Aux" (scroll)="scroll()" [class.heightconteiner2]="!(lengthlmov>numMaxRow)">
                    <table mat-table [dataSource]="datosMovimiento" class="tabladocumento" id="tabla" matSort>
                        <ng-container matColumnDef="Orden">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{idiomas.NOrden}}</th>
                            <td mat-cell *matCellDef="let element">{{element.orden}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Producto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{idiomas.Producto}}
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.producto}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Cantidad">
                            <th mat-header-cell class="right" *matHeaderCellDef mat-sort-header>{{idiomas.Cantidad}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="numeros">{{element.cantidad}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Estatus">
                            <th mat-header-cell class="left" *matHeaderCellDef mat-sort-header>{{idiomas.Estado}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="left">{{element.estatus}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaC">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{idiomas.FCompra}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.fechac | formatoFecha:idiomas.IdiomaSeleccionado}}
                                {{element.fechac | horaSubString1}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaE">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{idiomas.FEntrega}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.fechae | formatoFecha:idiomas.IdiomaSeleccionado}}
                                {{element.fechae | horaSubString1}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Total">
                            <th mat-header-cell class="right" *matHeaderCellDef mat-sort-header
                                style="padding-right: 10px;">{{idiomas.Total}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="numeros" style="padding-right: 10px;">$
                                {{element.total | cantidadMonetaria}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="RepetirTransfer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                <mat-icon title="{{idiomas.VComprar}}" (click)="resendPurchase(element)"
                                    style="cursor: pointer !important;">autorenew
                                </mat-icon>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Documento">
                            <th mat-header-cell class="left" *matHeaderCellDef mat-sort-header
                                [hidden]="!internet.internet">
                                {{idiomas.Recibo}}</th>
                            <td mat-cell *matCellDef="let element; let i=index" class="left"
                                [hidden]="!internet.internet">
                                <a *ngIf="element.id" style="cursor: pointer !important;"
                                    (click)=reportePDF(element)><mat-icon color="primary"
                                        style="font-size: 30px; height: 30px; width: 30px;">
                                        picture_as_pdf</mat-icon></a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsCompras; sticky: true"></tr>
                        <tr id="celda" mat-row
                            *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsCompras"
                            [ngClass]="{gray: even}">{{showHideColumns(last)}}</tr>
                    </table>
                </div>
                <div class="col flecha" style="margin-left: 4px;">
                    <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tabladocumento','right')">
                        keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <mat-paginator style="background:transparent" [length]="length" [pageIndex]="pageIndex"
                [pageSize]="pageSize" (page)="pageEvent = siguienteConsultaTabla($event)"></mat-paginator>
        </mat-card>
        <mat-card *ngIf="idVenta==1 && (bandMovimientos && !intento)">
            <div class="row">
                <div class="center col texto" id="lastelement">
                    <label><strong>{{idiomas.VentasRealizadas | uppercase}}</strong></label>
                </div>
            </div>
            <div class="row" style="margin-left: -24px !important;">
                <div class="col flecha" style="margin-right: 4px;">
                    <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tabladocumento','left')">
                        keyboard_arrow_left</mat-icon>
                </div>
                <div class="col container2Aux" (scroll)="scroll()" [class.heightconteiner2]="!(lengthlmov>numMaxRow)">
                    <table mat-table [dataSource]="datosMovimiento" class="tabladocumento" id="tabla" matSort>
                        <ng-container matColumnDef="Orden">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{idiomas.NOrden}}</th>
                            <td mat-cell *matCellDef="let element">{{element.orden}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Cliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="left">{{idiomas.Cliente}}
                            </th>
                            <td mat-cell *matCellDef="let element" style="max-width: 200px;" class="left">
                                {{element.nombre}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Producto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="left">{{idiomas.Producto}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="left">{{element.producto}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Estatus">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="left">{{idiomas.Estado}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="left">{{element.estatus}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaC">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="left">{{idiomas.FCompra}}
                            </th>
                            <td mat-cell *matCellDef="let element" style="min-width: 135px;" class="left">
                                {{element.fechac | formatoFecha:idiomas.IdiomaSeleccionado}}
                                {{element.fechac | horaSubString1}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaE">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="left">{{idiomas.FEntrega}}
                            </th>
                            <td mat-cell *matCellDef="let element" style="min-width: 135px;" class="left">
                                {{element.fechae | formatoFecha:idiomas.IdiomaSeleccionado}}
                                {{element.fechae | horaSubString1}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Total">
                            <th mat-header-cell class="right" *matHeaderCellDef mat-sort-header
                                style="padding-right: 10px;">{{idiomas.Total}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="numeros"
                                style="min-width: 80px; padding-right: 10px;">$
                                {{element.total | cantidadMonetaria}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Documento">
                            <th mat-header-cell class="right" *matHeaderCellDef mat-sort-header class="left"
                                [hidden]="!internet.internet">
                                {{idiomas.Recibo}}</th>
                            <td mat-cell *matCellDef="let element; let i=index" class="left"
                                [hidden]="!internet.internet">
                                <a *ngIf="element.id" style="cursor: pointer !important;"
                                    (click)=reportePDF(element)><mat-icon color="primary"
                                        style="font-size: 30px; height: 30px; width: 30px;">
                                        picture_as_pdf</mat-icon></a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="confirmacionVenta">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!internet.internet"></th>
                            <td mat-cell *matCellDef="let element" [hidden]="!internet.internet">
                                <mat-icon *ngIf="element.estatus=='Pendiente'" color="warn" title="{{idiomas.CVenta}}"
                                    (click)="confirmVenta(element.nMensaje, element.secuencia)"
                                    style="cursor: pointer !important;">
                                    assignment_turned_in
                                </mat-icon>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsVentas;sticky: true"></tr>
                        <tr id="celda" mat-row
                            *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsVentas;"
                            [ngClass]="{gray: even}">{{showHideColumns(last)}}</tr>
                    </table>
                </div>
                <div class="col flecha" style="margin-left: 4px;">
                    <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tabladocumento','right')">
                        keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <mat-paginator style="background:transparent" [length]="length" [pageIndex]="pageIndex"
                [pageSize]="pageSize" (page)="pageEvent = siguienteConsultaTabla($event)"></mat-paginator>
        </mat-card>
    </div>
    <div class="float" id="floatCont" style="z-index: 100;">
        <button mat-fab class="float-right buttonFab" title="{{idiomas.Actualizar | uppercase}}" color="primary"
            (click)="refrescartabla(1)">
            <mat-icon>replay</mat-icon>
        </button>
    </div>
</div>