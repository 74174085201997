import { Component, OnInit, Input } from '@angular/core';
import { FormControlValidators } from '../funciones/formcontrol';
import { BaseDatos } from '../funciones/basededatos';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { FuncionesTabla } from '../funciones/funcionesTabla';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, FormatoFechaTransferServ, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { PagoServiciosComponent } from '../pago-servicios/pago-servicios.component';
import { RecargasComponent } from '../recargas/recargas.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatDatepickerModule, MatProgressBarModule, MatInputModule, MatSelectModule, MatButtonModule, MatTableModule, MatListModule, MatCardModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, FormatoFechaTransferServ,  FormatoFecha, FlexLayoutModule, PagoServiciosComponent, RecargasComponent],
  selector: 'app-consulta-pago-servicios',
  templateUrl: './consulta-pago-servicios.component.html',
  styleUrls: ['./consulta-pago-servicios.component.css']
})

export class ConsultaPagoServiciosComponent implements OnInit {

  @Input() idiomas: any;
  @Input() data;
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() logoTemaOscuro: any;


  flechaR: boolean;
  flechaL: boolean;
  numMaxRow: number;
  datos2: any[];
  datos1: any[];
  cuentas: any[];
  intento: boolean;
  autoPeticion: boolean;
  bandData: boolean;
  displayedColumns: string[] = ['F.Trasaccion',/* 'F.TransHora',*/ 'Empresa', /*'Suministro',*/ 'Valor', 'RepetirTransfer',/*'Descripcion', 'CuentaDebito', */'Imprimir'];
  factual = new Date();
  maxDate = new Date(this.factual.getUTCFullYear(), this.factual.getUTCMonth(), this.factual.getUTCDate());
  dated = new Date(this.factual.getFullYear(), this.factual.getMonth(), 1);
  dateh = new Date(this.factual);
  band: boolean = false;
  fdesde: String = '';
  fhasta: String = '';
  band1: boolean;
  autoPeticion1: any;
  pdf: string = "";
  xls: string = "";

  bandsFuncion: any;
  resendPay: any;
  nomServ: string;
  bandMobile: boolean;

  constructor(private validators: FormControlValidators, private base: BaseDatos,
    private alerta: Alerta, private api: ApiService, public internet: internetComponent, public mostrarPdf: MostrarPDF,
    private funcionesTabla: FuncionesTabla) {
    this.cuentas = [];
    this.datos2 = [];
    this.datos1 = [];
    this.flechaR = false;
    this.flechaL = true;
    this.numMaxRow = 4;
    this.intento = false;
    this.band1 = false;
    this.autoPeticion = false;
    this.bandData = false;
    this.bandMobile = false;
    this.bandsFuncion = {
      'pagoServ': false,
      'recargas': false,
      'consultaServRec': true,
    }
  }

  cuentaForm = this.validators.comboForm();

  ngOnInit() {
    this.bandMobile = false;
    this.bandsFuncion = {
      'pagoServ': false,
      'recargas': false,
      'consultaServRec': true,
    }
    this.getPaymentsList();
    this.autoPeticion = true;
    this.autoPeticion1 = true;
    this.consultarPago();

    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  getPaymentsList() {
    const envio = {
      'transaccion': '184043-lv-in.xml',
      'alias': 'tc0',
      'cri^tu0^CUSUARIO^JOIN': '$userCode',
    };
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.cuentas = data;
        this.base.insertarConsulta('cuentasconpagoservicios', data)
      }
    }, (err) => {
      if (err.status !== 0 && err.status !== 504 && err.status !== -1 && err.status !== -1) {  // Con Internet
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via tokenFormControl JWT.') { this.logout() }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      } else { // Sin Internet
        if (this.base.retornarConsulta('cuentasconpagoservicios')) {
          this.cuentas = this.base.retornarConsulta('cuentasconpagoservicios')
        }
        else {
          this.cuentas = []
        }
      }
    });
  }

  logout() {
    const obj = {
      'salir': true
    };
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  showHideRows(last) {
    if (last) {
      try {
        this.funcionesTabla.DivHeight(this.datos2)
        let classcontainer2Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 400px')
          if (400 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:150px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 400px')
          if (400 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:115px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 400px; font-size: 14px')
          if (400 - classcontainer2Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'min-width:90px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaServiciosP')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }

  scroll(id) {
    if (id == 'tablaServiciosP') {
      var container1 = document.getElementsByClassName('container2Aux')
    }
    var tabla = document.getElementsByClassName(id)
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 2 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    if (clase == 'tablaServiciosP') {
      let container1 = document.getElementsByClassName('container2Aux')
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
      } else {
        container1.item(0).scrollLeft = 0
        this.flechaL = true
        this.flechaR = false
      }
    }
  }

  consultarPago() {
    this.band = false
    this.bandData = false;
    this.intento = true
    var fh = this.dateh
    fh = new Date(this.dateh.getTime() + (1000 * 60 * 60 * 24))
    this.fdesde = (this.dated.getFullYear() + "-" + this.anadirCero((this.dated.getMonth() + 1)) + "-" + this.anadirCero(this.dated.getDate()));
    this.fhasta = (fh.getFullYear() + "-" + this.anadirCero(fh.getMonth() + 1) + "-" + this.anadirCero(fh.getDate()));
    if (this.autoPeticion) {
      var envio = {
        "transaccion": "184043-con-initial-Web.xml",
        "cri^tm0^CPERSONA^NORMAL^tm0": "$personCode"
      }
      this.autoPeticion = false
    } else {
      envio = {
        "transaccion": "184043-con-in-Web.xml",
        "cri^tm0^CPERSONA^NORMAL^tm0": "$personCode"

      }
      envio['cri^tm0^FREAL^NORMAL^tm0^>='] = this.fdesde
      envio['cri^tm0^FREAL^NORMAL^tm0^<='] = this.fhasta
      envio['cri^tm0^CCUENTA^NORMAL^tm0'] = this.cuentaForm.value
      envio['ctl^fechadesde'] = this.fdesde + " 00:00:00.000"
      envio['ctl^fechahasta'] = this.fhasta + " 00:00:00.000"
      this.autoPeticion1 = false
    }
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.datos2 = data.tm0
        this.band = true
        let consulta = {
          'fdesde': this.dated,
          'fhasta': this.dateh,
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'cuenta': this.cuentaForm.value,
          'data': this.datos2
        }
        this.base.insertarConsulta("ConsultaServiciosPagados" + this.cuentaForm.value, consulta)
        if (this.datos2.length == 0) {
          this.band = false
          this.alerta.presentarAlerta(this.idiomas.NoTieneServiciosPagados.toUpperCase())
        } else {
          this.band = true
          this.bandData = true
        }
      }
    }, (err) => {
      this.intento = false
      if (err.status !== 0 && err.status !== 504 && err.status !== -1 && err.status !== -1) {  // Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        this.bandData = false;

        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout() }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { // Sin Internet
        let ObjConsulta = this.base.retornarConsulta("ConsultaServiciosPagados" + this.cuentaForm.value)
        this.intento = true
        if (ObjConsulta) {
          this.intento = false
          this.dated = ObjConsulta.fdesde
          this.dateh = ObjConsulta.fhasta
          this.band1 = true
          if (ObjConsulta.data == 0) {
            this.band = false  //Oculta tabla y muestra carta de no servicios pagados
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.alerta.presentarAlerta(this.idiomas.NoTieneServiciosPagados.toUpperCase())
          } else {
            this.band = true
            this.datos2 = []
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.datos2 = ObjConsulta.data
          }
        } else {
          this.intento = false
          this.dated = new Date(this.factual.getFullYear(), this.factual.getMonth(), 1)
          this.dateh = new Date(this.factual)
          this.band = false
          this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaCta.toUpperCase())
        }
        this.bandData = true;
      }
    });
  }

  resendTransfer(tx) {
    let resendTransferJSON = {
      numeroServicio: tx.CSERVICIO,
      suministro: tx.REFERENCIA,
      resender: true
    };
    if (tx.CTRANSACCION == "6000") {
      resendTransferJSON['tarPendType'] = 'pagoServ'
    }
    if (tx.CTRANSACCION == "6001") {
      resendTransferJSON['tarPendType'] = 'recargas'
    }
    this.alerta.generarDialogoConMensajeDeIdiomas(this.idiomas, tx.CTRANSACCION == "6000" ? "RegisterServicePay" : "RegisterServicePay").then((data) => {
      if (data) {
        if (resendTransferJSON['tarPendType'] == 'pagoServ') {
          this.habilitarFunciones('pagoServ', resendTransferJSON, tx.NOMBRESCONTRATO);
        }
        if (resendTransferJSON['tarPendType'] == 'recargas') {
          this.habilitarFunciones('recargas', resendTransferJSON, '');
        }
        this.autoPeticion = true
        this.consultarPago()
      }
    });
  }

  habilitarFunciones(funcionalidad, data, nombre) {
    this.resendPay = data;
    this.nomServ = nombre;
    this.bandsFuncion = {
      'pagoServ': false,
      'recargas': false,
      'consultaServRec': false,
    }
    this.bandsFuncion[funcionalidad] = true;
    this.alerta.clearAlerta();
    window.scroll(0, 0);
  }

  tomarValorCuentas() {
    this.consultarPago()
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  className() {
    if (document.getElementsByClassName("cdk-overlay-pane").length == 2) {
      document.getElementsByClassName("cdk-overlay-pane")[1].setAttribute("style", "margin-top: 30px")
    } else {
      document.getElementsByClassName("cdk-overlay-pane")[0].setAttribute("style", "margin-top: 30px")
    }
  }

  limpiar() {
    this.datos2 = []
    this.band1 = false
    this.band = false
    this.bandData = false
    this.dated = new Date(this.factual.getFullYear(), this.factual.getMonth(), 1)
    this.dateh = new Date(this.factual)
  }

  //FUNCION PARA ABRIR EL DIALOGO DE CONSULTA
  mostrarDialogo(data) {
    this.alerta.generarDialogoDetalleServicio(this.idiomas, data)
  }

  close() {
    this.datos2 = []
    this.band = false
    this.band1 = false
    this.bandData = false
    this.cuentaForm.reset()
    this.dated = new Date(this.factual.getFullYear(), this.factual.getMonth(), 1)
    this.dateh = new Date(this.factual)
  }
}
