<div class="example-form1" [class.div-mob]="bandMobile" fxLayout="column" fxLayoutAlign="start stretch" style="padding-top: 25px;">
    <div *ngIf=intento>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <app-generar-cartas *ngIf=!intento #generarCartas [idiomas]="idiomas" [mediano]="mediano" [grande]="grande"
        [normal]="normal" [datos]="datos" [datos2]="datos2" [ctasocio]="ctasocio" [ahorros]="ahorros"
        [inversiones]="inversiones" [prestamos]="prestamos" [datosGrafica]="datosGrafica" [coloresGrafica]="coloresGrafica" class="deb"
        [hasProducts]="hasProducts" [prestamosA]="prestamosA" [prestamosAA]="prestamosAA"
        [tarjetaCredito]="tarjetaCredito" (updatePosCons)="refreshPosCons()" (operationEvent)="operationEvent($event)"
        [skinSeleccionadoBand]="skinSeleccionadoBand"></app-generar-cartas>
    <div *ngIf="true && !bandMobile" class="float" id="floatContPosiConso" style="margin-right: 4% !important;">
        <button mat-fab (click)="refreshPosCons()" title={{idiomas.Actualizar}} class="float-right" color="primary"
            style="margin-right:0px !important">
            <mat-icon>autorenew</mat-icon>
        </button>
    </div>
</div>