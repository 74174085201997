<div id="login">
    <div id="tool-barHome" class="static-toolbar">
        <mat-toolbar style="height: auto;" class="cabecera" [class.offline]=!internetC.internet>
            <mat-toolbar-row>
                <img src="assets/resources/mipmap-hdpi/logo_clientNoUrl.png" class="imagen"
                    [class.offline]=!internetC.internet />
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <div class="under-toolbar">
        <mat-card class="formMaxWidthInicio">
            <mat-card-content style="margin-bottom: 0px">
                <div class="form-row">
                    <div *ngIf="!bandMobile" class="col-4" style="margin-top: auto; margin-bottom: auto;">
                        <img class="imagesize" src="assets/resources/mipmap-hdpi/certificados-validador.png"  [class.offline]=!internetC.internet />
                    </div>
                    <div class="contentMenu col-8 pt-3 pl-3" [class.col-12]="bandMobile">
                        <h3>{{idiomas.ValidacionCertificados}}</h3>
                        <hr>
                        <span>{{idiomas.descripcionValidacionCertificados}}</span>
                        <br>
                        <br>
                        <span><b>{{idiomas.Importante}}</b>{{idiomas.descripcionImportante}}</span>

                        <div style="padding-top: 15px;"></div>

                    <div autocomplete="off" class="form-row">

                        <div id="numeroCertificado" class="col-12" style="min-width: 100px">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <mat-label>{{idiomas.numeroDocumento}}</mat-label>
                                <div>
                                    <input  matInput
                                       type="text" autocomplete="off"
                                        [formControl]="numeroCertificado" name="numeroCertificado" placeholder="{{idiomas.ejemploNumeroDocumento}}"
                                        [class.placeholder-cursiva]="true"
                                        oninput="this.value = this.value.toUpperCase();">
                                   <!-- Agregar el icono de información -->

                                </div>
                                <mat-error *ngIf="numeroCertificado.hasError('required')">
                                    <strong>{{idiomas.ValidarCampo}}</strong>
                                </mat-error>
                                <mat-error *ngIf="numeroCertificado.hasError('pattern')">
                                    <strong>{{idiomas.msjSoloNumeros}}</strong>
                                </mat-error>

                                <mat-icon matSuffix class="icono-informacion" matTooltip="{{idiomas.mensajeInformativoCertificadoBancario}}">info_outline</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div autocomplete="off" class="form-row" hidden="true">
                        <div id="certificateCode" class="col-12">
                            <mat-form-field appearance="outline" fxFlexFill>
                                <input matInput [(ngModel)]="selectedDate" [matDatepicker]="picker" placeholder="{{idiomas.FechaEmision}}" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker (dateChange)="onDateSelected($event)"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <span>{{idiomas.codigoDeImagen}}</span>
                    <re-captcha #captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
                      <br>
                      <br>
                      <div class="modal-footer" style="text-align: right;">
                            <button mat-raised-button
                            [disabled]="isButtonDisabled()"
                            (click)="enviar()"
                                 title={{idiomas.Aceptar}} color="primary" class="buttonSubmit">
                                {{idiomas.validarCertificado}}
                            </button>
                    </div>
                    <div *ngIf="intento">
                        <br>
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                      </div>
                </div>
                </div>

            </mat-card-content>
            <div
            style="padding-top: 5px;">
            <a class="blueBack form-row returnContainer" (click)="returnInicio()" style="max-width: 85px;">
            <mat-icon class="returnArrow col">arrow_back_ios
            </mat-icon>
            <div class="returnLink col">{{idiomas.Regresar}}</div>
            </a>
        </div>

        </mat-card>
        <br>
    </div>
    <!-- Powered by FITBANK -->
    <div class="row bottomBanner" style="position: fixed; bottom: 0;">
        <span
            style="font-size:13px;line-height:15px;color:#848484;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
            by&nbsp;</span>
        <span
            style="font-size:13px;line-height:15px;color:#0082cb;vertical-align:middle;font-weight: bold;"><strong></strong>
            FIT</span>
        <span
            style="font-size:13px;line-height:15px;color:#f7941d;vertical-align:middle;font-weight: bold;"><strong></strong>
            BANK&nbsp;</span>
        <span
            style="font-size:13px;line-height:15px;color:#848484;vertical-align:middle;font-weight: bold;"><strong></strong>
            &reg;</span>
    </div>
</div>
