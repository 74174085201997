import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Servicios } from '../funciones/encryptar';
import { Router } from '@angular/router';
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet';
import { FormControlValidators } from '../funciones/formcontrol';
import { last } from 'rxjs/operators';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { FuncionesTabla } from '../funciones/funcionesTabla';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFecha, FormatoFechaCalendar, FormatoFechaTransferServ } from '../pipes/custom-pipes.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TransferenciasCuentasPropiasComponent } from '../transferencias-cuentas-propias/transferencias-cuentas-propias.component';
import { TransferenciasMismaInstitutcionComponent } from '../transferencias-misma-institutcion/transferencias-misma-institutcion.component';
import { TransferenciasComponent } from '../transferencias/transferencias.component';
import { TransferenciaTarjetasCreditoComponent } from '../transferencia-tarjetas-credito/transferencia-tarjetas-credito.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatCardModule, MatButtonModule, MatTableModule, MatDividerModule, MatInputModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, FormatoFecha, MatDatepickerModule, CantidadMonetaria,FormatoFechaTransferServ, FormatoFechaCalendar, FlexLayoutModule, TransferenciasCuentasPropiasComponent, TransferenciasMismaInstitutcionComponent, TransferenciasComponent, TransferenciaTarjetasCreditoComponent],
  selector: 'app-consulta-transferencias',
  templateUrl: './consulta-transferencias.component.html',
  styleUrls: ['./consulta-transferencias.component.css']
})
export class ConsultaTransferenciasComponent implements OnInit {

  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;
  @Input() bandTarjetaCredito: any;
  @Input() logoTemaOscuro: any;
  @Output() evtResendTransfer = new EventEmitter<any>();

  datosUsr: any;
  seleccion = this.validators.comboForm();
  usernameFormControl: string;
  displayedColumnsTransf = ['Fecha', 'Descripcion', /*'Hora', 'Cuenta Origen',*/  'Monto', 'RepetirTransfer', /* 'Cuenta Destino', 'Entidad Destino', 'Estado', 'Ref',*/ 'Imprimir'];
  datos2: any;
  fechaActual = new Date();
  maxDate = new Date(this.fechaActual.getUTCFullYear(), this.fechaActual.getUTCMonth(), this.fechaActual.getUTCDate());
  datedDesde = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1);
  datedHasta = new Date(this.fechaActual);
  flechaR: boolean;
  flechaL: boolean;
  nombreEntidad: any;
  bandData: boolean;
  intento: boolean;
  mostrarFecha: boolean;
  mostrarBoton: boolean;
  tipoidentificacion: string[];
  numMaxRow: number; //Numero maximo de filas en tablas para sticky
  pdf: string = "";
  bandConsultaTarjetaCredito: boolean;
  bandMobile: boolean;
  //.
  bandsFuncion: any;
  dataTarPend: any;
  index: any;

  constructor(private api: ApiService,
    public alerta: Alerta,
    private servicio: Servicios,
    private router: Router,
    private base: BaseDatos, public internet: internetComponent, private validators: FormControlValidators,
    public mostrarPdf: MostrarPDF,
    private funcionesTabla: FuncionesTabla) {
    this.flechaR = false;
    this.flechaL = true;
    this.mostrarFecha = false;
    this.bandData = false;
    this.numMaxRow = 4;
    this.mostrarBoton = false;
    this.tipoidentificacion = [];
    this.bandConsultaTarjetaCredito = false;
    this.bandMobile = false;
    this.bandsFuncion = {
      'transCtsProp': false,
      'transMismaInst': false,
      'transOtraInst': false,
      'consultaTrans': true,
    }
  }

  ngOnInit() {
    this.bandMobile = false;
    this.bandsFuncion = {
      'transCtsProp': false,
      'transMismaInst': false,
      'transOtraInst': false,
      'consultaTrans': true,
    }
    this.consultarTransferenciasInicial();
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  limpiarTabla() {
    this.datos2 = []
    this.bandData = false
    this.mostrarFecha = false
    this.datedDesde = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
    this.datedHasta = new Date(this.fechaActual)
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  consultarTransferenciasInicial() {
    this.bandData = false;
    this.intento = true;
    let tarjetaCredito = this.bandTarjetaCredito == 2 ? "1" : "0";
    let envio = {
      "transaccion": "185003-con-transferecias-initial-Web.xml",
      "cri^tm0^CPERSONA^NORMAL^tm0^=": '$personCode',
      "cri^tm0^CUSUARIO^NORMAL^tm0^=": '$userCode',
      "cri^tm0^ESPAGOTARJETA^NORMAL^tm0^=": tarjetaCredito

    };
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      this.intento = false;
      this.datos2 = data.tm0;
      for (var i = 0; i < this.datos2.length; i++) {
        if (this.datos2[i]['ESTADOTRANSACCION'] == 'AUT') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'AUTORIZADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'PRO') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'PROCESADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'ANU') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'ANULADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'NO') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'PROCESADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'SOL') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'SOLICITADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'NEG') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'NEGADO'
        } else {
          this.datos2[i]['ESTADOTRANSACCION'] = 'EN PROCESO'
        }
        if (tarjetaCredito == "1") {
          this.datos2[i]['DESCRIPCION'] = "Pago de Tarjetas de Crédito"
        }
      }
      if (this.datos2.length > 0) {
        this.bandData = true;
      }
      else {
        this.bandData = false;
        this.alerta.presentarAlerta(this.idiomas.NoTieneTransferencias.toUpperCase())
      }
      //Almacenar Consulta
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.datos2,
        'fechaDesde': this.datedDesde,
        'fechaHasta': this.datedHasta
      }
      if (this.bandTarjetaCredito != 2) {
        this.base.insertarConsulta('consultarTransfCtas', consulta)
      } else {
        this.base.insertarConsulta('consultarTransfTC', consulta)
      }
      this.bandConsultaTarjetaCredito = true
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { //Sin Internet
        let ObjConsulta = this.bandTarjetaCredito != 2 ? this.base.retornarConsulta('consultarTransfCtas') : this.base.retornarConsulta('consultarTransfTC')
        this.intento = true
        if (ObjConsulta) {
          this.intento = false
          this.mostrarFecha = true
          this.datedDesde = ObjConsulta.fechaDesde
          this.datedHasta = ObjConsulta.fechaHasta
          if (ObjConsulta.data == 0) {
            this.bandData = false
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.alerta.presentarAlerta(this.idiomas.NoTieneTransferencias.toUpperCase())
          } else {
            this.bandData = true
            this.datos2 = []
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.datos2 = ObjConsulta.data
          }
        } else {
          this.intento = false
          this.datedDesde = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
          this.datedHasta = new Date(this.fechaActual)
          this.bandData = false
          this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaConsultaTransf.toUpperCase())
        }
      }
    })
  }

  consultaEjecutar() {
    this.bandData = false;
    this.intento = true;
    let tarjetaCredito = this.bandTarjetaCredito == 2 ? "1" : "0";
    let ctransaccion = '';
    let transaccionType = '';
    let fdesde = '';
    let fhasta = '';
    if (this.seleccion.value == 1) {
      ctransaccion = '6073'
      transaccionType = 'TRANSFER_OWN_ACCOUNTS'
    }
    if (this.seleccion.value == 2) {
      ctransaccion = '6074'
      transaccionType = 'TRANSFER_OTHER_ACCOUNTS_IN_BANK'
    }
    if (this.seleccion.value == 3 || this.bandTarjetaCredito == 2) {
      ctransaccion = '6071'
      transaccionType = 'TRANSFER_ACCOUNTS_LOCAL_BANKS'
    }
    var fh = this.datedHasta
    fh = new Date(this.datedHasta.getTime() + (1000 * 60 * 60 * 24))
    fdesde = (this.datedDesde.getFullYear() + "-" + this.anadirCero((this.datedDesde.getMonth() + 1)) + "-" + this.anadirCero(this.datedDesde.getDate()));
    fhasta = (fh.getFullYear() + "-" + this.anadirCero(fh.getMonth() + 1) + "-" + this.anadirCero(fh.getDate()));
    let envio = {
      "transaccion": "185003-con-tranferencias-in-Web.xml",
      "cri^tm0^CPERSONA^NORMAL^tm0^=": '$personCode',
      "cri^tm0^CUSUARIO^NORMAL^tm0^=": '$userCode',
      "cri^tm0^ESPAGOTARJETA^NORMAL^tm0^=": tarjetaCredito,
      "cri^tm0^FREAL^NORMAL^tm0^>=": fdesde + " 00:00:00.000",
      "cri^tm0^FREAL^NORMAL^tm0^<=": fhasta + " 23:59:59.000",
      "cri^tm0^CTRANSACCION^NORMAL^tm0": ctransaccion
    };
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      this.intento = false;
      this.datos2 = data.tm0;
      for (var i = 0; i < this.datos2.length; i++) {
        if (this.datos2[i]['ESTADOTRANSACCION'] == 'AUT') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'AUTORIZADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'PRO') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'PROCESADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'ANU') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'ANULADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'NO') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'PROCESADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'SOL') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'SOLICITADO'
        }
        else if (this.datos2[i]['ESTADOTRANSACCION'] == 'NEG') {
          this.datos2[i]['ESTADOTRANSACCION'] = 'NEGADO'
        } else {
          this.datos2[i]['ESTADOTRANSACCION'] = 'EN PROCESO'
        }
        if (tarjetaCredito == "1") {
          this.datos2[i]['DESCRIPCION'] = "Pago de Tarjetas de Crédito"
        }
      }
      if (this.datos2.length > 0) {
        this.bandData = true;
      }
      else {
        this.bandData = false;
        this.alerta.presentarAlerta(this.idiomas.NoTieneTransferencias.toUpperCase())
      }
      //Almacenar Consulta
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.datos2,
        'fechaDesde': this.datedDesde,
        'fechaHasta': this.datedHasta
      }
      if (this.bandTarjetaCredito != 2) {
        this.base.insertarConsulta('consultarTransfCtas' + this.seleccion.value, consulta)
      } else {
        this.base.insertarConsulta('consultarTransfTC', consulta)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else { //Sin Internet
        let ObjConsulta = this.bandTarjetaCredito != 2 ? this.base.retornarConsulta('consultarTransfCtas' + this.seleccion.value) : this.base.retornarConsulta('consultarTransfTC')
        this.intento = true
        if (ObjConsulta) {
          this.intento = false
          this.mostrarFecha = true
          this.datedDesde = ObjConsulta.fechaDesde
          this.datedHasta = ObjConsulta.fechaHasta
          if (ObjConsulta.data == 0) {
            this.bandData = false
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.alerta.presentarAlerta(this.idiomas.NoTieneTransferencias.toUpperCase())
          } else {
            this.bandData = true
            this.datos2 = []
            this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
            this.datos2 = ObjConsulta.data
          }
        } else {
          this.intento = false
          this.datedDesde = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
          this.datedHasta = new Date(this.fechaActual)
          this.bandData = false
          this.alerta.presentarAlerta(this.idiomas.NoConsultaPreviaConsultaTransf.toUpperCase())
        }
      }
    })
  }

  scroll(id) {
    var container1 = document.getElementsByClassName('container2Aux')
    var tabla = document.getElementsByClassName(id)
    for (var i = 0; i < container1.length; i++) {
      if (container1.item(i).scrollLeft != 0) {
        if (container1[i].scrollLeft + 2 >= (tabla[0].clientWidth - container1[i].clientWidth)) {
          this.flechaR = true;
          this.flechaL = false;
        } else {
          this.flechaR = false;
          this.flechaL = true;
        }
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }

    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)

    let container1 = document.getElementsByClassName('container2Aux')
    if (direccion == 'right') {
      container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
    } else {
      container1.item(0).scrollLeft = 0
      this.flechaL = true
      this.flechaR = false

    }
  }

  showHideRows2(last) {
    if (last) {
      try {
        this.funcionesTabla.DivHeight(this.datos2)
        let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('transaccionesEF')[0].setAttribute('style', 'width: 450px')
          if (450 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            //document.getElementsByClassName('fechas')[0].setAttribute('style', 'max-width:150px')
            //document.getElementsByClassName('cuenta')[0].setAttribute('style', 'max-width:68px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('cuenta')[1].setAttribute('style', 'max-width:68px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('numeros')[0].setAttribute('style', 'max-width:60px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            //document.getElementsByClassName('fechas')[0].setAttribute('style', 'max-width:150px')
            //document.getElementsByClassName('cuenta')[0].setAttribute('style', 'max-width:68px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('cuenta')[1].setAttribute('style', 'max-width:68px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('numeros')[0].setAttribute('style', 'max-width:60px')
            document.getElementsByClassName('transaccionesEF')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('transaccionesEF')[0].setAttribute('style', 'width: 350px')
          //document.getElementsByClassName('fechas')[0].setAttribute('style', 'max-width:115px')
          //document.getElementsByClassName('cuenta')[0].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
          //document.getElementsByClassName('cuenta')[1].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
          //document.getElementsByClassName('numeros')[0].setAttribute('style', 'max-width:55px')
          if (350 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            document.getElementsByClassName('fechas')[0].setAttribute('style', 'max-width:115px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            //document.getElementsByClassName('fechas')[0].setAttribute('style', 'max-width:115px')
            //document.getElementsByClassName('cuenta')[0].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('cuenta')[1].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('numeros')[0].setAttribute('style', 'max-width:52px')
            document.getElementsByClassName('transaccionesEF')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('transaccionesEF')[0].setAttribute('style', 'width: 400px; font-size: 14px')
          if (400 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            //document.getElementsByClassName('fechas')[0].setAttribute('style', 'max-width:90px')
            //document.getElementsByClassName('cuenta')[0].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('cuenta')[1].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('numeros')[0].setAttribute('style', 'max-width:50px')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            //document.getElementsByClassName('fechas')[0].setAttribute('style', 'max-width:90px')
            //document.getElementsByClassName('cuenta')[0].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('cuenta')[1].setAttribute('style', 'max-width:60px; top: 0px; position: sticky; z-index: 100')
            //document.getElementsByClassName('numeros')[0].setAttribute('style', 'max-width:50px')
            document.getElementsByClassName('transaccionesEF')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }
  }

  resendTransfer(tx) {
    let resendTransferJSON = {
      cuentaOrigen: tx.CCUENTA,
      cuentaDestino: tx.CCUENTA_DESTINO,
      monto: tx.VALOR,
      referencia: tx.DETALLE,
      moneda: tx.CMONEDA,
      resender: true
    };
    if (tx.CTRANSACCION == "6073") {
      resendTransferJSON['tarPendType'] = 'transferCtasPropias'
    }
    if (tx.CTRANSACCION == "6074") {
      resendTransferJSON['tarPendType'] = 'transferCtasTer'
    }
    if (tx.CTRANSACCION == "6071") {
      resendTransferJSON['tarPendType'] = 'transferCtasOtr'
    }
    if (tx.CTRANSACCION == "6071" && this.bandTarjetaCredito == 2) {
      resendTransferJSON['tarPendType'] = 'transferCtasOtrTarCred'
    }
    this.alerta.generarDialogoConMensajeDeIdiomas(this.idiomas, "RepeatTransfer").then((data) => {
      if (data) {
        if (resendTransferJSON['tarPendType'] == 'transferCtasPropias') {
          this.habilitarFunciones('transCtsProp', resendTransferJSON);
        }
        if (resendTransferJSON['tarPendType'] == 'transferCtasTer') {
          this.habilitarFunciones('transMismaInst', resendTransferJSON);
        }
        if (resendTransferJSON['tarPendType'] == 'transferCtasOtr') {
          this.habilitarFunciones('transOtraInst', resendTransferJSON);
        }
        if (resendTransferJSON['tarPendType'] == 'transferCtasOtrTarCred') {
          this.habilitarFunciones('transTarCred', resendTransferJSON);
        }
      }
    });
  }

  habilitarFunciones(funcionalidad, data) {
    this.dataTarPend = data;
    this.bandsFuncion = {
      'transCtsProp': false,
      'transMismaInst': false,
      'transOtraInst': false,
      'consultaTrans': false,
    }
    if (funcionalidad == 'consultaTrans') {
      this.consultarTransferenciasInicial()
    }
    this.bandsFuncion[funcionalidad] = true;
    this.alerta.clearAlerta();
    window.scroll(0, 0);
  }

  //FUNCION PARA ABRIR EL DIALOGO DE CONSULTA
  mostrarDialogo(data, i) {
    this.alerta.generarDialogoDetalleTransferencia(this.idiomas, data, this.bandConsultaTarjetaCredito, i, this.grande, this.mediano, this.normal)
  }

  /*reportePDF(i: number) {
    this.intento = true
    let nombreReporte = this.bandTarjetaCredito == 2 ? "18_COMPROBANTE_PAGO_TC" : this.datos2[i]['CTRANSACCION'] == "6071" ? "COMPROBANTE_TRANSFERENCIA_SPI" : "COMPROBANTE_TRANSFERENCIA";
    if (this.tipoidentificacion[i] == 'CED') {
      var tipoidentificacion = "CÉDULA DE IDENTIDAD"
    } else {
      tipoidentificacion = "RUC"
    }
    var envio = {
      "nombre": nombreReporte,
      "subsistema": "18",
      "transaccion": "5003",
      "tipo": "PDF",
      "rep^NUMEROMENSAJE": this.datos2[i]['NUMEROMENSAJE']
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intento = false
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }*/

  close() {
    this.datos2 = []
    this.bandData = false
    this.mostrarFecha = false
    this.seleccion.reset()
    this.datedDesde = new Date(this.fechaActual.getFullYear(), this.fechaActual.getMonth(), 1)
    this.datedHasta = new Date(this.fechaActual)
  }
}