import { Component, OnInit, Input, DoCheck, Output, EventEmitter, HostListener } from '@angular/core';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Router, ActivatedRoute } from '@angular/router';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { internetComponent } from '../funciones/internet'
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FormControlValidators } from '../funciones/formcontrol';
import { OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatInputModule, MatTableModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-tareas-pendientes',
  templateUrl: './tareas-pendientes.component.html',
  styleUrls: ['./tareas-pendientes.component.scss']
})
export class TareasPendientesComponent implements DoCheck {

  @Output() tarPend = new EventEmitter<any>()
  @Output() numTarPen = new EventEmitter<Number>()
  @Input() idiomas: any;

  tareas: any;
  numTareasPrev: number;
  arrayOtp: UntypedFormControl[];
  arrayIntentos: boolean[];
  arrayTokensSolicitados: boolean[];
  arrayBandsEdit: boolean[];
  fecha: Date;
  pdf: any;
  comision: string;
  numeroReferenciaSPI: string = "";
  fechaTransaccion: string = "";
  numeroMensaje: string = "";
  bandMobile: boolean;

  constructor(private api: ApiService,
    public alerta: Alerta,
    private router: Router,
    private servicio: Servicios,
    private base: BaseDatos, public internet: internetComponent, private validators: FormControlValidators, public mostrarPdf: MostrarPDF) {
    this.idiomas = JSON.parse(localStorage.getItem('idioma'));
    this.numTareasPrev = 0;
    this.arrayOtp = [];
    this.arrayIntentos = [];
    this.arrayTokensSolicitados = [];
    this.arrayBandsEdit = [];
    this.comision = "";
    this.numeroReferenciaSPI = "";
    this.numeroMensaje = "";
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngDoCheck() {
    if (localStorage.getItem('ingresado')) {
      this.tareas = this.base.seleccionarTareas();
      this.numTarPen.emit(this.tareas.length);//Hacia Home - numero de tareas pendientes
      if ((this.numTareasPrev == 0) || (this.numTareasPrev != this.tareas.length)) {
        this.numTareasPrev = this.tareas.length;
        this.arrayOtpFormControlUpdate();
      }
    }
  }

  arrayOtpFormControlUpdate() {
    this.arrayOtp = []
    this.arrayIntentos = []
    this.arrayTokensSolicitados = []
    this.arrayBandsEdit = []
    for (let t of this.tareas) {
      this.arrayOtp.push(this.validators.otpForm());
      this.arrayIntentos.push(false);
      this.arrayTokensSolicitados.push(false);
      this.arrayBandsEdit.push(false);
    }
  }

  cerrar(i) {
    this.alerta.generarDialogoEliminarTarea("eliminarTarea", true, this.idiomas).then(
      (data) => {
        if (data)
          this.base.eliminarTarea(i);
      }
    )
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  solicitarToken(i, transaction) {
    const envio = { transaccion: transaction };
    this.arrayIntentos[i] = true;
    this.api.postProvider2InternetCheck('/clavetemporal', this.base.id_token, envio).then((data: any) => {
      this.arrayTokensSolicitados[i] = true;
      this.arrayIntentos[i] = false;
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
      setTimeout(function () { document.getElementById("soliToken" + i).focus(); }, 1);
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.arrayIntentos[i] = false;
        this.arrayTokensSolicitados[i] = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }


  enviarPrestamoPreAprobado(i) {
    let envio = this.base.seleccionarTareas()[i];
    this.api.postProvider2InternetCheck('/prestamospreaprobados', this.base.id_token, envio).then((data: any) => {
      this.alerta.presentarAlerta(this.idiomas.TransaccionE);
      this.arrayIntentos[i] = false;
      this.base.eliminarTarea(i);
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.arrayIntentos[i] = false;
        this.arrayTokensSolicitados[i] = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensaje)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    })
  }

  enviarRegistroServicio(i) {
    const envio = this.base.seleccionarTareas()[i];
    envio.otp = this.servicio.toAES(this.arrayOtp[i].value)
    envio.desencriptar = "1"
    this.arrayIntentos[i] = true;
    this.api.postProvider2InternetCheck('/ingresoservicio', this.base.id_token, envio).then((data: any) => {
      this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase())
      this.arrayIntentos[i] = false;
      this.base.eliminarTarea(i);
    }, (err) => {
      this.arrayBandsEdit[i] = true
      this.arrayIntentos[i] = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensaje)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  enviarRegistroBeneficiario(i) {
    let envio = this.base.seleccionarTareas()[i];
    envio.otp = this.servicio.toAES(this.arrayOtp[i].value)
    envio.desencriptar = "1"
    this.arrayIntentos[i] = true;
    this.api.postProvider2InternetCheck('/ingresobeneficiario', this.base.id_token, envio).then((data: any) => {
      this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase())
      this.arrayIntentos[i] = false;
      this.base.eliminarTarea(i);
    }, (err) => {
      this.arrayIntentos[i] = false;
      this.arrayBandsEdit[i] = true;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensaje)
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  enviarTransferenciaCtasPropias(i, envio) {
    envio.clave = this.servicio.toAES(this.arrayOtp[i].value)
    envio.desencriptar = "1"
    envio.comision = this.comision
    const envioMensaje = '<span><b>' + this.idiomas.DesdeCuenta + ': </b> ' + new OcultarCuentaPosTrans().transform(envio.cuentaOculta) +
      '</span><br>' + '<span><b>' + this.idiomas.HaciaCuenta + ': </b> ' + new OcultarCuentaPosTrans().transform(envio.cuentaPropiaOculta) +
      '</span><br>' + '<span><b>' + this.idiomas.MontoTransferencia + ': </b>' + envio.monto +
      '</span><br>' + '<span><b>' + this.idiomas.Comision + ': </b>' + envio.comision +
      '</span><br>' + '<span><b>' + this.idiomas.Referencia + ': </b>' + envio.referencia
    this.alerta.generarDialogoTransferenciasSeguro(this.idiomas.TransferenciasCuentasPropias, this.idiomas, envioMensaje).then((data) => {
      if (data) {
        this.api.postProvider2InternetCheck('/entrecuentaspropias', this.base.id_token, envio).then((data: any) => {
          this.arrayIntentos[i] = false
          this.alerta.presentarAlerta(data.mensajeUsuario)
          this.fechaTransaccion = data.fechaTransaccion
          this.numeroMensaje = data.numeroMensaje
          this.reportePDFTransferenciaCtasPropias(envio)
          this.base.eliminarTarea(i)
        }, (err) => {
          this.arrayIntentos[i] = false
          this.arrayBandsEdit[i] = true
          if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            }
            else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          }
          else { //Sin Internet
            this.arrayIntentos[i] = false
          }
        });
      } else {
        this.arrayIntentos[i] = false
      }
    });
  }

  enviarTransferenciaTerceros(i, envio) {
    envio.clave = this.servicio.toAES(this.arrayOtp[i].value)
    envio.desencriptar = "1"
    envio.comision = this.comision
    const envioMensaje = '<span><b>' + this.idiomas.DesdeCuenta + ': </b> ' + new OcultarCuentaPosTrans().transform(envio.cuentaOculta) +
      '</span><br>' + '<span><b>' + this.idiomas.HaciaCuenta + ':</b> ' + new OcultarCuentaPosTrans().transform(envio.cuentaBeOculta) +
      '</span><br>' + '<span><b>' + this.idiomas.MontoTransferencia + ': </b>' + envio.monto +
      '</span><br>' + '<span><b>' + this.idiomas.Comision + ': </b>' + envio.comision +
      '</span><br>' + '<span><b>' + this.idiomas.IdentificacionBeneficiario + ': </b>' + envio.identificacionBeneficiario +
      '</span><br>' + '<span><b>' + this.idiomas.NombreBeneficiario + ': </b>' + envio.beneficiario +
      '</span><br>' + '<span><b>' + this.idiomas.BancoBeneficiario + ': </b>' +
      envio.banco + '</span><br>' + '<span><b>' + this.idiomas.Referencia + ': </b>' + envio.referencia
    this.alerta.generarDialogoTransferenciasSeguro(this.idiomas.TransferenciasTerceros, this.idiomas, envioMensaje).then((data) => {
      if (data) {
        this.api.postProvider2InternetCheck('/tercerosmismainstitucion', this.base.id_token, envio).then((data: any) => {
          this.alerta.presentarAlerta(data.mensajeUsuario)
          this.fechaTransaccion = data.fechaTransaccion
          this.numeroMensaje = data.numeroMensaje
          this.reportePDFTransferenciaTerceros(envio)
          this.arrayIntentos[i] = false
          this.base.eliminarTarea(i)
        }, (err) => {
          this.arrayIntentos[i] = false
          this.arrayBandsEdit[i] = true
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout() }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            } else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          } else { // Sin Internet
            this.arrayIntentos[i] = false
          }
        });
      } else {
        this.arrayIntentos[i] = false
      }
    });
  }

  enviarTransferenciaOtros(i, envio) {
    this.numeroMensaje = "";
    this.numeroReferenciaSPI = "";
    envio.clave = this.servicio.toAES(this.arrayOtp[i].value)
    envio.desencriptar = "1"
    envio.comision = this.comision
    const envioMensaje = '<span><b>' + this.idiomas.DesdeCuenta + ': </b> ' + new OcultarCuentaPosTrans().transform(envio.cuentaView) +
      '</span><br>' + '<span><b>' + this.idiomas.HaciaCuenta + ':</b> ' + new OcultarCuentaPosTrans().transform(envio.cuentaBeView) +
      '</span><br>' + '<span><b>' + this.idiomas.MontoTransferencia + ': </b>' + envio.monto +
      '</span><br>' + '<span><b>' + this.idiomas.Comision + ': </b>' + envio.comision +
      '</span><br>' + '<span><b>' + this.idiomas.IdentificacionBeneficiario + ': </b>' + envio.identificacionBeneficiario +
      '</span><br>' + '<span><b>' + this.idiomas.NombreBeneficiario + ': </b>' + envio.beneficiario +
      '</span><br>' + '<span><b>' + this.idiomas.BancoBeneficiario + ': </b>' +
      envio.banco + '</span><br>' + '<span><b>' + this.idiomas.Referencia +
      ': </b>' + envio.referencia.toUpperCase()
    this.alerta.generarDialogoTransferenciasSeguro(envio.tarPendType == 'transferCtasOtrTarCred' ? this.idiomas.TransferenciasATarjetas : this.idiomas.TransferenciasAOtros, this.idiomas, envioMensaje).then((data) => {
      if (data) {
        this.api.postProvider2InternetCheck('/otrainstitucion', this.base.id_token, envio).then((data: any) => {
          this.alerta.presentarAlerta(data.mensajeUsuario)
          this.numeroReferenciaSPI = data.referenciaSPI
          this.fechaTransaccion = data.fechaTransaccion
          this.numeroMensaje = data.numeroMensaje
          this.reportePDFTransferenciaOtros(envio)
          this.arrayIntentos[i] = false
          this.base.eliminarTarea(i)
        }, (err) => {
          this.arrayIntentos[i] = false
          this.arrayBandsEdit[i] = true
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout() }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            } else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          } else { // Sin Internet
            this.arrayIntentos[i] = false
          }
        });
      } else {
        this.arrayIntentos[i] = false
      }
    });
  }

  sendServicePayment(i) {
    this.numeroMensaje = "";
    this.arrayIntentos[i] = true
    const paymentWSObject = this.base.seleccionarTareas()[i];
    paymentWSObject.clave = this.servicio.toAES(this.arrayOtp[i].value)
    paymentWSObject.desencriptar = "1"
    const messageDialog = `
    <span><b>${this.idiomas.Servicio}:</b> ${paymentWSObject.comentarioServicio}</span><br>
    <span><b>${this.idiomas.Suministro}:</b> ${paymentWSObject.numeroServicio}</span><br>
    <span><b>${this.idiomas.DesdeCuenta}:</b> ${new OcultarCuentaPosTrans().transform(paymentWSObject.cuenta)}</span><br>
    <span><b>${this.idiomas.Moneda}:</b> ${paymentWSObject.moneda}</span><br>
    <span><b>${this.idiomas.Monto}:</b> ${paymentWSObject.monto}</span><br>
    <span><b>${this.idiomas.Comision}:</b> ${paymentWSObject.comision}</span>
  `;
    this.alerta.generarDialogoTransferenciasSeguro(this.idiomas.pagServ, this.idiomas, messageDialog).then((data) => {
      if (data) {
        this.api.postProvider2InternetCheck('/pagoservicios', this.base.id_token, paymentWSObject).then((dataTr: any) => {
          this.arrayIntentos[i] = false
          this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase())
          this.fechaTransaccion = dataTr.fechaTransaccion
          this.numeroMensaje = dataTr.numeroMensaje
          this.reportePDFPagoServ(i)
        }, (err) => {
          this.arrayIntentos[i] = false
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            } else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          } else { // Sin Internet
            this.arrayIntentos[i] = false
          }
        });
      } else {
        this.arrayIntentos[i] = false
      }
    });
  }

  sendRecharge(i) {
    this.numeroMensaje = "";
    const rechargeWSObject = this.base.seleccionarTareas()[i]
    this.arrayIntentos[i] = true
    rechargeWSObject.clave = this.servicio.toAES(this.arrayOtp[i].value)
    rechargeWSObject.desencriptar = "1"
    const messageDialog = `
    <span><b>${this.idiomas.Servicio}:</b> ${rechargeWSObject.codigoServicio}</span><br>
    <span><b>${this.idiomas.Operadora}:</b> ${rechargeWSObject.comentarioServicio}</span><br>
    <span><b>${this.idiomas.Numero}:</b> ${rechargeWSObject.numeroServicio}</span><br>
    <span><b>${this.idiomas.DesdeCuenta}:</b> ${new OcultarCuentaPosTrans().transform(rechargeWSObject.cuenta)}</span><br>
    <span><b>${this.idiomas.Moneda}:</b> ${rechargeWSObject.moneda}</span><br>
    <span><b>${this.idiomas.Monto}:</b> ${rechargeWSObject.monto}</span><br>
    `;
    this.alerta.generarDialogoTransferenciasSeguro(this.idiomas.Recargas, this.idiomas, messageDialog).then((data) => {
      if (data) {
        this.api.postProvider2InternetCheck('/pagoservicios', this.base.id_token, rechargeWSObject).then((dataTr: any) => {
          this.arrayIntentos[i] = false
          this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase())
          this.fechaTransaccion = dataTr.fechaTransaccion
          this.numeroMensaje = dataTr.numeroMensaje
          this.reportePDFRecarga(i)
        }, (err) => {
          this.arrayIntentos[i] = false
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario)
              }
            } else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError)
            }
          } else { // Sin Internet
            this.arrayIntentos[i] = false
          }
        });
      } else {
        this.arrayIntentos[i] = false
      }
    });
  }

  editTarPend(i) {
    this.alerta.generarDialogoEliminarTarea("editarTarea", true, this.idiomas).then(
      (data) => {
        if (data) {
          let tempJson = this.tareas[i]
          if (tempJson.tarPendType == 'transferCtasTer' || tempJson.tarPendType == 'transferCtasPropias' || tempJson.tarPendType == 'transferCtasOtr' || tempJson.tarPendType == 'regBen') { //Tab Transferencias
            tempJson['sonTab'] = 1
          } else if (tempJson.tarPendType == 'transferCtasOtrTarCred' || tempJson.tarPendType == 'regBenTc') { //Tab Pago Tarjetas de Credito
            tempJson['sonTab'] = 2
          } else {
            if (tempJson.tarPendType == 'regServ' || tempJson.tarPendType == 'pagoServ' || tempJson.tarPendType == 'recargas') {  //Tab Servicios
              tempJson['sonTab'] = 3
            }
          }
          this.tarPend.emit(tempJson)
          this.base.eliminarTarea(i)
        }
      }
    )
  }

  reportePDFTransferenciaTerceros(transferencia) {
    var envio = {
      "nombre": "COMPROBANTE_TRANSFERENCIA",
      "subsistema": "18",
      "transaccion": "6074",
      "tipo": "PDF",
      "rep^NUMEROMENSAJE": this.numeroMensaje
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  reportePDFTransferenciaOtros(transferencia) {
    let nombre = ""
    nombre = transferencia.tarPendType == 'transferCtasOtr' ? "COMPROBANTE_TRANSFERENCIA_SPI" : "COMPROBANTE_TRANSFERENCIA_CRED";
    var envio = {
      "nombre": nombre,
      "subsistema": "18",
      "transaccion": "6071",
      "tipo": "PDF",
      "rep^NUMEROMENSAJE": this.numeroMensaje
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  reportePDFTransferenciaCtasPropias(transferencia) {
    var envio = {
      "nombre": "COMPROBANTE_TRANSFERENCIA",
      "subsistema": "18",
      "transaccion": "6073",
      "tipo": "PDF",
      "rep^NUMEROMENSAJE": this.numeroMensaje
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet

      }
    });
  }

  reportePDFPagoServ(i) {
    this.arrayIntentos[i] = true
    var envio = {
      'nombre': '18_COMPROBANTE_PAGO',
      'subsistema': '18',
      'transaccion': '6000',
      'tipo': 'PDF',
      "rep^NUMEROMENSAJE": this.numeroMensaje
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        this.arrayIntentos[i] = false
        this.base.eliminarTarea(i)
      }
    }, (err) => {
      this.arrayIntentos[i] = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  reportePDFRecarga(i) {
    this.arrayIntentos[i] = true
    let envio = {
      'usuario': '',
      'nombre': '18_COMPROBANTE_PAGO',
      'subsistema': '18',
      'transaccion': '6000',
      'tipo': 'PDF',
      "rep^NUMEROMENSAJE": this.numeroMensaje
    }
    this.api.postProvider2InternetCheck("/reporte", this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.pdf = data.reporte
        this.mostrarPdf.mostrarReportePDF(this.pdf)
        this.arrayIntentos[i] = false
        this.base.eliminarTarea(i)
      }
    }, (err) => {
      this.arrayIntentos[i] = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
      }
    });
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date()
    const year = now.getFullYear()
    const month = this.completeDigits(now.getMonth() + 1)
    const day = this.completeDigits(now.getDate())
    return `${year}-${month}-${day} 00:00:00.000`
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString()
  }

  obtenerComision(i, ctransfer) {
    this.arrayIntentos[i] = true
    const envio = this.base.seleccionarTareas()[i];
    envio.clave = this.servicio.toAES(this.arrayOtp[i].value)
    envio.desencriptar = "1"
    let enviocom = {
      alias: "tcrp1",
      "cri^tcrp1^PARAMETRO14^NORMAL": envio.cuentaView,
      "cri^tcrp1^PARAMETRO16^NORMAL": envio.monto
    }
    if (ctransfer == '7507') {
      enviocom['transaccion'] = "010041-lv-comision-txpropia-in.xml"
    } else if (ctransfer == '7508') {
      enviocom['transaccion'] = "010041-lv-comision-txmisma-in.xml"
    } else {
      enviocom['transaccion'] = "010041-lv-comision-txotras-in.xml"
    }
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, enviocom).then((data: any) => {
      if (data) {
        if (data.length > 0) {
          this.comision = data[0].PARAMETRO1
          if (this.comision == "null" || this.comision == "0") {
            this.comision = "0.00"
          }
        } else {
          this.comision = "0.00"
        }
        if (ctransfer == "7507") {
          this.enviarTransferenciaCtasPropias(i, envio)
        } else {
          if (ctransfer == "7508") {
            this.enviarTransferenciaTerceros(i, envio)
          } else {
            this.enviarTransferenciaOtros(i, envio)
          }
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.arrayIntentos[i] = false
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet
        this.arrayIntentos[i] = false
      }
    });
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }
}
