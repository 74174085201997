import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({ name: 'cantidadMonetaria', standalone: true })
export class CantidadMonetaria implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value));
    } else {
      return '0.00';
    }

  }
}

@Pipe({ name: 'cantidadMonetariaTransferencias', standalone: true })
export class CantidadMonetariaTransferencias implements PipeTransform {
  transform(value: string): string {
    if (value) {
      value = value.replace(/[X]/g, '')
      return new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(Number(value));
    } else {
      return '';
    }

  }
}

@Pipe({ name: 'cantidadMonetariaPosCon', standalone: true  })
export class CantidadMonetariaPosCon implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value));
    } else {
      return '0.00';
    }
  }
}

@Pipe({ name: 'cantidadMonetariaPosConMob', standalone: true  })
export class CantidadMonetariaPosConMob implements PipeTransform {
  transform(value: string): string {
    if (value) {
      var chars = value.split(".");
      return new Intl.NumberFormat('en-us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Number(chars[0]));
    } else {
      return '0';
    }
  }
}

@Pipe({ name: 'fechaSubString', standalone: true })
export class FechaSubString implements PipeTransform {
  transform(value: string): string {
    if (value == "0")
      return "-"
    else
      return value.substring(0, 10);
  }
}

@Pipe({ name: 'horaSubString', standalone: true })
export class HoraSubString implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.substring(10, 15);
    } else {
      return '';
    }
  }
}

@Pipe({ name: 'horaSubString1', standalone: true })
export class HoraSubString1 implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.substring(10, 16);
    } else {
      return '';
    }
  }
}

@Pipe({ name: 'ocultarCuenta', standalone: true })
export class OcultarCuenta implements PipeTransform {
  transform(value: string): string {
    let subs1 = value.substring(0, value.length - 4);
    //console.log(subs1)
    subs1 = subs1.replace(/[^]/g, 'X');
    let subs2 = value.substring(value.length - 4, value.length);
    return subs1 + subs2;
  }
}

@Pipe({ name: 'ocultarCuentaPosTrans', standalone: true })
export class OcultarCuentaPosTrans implements PipeTransform {
  transform(value: string): string {
    if (value) {
      let subs1 = value.substring(0,4);
      let subs2 = value.substring(value.length - 4, value.length);
      return subs1+ "XXX" + subs2;
    } else {
      return ''
    }

  }
}

@Pipe({ name: 'cambiarVariableCuenta', standalone: true })
export class CambiarVariableCuenta implements PipeTransform {
  transform(value: string): string {
    if(value){
      let subs1 = value.substring(0, value.length - 4);
      subs1 = subs1.replace(subs1, "...");
      let subs2 = value.substring(value.length - 4, value.length);
      return subs1 + subs2;
    } else {
      return ''
    }

  }
}

@Pipe({ name: 'cambiarTextoTransaccion', standalone: true })
export class CambiarTextoTransaccion implements PipeTransform {
  transform(value: string): string {
    if (value.includes("WEB")) {
      let subs1 = value.replace("WEB-", "");
      return subs1;
    } else if (value.includes("PC")) {
      let subs1 = value.replace("PC-", "");
      return subs1;
    } else {
      return ''

    }

  }
}

@Pipe({ name: 'cambiarTextoTransaccionDialogo', standalone: true})
export class CambiarTextoTransaccionDialogo implements PipeTransform {
  transform(value: string): string {
    if (value.includes("WEB")) {
      let subs1 = value.replace("WEB-", "WEB - ");
      return subs1;
    } else if (value.includes("PC")) {
      let subs1 = value.replace("PC-", "VENTANILLA - ");
      return subs1;
    } else {
      return ''

    }

  }
}

@Pipe({ name: 'ocultarOtp', standalone: true })
export class OcultarOtp implements PipeTransform {
  transform(value: string): string {
    let str = ""
    for (let i = 0; i < value.length; i++) {
      str += "*"
    }
    return str;
  }
}

@Pipe({name: 'formatoFechaInfBasica', standalone: true})
export class FormatoFechaInfBasica {
  transform(value: string): string {
    if(value == '' || value == 'null' || !value || value == 'Invalid Date'){
      return ''
    } else {
      var fecha = new Date(value);
      return fecha.getUTCFullYear() + "-" + this.anadirCero(fecha.getUTCMonth() + 1) + "-" + this.anadirCero(fecha.getUTCDate())

    }
  }
  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }
} 


@Pipe({ name: 'formatoFecha', standalone: true })
export class FormatoFecha implements PipeTransform {
  transform(value: string, idioma: string): string {
    //console.log(value);
    if (value) {
      let resp = ""
      if (value == '0')
        return resp = '-'
      else {
        value = value.toString().substring(0, 10)
        var fecha = new Date(value);
        if (new Date("0").getTime() == fecha.getTime()) {
          resp = " - "
        }
        else {
          fecha.setTime(fecha.getTime() + (12 * 60 * 60 * 1000)); //Establece fecha a medio dia para evitar conflictos de GTM

          var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
          var meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

          var day = fecha.getDate();
          var month_index = fecha.getMonth();
          var year = fecha.getFullYear();

          if (idioma == "EN") {
            resp = "" + day + "-" + months[month_index] + "-" + year.toString().substring(2, 4);
          }
          else {
            resp = "" + day + "-" + meses[month_index] + "-" + year.toString().substring(2, 4);
          }
        }
      }
      if (value == 'null') {
        resp = '-'
      }
      return resp;
    } else {
      return '-';
    }

  }
}

@Pipe({ name: 'formatoFechaCalendar', standalone: true })
export class FormatoFechaCalendar implements PipeTransform {
  transform(value: string, idioma: string): string {
    if (value) {
      let resp = ""
      var fecha = new Date(value);
      if (new Date("0").getTime() == fecha.getTime()) {
        resp = " - "
      }
      else {

        var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

        var day = fecha.getDate();
        var month_index = fecha.getMonth();
        var year = fecha.getFullYear();

        if (idioma == "EN") {
          resp = "" + day + "-" + months[month_index] + "-" + year.toString().substring(2, 4);
        }
        else {
          resp = "" + day + "-" + meses[month_index] + "-" + year.toString().substring(2, 4);
        }
      }
      return resp;
    } else {
      return '';
    }

  }
}

@Pipe({ name: 'formatoFechaTranferServ', standalone: true })
export class FormatoFechaTransferServ implements PipeTransform {
  transform(value: string, idioma: string): string {
    if (value) {
      let resp = ""
      value = value.substring(0, 10)
      var fecha = new Date(value);
      if (new Date("0").getTime() == fecha.getTime()) {
        resp = " - "
      }
      else {
        fecha.setTime(fecha.getTime() + (12 * 60 * 60 * 1000)); //Establece fecha a medio dia para evitar conflictos de GTM

        var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

        var day = fecha.getDate();
        var month_index = fecha.getMonth();
        var year = fecha.getFullYear();

        if (idioma == "EN") {
          resp = "" + day + "-" + months[month_index] + "-" + year.toString().substring(2, 4);
        }
        else {
          resp = "" + day + "-" + meses[month_index] + "-" + year.toString().substring(2, 4);
        }
      }
      return resp;
    } else {
      return '';
    }

  }
}

@Pipe({ name: 'cadenaCaracteres', standalone: true })
export class CadenaCaracteres implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.substring(0, 24);
    } else {
      return '------';
    }
  }
}

@Pipe({ name: 'plazoMeses', standalone: true })
export class plazoMeses implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return new Intl.NumberFormat('en-us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Number(value));
    } else {
      return '';
    }

  }
}

@Pipe({ name: 'sucursal', standalone: true })
export class sucursal implements PipeTransform {
  transform(value: string): string {
    if (value=="0"||value=="99"){
      return "NINGUNA"
    }else{
        return value;
    }
  }
}

