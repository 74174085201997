import { Injectable } from '@angular/core';
import { Servicios } from './encryptar';

@Injectable()
export class BaseDatos {

    usuario: string;
    contrasena: string;
    activeUser: any;    //Informacion de usuario Activo
    longitud: number;
    id_token: string;
    cpersona: string;
    usuarioHB: string;
    nombreUsuario: string;
    compania: string;
    sucursal: string;
    oficina: string;
    posicion: number;   //Posicion de la informacion del usuario activo en el arreglo de la BDD
    dias: number;
    intentosOffline: number;

    constructor(public servicio: Servicios) {
        this.longitud = 1; //Cantidad de Usuarios permitida para almacenar informacion
        this.dias = 15;
        this.intentosOffline = 0;
        if (localStorage.getItem('activeUser')) {
            this.activeUser = JSON.parse(this.servicio.desencriptar(localStorage.getItem('activeUser')));
            this.usuario = this.activeUser.usuario
            this.contrasena = this.activeUser.contrasena
            this.id_token = this.activeUser.objeto.token
            this.posicion = Number(this.servicio.desencriptar(localStorage.getItem('posicion')));
            this.cpersona = this.activeUser.objeto.cpersona
            this.usuarioHB = this.activeUser.objeto.usuarioHB
            this.nombreUsuario = this.activeUser.objeto.nombreUsuario
            this.compania = this.activeUser.objeto.compania
            this.sucursal = this.activeUser.objeto.sucursal
            this.oficina = this.activeUser.objeto.oficina
        }
    }

    setInfoUser(cio, suc, ofc) {
        this.compania = cio;
        this.sucursal = suc;
        this.oficina = ofc;
    }

    buscarUsuario(user, password, token, cpers, usr, nombreUsr, idm: any) {
        return new Promise((resolve) => {
            this.usuario = user
            this.contrasena = this.servicio.credentialsHash(password).toString();
            this.id_token = token;
            this.cpersona = cpers;
            this.usuarioHB = usr;
            this.nombreUsuario = nombreUsr;

            if (localStorage.getItem('BaseDatos') == null) { //Primer Ingreso a la Aplicacion - No existen datos.
                let lista = {
                    'usuario': this.usuario, 'fecha': (new Date()).toString(), 'objeto': {
                        'contrasena': this.contrasena,
                        'tareas': [],
                        'tareasecomm': [],
                        'token': this.id_token,
                        'cpersona': this.cpersona,
                        'usuarioHB': this.usuarioHB,
                        'nombreUsuario': this.nombreUsuario,
                        'compania': this.compania,
                        'sucursal': this.sucursal,
                        'oficina': this.oficina,
                        'consultas': {
                        }
                    }
                };
                let listaB = '[' + JSON.stringify(lista) + ']'
                this.activeUser = lista
                this.posicion = 0;
                this.crearBase(listaB);
                resolve(true)
            } else {
                for (let i = 0; i < JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos'))).length; i++) {
                    if (this.usuario == JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))[i].usuario) { // Si Usuario existe, devuelve el indice del arreglo con su informacion 
                        this.posicion = i;
                        this.activeUser = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))[i];
                        this.activeUser.fecha = (new Date()).toString();//Actualiza fecha de ultimo inicio de sesion
                        this.activeUser.objeto.contrasena = this.contrasena; //Actualiza clave de ultimo ingreso
                        this.activeUser.objeto.token = this.id_token;//Actualiza token de ultimo inicio de sesion
                        let base = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))
                        base[i].fecha = this.activeUser.fecha//Guarda en base con datos actualizados de fecha
                        base[i].objeto.token = this.activeUser.objeto.token//Guarda en base con datos actualizados de token
                        this.crearBase(JSON.stringify(base))
                        resolve(true);
                        return
                    }
                }
                //Si el usuario no existe en la BDD
                let lista = {
                    'usuario': this.usuario, 'fecha': (new Date()).toString(), 'objeto': {
                        'contrasena': this.contrasena,
                        'tareas': [],
                        'tareasecomm': [],
                        'token': this.id_token,
                        'cpersona': this.cpersona,
                        'usuarioHB': this.usuarioHB,
                        'nombreUsuario': this.nombreUsuario,
                        'compania': this.compania,
                        'sucursal': this.sucursal,
                        'oficina': this.oficina,
                        'consultas': {
                        }
                    }
                }
                let indice = this.verificarLongitud()
                if (indice) {   // Si ingresa un usuario nuevo, SUPERIOR al numero de usuarios permitidos para almacenar informacion

                    let base = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))
                    this.posicion = base.length - 1
                    base.push(lista)
                    this.activeUser = lista
                    this.crearBase(JSON.stringify(base))
                    this.borrarViejo()
                    resolve(true)

                } else {    // Si ingresa un usuario nuevo y NO excede el limite de usuarios permitidos en la BDD
                    let base = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))
                    base.push(lista)
                    this.posicion = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos'))).length
                    this.activeUser = lista
                    this.crearBase(JSON.stringify(base))
                    resolve(true)
                }
            }
        })
    }

    UsuarioOffline(user, password) {
        this.usuario = user
        this.contrasena = this.servicio.credentialsHash(password).toString();
        let usuarioBand = false;
        if ((localStorage.getItem('offline') != null) && (localStorage.getItem('BaseDatos') != null)) {
            let offlineDes = this.servicio.desencriptar(localStorage.getItem('offline'))
            if (offlineDes == "true") {
                for (let i = 0; i < JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos'))).length; i++) {
                    if (this.usuario == JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))[i].usuario) {
                        usuarioBand = true;
                        if (this.usuario == JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))[i].usuario &&
                            this.contrasena == JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))[i].objeto.contrasena) {
                            this.posicion = i;
                            this.activeUser = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))[i];

                            this.id_token = ""
                            this.activeUser.objeto.token = this.id_token;//Borra Token
                            let base = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))
                            base[i].objeto.token = this.activeUser.objeto.token//Guarda en base con datos actualizados de token
                            this.crearBase(JSON.stringify(base))
                            this.intentosOffline = 0
                            //localStorage.setItem("offline",this.servicio.encriptar("true"))
                            return 1 //Usuario y Pssw Offline OK!
                        }
                    }
                }
                if (usuarioBand) {
                    this.intentosOffline++;
                    if (this.intentosOffline >= 3) {    //Borrar  base de datos cuando 3 intentos erroneos
                        localStorage.setItem("offline", this.servicio.encriptar(new Date().getTime().toString()))
                        this.intentosOffline = 0
                        //localStorage.removeItem('BaseDatos')
                        return -2 //Modo Offline bloqueado por varios intentos
                    }
                    else
                        return 0 //Usr OK y Pssw x
                }
                else {
                    this.intentosOffline++;
                    if (this.intentosOffline >= 3) {    //Borrar  base de datos cuando 3 intentos erroneos
                        localStorage.setItem("offline", this.servicio.encriptar(new Date().getTime().toString()))
                        //localStorage.removeItem('BaseDatos')
                        return -2 //Modo Offline bloqueado por varios intentos
                    }
                    else
                        return -1; //No registro de usuario!
                }
            }
            else {
                return -3; //Offline Bloqueado
            }
        }
        else {
            return -1; //Base Vacia!
        }
    }

    seleccionarTareas() {
        return this.activeUser.objeto.tareas;
    }

    seleccionarTareasEcomm() {
        return this.activeUser.objeto.tareasecomm;
    }

    seleccionarConsultas() {
        return this.activeUser.objeto.consultas;
    }

    seleccionarToken() {
        return this.activeUser.objeto.token;
    }

    eliminarTarea(i) {
        this.activeUser.objeto.tareas.splice(i, 1)
        this.actualizarBase()
    }

    eliminarTareaEcomm(i) {
        this.activeUser.objeto.tareasecomm.splice(i, 1)
        this.actualizarBase()
    }

    insertarTarea(tarea) {
        this.activeUser.objeto.tareas.push(tarea);
        this.actualizarBase();
    }

    insertarTareaEcomm(tarea) {
        this.activeUser.objeto.tareasecomm.push(tarea);
        this.actualizarBase();
    }

    insertarPosicionConsolidada(posCons) {
        this.activeUser.objeto.consultas.PosicionConsolidada = posCons;
        this.actualizarBase();
    }
    insertarPosicionConsolidadaPreaprobado(posConsPre) {
        this.activeUser.objeto.consultas.PosicionConsolidadaPreaprobado = posConsPre;
        this.actualizarBase();
    }

    //Almacenar, Editar, Eliminar y Retornar Consultas
    insertarConsulta(consultaAlias, data) {
        this.activeUser.objeto.consultas[consultaAlias] = data;
        this.actualizarBase();
    }

    retornarConsulta(consultaAlias) {
        if (this.activeUser.objeto.consultas[consultaAlias])
            return this.activeUser.objeto.consultas[consultaAlias];
        else
            return null;
    }

    eliminarConsulta(consultaAlias) {
        if (this.activeUser.objeto.consultas[consultaAlias]) {
            this.activeUser.objeto.consultas.splice(this.activeUser.objeto.consultas.indexOf(consultaAlias), 1);
            this.actualizarBase();
        } else 
            return null;
    }

    editarConsulta(consultaAlias, data) {
        if (this.activeUser.objeto.consultas[consultaAlias]) {
            this.activeUser.objeto.consultas[consultaAlias] = data
            this.actualizarBase();
        } else 
            return null;
    }
    //.

    insertarTransacciones(consulta) {
        if (!this.activeUser.objeto.consultas.Transacciones) {
            this.activeUser.objeto.consultas.Transacciones = []
        }
        let bandExistente = false;
        for (let i = 0; i < this.activeUser.objeto.consultas.Transacciones.length; i++) {
            if (consulta.cuenta == this.activeUser.objeto.consultas.Transacciones[i].cuenta) {
                this.activeUser.objeto.consultas.Transacciones[i] = consulta;
                bandExistente = true;
                break;
            }
        }
        if (!bandExistente) {
            this.activeUser.objeto.consultas.Transacciones.push(consulta);
        }
        this.actualizarBase();
    }

    insertarSaldosBloqueados(consulta) {
        if (!this.activeUser.objeto.consultas.SaldosBloqueados) {
            this.activeUser.objeto.consultas.SaldosBloqueados = []
        }
        let bandExistente = false;
        for (let i = 0; i < this.activeUser.objeto.consultas.SaldosBloqueados.length; i++) {
            if (consulta.cuenta == this.activeUser.objeto.consultas.SaldosBloqueados[i].cuenta) {
                this.activeUser.objeto.consultas.SaldosBloqueados[i] = consulta;
                bandExistente = true;
                break;
            }
        }
        if (!bandExistente) {
            this.activeUser.objeto.consultas.SaldosBloqueados.push(consulta);
        }
        this.actualizarBase();
    }

    insertarSaldosPorConfirmar(consulta) {
        if (!this.activeUser.objeto.consultas.SaldosPorConfirmar) {
            this.activeUser.objeto.consultas.SaldosPorConfirmar = []
        }
        let bandExistente = false;
        for (let i = 0; i < this.activeUser.objeto.consultas.SaldosPorConfirmar.length; i++) {
            if (consulta.cuenta == this.activeUser.objeto.consultas.SaldosPorConfirmar[i].cuenta) {
                this.activeUser.objeto.consultas.SaldosPorConfirmar[i] = consulta;
                bandExistente = true;
                break;
            }
        }
        if (!bandExistente) {
            this.activeUser.objeto.consultas.SaldosPorConfirmar.push(consulta);
        }
        this.actualizarBase();
    }

    insertarSaldosPignorados(consulta) {
        if (!this.activeUser.objeto.consultas.SaldosPignorados) {
            this.activeUser.objeto.consultas.SaldosPignorados = []
        }
        let bandExistente = false;
        for (let i = 0; i < this.activeUser.objeto.consultas.SaldosPignorados.length; i++) {
            if (consulta.cuenta == this.activeUser.objeto.consultas.SaldosPignorados[i].cuenta) {
                this.activeUser.objeto.consultas.SaldosPignorados[i] = consulta;
                bandExistente = true;
                break;
            }
        }
        if (!bandExistente) {
            this.activeUser.objeto.consultas.SaldosPignorados.push(consulta);
        }
        this.actualizarBase();
    }

    darPosicionConsolidada() {
        if (this.activeUser.objeto.consultas.PosicionConsolidada)
            return this.activeUser.objeto.consultas.PosicionConsolidada;
        else
            return [];
    }

    darTransacciones(cuenta) {
        if (!this.activeUser.objeto.consultas.Transacciones) {
            this.activeUser.objeto.consultas.Transacciones = []
        }
        return this.activeUser.objeto.consultas.Transacciones.find(item => {
            return item.cuenta == cuenta
        })

    }

    darSaldosBloqueados(cuenta) {
        if (!this.activeUser.objeto.consultas.SaldosBloqueados) {
            this.activeUser.objeto.consultas.SaldosBloqueados = []
        }
        return this.activeUser.objeto.consultas.SaldosBloqueados.find(item => {
            return item.cuenta == cuenta
        })

    }

    darSaldosPorConfirmar(cuenta) {
        if (!this.activeUser.objeto.consultas.SaldosPorConfirmar) {
            this.activeUser.objeto.consultas.SaldosPorConfirmar = []
        }
        return this.activeUser.objeto.consultas.SaldosPorConfirmar.find(item => {
            return item.cuenta == cuenta
        })

    }

    darSaldosPignorados(cuenta) {
        if (!this.activeUser.objeto.consultas.SaldosPignorados) {
            this.activeUser.objeto.consultas.SaldosPignorados = []
        }
        return this.activeUser.objeto.consultas.SaldosPignorados.find(item => {
            return item.cuenta == cuenta
        })

    }

    insertarToken(token) {
        this.activeUser.objeto.token = token;
        this.actualizarBase();
    }

    verficarViejo() {
        let datos = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')));
        let viejo = new Date();
        let indexViejo = 0;
        for (let i = datos.length - 1; i >= 0; i--) {
            if (new Date(datos[i].fecha) < viejo) {
                viejo = new Date(datos[i].fecha);
                indexViejo = i;
            }
        }
        return indexViejo;  // Retorno del indice de usuario mas antiguo
    }

    borrarViejo() {
        let datos = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')));
        datos.splice(this.verficarViejo(), 1)
        this.crearBase(JSON.stringify(datos))
    }

    verificarLongitud() {
        let datos = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')));
        if (datos.length >= this.longitud) {
            let index = this.verficarViejo();
            return this.darUsuarioIndice(index);
        } else {
            return false;
        }
    }

    crearBase(base) {
        localStorage.setItem('posicion', this.servicio.encriptar(this.posicion.toString()))
        localStorage.setItem('activeUser', this.servicio.encriptar(JSON.stringify(this.activeUser)))
        localStorage.setItem('BaseDatos', this.servicio.encriptar(base))
    }

    actualizarBase() {
        localStorage.setItem('posicion', this.servicio.encriptar(this.posicion.toString()))
        this.activeUser.fecha = new Date().toString();
        let base = JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')));
        base[this.posicion] = this.activeUser;
        localStorage.setItem('BaseDatos', this.servicio.encriptar(JSON.stringify(base)))
        localStorage.setItem('activeUser', this.servicio.encriptar(JSON.stringify(this.activeUser)))
    }

    darUsuarioIndice(i) {
        return JSON.parse(this.servicio.desencriptar(localStorage.getItem('BaseDatos')))[i].usuario
    }

    darUsuario() {
        return this.activeUser.usuario
    }

    expirarToken() {
        this.id_token = " ";
    }

    limpiarUsuarioActivoBase() {
        this.activeUser = {}
        this.usuario = ""
        this.contrasena = ""
        this.id_token = ""
        this.posicion = 0
        this.cpersona = ""
        this.usuarioHB = ""
        this.nombreUsuario = ""
        this.compania = ""
        this.sucursal = ""
        this.oficina = ""
        localStorage.setItem('ingresado', this.servicio.encriptar('No ingresado'));
        localStorage.removeItem('activeUser')
    }
}