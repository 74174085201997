<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch" id="tableDiv" style="padding-bottom: 60px;">
  <mat-card class="cards-transations" *ngIf="bandsFuncion.consultaTrans && !bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6>
          <strong *ngIf="bandTarjetaCredito !=2">{{idiomas.CTransferencias | uppercase}}</strong>
          <strong *ngIf="bandTarjetaCredito ==2">{{idiomas.CTransferenciasTarjeta | uppercase}}</strong>
        </h6>
      </div>
    </div>
    <div style="padding: 16px;">
      <div class="form-row">
        <div class="col-md-4" [class.col-12]="bandMobile" *ngIf="bandTarjetaCredito !=2" style="width: 140px">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>Tipo</mat-label>
            <mat-select [formControl]="seleccion" (selectionChange)="limpiarTabla()">
              <mat-option *ngFor="let ttransf of idiomas.OpcionesConsTransf" [value]="ttransf.value">
                {{ttransf.viewValue}}
                <mat-divider style="border-top-width: 2px;"></mat-divider>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="internet.internet || mostrarFecha" class="col-4" [class.col-6]="bandMobile"
          [class.dategrande]="grande" style="min-width: 145px">
          <mat-form-field style="font-size: .85em;" appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.FDesde}}</mat-label>
            <input matInput readonly [ngModel]="datedDesde | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
              [ngModelOptions]="{standalone: true}">
            <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="datedDesde" [max]="maxDate"
              [matDatepicker]="pickerdesde">
            <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
            <mat-datepicker touchUi #pickerdesde></mat-datepicker>
          </mat-form-field>
        </div>
        <div id="lastelement" *ngIf="internet.internet || mostrarFecha" class="col-4" [class.col-6]="bandMobile"
          [class.dategrande]="grande" style="min-width: 145px">
          <mat-form-field appearance="outline" style="font-size: .85em;" fxFlexFill>
            <mat-label>{{idiomas.FHasta}}</mat-label>
            <input matInput readonly [ngModel]="datedHasta | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
              [ngModelOptions]="{standalone: true}">
            <input matInput readonly name="fhasta" [hidden]='true' [(ngModel)]="datedHasta" [max]="maxDate"
              [matDatepicker]="pickerhasta">
            <mat-datepicker-toggle matSuffix [for]="pickerhasta"></mat-datepicker-toggle>
            <mat-datepicker touchUi #pickerhasta></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="bandData && !bandMobile">
        <div style="padding-top: 10px;"></div>
        <div class="row" style="margin-left: -24px !important;">
          <div class="col flecha" style="margin-right: 4px;">
            <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('transaccionesEF','left')">
              keyboard_arrow_left</mat-icon>
          </div>
          <div class="col container2Aux" [class.heightconteiner2]="!(datos2.length>numMaxRow)"
            (scroll)="scroll('transaccionesEF')">
            <table mat-table [dataSource]="datos2" class="transaccionesEF" id="tabla04Trans">
              <ng-container matColumnDef="Fecha">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Fecha}}</th>
                <td mat-cell *matCellDef="let dato">
                  {{dato.FREAL | formatoFechaTranferServ:idiomas.IdiomaSeleccionado}}
                </td>
              </ng-container>
              <ng-container *ngIf="bandTarjetaCredito != 2" matColumnDef="Descripcion">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Descripcion}}</th>
                <td mat-cell *matCellDef="let dato" style="max-width: 100px !important">
                  {{dato.DESCRIPCION}}</td>
              </ng-container>
              <ng-container *ngIf="bandTarjetaCredito == 2" matColumnDef="Descripcion">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Descripcion}}</th>
                <td mat-cell *matCellDef="let dato" style="max-width: 100px !important">
                  {{dato.DETALLE}}</td>
              </ng-container>
              <ng-container matColumnDef="Monto">
                <th mat-header-cell *matHeaderCellDef>{{idiomas.Monto}}</th>
                <td mat-cell *matCellDef="let dato">
                  {{dato.VALOR | cantidadMonetaria}}
                </td>
              </ng-container>
              <ng-container matColumnDef="RepetirTransfer">
                <th mat-header-cell *matHeaderCellDef style="text-align:center;">{{idiomas.Reenviar}}</th>
                <td mat-cell *matCellDef="let dato" style="text-align:center;  max-width: 75px !important">
                  <mat-icon *ngIf="dato.NUMEROMENSAJE" title={{idiomas.Reenviar}} (click)="resendTransfer(dato)"
                    style="cursor: pointer !important;">autorenew</mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="Imprimir">
                <th mat-header-cell *matHeaderCellDef style="text-align:center;">
                  {{idiomas.DetalleTransferencia}}</th>
                <td mat-cell *matCellDef="let dato; let i = index"
                  style="text-align:center; max-width: 75px !important">
                  <mat-icon title={{idiomas.DetalleTransferencia}} style="cursor: pointer !important;"
                    (click)="mostrarDialogo(dato, i)">
                    library_books</mat-icon>
                </td>
              </ng-container>
              <tr mat-header-row class="mayt-header-row" *matHeaderRowDef="displayedColumnsTransf; sticky: true"></tr>
              <tr id="celda" mat-row
                *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsTransf;"
                [ngClass]="{gray: even}">{{showHideRows2(last)}}</tr>
            </table>
          </div>
          <div class="col flecha">
            <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('transaccionesEF','right')">
              keyboard_arrow_right
            </mat-icon>
          </div>
        </div>
      </div>
      <div class="row">
        <div id="floatCont" class="col-6">
          <button mat-raised-button (click)="consultaEjecutar()" title={{idiomas.Consultar}}
            *ngIf="seleccion.valid || (bandTarjetaCredito==2 && bandConsultaTarjetaCredito)" color="primary"
            class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
        <div id="floatCont" align="end" style="text-align: end" class="col-6">
          <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
            <mat-icon> clear_all</mat-icon>
            {{idiomas.Limpiar}}
          </button>
        </div>
      </div>
      <div *ngIf=intento>
        <br>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </mat-card>
  <div style="padding-left: 16px; padding-right: 16px;"
    *ngIf="bandMobile && (bandsFuncion.transCtsProp || bandsFuncion.transMismaInst || bandsFuncion.transOtraInst || bandsFuncion.transTarCred || bandsFuncion.transTarCred)">
    <button mat-raised-button (click)="habilitarFunciones('consultaTrans',true)" title={{idiomas.ConServPagados}}
      style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;" color="primary"
      class="buttonBene">
      <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
      <strong *ngIf="bandTarjetaCredito !=2">{{idiomas.CTransferencias}}</strong>
      <strong *ngIf="bandTarjetaCredito ==2">{{idiomas.CTransferenciasTarjeta}}</strong>
    </button>
  </div>
  <div *ngIf="bandsFuncion.consultaTrans && bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6>
          <strong *ngIf="bandTarjetaCredito !=2">{{idiomas.CTransferencias | uppercase}}</strong>
          <strong *ngIf="bandTarjetaCredito ==2">{{idiomas.CTransferenciasTarjeta | uppercase}}</strong>
        </h6>
      </div>
    </div>
    <div style="padding-top: 16px;">
      <div class="form-row">
        <div class="col-md-4" [class.col-12]="bandMobile" *ngIf="bandTarjetaCredito !=2" style="width: 140px">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>Tipo</mat-label>
            <mat-select [formControl]="seleccion" (selectionChange)="limpiarTabla()">
              <mat-option *ngFor="let ttransf of idiomas.OpcionesConsTransf" [value]="ttransf.value">
                {{ttransf.viewValue}}
                <mat-divider style="border-top-width: 2px;"></mat-divider>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="internet.internet || mostrarFecha" class="col-4" [class.col-6]="bandMobile"
          [class.dategrande]="grande" style="min-width: 145px">
          <mat-form-field style="font-size: .85em;" appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.FDesde}}</mat-label>
            <input matInput readonly [ngModel]="datedDesde | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
              [ngModelOptions]="{standalone: true}">
            <input matInput readonly name="fdesde" [hidden]='true' [(ngModel)]="datedDesde" [max]="maxDate"
              [matDatepicker]="pickerdesde">
            <mat-datepicker-toggle matSuffix [for]="pickerdesde"></mat-datepicker-toggle>
            <mat-datepicker touchUi #pickerdesde></mat-datepicker>
          </mat-form-field>
        </div>
        <div id="lastelement" *ngIf="internet.internet || mostrarFecha" class="col-4" [class.col-6]="bandMobile"
          [class.dategrande]="grande" style="min-width: 145px">
          <mat-form-field appearance="outline" style="font-size: .85em;" fxFlexFill>
            <mat-label>{{idiomas.FHasta}}</mat-label>
            <input matInput readonly [ngModel]="datedHasta | formatoFechaCalendar:idiomas.IdiomaSeleccionado"
              [ngModelOptions]="{standalone: true}">
            <input matInput readonly name="fhasta" [hidden]='true' [(ngModel)]="datedHasta" [max]="maxDate"
              [matDatepicker]="pickerhasta">
            <mat-datepicker-toggle matSuffix [for]="pickerhasta"></mat-datepicker-toggle>
            <mat-datepicker touchUi #pickerhasta></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 container-card-mobile" *ngIf="bandData && bandMobile"
        [class.heightconteiner2]="!(datos2.length>numMaxRow)">
        <mat-list>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let dato of datos2; let even = even;">
            <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
              <div class="col-8">
                <div mat-line style="font-weight: bold; font-size: .9em;">{{dato.FREAL |
                  formatoFecha:idiomas.IdiomaSeleccionado}}</div>
                <div mat-line>
                  <div *ngIf="bandTarjetaCredito != 2" style="font-size: .8em; white-space: initial;">
                    {{dato.DESCRIPCION
                    | uppercase}}</div>
                  <div *ngIf="bandTarjetaCredito == 2" style="font-size: .8em; white-space: initial;">{{dato.DETALLE |
                    uppercase}}</div>
                </div>
              </div>
              <div class="col-4" style="text-align: end;">
                <div style="font-size: .9em;" class="color-letras-cartas">
                  {{dato.VALOR | cantidadMonetaria}}</div>
              </div>
              <div class="col-6" style="font-weight: bold; font-size: .9em;"><a
                  style="padding-right: 15px;">{{idiomas.Reenviar}}</a> <mat-icon *ngIf="dato.NUMEROMENSAJE"
                  title={{idiomas.Reenviar}} (click)="resendTransfer(dato)"
                  style="cursor: pointer !important; vertical-align: text-bottom;">autorenew
                </mat-icon>
              </div>
              <div class="col-6" style="font-weight: bold; font-size: .9em; text-align: end;"><a
                  style="padding-right: 15px;">{{idiomas.DetalleTransferencia}}</a>
                <mat-icon title={{idiomas.DetalleTransferencia}}
                  style="cursor: pointer !important; vertical-align: text-bottom;" (click)="mostrarDialogo(dato, i)">
                  library_books</mat-icon>
              </div>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="row">
        <div id="floatCont" class="col-6">
          <button mat-raised-button (click)="consultaEjecutar()" title={{idiomas.Consultar}}
            *ngIf="seleccion.valid || (bandTarjetaCredito==2 && bandConsultaTarjetaCredito)" color="primary"
            class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
        <div id="floatCont" align="end" style="text-align: end" class="col-6">
          <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
            <mat-icon> clear_all</mat-icon>
            {{idiomas.Limpiar}}
          </button>
        </div>
      </div>
      <div *ngIf=intento>
        <br>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
  <div *ngIf="bandsFuncion.transCtsProp">
    <app-transferencias-cuentas-propias #transCtsProp [mediano]="mediano" [grande]="grande" [normal]="normal"
      [idiomas]="idiomas" [dataTarPend]="dataTarPend"></app-transferencias-cuentas-propias>
  </div>
  <div *ngIf="bandsFuncion.transMismaInst">
    <app-transferencias-misma-institutcion #transMismaInst (evtNuevoBenef)="nuevoBeneficiario($event)"
      [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend">
    </app-transferencias-misma-institutcion>
  </div>
  <div *ngIf="bandsFuncion.transOtraInst">
    <app-transferencias #transOtraInst (evtNuevoBenef)="nuevoBeneficiario($event)" [mediano]="mediano" [grande]="grande"
      [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend"></app-transferencias>
  </div>
  <div *ngIf="bandsFuncion.transTarCred">
    <app-transferencia-tarjetas-credito #transTarCred (evtNuevoBenef)="nuevoBeneficiario($event)" [mediano]="mediano"
      [grande]="grande" [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend">
    </app-transferencia-tarjetas-credito>
  </div>
  <div
    *ngIf="!bandMobile && (bandsFuncion.transCtsProp || bandsFuncion.transMismaInst || bandsFuncion.transOtraInst || bandsFuncion.transTarCred || bandsFuncion.transTarCred)"
    class="float-transacciones">
    <button mat-fab (click)="habilitarFunciones('consultaTrans',true)" title={{idiomas.Regresar}} class="float-right"
      color="primary">
      <mat-icon class="white">arrow_back</mat-icon>
    </button>
  </div>
</div>