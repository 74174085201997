<div class="textura">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div style="text-align: center">
        <img src="assets/resources/mipmap-hdpi/tituloDialogo.png" class="imagen4"/>
    </div>
    <strong>
        <h2 mat-dialog-title style="text-align: center;  color: #0069b2;">{{idiomas.Inicio}}</h2>
    </strong>

    <mat-dialog-content>
        <div style="text-align: center; color: #0069b2;">
            <a>{{idiomas.BienvenidoD | uppercase}} {{nombreUsuario}}</a>
            <img src="assets/resources/mipmap-hdpi/dialogo_Inicio.png" class="imagen3" />
            <a>{{idiomas.BienvenidoDC}}</a>
            <hr style="background-color: #0069b2 !important">
            <a>{{idiomas.BienvenidoDM}}</a>
        </div>
    </mat-dialog-content>
</div>