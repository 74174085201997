<div class="example-form " style="padding-top: 2% !important; padding-bottom: 60px;" fxLayout="column"
  fxLayoutAlign="start stretch">
  <mat-card class="cards-transations" *ngIf="!bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.DepositoP | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding:16px">
      <div class="form-row">
        <div *ngIf="mostrarDatosS" class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
            style="min-width: 152px; padding-top: 17px ">{{idiomas.PInvertir}}</label>
          <mat-form-field style="min-width: 152px" class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
            appearance="outline">
            <span matPrefix>$&nbsp;</span>
            <input matInput autocomplete="off" id="montoID"
              (blur)="montoPrestamo.setValue(this.validators.FormatNumber($event.target.value));"
              (keyup.enter)="montoPrestamo.setValue(this.validators.FormatNumber($event.target.value));"
              [formControl]="montoPrestamo" maxlength="10">
            <mat-error *ngIf="montoPrestamo.hasError('minAmount')">
              <strong>{{idiomas.montoDesde}} {{data[0].MONTOMINIMO | cantidadMonetaria}} - {{montoMaximo |
                cantidadMonetaria}}</strong>
            </mat-error>
            <mat-error *ngIf="montoPrestamo.hasError('maxAmount')">
              <strong>{{idiomas.montoDesde}} {{data[0].MONTOMINIMO | cantidadMonetaria}} - {{montoMaximo |
                cantidadMonetaria}}</strong>
            </mat-error>
            <mat-error *ngIf="montoPrestamo.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div *ngIf="mostrarDatosS" class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
            style="min-width: 152px; padding-top: 17px">{{idiomas.Plazo }} ({{idiomas.Dias}}):</label>
          <mat-form-field appearance="outline" style="min-width: 152px"
            class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <input matInput autocomplete="off" (keypress)="amountKeyInputControl($event)" id="plazo"
              [formControl]="plazoN" maxlength="31">

            <mat-error *ngIf="plazoN.value!='' && plazoN.hasError('minAmount')">
              <strong>{{idiomas.plazoDesde}} {{data[0].PLAZOMINIMO}} - {{data[0].PLAZOMAXIMO}}</strong>
            </mat-error>
            <mat-error *ngIf="plazoN.value!='' && plazoN.hasError('maxAmount')">
              <strong>{{idiomas.plazoDesde}} {{data[0].PLAZOMINIMO}} - {{data[0].PLAZOMAXIMO}}</strong>
            </mat-error>
            <mat-error *ngIf="plazoN.hasError('pattern')">
              <strong>{{idiomas.ValorEntero}}</strong>
            </mat-error>
            <mat-error *ngIf="plazoN.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>

          </mat-form-field>
        </div>
      </div>
      <div class="form-row" *ngIf="mostrarDatosS">
        <div class="col-6" style="min-width: 205px">
          <label class="rowCardTopSpace col-lg-6 col-md-6 col-sm-12 col-xs-12"
            style="min-width: 152px;">{{idiomas.Frecuencia}}</label>
          <mat-form-field class="rowCardTopSpace col-lg-6 col-md-6 col-sm-12 col-xs-12" appearance="outline">
            <mat-select [formControl]="tipoCuota" (selectionChange)="onSelectionChange($event)">
              <mat-option *ngFor="let tcuota of tCuota" [value]="tcuota">
                {{tcuota.descripcion}}
                <mat-divider style="border-top-width: 1px;"></mat-divider>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="left col-4 token-button" *ngIf="plazoN.valid && montoPrestamo.valid">
          <button mat-raised-button (click)="obtenerTablaN(); obtenerTabla();" color="primary"
            [disabled]="calculateDisabled" [hidden]="hiddenCalculate" class="buttonToken">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Calcular}}</button>
        </div>
      </div>
      <div class="col" style="margin-bottom: 24px !important;">
        <mat-card id="cardServ" class="cardServ" *ngIf="mostrarDatosListado">
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha" style="margin-right: 4px;">
              <mat-icon [class.desaparecer]=flechaL1 id="flechaL1" (click)="scrollToN('tablaNueva','left')">
                keyboard_arrow_left</mat-icon>
            </div>
            <div class="col container3Aux" (scroll)="scrollN('tablaNueva')"
              [class.heightconteiner2]="!(datos.length>numMaxRow)">
              <table mat-table [dataSource]="datosNuevo" class="tableN tablaNueva ">
                <ng-container matColumnDef="escoger">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.POpcion}}</th>
                  <td mat-cell *matCellDef="let dato; let i=index;" class="left">
                    <mat-checkbox style="margin-top: 5px !important;" name="check" color="primary"
                      [checked]="dato.checked" [disabled]="contadorN>=1" (change)="checkAllOne($event, i,'tabla2') ">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="capital">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Capitalpl}}</th>
                  <td mat-cell *matCellDef="let dato" class="fechas left">
                    {{capitalIngresado}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="tasa">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Tasa}}</th>
                  <td mat-cell *matCellDef="let dato" class="tasa left"> {{dato.vencimiento== ' ' ? '' :dato.tasa
                    |cantidadMonetaria}}{{dato.vencimiento== ' ' ? '' :'%'}} </td>
                </ng-container>
                <ng-container matColumnDef="interes">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.InteresGando}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.vencimiento== ' ' ? '' :dato.interesG
                    |cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="impuesto">
                  <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Impuesto}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros">{{dato.vencimiento== ' ' ? '' :dato.impuesto
                    |cantidadMonetaria}}</td>
                </ng-container>
                <ng-container matColumnDef="valorRecibir">
                  <th mat-header-cell *matHeaderCellDef class="right left" style=" min-width: 120px !important;">
                    {{idiomas.PRecibir}}</th>
                  <td mat-cell *matCellDef="let dato" class="numeros" style=" min-width: 120px !important;">
                    <span
                      style="background: #fdc800 !important; padding: 5px; border-radius: 10px;">{{dato.vencimiento==
                      ' ' ? '' :dato.neto |cantidadMonetaria}}</span>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTabs; sticky: true"></tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsTabs;"
                  [ngClass]="{gray: even}">
                  {{showHideRowsN(last)}}</tr>
              </table>
            </div>
            <div class="col flecha" style="margin-left: 4px;">
              <mat-icon [class.desaparecer]=flechaR1 id="flechaR1" (click)="scrollToN('tablaNueva','right')">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </mat-card>
      </div>
      <div autocomplete="off" class="form-row">
        <label class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="mostrarDatosListado"
          style="padding-left: 20px;">{{idiomas.PlazoSugerido}}</label>
        <div class="col" style="margin-bottom: 24px !important;">
          <mat-card id="cardServ" class="cardServ" *ngIf="mostrarDatosListado">
            <div class="row" style="margin-left: -24px !important;">
              <div class="col flecha" style="margin-right: 4px;">
                <mat-icon id="lastelement" [class.desaparecer]=flechaL id="flechaL"
                  (click)="scrollTo('tablaServiciosP','left')">
                  keyboard_arrow_left</mat-icon>
              </div>
              <div class="col container1Aux" (scroll)="scroll('tablaServiciosP')"
                [class.heightconteiner2]="!(datos.length>numMaxRow)">
                <table mat-table [dataSource]="datos" class="tableH tablaServiciosP">
                  <ng-container matColumnDef="escoger">
                    <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.POpcion}}</th>
                    <td mat-cell *matCellDef="let dato; let i=index;" class="left">
                      <mat-checkbox style="margin-top: 5px !important;" name="check" color="primary"
                        [checked]="dato.checked" (change)="checkAllOne($event, i,'tabla') " [disabled]="deshabilitar">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="plansugerido">
                    <th mat-header-cell *matHeaderCellDef class="left" style=" min-width: 120px !important;">
                      {{idiomas.Plazo}}</th>
                    <td mat-cell *matCellDef="let dato"
                      style="padding-top: 5px; padding-bottom: 5px;min-width: 120px !important; " class="left">
                      {{dato.plazo}}</td>
                  </ng-container>
                  <ng-container matColumnDef="fvencimiento">
                    <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Vencimientopl}}</th>
                    <td mat-cell *matCellDef="let dato" class="fechas left">
                      {{dato.vencimiento | formatoFecha:idiomas.IdiomaSeleccionado}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="tasa">
                    <th mat-header-cell *matHeaderCellDef class="right left">{{idiomas.Tasa}}</th>
                    <td mat-cell *matCellDef="let dato" class="tasa left"> {{dato.tasa |cantidadMonetaria}}% </td>
                  </ng-container>
                  <ng-container matColumnDef="valorRecibir">
                    <th mat-header-cell *matHeaderCellDef class="right left" style=" min-width: 120px !important;">
                      {{idiomas.PRecibir}}</th>
                    <td mat-cell *matCellDef="let dato" class="numeros" style=" min-width: 120px !important">

                      <span style="background: #fdc800 !important; padding: 5px; border-radius: 10px;">{{dato.neto
                        |cantidadMonetaria}}</span>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTabs2; sticky: true"></tr>
                  <tr id="celda" mat-row
                    *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsTabs2;"
                    [ngClass]="{gray: even}">
                    {{showHideRows(last)}}</tr>
                </table>
              </div>
              <div class="col flecha" style="margin-left: 4px;">
                <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tablaServiciosP','right')">
                  keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div *ngIf="itemTableSelected">
        <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
          style="min-width: 152px;">{{idiomas.CuentaDebito}}</label>
        <mat-form-field class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12" style="min-width: 152px; "
          appearance="outline">
          <mat-label>{{idiomas.DesdeCuenta}}</mat-label>

          <mat-select [formControl]="accountFormControl"
            (selectionChange)="onSelectedAccount($event.value); consultaSucursales()">
            <mat-option *ngFor="let account of accounts" [value]="account.id">
              {{account.etiqueta}} - {{account.producto}}
              <mat-divider style="border-top-width: 1px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="accountFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf=mostrarSucursal>
        <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
          style="min-width: 120px;">{{idiomas.listaAgencias}}</label>
        <mat-form-field class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
          style="min-width: 120px;  max-height: 150px " appearance="outline">
          <mat-label>{{idiomas.Agencia}}</mat-label>
          <mat-select [formControl]="agencyFormControl"
            (selectionChange)="onSelectAgency($event.value); calculateSimultaion()" id="consultaAgencias"
            class="select option">
            <mat-form-field style="height: 35px;border-bottom-style: groove;"
              class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <input (keyup)="searchAgency($event.target.value.toUpperCase())" matInput
                placeholder="{{idiomas.FiltrarResultados | uppercase }}">
            </mat-form-field>
            <mat-option *ngFor="let agency of agencies" [value]="agency.CSUCURSAL"
              style="padding-right: 50px!important">
              {{agency.NOMBRE}} {{agency.OBSERVACIONES}}
              <mat-divider style="border-top-width: 1px;"></mat-divider>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="mostrarTerminos && selectedAgency!=''" [hidden]="!calculateDisabled">
        <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: left">
          <mat-checkbox name="check" color="primary" [checked]="checkedC" (change)="terminosC($event) "
            [disabled]="deshabilitar">
          </mat-checkbox>
          <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.AceptarT1}}<u style="cursor:pointer"
              (click)="reporteTerminos()">{{idiomas.AceptarT2}}</u></label>
        </div>
        <div class="form-row" *ngIf="internet.internet" (keyup.enter)="enviarRespuesta('A')"
          style="padding-left: 15px;">
          <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
              maxlength="6" appBlockCopyPaste>
            <mat-error *ngIf="tokenFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumeros}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('minlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
          </mat-form-field>
          <div *ngIf="internet.internet" class="left col-2 token-button">
            <button [disabled]="(!checkedC) || (enableCronoIn)" mat-raised-button (click)="solicitarToken()"
              color="primary" class="buttonToken">
              <mat-icon>security</mat-icon>
              {{idiomas.SolicitarToken}}
            </button>
          </div>
          <div *ngIf="mostrarCro" class="col" style="padding-left: 25px !important;">
            <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
              [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
          </div>
          <div class="col-1" *ngIf="internet.internet && generaDocumento"
            style="cursor:pointer;padding-top: 15px;max-width: 30px !important">
            <a (click)="reportePDFPlazo()" style="margin-top: 15px; padding-left: 30px">
              <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                picture_as_pdf</mat-icon></a>
          </div>
          <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
            *ngIf="internet.internet && !deshabilitarL" style="cursor:pointer;padding-top: 1em;">
            <label>{{idiomas.LicitudFondosR}}</label>
            <a (click)="reportePDFLicitud()" style="margin-top: 10px; padding-left: 30px">
              <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                picture_as_pdf</mat-icon></a>
          </div>
        </div>
      </div>
      <div *ngIf=intento style="padding-top: 10px !important; padding-bottom: 5px !important;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="form-row">
        <div id="floatCont" class="col-6">
          <button mat-raised-button
            *ngIf="accountS && mostrarCuenta && tokenFormControl.valid && mantenimiento && calculateDisabled"
            (click)="enviarRespuesta('A')" title={{idiomas.Aceptar}} color="primary" class="buttonSubmit">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
        </div>
        <div id="floatCont" align="end" style="text-align: end;" class="col-6">
          <button mat-raised-button *ngIf="mostrarDatosListado" (click)="enviarRespuesta('N')" title={{idiomas.Limpiar}}
            color="warn" class="buttonClean">
            <mat-icon> clear_all</mat-icon>
            {{idiomas.Limpiar}}
          </button>
        </div>
      </div>
    </div>
  </mat-card>
  <div *ngIf="bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.PlazoFijoA | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding:16px">
      <div class="form-row">
        <div *ngIf="mostrarDatosS" class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
            style="min-width: 152px;">{{idiomas.PInvertir}}</label>
          <mat-form-field style="min-width: 152px" class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
            appearance="outline">
            <span matPrefix>$&nbsp;</span>
            <input matInput autocomplete="off" (keypress)="amountKeyInputControl($event);" id="montoID"
              (blur)="montoPrestamo.setValue(this.validators.FormatNumber($event.target.value));"
              (keyup.enter)="montoPrestamo.setValue(this.validators.FormatNumber($event.target.value));"
              [formControl]="montoPrestamo" maxlength="10" inputmode="decimal">
            <mat-error *ngIf="montoPrestamo.hasError('minAmount')">
              <strong>{{idiomas.montoDesde}} {{data[0].MONTOMINIMO | cantidadMonetaria}} - {{montoMaximo |
                cantidadMonetaria}}</strong>
            </mat-error>
            <mat-error *ngIf="montoPrestamo.hasError('maxAmount')">
              <strong>{{idiomas.montoDesde}} {{data[0].MONTOMINIMO | cantidadMonetaria}} - {{montoMaximo |
                cantidadMonetaria}}</strong>
            </mat-error>
            <mat-error *ngIf="montoPrestamo.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div *ngIf="mostrarDatosS" class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12" style="min-width: 152px;">{{idiomas.Plazo
            }} ({{idiomas.Dias}}):</label>
          <mat-form-field appearance="outline" style="min-width: 152px"
            class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <input matInput autocomplete="off" (keypress)="amountKeyInputControl($event)" id="plazo"
              [formControl]="plazoN" maxlength="31">

            <mat-error *ngIf="plazoN.value!='' && plazoN.hasError('minAmount')">
              <strong>{{idiomas.plazoDesde}} {{data[0].PLAZOMINIMO}} - {{data[0].PLAZOMAXIMO}}</strong>
            </mat-error>
            <mat-error *ngIf="plazoN.value!='' && plazoN.hasError('maxAmount')">
              <strong>{{idiomas.plazoDesde}} {{data[0].PLAZOMINIMO}} - {{data[0].PLAZOMAXIMO}}</strong>
            </mat-error>
            <mat-error *ngIf="plazoN.hasError('pattern')">
              <strong>{{idiomas.ValorEntero}}</strong>
            </mat-error>
            <mat-error *ngIf="plazoN.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>

          </mat-form-field>
        </div>
      </div>

      <div class="form-row" *ngIf="mostrarDatosS">
        <div class="col-12" style="min-width: 205px">
          <label class="rowCardTopSpace col-lg-6 col-md-6 col-sm-12 col-xs-12"
            style="min-width: 152px;">{{idiomas.Frecuencia}}</label>
          <mat-form-field class="rowCardTopSpace col-lg-6 col-md-6 col-sm-12 col-xs-12" appearance="outline">
            <mat-select [formControl]="tipoCuota" (selectionChange)="onSelectionChange($event)">
              <mat-option *ngFor="let tcuota of tCuota" [value]="tcuota">
                {{tcuota.descripcion}}
                <mat-divider style="border-top-width: 1px;"></mat-divider>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="left col-4 token-button" *ngIf="plazoN.valid && montoPrestamo.valid">
          <button mat-raised-button (click)="obtenerTablaN(); obtenerTabla();" color="primary"
            [disabled]="calculateDisabled" [hidden]="hiddenCalculate" class="buttonToken">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Calcular}}</button>
        </div>
      </div>

      <div autocomplete="off" class="form-row">
        <div class="col-12 container-card-mobile" *ngIf="bandMobile && mostrarDatosListado"
          [class.heightconteiner2]="!(datosNuevo.length>numMaxRow)">
          <mat-list>
            <mat-divider></mat-divider>
            <mat-list-item *ngFor="let dato of datosNuevo; let even = even; let i=index;">
              <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                <div class="col-8">
                  <div mat-line style="font-weight: bold; font-size: .9em;">{{idiomas.POpcion}}
                    <mat-checkbox style="margin-top: 5px !important;" name="check" color="primary"
                      [checked]="dato.checked" [disabled]="contadorN>=1" (change)="checkAllOne($event, i,'tabla2') ">
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-4" style="text-align: end;">
                  <div class="colorCartas" style="font-size: .9em;">
                    {{idiomas.PRecibir}} {{dato.vencimiento== ' ' ? '' :dato.neto |cantidadMonetaria}}</div>
                </div>
                <div class="col-12">
                  <mat-expansion-panel style="background-color: #fdc800;">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <strong class="colorCartas">{{idiomas.DetalleTransferencia}}</strong>
                      </mat-panel-title>
                      <mat-panel-description>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="form-row">
                      <div class="col-6" style="font-size: 1em;">{{idiomas.InteresGandoMob}}: {{dato.vencimiento== ' ' ?
                        ''
                        :dato.interesG
                        |cantidadMonetaria}}
                      </div>
                      <div class="col-6" style="font-size: 1em;">{{idiomas.Impuesto}}: {{dato.vencimiento== ' ' ? ''
                        :dato.impuesto
                        |cantidadMonetaria}}
                      </div>
                      <div class="col-6" style="font-size: 1em;">{{idiomas.Tasa}}: {{dato.vencimiento== ' ' ? ''
                        :dato.tasa
                        |cantidadMonetaria}}{{dato.vencimiento== ' ' ? '' :'%'}}
                      </div>
                    </div>
                  </mat-expansion-panel>
                </div>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
          <br>
        </div>

        <label class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="mostrarDatosListado"
          style="padding-left: 20px;">{{idiomas.PlazoSugerido}}</label>
        <div class="col-12 container-card-mobile" *ngIf="bandMobile && mostrarDatosListado"
          [class.heightconteiner2]="!(datos.length>numMaxRowMob)">
          <mat-list>
            <mat-divider></mat-divider>
            <mat-list-item *ngFor="let dato of datos; let even = even; ; let i=index;">
              <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                <div class="col-8">
                  <div mat-line style="font-weight: bold; font-size: .9em;">{{idiomas.POpcion}}
                    <mat-checkbox style="margin-top: 5px !important;" name="check" color="primary"
                      [checked]="dato.checked" (change)="checkAllOne($event, i,'tabla') " [disabled]="deshabilitar">
                    </mat-checkbox>
                  </div>
                  <div mat-line>
                    <div *ngIf="bandTarjetaCredito != 2" style="font-size: .8em; white-space: initial;">
                      {{idiomas.Plazo}} {{idiomas.Dias}} {{dato.plazo}}
                    </div>
                  </div>
                </div>
                <div class="col-4" style="text-align: end;">
                  <div class="colorCartas" style="font-size: .9em;">
                    {{idiomas.PRecibir}} {{dato.neto | cantidadMonetaria}}</div>
                </div>
                <div class="col-12">
                  <mat-expansion-panel style="background-color: #fdc800;">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <strong class="colorCartas">{{idiomas.DetalleTransferencia}}</strong>
                      </mat-panel-title>
                      <mat-panel-description>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="form-row">
                      <div class="col-8" style="font-size: 1em;">{{idiomas.VencimientoplMob}}: {{dato.vencimiento |
                        formatoFecha:idiomas.IdiomaSeleccionado}}
                      </div>
                      <div class="col-4" style="font-size: 1em;">{{idiomas.Tasa}}: {{dato.tasa |cantidadMonetaria}}
                      </div>
                      <div class="col-6" style="font-size: 1em;">{{idiomas.InteresGandoMob}}: {{dato.interesG
                        |cantidadMonetaria}}
                      </div>
                      <div class="col-6" style="font-size: 1em;">{{idiomas.Impuesto}}: {{dato.impuesto
                        |cantidadMonetaria}}
                      </div>
                    </div>
                  </mat-expansion-panel>
                </div>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
          <br>
        </div>
      </div>
      <div *ngIf="itemTableSelected">
        <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
          style="min-width: 152px;">{{idiomas.CuentaDebito}}</label>
        <mat-form-field class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12" style="min-width: 152px; "
          appearance="outline">
          <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
          <mat-select [formControl]="accountFormControl"
            (selectionChange)="onSelectedAccount($event.value); consultaSucursales()"
            (openedChange)="funcionRetornarCuentasOwn($event)">
            <mat-option *ngIf="!intentoCuentasOwn" disabled>
              <div style="margin: 10px;">
                <mat-progress-bar mode="indeterminate">
                </mat-progress-bar>
              </div>
            </mat-option>
            <mat-option *ngFor="let account of accounts" [value]="account.id">
              {{account.etiqueta}} - {{account.producto}}
              <mat-divider style="border-top-width: 1px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="accountFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf=mostrarSucursal>
        <label class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
          style="min-width: 120px;">{{idiomas.listaAgencias}}</label>
        <mat-form-field class="rowCardTopSpace col-lg-3 col-md-6 col-sm-12 col-xs-12"
          style="min-width: 120px;  max-height: 150px " appearance="outline">
          <mat-label>{{idiomas.Agencia}}</mat-label>
          <mat-select [formControl]="agencyFormControl"
            (selectionChange)="onSelectAgency($event.value); calculateSimultaion()" id="consultaAgencias"
            class="select option">
            <mat-form-field style="height: 35px;border-bottom-style: groove;"
              class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <input (keyup)="searchAgency($event.target.value.toUpperCase())" matInput
                placeholder="{{idiomas.FiltrarResultados | uppercase }}">
            </mat-form-field>
            <mat-option *ngFor="let agency of agencies" [value]="agency.CSUCURSAL"
              style="padding-right: 50px!important">
              {{agency.NOMBRE}} {{agency.OBSERVACIONES}}
              <mat-divider style="border-top-width: 1px;"></mat-divider>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="mostrarTerminos && selectedAgency!=''" [hidden]="!calculateDisabled">
        <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: left">
          <mat-checkbox name="check" color="primary" [checked]="checkedC" (change)="terminosC($event) "
            [disabled]="deshabilitar">
          </mat-checkbox>
          <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.AceptarT1}}<u style="cursor:pointer"
              (click)="reporteTerminos()">{{idiomas.AceptarT2}}</u></label>
        </div>
        <div class="form-row" *ngIf="internet.internet" (keyup.enter)="enviarRespuesta('A')"
          style="padding-left: 15px;">
          <div *ngIf="internet.internet" class="left col-6 token-button" style="padding-bottom: 10px !important;">
            <button style="margin-top: 0 !important;" [disabled]="(!checkedC) || (enableCronoIn)" mat-raised-button
              (click)="solicitarToken()" color="primary" class="buttonToken">
              <mat-icon>security</mat-icon>
              {{idiomas.SolicitarToken}}
            </button>
          </div>
          <div *ngIf="mostrarCro" class="col-6" style="padding-left: 15px !important;">
            <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
              [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
          </div>
          <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline" [class.col-12]="bandMobile">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
              inputmode="numeric" maxlength="6" appBlockCopyPaste>
            <mat-error *ngIf="tokenFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumeros}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('minlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" [class.col-12]="bandMobile" appearance="outline">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
          </mat-form-field>
          <div class="col-1" *ngIf="internet.internet && generaDocumento"
            style="cursor:pointer;padding-top: 15px;max-width: 30px !important">
            <a (click)="reportePDFPlazo()" style="margin-top: 15px; padding-left: 30px">
              <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                picture_as_pdf</mat-icon></a>
          </div>
          <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
            *ngIf="internet.internet && !deshabilitarL" style="cursor:pointer;padding-top: 1em;">
            <label>{{idiomas.LicitudFondosR}}</label>
            <a (click)="reportePDFLicitud()" style="margin-top: 10px; padding-left: 30px">
              <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                picture_as_pdf</mat-icon></a>
          </div>
        </div>
      </div>
      <div *ngIf=intento style="padding-top: 10px !important; padding-bottom: 5px !important;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="form-row">
        <div id="floatCont" class="col-6">
          <button mat-raised-button
            *ngIf="accountS && mostrarCuenta && tokenFormControl.valid && mantenimiento && calculateDisabled"
            (click)="enviarRespuesta('A')" title={{idiomas.Aceptar}} color="primary" class="buttonSubmit"
            [disabled]="disableButton">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
        </div>
        <div id="floatCont" align="end" style="text-align: end;" class="col-6">
          <button mat-raised-button *ngIf="mostrarDatosListado" (click)="enviarRespuesta('N')" title={{idiomas.Limpiar}}
            color="warn" class="buttonClean">
            <mat-icon> clear_all</mat-icon>
            {{idiomas.Limpiar}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>