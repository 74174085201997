import { Component, Input, OnInit } from '@angular/core';
import { FormControlValidators } from '../funciones/formcontrol';
import { ApiService } from '../api.service';
import { Servicios } from '../funciones/encryptar';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { internetComponent } from '../funciones/internet';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CadenaCaracteres, CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { ProductosComponent } from '../productos/productos.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatExpansionModule, MatDatepickerModule, MatButtonModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, CadenaCaracteres, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, ProductosComponent],
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css']
})
export class ProveedoresComponent implements OnInit {
  @Input() mediano: boolean;
  @Input() grande: boolean;
  @Input() normal: boolean;
  @Input() idiomas: any;

  bandProducts: boolean = false;
  parametros: any;
  proveedores: any[];
  categoriasTiendas: any[];
  datoBusquedaListModulos: any[];
  intento: boolean;
  grupoproductos: any;
  imagenOffline: string;


  constructor(private validators: FormControlValidators, public apiService: ApiService,
    private servicio: Servicios,
    public alerta: Alerta,
    public basedatos: BaseDatos, private _DomSanitizer: DomSanitizer, public internet: internetComponent) {
    this.parametros = [];
    this.proveedores = [];
    this.categoriasTiendas = [];
    this.intento = false;
    this.datoBusquedaListModulos = this.proveedores;
    this.grupoproductos = [];
    this.imagenOffline = '';
  }

  categoriaFormT = this.validators.comboForm();

  ngOnInit() {
  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.retornarCategorias());
    Promise.resolve(null).then(() => this.retornarTiendas());
    this.retornarImagenOffline()
  }

  applyFilterTypeStore(filterValue: any) {
    this.proveedores = this.datoBusquedaListModulos.filter((documento) => documento.tipo == filterValue)
    if (this.proveedores.length == 0) {
      console.log("No existe dicho documento")
    }
  }

  retornarTiendas() {
    let auxi: ITiendas = {
      id: '',
      tipo: '',
      slogan: '',
      nombre: '',
      cimagen: '',
      imagen: '',
      cpersona: '',
      ccuenta: ''
    }
    let enviomonedas = {
      transaccion: '010003-con-tiendas.xml'
    }
    var i = 0;
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, enviomonedas).then((data: any) => {
      for (const tienda of data.ttie0) {
        auxi.id = tienda.CTIENDA
        auxi.tipo = tienda.CCATEGORIAECOMM
        auxi.slogan = tienda.SLOGAN
        auxi.nombre = tienda.DESCRIPCION
        auxi.cimagen = tienda.CIMAGEN
        auxi.imagen = tienda.IMAGEN
        auxi.cpersona = tienda.CPERSONA
        auxi.ccuenta = tienda.CCUENTA
        this.proveedores.push(auxi)
        auxi = {
          id: '',
          tipo: '',
          slogan: '',
          nombre: '',
          cimagen: '',
          imagen: '',
          cpersona: '',
          ccuenta: ''
        }
        i = i + 1;
      }
      this.consultaOffiline(this.proveedores)
      this.intento = false
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        this.retornarTiendasOffline()
        if (!this.proveedores) {
          this.proveedores = []
        }
      }
    });
  }

  consultaOffiline(proveedores: any[]) {
    let aux = {
      id: '',
      tipo: '',
      slogan: '',
      nombre: '',
      cimagen: '',
      cpersona: '',
      ccuenta: ''
    }
    let lproveedores = []
    for (let proveedor of proveedores) {
      aux.id = proveedor.id
      aux.tipo = proveedor.tipo
      aux.slogan = proveedor.slogan
      aux.nombre = proveedor.nombre
      aux.cimagen = proveedor.cimagen
      aux.cpersona = proveedor.cpersona
      aux.ccuenta = proveedor.ccuenta
      lproveedores.push(aux)
      aux = {
        id: '',
        tipo: '',
        slogan: '',
        nombre: '',
        cimagen: '',
        cpersona: '',
        ccuenta: ''
      }
    }
    const consulta = {
      'fecha': new Date().toLocaleDateString(),
      'hora': new Date().toLocaleTimeString(),
      'data': lproveedores
    };
    this.basedatos.insertarConsulta('ListaTiendas', consulta);
  }

  retornarTiendasOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('ListaTiendas');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias)
    } else {
      this.proveedores = [];
      this.proveedores = ObjConsulta.data;
      this.datoBusquedaListModulos = this.proveedores
    }
  }

  retornarImagen(cimagen, i) {
    let envio = {
      transaccion: '010003-con-imagen.xml',
      "cri^timg0^CIMAGEN^NORMAL": cimagen
    }
    //envio["cri^timg0^IN('" + this.proveedores[0].cimagen + "')^CIMAGEN^NORMAL"] = this.proveedores[0].cimagen
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.proveedores[i]['imagen'] = data.timg0[0].IMAGEN;
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet    
      }
    })
  }

  retornarCategorias() {
    this.intento = true
    let auxi: ICategorias = {
      id: '',
      detalle: ''
    }
    let enviomonedas = {
      transaccion: '010003-lv-categoriasT.xml',
      alias: "tcat0"
    }
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviomonedas).then((data: any) => {
      for (const categoria of data) {
        auxi.id = categoria.CCATEGORIAECOMM
        auxi.detalle = categoria.DESCRIPCION
        if (categoria.CCATEGORIAECOMM) {
          this.categoriasTiendas.push(auxi)
        }
        auxi = {
          id: '',
          detalle: ''
        }
      }
      const consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.categoriasTiendas,
      };
      this.basedatos.insertarConsulta('CategoriasT', consulta);
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      } else {
        this.retornarCategoriasOffline()
        if (!this.categoriasTiendas) {
          this.categoriasTiendas = []
        }
      }
    });
  }

  retornarCategoriasOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CategoriasT');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias)
    } else {
      this.categoriasTiendas = [];
      this.categoriasTiendas = ObjConsulta.data;
    }
  }

  retornarProductos() {
    var lproductos = []
    let envio = {
      transaccion: '010003-con-productos.xml'
    }
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      this.grupoproductos = data.tpro0
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
      }
    });
  }

  retornarImagenOffline() {
    this.imagenOffline = "iVBORw0KGgoAAAANSUhEUgAAAQ4AAAC7CAMAAACjH4DlAAAAe1BMVEX6+vo8PDz////8/Pw6Ojr4+PgpKSksLCw+Pj40NDQv"
      + "Ly83NzcyMjImJibs7OwjIyPg4ODW1tbx8fHAwMDQ0NCQkJBubm6ysrKoqKhZWVmKioqAgIDn5+e7u7uioqLKysp1dXVlZWVKSkphYWGYmJhSUlJHR0cd"
      + "HR0ODg775Y0oAAAHVklEQVR4nO2b6ZKbOhBGoSW0AQYDNsbGy3iZue//hFctVnuWX0lNLPepVCohpMr6pne1g4AgCIIgCIIgCIIgCIIgCIIgCIIgCIIg"
      + "CIIgCIIgCIIgCIIgCIIgCIIgCIL4mzBgv/0R/g1YwBiD5S6H3/4k/wgsgL2RamGF+e2P8k8AhzRUcRP5rYY9Hft0QuaezR5bT9nKMFTKrL2OH4PtY2jo"
      + "Yb08d2oEa6sGIrde62G9AIny5bEs9/uyPC7zyKky0wMWjQ57RO1hOO18wVoEW1SbbaOzVAjpECLN4ma7qRY2r3aKQM7jcJTj6qEcgdNicdw2sTB6OmxP"
      + "rI2IL9uyDVgUwDHm/XMVylP02x/8bwDQ1m+p0ONJPxOLc27NA+qMjy+JlYe2Yd2gPCXmk1HcY94K6y1wldxaRUe68SyQssjmkMVBSf2TEvb4Smzx5Gwn"
      + "hmcqTo6eqYGWsVhp872LjG5xxeRSrM34RIdL39RgUFgx7E9a/aCEtQQuayxA2lvChzeT28K3uMFYHZrBHcazz6VR7hfX1i0iWKp4eNHWozaSeJVVbE/a"
      + "yPDeLpyd2LxqhEm01klihNRG5RhEy2T0KSV2zLW1v32GPwcLriIOH71Em5Rfdod9tczzts3zZbW/7lobJGAjJiNKD/hksfGjn8WKG/LGzJwE/8ATGZ/q"
      + "ZcFcpzLQVehwTSfNbCQJImhv6SWH++buOcFxhbivM5ROb6vKKfHV+ycxKhfrCiIbSeI4jGX5/NkFf9hbcScGT9LTsYBvogEUl8mSEoVDMCg1d0Fk9fx6"
      + "QPEm74KGCa9tbxZfHA7y97FKU6YpUI1N1ssj1s8+AmLFLZmnUi2vi08/Y1eDukwKFY+H/BpKPD2D1WRcSRM9dQHCWjUvybncuYJqPt8Juijadf37lI+e"
      + "guMeW2+sxcy49HnxzPYBWzMrtcxtCe7gw5G6QdgiXx5zp8ZBTPnHJlj3zvF9KtZDJZ+6r7U/7v6ASnGxmqInc1JEeb29qER+nArUZujZVKhiU3bntvax"
      + "FWNNxkX5zHJgHOTdCbW6a0oZROXunErNeZhe0S2KYShq345tzza9WY4jsezZ54OwkU4NHGGMDxmwasdlPwvDSitgi1syOIrS53ZSAweml85hPJiWdnlS"
      + "bme5FYJ9k8Zdw6p4gvbPMMGqPv1Y6SDo56X9/zrJzjaePtNaPVLtGo/h77A/y7FM1QpHGVANAzJU4+TuWrb7ybkYW4k4tbbx5GoE6Bn1ZToZg+qCNXsf"
      + "HbtRBtRy1sG66U/0JuTFKjU09lC/7T1Qw7VwbHB56wFb2fW2Cn+XnVtch5TCFbc2gB1sYyNJLFbBaFXg3y0+g+V5VkV0F2ss2I2ZVHFzxHlHzrUaK5Xf"
      + "/tR/DajNfOyRYaVl3cKMz+J4if38UfeDQRUnz59MvsG2H9kwEMQxoMGIAvlZj2okzcJdr3zwwVhCnl19tY+Tme7UVJxWGERxKNo/U/LN5dFVpuazInl6"
      + "/gj6BbYAmY5pK61ulDEV4GF3vRJE9ZvQ8/nylKV9AvbZdEaDbsFgM1NjOHUEUJ2yeBZi9h6qETF2lb2jOAdg0d2cTOynmGkFacYM5Oteh60vOvvgaZdS"
      + "TtNdA4+ruwwC7NAZjko9GAl+BYv6eku/Y7UJRTPNybRqH/Mp5DftbIN5uyPH4JApc1m4Sut9SrD2Eebcbh2qa9qwTl9LLrbgrRpYrx/EDrMpVLNNF7N2"
      + "G2Kr26q0cXR6m61w+OWrGG4tjuHwl8HeRoZhbChX0NtCIsypYvOQCl9sF/pDf49mbWQqQbKNm/5cEvdEy0sF42jEYyk68K4g2KbDhb2b/mAkCceJe5yd"
      + "CvDqwv4H7NGD2VBUaxyKQnW3KKbfK1/7tkciaM/9UNT6S3LDBAv7j3Aa/zgD8baPfWDq2XAMeMF5sluJeyDLvQ8bCJTJbG92h3eRbPe4CGNJX0EOBrUY"
      + "N744Xq8EUKwlrgAZo2cm4rKN30T3189c4EocLM5u8bquD6dbYvo9UuG/Gsi4KWoTrD7iGHBpmrodvrGQHxTudqjMt5Xar2DRZezZlFa42wSlKtnUlDCI"
      + "DgnHBePA/wqMlRkf5qRJgz0bO5yKO6+wzpPfspdQw1rHbag85bqTYfG5IYEI76G8FyPAvuS9G/n9NMOI2MO3vzwFR6M59rHh/MJ2+Kf7xTn/1UAiqEzI"
      + "5f4+b6BORdsWL5BN7rGppP7PPLZnDJYnlej3Xf5qgmAn//DlA+slqw8dcs71h7f3bd/zmEvmleqwHffCwHF2GZUtX848HoDLbF89Xr+4ebB23sjyuHht"
      + "82BLGc4wrzDm+AFWzRf4lXnx4MHyZG4dun1tOQJ4n8WOuHm9yuMeqGdLDen+xTOLdZf++g0D6frFbSPALwBdRD88ffovM/0RooNOhcz4hgWvchf5Pe57"
      + "PVVZum/+kHk48Eukv/0ZCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgiFfnfyQCT6Ctod7+AAAAAElFTkSuQmCC"
    localStorage.setItem('ImagenOffline', this.imagenOffline)
  }

  refreshTienda() {
    this.categoriaFormT.reset()
    this.proveedores = this.datoBusquedaListModulos
  }

  retornar() {
    this.bandProducts = false
    this.refreshTienda();
    window.scrollTo(0, 0);
  }

  tienda(value, i, cpersonaTienda) {
    this.parametros = { nombre: value, id: i, cpersona: cpersonaTienda }
    this.bandProducts = true
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }
}

interface ITiendas {
  id: string,
  tipo: string,
  slogan: string,
  nombre: string,
  cimagen: string,
  imagen: SafeUrl,
  cpersona: string,
  ccuenta: string
}

interface ICategorias {
  id: string,
  detalle: string
}
