<div class="textura">
    <h2 mat-dialog-title style="text-align: center;"><strong>{{idiomas.TituloInstalarApp}}</strong></h2>
    <mat-dialog-content *ngIf="bandMobile && bandIos" style="max-height: 80vh !important;">
        <div *ngIf="appInstall">
            <p *ngIf="isSafari" style="text-align: justify !important;">{{idiomas.InstalariOSTienda}}</p>
            <p *ngIf="!isSafari" style="text-align: justify !important;">{{idiomas.InstalariOSTiendaSafari}}</p>
        </div>
        <div style="padding-bottom: 20px; text-align: center;" *ngIf="appInstall && isSafari">
            <img src="assets/resources/mipmap-hdpi/dialogoinstall/image6.jpg" class="zoom" width="300" height="400" />
        </div>
        <div *ngIf="appInstall && isSafari">
            <p style="text-align: justify !important;">{{idiomas.InstalariOS}}</p>
        </div>
        <div style="padding-bottom: 20px;" *ngIf="appInstall && isSafari">
            <img src="assets/resources/mipmap-hdpi/dialogoinstall/image2.jpg" class="zoom" width="300" height="300" />
        </div>
        <div *ngIf="appInstall && isSafari">
            <p style="text-align: justify !important;">{{idiomas.InstalariOSSec}}</p>
        </div>
        <div style="padding-bottom: 20px;" *ngIf="appInstall && isSafari">
            <img src="assets/resources/mipmap-hdpi/dialogoinstall/image1.jpg" class="zoom" width="300" height="500" />
        </div>
        <div *ngIf="(appInstall || !appInstall) && isSafari">
            <p style="text-align: justify !important;">{{idiomas.InstalariOSThird}}</p>
        </div>
        <div style="padding-bottom: 20px;" *ngIf="!appInstall && isSafari">
            <img src="assets/resources/mipmap-hdpi/dialogoinstall/image4.jpg" class="zoom" width="300" height="500" />
        </div>
        <div *ngIf="!appInstall && isSafari">
            <p style="text-align: justify !important;">{{idiomas.DesintalarAplicacion}}</p>
        </div>
        <div class="col-12" style="text-align: center;" *ngIf="!appInstall && isSafari">
            <button mat-raised-button color="primary" style="height: 80px;" class="login button-login"
                (click)="deleteFromIOs()">
                <mat-icon style="font-size: 45px !important; height: 50px; width: 50px">delete_forever
                </mat-icon>
                <div class="form-row">
                    <div class="col-12" style="line-height: 10px; font-size: .9em;">
                        {{idiomas.Eliminar}}
                    </div>
                </div>
            </button>
        </div>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="bandMobile && !bandIos" style="max-height: 80vh !important;">
        <div>
            <p *ngIf="appInstall && !isChrome" style="text-align: justify !important;">{{idiomas.InstalarAndroidTienda}}
            </p>
            <p *ngIf="appInstall && isChrome" style="text-align: justify !important;">
                {{idiomas.InstalarAndroidTiendaCrhome}}</p>
        </div>
        <div *ngIf="appInstall && isChrome" style="padding-bottom: 20px; text-align: center;">
            <img src="assets/resources/mipmap-hdpi/dialogoinstall/image5.jpg" class="zoom" width="330" height="450" />
        </div>
        <div>
            <p *ngIf="appInstall && isChrome" style="text-align: justify !important;">{{idiomas.InstalariOSThird}}</p>
            <p *ngIf="!appInstall && isChrome" style="text-align: justify !important;">{{idiomas.InstaladoAndroid}}</p>
        </div>
        <div *ngIf="!appInstall && isChrome" style="padding-bottom: 20px;">
            <img src="assets/resources/mipmap-hdpi/dialogoinstall/image3.jpg" class="zoom" width="330" height="450" />
        </div>
        <!--<div style="text-align: center !important;" *ngIf="appInstall && isChrome">
            <button mat-raised-button color="primary" class="login button-login" (click)="addToHomeScreenAndroid()">
                <mat-icon style="font-size: 45px !important; height: 50px; width: 50px">cloud_download
                </mat-icon>
                <div class="form-row">
                    <div class="col-12" style="line-height: 2px; font-size: .9em;">
                        {{idiomas.Instalar}}
                    </div>
                    <div class="col-12" style="line-height: 20px; font-size: .9em;">
                        {{idiomas.App}}
                    </div>
                </div>
            </button>
        </div>-->
        <div *ngIf="!appInstall && isChrome">
            <p style="text-align: justify !important;">{{idiomas.DesintalarAplicacion}}</p>
        </div>
        <div class="col-12" style="text-align: center;" *ngIf="!appInstall && isChrome">
            <button mat-raised-button color="primary" style="height: 80px;" class="login button-login"
                (click)="deleteFromIOs()">
                <mat-icon style="font-size: 45px !important; height: 50px; width: 50px">delete_forever
                </mat-icon>
                <div class="form-row">
                    <div class="col-12" style="line-height: 10px; font-size: .9em;">
                        {{idiomas.Eliminar}}
                    </div>
                </div>
            </button>
        </div>
    </mat-dialog-content>
</div>