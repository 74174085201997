<div class="textura fontsize" [class.mediano]=mediano [class.grande]=grande>

    <div *ngIf="!bandMobile" style="max-width: 500px;">
        <div class="form-row">
            <h2 class="col-11" style="text-align: center;" mat-dialog-title>{{idiomas.SolicitudEnviada}}</h2>
            <div class="col-1" style="text-align: right;">
                <mat-icon color="primary" style="cursor: pointer;" (click)="close()">cancel</mat-icon>
            </div>
        </div>
        <div style="padding-bottom: 20px;">
            <app-alertas-login [alertasolicitud]="true" [mensaje]="idiomas.MsgNotificacionesPush">
            </app-alertas-login>
        </div>
        <div *ngIf="spinner" class="col-12 spinner">
            <mat-spinner style="margin: auto"></mat-spinner>
        </div>
    </div>
    <div *ngIf="bandMobile" style="text-align: center;">
        <div class="form-row">
            <h2 *ngIf="!spinner" class="col-11" mat-dialog-title>{{idiomas.IngresarPin}}</h2>
            <div *ngIf="!spinner" class="col-1" style="text-align: right;">
                <mat-icon color="primary" style="cursor: pointer;" (click)="close()">cancel</mat-icon>
            </div>
            <h2 *ngIf="spinner && enterPinForm" class="col-12" mat-dialog-title>{{idiomas.ValidandoPin}}</h2>
            <h2 *ngIf="spinner && !enterPinForm" class="col-12" mat-dialog-title>{{idiomas.ValidandoBiometric}}</h2>
        </div>
        <div *ngIf="spinner" class="col-12 spinner">
            <mat-spinner style="margin: auto"></mat-spinner>
        </div>
        <div *ngIf="!spinner">
            <div *ngIf="data.event == 'homebanking.login'">
                <p class="auth-p"><strong>{{idiomas.AutorizacionLogin}}</strong></p>
                <p class="auth-p"><strong>{{idiomas.Fecha}}:</strong> {{data.date |
                    formatoFechaCalendar:idiomas.IdiomaSeleccionado}}</p>
                <p class="auth-p"><strong>{{idiomas.Hora}}:</strong> {{data.time }}</p>
                <p class="auth-p"><strong>IP Address:</strong> {{data.ipAddress }}</p>
                <p class="auth-p"><strong>{{idiomas.Dispositivo}}:</strong> {{data.device }}</p>
            </div>
            <div *ngIf="data.event == 'homebanking.transference'">
                <p class="auth-p"><strong>{{idiomas.AutorizacionTransferencia}}</strong></p>
                <p class="auth-p"><strong>{{idiomas.DesdeCuenta}}:</strong> {{data.originAccount |
                    ocultarCuentaPosTrans}}</p>
                <p class="auth-p"><strong>{{idiomas.HaciaCuenta}}:</strong> {{data.destinationAccount |
                    ocultarCuentaPosTrans}}</p>
                <p class="auth-p"><strong>{{idiomas.MontoTransferencia}}:</strong> {{data.amount |
                    cantidadMonetariaTransferencias}}</p>
                <p class="auth-p"><strong>{{idiomas.NombreBeneficiario}}:</strong> {{data.destinationName}}</p>
                <p class="auth-p"><strong>{{idiomas.Referencia}}:</strong> {{data.reference}}</p>
                <p class="auth-p"><strong>{{idiomas.Fecha}}:</strong> {{data.date |
                    formatoFechaCalendar:idiomas.IdiomaSeleccionado}}</p>
                <p class="auth-p"><strong>{{idiomas.Hora}}:</strong> {{data.time }}</p>
            </div>
            <div *ngIf="data.event == 'homebanking.beneficiary'">
                <p class="auth-p"><strong>{{idiomas.AutorizacionBeneficiario}}</strong></p>
                <p class="auth-p"><strong>{{idiomas.Identificacion1}}:</strong> {{data.beneficiaryId}}</p>
                <p class="auth-p"><strong>{{idiomas.NombreBeneficiario}}:</strong> {{data.beneficiaryName}}</p>
                <p class="auth-p"><strong>{{idiomas.BancoBeneficiario}}:</strong> {{data.beneficiaryBank}}</p>
                <p class="auth-p"><strong>{{idiomas.Cuenta}}:</strong> {{data.destinationAccount |
                    ocultarCuentaPosTrans}}</p>
                <p class="auth-p"><strong>{{idiomas.correoElectronico}}:</strong> {{data.mail}}</p>
                <p class="auth-p"><strong>{{idiomas.NumeroTelefonoCelular}}:</strong> {{data.phone}}</p>
                <p class="auth-p"><strong>{{idiomas.Fecha}}:</strong> {{data.date |
                    formatoFechaCalendar:idiomas.IdiomaSeleccionado}}</p>
                <p class="auth-p"><strong>{{idiomas.Hora}}:</strong> {{data.time }}</p>
            </div>
            <div *ngIf="data.event == 'homebanking.maxammount'">
                <p class="auth-p"><strong>{{idiomas.AutorizacionMontosMaximos}}</strong></p>
                <p class="auth-p"><strong>{{idiomas.Transaccion}}:</strong> {{data.transation}}</p>
                <div *ngIf="data.frequency0 != 'null' && data.frequency0 != ''">
                    <p class="auth-p"><strong>{{idiomas.Frecuencia}}:</strong> {{data.frequency0}}</p>
                    <p class="auth-p"><strong>{{idiomas.Monto}}:</strong> {{data.ammount0 |
                        cantidadMonetariaTransferencias}}</p>
                </div>
                <div *ngIf="data.frequency1 != 'null' && data.frequency1 != ''">
                    <p class="auth-p"><strong>{{idiomas.Frecuencia}}:</strong> {{data.frequency1}}</p>
                    <p class="auth-p"><strong>{{idiomas.Monto}}:</strong> {{data.ammount1 |
                        cantidadMonetariaTransferencias}}</p>
                </div>
                <div *ngIf="data.frequency2 != 'null' && data.frequency2 != ''">
                    <p class="auth-p"><strong>{{idiomas.Frecuencia}}:</strong> {{data.frequency2}}</p>
                    <p class="auth-p"><strong>{{idiomas.Monto}}:</strong> {{data.ammount2 |
                        cantidadMonetariaTransferencias}}</p>
                </div>
                <p class="auth-p"><strong>{{idiomas.Fecha}}:</strong> {{data.date |
                    formatoFechaCalendar:idiomas.IdiomaSeleccionado}}</p>
                <p class="auth-p"><strong>{{idiomas.Hora}}:</strong> {{data.time }}</p>
            </div>
            <br>
            <div *ngIf="!enterPinForm" class="form-row">
                <div class="col-6" style="text-align: center;">
                    <button mat-button [disabled]="!hasBiometricCredentials" color="primary" class="buttonMenu"
                        (click)="webAuthVerifyUserPassw()">
                        <div class="iconRoundContainerMob">
                            <mat-icon style="margin-top: 5px !important;" class="iconMenu">fingerprint</mat-icon>
                        </div>
                        <div class="form-row">
                            <div class="col-12" style="line-height: 2px; font-size: .8em;">
                                {{idiomas.FingerprintButton}}
                            </div>
                            <div class="col-12" style="line-height: 20px; font-size: .8em;">
                                {{idiomas.FingerprintButtonF}}
                            </div>
                        </div>
                    </button>
                </div>
                <div class="col-6" style="text-align: center;">
                    <button mat-button class="buttonMenu" (click)="activePIN()">
                        <div class="iconRoundContainerMob">
                            <mat-icon class="iconMenu">dialpad</mat-icon>
                        </div>
                        <p style="font-size: .8em;">PIN</p>
                    </button>
                </div>
            </div>
            <div *ngIf="enterPinForm">
                <mat-form-field id="pin" *ngFor="let digit of digits; let i = index" appearance="outline"
                    style="width: 30px; margin-left: 5px;">
                    <input id="myInput{{i}}" (keydown)="blockText($event)"
                        (keyup)="enableButton(); focusNext(i, $event)" matInput [formControl]="digit.field"
                        (keypress)="changeValue(i, $event)" matInput [formControl]="digit.field" maxlength="1"
                        inputmode="numeric" type="password">
                </mat-form-field>
            </div>
            <div *ngIf=error>
                <app-alertas-login [error]="error" [mensaje]="msjerror"></app-alertas-login>
            </div>
            <div *ngIf="enterPinForm">
                <button mat-raised-button (click)="sendPin()" color="primary" class="buttonSubmit"
                    [disabled]="!validPin">
                    <mat-icon>download_done</mat-icon>{{idiomas.Enviar}}
                </button>
            </div>
        </div>
    </div>
</div>