import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { Alerta } from '../funciones/alerta';
import { internetComponent } from '../funciones/internet';
import { OcultarCuenta, CantidadMonetaria, OcultarCuentaPosTrans, CantidadMonetariaPosCon } from '../pipes/custom-pipes.pipe';
import { Servicios } from '../funciones/encryptar';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BeneficiariosComponent } from '../beneficiarios/beneficiarios.component';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { CurrencyInputDirective } from '../currency-input.directive';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatInputModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, CurrencyInputDirective, CantidadMonetariaPosCon, FlexLayoutModule, forwardRef(() => BeneficiariosComponent), TemporizadorComponent],
  selector: 'app-transferencias',
  templateUrl: './transferencias.component.html',
  styleUrls: ['./transferencias.component.css']
})
export class TransferenciasComponent implements OnInit, AfterViewInit {

  //Inputs
  @Input() idiomas: any;
  @Input() dataTarPend: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  //Outputs
  @Output() evtNuevoBenef = new EventEmitter<any>();

  //Variables
  monedaDesde: string;
  productoDesde: string;
  monedaHacia: string;
  productoHacia: string;
  bancoBeneficiario: string;
  bancoBeneficiarioN: string;
  efiInstFinanBeneficiario: string;
  identificacionBeneficiario: string;
  nombreBeneficiario: string;
  referencia = this.validators.textNumberForm();
  cuentas: any;
  intento: boolean;
  cuentasBeneficiarios: any;
  ultimasTransferencias: any;
  tokenSolicitado: boolean;
  fecha: Date;
  pdf: string = "";
  dowloadReporte: boolean;
  comision: string;
  bandDisableForm: boolean;
  bandCtsDesde: boolean = false;
  bandCtsHacia: boolean = false;
  CodigoBackend: string = "";
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  intentoCuentasOwn: boolean = false;
  intentoCuentasBene: boolean = false;
  usuarioSospechoso: boolean;
  intentoUsuarioSospechoso: boolean;
  numeroReferenciaSPI: string = "";
  fechaTransaccion: string = "";
  numeroMensaje: string = "";
  regBenefJson: any = {};
  bandNewBeneficiary: boolean = false;
  bandMobile: boolean;
  lfiltradocuentas: any[] = [];
  disableButton: boolean;
  intentoReenviar: boolean = false;
  isExpanded: boolean = false;
  pinValid: boolean = false;
  pinValidation: boolean;
  intentoBanred: boolean = true;
  //FormControls
  token = this.validators.otpForm();
  cuentaDesdeSelected = this.validators.comboForm();
  cuentaHaciaSelected = this.validators.comboForm();
  montoTransfer = this.validators.decimalsDolarForm();
  transferenciaSelected = new UntypedFormControl();

  constructor(public alerta: Alerta, public servicios: Servicios, public apiService: ApiService, public basedatos:
    BaseDatos, public validators: FormControlValidators, public internet: internetComponent, public mostrarPdf: MostrarPDF) {
    this.pinValidation = this.basedatos.retornarConsulta("pinValidation")
    this.dowloadReporte = false;
    this.comision = "";
    this.bandDisableForm = false;
    this.enableCronoIn = false;
    this.intentoCuentasOwn = false;
    this.intentoCuentasBene = false;
    this.mostrarCron = false;
    this.disableButton = false;
    this.intentoReenviar = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngOnInit() {
    this.token.setValue('');
    this.intento = false;
    this.tokenSolicitado = false;
    this.intentoReenviar = false;
  }

  ngAfterViewInit() {
    //Data desde tareas pendientes
    if (this.dataTarPend) {
      Promise.resolve(null).then(() => this.intento = true);
      setTimeout(function () { Promise.resolve(null).then(() => this.intentoReenviar = true), 100 });
      Promise.resolve(null).then(() => this.funcionRetornarCuentasOwn("e"));
    }
    //.
  }


  setValuesOfForm() {
    this.cuentaDesdeSelected.setValue(this.dataTarPend.cuentaOrigen);
    this.seleccionarCuentaDesde();
    this.cuentaHaciaSelected.setValue(this.dataTarPend.cuentaDestino);
    this.seleccionarCuentaHacia();
    this.montoTransfer.setValue(this.dataTarPend.monto == '0' ? '' : "$" + new CantidadMonetaria().transform(this.dataTarPend.monto));
    this.referencia.setValue(this.dataTarPend.referencia);
  }

  obtainOwnAccount() {
    this.intentoCuentasOwn = false;
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'fullNames': 'true',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN^tc0^=': '$userCode',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN^tc0^=': '18',
      'cri^tctp0^CTRANSACCION^JOIN^tc0^=': '6071',
      'cri^tctp0^VERSIONTRANSACCION^JOIN^tc0^=': '01'
    };
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data.tc0) {
        this.cuentas = this.transformadorCuentasDesde(data.tc0);
        // Data desde tareas pendientes
        if (this.dataTarPend) {
          Promise.resolve(null).then(() => this.funcionRetornarCuentasBene(true));
          Promise.resolve(null).then(() => this.setValuesOfForm());
        }
        // .
        // Almacenar Consulta
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentas,
        };
        this.basedatos.insertarConsulta('CtasTransfCtasProp', consulta); // Almacena en BDD las cuentas del socio
        this.bandCtsDesde = true;
        if (this.dataTarPend && this.bandCtsDesde && this.bandCtsHacia) {
          this.repeatTransferValidation();
        }
        this.intentoCuentasOwn = true;
      }
    }, (err) => {
      this.intentoCuentasOwn = false;
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      } else { // Sin Internet
        this.cuentasTransferOffline();
        if (!this.cuentas) {
          this.cuentas = [];
        }
      }
    });
  }

  funcionRetornarCuentasOwn(e) {
    if (!this.intentoCuentasOwn) {
      this.obtainOwnAccount();
    }
  }

  cuentasTransferOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasTransfCtasProp');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias);
      this.close();
    } else {
      this.cuentas = [];
      this.cuentas = ObjConsulta.data;
      this.bandCtsDesde = true;
      if (this.dataTarPend && this.bandCtsDesde && this.bandCtsHacia) {
        this.repeatTransferValidation();
      }
    }
  }

  logout() {
    const obj = {
      'salir': true
    };
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  ultimasTransferOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('UltTransfCtasOtr');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlertaX(this.idiomas.FaltaConsultaTransferencias.toUpperCase());
    } else {
      this.ultimasTransferencias = [];
      this.ultimasTransferencias = ObjConsulta.data;
    }
  }

  obtainBeneficiaryAccounts(bandDialog: boolean) {
    this.intentoCuentasBene = false;
    const envio = { otrosBancos: '1' };
    this.apiService.postProvider2InternetCheck('/beneficiarios', this.basedatos.id_token, envio).then((data: any) => {
      if (data) {
        this.cuentasBeneficiarios = this.transformadorCuentasHacia(data);
        this.lfiltradocuentas = this.cuentasBeneficiarios;
        // Data desde tareas pendientes
        if (this.dataTarPend) {
          this.intento = false;
          this.intentoReenviar = false;
          this.cuentaHaciaSelected.disable();
        }
        // Almacenar Consulta
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentasBeneficiarios,
        };
        this.basedatos.insertarConsulta('BeneficiariosOtrosBancos', consulta); // Almacena en BDD las cuentas del socio
        this.bandCtsHacia = true;
        if (this.dataTarPend && this.bandCtsDesde && this.bandCtsHacia) {
          this.repeatTransferValidation();
        }
        this.intentoCuentasBene = true;
      }
    }, (err) => {
      this.intentoCuentasBene = false;
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      } else { // Sin Internet
        this.beneficiariosTransferOffline(bandDialog);
        if (!this.cuentas) {
          this.cuentas = [];
        }
      }
      this.lfiltradocuentas = this.cuentasBeneficiarios;
    });
  }

  funcionRetornarCuentasBene(e) {
    if (!this.intentoCuentasBene) {
      this.obtainBeneficiaryAccounts(true);
    }
  }

  beneficiariosTransferOffline(bandDialog: boolean) {
    const ObjConsulta = this.basedatos.retornarConsulta('BeneficiariosOtrosBancos');
    if ((!ObjConsulta || ObjConsulta.data === 0) || !this.cuentas) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias);
      this.close();
    } else {
      this.cuentasBeneficiarios = [];
      if (bandDialog && !this.dataTarPend) {
        this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas);
      }
      this.cuentasBeneficiarios = ObjConsulta.data;
      this.bandCtsHacia = true;
      if (this.dataTarPend && this.bandCtsDesde && this.bandCtsHacia) {
        this.repeatTransferValidation();
      }
    }
  }

  repeatTransferValidation() {
    let existOriAccount = false;
    let existDesAccount = false;
    let maskOriAccount = this.dataTarPend.cuentaOrigen.viewValue || this.dataTarPend.cuentaOrigen;
    let maskDesAccount = this.dataTarPend.cuentaDestino.viewValue || this.dataTarPend.cuentaDestino;
    this.cuentas.filter(x => {
      if (x.viewValue.slice(-4) == maskOriAccount.slice(-4)) {
        existOriAccount = true;
      }
    });
    this.cuentasBeneficiarios.filter(x => {
      if (x.viewValue.slice(-4) == maskDesAccount.slice(-4)) {
        existDesAccount = true;
      }
    });
    if (!(existOriAccount && existDesAccount)) {
      this.alerta.generarDialogo(this.idiomas, "noRepetirTransferencia").then((data) => {
        if (data) {
          this.close();
        }
      });
    }
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        value: value[i]["tc0.CCUENTA"], viewValue:
          value[i]["tc0.CCUENTA"], 'descProducto': value[i]["tp0.DESCRIPCION"], moneda: value[i]["tc0.CMONEDA"]
        , saldo: value[i]["tsd0.SALDOMONEDAOFICIAL"] == "null" ? "0.00" : value[i]["tsd0.SALDOMONEDAOFICIAL"], SDisponible: this.idiomas.SDisponible
      });
    }
    return lista;
  }

  applyFilter(filterValue: any) {
    this.cuentasBeneficiarios = this.lfiltradocuentas.filter((cuenta) => (cuenta.value + cuenta.nombre + cuenta.banco).indexOf(filterValue) > -1);
  }

  transformadorCuentasHacia(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'value': value[i].id, 'viewValue':
          value[i].etiqueta, 'descProducto': this.idiomas.TiposCuentaBeneficiario.filter(x => { return value[i].tipo == x.value })[0].viewValue.toUpperCase(), banco: value[i].cbanco
        , identificacion: value[i].identificacionBeneficiario, nombre: value[i].nombreBeneficiario, moneda: value[i].moneda, cpersonaBanco: value[i].cpersona_banco,
        tipoidentificacion: value[i].ctipoIdentificacion, efiInstFinan: value[i].efiInstFinan, prodIdTipoDest: value[i].tipo, codigoinstitucion2ordenante: value[i].codigoinstitucion2ordenante
      });
    }
    return lista;
  }

  transformadorTransferencias(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        'descripcion': value[i].descripcion, 'cuenta':
          value[i].cuenta, 'detalle': value[i].detalle, identificacionBen: value[i].identificacionBen
        , nombreBen: value[i].nombreBen, bancoBen: value[i].bancoBen, cuentaBen: value[i].cuentaBen
        , monto: value[i].monto
      });
    }
    return lista;
  }

  seleccionarCuentaDesde() {
    this.monedaDesde = this.cuentaDesdeSelected.value.moneda;
    this.productoDesde = this.cuentaDesdeSelected.value.descProducto;
  }

  seleccionarCuentaHacia() {
    this.intentoBanred = this.cuentaHaciaSelected.value.codigoinstitucion2ordenante != null ? true : false
    if (this.cuentaHaciaSelected.value && this.cuentaHaciaSelected.value.efiInstFinan) {
      this.monedaHacia = this.cuentaHaciaSelected.value.moneda;
      this.productoHacia = this.cuentaHaciaSelected.value.descProducto;
      this.bancoBeneficiario = this.cuentaHaciaSelected.value.efiInstFinan + ' - ' + this.cuentaHaciaSelected.value.banco;
      this.bancoBeneficiarioN = this.cuentaHaciaSelected.value.banco;
      this.efiInstFinanBeneficiario = this.cuentaHaciaSelected.value.efiInstFinan;
      this.identificacionBeneficiario = this.cuentaHaciaSelected.value.identificacion;
      this.nombreBeneficiario = this.cuentaHaciaSelected.value.nombre;
    } else {
      this.monedaHacia = '';
      this.productoHacia = '';
      this.bancoBeneficiario = '';
      this.bancoBeneficiarioN = '';
      this.efiInstFinanBeneficiario = '';
      this.identificacionBeneficiario = '';
      this.nombreBeneficiario = '';
    }
  }

  seleccionarTransferencia() {
    this.closeSinUltima();
    this.cuentaDesdeSelected.setValue(this.cuentas.find(item => {
      return item.viewValue === this.transferenciaSelected.value.cuenta;
    }));
    this.cuentaHaciaSelected.setValue(this.cuentasBeneficiarios.find(item => {
      return item.viewValue === this.transferenciaSelected.value.cuentaBen;
    }));
    const x = /X/gi;
    const monto = this.transferenciaSelected.value.monto.replace(x, '');
    this.montoTransfer.setValue(monto);
    this.montoTransfer.setValue(Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(Number(monto)));
    this.seleccionarCuentaDesde();
    this.seleccionarCuentaHacia();
    this.referencia.setValue(this.transferenciaSelected.value.detalle);
  }

  solicitarToken() {
    const envio = { transaccion: '186071' };
    this.intento = true;
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.basedatos.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.token.reset();
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  enviarTransferencia(isValidation) {
    this.numeroMensaje = "";
    this.fechaTransaccion = "";
    this.numeroReferenciaSPI = "";
    this.CodigoBackend = "";
    const envio = {
      cuenta: this.cuentaDesdeSelected.value.value,
      cuentaView: this.cuentaDesdeSelected.value.viewValue,
      cuentaBe: this.cuentaHaciaSelected.value.value,
      cuentaBeView: this.cuentaHaciaSelected.value.viewValue,
      clave: this.servicios.toAES(!this.pinValidation ? this.token.value : "000000"),
      desencriptar: '1',
      moneda: this.monedaDesde,
      personBank: this.cuentaHaciaSelected.value.cpersonaBanco,
      beneficiario: this.nombreBeneficiario, banco: this.bancoBeneficiario, bancoN: this.bancoBeneficiarioN,
      identificacionBeneficiario: this.identificacionBeneficiario,
      monto: this.montoTransfer.value.replace("$", '').replace(/,/g, ''),
      cuentaOrigen: this.cuentaDesdeSelected.value,
      cuentaDestino: this.cuentaHaciaSelected.value,
      referencia: this.referencia.value.toUpperCase(),
      comision: this.comision,
      tarCredito: '0',
      ProdDeb: this.cuentaDesdeSelected.value.prodIdTipoOri,
      MonedaDeb: this.cuentaDesdeSelected.value.moneda,
      ProdCred: this.cuentaHaciaSelected.value.prodIdTipoDest,
      MonedaCred: this.cuentaHaciaSelected.value.moneda,
      RutaTranDest: this.efiInstFinanBeneficiario,
      'tarPendType': 'transferCtasOtr',
      isMobile: this.bandMobile ? "1" : "0",
      isValidation: isValidation
    };
    envio['spiTransfer'] = this.intentoBanred ? '0' : '1';
    if (isValidation == '1') {
      this.alerta.generarDialogoConfirmacionTransferencias(this.idiomas.TransferenciasAOtros, 'TransferenciasAOtros', this.idiomas, envio).then((data) => {
        if (data) {
          this.apiService.postProvider2InternetCheck('/otrainstitucion', this.basedatos.id_token, envio).then((dataTr: any) => {
            this.disableFormControls();
            this.intento = false;
            this.CodigoBackend = dataTr.CodigoBackend;
            this.numeroReferenciaSPI = dataTr.referenciaSPI;
            this.fechaTransaccion = dataTr.fechaTransaccion;
            this.numeroMensaje = dataTr.numeroMensaje;
            this.dowloadReporte = true;
            this.enableCronoIn = false;
            this.mostrarCron = false;
            this.alerta.generarDialogoSegurotransaccionesBanred(this.idiomas, this.numeroMensaje, true, 3, this.intentoBanred)
          }, (err) => {
            this.intento = this.bandMobile && this.pinValidation ? true : false;
            this.disableButton = this.pinValidation ? true : false;
            this.dowloadReporte = false;
            this.enableCronoIn = false;
            this.mostrarCron = false;
            if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
              if (err.error) {
                if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
                else if (err.error.mensaje == "MENSAJE PENDIENTE DE AUTORIZACION POR SOFT TOKEN ") {
                  if (this.pinValidation) {
                    let user = {
                      userCode: this.basedatos.usuarioHB
                    }
                    this.alerta.generarDialogoSolicitarAutorizacionData(this.idiomas, this.grande, this.mediano, user, "").then((data: any) => {
                      if (data) {
                        this.pinValid = true
                        this.disableButton = false
                        if (this.bandMobile) {
                          this.enviarTransferencia('0')
                        }
                      } else {
                        this.intento = false
                        this.pinValid = false
                        this.disableButton = false
                      }
                    }, (err) => {
                      this.intento = false
                    });
                  }
                } else {
                  if (err.error.errorCode.toString().startsWith("PD")) {
                    this.alerta.generarDialogoSeguroErrortransaccionesBanred(this.idiomas, err.error.mensajeUsuario, true).then((data) => {
                      if (data) {
                        this.intentoBanred = false;
                      }
                    });
                  } else {
                    this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario)
                  }
                }
              } else {
                this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError);
              }
            } else { // Sin Internet
              this.disableFormControls();
              this.dowloadReporte = false;
              this.basedatos.insertarTarea(envio);
              this.alerta.presentarAlerta(this.idiomas.NoConectado);
            }
          });
        } else {
          this.intento = false;
          this.dowloadReporte = false;
          this.disableButton = false;
        }
      });
    } else {
      envio.isValidation = isValidation
      this.apiService.postProvider2InternetCheck('/otrainstitucion', this.basedatos.id_token, envio).then((dataTr: any) => {
        this.disableFormControls();
        this.intento = false;
        this.CodigoBackend = dataTr.CodigoBackend;
        this.fechaTransaccion = dataTr.fechaTransaccion;
        this.numeroReferenciaSPI = dataTr.referenciaSPI
        this.numeroMensaje = dataTr.numeroMensaje;
        this.dowloadReporte = true;
        this.enableCronoIn = false;
        this.mostrarCron = false;
        this.alerta.generarDialogoSegurotransaccionesBanred(this.idiomas, this.numeroMensaje, true, 3, this.intentoBanred)
      }, (err) => {
        this.intento = false;
        this.disableButton = false;
        this.dowloadReporte = false;
        this.enableCronoIn = false;
        this.mostrarCron = false;
        if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
          if (err.error) {
            if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
            else {
              if (err.error.errorCode.toString().startsWith("PD")) {
                this.alerta.generarDialogoSeguroErrortransaccionesBanred(this.idiomas, err.error.mensajeUsuario, true).then((data) => {
                  if (data) {
                    this.intentoBanred = false;
                  }
                });
              } else {
                this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario)
              }
            }
          } else {
            this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError);
          }
        } else { // Sin Internet
          this.disableFormControls();
          this.dowloadReporte = false;
          this.basedatos.insertarTarea(envio);
          this.alerta.presentarAlerta(this.idiomas.NoConectado);
        }
      });
    }
  }

  solicitarAutorizacion() {
    this.obtenerComision('1')
  }

  disableFormControls() {
    this.bandDisableForm = true;
    this.cuentaDesdeSelected.disable();
    this.montoTransfer.disable();
    this.cuentaHaciaSelected.disable();
    this.referencia.disable();
    this.token.disable();
  }

  obtenerComision(isValidation) {
    this.intento = true;
    this.disableButton = true;
    const enviocom = {
      transaccion: "010041-lv-comision-txotras-in.xml",
      alias: "tcrp1",
      "cri^tcrp1^PARAMETRO14^NORMAL": this.cuentaDesdeSelected.value.viewValue,
      "cri^tcrp1^PARAMETRO16^NORMAL": this.montoTransfer.value.replace("$", '').replace(/,/g, '')
    };
    enviocom['cri^tcrp1^PARAMETRO11^NORMAL'] = this.intentoBanred ? '6179' : '2080'
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviocom).then((data: any) => {
      if (data) {
        if (data.length > 0) {
          this.comision = data[0].PARAMETRO1;
          if (this.comision == "null" || this.comision == "0") {
            this.comision = "0.00";
          }
        } else {
          this.comision = "0.00";
        }
      } else {
        this.comision = "0.00";
      }
      this.enviarTransferencia(isValidation);
    }, (err) => {
      this.intento = false;
      this.disableButton = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(err.error.mensajeUsuario);
        }
      }
      else { //Sin Internet 
        this.enviarTransferencia('0');
      }
    });
  }

  close() {
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.dowloadReporte = false;
    this.bandDisableForm = false;
    this.tokenSolicitado = false;
    this.disableButton = false;
    this.pinValid = false;
    this.transferenciaSelected.setValue('');
    this.cuentaDesdeSelected.setValue('');
    this.montoTransfer.setValue('');
    this.cuentaHaciaSelected.setValue('');
    this.referencia.setValue('');
    this.token.setValue('');
    this.seleccionarCuentaDesde();
    this.seleccionarCuentaHacia();
    this.referencia.reset();
    this.cuentaDesdeSelected.reset();
    this.cuentaHaciaSelected.reset();
    this.montoTransfer.reset();
    this.token.reset();
    this.referencia.enable();
    this.cuentaDesdeSelected.enable();
    this.cuentaHaciaSelected.enable();
    this.montoTransfer.enable();
    this.token.enable();
    this.intentoReenviar = false;
  }

  closeSinUltima() {
    this.cuentaDesdeSelected.setValue('');
    this.montoTransfer.setValue('');
    this.cuentaHaciaSelected.setValue('');
    this.referencia.setValue('');
    this.token.setValue('');
    this.seleccionarCuentaDesde();
    this.seleccionarCuentaHacia();
    this.dowloadReporte = false;
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.intentoReenviar = false;
  }

  compareJsonPerValue(object1: any, object2: any) {
    return object1 && object2 && ((object1.value == object2.value) || (object1.viewValue.slice(-4) == object2.toString().slice(-4)));
  }

  nuevoBeneficiario() {
    this.alerta.generarDialogoConMensajeDeIdiomas(this.idiomas, "LoseInfo").then((data) => {
      if (data) {
        this.regBenefJson = { tarPendType: null, otrasInstituciones: true };
        this.bandNewBeneficiary = true;
      }
    });
  }

  retornar() {
    this.close();
    this.regBenefJson = { tarPendType: null, otrasInstituciones: true };
    this.bandNewBeneficiary = false;
    this.intentoReenviar = false;
    this.obtainBeneficiaryAccounts(true);
  }

  selectedOptionOrigen(cta) {
    if (this.dataTarPend && this.dataTarPend.resender) {
      this.dataTarPend.cuentaOrigen = cta;
    }
  }

  selectedOptionDestino(cta) {
    if (this.dataTarPend && this.dataTarPend.resender) {
      this.dataTarPend.cuentaDestino = cta;
      this.dataTarPend.resender = false;
      Promise.resolve(null).then(() => this.setValuesOfForm());
    }
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  comaToDot(event) {
    if (event.key === ',') {
      event.target.value += '.';
      event.preventDefault();
    }
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace("$", '').replace(/\,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0];
          let decimalPart = amountArrayDotSplit[1];
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }
}