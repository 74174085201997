import { CommonModule, UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-preguntas-frecuentes',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatTabsModule, MatExpansionModule, MatMenuModule, MatToolbarModule, MatSidenavModule, MatExpansionModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrl: './preguntas-frecuentes.component.css'
})
export class PreguntasFrecuentesComponent implements OnInit {

  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  intento: boolean = true;
  bandMobile: boolean;

  constructor() {
    this.bandMobile = false;
  }

  ngOnInit() {
    this.bandMobile = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  async ngAfterViewInit() {
    Promise.resolve(null).then(async () => {
      this.intento = false
    });
  }

  mouseEnterHandler(event: MouseEvent, chapterExpansionPanel: MatExpansionPanel) {
    if (event.buttons && !chapterExpansionPanel.expanded) {
      chapterExpansionPanel.open();
    }
  }

  chapters = [
    {
      id: 1,
      name: '¿Cómo puedo cambiar mi clave?',
      items: [
        {
          id: 1,
          name: 'Ingresa a tu coop virtual y dirigete a administración de cuenta, allí podrás modificar tu clave de acceso.'
        }
      ]
    },
    {
      id: 2,
      name: 'Se me bloqueo mi usuario, cómo activarlo',
      items: [
        {
          id: 2,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos y selecciona la opción 1️⃣ Reestablecer clave'
        }
      ]
    },
    {
      id: 3,
      name: 'No recuerdo mi usuario de Coop virtual',
      items: [
        {
          id: 3,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos y selecciona la opción 3️⃣ Recordar usuario'
        }
      ]
    },
    {
      id: 4,
      name: 'Quiero recibir asesoria personalizada para activar mi usuario',
      items: [
        {
          id: 4,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos y selecciona la opción 4️⃣ Comunicarse con un asesor'
        }
      ]
    },
    {
      id: 5,
      name: 'Quiero cambiar mi correo electrónico y número de teléfono',
      items: [
        {
          id: 5,
          name: 'Para mayor seguridad es recomendable acercarse a la agencia PJL más cercana.'
        }
      ]
    },
    {
      id: 6,
      name: '¿Cuál es la dirección de sus agencias?',
      items: [
        {
          id: 10,
          name: 'Ingresa en https://lorente.fin.ec/agencias/  y identifica la agencia más cercana.'
        }
      ]
    },
    {
      id: 7,
      name: 'Mi usuario se bloqueo totalmente , cómo lo reactivo',
      items: [
        {
          id: 7,
          name: 'Acércate a la agencia más cercana y te ayudarán con el desbloqueo.'
        }
      ]
    },
    {
      id: 8,
      name: '¿Tienes problemas con Coop virtual PJL?',
      items: [
        {
          id: 8,
          name: 'Contáctate con nuestro asesor digital en Whatsapp: 098 743 6070 Facebook @coopadrejulian o en nuetsra página web valida tus datos de 8:30 a 18:00 y te ayudarán con asesoría'
        }
      ]
    }
  ];
}
