<div class="textura" [class.mediano]=normal [class.mediano]=mediano [class.grande]=grande>
  <h2 mat-dialog-title>{{idioma.TituloDialogos}}</h2>

  <mat-dialog-content *ngIf="!imagen">
    <p [innerHTML]='mensaje'></p>
  </mat-dialog-content>

  <p *ngIf="imagen" [innerHTML]='mensaje'></p>
  <mat-dialog-content *ngIf="imagen" style="height: 100%;" class="dialogContent">
    <mat-progress-bar *ngIf="loadContract" mode="indeterminate"></mat-progress-bar>
    <iframe *ngIf="!bandMobile" #pdfMostrar (load)="contractLoaded()"
      src="assets/resources/reporte/CONTRATO_JULIANLORENTE_EN_LINEA.pdf" user-scalable="yes" type="application/pdf"
      frameborder="0" width="100%" height="95%">
    </iframe>
    <ngx-extended-pdf-viewer *ngIf="bandMobile" #pdfViewer
      [src]="'/assets/resources/reporte/CONTRATO_JULIANLORENTE_EN_LINEA.pdf'" backgroundColor="#ffffff"
      [textLayer]="true" [handTool]=false [useBrowserLocale]="true" [customToolbar]="additionalButtons">
    </ngx-extended-pdf-viewer>
    <ng-template #additionalButtons>
      <div id="toolbarViewer">
        <pdf-print></pdf-print>
        <app-open-in-new-tab></app-open-in-new-tab>
        <pdf-download></pdf-download>
      </div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!imagen">
    <div style="width: 100% !important; text-align: center;">
      <button mat-raised-button class="buttonToken" (click)="close()">
        <mat-icon> cancel_presentation</mat-icon>
        {{rechazar}}
      </button>
      <button mat-raised-button class="buttonToken" (click)="accept()">
        <mat-icon>download_done</mat-icon>{{aceptar}}
      </button>
    </div>
  </mat-dialog-actions>

  <div *ngIf=intento>
    <br>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="information">
    <br>
    <app-alertas-login [information]="information" [mensaje]="msjinformation.toUpperCase()"></app-alertas-login>
  </div>

  <div *ngIf="error">
    <br>
    <app-alertas-login [error]="error" [mensaje]="msjerror.toUpperCase()"></app-alertas-login>
  </div>

  <div *ngIf="imagen && !bandContract" class="form-row" style="margin: auto; margin-top: 5px;">
    <label class="col-2" style="max-width: 70px;margin-left: auto">{{aceptar}}</label>
    <mat-checkbox class="col-1" [checked]='checkaceptar' [value]="checkaceptar" [disabled]="loadContract"
      style="max-width: 70px;margin-right: auto;padding-top: 5px" (change)="checkaceptar=!checkaceptar" color="primary">
    </mat-checkbox>
  </div>

  <mat-dialog-actions *ngIf="imagen && !bandContract">
    <div style="width: 100% !important; text-align: center;">
      <button mat-raised-button class="buttonToken" (click)="close()">
        <mat-icon> cancel_presentation</mat-icon>
        {{rechazar}}
      </button>
      <button mat-raised-button class="buttonToken" (click)="sendAceptanceContract()" [disabled]="!checkaceptar"
        color="primary"><mat-icon>done</mat-icon>
        {{idioma.Enviar}}</button>
    </div>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="imagen && bandContract" style="padding-top: 0px">
    <div style="width: 100% !important; text-align: right;">
      <button mat-raised-button class="buttonToken" (click)="aceptanceMessageContract()">
        <mat-icon>done</mat-icon>{{aceptar}}
      </button>
    </div>
  </mat-dialog-actions>
</div>