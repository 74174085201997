import { Component, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { FormControlValidators } from '../funciones/formcontrol';
import { internetComponent } from '../funciones/internet';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { Alerta } from '../funciones/alerta';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Servicios } from '../funciones/encryptar';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TemporizadorComponent } from '../temporizador/temporizador.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatInputModule, MatTableModule, MatListModule, MatDividerModule, MatExpansionModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-montos-transferencias',
  templateUrl: './montos-transferencias.component.html',
  styleUrls: ['./montos-transferencias.component.css']
})
export class MontosTransferenciasComponent implements OnInit {

  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  bandCheck: boolean;
  flechaR: boolean;
  flechaL: boolean;
  paraScroll: any;
  topOfTable: any;
  container: boolean;
  band: boolean = true;
  //Variable para el temporizador
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  intento: boolean;
  intentop: boolean;
  intento2: boolean;

  //Variable para poblar información del usuario y poder consultar montos máximos
  enviomontos = {
    cidioma: '',
    ccanal: 'WEB',
    ctransaccion: '',
    csubsistema: '',
    cpersona: this.base.cpersona
  }
  canal: string = 'WEB'     //se envía por defecto el canal :D
  nombreonl: string; OJO
  transSeleccionada: any;   //variable para definir transaccion seleccionada
  token = this.validators.otpForm()    //tomar token
  showTable: boolean = false;
  transaccionForm = this.validators.comboForm();
  ttransaccionForm = this.validators.comboForm();
  monto_aut = new UntypedFormGroup({
    '0': this.validators.decimalsForm(),
    '1': this.validators.decimalsForm(),
    '2': this.validators.decimalsForm(),
  });
  datos2: any[];
  displayedColumns = ['Cupo', 'Moneda', 'Cupo Máximo', 'Cupo Autorizado']
  lttransaccion: any[] = [];
  ttransacion: string = '';
  ltransaccion: any[] = [];
  lmontoCompañia: any[];
  lmontoPersona: any[];
  lmontos: any[];
  habilitarltransaccion = false;
  tokenrecibido: boolean;
  bandMobile: boolean;
  numMaxRow: number;
  disableButton: boolean;

  constructor(private api: ApiService, private base: BaseDatos, private validators: FormControlValidators,
    public internet: internetComponent, public alerta: Alerta, private services: Servicios) {
    this.bandCheck = true;
    this.intento = false;
    this.datos2 = []
    this.showTable = false;
    this.disableButton = false;
    this.transSeleccionada = '';
    this.flechaR = false;
    this.flechaL = true;
    this.paraScroll = 0;
    this.topOfTable = 0;
    this.container = false;
    this.tokenrecibido = false;
    this.intentop = false;
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.bandMobile = false;
    this.numMaxRow = 3;
  }

  ngOnInit() {
    this.bandMobile = false;
    this.intento = false;
    this.nombreonl = this.base.nombreUsuario
    this.poblarListaTiposTransaccion()
    this.consultarMontos()
    for (var i = 0; i > 3; i++) {
      this.monto_aut[i] = this.validators.decimalsForm();
    }
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.bandMobile = isMobile()
  }

  ngOnChanges(changes: SimpleChanges) {
    const cambioIdiomas: SimpleChange = changes.idiomas;
    if (cambioIdiomas.previousValue != cambioIdiomas.currentValue && cambioIdiomas.previousValue != undefined) {
      this.intento = false;
      this.poblarListaTransaccion()
      this.poblarListaTiposTransaccion()
      this.showTable = false;
      this.datos2 = []
      this.close() //Para resetear formulario si se cambia de de idioma
    }
  }

  close() {
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.showTable = false;
    this.disableButton = false;
    this.token.setValue(null);
    this.intentop = false;
    this.token.reset();
    this.transaccionForm.reset();
    this.ttransaccionForm.reset();
    this.habilitarltransaccion = false;
  }

  scroll4(id) {
    if (id == 'tablaMontos') {
      var container1 = document.getElementsByClassName('container1Aux')
    }
    var tabla = document.getElementsByClassName(id)
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 1 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  scrollToTab(band) {

    if (band && this.paraScroll < 1) {
      scroll(0, this.topOfTable - document.getElementById("tool-bar").getBoundingClientRect().height);
      this.paraScroll = this.paraScroll + 1;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase)
    if (clase == 'tablaMontos') {
      let container1 = document.getElementsByClassName('container1Aux')
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth
      } else {
        container1.item(0).scrollLeft = 0
        this.flechaL = true
        this.flechaR = false
      }
    }
  }

  showHideRows4(last) {
    if (last) {
      try {
        let classContainer1Width = document.getElementsByClassName('container1Aux')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('tablaMontos')[0].setAttribute('style', 'width: 695px')
          if (695 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaMontos')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('tablaMontos')[0].setAttribute('style', 'width: 582px')
          if (582 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')

            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaMontos')[0].setAttribute('style', 'width: 100%')
          }
        }
        if (this.normal) {
          document.getElementsByClassName('tablaMontos')[0].setAttribute('style', 'width: 451px; font-size: 14px')
          if (451 - classContainer1Width > 0) {
            document.getElementById('flechaR').setAttribute('style', 'visibility: visible;')
            if (!this.flechaL) {
              document.getElementById('flechaL').setAttribute('style', 'visibility: visible;')
            }
          } else {
            document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;')
            document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;')
            document.getElementsByClassName('tablaMontos')[0].setAttribute('style', 'width: 100%; font-size: 14px')
          }
        }
      } catch (e) {
      }
    }

  }

  functionAux(aux, i) {
    if (aux == undefined) {
      aux = this.datos2[i].montomaximo_user
    }
    aux = aux.replace(',', '')
    this.datos2[i].cupoAut = new CantidadMonetaria().transform(aux)
    this.datos2[i].monto_actualizado = aux
  }

  functionFormat(value: any, i) {
    if (i == 0) {
      this.monto_aut.patchValue({ '0': this.validators.FormatNumber(value) })
    } else if (i == 1) {
      this.monto_aut.patchValue({ '1': this.validators.FormatNumber(value) })
    } else {
      this.monto_aut.patchValue({ '2': this.validators.FormatNumber(value) })
    }
  }

  VerificarMonto(aux, i) {
    try {
      let variable = Number(aux.replace(',', ''))
      let variablesistema = Number(this.datos2[i].montomaximo_sistema)

      if (variable > variablesistema) {
        this.alerta.presentarAlertaX(this.idiomas.MontoMaximoExedido.toUpperCase() + " " + this.datos2[i].frecuencia_descripcion.toUpperCase() + ' ' + aux)
      }
    } catch (e) {
    }
  }

  // Lista Valores tipo transaccion  /listaTipoTransaccion
  poblarListaTiposTransaccion() {
    this.showTable = false;
    this.datos2 = []
    let auxi: any = {}
    this.lttransaccion = []
    let idiomaactual = localStorage.getItem('locale').toUpperCase()
    this.api.noTokenProviderInternetCheck({}, '/listaTipoTransaccion').then((data: any) => {
      for (const tipoTransaccion of data) {
        auxi.grupotransaccion = tipoTransaccion.grupotransaccion
        auxi.descripcion = this.idiomas.tipoTransaccionL.find(item => item.codTx == tipoTransaccion.grupotransaccion).viewValue;
        auxi.cidioma = tipoTransaccion.cidioma
        this.lttransaccion.push(auxi)
        auxi = {}
      }
      this.base.insertarConsulta('ltrans', this.lttransaccion)
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.lttransaccion = this.base.retornarConsulta('ltrans')
      }
    });
  }

  valorTransaccion(valor) {
    for (const iterator of this.ltransaccion) {
      if (valor == iterator.codigotransaccion) {
        this.enviomontos.ctransaccion = iterator.codigotransaccion
        this.enviomontos.csubsistema = iterator.codigsubsistema
        break;
      }
    }
  }

  valorTipo(valor) {
    for (const iterator of this.lttransaccion) {
      if (valor == iterator.grupotransaccion) {
        this.ttransacion = valor
        this.habilitarltransaccion = true;
        break;
      } else {
        this.habilitarltransaccion = false;
      }
    }
    this.poblarListaTransaccion()
  }

  /// Lista Valores Transacciones
  poblarListaTransaccion() {
    let auxi: any = {}
    let idiomaactual = localStorage.getItem('locale').toUpperCase()
    this.ltransaccion = []
    this.showTable = false
    this.datos2 = []
    this.api.noTokenProviderInternetCheck({}, '/listaTransacciones').then((data: any) => {
      this.base.insertarConsulta('lttransaccion', data)
      for (const transacciones of data) {
        if (this.ttransacion == transacciones.codigogrupotransaccion && transacciones.cidioma == "ES") {
          if (transacciones.codigogrupotransaccion == "TRF") {
            auxi.descripcion = this.idiomas.TransaccionesWeb.find(item => item.codTransf == transacciones.codigotransaccion).viewValue;
          } else {
            auxi.descripcion = this.idiomas.PagoServicios.find(item => item.codServ == transacciones.codigotransaccion).viewValue;
          }
          auxi.codigotransaccion = transacciones.codigotransaccion
          auxi.codigsubsistema = transacciones.codigsubsistema
          auxi.cidioma = transacciones.cidioma
          auxi.codigogrupotransaccion = transacciones.codigogrupotransaccion
          this.ltransaccion.push(auxi)
          auxi = {}
        }
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.ltransaccion = [];
        let temp = this.base.retornarConsulta('lttransaccion')
        for (const transacciones of temp) {
          if (this.ttransacion == transacciones.codigogrupotransaccion && idiomaactual == transacciones.cidioma) {
            auxi.codigotransaccion = transacciones.codigotransaccion
            auxi.descripcion = transacciones.descripcion
            auxi.codigsubsistema = transacciones.codigsubsistema
            auxi.cidioma = transacciones.cidioma
            auxi.codigogrupotransaccion = transacciones.codigogrupotransaccion

            this.ltransaccion.push(auxi)
            auxi = {}
          }
        }
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  //// MontosMáximo
  consultarMontos() {
    this.intento = true;
    this.datos2 = []
    this.showTable = false;
    this.enviomontos.cidioma = localStorage.getItem('locale').toUpperCase();
    let persona = this.base.cpersona
    let ctrans = this.enviomontos.ctransaccion
    let enviomontosuser = {
      transaccion: '183028-MontosMaximos-con-in.xml',
      fullNames: true,
      'cri^tpmmt1^CPERSONA^JOIN^tpmmt0^=': "$personCode",
      'cri^tpmmt0^CTRANSACCION^NORMAL': this.ttransaccionForm.value
    }
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, enviomontosuser).then((data: any) => {
      let aux: IConsultaMontos = {
        ccanal: '',
        csubsistema: '',
        ctransaccion: '',
        subsistema_descripcion: '',
        cfrecuencia: '',
        frecuencia_descripcion: '',
        cmoneda: '',
        cpersona_compania: '',
        cpersona_sistema: '',
        montomaximo_sistema: '',
        cpersona_user: '',
        montomaximo_user: '',
        monto_especial_user: '',
        montomaximo_especial_user: '',
        version_control: ''
      }
      let auxiliar = data.tpmmt0
      let indexAuxiliar = 0
      for (const iterator of auxiliar) {
        aux.ccanal = iterator['tpmmt0.CCANAL']
        aux.csubsistema = iterator['tpmmt0.CFRECUENCIA']
        aux.ctransaccion = iterator['tpmmt0.CTRANSACCION']
        aux.subsistema_descripcion = iterator['TSUBSISTEMATRANSACCIONES.DESCRIPCION']
        aux.cfrecuencia = iterator['tpmmt0.CFRECUENCIA']
        aux.frecuencia_descripcion = iterator['tpmmt0.CFRECUENCIA'] != "0" ? iterator['TFRECUENCIAS.DESCRIPCION'] : "POR TRANSACCIÓN"
        aux.cmoneda = iterator['tpmmt0.CMONEDA']
        aux.cpersona_compania = iterator['tpmmt0.CPERSONA_COMPANIA']
        aux.cpersona_sistema = iterator['tpmmt0.CPERSONA']
        aux.montomaximo_sistema = iterator['tpmmt0.MONTOMAXIMO']
        aux.montomaximo_user = iterator['tpmmt1.MONTOMAXIMO']
        aux.monto_especial_user = iterator['tpmmt1.MONTOESPECIAL']
        aux.montomaximo_especial_user = iterator['tpmmt1.MONTOMAXIMOESPECIAL']
        aux.cpersona_user = iterator['tpmmt1.CPERSONA']
        aux.version_control = iterator['tpmmt1.VERSIONCONTROL']
        if (aux.montomaximo_user == "null") { //Si es nulo da valor 0
          aux.montomaximo_user = aux.montomaximo_sistema
        }
        if (aux.version_control == "null") { //Si es nulo da valor 1
          aux.version_control = '1'
        }
        if (aux.cpersona_user == "null") { //Si es nulo da valor de campo de control
          aux.cpersona_user = data.ctl.CPERSONA
        }
        //Se verifica si usuario tiene definidos montos especiales
        if (aux.monto_especial_user == "1") {
          aux.montomaximo_user = aux.montomaximo_especial_user == "null" ? '0' : aux.montomaximo_especial_user;
          this.monto_aut.get(indexAuxiliar.toString()).disable();
        }
        else {
          this.monto_aut.get(indexAuxiliar.toString()).enable();
        }
        this.functionFormat(aux.montomaximo_user, indexAuxiliar)
        this.datos2.push(aux)
        aux = {
          ccanal: '',
          csubsistema: '',
          ctransaccion: '',
          subsistema_descripcion: '',
          cfrecuencia: '',
          frecuencia_descripcion: '',
          cmoneda: '',
          cpersona_compania: '',
          cpersona_sistema: '',
          montomaximo_sistema: '',
          cpersona_user: '',
          montomaximo_user: '',
          monto_especial_user: '',
          montomaximo_especial_user: '',
          version_control: ''
        }
        indexAuxiliar += 1;
      }
      //Almacenar Consulta
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': this.datos2,
      }
      this.base.insertarConsulta('MontosRegistrados' + this.enviomontos.ctransaccion, consulta); //Almacena en BDD los servicios registrados
      this.showTable = true;
      this.intento = false;
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.intento = false
        this.bandCheck = true;
      }
      else { //Sin Internet
        this.intento = false
        this.band = true;
        this.montosRegOffline()
      }
    });
  }

  montosRegOffline() {
    let ObjConsulta = this.base.retornarConsulta('MontosRegistrados' + this.enviomontos.ctransaccion);
    if (!ObjConsulta || ObjConsulta.data.length == 0) {
      this.band = false;
    }
    else {
      this.datos2 = []
      this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas)
      this.datos2 = ObjConsulta.data
      this.showTable = true;
      this.intento = false
    }
  }

  SolicitarToken() {
    this.intentop = false
    this.intento2 = true
    const envio = { transaccion: '183028' };
    this.api.postProvider2InternetCheck('/clavetemporal', this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.intentop = true
        this.intento2 = false
        this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
      }
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intentop = false
      this.intento2 = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.bandCheck = true;
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  actualizarMontos() {
    this.intento2 = true;
    this.disableButton = true;
    let auxenvio: any = {}
    let envioarray: any[] = []
    let envio: any = {}
    let huboerror: boolean = false
    for (const iterator of this.datos2) {
      if (iterator.monto_actualizado != undefined && iterator.monto_actualizado != null && iterator.monto_actualizado != ""
        && Number(iterator.monto_actualizado) != Number(iterator.montomaximo_user) && Number(iterator.monto_actualizado) <= Number(iterator.montomaximo_sistema)) {
        auxenvio.csubsistema = iterator.csubsistema,
          auxenvio.ctransaccion = iterator.ctransaccion,
          auxenvio.cpersona_user = iterator.cpersona_user,
          auxenvio.cpersona_compania = iterator.cpersona_compania,
          auxenvio.cfrecuencia = iterator.cfrecuencia,
          auxenvio.dfrecuencia = iterator.frecuencia_descripcion,
          auxenvio.cmoneda = iterator.cmoneda,
          auxenvio.ccanal = iterator.ccanal,
          auxenvio.montomaximo_user = iterator.montomaximo_user,
          auxenvio.montoactualizado = iterator.monto_actualizado,
          auxenvio.version_control = iterator.version_control
        envioarray.push(auxenvio)
        auxenvio = {}
      } else {
        auxenvio = {}
        huboerror = true;
      }
    }
    if (envioarray.length > 0) {
      let cont = 0
      let envio = {
        transaccion: '183028-man-in.xml',
        "ctl^TRANSACTIONAL_PASSWORD": "$password(" + this.services.toAES(this.token.value) + ")",
        "ctl^CUSUARIO": "$userCode",
        "ctl^CHANGEAMOUNT": "1",
        "desencriptar": '1'
      }
      for (const iterator of envioarray) {
        envio['rec^tpmmt1^' + cont + '^CTRANSACCION^tpmmt1'] = iterator.ctransaccion
        envio['rec^tpmmt1^' + cont + '^CPERSONA^tpmmt1'] = iterator.cpersona_user
        envio['rec^tpmmt1^' + cont + '^CPERSONA_COMPANIA^tpmmt1'] = iterator.cpersona_compania
        envio['rec^tpmmt1^' + cont + '^CFRECUENCIA^tpmmt1'] = iterator.cfrecuencia
        envio['rec^tpmmt1^' + cont + '^CMONEDA^tpmmt1'] = iterator.cmoneda
        envio['rec^tpmmt1^' + cont + '^MONTOMAXIMO^tpmmt1^VAL'] = iterator.montoactualizado
        envio['rec^tpmmt1^' + cont + '^MONTOMAXIMO^tpmmt1^oldval'] = iterator.montomaximo_user
        envio['rec^tpmmt1^' + cont + '^VERSIONCONTROL^tpmmt1'] = "$vc"
        cont = cont + 1
      }
      this.api.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
        this.showTable = false;
        this.intento2 = false;
        this.alerta.presentarAlerta(this.idiomas.MontosActualizados.toUpperCase())
        this.token.reset();
        this.consultarMontos();
        this.mostrarCron = false;
        this.enableCronoIn = false;
        this.disableButton = false;
      }, (err) => {
        this.showTable = true;
        this.intento2 = false;
        this.mostrarCron = false;
        this.enableCronoIn = false;
        this.disableButton = false;
        if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
          envio = {
            transaccion: '',
            "ctl^TRANSACTIONAL_PASSWORD": '',
            "ctl^CUSUARIO": '',
            "ctl^CHANGEAMOUNT": '',
            "desencriptar": ''
          }
          if (err.error) {
            if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
            else {
              this.alerta.presentarAlertaX(err.error.mensajeUsuario)
            }
          }
          else {
            this.alerta.presentarAlerta(this.idiomas.ServidorError)
          }
          this.bandCheck = true;
        }
        else { //Sin Internet
        }
      });
      envio = {
        transaccion: '',
        "ctl^TRANSACTIONAL_PASSWORD": '',
        "ctl^CUSUARIO": '',
        "ctl^CHANGEAMOUNT": '',
        "desencriptar": ''
      }
    } else {
      this.showTable = true;
      this.intento2 = false;
      this.alerta.presentarAlertaX(this.idiomas.NoMontosActualizados.toUpperCase())
    }
  }
}

interface IConsultaMontos {
  ccanal: string,
  csubsistema: string,
  ctransaccion: string,
  subsistema_descripcion: string,
  cfrecuencia: string,
  frecuencia_descripcion: string,
  cmoneda: string,
  cpersona_compania: string,
  cpersona_sistema: string,
  montomaximo_sistema: string,
  cpersona_user: string,
  montomaximo_user: string,
  monto_especial_user: string,
  montomaximo_especial_user: string,
  version_control: string
}