import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormControlValidators } from '../funciones/formcontrol';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatButtonModule, MatRadioModule, MatInputModule, MatExpansionModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  selector: 'app-dialogo-administracion-productos',
  templateUrl: './dialogo-administracion-productos.component.html',
  styleUrls: ['./dialogo-administracion-productos.component.css']
})
export class DialogoAdministracionProductosComponent implements OnInit {
  er: boolean = false;
  idioma: any;
  descripcion: string;
  nombre: any
  precio: any
  cantidad: any
  bandCompra: any;
  urls: any[];
  adm: any;
  categoria: any
  difiere: any
  DETALLECARAC_1: any
  disponible: any
  DETALLECARAC_2: any
  VERSIONCONTROL: any
  cargaArchivo: boolean
  cargaProducto: boolean
  intento: boolean
  cproductoEcomm: any
  carac1: any
  carac2: any
  categoriasProductos: any[]
  imagenesProductos: any[]
  cctienda: any
  cont: any
  checkDis: boolean
  checkDis2: boolean
  checkdif: boolean
  checkdif2: boolean
  imagePath;
  imgURL: any;
  message: string;
  imgBD: any
  ccategoria: any
  cargaArchivoP: boolean
  cimagen: any
  imageOdvl: any
  intentoMessage: boolean
  intentoMessageError: boolean
  Message: any
  MessageError: any
  cargadoArchivo: boolean
  archivoNombre:any
  index: number;

  constructor
    (
      private dialogRef: MatDialogRef<DialogoAdministracionProductosComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private validators: FormControlValidators,
      public apiService: ApiService,
      public basedatos: BaseDatos,
      private _DomSanitizer: DomSanitizer
    ) {

    this.idioma = data.idioma
    this.adm = data.adm
    this.carac1 = data.carac1
    this.carac2 = data.carac2
    this.ccategoria = data.parametros['tcat0.CCATEGORIAECOMM']
    this.descripcion = data.parametros['tpro0.DESCRIPCION']
    this.nombre = data.parametros['tpro0.NOMBRE']
    this.cproductoEcomm = data.parametros['tpro0.CPRODUCTOECOMM']
    this.precio = data.parametros['tpro0.PRECIO']
    this.categoria = data.parametros['tpro0.DETALLE']
    this.DETALLECARAC_1 = data.parametros['tpro0.DETALLE1']
    this.DETALLECARAC_2 = data.parametros['tpro0.DETALLE2']
    this.difiere = data.parametros['tpro0.PERMITEDIFERIR']
    this.disponible = data.parametros['tpro0.DISPONIBLE']
    this.cctienda = data.ctienda
    this.urls = [];
    this.imagenesProductos = []
    this.cargaArchivo = false
    this.cargaProducto = false
    this.checkDis = false
    this.checkDis2 = false
    this.checkdif = false
    this.checkdif2 = false
    this.cargaArchivoP = false
    this.intentoMessage = false
    this.intentoMessageError = false
    this.cargadoArchivo = true
    this.cont = 0
    this.index = 0
  }

  ngOnInit() {
    if (this.adm == 1) {
      this.productoP.setValue(this.nombre)
      this.precioP.setValue(this.precio)
      this.caracteristicaP1.setValue(this.DETALLECARAC_1)
      this.caracteristicaP2.setValue(this.DETALLECARAC_2)
      this.descripcionP.setValue(this.descripcion)

      if (this.disponible == 1) {
        this.checkDis = true
      } else {
        this.checkDis2 = true
      }
      if (this.difiere == 1) {
        this.checkdif = true
      } else {
        this.checkdif2 = true
      }
    }
  }

  orden = this.validators.numberForm();
  categoriaFormP = this.validators.comboForm();
  productoP = this.validators.textNumberSpecialFeaturetForm()
  precioP = this.validators.decimalsForm()
  caracteristicaP1 = this.validators.textNumberForm()
  caracteristicaP2 = this.validators.textNumberForm()
  disponibilidadP = this.validators.numberForm()
  diferirP = this.validators.numberForm()
  descripcionP = this.validators.textNumberSpecialFeaturetForm()
  imagenFormP = this.validators.comboForm()

  close() {
    this.dialogRef.close(this.er);
  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.retornarCategoriasLV());
    Promise.resolve(null).then(() => this.retornarImagenes());
  }

  radioChange(e) {
    if (this.orden.value == '1') {
      this.cargaProducto = true
      this.cargaArchivo = false

    } else {
      this.cargaProducto = false
      this.cargaArchivo = true
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0] && this.cont < 3) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          if (this.cont < 3) {
            this.urls.push(event.target.result);
            this.cont++
          }
        }
        this.cargadoArchivo = false
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectFileArchive(event) {
    if (event.target.length === 0)
      return;

    var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.cargaArchivoP = true
      this.imgURL = reader.result;
      this.archivoNombre = event.target.files[0].name 
      this.cargadoArchivo = false
    }
  }

  onSelectFileEdit(event) {
    if (event.target.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.cargaArchivoP = true
      this.imgURL = reader.result;
    }
  }

  retornarCategoriasLV() {
    let auxi: ICategoriasP = {
      id: '',
      detalle: ''
    }
    this.intento = true
    let enviomonedas = {
      transaccion: '010003-lv-categoriasP.xml',
      alias: "tcat0",
      "cri^tcat0^CTIENDA^NORMAL": this.cctienda
    }
    var i = 0;
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviomonedas).then((data: any) => {
      for (const categoria of data) {
        auxi.id = categoria.CCATEGORIAECOMM
        auxi.detalle = categoria.DESCRIPCION
        if (categoria.CCATEGORIAECOMM) {
          this.categoriasProductos.push(auxi)
        }
        auxi = {
          id: '',
          detalle: ''
        }
        i = i + 1;
      }
      this.categoriaFormP.setValue(this.ccategoria)
      this.intento = true
    }, (err) => {
      this.intento = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }


  retornarImagenes() {
    this.categoriasProductos = []
    this.intento = true
    let auxi: IImagenesP = {
      id: '',
      detalle: ''
    }
    let enviomonedas = {
      transaccion: '183036-con-imagenes-productos.xml',
      alias: "tpro0",
      "cri^tpro0^CPRODUCTOECOMM^NORMAL": this.cproductoEcomm
    }
    var i = 0;
    this.apiService.postProvider2InternetCheck('/listadevalores', this.basedatos.id_token, enviomonedas).then((data: any) => {
      for (const categoria of data) {
        auxi.id = categoria.CIMAGEN
        auxi.detalle = categoria.IMAGEN
        if (categoria.CIMAGEN) {
          this.imagenesProductos.push(auxi)
        }
        auxi = {
          id: '',
          detalle: ''
        }
        i = i + 1;
      }
      this.intento = true
    }, (err) => {
      this.intento = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  derecha(i) {
    var length = this.imagenesProductos.length
    var auxin = this.index + 1
    if (this.imagenesProductos.length > 0 && auxin < length) {
      this.index = this.index + 1
    } else {
      this.index = this.index - (length - 1)
    }
  }


  izquierda(i) {
    var length = this.imagenesProductos.length
    var auxin = this.index
    if (this.imagenesProductos.length > 0 && auxin > 0) {
      this.index = this.index - 1
    } else {
      this.index = this.index + (length -1)
    }
  }

  newProductSingle() {
    let cont = 0
    this.intento = false
    let envio = {
      transaccion: '183036-man-crear-productos.xml',
    }
    envio['rec^tpe0^0^CTIENDA^tpe0'] = this.cctienda
    envio['rec^tpe0^0^NOMBRE^tpe0'] = this.productoP.value.toUpperCase()
    envio['rec^tpe0^0^CCATEGORIAECOMM_1^tpe0'] = this.categoriaFormP.value
    envio['rec^tpe0^0^DETALLE1^tpe0'] = this.caracteristicaP1.value.toUpperCase()
    envio['rec^tpe0^0^DETALLE2^tpe0'] = this.caracteristicaP2.value.toUpperCase()
    envio['rec^tpe0^0^DESCRIPCION^tpe0'] = this.descripcionP.value.toUpperCase()
    envio['rec^tpe0^0^PRECIO^tpe0'] = this.precioP.value.replace(/,/g, "")
    envio['rec^tpe0^0^PERMITEDIFERIR^tpe0'] = this.diferirP.value
    envio['rec^tpe0^0^DISPONIBLE^tpe0'] = this.disponibilidadP.value

    for (const imagen of this.urls) {
      envio['rec^ti0^' + cont + '^IMAGEN^ti0'] = imagen.split(',')[1]
      cont = cont + 1
    }

    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = true
      this.message = this.idioma.TransaccionE.toUpperCase()
      this.intentoMessage = true
    }, (err) => {
      this.intento = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  newProductArchive() {
    this.intento = false
    let envio = {
      transaccion: '183036-man-cargar-productos-lote.xml',
      "ctl^CTIENDA": this.cctienda,
      "ctl^ARCHIVO": (this.imgURL as string).split(',')[1]
    }

    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = true
      this.message = this.idioma.TransaccionE.toUpperCase()
      this.intentoMessage = true
    }, (err) => {
      this.intento = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  cambiarImagen() {
    for (const imagen of this.imagenesProductos) {
      if (imagen.id == this.imagenFormP.value) {
        this.imageOdvl = imagen.detalle
        this.imgBD = this._DomSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imagen.detalle)
      }
    }
  }

  editProduct() {
    this.intento = false
    let envio = {
      transaccion: '183036-man-editar-productos.xml',
      "cri^tpe0^CPRODUCTOECOMM^NORMAL^tpe0^=": this.cproductoEcomm
    }

    envio["rec^tpe0^0^CPRODUCTOECOMM^tpe0"] = this.cproductoEcomm
    envio["rec^tpe0^0^CTIENDA^tpe0"] = this.cctienda

    if (this.productoP.value && (this.productoP.value != this.nombre)) {
      envio["rec^tpe0^0^NOMBRE^tpe0^VAL"] = this.productoP.value.toUpperCase()
      envio["rec^tpe0^0^NOMBRE^tpe0^oldval"] = this.nombre
    } else {
      envio["rec^tpe0^0^NOMBRE^tpe0"] = this.nombre
    }

    if (this.categoriaFormP.value && (this.categoriaFormP.value != this.ccategoria)) {
      envio["rec^tpe0^0^CCATEGORIAECOMM_1^tpe0^VAL"] = this.categoriaFormP.value
      envio["rec^tpe0^0^CCATEGORIAECOMM_1^tpe0^oldval"] = this.ccategoria
    } else {
      envio["rec^tpe0^0^CCATEGORIAECOMM_1^tpe0"] = this.ccategoria
    }

    if (this.caracteristicaP1.value && (this.caracteristicaP1.value != this.DETALLECARAC_1)) {
      envio["rec^tpe0^0^DETALLE1^tpe0^VAL"] = this.caracteristicaP1.value.toUpperCase()
      envio["rec^tpe0^0^DETALLE1^tpe0^oldval"] = this.DETALLECARAC_1
    } else {
      envio["rec^tpe0^0^DETALLE1^tpe0"] = this.caracteristicaP1.value
    }

    if (this.caracteristicaP2.value && (this.caracteristicaP2.value != this.DETALLECARAC_2)) {
      envio["rec^tpe0^0^DETALLE2^tpe0^VAL"] = this.caracteristicaP2.value.toUpperCase()
      envio["rec^tpe0^0^DETALLE2^tpe0^oldval"] = this.DETALLECARAC_2
    } else {
      envio["rec^tpe0^0^DETALLE2^tpe0"] = this.caracteristicaP2.value
    }

    if (this.descripcionP.value && (this.descripcionP.value != this.descripcion)) {
      envio["rec^tpe0^0^DESCRIPCION^tpe0^VAL"] = this.descripcionP.value.toUpperCase()
      envio["rec^tpe0^0^DESCRIPCION^tpe0^oldval"] = this.descripcion
    } else {
      envio["rec^tpe0^0^DESCRIPCION^tpe0"] = this.descripcion
    }

    if (this.precioP.value && (this.precioP.value != this.precio)) {
      envio["rec^tpe0^0^PRECIO^tpe0^VAL"] = this.precioP.value.replace(/,/g, "")
      envio["rec^tpe0^0^PRECIO^tpe0^oldval"] = this.precio
    } else {
      envio["rec^tpe0^0^PRECIO^tpe0"] = this.precio
    }

    if (this.diferirP.value) {
      envio["rec^tpe0^0^PERMITEDIFERIR^tpe0^VAL"] = this.diferirP.value == 2 ? "0" : this.diferirP.value
      envio["rec^tpe0^0^PERMITEDIFERIR^tpe0^oldval"] = this.difiere
    } else {
      envio["rec^tpe0^0^PERMITEDIFERIR^tpe0"] = this.difiere
    }

    if (this.disponibilidadP.value) {
      envio["rec^tpe0^0^DISPONIBLE^tpe0^VAL"] = this.disponibilidadP.value == 2 ? "0" : this.disponibilidadP.value
      envio["rec^tpe0^0^DISPONIBLE^tpe0^oldval"] = this.disponible
    } else {
      envio["rec^tpe0^0^DISPONIBLE^tpe0"] = this.disponible
    }

    envio['rec^tpe0^0^VERSIONCONTROL^tpe0'] = "$vc"

    if (this.cargaArchivoP) {
      envio["cri^ti0^CIMAGEN^NORMAL^ti0^="] = this.imagenesProductos[this.index].id
      envio["rec^ti0^0^CIMAGEN^ti0"] = this.imagenesProductos[this.index].id
      envio["rec^ti0^0^IMAGEN^ti0^VAL"] = (this.imgURL as string).split(',')[1]
      envio["rec^ti0^0^IMAGEN^ti0^oldval"] = this.imagenesProductos[this.index].detalle
      envio['rec^ti0^0^VERSIONCONTROL^ti0'] = "$vc"
    }

    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envio).then((data: any) => {
      this.intento = true
      this.message = this.idioma.TransaccionE.toUpperCase()
      this.intentoMessage = true
    }, (err) => {
      this.intento = true
      this.intentoMessage = true
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  removeProducto() {
    this.intento = true;
    var cont = 0;
    let envioproductos = {
      transaccion: '183036-man-caducarProducto.xml',
      usuario: this.basedatos.usuario,
      "rec^tpro0^0^CPRODUCTOECOMM": this.cproductoEcomm
    }

    this.apiService.postProvider2InternetCheck('/mantenimientosimple', this.basedatos.id_token, envioproductos).then((data: any) => {
      this.intento = false
      this.message = this.idioma.TransaccionE.toUpperCase()
      this.intentoMessage = true
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.intentoMessage = true
          this.intentoMessageError = true
          this.MessageError = this.idioma.ServidorError
        }
      }
    });
  }

  comaToDot(event) {
    if (event.key === ',') {
      event.target.value += '.';
      event.preventDefault();
    }
  }

  logout() {
    let obj = {
      'salir': true
    }
  }

}

interface IImagenesP {
  id: string,
  detalle: string
}

interface ICategoriasP {
  id: string,
  detalle: string
}


